/*
 * Created by Volodymyr Mak. on 20/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import {StudentProfileService} from 'src/app/student/profile/student-profile.service'
import {StudentSoftQuestions} from 'src/app/student/profile/profile.interface'
import { TeacherService, NotesAboutStudent, ArchiveMeeting, SubjectResult, ArchiveStudentTest } from '../../../teacher/services/teacher.service';
import { Student, ProfileCardData, ProfileFieldsNames } from 'src/app/shared/components/profile-card/profile-card.model';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';

@Component({
  selector: 'curr-course-student-profile',
  templateUrl: './course-student-profile.component.html',
  styleUrls: ['./course-student-profile.component.scss']
})
export class CourseStudentProfileComponent implements OnInit, OnDestroy {
  student: Student;
  softQuestion: StudentSoftQuestions;
  notesAboutStudent: NotesAboutStudent[];
  myNotes: NotesAboutStudent[];
  archiveMeetings: ArchiveMeeting[];
  subjectsResultList: SubjectResult[];
  archiveTestList: ArchiveStudentTest[];
  profileData: ProfileCardData;
  subjects = [
    {value: 'all', label: 'All subjects', selected: true},
    {value: 'astronomy', label: 'Astronomy', selected: false},
    {value: 'physics', label: 'Physics', selected: false},
    {value: 'math', label: 'Math', selected: false},
    {value: 'economics', label: 'Economics', selected: false},
    {value: 'biology', label: 'Biology', selected: false},
  ]
  private subscription = new Subscription();
  private teacherId = 'teacherId2';

  constructor(
    private _studentService: StudentProfileService,
    private _teacherService: TeacherService,
    private _profileDataService: ProfileDataService
  ) { }

  ngOnInit(): void {
    this._studentService.getStudentProfile('studentId1').subscribe({
      next: (data: Student) => {
        this.listenOnUserProfileChanges();
      }
    });
    this._studentService.getStudentSoftQ('studentId1').subscribe(
      {
        next: (data: StudentSoftQuestions) => {
          this.softQuestion = data;
        }
      }
    );
    this._teacherService.getNotesAboutStudent('sudentId1').subscribe(
      {
        next: (data: NotesAboutStudent[]) => {
          this.notesAboutStudent = data;
          this.myNotes = this.notesAboutStudent.filter((note: NotesAboutStudent) => note.authorId === this.teacherId)
        }
      }
    );
    this._teacherService.getMeetingArchiveList('sudentId1').subscribe(
      {
        next: (data: ArchiveMeeting[]) => {
          this.archiveMeetings = data;
        }
      }
    );
    this._teacherService.getStudentSubjectsResults('studentId1').subscribe(
      {
        next: (data:SubjectResult[]) => {
          this.subjectsResultList = data;
        }
      }
    );
    this._teacherService.getStudentArchiveTestList('studentId1').subscribe(
      {
        next: (data: ArchiveStudentTest[]) => {
          this.archiveTestList = data;
        }
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  removeSubject() {}
  editSubject() {}
  addNote() {}

  private listenOnUserProfileChanges() {
    this.subscription.add(
      this._profileDataService.UserProfile.subscribe({
        next: (student: Student) => {
          this.profileData = {
            formFieldsToDisplay: [
              {label: 'Email', name: ProfileFieldsNames.EMAIL, verify: {valid: student.email.verify}},
              {label: 'Phone', name: ProfileFieldsNames.PHONE, verify: {valid: student.phone.verify}},
              {label: 'Year', name: ProfileFieldsNames.YEAR},
              {label: 'Personal ID', name: ProfileFieldsNames.PERSONAL_ID}
            ],
            userData: student
          }
          this.student = student;
        }
      })
    )
  }
}
