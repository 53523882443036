/**
 * Created by Alex Poh. on 29/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private defaultPrimaryColor = '#006878';
  private defaultAccentColor = '#04A109';
  private greyColor = '#647077';

  private lightLeftLimit = 0.05;
  private lightRightLimit = 0.95;

  private step = 0.03;

  addColorVariablesToPage(primaryColor = this.defaultPrimaryColor, accentColor = this.defaultAccentColor) {
    this.addColor(primaryColor, 'primary');
    this.addColor(accentColor, 'accent');
    this.addColor(this.greyColor, 'grey');
  }

  private addColor(color, name) {
    const style = document.createElement('style');
    style.innerHTML = this.getPalette(color, name);
    document.head.appendChild(style);
  }

  private getPalette(color: string, type: string) {
    const hslArray = this.getHSLArrayByColor(color);
    let variablesPart = '';
    let opacity = 1;
    hslArray.forEach((hsl: HSL, index) => {
      if (index < 5) {
        opacity = (index + 1) * 0.1666;
      }
      variablesPart += `--theme-color-${ type }-${ index }:hsla(${ hsl.h * 360 }, ${ hsl.s * 100 }%, ${ hsl.l *
      100 }%, ${ opacity }); \n`;
    });
    return `:root{${ variablesPart }}`;
  }

  private getHSLArrayByColor(color: string): HSL[] {
    const result = [];
    // tslint:disable-next-line:radix
    const r = parseInt('0x' + color.slice(1, 3));
    // tslint:disable-next-line:radix
    const g = parseInt('0x' + color.slice(3, 5));
    // tslint:disable-next-line:radix
    const b = parseInt('0x' + color.slice(5, 7));
    const hsl = this.rgbToHsl(r, g, b);
    let startingLight = hsl.l - this.step * 8;
    const endLight = hsl.l + this.step * 5;

    if (endLight > this.lightRightLimit) {
      startingLight = startingLight - (endLight - this.lightRightLimit);
    }

    if (startingLight < this.lightLeftLimit) {
      startingLight = this.lightLeftLimit;
    }

    for (let i = 13; i >= 0; i--) {
      const modifiedHsl = { ...hsl };
      modifiedHsl.l = startingLight + this.step * i;
      result.push(modifiedHsl);
    }
    return result;
  }

  private rgbToHsl(R, G, B): HSL {
    const r = R / 255;
    const g = G / 255;
    const b = B / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // TODO: check if we really need init as undefined
    // tslint:disable-next-line:no-unnecessary-initializer
    let h = undefined;
    // tslint:disable-next-line:no-unnecessary-initializer
    let s = undefined;
    // tslint:disable-next-line:prefer-const
    let l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }

      h /= 6;
    }
    return { h, s, l };
  }
}

export class HSL {
  h: number;
  s: number;
  l: number;
}
