/**
 * Created by Max Bon. on 29/07/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { StudentAddCommentToNotePopupComponent } from '../student-add-comment-to-note-popup/student-add-comment-to-note-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ResourceStudentNotesService } from './resource-student-notes.service';
import { StudentNote } from './student-note';
import { DomSanitizer } from '@angular/platform-browser';
import { StudentNotesHelperService } from './student-notes-helper.service';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UpdateNetesSubjectService } from './update-netes-subject';

interface NoteToDisplay {
  title?: string;
  note?: any;
  curriculum_topic_id?: number;
  lenDifference?: number;
  studentNotes?: StudentNote[];
  alreadyChangeCoreText?: boolean;
  id?: number;
}

@Component({
  selector: 'curr-student-notes-resources',
  templateUrl: './student-notes-resources.component.html',
  styleUrls: ['./student-notes-resources.component.scss']
})
export class StudentNotesResourcesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Output() newNoteAdded = new EventEmitter<StudentNote>();
  @Output() noteClicked = new EventEmitter<number>();
  @Input() teacherNotesForStudent: any[] = [];
  @Input() studNotes: StudentNote[] = [];
  @Input() courseStudId: number;
  @Input() selectedlearningObjective: any;

  screenWidth = window.innerWidth;

  hintOnHover = '';
  imageHintOnHover = '';
  currentImageUrlOnHover = '';
  currentImageColorOnHover = '';
  isImageHighlighted = false;
  selectedText = '';
  previewWidth = 0;
  contextMenuCoordinates = {
    x: 0,
    y: 0
  };
  hintCoordinates = {
    x: 0,
    y: 0
  };
  imagePreviewCoordinates = {
    x: 0,
    y: 0
  };
  imageUrlPreviewCoordinates = {
    x: 0,
    y: 0
  };
  note: StudentNote = {};
  notes: NoteToDisplay[] = [];
  currentSelectedNoteId: NoteToDisplay = {};
  unssSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private noteService: ResourceStudentNotesService,
    private unss: UpdateNetesSubjectService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private notesHelperService: StudentNotesHelperService
  ) {
  }

  ngOnChanges(): void {
    this.currentImageUrlOnHover = '';
    this.displayNotes();
  }

  ngOnInit(): void {
    this.displayNotes();
    this.unssSubscription = this.unss.get().subscribe(() => {
      this.clearSelection();
      this.displayNotes();
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    this.unssSubscription.unsubscribe();
  }

  displayNotes() {
    this.setupTeacherNotesForStudent();
    if (this.studNotes.length) {
      this.injectStudentTextNotes();
      this.processImagesNotes();
    }
    timer(1000)
      .pipe(take(1))
      .subscribe(resp => {
        this.addEventListenersToStudentsTextNotes();
        this.addEventListenersToStudentsImageNotes();
        this.cdr.detectChanges();
      });
  }

  setupTeacherNotesForStudent() {
    this.notes = this.teacherNotesForStudent.map((i, index) => {
      i.note = this.decodeHtml(i.note);
      i.note = i.note.replace('/↵/gi', '');
      return {
        title: i.title,
        note: this.sanitizeHtml(i.note),
        curriculum_topic_id: i.curriculumTopicId,
        lenDifference: 0,
        studentNotes: this.studNotes.filter(a => a.curriculum_learning_objective_note_id === i.id),
        alreadyChangeCoreText: false,
        id: i.id
      };
    });
    this.notes.forEach(i => {
      i.studentNotes.sort(this.compareNumbers);
    });
  }

  decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  injectStudentTextNotes() {
    this.notes.forEach(note => {
      note.studentNotes.forEach(n => {
        if (n.selected_image || !n.selected_text) {
          // if note is image note - do nothing here
          return;
        }
        let html = note.note.changingThisBreaksApplicationSecurity.toString();
        const textToReplace = html.substring(n.start_idx_num + note.lenDifference, n.end_idx_num + note.lenDifference);

        const plug = `<span id="hl-${n.id}" style="background-color: ${n.selected_text_color};">
                        ${this.addOpenCloseSpanTag(textToReplace, n.selected_text_color)}
                        </span><a id="ahl-${n.id}"></a>`;
        const ld = note.lenDifference;
        if (!note.alreadyChangeCoreText) {
          note.alreadyChangeCoreText = true;
          note.lenDifference = ld + plug.length - (n.end_idx_num - n.start_idx_num);
        } else {
          note.lenDifference = ld + plug.length - (n.end_idx_num - n.start_idx_num);
        }
        html = html.replace(textToReplace, plug);
        note.note = this.sanitizeHtml(html);
      });
    });
  }

  fixHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerHTML;
  }

  addOpenCloseSpanTag(html, color) {
    html = this.fixHtml(html);
    const div = document.createElement('div');
    div.innerHTML = html;
    let children = div.childNodes;
    if (children.length > 1) {
      children.forEach((node, i) => {
        if (i === 0 || i === children.length - 1) {
          const text = node.nodeValue || node.childNodes[0].nodeValue;
          const sp = document.createElement('span');
          sp.innerText = text;
          div.insertBefore(sp, node);
          div.removeChild(node);
        }
      });
    }
    children = div.childNodes;
    children.forEach((node, i) => {
      if ((node as HTMLElement)?.style) {
        // @ts-ignore
        (node as HTMLElement)?.style['background-color'] = color;
      }
    });
    return div.innerHTML;
  }

  private addEventListenersToImages() {
    this.notes.forEach(n => {
      const root = document.getElementById(`notesContainer-${n.id}`);
      if (!root) {
        return;
      }
      const allImgs = root.querySelectorAll('img');
      if (allImgs.length) {
        allImgs.forEach(e => {
          if (e.width > this.screenWidth) {  // For mobile, decrease width
            e.setAttribute('width', '100%');
          }
          if (e.classList.contains('already-used')) {
            return;
          }
          e.classList.add('height-unset');
          e.addEventListener('mouseenter', () => {
            // tslint:disable-next-line:triple-equals
            if (this.currentImageUrlOnHover == e.getAttribute('src')) {
              return;
            }
            this.currentImageUrlOnHover = e.getAttribute('src');
            if (this.currentImageUrlOnHover) {
              this.currentSelectedNoteId = n;
              this.isImageHighlighted = false;
              this.imageHintOnHover = '';
              this.currentImageColorOnHover = '';
              const rect = e.getBoundingClientRect();
              this.previewWidth = rect.right - rect.left;
              this.imageUrlPreviewCoordinates = {
                x: rect.left,
                y: rect.top + window.pageYOffset
              };
            }
          });
        });
      }
    });
  }

  addEventListenersToStudentsImageNotes() {
    this.studNotes.forEach(n => {
      if (!n.selected_image) {
        // if note is image note - do nothing here
        return;
      }
      const imgContainerEl = document.getElementById(`ichl-${ n.id }`);

      if (imgContainerEl) {
        const sibling = imgContainerEl.nextSibling as HTMLElement;
        imgContainerEl.classList.add('image-note-container');
        if (sibling && sibling.tagName?.toLowerCase() === 'img') {
          sibling.classList.add('already-used', 'height-unset');
          imgContainerEl.appendChild(imgContainerEl.nextSibling);
          if (sibling.style.float) {
            imgContainerEl.style.float = sibling.style.float ? sibling.style.float : 'left';
          }
        }
        imgContainerEl.addEventListener('mouseenter', () => {
          // tslint:disable-next-line:triple-equals
          if (this.currentImageUrlOnHover == n.selected_image) {
            return;
          }
          this.isImageHighlighted = true;
          this.imageHintOnHover = n.image_note || '';
          this.currentImageUrlOnHover = n.selected_image || '';
          this.currentImageColorOnHover = n.selected_text_color || '';
          if (this.currentImageUrlOnHover) {
            this.setImagePreviewCoordinates(n);
          }
        });
      }
    });
    this.addEventListenersToImages();
  }

  addEventListenersToStudentsTextNotes() {
    this.studNotes.forEach(n => {
      if (n.selected_image) {
        // if note is image note - do nothing here
        return;
      }
      const noteElement = document.getElementById(`hl-${ n.id }`);
      if (noteElement) {
        noteElement.classList.add('noselect', 'hover-cursor');
        noteElement.addEventListener('mouseenter', () => {
          this.hintOnHover = n.text_note || '';
          if (this.hintOnHover) {
            this.setHintCoordinates(n);
          }
        });
        noteElement.addEventListener('click', () => {
          this.noteClicked.emit(n.id);
        });
        noteElement.addEventListener('mouseleave', () => {
          this.hintOnHover = '';
          this.hintCoordinates = {
            x: -1000,
            y: -1000
          };
        });
      }
    });
  }

  setHintCoordinates(n: StudentNote) {
    const anchorElement = document.getElementById(`ahl-${ n.id }`);
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();
      this.hintCoordinates = {
        x: rect.right + 4,
        y: rect.bottom - 4 + window.pageYOffset
      };
    }
  }

  setImagePreviewCoordinates(n: StudentNote) {
    const anchorElement = document.getElementById(`ichl-${ n.id }`);
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();
      this.previewWidth = rect.right - rect.left;
      this.imagePreviewCoordinates = {
        x: rect.right + 4,
        y: rect.bottom - 4
      };
      this.imageUrlPreviewCoordinates = {
        x: rect.left,
        y: rect.top + window.pageYOffset
      };
    }
  }

  onSelect(e: MouseEvent, note: any) {

    this.currentSelectedNoteId = note;
    const noteHtml = this.teacherNotesForStudent.find(i => i.id === note.id)?.note;
    this.contextMenuCoordinates.x = e.pageX;
    this.contextMenuCoordinates.y = e.pageY;
    let text = '';
    const doc = document as any;
    if (window.getSelection) {
      text = window.getSelection().toString();
      const selection = window.getSelection() as any;
      if (!text.length) {
        this.clearSelection();
        return;
      }
      if (this.isFindFewOccurrences(text, noteHtml) > 1) {
        this.toastr.info('Try to be more specific, please.', 'Select more');
        this.clearSelection();
        return;
      }
      const startNode = selection.baseNode.nodeValue;
      const endNode = selection.focusNode.nodeValue;
      // If noteHtml dont includes startNode or endNode - ignore selection
      if (!noteHtml.includes(startNode) || !noteHtml.includes(endNode)) {
        this.clearSelection();
        return;
      }
      this.setupStartEndIndexesForNoteInNode(noteHtml, startNode, endNode, text, note);
    } else if (doc.selection && doc.selection.type !== 'Control') {
      text = doc.selection.createRange().text;
    }
    this.selectedText = text;
    this.note.selected_text = text;
  }

  isFindFewOccurrences(text: string, noteHtml: any) {
    const regexp = new RegExp(text, 'g'); // if we found more than one text occurrences
    const array = [...noteHtml.matchAll(regexp)];
    return array.length;
  }

  setupStartEndIndexesForNoteInNode(
    noteHtml: string,
    startNode: string,
    endNode: string,
    selectedText: string,
    note: any
  ) {
    const startNodeIndex = noteHtml.indexOf(startNode);
    const endNodeIndex = noteHtml.indexOf(endNode);

    this.note.start_idx_num =
      this.notesHelperService.findStartIndex(startNode, selectedText, startNodeIndex > endNodeIndex) + startNodeIndex;
    this.note.end_idx_num =
      this.notesHelperService.findEndIndex(endNode, selectedText, !(startNodeIndex > endNodeIndex)) + endNodeIndex;
    if (this.note.end_idx_num < this.note.start_idx_num) {
      const ind = this.note.start_idx_num;
      this.note.start_idx_num = this.note.end_idx_num;
      this.note.end_idx_num = ind;
    }
    if (this.isTextAlreadyHighlighted(this.note.start_idx_num, this.note.end_idx_num, note)) {
      this.clearSelection();
      return;
    }
  }

  /*
   * forbid to select already selected text
   * */
  isTextAlreadyHighlighted(start: number, end: number, note: any): boolean {
    const id = note.id;
    const existingNotes = this.studNotes.filter(i => i.curriculum_learning_objective_note_id === id);
    let res = false;
    if (existingNotes.length) {
      existingNotes.forEach((i: StudentNote) => {
        if (res) {
          return;
        }
        const { end_idx_num, start_idx_num } = i;
        if (start >= start_idx_num && start <= end_idx_num) {
          res = true;
        }
        if (end >= start_idx_num && end <= end_idx_num) {
          res = true;
        }
        if (start <= start_idx_num && end >= end_idx_num) {
          res = true;
        }
      });
    }
    return res;
  }

  openCreateNotePopup() {
    const dialogRef = this.dialog.open(StudentAddCommentToNotePopupComponent, {
      width: '522px',
      data: {
        inEditNoteMode: false
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.comment && r.color) {
        this.note.text_note = r.comment;
        this.note.selected_text_color = r.color;
        this.createStudentNote();
      }
    });
  }

  onColorSelectEvent(c: string) {
    if (c === 'note') {
      this.openCreateNotePopup();
    } else {
      this.note.selected_text_color = c;
      this.createStudentNote();
    }
  }

  createStudentNote() {
    this.note.curriculum_learning_objective_id = this.selectedlearningObjective.id;
    this.note.curriculum_learning_objective_note_id = this.currentSelectedNoteId.id;
    this.note.school_year_course_student_id = this.courseStudId;
    if (this.note.selected_image) {
      this.addStartIndexToSelectedImage();
    }
    this.noteService.addNote(this.note).subscribe(
      resp => {
        this.newNoteAdded.emit((resp as any).data);
        this.toastr.success('Note was saved!');
      },
      error => {
        this.toastr.warning('Error happened!');
      }
    );
    this.clearSelection();
    this.displayNotes();
  }

  addStartIndexToSelectedImage() {
    if (!this.note.selected_image) {
      return;
    }

    const note = this.teacherNotesForStudent.find(i => this.note.curriculum_learning_objective_note_id === i.id);
    if (note) {
      this.note.start_idx_num = note.note.indexOf(this.note.selected_image);
      this.note.end_idx_num = this.note.start_idx_num + this.note.selected_image.length;
    }
  }

  clearSelection() {
    this.selectedText = '';
    this.currentImageUrlOnHover = '';
    this.contextMenuCoordinates = {
      x: -200,
      y: -200
    };
    this.note = {};
  }

  sanitizeHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  compareNumbers(a, b) {
    return a.end_idx_num - b.start_idx_num;
  }

  private processImagesNotes() {
    this.notes.forEach(note => {
      note.studentNotes.forEach(n => {
        if (n.selected_image) {
          // if note is image note - do magic
          let text = note.note.changingThisBreaksApplicationSecurity.toString();
          const indexOfSelectedImage = text.indexOf(n.selected_image);
          const indexOfCloseTag = text.indexOf('>', indexOfSelectedImage + n.selected_image.length);
          const indexOfOpenTag = text.lastIndexOf('<img', indexOfSelectedImage);
          const allTag = text.substring(indexOfOpenTag, indexOfCloseTag + 1);
          text = text.replace(
            allTag,
            `<div id="ichl-${n.id}" style="border-color: ${n.selected_text_color};background-color: ${n.selected_text_color};border-radius: 4px;position: relative;"></div>${allTag}`
          );
          note.note = this.sanitizeHtml(text);
        }
      });
    });
  }

  createImageNote(e: { comment?: string; color?: string }) {
    this.note.image_note = e.comment;
    this.note.selected_text_color = e.color;
    this.note.selected_image = this.currentImageUrlOnHover;
    this.createStudentNote();
  }

}
