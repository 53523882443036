<!-- Created by Maxim B. on 10/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="disabled-input-container">
  <div class="disable-layer" *ngIf="disabled"></div>
  <curr-textarea [(value)]="value" (valueChange)="updateValue($event)"
                 (onLostFocus)="lostFocus($event)"
                 [rows]="rows" [label]="label"
                 [controlName]="controlName" [formGroup]="formGroup"
                 [errorMessage]="errorMessage">

  </curr-textarea>

</div>
