import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ResultsTableService {
  constructor(private httpService: ApplicationHttpClient) {}

  getData(subjectId) {
    return this.httpService
      .post(`/schoolYearCourses/getHistoricalAssessment/${subjectId}`, {
        period_from: '2019-06-20 12:44:33',
        period_to: '2021-06-23 12:44:33',
        row_offset: 0,
        rows_count: 100
      })
      .pipe(map((res: any) => res.data.items));
  }
  getStudentData(subjectId, studentId) {
    return this.httpService
      .post(`/schoolYearCourses/getStudentHistoricalAssessment/${subjectId}`, {
        student_id:studentId,
        period_from: '2019-06-20 12:44:33',
        period_to: '2025-06-23 12:44:33',
        row_offset: 0,
        rows_count: 100
      })
      .pipe(map((res: any) => res.data.items));
  }
}
