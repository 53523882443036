/**
 * Created by Maxim B. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ITableStructure } from './teachers-statistic-table.interface';
import { MatTabGroup, MatTabNav } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { StatisticRequestParams, TeachersStatisticTableService } from './teachers-statistic-table.service';
import { ToastrService } from 'ngx-toastr';
import {
  CoursesFilterOptions,
  CoursesFilterFields,
  SelectCoursesFiltersComponent,
} from '../select-courses-filters/select-courses-filters.component';
import { Router } from '@angular/router';

const tableFields: ITableStructure = {
  fields: [
    { fieldName: 'subject', fieldFilter: 'all' },
    { fieldName: 'hwDelivered', fieldFilter: 'all' },
    { fieldName: 'hwCompleted', fieldFilter: 'all' },
    { fieldName: 'qzDelivered', fieldFilter: 'all' },
    { fieldName: 'qzCompleted', fieldFilter: 'all' },
    { fieldName: 'ctDelivered', fieldFilter: 'all' },
    { fieldName: 'avgGrade', fieldFilter: 'all' },
  ],
  firstColumn: { fieldName: 'Teacher Name', fieldFilter: '' },
  name: 'Teachers',
};

@Component({
  selector: 'curr-teachers-statistic-table',
  templateUrl: './teachers-statistic-table.component.html',
  styleUrls: ['./teachers-statistic-table.component.scss'],
})
export class TeachersStatisticTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() statistic: [];
  @Input() set selectedCourseId(value: string) {
    if (value) {
      this.selectFilter[this.coursesFilterFields.course] = value;
      this.preselectedCourseId = value;
    }
  }

  preselectedCourseId: string;

  @ViewChild(SelectCoursesFiltersComponent) tableFiltersRef: SelectCoursesFiltersComponent;

  searchValue = '';
  isMeetingThisWeek = true;
  isMeetingDropdownOpen = false;
  isInTableDropdownOpen = false;
  clickedPendingUser = '';
  pendingMenuSubscription: any;
  isDataLoading = false;
  isDataPresented = true;

  resultsLength = 0;
  currentPageIndex = 0
  customTables: ITableStructure = tableFields;

  @ViewChild('nameInput') private nameInput: ElementRef;
  @ViewChild(MatTabGroup) matTabs: MatTabNav;

  // FILTERS - DROPDOWN
  ddOptionsPredictedGrade = [];
  selectFilter: CoursesFilterOptions = {};
  filterData: any;
  coursesFilterFields: CoursesFilterFields = {
    subject: 'subjectName',
    course: 'stCourseId',
    grade: 'studyYearId',
  };

  // TABLE
  TEACHERS_DATA = [];
  ELEMENT_DATA = [];
  displayedColumns: string[] = [];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    public dialog: MatDialog,
    public teachersStatisticTableService: TeachersStatisticTableService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initTableData();
    this.getOptionsForFilters();
  }

  ngAfterViewInit(): void {}

  onPageChange(pageIndex: number) {
    this.getTeachersStatistic(pageIndex * 20);
  }

  initTableData() {
    this.getTeachersStatistic();

    this.ddOptionsPredictedGrade = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'F', label: 'F' },
    ];
  }

  updateCurrentTable() {
    this.getTeachersStatistic();
  }

  getTeachersStatistic(offset = 0) {
    this.showSpinner();
    const o: StatisticRequestParams = {
      rowsCount: 20,
      rowOffset: offset,
      ...this.selectFilter,
    };

    this.teachersStatisticTableService.getTeachersStatistic(o).subscribe(
      (res: any) => {
        if (!res.results.length) {
          // this.toastr.info('No data to show');
          this.isDataPresented = false;
          return;
        } else {
          this.isDataPresented = true;
          this.resultsLength = res.totalResults;
          this.currentPageIndex = res.pageIndex;

          res.results = res.results.map((item, key) => {
            item.hwCompleted = `${item.hwCompleted}%`;
            item.qzCompleted = `${item.qzCompleted}%`;
            return item;
          });
          this.TEACHERS_DATA = [];
          this.TEACHERS_DATA.push(...res.results);
          this.setTeachersTableData();
        }
      },
      (error) => {
        this.toastr.error('Failed to load statistic');
      },
      () => {
        this.hideSpinner();
      },
    );
  }

  setTeachersTableData() {
    this.displayedColumns = ['teacherName'];
    this.customTables.fields.forEach((i) => {
      this.displayedColumns.push(i.fieldName + '');
    });
    this.dataSource = new MatTableDataSource(this.TEACHERS_DATA);
    this.cdr.detectChanges();
    this.dataSource.sort = this.sort;
    this.setFilterPredicate();
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter: string) => {
      return (
        data.teacherName.toLowerCase().indexOf(filter) > -1 ||
        data.subject?.toLowerCase().indexOf(this.selectFilter.subjectName) > -1 ||
        (data.year !== undefined && data.year === this.selectFilter.studyYearId) ||
        (data.form !== undefined && data.form === this.selectFilter.stCourseId)
      );
    };
  }

  filterEntries(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = this.searchValue.length ? this.searchValue : '__________';
  }

  onSelectFilterChange(selectFilter: CoursesFilterOptions) {
    this.selectFilter = selectFilter;

    this.setFilterPredicate();
    this.dataSource.filter = this.searchValue.length ? this.searchValue : '__________'; // If empty string filter won't apply
    this.updateCurrentTable();
  }

  getOptionsForFilters() {
    this.teachersStatisticTableService.getFilters().subscribe((resp) => {
      this.filterData = resp;
    });
  }

  showSpinner() {
    this.isDataLoading = true;
  }

  hideSpinner() {
    this.isDataLoading = false;
  }

  ngOnDestroy(): void {
    if (this.pendingMenuSubscription) {
      this.pendingMenuSubscription.unsubscribe();
    }
  }

  navigateTo(url: string): void {
    this.router.navigateByUrl(url);
  }
}
