/**
 * Created by Maxim B. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PickYearDialogComponent } from './pick-year-dialog/pick-year-dialog.component';

@Component({
  selector: 'curr-years-picker',
  templateUrl: './years-picker.component.html',
  styleUrls: ['./years-picker.component.scss']
})
export class YearsPickerComponent implements OnInit, OnChanges {
  @Input() options: { label: string, years: number[] }[];
  @Input() disabledYears: string[];
  @Output() valueChange = new EventEmitter<any[]>();
  @Output() onAddNewOption = new EventEmitter<{ label: string, years: number[] }>();
  @Output() onSelectPeriod = new EventEmitter<{ label: string, years: number[] }>();
  selectOptions: { label: string, value: string }[] = [];

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.selectOptions = this.options.map(i => {
      return { label: i.label, value: i.label };
    });
  }

  saveChanges() {
    this.valueChange.emit(this.options);
  }

  setSelectedYearsRange(label: string) {
    const currOpt = this.options.find(i => i.label === label);
    this.onSelectPeriod.emit(currOpt);
  }

  addNewYearsRange() {
    const dialogRef = this.dialog.open(PickYearDialogComponent, {
      width: '733px',
      data: { disabledFields: this.disabledYears }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.createNewRange(result);
      }
    });
  }

  private createNewRange(years: number[]) {
    const item = {
      label: this.createLabel(years),
      years
    };
    this.options.unshift(item);
    this.onAddNewOption.emit(item);
    this.selectOptions = this.options.map(i => {
      return { label: i.label, value: i.label };
    });
  }

  // this func create label like For Years 1, 3-7, 10, 11, 13
  createLabel(years: number[]) {
    const base = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const res = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    years.forEach(i => {
      base[i] = i;
      res[i] = i;
    });
    for (let i = 2; i < base.length; i++) {
      const item = base[i];
      const prev = (item - 1) === base[i - 1];
      const next = (item + 1) === base[i + 1];
      if (prev && next) {
        // @ts-ignore
        res[i] = '-';
      }
    }
    let finalLabel = 'For Years ';
    res.forEach((i, index) => {
      // tslint:disable-next-line:triple-equals
      if (i != 0) {
        const add = isNaN(res[index + 1]) ? i : i + ',';
        finalLabel = finalLabel + add;

      } else {
        // tslint:disable-next-line:triple-equals
        if (finalLabel[finalLabel.length - 1] != ',' && finalLabel[finalLabel.length - 1] != ' ') {
          finalLabel = finalLabel + ',';
        }
      }
    });
    // tslint:disable-next-line:triple-equals
    if (finalLabel[finalLabel.length - 1] == ',') {
      finalLabel = finalLabel.substr(0, finalLabel.length - 1);
    }
    finalLabel = finalLabel.replace(/-,/g, '-');
    finalLabel = finalLabel.replace(/-+/g, '-');
    finalLabel = finalLabel.replace(/,/g, ', ');
    return finalLabel;
  }
}
