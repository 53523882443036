/**
 * Created by Alex Poh. on 07/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { PreviewComponent } from '../preview-component';
import { Router } from '@angular/router';
import { UserService } from '../../../shared/user.service';

@Component({
  selector: 'curr-page-card-preview',
  templateUrl: './page-card-preview.component.html',
  styleUrls: ['./page-card-preview.component.scss']
})
export class PageCardPreviewComponent implements OnInit {
  @Input()
  component: PreviewComponent;

  @Input()
  isFake = false;

  constructor(private router: Router, private userService: UserService) {}

  ngOnInit(): void {}

  navigateToComponent() {
    this.userService.saveUser({ role: this.component.role });
    this.router.navigateByUrl(this.component.link);
  }
}
