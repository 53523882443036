/*
 * Created by Dmytro Sav. on 5/21/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData {
  isDeleted: boolean;
}

@Component({
  selector: 'curr-delete-course-popup',
  templateUrl: './delete-course-popup.component.html',
  styleUrls: ['./delete-course-popup.component.scss']
})
export class DeleteCoursePopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteCoursePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  delete() {
    this.data.isDeleted = true;
  }

  ngOnInit() {}
}
