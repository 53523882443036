/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { MatDialog } from '@angular/material/dialog';
import { IMostLostMarks } from '../../interfaces';
import { COLORS } from '../consts';
import { CourseDetailsService } from '../../services/course-details.service';
import { QuestionsListWithMostLostMarksComponent } from '../questions-list-with-most-lost-marks/questions-list-with-most-lost-marks.component';

@Component({
  selector: 'curr-questions-types-where-most-marks-are-lost',
  templateUrl: './questions-types-where-most-marks-are-lost.component.html',
  styleUrls: ['./questions-types-where-most-marks-are-lost.component.scss']
})
export class QuestionsTypesWhereMostMarksAreLostComponent implements OnInit {
  @Input() courseId: string;
  @Input() learningObjectiveId: string;

  @Input() set data(value: IMostLostMarks) {
    if (value) {
      const chartData = [{ data: Object.values(value).map(dataItem => Number(dataItem)) }];
      this.labels = Object.keys(value);

      this.chartData = chartData[0].data;
      this.labels = this.labels.map(item => {
        return `${ item } MARK(S)`;
      });

      this.dataNew = {
        labels: this.labels,
        datasets: [{
          data: this.chartData,
          backgroundColor: COLORS,
          hoverBackgroundColor: COLORS,
          borderColor: COLORS,
          hoverBorderColor: COLORS,
          hoverColor: COLORS,
          borderWidth: 1
        }]
      };
    }
  }

  chartData: number[];
  labels: string[];
  dataNew;
  barChartOptions: ChartConfiguration<'bar'>['options'] = {
    scales: {
      x: {
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          padding: 4,
          callback: (value) => {
            return [`${ +value + 1 } MARK`, `QUESTIONS`];
          }
        }
      },
      y: {
        min: 10,
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          callback: (value) => `${ value }%`,
          padding: 4
        }
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      datalabels: {
        formatter: value => {
          return value ? `${ Math.round(value) }%` : null;
        },
        align: 'center',
        color: '#000A12',
        font: {
          weight: 500,
          size: 10
        }
      },
    },
  };

  constructor(private courseDetailsService: CourseDetailsService, private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  onChartClick(e: any) {
    if (e.active.length) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length) {
        let mark = activePoints[0].$datalabels[0]._index;
        this.courseDetailsService
          .getQuestionsByMarkGroupWhereMostMarksAreLost(this.courseId, this.learningObjectiveId, mark++, mark++)
          .subscribe(data => {
            this.dialog.open(QuestionsListWithMostLostMarksComponent, {
              data
            });
          });
      }
    }
  }
}
