/**
 * Created by Daniil V. on 15/02/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LearningObjective } from '../../api-subject-details';
import { PaperDetailsService } from '../paper-details.service';
import { TeacherGuideline } from '../paper-details.interfaces';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { fadeAnimation } from '../../../../shared/animations/fade.animation';
import { FileUploadService } from '../../../../shared/components/edit-question-block/file-upload.service';
import { forkJoin } from 'rxjs';
import { Observable } from 'rxjs';
import { SimpleChanges } from '@angular/core';

@Component({
  selector: 'curr-teacher-guidance-notes',
  templateUrl: './teacher-guidance-notes.component.html',
  styleUrls: ['./teacher-guidance-notes.component.scss'],
  animations: [fadeAnimation]
})
export class TeacherGuidanceNotesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() topic: LearningObjective;

  isNotesLoading = false;
  isEditingMode = false;
  isAddingMode = false;

  notes: TeacherGuideline[] = [];
  newNote: TeacherGuideline = { title: '', note: '' };
  updateNotessubscription: Subscription;

  get isEditFieldsValid(): boolean {
    return true;
  }

  constructor(private paperDetailsService: PaperDetailsService,
              private toastr: ToastrService,
              private fileUploadService: FileUploadService) {
  }

  ngOnInit() {
    this.getNotes();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topic) {
      if (changes?.topic?.currentValue?.id !== changes?.topic?.previousValue?.id) {
        this.getNotes();
      }
    }
  }

  ngOnDestroy() {
    this.updateNotessubscription?.unsubscribe();
  }

  private getNotes() {
    this.isNotesLoading = true;
    this.paperDetailsService.getTeacherGuidelines(Number(this.topic.id)).subscribe(data => {
      this.notes = data;
      for (const note of this.notes) {
        if (note.note.indexOf('<img src')) {
          // this.uploadImages(note);
        }
      }
      setTimeout(() => (this.isNotesLoading = false), 1000);
    }, this.onError.bind(this));
  }

  uploadImages(note: TeacherGuideline) {
    const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(note.note);
    this.isNotesLoading = true;
    let noteText = note.note;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          noteText = noteText.replace(key, imageKeyValue[key]);
        }
        // note.note = noteText;
        this.isNotesLoading = false;
      },
      error: () => (this.isNotesLoading = false)
    });
  }

  addNote() {
    this.paperDetailsService
      .postTeacherGuideline({ ...this.newNote, curriculumLearningObjectiveId: this.topic.id })
      .subscribe(res => {
        this.newNote.title = null;
        this.notes.push(res);
        this.isEditingMode = true;
        this.toggleAddingNote();
        this.toastr.success('Guidance note was added!');
      }, this.onError.bind(this));
  }

  updateNotes() {
    if (this.isEditingMode) {
      this.updateNotessubscription = from(this.notes)
        .pipe(mergeMap(note => this.paperDetailsService.putTeacherGuideline(note)))
        .subscribe(_ => {
          this.toastr.success('Guidance notes were updated!');
        }, this.onError.bind(this));
    }
    this.togglEditingNotes();
  }

  deleteNote(id: number) {
    this.paperDetailsService.deleteTeacherGuideline(id).subscribe(_ => {
      this.notes = this.notes.filter(note => note.id !== id);
      this.toastr.success('Guidance note was deleted!');
    }, this.onError.bind(this));
  }

  togglEditingNotes() {
    this.isEditingMode = !this.isEditingMode;
  }

  toggleAddingNote() {
    this.isAddingMode = !this.isAddingMode;
  }

  onError(err) {
    this.toastr.warning('Error happened: ' + err);
  }
}
