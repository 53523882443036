import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'taskListPresentDate'
})
export class TaskListPresentDatePipe implements PipeTransform {
  transform(d: string | Date, args?: any): string {
    const today = new Date();
    const day = new Date(d);

    if (day.getDate() === today.getDate() && day.getFullYear() === today.getFullYear()) {
      return 'Today';
    }

    if (day.getDate() + 1 === today.getDate() && day.getFullYear() === today.getFullYear()) {
      return 'Yesterday (Overdue)';
    }

    if (day.getDate() - 1 === today.getDate() && day.getFullYear() === today.getFullYear()) {
      return 'Tomorrow';
    }

    return moment(new Date(day)).format('DD MMM YYYY');
  }
}
