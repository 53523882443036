/*
 * Created by Dmytro Sav. on 5/11/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../shared/utils/custom-validators';

@Component({
  selector: 'curr-edit-info',
  templateUrl: './edit-info.component.html',
  styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent implements OnInit {
  @ViewChild('photoUpload', { static: false }) fileUpload: ElementRef;

  @Input() userInfo;
  form: FormGroup;

  options = [
    { value: '1', label: 'UK' },
    { value: '2', label: 'US' },
    { value: '3', label: 'UAH' },
    { value: '4', label: 'EU' }
  ];

  constructor(private fb: FormBuilder) {}

  submit() {
    this.form.markAllAsTouched();

  }

  preview;

  uploadPhoto() {
    const fileUpload = this.fileUpload.nativeElement;

    fileUpload.onchange = () => {
      const file = fileUpload.files[0];

      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          // convert image file to base64 string
          this.preview = reader.result;
        },
        false
      );

      if (file) {
        reader.readAsDataURL(file);
      }
    };
    fileUpload.click();
  }

  ngOnInit() {
    const user = this.userInfo;

    this.form = this.fb.group({
      name: [user.name, [Validators.required]],
      surname: [user.surname, [Validators.required]],
      id: [user.id, [Validators.required]],
      email: [user.email, [Validators.required, CustomValidators.validateEmailOrPhone]],
      phoneNumber: [user.phoneNumber, [Validators.required]],
      country: [user.country, [Validators.required]]
    });

    if (user.school !== undefined) {
      const school = new FormControl('', Validators.required);
      this.form.addControl('school', school);
    }
  }

  chooseFile() {}
  loadInfo() {}

  getCountry(value) {}
}
