<div class="payment-container">
  <form class="payment-form" method="post" (ngSubmit)="createToken()" [formGroup]="stripeTest">
    <div ngxStripeCardGroup class="card-container">
      <google-pay-button
        environment="TEST"
        buttonType="buy"
        buttonColor="black"
        [paymentRequest]="paymentRequest"
        (error)="onError($event)"
        [paymentAuthorizedCallback]="onPaymentDataAuthorized"
      >
      </google-pay-button>
      <p class="pay-with-card">Or pay with card</p>
      <p class="card-info">Card information</p>
      <div class="card-wrapper">
        <div class="card-number">
          <ngx-stripe-card-number (change)="changeStripeNumber($event)"
                                  [options]="cardOptions"></ngx-stripe-card-number>
        </div>
        <div class="card-expiry">
          <ngx-stripe-card-expiry (change)="changeStripeNumber($event)"
                                  [options]="cardOptions"></ngx-stripe-card-expiry>
        </div>
        <div class="card-cvc">
          <ngx-stripe-card-cvc (change)="changeStripeNumber($event)" [options]="cardOptions"></ngx-stripe-card-cvc>
        </div>
        <mat-error class="zip-err">{{ invalidCard | translate }}</mat-error>
      </div>

      <label class="label-name" for="name-on-card">Name on card</label>
      <input formControlName="name" class="input-name" id="name-on-card" type="text">
      <div
        *ngIf="stripeTest.get('name').touched"
        class="validation">
        <mat-error *ngIf="stripeTest.get('name').errors && stripeTest.get('name').invalid">
          Name is required
        </mat-error>
      </div>
      <div class="address-wrapper">
        <label class="label-country" for="country-select">Country or region</label>
        <div class="address-info">
          <div class="select-wrapper">
          <select
            [(ngModel)]="selectedCountry"
            formControlName="country"
            class="country-select"
            id="country-select"
          >
            <option>{{selectedCountry}}</option>
            <option *ngFor="let country of countries">
              {{ country.name }}
            </option>
          </select>
          </div>
          <input formControlName="zip" class="input-zip" placeholder="ZIP" type="text">
          <div
            *ngIf="stripeTest.get('zip').touched"
            class="validation-zip">
            <mat-error class="zip-err" *ngIf="stripeTest.get('zip').errors && stripeTest.get('zip').invalid">
              ZIP is required
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf="isLoading" class="loading">
        <p class="card-info">This may take a minute to process.</p>
        <curr-loader></curr-loader>
      </div>

      <button
        *ngIf="!isLoading"
        [disabled]="!stripeTest.valid || !stripeValidationState.number || !stripeValidationState.expiry || !stripeValidationState.cvc"
        type="submit"
        class="btn-confirm-pay"
      >Confirm
      </button>
      <p class="subscription-info">
        By confirming your subscription, you allow
        Curriculum LTD to charge your card for this payment in accordance with their terms.
      </p>
    </div>
  </form>
</div>
