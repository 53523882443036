/**
 * Created by Alex Poh. on 05/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { restApiServiceCreator } from '../../../shared/api/rest-api.service';
import { PaperResponse } from '../api-subject-details';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaperDetailsResolver implements Resolve<PaperResponse> {
  constructor(private httpClient: ApplicationHttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PaperResponse> {
    const id = route.paramMap.get('paperId');
    const restApiService = restApiServiceCreator<PaperResponse>('curriculumPapers')(this.httpClient);
    return restApiService.getById(id).pipe(take(1));
  }
}
