<mat-horizontal-stepper
  (selectionChange)="setIndex($event)" (click)="triggerClick()"
  [linear]="isLinear"
  #stepper
  class="stepper-body"
>
  <ng-template matStepperIcon="edit">
    <mat-icon class="step-done-icon" [svgIcon]="'check'"></mat-icon>
  </ng-template>
  <mat-step class="step-checkout  box-align-center">
    <curr-choose-plan></curr-choose-plan>
  </mat-step>
  <mat-step class="step-checkout subscribe-part box-align-center">
    <curr-choose-subject (animationDone)="goNextStep()"></curr-choose-subject>
  </mat-step>
  <mat-step [tabIndex]="3">
    <div class="stripe-container">
      <div  class="payment-wrapper">
        <div class="payment-item">
          <p class="title-payment">Subscribe</p>
          <div class="items-subject-checkout">
            <ng-container *ngFor="let subject of (calculatedSubjects$ | async)?.subjects">
            <div  class="item-checkout">
              <mat-icon class="subject-icon" [svgIcon]="'biology-sm'"></mat-icon>
              <div class="item-checkout-description">
                <div class="item-checkout-title">
                  <p class="subject-item-title">{{ subject?.name }}</p>
                  <span class="item-subject-price">£{{ subject?.price }}</span>
                </div>
                <!-- <curr-dropdown
                  class="checkout-dropdown-subject"
                  [placeholder]="subject?.paymentPlanType"
                  [label]="true"
                  [options]="planOptions"
                  (valueChange)="changePlan($event, subject.id)"
                ></curr-dropdown> -->
              </div>
            </div>
          </ng-container>
          <div  class="item-checkout" *ngIf="(calculatedSubjects$ | async)?.oneTimePayments?.UNIVERSITY_BOOST as universityBoost">
            <mat-icon class="subject-icon" [svgIcon]="'biology-sm'"></mat-icon>
            <div class="item-checkout-description">
              <div class="item-checkout-title">
                <p class="subject-item-title">Uni-Prep Boost</p>
                <span class="item-subject-price">£{{ universityBoost }}</span>
              </div>
              <!-- <curr-dropdown
                class="checkout-dropdown-subject"
                [placeholder]="subject?.paymentPlanType"
                [label]="true"
                [options]="planOptions"
                (valueChange)="changePlan($event, subject.id)"
              ></curr-dropdown> -->
            </div>
          </div>
          </div>
          <div class="total-box">
            <p class="checkout-total-title">6 Month total</p>
            <span class="price-checkout-total">£{{ (calculatedSubjects$ | async)?.totalPrice }}</span>
          </div>
        </div>
      </div>
      <curr-payment-part></curr-payment-part>
    </div>
  </mat-step>
</mat-horizontal-stepper>

