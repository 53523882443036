import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';

@Injectable()
export class CtReviewService {

  ctInfo: any;

  constructor(
    private httpClient: ApplicationHttpClient
    ){}

  getCumulativeTestReview(testId: string, studentId: string): Observable<any> {
    return this.httpClient.get(`/studentCumTestQuestions/review?cum_test_id=${testId}&course_student_id=${studentId}`);
  }

}
