/**
 * Created by Daniil V. on 04/03/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { IMasterQuestion, TeacherGuideline } from './paper-details.interfaces';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Paper } from '../api-subject-details';

@Injectable({
  providedIn: 'root',
})
export class PaperDetailsService {

  private activeTopic = new Subject<void>();
  private updateQuestions = new Subject<void>();
  private activeTopicById = new Subject<number>();
  private activeTopicByIdWithScroll = new Subject<number>();
  private updateTopicName = new Subject<{topicId: number, newName: string}>();
  
  constructor(public http: ApplicationHttpClient) {}

  // TeacherGuidelines CRUD
  getTeacherGuidelines(curriculumLearningObjectiveId: number): Observable<TeacherGuideline[]> {
    return this.http
      .get<{
        data: { items: TeacherGuideline[] };
      }>(`/curriculumLearningObjectiveGuideliness?curriculumLearningObjectiveId=${curriculumLearningObjectiveId}`)
      .pipe(map((res) => res?.data?.items));
  }

  postTeacherGuideline(data: TeacherGuideline): Observable<TeacherGuideline> {
    return this.http
      .post<{ data: TeacherGuideline }>(`/curriculumLearningObjectiveGuideliness`, data)
      .pipe(map((res) => res.data));
  }

  putTeacherGuideline(data: TeacherGuideline): Observable<TeacherGuideline> {
    return this.http
      .put<{ data: TeacherGuideline }>(`/curriculumLearningObjectiveGuideliness`, data)
      .pipe(map((res) => res.data));
  }

  deleteTeacherGuideline(teacherGuidelineId: number): Observable<null> {
    return this.http.delete<null>(`/curriculumLearningObjectiveGuideliness/${teacherGuidelineId}`);
  }

  getPapers(): Observable<Paper> {
    return this.http.get('curriculumPapers?curriculum_id=1128');
  }

  updateTopicsOrder(data: { sequenceNum: number; curriculumLearningObjectiveId: number }[]): Observable<void> {
    return this.http.put<void>(`/updateCurriculumLearningObjective/sequence`, data);
  }

  getMasterQuestionsList(data: {
    subject: string;
    studyYear: number;
    questionYear?: number;
    examinationBoard?: string;
  }): Observable<IMasterQuestion[]> {
    let params: { subject: string; studyYear: number; questionYear?: number; examinationBoard?: string } = {
      subject: data.subject,
      studyYear: data.studyYear,
    };

    if (data.questionYear) params.questionYear = data.questionYear;
    if (data.examinationBoard) params.examinationBoard = data.examinationBoard;

    return this.http.get<IMasterQuestion[]>(`/curriculumQuestions/master`, { params } as any);
  }

  getExaminationBoardList(): Observable<any> {
    return this.http.get('/examination-boards');
  }

  getTopicQuestions(learningObjectiveId: number): Observable<void> {
    return this.http.get<void>(`/curriculumLearningObjectiveCTQuestions`, {
      params: { curriculum_LearningObjective_id: learningObjectiveId },
    } as any);
  }

  createUpdateTopic(data): Observable<number> {
    return this.http.post<number>(`/curriculumLearningObjective/master`, data);
  }

  deleteTopic(topicId: number): Observable<void> {
    return this.http.delete<void>(`/curriculumLearningObjectives/${topicId}`);
  }

  deleteMasterQuestion(questionId: number): Observable<void> {
    return this.http.delete<void>(`/curriculumLearningObjectiveCTQuestions/${questionId}`);
  }

  setActiveTopic() {
    this.activeTopic.next();
  }

  onSetActiveTopic(): Observable<void> {
    return this.activeTopic.asObservable();
  }

  setActiveTopicById(topicId: number) {
    this.activeTopicById.next(topicId);
  }

  onSetActiveTopicById(): Observable<number> {
    return this.activeTopicById.asObservable();
  }

  updateQuestionList() {
    this.updateQuestions.next();
  }

  onUpdateQuestionList(): Observable<void> {
    return this.updateQuestions.asObservable();
  }

  setActiveTopicByIdWithScroll(topicId: number) {
    this.activeTopicByIdWithScroll.next(topicId);
  }

  onSetActiveTopicByIdWithScroll(): Observable<number> {
    return this.activeTopicByIdWithScroll.asObservable();
  }

  setNewTopicName(data: {topicId: number, newName: string}) {
    this.updateTopicName.next(data);
  }

  onNewTopicName(): Observable<{topicId: number, newName: string}> {
    return this.updateTopicName.asObservable();
  }
}
