/**
 * Created by Maxim B. on 30/03/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'curr-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * Usage example:
 * <curr-checkbox [options]="cbOptions" [(value)]="cbValues" [color]="cbColor" [type]="cbType"
 *  [isMultipleSelect]="isCbMultiselect"></curr-checkbox>
 *  cbOptions: { value, label, disabled }[]
 *  cbValues: any[]
 *  cbColor: string
 *  cbType: string ('checkbox' | 'radio')
 *  isMultipleSelect: boolean  to multiple select values
 *  value will be auto assigned back to your cbValues
 */
export class CheckboxComponent implements OnInit, OnChanges {
  @Input() options: { value; label; disabled? }[];
  @Input() set setOptions(options: { value; label; disabled? }[]) {
    this.options = options;
  }
  @Input() set value(value: any[]) {
    this.valueNew = value;
    this.renderComponent()
  }
  @Input() isMultipleSelect = true;
  @Input() isDisabled = false;
  @Input() color = 'primary';
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Input() type: 'checkbox' | 'radio' = 'checkbox';
  @Output() valueChange = new EventEmitter<any[]>();

  valueNew: any[];
  componentOptions: { value; label; checked; disabled }[] = [];
  error = '';

  constructor() {
  }

  identify(index, item) {
    return item.label;
  }

  ngOnChanges(): void {
    // this.error = '';
    // this.componentOptions = [];
    // if (this.value?.length > this.options?.length) {
    //   this.error = 'you entered wrong options for component!';
    // }
    // if (this.value?.length > 1 && !this.isMultipleSelect) {
    //   this.error = 'To much values for single select mode!';
    // }
    // this.options.forEach(i => {
    //   this.componentOptions.push({
    //     label: i.label,
    //     value: i.value,
    //     checked: this.value?.includes(i.value),
    //     disabled: i.disabled || false
    //   });
    // });
  }

  clearOptions(): void {
    this.componentOptions?.forEach(i => {
      if (this.valueNew?.length) {
        if (this.valueNew[0] === i.value) {
          i.checked = false;
        }
      }
    });
  }

  renderComponent(): void {
    this.error = '';
    this.componentOptions = [];
    if (this.valueNew?.length > this.options?.length) {
      this.error = 'you entered wrong options for component!';
    }
    if (this.valueNew?.length > 1 && !this.isMultipleSelect) {
      this.error = 'To much values for single select mode!';
    }
    this.options?.forEach(i => {
      this.componentOptions?.push({
        label: i.label,
        value: i.value,
        checked: this.valueNew?.includes(i.value),
        disabled: i.disabled || false
      });
    });
  }

  ngOnInit(): void {
    this.renderComponent();
  }

  updateValue() {
    if (!this.isMultipleSelect && (this.valueNew[0] + '')) {
      this.clearOptions()
    }

    this.valueNew = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.componentOptions.length; i++) {
      if (this.valueNew.length > 0 && !this.isMultipleSelect) {
        this.componentOptions[i].checked = false;
        break;
      }
      if (this.componentOptions[i].checked) {
        this.valueNew.push(this.componentOptions[i].value);
      }
    }
    this.valueChange.emit(this.valueNew);
  }
}
