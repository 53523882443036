/*
 * Created by Alex Poh. on 5/20/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { getRestApiProvider, RestApiService } from '../../shared/api/rest-api.service';
import { CourseFilters } from './course-filters';
import { plainToClass } from 'class-transformer';
import { map } from 'rxjs/operators';
import { fadeAnimation } from '../../shared/animations/fade.animation';

@Component({
  selector: 'curr-course-filters',
  templateUrl: './course-filters.component.html',
  styleUrls: ['./course-filters.component.scss'],
  providers: [getRestApiProvider<CourseFilters>('filter/options/courses', 'courses')],
  animations: [fadeAnimation]
})
export class CourseFiltersComponent implements OnInit {
  @Input()
  @Output()
  filterParamsChanged = new EventEmitter();

  courseFilters: CourseFilters;

  filterParams: any = {};

  constructor(@Inject('courses') private courseFiltersOptions: RestApiService<CourseFilters>) {}

  ngOnInit(): void {
    this.courseFiltersOptions
      .getById('')
      .pipe(map(res => plainToClass(CourseFilters as any, res)))
      .subscribe(res => {
        this.courseFilters = res as any;
      });
  }

  selectSubject(event) {
    this.filterParams.subject_name = this.courseFilters.subjects.find(subject => subject.value === event).label;
    this.emit();
  }

  selectCourse(event) {
    this.courseFilters.selectFilteredItem('courses', event);
    this.filterParams.st_course_id = event;
    this.emit();
  }

  selectSemester(event) {
    this.filterParams.semesters = event;
    this.emit();
  }

  emit() {
    Object.keys(this.filterParams).forEach(key =>
      this.filterParams[key] === undefined ? delete this.filterParams[key] : {}
    );
    this.filterParamsChanged.emit(this.filterParams);
  }

  dropDownOptions = [
    { value: '1', label: 'Biology' },
    { value: '2', label: 'Geography' },
    { value: '3', label: 'Math' },
    { value: '4', label: 'Chemistry' }
  ];

  dropDownClick(value: string, filter: string) {}
}
