<div class="block-header m-b-8" fxLayout="row" fxLayoutAlign="space-between center">
  <span class="header mat-h4 m-b-0" fxFlex>{{questionLabel}}:</span>
  <div class="" fxLayout="row" fxLayoutAlign="end center">
    <mat-slide-toggle
      class="example-margin"
      [color]="'primary'" *ngIf="isEditQuestionWordingMode"
      [checked]="isQuestionTinyEditor"
      [(ngModel)]="isQuestionTinyEditor">
      <span class="mat-body-1">Editor</span>
    </mat-slide-toggle>
    <curr-icon-button class="m-l-8" icon="edit" *ngIf="!isEditQuestionWordingMode"
                      (click)="editQuestion('isEditQuestionWordingMode')">
    </curr-icon-button>
  </div>
</div>
<div class="block-content" *ngIf="question">
  <div *ngIf="!isEditQuestionWordingMode" class="m-b-32">
    <span class="mat-body-1" [innerHTML]="question?.question"></span>
  </div>
  <div *ngIf="isEditQuestionWordingMode">
    <curr-textarea [currMath]="question.question" *ngIf="!isQuestionTinyEditor"
                   [label]="questionPlaceholder" rows="auto" (onLostFocus)="updateQuestion($event)"></curr-textarea>
    <curr-tiny-editor *ngIf="isQuestionTinyEditor" [options]="{height: 300, statusbar: false}"
                      [formGroup]="form"
                      controlName="title"
                      (onFocusOut)="updateValue($event)">
    </curr-tiny-editor>


    <div class="m-t-8 m-b-8" fxLayout="row" fxLayoutAlign="start center"
         *ngIf="!question.parent_id">
      <curr-checkbox [options]="[{label: 'Is past exam question', value:true}]"
                     [value]="[question.is_past_exam_question]"
                     (valueChange)="setIsPastExam($event)">

      </curr-checkbox>
      <curr-dropdown *ngIf="question.is_past_exam_question" fxFlex="150px" class="m-l-16"
                     placeholder="Select year"
                     [selectedValue]="question.question_year"
                     [options]="pastExamOptions"
                     (valueChange)="setPastExamYear($event)"></curr-dropdown>
      <curr-dropdown *ngIf="question.is_past_exam_question" fxFlex="150px" class="m-l-16"
                     placeholder="Examination board"
                     [options]="examinationBoardList"
                     [selectedValue]="selectedBoard"
                     (valueChange)="setExaminationBoard($event)"></curr-dropdown>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="m-b-32"
         [ngClass]="isQuestionTinyEditor ? 'm-t-16' : ''" *ngIf="!inSowPage">
      <curr-button variant="contained" (btnClick)="saveQuestion('isEditQuestionWordingMode')">SAVE</curr-button>
      <curr-button variant="text" (btnClick)="cancelEdit('isEditQuestionWordingMode')">CANCEL</curr-button>
    </div>
  </div>
</div>
