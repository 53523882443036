/**
 * Created by Dmytro Sav. on 4/6/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

/**
 *
 * Usage example
 * <curr-user-card [isDeactivated]='true' userData='{}'> </curr-user-card>
 *
 * userData: { name: string, id: string, subject: string,  role: Role,  status: string,  img: string  }
 */

import { Component, Input, OnInit } from '@angular/core';
import { FileUploadService } from 'src/app/shared/components/edit-question-block/file-upload.service';
import { Router } from '@angular/router';

@Component({
  selector: 'curr-course-user-card',
  templateUrl: './course-user-card.component.html',
  styleUrls: ['./course-user-card.component.scss']
})
export class CourseUserCardComponent implements OnInit {

  @Input() userData: any;

  constructor(private fileUploadService: FileUploadService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (this.userData.photo) {
      this.uploadAvatar();
    }
  }

  private uploadAvatar() {
    this.fileUploadService
      .download(this.userData.photo, 'IMAGE')
      .subscribe((resp: any) => {
        const bytes = resp.data?.bytes;
        if (bytes != null) {
          this.userData.avatar = 'data:image/png;base64,' + bytes;
        }
      });
  }

  navigateToProfile(url: string) {
    this.router.navigateByUrl(url);
  }
}
