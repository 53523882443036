/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'curr-question-type-dropdown',
  templateUrl: './question-type-dropdown.component.html',
  styleUrls: ['./question-type-dropdown.component.scss']
})
export class QuestionTypeDropdownComponent {

  @Output()
  onClickAI = new EventEmitter<void>();

  @Output()
  onClickManual = new EventEmitter<void>();

  isAddNewOpen = false;

  constructor() {}
}
