/**
 * Created by Maxim B. on 26/05/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'curr-glossary-of-terms',
  templateUrl: './glossary-of-terms.component.html',
  styleUrls: ['./glossary-of-terms.component.scss']
})
export class GlossaryOfTermsComponent implements OnInit {
  displayedColumns: string[] = ['description', 'value', 'edit'];
  terms;
  dataSource;
  path = [
    {
      label: 'Home',
      url: ''
    },
    {
      label: 'Glossary of Terms',
      url: ''
    }
  ];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ddOptions = [
    { label: 'Semester', value: 'Semester' },
    { label: 'Lessons', value: 'Lessons' },
    { label: 'Years', value: 'Years' },
    { label: 'Students', value: 'Students' },
    { label: 'Marks', value: 'Marks' },
    { label: 'Teachers', value: 'Teachers' },
    { label: 'Personal School ID', value: 'Personal School ID' }
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.terms = this.route.snapshot.data.terms;
    this.terms.forEach(i => {
      i.isEditing = false;
    });
    this.dataSource = new MatTableDataSource(this.terms);
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editItem(element: any) {
    element.isEditing = !element.isEditing;
  }

  selectValue(val: string, element: any) {
    element.value = val;
    element.customValue = '';
  }
}
