/**
 * Created by Maxim B. on 17/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';
import { MediaItem, MediaType } from '../../../../shared/components/media-item/api-media-item';
import { LearningObjective } from '../../api-subject-details';
import { getRestApiProvider, RestApiService } from '../../../../shared/api/rest-api.service';
import { switchMap, switchMapTo } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { fadeAnimation } from '../../../../shared/animations/fade.animation';

@Component({
  selector: 'curr-learning-resources',
  templateUrl: './learning-resources.component.html',
  styleUrls: ['./learning-resources.component.scss'],
  providers: [getRestApiProvider<MediaItem>('curriculumLearningObjectiveResources')],
  animations: [fadeAnimation]
})
export class LearningResourcesComponent implements OnInit, OnChanges {
  @Input() learningObjective: LearningObjective;
  @Output()
  learningObjectiveUpdated = new EventEmitter<boolean>();
  currResourceType = MediaType.video;
  disabledStatus = true;
  isNewPaper = false;
  newMediaItem: MediaItem;
  isLoading = true;
  ddTypeOptions = [
    { label: 'Video', value: MediaType.video },
    { label: 'Image', value: MediaType.image },
    { label: 'Link', value: MediaType.link },
    { label: 'Document', value: MediaType.document }
  ];
  MediaType = MediaType;

  mediaItems: MediaItem[] = [];

  constructor(private route: ActivatedRoute, private learningResourceService: RestApiService<MediaItem>) {
  }

  ngOnInit(): void {
    this.isNewPaper = this.route.snapshot.parent?.params.subjectId === 'new';

    this.currResourceType = this.route.snapshot.queryParams.resources
      ? this.route.snapshot.queryParams.resources
      : MediaType.video;
    this.initByMediaType(this.currResourceType);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initByMediaType(this.currResourceType);
  }

  initByMediaType(mediaType) {
    this.currResourceType = mediaType;
    this.mediaItems = [];
    this.learningResourceService
      .getAll({ resource_type: mediaType, curriculum_learning_objective_id: this.learningObjective.id })
      .subscribe(
        (res: MediaItem[]) => {
          this.mediaItems = res;
        },
        () => {},
        () => (this.isLoading = false)
      );
  }

  dropItem(event: CdkDragDrop<string[]>, type) {
    moveItemInArray(this.mediaItems, event.previousIndex, event.currentIndex);
  }

  addMedia() {
    this.newMediaItem = {
      title: '',
      estimated_time: 1,
      description: '',
      resource: '',
      resource_type: this.currResourceType,
      curriculum_lobj_id: this.learningObjective.id
    };
  }

  createNewMediaItem(item: MediaItem) {
    this.learningResourceService
      .add(item)
      // .pipe(this.updateFile(item) as any)
      .subscribe((res: MediaItem) => {
        if (res.resource_type === MediaType.document || res.resource_type === MediaType.image) {
          this.mediaItems.push(res);
        } else {
          this.mediaItems.push({ ...item, id: res.id });
        }
        this.learningObjectiveUpdated.emit(true);
      });
    this.newMediaItem = undefined;
  }

  updateMediaItem(item: MediaItem, index?: number) {
    this.learningResourceService
      .update({ ...item, curriculum_learning_objective_id: item.curriculum_lobj_id } as any, item.id)
      // .pipe(this.updateFile(item) as any)
      .pipe(
        switchMapTo(
          this.learningResourceService.getAll({
            resource_type: this.currResourceType,
            curriculum_learning_objective_id: this.learningObjective.id
          })
        )
      )

      .subscribe((res: MediaItem[]) => {
        this.mediaItems = res;
        this.learningObjectiveUpdated.emit(true);
      });
    this.newMediaItem = undefined;
  }

  deleteMediaItem(item: MediaItem) {
    this.learningResourceService.remove(item.id).subscribe(res => {
      this.learningObjectiveUpdated.emit(true);
      this.mediaItems.splice(
        this.mediaItems.findIndex(mediaItem => mediaItem.id === item.id),
        1
      );
    });
  }

  cancelAddMedia() {
    this.newMediaItem = undefined;
  }

  setType(val: string) {
    this.currResourceType = val as MediaType;
  }

  onSaveClick() {
    this.disabledStatus = true;
    this.newMediaItem = undefined;
  }

  // TODO: check if needed?
  // tslint:disable-next-line:no-unused-variable
  private updateFile = item =>
    pipe(
      switchMap((res: any) => {
        if ((item.resource_type === MediaType.image || item.resource_type === MediaType.document) && item.file) {
          return this.learningResourceService.patchFile({ resource_type: item.resource_type, file: item.file }, res.id);
        } else {
          return of(res);
        }
      })
    );
}
