import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, map, takeUntil} from 'rxjs/operators';

import { BreadcrumbService } from 'src/app/shared/components/breadcrumb/breadcrumb.service';
import { Role } from '../../shared/role';
import { UserService } from '../../shared/user.service';
import { CourseDetailsService } from '../../teacher/services/course-details.service';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { SubjectDetailsService } from '../subject-details/subject-details.service';
import { NavigationTree } from '../subject-details/paper-details/paper-details.component';
import {StudentCumulativeTestService} from '../../student/cumulative-test/student-cumulative-test.service';
import {ETestType} from '../../teacher/teacher.enum';

@Component({
  selector: 'curr-subject-details',
  templateUrl: './subject-details-list.component.html',
  styleUrls: ['./subject-details-list.component.scss'],
  providers: [BreadcrumbService]
})
export class SubjectDetailsListComponent implements OnInit, OnDestroy {
  highlightedTopics: number[] = [];
  style: string = '-webkit-box-shadow: 0px 0px 50px 1px rgba(0, 104, 120, 0.93);'+
    '-moz-box-shadow: 0px 0px 50px 1px rgba(0, 104, 120, 0.93);' +
    'box-shadow: 0px 0px 50px 1px rgba(0, 104, 120, 0.93);';
  course;
  cards;
  subjectDetails;
  subject;
  subjectTopics;
  studentProfile;
  studentTopicTestSchoolData;
  cumulativeTests = [];
  navigationTree: NavigationTree;

  offsetStep = 10;

  lessons = [];
  lessonsOffset = 0;
  isAllLessonsFetched = false;
  isLessonsLoading = false;

  path = [];
  private unsubscribe$: Subject<void> = new Subject<void>();
  public isShow: boolean = true;

  loadQuantity = 10;
  loadOffsetCT = 0;
  isLoading = false;
  courseId = 0;
  subjectId: number;
  isLoadMoreLessons: boolean;
  isLoadMoreCumulativeTests: boolean;
  schoolYearCourseId: string;
  subjectName: string;
  papers: string[] = [];
  isDisplayed: boolean = true;

  constructor(
    private courseDetailsService: CourseDetailsService,
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private httpClient: ApplicationHttpClient,
    private subjectDetailsService: SubjectDetailsService,
    private elRef: ElementRef,
    private studentCTService: StudentCumulativeTestService
  ) {
  }

  ngOnInit() {
    localStorage.setItem('curriculumId', this.route.snapshot.params.curriculumId);
    this.breadcrumbService
      .getRoutParameter('courseId')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (param: string) => {
          this.schoolYearCourseId = param;
          this.path = [
            {
              label: 'Subjects',
              url: '/courses-details'
            },
            { label: this.route.snapshot.data.subject.subject, url: '' }
          ];
        }
      });
    this.subjectName = this.route.snapshot.data.subject.subject;
    this.subjectDetails = this.route.snapshot.data.subjectDetails;
    this.cumulativeTests = this.route.snapshot.data.cumulativeTests;

    this.subject = this.route.snapshot.data.subject;
    this.subjectTopics = this.route.snapshot.data.topicTests;
    this.studentProfile = this.route.snapshot.data.studentProfile;
    this.studentTopicTestSchoolData = this.route.snapshot.data.studentTopicTestSchoolData;
    this.navigationTree = NavigationTree.build(this.subject);
    this.getPapersTitles();
    this.subjectId = this.route.snapshot.params.courseId;
    this.isLoadMoreCumulativeTests = this.cumulativeTests?.length === this.offsetStep;
    this.lessons = this.route.snapshot.data.lessons;

    this.isLoadMoreLessons = this.lessons?.length === this.offsetStep;

    this.userService.hasRoles([Role.Teacher]).subscribe(isTeacher => {
      if (isTeacher) {
        this.cards = [];
      }
    });

    this.courseDetailsService.data.subscribe(result => {
      this.course = {
        type: 'single',
        title: this.title,
        buttons: this.buttons,
        blocks: result.blocks
      };

      if (result.title && !this.isEmpty(result.title)) {
        this.course.title.primary.value = result.title.primary.value;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  highlightTopic(value: boolean): void {
    if (this.navigationTree?.unitLevels.length && this.highlightedTopics.length) {
      this.navigationTree.unitLevels.forEach(unit => {
        unit.topicLevels.forEach(topic => {
          this.highlightedTopics.forEach(highlighted => {
            if (topic.topic.id.toString() === highlighted.toString()) {
              topic.topic.highlighted = value;
            }
          })
        })
      })
    }
  }

  onTopicTestWidgetLoad(highlightedTopics: number[]): void {
    this.highlightedTopics = highlightedTopics;
    this.highlightTopic(true);
    setTimeout(() => {
      const elements = this.elRef.nativeElement.querySelectorAll('.highlighted');
      if (elements.length) {
        window.scroll({
            left: 0,
            top: elements[0].offsetTop - elements[0].offsetHeight,
             behavior: 'smooth'
           })
      }
    }, 0)
    setTimeout(() => {
     this.highlightTopic(false)
    }, 5000);
  }

  getPapersTitles(): void {
    if (this.navigationTree?.unitLevels.length) {
      this.navigationTree.unitLevels.forEach((el) => {
        const index = this.subject.papers.findIndex(paper => paper.id === el.unit.curriculum_paper_id);
        if (index >= 0) {
          this.papers.push(this.subject.papers[index].title);
        } else {
          this.papers.push('');
        }
      })
    }
  }

  getLessons() {
    this.lessonsOffset += this.offsetStep;
    this.isLessonsLoading = true;
    this.subjectDetailsService
      .getLessonsByCourseId(this.route.snapshot.paramMap.get('courseId'), this.lessonsOffset)
      .subscribe(res => {
        if (res.length) {
          this.isLoadMoreLessons = !!res.length;
          this.lessons = [...this.lessons, ...res];
        } else {
          this.isAllLessonsFetched = true;
        }
        this.isLessonsLoading = false;
      });
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  loadMoreCt() {
    this.loadOffsetCT += this.offsetStep;
    this.isLoading = true;
    this.httpClient
      .get(
        `/studentPendingCumTests?courseId=${ this.courseId }&rowCount=${ this.loadQuantity }&rowOffset=${ this.loadOffsetCT }`
      )
      .pipe(
        map(res => {
          const r = res as any;
          if (r.data) {
            return r.data.items;
          }
          return r;
        })
      )
      .subscribe(
        resp => {
          this.isLoadMoreCumulativeTests = !!resp.length;
          this.cumulativeTests.push(...resp);
        },
        () => {},
        () => {
          this.isLoading = false;
        }
      );
  }

  baseButtons = {
    link: '/demo/story-book',
    color: 'primary',
    variant: 'contained'
  };

  buttons_1 = [
    {
      ...this.baseButtons,
      label: 'VIEW RESULT'
    },

    {
      ...this.baseButtons,
      label: 'RETAKE TEST'
    }
  ];

  buttons_2 = [
    {
      ...this.baseButtons,
      label: 'START SELF ASSESSMENT'
    }
  ];

  buttons_3 = [
    {
      ...this.baseButtons,
      label: 'TAKE THE TEST'
    },

    {
      ...this.baseButtons,
      label: 'DOWNLOAD THE TEST'
    }
  ];

  card1 = [
    {
      type: 'future',
      icon: 'icon-complete',
      name: 'Photosynthesis',
      buttons: this.buttons_1,

      hint: 'YESTERDAY',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Complete',
          icon: 'icon-success',
          isUnderline: false
        }
      ]
    }
  ];

  card2 = [
    {
      type: 'complete',
      name: 'Respiration',
      buttons: this.buttons_2,

      hint: 'TODAY',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',
          icon: 'report_problem',
          isUnderline: false
        }
      ]
    },
    {
      name: 'Stimuli, both internal and external are detected and lead to a response',
      buttons: this.buttons_2,

      hint: 'YESTERDAY',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',
          icon: 'report_problem',
          isUnderline: false
        }
      ]
    }
  ];

  card3 = [
    {
      type: 'complete',
      isToday: true,
      name: 'Cummulative Test 3',
      buttons: this.buttons_3,

      hint: 'Today! Please take the test!',

      bottomTitle: [
        {
          label: 'Time: ',
          value: '1 hr. 30 min.',
          isUnderline: false
        },
        {
          label: 'Possible attempts:',
          value: '1',
          isUnderline: false
        }
      ]
    }
  ];

  card4 = [
    {
      type: 'future',
      name: 'Nervous coordination',
      buttons: this.buttons_2,
      isIconOpacity: true,
      hint: 'TOMORROW',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',
          icon: 'report_problem',
          isUnderline: false
        }
      ]
    }
  ];

  card5 = [
    {
      type: 'future',
      name: 'Inheritance',
      isIconOpacity: true,

      buttons: this.buttons_2,

      hint: 'APRIL 9, THURSDAY',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',
          icon: 'report_problem',
          isUnderline: false
        }
      ]
    }
  ];

  card6 = [
    {
      type: 'future',
      name: 'Populations',
      buttons: this.buttons_2,
      isIconOpacity: true,

      hint: 'APRIL 10, FRIDAY',

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',

          icon: 'report_problem',
          isUnderline: false
        }
      ]
    },
    {
      name: 'Gene expression is controlled by a number of features',
      buttons: this.buttons_2,

      bottomTitle: [
        {
          label: '',
          value: 'Self Assessment Incomplete',
          icon: 'report_problem',
          isUnderline: false
        }
      ]
    }
  ];

  /* COURSE CARD*/
  title = {
    aligned: 'left',
    primary: { label: '', value: 'Biology' },
    tertiary: { label: 'Teacher: ', value: 'Ashley Bales', isUnderLine: true },

    hint: {
      label: 'Next Cumulative Test: 12 February at 9:30',
      icon: 'calendar',
      color: 'primary'
    }
  };

  buttons = [
    {
      label: 'HISTORICAL ASSESSMENT RESULTS',

      link: '/subjects/1/grade-results',
      color: 'primary',
      variant: 'contained'
    },

    {
      label: 'SAVED MATERIALS',

      link: '/learning-resources/1',
      color: 'primary',
      variant: 'contained'
    },

    {
      label: 'ALL NOTES',

      link: '/subjects/1/notes',
      color: 'primary',
      variant: 'contained'
    },
    {
      label: 'TAKE NEXT TOPIC TEST',

      link: 'subjects/1/cumulative/1',
      color: 'primary',
      variant: 'contained'
    }
  ];
}
