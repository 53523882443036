import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StudentCheckTestService } from './student-check-test.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SaQuestionsPageResolver implements Resolve<any> {

  constructor(private testService: StudentCheckTestService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const id = route.paramMap.get('saId');
    const courseId = route.paramMap.get('courseId');
    return this.testService.getSATestQuestion(+id, +courseId);
  }

}
