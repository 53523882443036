/**
 * Created by Alex Poh. on 07/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { PreviewComponent } from './preview-component';
import { Role } from '../../shared/role';

@Component({
  selector: 'curr-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class ViewSelectorComponent implements OnInit {
  components: PreviewComponent[] = [
    new PreviewComponent('Login page', 'login.png', '/sign-up', Role.Unauthorized),
    new PreviewComponent('Self assessment page', 'sa.png', '/subjects/73/self-assessment/425', Role.Student),
    new PreviewComponent('Topic test page', 'cu.png', '/subjects/11/cumulative-test/51', Role.Student),
    new PreviewComponent('Teacher page', 'teacher.png', 'subjectsUpdate/11', Role.Teacher),
    new PreviewComponent('Gantt chart', 'Gant-Chart.png', 'subjects/1/paper/1/ganttChart', Role.Teacher),
    new PreviewComponent('Curriculum Management', 'curriculum-management.png', '/curriculum-management', Role.Teacher),
    new PreviewComponent('Topic tests', 'All-Cumulative-Tests.png', '/cumulative-tests', Role.Teacher),
    new PreviewComponent('Self assessments', 'All-Self-Assessments-Tests.png', '/self-assessments', Role.Teacher),
    new PreviewComponent('Course details', 'Student-Subjects.png', '/courses-details', Role.Student),
    new PreviewComponent('Subject details', 'Student-Subject-Opened.png', '/subject-details/1', Role.Student),
    new PreviewComponent('Subjects', 'Teacher-Courses.png', '/subjects', Role.Teacher),
    new PreviewComponent('School Admin Dashboard', 'School-Admin-Dashboard.png', 'dashboard', Role.SchoolAdmin),
    new PreviewComponent('Teacher Dashboard', 'Teacher-Dashboard.png', '/dashboard', Role.Teacher)
  ];

  fakeComponents: PreviewComponent[] = [
    new PreviewComponent('Teacher Dashboard', 'Teacher-Dashboard.png', '/teacher-dashboard', Role.Teacher)
  ];

  constructor() {}

  ngOnInit(): void {}
}
