/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { MatDialog } from '@angular/material/dialog';
import { IUnderlyingFactors, IUnderlyingFactorsStatistics } from '../../interfaces/underlying-factors';

@Component({
  selector: 'curr-underlying-factors',
  templateUrl: './underlying-factors.component.html',
  styleUrls: ['./underlying-factors.component.scss']
})
export class UnderlyingFactorsComponent implements OnInit {
  @Input() set data(value: IUnderlyingFactors) {
    if (value) {
      this.receivedData = value;
      this.displayedData = value.underlyingFactorsStatistics?.slice(this.offset, this.limit);
    }
  }

  offset = 0;
  limit = 4;

  receivedData: IUnderlyingFactors;
  displayedData: IUnderlyingFactorsStatistics[];

  imageKeyValue: { [key: string]: string } = {};

  constructor(private fileUploadService: FileUploadService, private dialog: MatDialog) {}

  ngOnInit() {}

  showMore() {
    this.offset += 4;
    this.displayedData = [...this.displayedData, ...this.receivedData?.underlyingFactorsStatistics.slice(this.offset, this.limit + this.offset)];
  }
}
