/*
 * Created by Volodymyr Mak. on 20/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

export interface FormField {
    label: string,
    name: ProfileFieldsNames,
    verify?: {valid: boolean}
}

export interface ProfileCardData {
  formFieldsToDisplay: FormField[],
  userData: Student | Teacher | HubAdmin | SchoolAdmin
}

export enum ProfileFieldsNames {
  PHONE = 'phone',
  EMAIL = 'email',
  YEAR = 'year',
  PERSONAL_ID = 'personalID',
  COUNTRY = 'country',
  SCHOOL = 'school',
}

export enum UserRole {
  HUB_ADMIN = 'hubAdmin',
  SCHOOL_ADMIN = 'schoolAdmin',
  TEACHER = 'teacher',
  STUDENT = 'student'
}

export interface ProfileCard<T, M> {
  updateFormValues(data: T): void,
  createProfileDataModel(data: T): M,
  updateUserData(data: T): T
}

export interface User {
  id: string,
  role: UserRole,
  name: string,
  email: {value: string, verify: boolean},
  phone: {value: string, verify: boolean},
  personalID: string,
  avatar: string,
  isActive?: boolean,
}

export interface Student extends User {
  year: number
}

export interface SchoolAdmin extends User {
  country: string,
  school: string
}

export interface Teacher extends User {
  country: string,
  school: string
}

export interface HubAdmin extends User {
  country: string,
}
