/**
 * Created by Alex Poh. on 01/07/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { Injectable } from '@angular/core';
import { UserService } from '../../shared/user.service';
import { switchMap, take } from 'rxjs/operators';
import { CourseDetails } from './course';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CoursesService {
  constructor(private httpClient: ApplicationHttpClient, private userService: UserService) {}

  assignCourse(courseId, curriculumId) {
    return this.userService
      .getCurrentUser()
      .pipe(take(1))
      .pipe(
        switchMap(res => {
          return this.httpClient.patch(`/schoolYearCourses/${courseId}/assignTeacher`, null, {
            params: { teacherId: res?.id, schoolYearCourseId: courseId, curriculumId }
          } as any);
        })
      );
  }

  createCourse(courseId, curriculumId) {
    return this.userService
      .getCurrentUser()
      .pipe(take(1))
      .pipe(
        switchMap(res => {
          return this.httpClient.post(`/schoolYearCourses`, {
            teacher_id: res?.id,
            course_id: courseId,
            curriculum_id: curriculumId
          });
        })
      );
  }

  block_1 = {
    title: 'Average grade for the Cumulative Test:',
    hint: {},
    showProgressBar: false,
    progressBar: { color: 'accent', value: 70 }
  };
  block_2 = {
    title: 'Lessons',
    hint: {},
    bottomTitle: { label: '20/25 completed - 40%' },
    showProgressBar: true,
    progressBar: { color: 'accent', value: 40 }
  };
  block_3 = {
    title: 'Cummulative Tests',
    hint: {},
    bottomTitle: { label: `40% completed` },
    showProgressBar: true,
    progressBar: { color: 'accent', value: 40 }
  };
  hint = {
    icon: 'warn',
    label: '',
    color: 'warn',
    isUnderLine: true,
    postIcon: 'mdi-arrow-right'
  };
  block_4 = {
    title: 'Percentage of completed Self Assessments',
    bottomTitle: { label: '20/25 completed - 44%', isUnderLine: false },
    showProgressBar: true,
    progressBar: { color: 'accent', value: 44 }
  };
  // private linkDashboard = { rout: '/dashboard', fragment: 'students' };

  createCourseCards(res: any[]) {
    return res.map((course: CourseDetails) => {
      return {
        data: {
          subject: course.subject,
          curriculumId: course.curriculumId,
          level: course.studyLevel
        },
        title: {
          aligned: 'center',
          topRightIcons: [],
          secondary: { value: course.studyLevel }, // TODO: check api for this field
          primary: { label: '', value: course.subject },
          tertiary: { label: 'COURSE ID: ', value: course.course }
        },

        blocks: [
          {
            ...this.block_1,
            bottomTitle: { label: course.avgGrade || '-' },
            progressBar: { color: 'accent', value: course.avgMark }
          },

          // {
          //   ...this.block_2,
          //   bottomTitle: {
          //     label: `${course.lessPassed}/${course.lessAll} complete – ${this.roundNumber(course.lessCompleted)}%`
          //   },
          //   progressBar: { color: 'accent', value: course.lessCompleted }
          // },

          // {
          //   ...this.block_3,
          //   // title: `Students that passed latest Cummulative test ${
          //   //   course.lastCumTest ? new Date(course.lastCumTest).toLocaleDateString('en-US') : ''
          //   // }`,
          //   title: 'Tutorial Sessions',
          //   bottomTitle: {
          //     label: `${course?.tsPassed}/${course?.tsAll} complete – ${Math.round(course?.tsCompleted)}%`
          //   },
          //   progressBar: { color: 'accent', value: course.tsCompleted }
          // }

          // {
          //   ...this.block_4,
          //   bottomTitle: { label: `${this.roundNumber(course.saCompleted)}% completed` },
          //   progressBar: { color: 'accent', value: course.saCompleted },
          //   hint: {
          //     ...this.hint,
          //     label:
          //       course.saRemained !== 0
          //         ? `${course.saRemained}% of your Self Assessments not complete`
          //         : `All of your Self Assessments complete`,
          //     icon: course.saRemained !== 0 ? 'warn' : 'info',
          //     color: course.saRemained !== 0 ? 'warn' : 'accent',
          //     link: this.linkDashboard
          //   }
          // }
        ],
        buttons: [
          {
            label: 'SCHEME OF WORK',
            link: `/subjectsUpdate/${course.curriculumId}/${course.courseId}`,
            color: 'primary',
            variant: 'contained',
            courseId:course.courseId
          },
          {
            label: 'STUDENT LIST',
            link: `/course/users/${course.courseId}`,
            color: 'primary',
            variant: 'contained',
            courseId:course.courseId
          },
          {
            label: 'CUMULATIVE TESTS',
            link: `/courses/cumulative-test/${course.courseId}/${course.curriculumId}`,
            color: 'primary',
            variant: 'contained',
            courseId:course.courseId
          },
          {
            label: 'HOMEWORK',
            link: `/courses/homework/${course.courseId}/${course.curriculumId}`,
            color: 'primary',
            variant: 'contained',
            courseId:course.courseId
          },
          {
          label: 'QUIZZES',
            link: `/courses/quiz/${course.courseId}/${course.curriculumId}`,
            color: 'primary',
            variant: 'contained',
            courseId:course.courseId
          },
          // {
          //   label: 'SELF ASSESSMENT TESTS',
          //   link: `/self-assessment/${course.courseId}/${course.curriculumId}`,
          //   color: 'primary',
          //   variant: 'contained',
          //   courseId:course.courseId
          //
          // }
        ]
      };
    });
  }

  roundNumber(num: number) {
    return Math.round(num * 100) / 100;
  }

  getFilters(): Observable<any> {
    return this.httpClient.get('/filter/options/courses').pipe(map(response => (response as any).data));
  }
}
