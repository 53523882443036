<!--
 Created by Volodymyr Mak. on 12/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<input type="file" hidden (change)="fileChangeEvent($event)" #fileInput />
<button class="img-uploader" (click)="fileInput.click()">
  <mat-icon svgIcon="upload-img"></mat-icon>
</button>

<ng-template #cropImagePopup>
  <div class="mat-dialog-header border-bottom">
    <div class="crop-img-header" mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
      <div class="crop-title">Update profile picture</div>
        <mat-icon svgIcon="close" class="close-btn" matDialogClose (click)="cancelImageSaving()"></mat-icon>
    </div>
    <div mat-dialog-content class="crop-img-container">
      <image-cropper class="image-to-crop image-cropper"
          [imageFile]="imageBlob"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          roundCropper="true"
          format="png"
          [resizeToWidth]="144"
          (imageCropped)="getCroppedImg($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">     
      <curr-button variant="text" class="cancel-button" color="primary" [style]="cancelButtonStyle" size="small" (btnClick)="cancelImageSaving()">
        Cancel
      </curr-button>     
      <curr-button class="crop-btn curr-button" (btnClick)="saveImage()">Confirm</curr-button>
    </div>
  </div>
</ng-template>
