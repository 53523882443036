/*
 * Created by Volodymyr Mak. on 12/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, ViewChild, TemplateRef, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'curr-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit, OnDestroy {
  @Output() imgCropped = new EventEmitter();

  @ViewChild('cropImagePopup') cropImagePopup: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;

  cropImg: string = ''
  imageBlob: any = '';
  cancelButtonStyle = { color: '#006878', 'text-decoration': 'underline' };

  constructor(
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      // Conver upload img into base64 format.
      this.imageBlob = new Blob([file], { type: 'image/jpeg' });

      this.dialog.open(this.cropImagePopup, {
        maxHeight: '520px',
        height: '100%',
        width: '100%',
        maxWidth: '680px'
      }).afterClosed().subscribe(
        {
          next: () => {
            this.fileInput.nativeElement.value = '';
          },
          complete: () => {
            this.dialog.ngOnDestroy();
          }
        }
      )
    }
  }

  getCroppedImg(event: ImageCroppedEvent) {
    this.cropImg = event.base64;
  }

  saveImage() {
    this.imgCropped.next(this.cropImg);
    this.dialog.closeAll();
  }

  cancelImageSaving() {
  this.imgCropped.next(this.cropImg);
    this.cropImg = null;
    this.dialog.closeAll();
  }

  // TODO: remove?
  imageLoaded() {
  }

  // TODO: remove?
  cropperReady() {
  }

  // TODO: remove?
  loadImageFailed() {
  }
}
