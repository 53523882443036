<div class="choose-plan-container">
  <div class="choose-plan-header">
    <div class="choose-plan-title m-l-32 mat-h2">
      <p>Limited space available.</p>
    </div>
  </div>
  <div class="choose-plan-main m-t-16">
<!--    <div class="your-plan">-->
<!--      <div class="your-plan-inside">-->
<!--        <div class="choose-main-title m-l-16">-->
<!--          <p class="choose-main-heading mat-h2 academic-boost-header">EXAM BOOST: £1,550 (biannual).</p>-->
<!--        </div>-->
<!--        <div class="plan-description m-l-16">-->
<!--          <div class="plan-description-subtitle">Package</div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Monthly 60-minute one-to-one online live sessions with your academic mentor.-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Fortnightly 30-minute one-to-one online live sessions with your academic mentor.-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Personalised exam board specific topic tests with feedback provided during live one-to-ones (may be delivered on or off curriculum.com dependent on year and subject).-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Regular quizzes (may be delivered on or off curriculum.com dependent on year and subject).-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="your-plan">-->
<!--      <div class="your-plan-inside">-->
<!--        <div class="choose-main-title m-l-16">-->
<!--          <p class="choose-main-heading mat-h2">Uni-Prep Boost (Suitable for students aged 12-19): £2,950 (biannual).-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="title-recommendation">-->
<!--          We highly recommend choosing EXAM BOOST alongside UNI-PREP BOOST (Reading Club). It is key to the success-->
<!--          of UNI-PREP BOOST that we are beside you providing academic-->
<!--          support for your most important subjects.-->
<!--        </div>-->
<!--        <div class="plan-description m-l-16">-->
<!--          <div class="plan-description-subtitle">Package</div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Six one-to-one counselling sessions with our Career experts to help you deepen your interest and knowledge-->
<!--              of your chosen career pathway.-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Personal reading plan based on your capability, passions, interests and university ambitions, guided by-->
<!--              your Career Counsellor.-->
<!--            </p>-->
<!--            &lt;!&ndash;          <span&ndash;&gt;-->
<!--            &lt;!&ndash;            data-html="true"&ndash;&gt;-->
<!--            &lt;!&ndash;            data-tooltip="Exam preparation session&ndash;&gt;-->
<!--            &lt;!&ndash;            Focuses on outcomes from the week's assessment&ndash;&gt;-->
<!--            &lt;!&ndash;            and exam preparation strategy">&ndash;&gt;-->
<!--            &lt;!&ndash;            <mat-icon class="mat-info hover-cursor" [svgIcon]="'info-new'"></mat-icon>&ndash;&gt;-->
<!--            &lt;!&ndash;          </span>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Programme of career specific exercises, competitions, tasks and challenges set by your Career Counsellor-->
<!--              to develop your x-factor!-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="plan-description-item">-->
<!--            <mat-icon class="icon-check" [svgIcon]="'check'"></mat-icon>-->
<!--            <p class="plan-description-name mat-body-1-m">-->
<!--              Progress reporting and support via written/audio feedback at the end of each task set by your Counsellor-->
<!--              during the programme.-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="your-plan">
      <div class="your-plan-inside">
        <div class="btn-container">
          <button (click)="openChooseYearPopUp()" class="subscribe-btn-title" mat-button matStepperNext>Continue
          </button>
        </div>
        <div class="secure-plan-box plan-description">
          <p>
            <span>Get access to Curriculum.com for FREE!</span> Upon sign up, you will be provided with a unique link.
            For every
            student that you or a family member successfully refers using the link, your account will be credited £250.
            This credit will be provided in the form of “cash back” upon your successful completion of the program. A
            successful referral requires both yourself and the referred student to complete the program. This is a
            limited time offer running until December 31, 2022. We reserve the right to end the referral program early
            as spaces are limited.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
