/**
 * Created by Maxim B. on 21/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatisticTable } from '../statistic-table';
import { Teacher } from '../teacher';
import { CommonFields } from '../common-fields';
import { Student } from '../student';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'curr-table-constructor',
  templateUrl: './table-constructor.component.html',
  styleUrls: ['./table-constructor.component.scss']
})
export class TableConstructorComponent implements OnInit {

  @Input() table: StatisticTable;
  @Output() saveTable = new EventEmitter<StatisticTable>();
  @Output() cancelCreateEmitter = new EventEmitter<StatisticTable>();

  typeOfUsersOpt = [
    { value: 'all', label: 'Students & Teachers' },
    { value: 'teachers', label: 'Teachers' },
    { value: 'students', label: 'Students' }
  ];
  tableFieldsOptions = [];


  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    if (!this.table) {
      this.table = new StatisticTable();
    }
    this.setupFieldsOption('all');
  }

  deleteColumn(i: number) {
    this.table.fields.splice(i, 1);
  }

  createNewField() {
    const firstUnselectedItem = this.getFirstUnselectedOption();
    this.table.fields.push({ fieldName: firstUnselectedItem.value, fieldFilter: 'all' });
  }

  createNewTable() {
    this.saveTable.emit(this.table);
  }

  cancelCreate() {
    this.cancelCreateEmitter.emit(this.table);
  }

  setupFieldsOption(event: any | []) {
    if (!this.table.fields) {
      this.table.fields = [];
    }
    let keys = [];
    if (event === 'all') {
      keys = Object.keys(new CommonFields());
    }
    if (event === 'teachers') {
      keys = Object.keys(new Teacher());

    }
    if (event === 'students') {
      keys = Object.keys(new Student());

    }
    this.tableFieldsOptions = [];
    keys.forEach(i => {
      this.translateService.get(`dropdownItemLabels.${ i }`).subscribe(tr => {
        this.tableFieldsOptions.push({ value: i, label: tr });
      });
    });
  }

  setTableField(event: any | [], i: number) {
    const itms = this.table.fields.filter(f => f.fieldName === event);
    if (itms.length === 0) {
      this.table.fields[i].fieldName = event;
    } else {
      const firstUnselectedItem = this.getFirstUnselectedOption();
      this.table.fields[i] = { fieldName: firstUnselectedItem.value, fieldFilter: 'all' };
    }
  }


  getFirstUnselectedOption() {
    let res: any = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.tableFieldsOptions.length; i++) {
      const opt = this.tableFieldsOptions[i];
      const itms = this.table.fields.filter(f => f.fieldName === opt.value);
      if (itms.length === 0) {
        res = opt;
        break;
      }
    }
    return res;

  }
}
