/*
 * Created by Dmytro Sav. on 5/11/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';

interface Options {
  value: string;
  label: string;
}

@Component({
  selector: 'curr-edit-teacher-page',
  templateUrl: './edit-teacher-page.component.html',
  styleUrls: ['./edit-teacher-page.component.scss']
})
export class EditTeacherPageComponent implements OnInit {
  userInfo = {
    name: 'Andrew',
    surname: 'Sims',
    id: '43437474647254',
    email: 'example@gmail.com',
    phoneNumber: '+1 234 567 89',
    country: '1'
  };

  sub1 = {
    name: 'Astronomy',
    courses: [
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' }
    ]
  };
  sub2 = {
    name: 'Astronomy',
    courses: [
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' },
      { courseId: '#4325235', form: '1', year: 10, avg: 'A' }
    ]
  };
  sidebarData = [this.sub1, this.sub2];

  courseOptions = [
    { value: '1', label: '# 24235 Algebra ' },
    { value: '2', label: '# 45346 Biology' },
    { value: '3', label: '# 6456464 Drawing' },
    { value: '4', label: '# 4545845 Chemistry' },
    { value: '5', label: '# 5456456 Geography' }
  ];

  yearsOptions = [
    { value: '1', label: 'Year_1' },
    { value: '2', label: 'Year_2' },
    { value: '3', label: 'Year_3' },
    { value: '4', label: 'Year_4' }
  ];

  formOptions = [
    { value: '1', label: 'Form_1' },
    { value: '2', label: 'Form_2' },
    { value: '3', label: 'Form_3' },
    { value: '4', label: 'Form_4' }
  ];

  gradeOptions = [
    { value: '1', label: 'A' },
    { value: '2', label: 'B' },
    { value: '3', label: 'C' },
    { value: '4', label: 'D' }
  ];

  subjects = [
    {
      course: { value: '3', label: '# 6456464 Drawing' },
      name: 'Drawing',
      year: { value: '2', label: 'Year_2' },
      form: { value: '2', label: 'Form_2' },
      grade: [
        { value: '1', label: 'A' },
        { value: '2', label: 'B' },
        { value: '3', label: 'C' }
      ]
    },
    {
      course: { value: '1', label: '# 24235 Astronomy' },
      name: 'Astronomy',
      year: { value: '1', label: 'Year_1' },
      form: { value: '1', label: 'Form_1' },
      grade: [
        { value: '1', label: 'A' },
        { value: '2', label: 'B' },
        { value: '3', label: 'C' }
      ]
    }
  ];

  findByIndex(index, obj: Options[]) {
    return obj.filter(option => option.value === index)[0];
  }

  findByIndexes(indexes: [], obj: Options[]) {
    const found = [];
    for (const index of indexes) {
      found.push(obj.filter(option => option.value === index)[0]);
    }

    return found;
  }

  getSelectedValues(event) {
    const course = this.findByIndex(event.selectedCourse, this.courseOptions);
    const year = this.findByIndex(event.selectedYear, this.yearsOptions);
    const form = this.findByIndex(event.selectedForm, this.formOptions);
    const grade = this.findByIndexes(event.selectedGrade, this.gradeOptions);

    this.subjects.push({
      course,
      year,
      form,
      grade,
      name: 'Name of subject'
    });
  }

  constructor() {}

  ngOnInit() {}
}
