<div class="learning_wrapper">
  <div class="learning_heading">
    <div class="learning_heading-title mat-h1">
      Cell structure and organisation
    </div>
    <curr-breadcrumb position="start" class="m-b-xs-16" [path]="path"></curr-breadcrumb>
  </div>
  <div class="learning-objective_container">
    <div class="learning-objective_main__container">
      <div class="learning-objective_main__title mat-h3">
        Recommended text book readings and exercises
      </div>
      <div class="learning-objective_main__description">
        <p class="mat-body-1 m-b-0 m-t-24">
          WJEC Biology Student Guide 1: Unit 1: Basic biochemistry and cell organisation - Unit 1
        </p>
        <span class="learning-objective_main__description-span">Foulder, Dan,  Gray, Melanie</span>
        <p class="mat-body-1  m-b-0 m-t-16">
          WJEC Biology Student Guide 1: Unit 1: Basic biochemistry and cell organisation - Unit 1
        </p>
        <span class="learning-objective_main__description-span">Foulder, Dan,  Gray, Melanie</span>
      </div>
    </div>
    <div class="learning-objective_main__container m-t-24">
      <div class="learning-objective_main__title mat-h3">
        Cell structure and organisation
      </div>
      <div class="learning-objective_main__description">
        <p class="mat-body-1 m-b-0 m-t-24 text-description-align">
          All organisms are composed of cells. The cell theory is a unifying concept in biology.
          The theory states that new cells are formed from other existing cells, and that the cell is
          a fundamental unit of structure, function and organisation in all living organisms.
          The understanding of the ultrastructure of cells has been developed through advances in microscopy.
        </p>
      </div>
      <div class="learning-objective_main__video">
        <curr-upcoming-lessons-videos [mediaArr]="[{url: 'https://www.youtube.com/watch?v=URUJD5NEXC8'}]"></curr-upcoming-lessons-videos>
      </div>
    <curr-button class="btn-start-sa" size="big">Start Self-Assessment</curr-button>
    </div>
  </div>
</div>
