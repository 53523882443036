<circle-progress
  [percent]="percent"
  [maxPercent]="maxPercent"
  [radius]="radius"
  [outerStrokeWidth]="outerStrokeWidth"
  [outerStrokeColor]="outerStrokeColor"
  [backgroundColor]="backgroundColor"
  [titleColor]="titleColor"
  [titleFontSize]="titleFontSize"
  [toFixed]="toFixed"
  [animation]="animation"
  [animationDuration]="animationDuration"
  [outerStrokeLinecap]="outerStrokeLinecap"
  [backgroundPadding]="backgroundPadding"
  [responsive]="true"
  [showInnerStroke]="false"
  [showSubtitle]="false"
  [showUnits]="false">
</circle-progress>
