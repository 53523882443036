/**
 * Created by Maxim B. on 06/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CustomerPlate } from './customer-plate';

@Component({
  selector: 'curr-customer-plate',
  templateUrl: './customer-plate.component.html',
  styleUrls: ['./customer-plate.component.scss']
})
export class CustomerPlateComponent implements OnInit {

  @Input() customer: CustomerPlate;

  constructor() { }

  ngOnInit(): void {
  }

}
