import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectInformationService {
  public selectedSubject$: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor() { }

  get selectedSubject(): BehaviorSubject<number> {
    return this.selectedSubject$;
  }

  setSubject(id: number): void {
    this.selectedSubject$.next(id);
  }
}
