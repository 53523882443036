/**
 * Created by Maxim B. on 01/04/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, ViewChild, ViewContainerRef,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';

@Component({
  selector: 'curr-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})

/**
 * Usage example:
 *  <curr-input
 *    fxFlex
 *    [(value)]="val"
 *    placeholder="User Name"
 *    userHint="Enter Name"
 *    [isBordered]="true"
 *    prefix="movie_creation"
 *    inputType="string"
 *    [formGroup]="form"
 *    [isGreyBackground]="true"
 *    numberInputWidth="100"
 *    controlName="name"
 *    errorMessage="Enter correct name"></curr-input>
 *  WARN you can use or VALUE or FORMGROUP and CONTROLNAME. not Both at once
 *   prefix: string = icon from angular material
 *   userHint: text under input
 *   inputType: string or password (to make eye icon visible) or number
 *   [isBordered]="true" to add border near input
 *   [isGreyBackground]="true" to add grey background to input !!! DO NOT USE WITH [isBordered]="true"
 *   numberInputWidth="100" in px allowed 50, 100, 200
 */
export class InputComponent implements OnInit, OnChanges {
  readonly INPUT_TYPES = {
    string: 'string',
    password: 'password',
    number: 'number'
  };
  @ViewChild('customInput', { read: ViewContainerRef, static: true }) public customInput: ViewContainerRef;
  @Input() placeholder = '';
  @Input() value: string | number;
  @Input() userHint = '';
  @Input() controlName: string;
  @Input() errorMessage = '';
  @Input() prefix = '';
  @Input() maxlength = '';
  @Input() inputType = 'string';
  @Input() numberInputWidth = '100';
  @Input() isBordered = true;
  @Input() isGreyBackground = false;
  @Input() isDisabled = false;
  @Input() formGroup: FormGroup;
  @Input() isCentered?: boolean;
  @Input() isRequired?: boolean;
  @Input() suffix?: string;
  @Input() isSuffixDisabled: boolean = false;
  @Output() valueChange = new EventEmitter<string | number>();
  @Output() onInputBlur = new EventEmitter<string | number>();
  @Output() onSuffixClick = new EventEmitter<void>();

  constructor(
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.controlName = 'item' + Date.now() + Math.random();
      this.formGroup = this.fb.group({
        [this.controlName]: new FormControl({ value: this.value, disabled: this.isDisabled })
      });
    }

    // Set validation rules
    if (this.isRequired) {
      this.formGroup.controls[this.controlName].setValidators([Validators.required]);
    }

    if (this.inputType === this.INPUT_TYPES.number) {
      this.isGreyBackground = false;
      this.isBordered = true;
      this.prefix = '';
      this.userHint = '';
    }
  }

  updateValue() {
    if (this.formGroup.controls[this.controlName]) {
      const val = this.formGroup.controls[this.controlName].value;
      this.valueChange.emit(val);
    }
  }

  ngOnChanges(): void {
    if (this.formGroup && this.value?.toString().length) {
      this.formGroup.controls[this.controlName].setValue(this.value);
    }
    if (this.formGroup) {
      if (this.isDisabled) {
        this.formGroup.controls[this.controlName].disable();
      } else {
        this.formGroup.controls[this.controlName].enable();
      }
    }
  }

  onLostFocus() {
    if (this.formGroup.controls[this.controlName]) {
      const val = this.formGroup.controls[this.controlName].value;
      this.onInputBlur.emit(val);
    }
  }

  suffixClicked(event): void {
    if (this.isSuffixDisabled) {
      return;
    }
    event.stopPropagation();
    this.onSuffixClick.emit();
  }

  focusInput(): void {
    this.customInput.element.nativeElement.focus();
  }
}
