<article class="personal-data" fxLayout="column" fxLayoutAlign="stretch start">
  <div class="title-wrapper" fxFlex fxLayout="row" fxLayoutAlign="space-between center" [@fade]>
    <div class="profile-title">Personal Data</div>
    <!-- <curr-button *ngIf="!editPersonalData && (curUser.role === profileData.user_type)"
        (btnClick)="toggleEditpersonalDataMode(true)" icon="edit" [style]="{border: 'none'}" size="small"
        variant="outlined">
      </curr-button> -->
    <mat-icon
      *ngIf="!editPersonalData"
      class="edit-icon"
      svgIcon="edit"
      (click)="toggleEditpersonalDataMode(true)"
    ></mat-icon>
  </div>

  <section class="user-info" *ngIf="!editPersonalData; else editPersonal" [@fade]>
    <div class="info-item">
      <div class="title">
        Gender
      </div>
      <div class="text">
        <span *ngIf="true">{{ profileData?.gender | gender | titlecase }}</span>
      </div>
    </div>
    <div class="info-item">
      <div class="title" fxLayout="row" fxLayoutAlign="stretch center">
        DOB
      </div>
      <div class="text">
        <span *ngIf="true">{{ profileData?.dateOfBirth }}</span>
      </div>
    </div>
    <div class="info-item">
      <div class="title" fxLayout="row" fxLayoutAlign="stretch center">
        Country
      </div>
      <div class="text">
        <span *ngIf="true">{{ profileData?.country }}</span>
      </div>
    </div>
    <div class="info-item">
      <div class="title" fxLayout="row" fxLayoutAlign="stretch center">
        School
      </div>
      <div class="text">
        <span *ngIf="true">{{ profileData?.attendingSchool }}</span>
      </div>
    </div>
  </section>
</article>

<ng-template #editPersonal  [@fade]>
  <div class="user-info">
    <div class="text">
        <curr-dropdown
        fxFlex="250px"
        class="m-r-16"
        [selectedValue]="profileData.gender"
        [options]="genders"
        placeholder="Gender"
        (valueChange)="setGender($event)"
      ></curr-dropdown>
      <!-- <curr-input class="personal-input" [(value)]="profileData.gender" placeholder="Gender"></curr-input> -->
    </div>
    <div class="text">
      <div class="birth-date" fxLayout="row" fxFlex fxLayoutAlign="space-between center" fxLayoutGap="16">
        <input
          #dateInput
          class="deadline-date button cursor-pointer"
          (dateTimeChange)="saveTask(dt1)"
          [owlDateTime]="dt1"
          [max]="max"
          [placeholder]="profileData?.dateOfBirth || dateFormat"
          onkeypress="return false;"
          [value]="profileData?.dateOfBirth | date: dateFormat"
        />

        <owl-date-time [pickerType]="'calendar'" #dt1></owl-date-time>
        <span fxFlex fxLayoutAlign="end center" class="trigger" [owlDateTimeTrigger]="dt1">
          <mat-icon color="primary" svgIcon="calendar" class="cursor-pointer"></mat-icon>
        </span>
      </div>
    </div>
    <div class="text">
      <curr-dropdown
        fxFlex="250px"
        class="m-r-16"
        [selectedValue]="profileData.country"
        [options]="countries"
        placeholder="Country"
        (valueChange)="setCountry($event)"
      ></curr-dropdown>

      <!-- <curr-input [(value)]="profileData.country" placeholder="Country"></curr-input> -->
    </div>
    <div class="text">
      <curr-input [(value)]="profileData.attendingSchool" inputType="Text" placeholder="School"></curr-input>
    </div>
    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
      <curr-button variant="text" [style]="cancelButtonStyle" color="primary" size="small" (btnClick)="cancelChanges()">
        CANCEL
      </curr-button>
      <curr-button class="save-btn profile-btn" (btnClick)="updateUserProfile()">
        SAVE
      </curr-button>
    </div>
  </div>
</ng-template>
