/**
 * Created by Maxim Bon. on 03/07/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { RestApiService, restApiServiceCreator } from '../../shared/api/rest-api.service';
import { CumulativeTest } from './cumulative-test';
import { ETestType } from '../../teacher/teacher.enum';

@Injectable({
  providedIn: 'root'
})
export class TestCreationService {
  cTestService: RestApiService<CumulativeTest>;

  constructor(private httpClient: ApplicationHttpClient) {
    this.cTestService = restApiServiceCreator<CumulativeTest>('curriculumCumTests')(this.httpClient);
  }

  generateTestQuestions(topicIds: any[], testType: ETestType) {
    return this.httpClient.get(`/generateBaseCurriculumCTQuestions?testType=${testType}&curriculum_learning_objective_ids=${topicIds}`);
  }

  addQuestionsFromDataBase(topicIds: any[], questionIds: any[], questionType: string) {
    return this.httpClient.get(
      `/curriculumLearningObjectiveCTQuestions/questions?learningObjectiveIds=${topicIds}&questionIds=${questionIds}&questionType=${questionType}`
    );
  }

  getMasterTopicQuestions(topicIds: any[]) {
    return this.httpClient.get(`/curriculumLearningObjectiveCTQuestions/${topicIds}/questions`);
  }

  saveCumulativeTest(test: CumulativeTest) {
    return this.cTestService.add(test);
  }

  saveOfflineCumulativeTest(test: CumulativeTest) {
    return this.httpClient.post(`/curriculumCumTests/offline`, test);
  }

  updateCumulativeTest(test: CumulativeTest) {
    return this.httpClient.put('/curriculumCumTests', test);
  }

  updateCumulativeTestEstimationType(questionIds: any[], markType: 'AI_MARKED' | 'SELF_MARKED') {
    return this.httpClient.put(
      `/curriculumLearningObjectiveCTQuestions/estimation?questionIds=${questionIds}&markType=${markType}`, {});
  }
}
