/**
 * Created by Alex Poh. on 07/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Role } from '../../shared/role';

export class PreviewComponent {
  constructor(public title: string, public image: string, public link: string, public role: Role) {}
}
