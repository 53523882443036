/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../../shared/role';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ManageUsersResolver implements Resolve<any> {

  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return [
      {
        name: 'Michael Volkov',
        id: '336',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michael Volkov', id: '337', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '338', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      {
        name: 'Michael Deactivated',
        id: '339',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michae Volkovl', id: '331', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      {
        name: 'Michael Volkov',
        id: '332',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michael Volkov', id: '333', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '334', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '335', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '355', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '333', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '322', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      {
        name: 'Michael Volkov',
        id: '22',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michael Volkov', id: '33337', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '334568', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      {
        name: 'Michael Volkov',
        id: '3et39',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michae Volkovl', id: '333451', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      {
        name: 'Michael Volkov',
        id: '33132',
        deactivated: true,
        subject: 'Biology',
        role: Role.Teacher,
        status: 'Lead',
        img: ''
      },
      { name: 'Michael Volkov', id: '375633', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '335674', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '345635', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '355', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '333', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' },
      { name: 'Michael Volkov', id: '322', subject: 'Biology', role: Role.Teacher, status: 'Lead', img: '' }
    ];


    // return this.httpClient.get(`/teachers?limit=100&offset=0`,{});
  }

}
