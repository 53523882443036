/**
 * Created by Alex Poh. on 30/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';

import { Breadcrumb } from './breadcrumb';

@Component({
  selector: 'curr-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  breadcrumb: Breadcrumb[] = [];

  @Input()
  position: 'start' | 'center' | 'end' = 'center';
  @Input()
  path: Breadcrumb[];

  params: { [key: string]: any };
  symbol = 'breadcrumb-arrow';

  constructor() {
  }

  ngOnInit() {
    this.breadcrumb = this.path;
  }
}
