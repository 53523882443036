/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { QuestionType } from '../../../student/test.model';

@Component({
  selector: 'curr-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: ['./students-list.component.scss']
})
export class StudentsListComponent implements OnInit {

  isResultImage: boolean = false;
  qt = QuestionType;

  @Input() studentData: any;
  @Input() isFirst = false;

  reviewedStudentQuestions = 0;
  questionsAmount = 0;

  constructor() {}

  ngOnInit() {
    this.checkReviewedQuestions();
  }

  checkReviewedQuestions() {
    this.studentData.questionsWithAnswers.forEach(item => {
      const isPlus = item.answers && item.answers[this.studentData.key].reviewState === 'APPROVED';
      if (isPlus) this.reviewedStudentQuestions++;
      if (item.children.length === 0) this.questionsAmount++;

      if (item.children.length > 0) {
        item.children.forEach(itemSecond => {
          const isPlusSecond = itemSecond.answers && itemSecond.answers[this.studentData.key].reviewState === 'APPROVED';
          if (isPlusSecond) this.reviewedStudentQuestions++;
          if (itemSecond.children.length === 0) this.questionsAmount++;

          if (itemSecond.children.length > 0) {
            itemSecond.children.forEach(itemThird => {
              const isPlusThird = itemThird.answers && itemThird.answers[this.studentData.key].reviewState === 'APPROVED';
              if (isPlusThird) this.reviewedStudentQuestions++;
              if (itemThird.children.length === 0) this.questionsAmount++;
            });
          }
        });
      }
    });
  }
}
