<!-- Created by Maxim Bon. on 15/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="block" *ngIf="question.answer_type === questionType.MULTIPART">
  <curr-multistage-question-form-block [rootQuestion]="question" [isCTQuestion]="isCTQuestion" [inSowPage]="inSowPage"
    [cou]="cou"></curr-multistage-question-form-block>
</div>


<div class="block" *ngIf="question.answer_type != questionType.MULTIPART">
  <curr-question-form-question-block [question]="question" [inSowPage]="inSowPage"
    (saveQuestionData)="saveQuestion($event);upd($event)"></curr-question-form-question-block>

</div>

<div class="block" *ngIf="question.answer_type != questionType.MULTIPART">
  <div class="block-header m-b-8" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="header mat-h4 m-b-0" fxFlex>Answer:</span>
    <div class="" fxLayout="row" fxLayoutAlign="end center">
      <curr-icon-button icon="edit" *ngIf="!isEditQuestionMode" (click)="editQuestion('isEditQuestionMode')">
      </curr-icon-button>
    </div>
  </div>
  <div class="block-content">
    <!--    VIEW MODE-->
    <div *ngIf="!isEditQuestionMode">
      <div *ngIf="isOpenAnswer()" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let option of question?.answer_options; let i = index">
          <mat-icon [class.correct-circle]="isAnswerCorrect(i)"
            [svgIcon]="!isAnswerCorrect(i) ? 'lens-outlined' : 'lens-filled'" class="circle-question m-r-8"></mat-icon>
          <span [class.correct-label]="isAnswerCorrect(i)" [innerHTML]="option"></span>
        </div>
        <div *ngIf="!question?.answer_options?.length">
          No setup
        </div>
      </div>
      <div *ngIf="!isOpenAnswer()">Answer type - {{ getQuestionTypeLabel() }}</div>
      <div *ngIf="questionType.OPEN_ENDED == question.answer_type">
        <ng-container *ngTemplateOutlet="keywordsTable"></ng-container>
      </div>
    </div>

    <!--    EDIT MODE-->
    <div *ngIf="isEditQuestionMode" fxLayout="column" fxLayoutAlign="start start" class="full-width">
      <curr-question-form-answer-block [question]="question" [isCTQuestion]="isCTQuestion"
        [isEditQuestionMode]="isEditQuestionMode" [checkBoxCorrectAnswers]="checkBoxCorrectAnswers"
        [inSowPage]="inSowPage" [isQuestionOptionsTinyEditor]="isQuestionOptionsTinyEditor"
        (closeEditMode)="cancelEdit($event)" (saveQuestionData)="saveQuestion($event);upd($event)" class="full-width">
      </curr-question-form-answer-block>

      <div class="m-t-16 full-width" fxLayout="column" *ngIf="!isCTQuestion">
        <span class="header mat-h4 m-b-0" fxFlex>Hint:</span>
        <!--        <curr-input class="dp-width"></curr-input>-->
        <curr-tiny-editor
          [options]="{ height: 250, placeholder: 'Hint' }" (valueChange)="changeHint($event)" (onLoaded)="changeHint($event)" [value]="hint" (onFocusOut)="updateValue($event)"></curr-tiny-editor>
      </div>

      <div class="m-t-16 full-width" fxLayout="column" *ngIf="!isCTQuestion">
        <span class="header mat-h4 m-b-0" fxFlex>Explanation:</span>
<!--        <curr-input (valueChange)="setVideoUrl($event)" class="dp-width" [formGroup]="mediaFormGroup"-->
<!--          controlName="video_url" errorMessage="ex. https://youtube.com/video/uid">-->
<!--        </curr-input>-->
        <curr-tiny-editor
          [options]="{ height: 250, placeholder: 'Explanation' }" (onFocusOut)="setVideoUrlFocusOut($event)" (onLoaded)="setVideoUrl($event)" (valueChange)="setVideoUrl($event)" [value]="explanation"></curr-tiny-editor>
      </div>
    </div>

  </div>
</div>

<div class="block">
  <div class="block-header" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="header mat-h4 m-b-0" fxFlex *ngIf="isCTQuestion"></span>
    <span class="header mat-h4 m-b-0" fxFlex *ngIf="!isCTQuestion">
      Confirmation of understanding{{ isCTQuestion ? '' : ' right' }}:
    </span>
    <curr-icon-button icon="edit" *ngIf="!isEditCOUMode" (click)="editQuestion('isEditCOUMode')"></curr-icon-button>
  </div>
  <div class="block-content">
    <div *ngIf="!isEditCOUMode" fxLayout="column" fxLayoutAlign="start start">
      <span class="mat-body-1 m-b-24">{{ getCouLabel(question?.cou_right_id) }}</span>
      <span class="header mat-h4 m-b-0" fxFlex>Topic covered:</span>
      <span class="mat-body-1 m-b-24" [currMath]="getTopicLabel(question?.curriculum_lobj_id)"></span>
      <span class="header mat-h4 m-b-0" fxFlex>Level:</span>
      <span class="mat-body-1 m-b-0">{{ getLevelLabel(question?.difficulty_level) }}</span>
    </div>
    <div *ngIf="isEditCOUMode" fxLayout="column" fxLayoutAlign="start start" class="full-width">
      <curr-dropdown (valueChange)="selectCou($event)" class="m-b-24 dp-width" placeholder="Select Cou"
        [options]="ddCOUQuestions | couRight: true" *ngIf="!isCTQuestion" [selectedValue]="question?.cou_right_id">
      </curr-dropdown>

      <ng-container *ngIf="!isCTQuestion">
        <span class="header mat-h4 m-b-0" fxFlex>Confirmation of understanding wrong:</span>
        <curr-dropdown (valueChange)="selectWrongCou($event)" class="m-b-24 dp-width" placeholder="Select Cou"
          [options]="ddCOUQuestions | couRight:false" [selectedValue]="question?.cou_wrong_id"></curr-dropdown>
      </ng-container>

      <span class="header mat-h4 m-b-0" fxFlex>Topic covered:</span>
      <curr-dropdown (valueChange)="selectTopic($event)" class="m-b-24 dp-width" placeholder="Select Topic"
        [options]="ddTopics" [selectedValue]="question?.curriculum_lobj_id"></curr-dropdown>
<!--      <span class="header mat-h4 m-b-0" fxFlex>Level:</span>-->
<!--      <curr-dropdown (valueChange)="selectLevel($event)" class="m-b-24 dp-width" placeholder="Select Level"-->
<!--        [options]="ddLevels" [selectedValue]="question?.difficulty_level"></curr-dropdown>-->




      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="m-t-32" *ngIf="!inSowPage">
        <curr-button variant="contained" (btnClick)="saveQuestion('isEditCOUMode')">SAVE</curr-button>
        <curr-button variant="text" (btnClick)="cancelEdit('isEditCOUMode')">CANCEL</curr-button>
      </div>


    </div>

  </div>
</div>



<div fxLayout="row" fxLayoutAlign="start center" class="m-t-24 m-l-8" *ngIf="isCTQuestion">
    <span class="mat-h4 m-b-0 m-r-8" *ngIf="question.answer_type !== questionType.MULTIPART">Marks:</span>
  <curr-input inputType="number" [isCentered]="true" *ngIf="question.answer_type !== questionType.MULTIPART" class="m-r-24 number-input curr-input " fxFlex="55px"
    [value]="question?.mark" (valueChange)="setMark($event)"></curr-input>
  <span class="mat-h4 m-b-0  m-r-8">Answer time:</span>
  <curr-input inputType="number" [isCentered]="true" class="number-input" fxFlex="55px"
    [value]="question?.estimated_time" (valueChange)="setAnswerTime($event)"></curr-input>
  <span class="mat-h4 m-b-0  m-l-8">min.</span>
</div>

<ng-template #keywordsTable>
  <div fxLayout="column" fxLayoutAlign="start start" class="m-t-24">
    <div class="mat-h4 m-b-8">
      Keywords:
    </div>
    <curr-table [inEditMode]="isEditQuestionMode" [tableData]="question.oeq_keywords"
      (onKeywordsUpdate)="updateKeyword($event)"></curr-table>
  </div>
</ng-template>
