/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  selector: 'curr-create-edit-about-paper',
  templateUrl: './create-edit-about-paper.component.html',
  styleUrls: ['./create-edit-about-paper.component.scss']
})
export class CreateEditAboutPaperComponent implements OnInit {

  @Input() inputData!: any;

  private readonly _onOutputData = new Subject<{ data: any; shouldClose: boolean, confirmDialog: boolean }>();
  public onOutputData = this._onOutputData.asObservable();

  constructor(
    private fb: FormBuilder
  ) {}

  form: FormGroup;
  aboutText: string;

  ngOnInit(): void {
    this.form = this.fb.group({
      aboutName: [this.inputData.data.oldAboutName ?? '', [Validators.required, Validators.maxLength(100)]],
      aboutText: [this.inputData.data.oldAboutText ?? '', [Validators.required]],
    });
  }

  save(): void {
    this._onOutputData.next({
      data: {
        aboutName: this.form.get('aboutName').value,
        aboutText: this.form.get('aboutText').value
      },
      shouldClose: true,
      confirmDialog: false
    });
  }

  cancel(): void {
    this._onOutputData.next({ data: {}, shouldClose: true, confirmDialog: true });
  }
}
