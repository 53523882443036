/**
 * Created by Dmytro Sav. on 4/8/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UploadFilesService } from './upload-files.service';
import { FileUploadModel, fileInit } from './file-model';

import { WebcamImage } from 'ngx-webcam';
import { IgxToastComponent } from 'igniteui-angular';

/*
Example usage
<curr-upload-files
  title="Drop image here"
  [formats]="['.doc', '.docx', 'image/jpeg', 'image/png']">
  </curr-upload-files>

  title :string
  [formats] : Array<string>
  hint? : string (text aligned top-left corner)
  type : string = 'edit-resource' | 'answer-question'

*/
@Component({
  selector: 'curr-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  providers: [UploadFilesService]
})
export class UploadFilesComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('toast') private toast: IgxToastComponent;

  @Input() title?: string = 'Drop file with your answer here';
  @Input() hint?: string = 'Your answer';
  @Input() formats? = ['.pdf', '.doc', '.docx', 'image/jpeg', 'image/png', 'xlsx'];
  @Input() type? = 'answer-question';
  @Input() displayPhotoBtn: boolean = true;
  @Input() automaticallyImport: boolean = true;
  @Output() onloadedFile: EventEmitter<any> = new EventEmitter();
  @Input() enableProgressbard: boolean = true;
  @Input() isMultiple: boolean = false;

  files: Array<FileUploadModel> = [];
  pureFilesList: File[] = [];

  public webcamImage: WebcamImage = null;

  supported = [];
  color = 'accent';
  isShowedCamera = false;
  supportedConverted;

  notSupportedMessage = '';

  constructor(private uploadFilesService: UploadFilesService) {
  }

  ngOnInit() {
    this.supported = [...this.formats];
    this.supportedConverted = this.uploadFilesService.convertSupported(this.supported);
    this.notSupportedMessage =
      'This file format is not supported. Please select the correct file format: ' + this.supportedConverted;
  }

  public toastPosition: any;

  marked() {
    this.files.forEach(file => {
      file.marked = true;
    });
  }

  getFileType(fileName: string) {
    const dotLastPosition = fileName.lastIndexOf('.');
    return fileName.slice(dotLastPosition);
  }

  onClick(droppedEvent?) {
    // this.files = [];
    this.marked();
    const fileUpload = this.fileUpload.nativeElement;

    if (!droppedEvent) {
      fileUpload.onchange = () => {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < fileUpload.files.length; index++) {
          const file = fileUpload.files[index];
          if (this.isMultiple) {
            this.files.push({ data: file, ...fileInit });
            this.pureFilesList.push(file);
          } else {
            this.files = [{ data: file, ...fileInit }];
            this.pureFilesList = [file];
          }
        }
        if (this.automaticallyImport) {
          this.uploadFilesService.uploadFiles(this.files);
        }

        this.onloadedFile.emit(this.pureFilesList);
      };
      fileUpload.click();
    } else {
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < droppedEvent.length; index++) {
        const file = droppedEvent[index];
        const isSupported = this.supported.includes(file.type) || this.supported.includes(this.getFileType(file.name));
        if (!isSupported) {
          this.toast.open();
        } else {
          if (this.isMultiple) {
            this.files.push({ data: file, ...fileInit });
            this.pureFilesList.push(file);
          } else {
            this.files = [{ data: file, ...fileInit }];
            this.pureFilesList = [file];
          }
        }
      }

      if (this.automaticallyImport) {
        this.uploadFilesService.uploadFiles(this.files);
      }

      this.onloadedFile.emit(this.pureFilesList);
    }

    fileUpload.value = '';
  }

  handleImage(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
    this.isShowedCamera = false;

    const file = this.uploadFilesService.b64toFile(webcamImage.imageAsDataUrl);
    this.uploadFile(file);
  }

  uploadFile(data: File) {
    this.files = [];
    const file = { data, ...fileInit };
    this.files.push(file);
    this.uploadFilesService.uploadFiles(this.files);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFilesService.retryFile(file);
  }

  cancelFile(file: FileUploadModel) {
    this.uploadFilesService.cancelFile(file);
  }

  showCamera() {
    this.isShowedCamera = true;
  }
}
