<!--
 Created by Dmytro Sav. on 4/30/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container" ngClass.lt-sm="mob-container">
  <div class="m-b-40" fxLayout="row" fxLayoutAlign="space-between center">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center center">
    <h2 class="mat-h2 m-b-0 caption" fxFlex.gt-xs="m-l-16">
      {{ tests?.subject }} - Self Assessment
    </h2>
    <div class="tabs-container" fxLayoutAlign.lt-sm="end center" ngClass.lt-sm="full-width">
      <curr-dropdown
        [options]="ddOptions"
        [selectedValue]="currSelectedTabIndex + ''"
        class="full-width m-t-8"
        fxFlex
        (valueChange)="selectTab($event)"
        fxHide.gt-xs
      ></curr-dropdown>
      <curr-teacher-tabs fxHide.lt-sm [tabLabels]="tabLabels" [(value)]="currSelectedTabIndex"></curr-teacher-tabs>
    </div>
  </div>

  <mat-divider class="m-b-16"></mat-divider>

  <div class="wrapper" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center">
    <div
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center"
      class="block"
      *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 1"
    >
      <curr-universal-card *ngFor="let card of tests?.passedCard" [card]="card"></curr-universal-card>
    </div>

    <div
      fxLayout="column"
      fxLayoutGap="10px"
      fxLayoutAlign="center"
      class="block"
      *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 2"
    >
      <curr-universal-card *ngFor="let card of tests?.notPassedCard" [card]="card"></curr-universal-card>
    </div>
  </div>
</div>
