import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { GraphOptions } from '../graph-options';
import { StudentGradesStatisticService } from './student-grades-statistic.service';
import { takeUntil } from 'rxjs/operators';
import { StudentGrade } from '../../components/student-dashboard-chart/student-dashboard-chart.component';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { ChartPeriod } from '../../components/dashboard-chart/dashboard-chart.service';

@Component({
  selector: 'curr-student-grades-statistic',
  templateUrl: './student-grades-statistic.component.html',
  styleUrls: ['./student-grades-statistic.component.scss'],
  providers: [StudentGradesStatisticService],
  animations: [fadeAnimation]
})
export class StudentGradesStatisticComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() chartData$: Observable<StudentGrade[]>;
  @Input() width: number;
  @Input() currentPeriod: ChartPeriod = ChartPeriod.YEAR;

  @ViewChild('studentGradesChartContainer') chartContainer: ElementRef;

  options: GraphOptions;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private studentGradesStatisticService: StudentGradesStatisticService) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.options = {
      width: this.width ? this.width : window.innerWidth / 2,
      height: 300,
      margin: {
        top: 20,
        bottom: 20,
        right: 25,
        left: 50
      }
    };
    this.chartContainer.nativeElement.style.height = this.options.height;
    this.chartContainer.nativeElement.style.width = this.options.width;
    this.studentGradesStatisticService.init(this.chartContainer.nativeElement, this.options);
    this.chartData$?.pipe(takeUntil(this.destroy$)).subscribe(dataValue => {
      this.studentGradesStatisticService.updateHistogram(dataValue, this.currentPeriod);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
