<!--
 Created by Dmytro Sav. on 4/30/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container">
  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>
  <div class="m-b-24 filters-row">
    <curr-select-courses-filters (onFilterChange)="onCoursesFilterChange($event)" [filterFields]="coursesFilterFields"
      [filterOptionsData]="filtersData">
    </curr-select-courses-filters>
    <curr-button variant="contained" color="primary" size="big" ngClass.lt-sm="m-t-16" fxFlex.gt-xs="159px"
      (btnClick)="createCourse()">
      CREATE COURSE
    </curr-button>
  </div>

  <div @fade class="wrapper" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="grid"
    fxLayoutAlign="start center" fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" *ngIf="cards.length > 0">
    <curr-course-card *ngFor="let card of cards" [course]="card" fxLayout="column"></curr-course-card>
  </div>

  <div *ngIf="cards.length > 0" fxLayout="row" fxLayoutAlign="center center" class="m-t-24">
    <curr-button (btnClick)="showMore()" fxFlex="144px" variant="contained" color="primary" size="big">
      SHOW MORE
    </curr-button>
  </div>
</div>
