<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-16">
  <h2 class="mat-h2 m-b-0" fxFlex>{{data.title}}</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div [formGroup]="form" class="name-container m-b-16" fxLayout="column" fxLayoutAlign="center center">
  <curr-input
    [placeholder]="'Task'"
    [formGroup]="form"
    controlName="name"
    class="full-width"
    [errorMessage]="form.controls['name'].hasError('required') ? 'Name is required' : 'Name max length = 100 characters'"
  ></curr-input>
  <curr-datepicker
    class="full-width"
    [placeholder]="'Date'"
    [activeFromToday]="true"
    [isReadOnly]="true"
    [isRequired]="true"
    [suffix]="'calendar-today'"
    [value]="oldDeadlineDate"
    [errorMessage]="form.controls['deadlineDate'].hasError('required') ? 'Date is required' : ''"
    (onInputBlur)="setStartDate($event)"
    (valueChange)="setStartDate($event)"></curr-datepicker>
  <curr-timepicker
    class="full-width"
    [placeholder]="'Time'"
    [suffix]="'time'"
    [value]="oldDeadlineTime"
    [isReadOnly]="true"
    [isRequired]="true"
    [errorMessage]="form.controls['deadlineTime'].hasError('required') ? 'Time is required' : ''"
    (onInputBlur)="setStartTime($event)"
    (valueChange)="setStartTime($event)"></curr-timepicker>
  <div class="m-t-8 full-width" fxLayout="row" fxLayoutAlign="end center">
    <curr-button variant="text" (btnClick)="dialogRef.close()" size="big">CANCEL</curr-button>
    <curr-button variant="contained" class="m-l-16" [isDisabled]="form.invalid" (btnClick)="save()" size="big">SAVE</curr-button>
  </div>
</div>
