<div class="tests-container" fxLayout="row" fxLayoutAlign="space-between center"
     fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">
  <div fxLayout="row" fxLayoutAlign="center center" ngClass.lt-sm="full-width">
    <div class="left-container"  ngClass.lt-sm="full-width" fxLayout="column" fxLayoutAlign="center start" fxLayoutAlign.lt-sm="start start">
      <div class="overline" *ngIf="card?.topText" fxLayout="row" fxLayoutAlign="start start">
        <span *ngIf="card?.topText.label.length > 0" class="m-r-8">{{ card?.topText.label }}</span>
        <span [style.color]="card?.topText.color">{{ card?.topText.value }}</span>
        <span *ngIf="card?.isClaimed" class="m-l-8">|</span>
        <div *ngIf="card?.isClaimed" class="claimed" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon svgIcon="icon-success-checkmark" class="m-l-8 m-r-0"></mat-icon>
          <span>Claimed</span>
        </div>
      </div>

      <span class="ls mat-h2 long-text">{{ card?.title }}</span>

      <div class="details-container" fxLayout="row" fxLayoutAlign="start center"
           fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start">
        <ng-container *ngFor="let text of card?.bottomText; let last = last">
          <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="center center"
            class="details-wrapper mat-body-2">
            <span *ngIf="text.label.length > 1" [style.color]="text.color" [class.label]="text.isBold">{{ text.label }}</span>

            <div class="value mat-body-2" [style.color]="text.color">{{ text.value }}</div>
            <curr-red-label *ngIf="text.isAttention" [text]="text.attention" class="m-l-8"></curr-red-label>
          </div>
          <span *ngIf="text.label.length > 1 || text.value" class="divider" [class.last]="last" fxHide.lt-sm>|</span>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="right-container" fxLayout="row" fxLayoutGap="8px"
       fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start start"
       ngClass.lt-sm="full-width m-t-8">
    <ng-container *ngFor="let button of card?.buttons" >
      <curr-button
        ngClass.lt-sm="full-width"
        [variant]="button.variant"
        [color]="button.color"
        [size]="button.size"
        [style]="button.style"
        [icon]="button.icon"
        (btnClick)="navigate(button)"
        [isDisabled]="button.isDisabled"
      >{{ button.label }}</curr-button>
    </ng-container>
    <ng-content *ngIf="!card?.buttons"  ngClass.lt-sm="full-width"></ng-content>
  </div>
</div>
