<!--
 Created by Dmytro Sav. on 4/6/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="card"
  [class.teacher]="isTeacher"
  [class.admin]="!isTeacher"
  (click)="navigateToProfile(userData.user_type === userRoles.Student ? '/studentProfile/'+userData.id || '1' : '/profile/'+userData.id || '1')">
  <div class="deactivated" *ngIf="isDeactivated"></div>
  <div class="icons-wrapper">
    <div fxLayout="row" fxLayoutAlign="end center">
      <curr-icon-button icon="trash" class="m-r-0" (click)="deleteUser($event)"></curr-icon-button>
      <curr-icon-button icon="edit" [routerLink]="userData.user_type === userRoles.Student ? '/studentProfile/'+userData.id || '1' : '/profile/'+userData.id || '1'"></curr-icon-button>
    </div>
    <span class="activate" *ngIf="isDeactivated" (click)="activateUser()">Activate</span>
  </div>

  <div fxLayout="column" fxLayoutAlign="center center">
<!--    <div class="badge" *ngIf="!isTeacher" fxLayout="row" fxLayoutAlign="center center">-->
<!--      <span class="ellipse"></span>-->
<!--      <span class="status">{{ userData?.status }}</span>-->
<!--    </div>-->

    <div class="avatar">
      <curr-initials-avatar class="avatar-container" *ngIf="!userData.avatar"
                            [name]="userData?.first_name + ' ' + userData?.last_name" size="40px"></curr-initials-avatar>
      <img *ngIf="userData.avatar" [src]="userData.avatar" alt="avatar"/>
    </div>

    <div class="name">{{ userData?.first_name + ' ' + userData?.last_name}}</div>
    <div class="id" *ngIf="isTeacher">ID: {{ userData?.id }}</div>
  </div>
</div>
