import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class HistoricalAssessmentService {

  constructor(private httpClient: ApplicationHttpClient) { }

  getAllAssessments(courseId: number, period_from: string, period_to: string, row_offset?: number, rows_count?: number, test_type?: string[]): Observable<any> {
    return this.httpClient.post(`/schoolYearCourses/getHistoricalAssessment/${ courseId }`,
      { test_type, rows_count }
    )
  }

  getNextTopicTest(courseId: number): Observable<any> {
    return this.httpClient.get(`/curriculumCumTestNextByCourseId/${ courseId }`)
  }
}
