function createLayoutFacade(){

	function getTimeline(gantt){
		return gantt.$ui.getView("timeline");
	}

	function getGrid(gantt){
		return gantt.$ui.getView("grid");
	}

	function getVerticalScrollbar(gantt){
		return gantt.$ui.getView("scrollVer");
	}

	function getHorizontalScrollbar(gantt){
		return gantt.$ui.getView("scrollHor");
	}

	var DEFAULT_VALUE = "DEFAULT_VALUE";

	function tryCall(getView, method, args, fallback){
		var view = getView(this);
		if (!(view && view.isVisible())) {
			if(fallback){
				return fallback();
			}else{
				return DEFAULT_VALUE;
			}
		} else {
			return view[method].apply(view, args);
		}
	}

	return {

		getColumnIndex: function(name) {
			var res = tryCall.call(this, getGrid, "getColumnIndex", [name]);
			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},

		dateFromPos: function(x) {
			var res = tryCall.call(this, getTimeline, "dateFromPos", Array.prototype.slice.call(arguments));
			if(res === DEFAULT_VALUE){
				return this.getState().min_date;
			}else{
				return res;
			}
		},

		posFromDate: function(date) {
			var res = tryCall.call(this, getTimeline, "posFromDate", [date]);
			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},

		getRowTop: function(index) {
			var self = this;
			var res = tryCall.call(self, getTimeline, "getRowTop", [index],
				function(){ return tryCall.call(self, getGrid, "getRowTop", [index]);}
				);

			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},

		getTaskTop: function(id) {
			var self = this;
			var res = tryCall.call(self, getTimeline, "getItemTop", [id],
				function(){ return tryCall.call(self, getGrid, "getItemTop", [id]);}
			);

			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},


		getTaskPosition: function(task, start_date, end_date) {
			var res = tryCall.call(this, getTimeline, "getItemPosition", [task, start_date, end_date]);

			if(res === DEFAULT_VALUE){
				var top = this.getTaskTop(task.id);
				var height = this.getTaskHeight();

				return {
					left: 0,
					top: top,
					height: height,
					width: 0
				};
			}else{
				return res;
			}
		},

		getTaskHeight: function() {
			var self = this;
			var res = tryCall.call(self, getTimeline, "getItemHeight", [],
				function(){ return tryCall.call(self, getGrid, "getItemHeight", []);}
			);

			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},


		columnIndexByDate: function(date) {
			var res = tryCall.call(this, getTimeline, "columnIndexByDate", [date]);
			if(res === DEFAULT_VALUE){
				return 0;
			}else{
				return res;
			}
		},

		roundTaskDates: function() {
			tryCall.call(this, getTimeline, "roundTaskDates", []);
		},

		getScale: function() {
			var res = tryCall.call(this, getTimeline, "getScale", []);
			if(res === DEFAULT_VALUE){
				return null;
			}else{
				return res;
			}
		},

		getTaskNode: function(id) {
			var timeline = getTimeline(this);
			if (!timeline || !timeline.isVisible()) {
				return null;
			} else {
				var node = timeline._taskRenderer.rendered[id];
				if(!node){
					var domAttr = timeline.$config.item_attribute;
					node = timeline.$task_bars.querySelector("[" +domAttr+ "='"+id+"']");
				}

				return node || null;
			}
		},


		getLinkNode: function(id) {
			var timeline = getTimeline(this);
			if (!timeline.isVisible()) {
				return null;
			} else {
				return timeline._linkRenderer.rendered[id];
			}
		},

		scrollTo: function(left, top){
			var vertical = getVerticalScrollbar(this);
			var horizontal = getHorizontalScrollbar(this);

			var oldH = {position: 0},
				oldV = {position: 0};

			if(vertical){
				oldV = vertical.getScrollState();
			}
			if(horizontal){
				oldH = horizontal.getScrollState();
			}

			if (horizontal && left*1 == left){
				horizontal.scroll(left);
			}
			if(vertical && top*1 == top){
				vertical.scroll(top);
			}

			var newV = {position: 0},
				newH = {position: 0};
			if(vertical){
				newV = vertical.getScrollState();
			}
			if(horizontal){
				newH = horizontal.getScrollState();
			}

			this.callEvent("onGanttScroll", [oldH.position, oldV.position, newH.position, newV.position]);
		},

		showDate: function(date){
			var date_x = this.posFromDate(date);
			var scroll_to = Math.max(date_x - this.config.task_scroll_offset, 0);
			this.scrollTo(scroll_to);
		},
		showTask: function(id) {
			var pos = this.getTaskPosition(this.getTask(id));

			// GS-1261: we need to show the start_date even in the RTL mode
			var leftPos = pos.left;
			if (this.config.rtl) leftPos = pos.left + pos.width;

			var left = Math.max(leftPos - this.config.task_scroll_offset, 0);

			var dataHeight = this._scroll_state().y;
			var top;
			if(!dataHeight){
				top = pos.top;
			}else{
				top = pos.top - (dataHeight - this.config.row_height)/2;
			}

			this.scrollTo(left, top);
		},
		_scroll_state: function(){
			var result = {
				x: false,
				y: false,
				x_pos: 0,
				y_pos: 0,
				scroll_size: this.config.scroll_size + 1,//1px for inner content
				x_inner: 0,
				y_inner: 0
			};

			var scrollVer = getVerticalScrollbar(this),
				scrollHor = getHorizontalScrollbar(this);
			if(scrollHor){
				var horState = scrollHor.getScrollState();
				if(horState.visible){
					result.x = horState.size;
					result.x_inner = horState.scrollSize;
				}
				result.x_pos = horState.position || 0;
			}

			if(scrollVer){
				var verState = scrollVer.getScrollState();
				if(verState.visible){
					result.y = verState.size;

					result.y_inner = verState.scrollSize;
				}
				result.y_pos = verState.position || 0;
			}

			return result;
		},
		getScrollState: function(){
			var state = this._scroll_state();
			return { x:state.x_pos, y:state.y_pos, inner_width:state.x, inner_height:state.y, width: state.x_inner, height: state.y_inner };
		}

	};

}

module.exports = createLayoutFacade;