/**
 * Created by Alex Poh. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { DateValue, Histogram, Series } from '../../graphs/school-performance-histogram/series.model';
import { ExpectedAverageGrade, DashboardGradeStatistic } from './dashboard-grade-statistic';
import { catchError, delay, shareReplay } from 'rxjs/operators';
import { TimePeriod } from '../../../hub-admin/hub-admin-dashboard/hub-admin-school-statistic/time-period';
import { Options } from '../dropdown/dropdown.component';
import { ChartData, DataGroup } from '../dashboard-chart/chart-data';
import { ChartPeriod, DashboardChartService } from '../dashboard-chart/dashboard-chart.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursesFilterFields } from '../select-courses-filters/select-courses-filters.component';
import { CoursesFilterOptions } from '../select-courses-filters/select-courses-filters.component';

const mockedGrades = {
  A: {
    averageValue: 95.5,
    maxValue: 100.99,
    minValue: 90.0
  },
  B: {
    averageValue: 85.0,
    maxValue: 89.99,
    minValue: 80.0
  },
  C: {
    averageValue: 75.0,
    maxValue: 79.99,
    minValue: 70.0
  },
  D: {
    averageValue: 65.0,
    maxValue: 69.99,
    minValue: 60.0
  },
  E: {
    averageValue: 55.0,
    maxValue: 59.99,
    minValue: 50.0
  },
  F: {
    averageValue: 25.0,
    maxValue: 49.99,
    minValue: 0.0
  }
};

@Component({
  selector: 'curr-school-admin-chart',
  templateUrl: './school-admin-chart.component.html',
  styleUrls: ['./school-admin-chart.component.scss']
})
export class SchoolAdminChartComponent implements OnInit {
  width = 1440 - 48 * 2 - 16 * 2 - 432 - 24;
  dashboardData: DashboardGradeStatistic[];
  displayData$: Subject<Histogram> = new BehaviorSubject<Histogram>({ series: [], grades: null });
  options: Options[];
  dividedTimePeriods: TimePeriod[] = [];
  currentTimePeriodIndex = 0;
  currentPeriodName = 'year';
  showLegend$;

  // TODO: remove?
  // filters: ChartFilter;

  selectedCourseId: string;

  generalGraphData: ChartData;
  isLoading = true;
  currentGraphData: DataGroup;
  currentPeriod: ChartPeriod;
  filtersData: any;
  coursesFilterFields: CoursesFilterFields = {
    subject: 'subjectName',
    course: 'course_id',
    grade: 'studyYearId'
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private dashboardChartService: DashboardChartService) {
  }

  get isStatisticVisible(): boolean {
    return !!this.generalGraphData?.currentYear.length;
  }

  ngOnInit(): void {
    this.currentPeriod = ChartPeriod.YEAR;

    this.dashboardChartService.getFilters().subscribe(resp => {
      this.filtersData = resp;
    });
  }

  fetchGraphData() {
    let studId;
    const selectedFiler = { course_id: this.selectedCourseId };
    if (this.router.url.indexOf('studentProfile')) {
      studId = this.route.snapshot?.params?.studentId;
      if (studId) {
        // @ts-ignore
        selectedFiler.student_id = studId;
      }
    }
    const dataBySubject$ = this.dashboardChartService
      .getChartDataForTeacherDashboard(this.currentPeriod, selectedFiler)
      .pipe(shareReplay());
    this.showLegend$ = dataBySubject$.pipe(delay(500));
    dataBySubject$
      .pipe(
        catchError(err => {
          this.isLoading = false;
          return of(null);
        })
      )
      .subscribe(res => {
        this.generalGraphData = res;
        this.isLoading = false;
        this.refreshGraph();
      });
  }

  refreshGraphWithPeriod(period) {
    this.currentPeriod = period;
    this.fetchGraphData();
  }

  getSeriesBeforeLoading() {
    const result = [
      this.getDisplayDataForAverage(
        { value: 3.8, start: '2007-01-01', end: '2008-12-31' },
        {
          data: [],
          name: 'EX. AVG. GRADE',
          cssClasses: { line: 'red-statistic disabled-line', dot: 'no-dot' }
        }
      )
    ];
    return result;
  }

  refreshGraph() {
    if (this.generalGraphData) {
      if (!this.generalGraphData.currentYear?.length) {
        return;
      }
      const avgPredictedGrade = parseFloat(this.generalGraphData?.currentYear[0]?.avgPredictedGrade) || null;
      // TODO: remove?
      // const grades = this.generalGraphData?.grades || {};
      const newSeries = [
        {
          data: this.generalGraphData.currentYear,
          name: this.generalGraphData.currentData.name,
          cssClasses: { line: 'green-statistic', dot: 'green-statistic-dot' }
        },
        {
          // Hardcoded data for demo
          data: [
            { date: this.generalGraphData.currentYear[0].date, value: avgPredictedGrade },
            {
              date: this.generalGraphData.currentYear[this.generalGraphData.currentYear.length - 1].date,
              value: avgPredictedGrade
            }
          ],
          name: 'Benchmark',
          cssClasses: { line: 'statistic-predicted', dot: 'no-dot' }
        }
      ];
      this.displayData$.next({ series: newSeries, grades: mockedGrades });
    }
  }

  getDisplayData(timePeriod: TimePeriod, series: Series): Series {
    const gradesSeries = { ...series };
    this.dashboardData[0].grades.data.forEach(grade => {
      const date = new Date(grade.date);
      if (
        date >= timePeriod.timeDisplayState.displayedStartDate &&
        date <= timePeriod.timeDisplayState.displayedEndDate
      ) {
        const newGrade = {
          ...grade,
          date: this.dividedTimePeriods[this.currentTimePeriodIndex].timeDisplayState.project(date) + ''
        };
        gradesSeries.data.push(newGrade);
      }
    });
    return gradesSeries;
  }

  getDisplayDataForAverage(expectedAverageGrade: ExpectedAverageGrade, series: Series): Series {
    const data = [
      {
        date: this.dividedTimePeriods[this.currentTimePeriodIndex].timeDisplayState.displayedStartDate + '',
        value: expectedAverageGrade.value
      },
      {
        date: this.dividedTimePeriods[this.currentTimePeriodIndex].timeDisplayState.displayedEndDate + '',
        value: expectedAverageGrade.value
      }
    ];
    return { ...series, data };
  }

  divideTimePeriod(timePeriod: TimePeriod) {
    this.dividedTimePeriods = [];
    this.dividedTimePeriods.push({ ...timePeriod });
    while (timePeriod.timeDisplayState.displayedStartDate > timePeriod.startDate) {
      timePeriod.timeDisplayState.prev();
      this.dividedTimePeriods.push({ ...timePeriod });
    }
  }

  getDateOptions() {
    const result = [];
    this.dividedTimePeriods.forEach((tp, i) => {
      result.push({ value: i, label: `Year ${ this.getYearToDisplay(tp) }` });
    });
    return result;
  }

  filterDataForDisplay(data: DateValue[]): DateValue[] {
    return data.filter((item: DateValue) => {
      const date = new Date(item.date);
      return (
        date >= this.dividedTimePeriods[this.currentTimePeriodIndex].timeDisplayState.displayedStartDate &&
        date <= this.dividedTimePeriods[this.currentTimePeriodIndex].timeDisplayState.displayedEndDate
      );
    });
  }

  selectAnotherPeriod(value) {
    this.currentTimePeriodIndex = value;
    this.refreshGraph();
  }

  switchToYear() {
    const timePeriod = this.getTimePeriod();
    timePeriod.timeDisplayState.setToYear();
    this.divideTimePeriod(timePeriod);
    this.currentPeriodName = 'year';
    this.refreshGraph();
  }

  switchToSemester() {
    const timePeriod = this.getTimePeriod();
    timePeriod.timeDisplayState.setToSemester();
    this.divideTimePeriod(timePeriod);
    this.currentPeriodName = 'semester';
    this.refreshGraph();
  }

  switchToMonth() {
    const timePeriod = this.getTimePeriod();
    timePeriod.timeDisplayState.setToYear();
    this.divideTimePeriod(timePeriod);
    this.currentPeriodName = 'month';
    this.refreshGraph();
  }

  getYearToDisplay(timePeriod: TimePeriod) {
    return timePeriod.timeDisplayState.displayedStartDate.getFullYear() - this.dashboardData[0].yearStart;
  }

  getTimePeriod() {
    const startDate = new Date(this.dashboardData[0].grades.data[0].date);
    const endDate = new Date(this.dashboardData[0].grades.data[this.dashboardData[0].grades.data.length - 1].date);
    return new TimePeriod(startDate, endDate);
  }

  filterChanged({ course_id }: CoursesFilterOptions) {
    if (course_id && course_id !== this.selectedCourseId) {
      this.selectedCourseId = course_id as string;
      this.fetchGraphData();
    }
  }

  ChartPeriod = ChartPeriod;
}
