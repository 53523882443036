import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CalculatedPriceService {
  private calculatedPrice$: BehaviorSubject<any> = new BehaviorSubject(null);
  constructor() { }

  get getCalculatedPrice(): BehaviorSubject<any> {
    return this.calculatedPrice$;
  }

  changePrice(price: any): void {
    const newPrice = {
      ...price,
      subjects: price.subjects.sort((a,b) => a.id - b.id)
    }
    this.calculatedPrice$.next(newPrice);
  }
}
