import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FailedMessageService {
  public failedMessage$: BehaviorSubject<any> = new BehaviorSubject(null);

  get getMessage(): BehaviorSubject<any> {
    return this.failedMessage$;
  }

  changeMessage(message: any): void {
    this.failedMessage$.next(message);
  }
}
