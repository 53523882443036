/**
 * Created by Maxim Bon. on 01/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
/**
 * Created by Volodymyr Mak. on 3/7/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UploadFilesService } from '../upload-files/upload-files.service';
import { TimeTableService, TimeTable, Weeks } from './timetable.service';
import { GanttService } from '../../../shared-pages/subject-details/gantt-chart/gantt.service';
import { CreateCoursePopupComponent } from '../../../shared-pages/courses/create-course-popup/create-course-popup.component';

@Component({
  selector: 'curr-create-subject-popup',
  templateUrl: './create-subject-popup.component.html',
  styleUrls: ['./create-subject-popup.component.scss'],
  providers: [TimeTableService]
})
export class CreateSubjectPopupComponent implements OnInit {
  currentStep: number;
  uploadFiles: File[];
  isDisabled = true;
  startImport = false;
  fileUploaded = false;
  disableTimeTableSaving = true;
  subjectId: number;
  dialogType: string;
  weeksCount: number;
  timeTableList: Weeks;
  timeTable: TimeTable;
  teacherClaimedCoursesId = [];
  teacherUnClaimedCoursesId = [];
  importedCourses = {};

  days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  academicYearList: { value: number; label: string; selected: boolean }[] = [];
  semester = [
    { value: 0, label: 'All semesters', selected: true },
    { value: 1, label: 'One semester', selected: false },
    { value: 2, label: 'Two semesters', selected: false },
    { value: 3, label: 'Three semesters', selected: false },
    { value: 4, label: 'Four semesters', selected: false }
  ];
  private semesterCount: number = 0;
  private academicYearId: number;

  constructor(
    public dialogRef: MatDialogRef<CreateSubjectPopupComponent>,
    private _uploadFilesService: UploadFilesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialog: MatDialog,
    private _timeTableService: TimeTableService,
    private ganttService: GanttService
  ) {
    this.currentStep = 0;
  }

  ngOnInit(): void {
    this.dialogType = this.dialogRef.componentInstance.data.importType;
    this._timeTableService.getTimetablePeriod().subscribe(periodList => {
      this.academicYearId = periodList[0].value;
      this.academicYearList = periodList;
    });
    this.getTeacherCoursesId();
  }

  getTeacherCoursesId() {
    this._timeTableService.getTeacherCourses().subscribe((resp: any) => {
      this.teacherClaimedCoursesId = (resp?.data?.items as []).map((i: any) => i.course);
      if (this.timeTableList) {
        this.findUnCreatedCourses();
      }
    });
  }

  submitSubject(answer) {
    this.dialogRef.close(answer);
  }

  createManually() {
    this.dialogRef.close();
    this.router.navigate(['subjects', 'new']);
  }

  nextStep() {
    this.currentStep++;
  }

  stopImport() {
    this.nextStep();
  }

  onLoadedFile(files: File[]) {
    this.isDisabled = false;
    this.uploadFiles = files;
  }

  importFile() {
    this.startImport = true;
    this.nextStep();
    this._uploadFilesService.importFiles(this.uploadFiles).subscribe(
      (fileInfo: { data: number; message: string; token: string }) => {
        this.nextStep();
        this.subjectId = fileInfo.data;

      }, () => {
        this.currentStep = 5;
      });
  }

  importTimeTable() {
    this.startImport = true;
    this.nextStep();
    this._timeTableService.uploadTimeTable(this.uploadFiles).subscribe({
      next: (data: TimeTable) => {
        this.timeTable = data;
        this.timeTableList = data.weeks;
        this.disableTimeTableSaving = this.timeTable.courses && !!this.timeTable.courses.length;
        this.weeksCount = Object.keys(this.timeTableList).length;
        this.findUnCreatedCourses();
        this.nextStep();
      }
    });
  }

  saveTimetable() {
    this.nextStep();
    this.startImport = true;
    this.timeTable.school_year_settings_id = this.academicYearId;
    this.timeTable.semester_count = this.semesterCount;
    // backend dev ask about that.
    delete this.timeTable.courses;
    this._timeTableService.saveTimeTable(this.timeTable).subscribe({
      next: (data: any) => {
        this.ganttService.refreshGantt$.next(true);
        this.nextStep();
      },
      error: err => {
        this.currentStep = 5;
      }
    });
  }

  changeAcademicYearId(yearValue: number) {
    this.academicYearId = yearValue;
  }

  changeSemesterCount(semesterValue: number) {
    this.semesterCount = semesterValue;
  }

  viewImport() {
    this.router.navigate([`/courses-details/subjects/${ this.subjectId }`]);
    this.dialogRef.close();
  }


  private findUnCreatedCourses() {
    // tslint:disable-next-line:forin
    for (const key in this.timeTableList) {
      const days = this.timeTableList[key].days;
      // tslint:disable-next-line:forin
      for (const dkey in days) {
        const day: any[] = days[dkey];
        day.forEach(d => {
          this.importedCourses[d.course] = d;
        });
      }
    }
    this.teacherUnClaimedCoursesId = [];
    for (const key in this.importedCourses) {

      if (this.teacherClaimedCoursesId.indexOf(key) === -1) {
        this.teacherUnClaimedCoursesId.push(key);
      }
    }

  }

  createCourse(id: string) {
    const dialogRef = this.dialog.open(CreateCoursePopupComponent, {
      width: '501px',
      maxHeight: '90vh',
      data: { courseId: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getTeacherCoursesId();
    });
  }
}
