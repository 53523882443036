/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-close-confirmation-popup',
  templateUrl: './close-confirmation-popup.component.html',
  styleUrls: ['./close-confirmation-popup.component.scss']
})
export class CloseConfirmationPopupComponent implements OnInit {

  dialogTitle!: string;
  showContent = true;
  
  constructor(
    public dialogRef: MatDialogRef<CloseConfirmationPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this.data.title;
    this.showContent = this.data.showContent;
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
