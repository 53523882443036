<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="curr-most-frequently-incorrect-questions">
  <div class="items-section">
    <div class="items-list" *ngFor="let item of displayedData">
      <div class="questions" [currMath]="item.text"></div>
      <div class="times" *ngIf="item.times">{{item.times}} time{{item.times > 1 ? 's': ''}}</div>
    </div>
  </div>

  <div class="load-more-section" *ngIf="receivedData?.length >= limit && (displayedData?.length < receivedData?.length)">
    <curr-button size="big" class="load-more-btn" variant="outlined" (btnClick)="showMore()">load more
    </curr-button>
  </div>

  <div class="incorrect-empty-data" *ngIf="!receivedData && !displayedData">
    <img src="/assets/images/colored/empty-chart.svg" />
    <div class="empty-data-text">No data to show.</div>
  </div>
</div>
