import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Question, QuestionType } from '../../../../student/test.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NewQuestionService } from '../new-question.service';

@Component({
  selector: 'curr-new-question-answer-variant',
  templateUrl: './new-question-answer-variant.component.html',
  styleUrls: ['./new-question-answer-variant.component.scss']
})
export class NewQuestionAnswerVariantComponent implements OnInit, OnDestroy {
  @Input() question: Question;
  @Output() onUpdateQuestion = new EventEmitter<Question>();

  isSelectOneEditor = false;
  isSelectMultiEditor = false;

  questionType = QuestionType;
  showDelete = false;
  answersList: string[] = [];
  checkBoxCorrectAnswers: any[] = [];
  questionOptionsForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  public formula: string | null = null;

  constructor(private fb: FormBuilder, private newQuestionService: NewQuestionService) {
  }

  get questionOptions() {
    return this.questionOptionsForm.controls.questionOptions as FormArray;
  }

  ngOnInit(): void {
    this.questionOptionsForm = this.fb.group({
      questionOptions: this.fb.array([])
    });

    const answersIndexes = this.question.correct_answer_options;

    if (this.question.answer_options?.length) {
      this.question.answer_options.forEach((item, i) => {
        const optionsForm = this.fb.group({
          description: [item],
          isChecked: answersIndexes.length > 0 ? answersIndexes.includes(i) : i === 1
        });

        this.questionOptions.push(optionsForm);
      });
    }

    this.answersList = this.question.answer_options;
    this.changeColumns();

    this.questionOptions.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      const newOptionsList = [];
      this.question.correct_answer_options = [];

      changes.map((item, index) => {
        newOptionsList.push(item.description);
        if (item.isChecked) {
          this.question.correct_answer_options.push(index);
        }
      });

      this.answersList = newOptionsList;

      this.question.answer_options = this.answersList;
      this.onUpdateQuestion.emit(this.question);
      this.changeColumns();
    });

    this.newQuestionService.onAnswerOptions().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.questionNumber === this.question.itemNumber) {
        const optionsForm = this.fb.group({
          description: [data.option],
          isChecked: false
        });

        this.questionOptions.push(optionsForm);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setCorrectRadioAnswer(i: number) {
    this.question.correct_answer_options[0] = i;
    this.onUpdateQuestion.emit(this.question);
  }

  checkBoxClick(isChecked: boolean, i: number) {
    this.questionOptions.controls[i].patchValue({
      description: this.questionOptions.controls[i].value.description,
      isChecked
    });
  }

  addAnswer() {
    const nextOptionNumName = this.answersList.length + 1;

    const newItem = this.fb.group({
      description: [`Option ${ nextOptionNumName }`],
      isChecked: false
    });
    this.questionOptions.push(newItem);

    if (this.question.answer_type === this.questionType.SELECT_MULTIPLE) {
      this.checkBoxCorrectAnswers.push('');
    }
  }

  updateOption(event: string, index: number): void {
    this.answersList[index] = event;
    this.question.answer_options = this.answersList;
    this.onUpdateQuestion.emit(this.question);
  }

  updateEditorOption(event: string, index: number): void {
    this.answersList[index] = event;
    this.question.answer_options = this.answersList;
  }

  updateOptionEditor(): void {
    this.answersList.forEach((item, index) => {
      this.questionOptions.controls[index].patchValue({
        description: item,
        isChecked: this.questionOptions.controls[index].value.isChecked
      });
    })

    this.onUpdateQuestion.emit(this.question);
  }

  deleteAnswer(i: number) {
    if (this.answersList.length <= 2) return;
    this.question.correct_answer_options.splice(i, 1);
    this.checkBoxCorrectAnswers = this.question.correct_answer_options;
    this.questionOptions.removeAt(i);
    this.onUpdateQuestion.emit(this.question);
  }

  private changeColumns() {
    this.showDelete = this.question.answer_options.length > 2;
  }
}
