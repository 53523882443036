/**
 * Created by Daniil Vat. on 28/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { IHardestQuestion } from '../../interfaces';
import { COLORS } from '../consts';
@Component({
  selector: 'curr-questions-pie-chart',
  templateUrl: './questions-pie-chart.component.html',
  styleUrls: ['./questions-pie-chart.component.scss']
})
export class QuestionsPieChartComponent implements OnInit {
  @Input() set data(value: IHardestQuestion[]) {
    this.chartData = value?.map(item => Number(item.percentage));
    this.labels = value?.map(item => `${ parseInt(item.percentage, 10) }%`);
  }

  type: ChartType = 'pie';
  chartData: number[];
  labels: string[];

  options: ChartOptions = {
    responsive: true,
    // tooltips: {
    //   enabled: false
    // },
    hover: { mode: null },
    plugins: {
      // labels: {
      //   render: 'percentage',
      //   fontColor: '#000A12',
      //   fontWeight: '500',
      //   precision: 0
      // }
    }
  };
  plugins = [];
  colors = [
    {
      backgroundColor: COLORS
    }
  ];

  constructor() {
  }

  // TODO: remove?
  ngOnInit() {
  }
}
