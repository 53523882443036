/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type
} from '@angular/core';
import { FullScreenPopupModule } from './full-screen-popup.module';
import { FullScreenPopupComponent } from './full-screen-popup.component';
import { FullScreenPopupInjector } from './full-screen-popup-injector';
import {BehaviorSubject} from 'rxjs';

export class FullScreenPopupConfig<D = any> {
  data?: D;
  options?: D;
}

@Injectable({
  providedIn: FullScreenPopupModule
})
export class FullScreenPopupService {

  dialogComponentRef: ComponentRef<FullScreenPopupComponent>;
  fullScreenPopupCounter = new BehaviorSubject<number>(0);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  open(componentType: Type<any>, config: FullScreenPopupConfig) {
    this.fullScreenPopupCounter.next(1);
    const dialogRef = this.appendDialogComponentToBody(config);
    this.dialogComponentRef.instance.childComponentType = componentType;
    return dialogRef;
  }

  private appendDialogComponentToBody(config: FullScreenPopupConfig) {
    const map = new WeakMap();
    map.set(FullScreenPopupConfig, config);

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(FullScreenPopupComponent);
    const componentRef = componentFactory.create(new FullScreenPopupInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;

    if (config) {
      this.dialogComponentRef.instance.contentData = config;
    }

    this.dialogComponentRef.instance.onClose.subscribe(() => {
      this.removeDialogComponentFromBody();
    });

    return this.dialogComponentRef;
  }

  private removeDialogComponentFromBody(): void {
    this.fullScreenPopupCounter.next(0);
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
