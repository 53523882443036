<!--Created by Maxim B. on 16/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="tests-container" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div class="left-container" fxLayout="column" fxLayoutAlign="center start">
    <p class="date">self assessment</p>
    <h2 class="name mat-h2" [currMath]="testCard?.name"></h2>
    <div class="details-container  mat-body-2" fxLayout="row" fxLayoutAlign="start center">
      <span>{{ testCard?.studentsPassed }}</span>
    </div>
  </div>
  <div
    class="right-container"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    *ngIf="testCard?.testType == 'passed' || testCard?.testType == 'notPassed' "
  >
    <curr-button size="big"  variant="contained" color="primary" size="small" (click)="goToTestReview()">
      VIEW RESULTS
    </curr-button>
  </div>
</div>
