/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YearSettings } from '../../../../school-admin/year-settings/year-settings';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';

@Component({
  selector: 'curr-ys-step-three',
  templateUrl: './ys-step-three.component.html',
  styleUrls: ['./ys-step-three.component.scss']
})
export class YsStepThreeComponent implements OnInit {
  @Input() isNew = true;
  @Input() isInPopup = false;
  @Input() ys: YearSettings;
  @Output() ysChange = new EventEmitter<YearSettings>();
  @Output() onNextCancel = new EventEmitter<string>();

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  goToNext(step:string) {
    this.onNextCancel.emit(step)
  }

  saveSettings(save: string) {
    if (this.isInPopup) {
      this.onNextCancel.emit('save');
      this.dialog.closeAll();
      return;
    }

    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        questionDescription:'Settings saved!',
        questionTitle:'Success!',
        confirmLabel: 'OKAY',
        cancelLabel: 'GO BACK'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onNextCancel.emit('save');
      }
    });
  }
}
