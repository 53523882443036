/**
 * Created by Alex Poh. on 30/06/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Expose, Type } from 'class-transformer';
import { MediaType } from '../../../shared/components/media-item/api-media-item';

export class Level {
  id?: string;
  name: string;
  private _navigationPart?: NavigationPart;
  public get navigationPart(): NavigationPart {
        return this._navigationPart;
    }
    public set navigationPart(value: NavigationPart) {
        this._navigationPart = value;
    }
  hasContent? = true;
}

export class SunburstApiResponse extends Level {
  @Type(() => LevelTwo)
  children: LevelTwo[];
  @Expose()
  get navigationPart() {
    return {
      urlPart: `subjectsUpdate/${this.id}`
    };
  }
}

export class LevelTwo extends Level {
  @Type(() => LevelThree)
  children: LevelThree[];
  @Expose()
  get navigationPart() {
    return {
      urlPart: `paper/${this.id}`
    };
  }
}

export class LevelThree extends Level {
  @Expose()
  get navigationPart(): NavigationPart {
    return {
      query: { topic: this.id }
    };
  }

  @Type(() => LevelFour)
  children: LevelFour[];
}

export class LevelFour extends Level {
  @Type(() => LevelFive)
  children: LevelFive[];
  @Expose()
  get navigationPart(): NavigationPart {
    const result = topicMenu.find(tm => tm.name === this.name);
    return result.navigationPart;
  }
}
export class LevelFive extends Level {
  @Expose()
  get navigationPart(): NavigationPart {
    const result = resources.find(resource => resource.name === this.name);
    return result.navigationPart;
  }
}

export class NavigationPart {
  urlPart?: string;
  query?: object;
}

const resources: any[] = [
  {
    name: 'video',
    navigationPart: {
      query: {
        resources: MediaType.video
      }
    },
    children: []
  },
  {
    name: 'image',
    navigationPart: {
      query: {
        resources: MediaType.image
      }
    },
    children: []
  },
  {
    name: 'document',
    navigationPart: {
      query: {
        resources: MediaType.document
      }
    },
    children: []
  },
  {
    name: 'article',
    navigationPart: {
      query: {
        resources: MediaType.link
      }
    },
    children: []
  }
];

const topicMenu = [
  {
    name: 'Scheme of Work',
    navigationPart: {
      query: {
        menu: '0'
      }
    },
    children: []
  },
  {
    name: 'Student notes',
    navigationPart: {
      query: {
        menu: '1'
      }
    },
    children: []
  },
  {
    name: 'Lesson Plan',
    navigationPart: {
      query: {
        menu: '2'
      }
    },
    children: []
  },
  {
    name: 'Resources',
    navigationPart: {
      query: {
        menu: '3'
      }
    },
    children: resources
  },
  {
    name: 'Test questions',
    navigationPart: {
      query: {
        menu: '4'
      }
    },
    children: []
  },
  {
    name: 'COU',
    navigationPart: {
      query: {
        menu: '5'
      }
    },
    children: []
  }
];
