/*
 * Created by Dmytro Sav. on 4/29/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AudioRecordingService } from '../../../media-services/audio-recorder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  blobUrl?: any;
  blobFile?: any;
}

type StateRecord = 'init' | 'start' | 'stop' | 'pause';
@Component({
  selector: 'curr-dialog-recording',
  templateUrl: './dialog-recording.component.html',
  styleUrls: ['./dialog-recording.component.scss'],
  providers: [AudioRecordingService]
})
export class DialogRecordingComponent implements OnInit, OnDestroy {
  btnStyle = { padding: 0 };
  stateRecord: StateRecord;
  recordedTime: string;
  time: number;

  constructor(
    public dialogRef: MatDialogRef<DialogRecordingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private audioRecordingService: AudioRecordingService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.abortRecording();
  }

  init() {
    this.audioRecordingService.recordingFailed().subscribe(() => {
      this.stateRecord = 'init';
    });

    this.audioRecordingService.getRecordedTime().subscribe(time => {
      this.time = +time;
      this.recordedTime = this.millisToMinutesAndSeconds(time);
    });

    this.audioRecordingService.getRecordedBlob().subscribe(res => {
      this.data.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(res.blob));
      this.data.blobFile = res.blob;
    });

    this.stateRecord = 'init';
  }

  startRecording() {
    if (this.stateRecord === 'init') {
      this.stateRecord = 'start';
      this.audioRecordingService.startRecording();
    }
  }

  recordAnother() {
    this.data.blobUrl = null;
    this.data.blobFile = null;
    this.abortRecording();
    this.init();
  }

  pauseRecording() {
    if (this.stateRecord === 'start') {
      this.audioRecordingService.pauseRecording();
      this.stateRecord = 'pause';
    }
  }

  resumeRecording() {
    this.audioRecordingService.resumeRecording();
    this.stateRecord = 'start';
  }

  stopRecording() {
    if (this.stateRecord === 'start' || this.stateRecord === 'pause') {
      this.audioRecordingService.stopRecording();
      this.stateRecord = 'stop';
    }
  }

  abortRecording() {
    this.stateRecord = 'stop';
    this.audioRecordingService.abortRecording();
  }

  close(): void {
    this.dialogRef.close();
  }

  millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ':' + (+seconds < 10 ? '0' : '') + seconds;
  }


}
