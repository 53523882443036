/*
 * Created by Volodymyr Mak. on 1/6/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../role';
import { UserService } from '../../user.service';

@Component({
  selector: 'curr-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss']
})
export class MobileMenuComponent {
  @Input() isActive: boolean = false;
  @Input() userPages: any[] = [{}];
  @Input() generalPages: any[] = [{}];
  @Input() userInfo: {name: string, year?: number, image: string} = {
    name: '',
    image: ''
  };
  @Output() menuClosed = new EventEmitter();
  @Output() emitTutorSession = new EventEmitter();
  @Output() emitMenu = new EventEmitter();

  constructor(private router: Router,
              private userService: UserService,) { }

  closeMenu(): void {
    this.isActive = false;
    this.menuClosed.emit();
  }

  menuClick(path: string): void {
    if (path === 'logout') {
      this.userService.saveUser({ role: Role.Unauthorized });
      window.localStorage.clear();
      this.router.navigateByUrl('');
    }
  }

  goTutorSession(icon: string): void {
    if(icon === 'tv'){
      this.emitTutorSession.emit();
    }
    else if (icon === 'avatar'){
      this.emitMenu.emit(icon);
    }
  }
}
