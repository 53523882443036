<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="doughnut-chart-container">
  <canvas baseChart
          class="doughnut-chart"
          [data]="doughnutChartData"
          [type]="doughnutChartType"
          [options]="doughnutChartOptions">
  </canvas>
  <div class="doughnut-chart-data">
    <ng-container *ngFor="let item of doughnutChartData.labels; let index = index;">
      <div class="chart-item" *ngIf="doughnutChartData.datasets[0].data[index]">
        <div class="chart-item-color" [style.backgroundColor]="doughnutChartData.datasets[0].backgroundColor[index]"></div>
        <div class="chart-item-label">{{item}}</div>
      </div>
    </ng-container>
  </div>
</div>
