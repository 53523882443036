<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<!--<div class="unit_container">-->
  <div class="card_wrapper">
    <div class="mock-size">
      <div style="width: 356px; height: 112px" *ngIf="!loaded"></div>
      <mat-icon class="tv-icon" [svgIcon]="'tv'"></mat-icon>
      <img class="card_image" [hidden]="!loaded" [src]="img" alt="{{ lesson.subject | lowercase }}" (load)="loaded = true">
    </div>
    <div class="card_description">
      <div class="card_title-box">
        <div class="card_title mat-h2">{{ lesson.topic.title | titlecase }}</div>
        <div class="card_coins">
          <mat-icon class="card_coins__icon" [svgIcon]="'coin-10'"></mat-icon>
        </div>
      </div>
      <curr-progress-bar
        [value]="lesson.topic.progress_data.proficientPercentage * 100 || 0"
        [secondValue]="lesson.topic.progress_data.couldBeImprovedPercentage * 100 || 0"
        [thirdValue]="lesson.topic.progress_data.toBeCompletedPercentage * 100 || 0"
        width="100%"
        height="6px"
        mainColor="#F5F5F6"
        secondColor="#5DB605">
      </curr-progress-bar>
      <div class="activities__subject-steps">
        <span class="activities__subject-step">{{ ((lesson.topic.progress_data.proficientPercentage * 100) | number: '1.0-0') || 0 }}% Proficient</span>
        <span class="activities__subject-step">{{ ((lesson.topic.progress_data.couldBeImprovedPercentage * 100) | number: '1.0-0') || 0 }}% Could be Improved</span>
        <span class="activities__subject-step">{{ ((lesson.topic.progress_data.toBeCompletedPercentage * 100) | number: '1.0-0') || 0 }}% To be completed</span>
      </div>
      <curr-button
        *ngIf="topicTestURL"
        (click)="goToTopicTest()"
        size="big"
        class="card_btn-tt"
        color="accent"
      >Start Topic Test
      </curr-button>

      <curr-button
        *ngIf="!lesson.topic.continue_data.hasStarted && lesson.topic.progress_data.toBeCompletedPercentage !== 0"
        (click)="goToLearningResources(subjectId)"
        size="big"
        class="card_btn"
        color="accent"
      >Start SA
      </curr-button>

      <curr-button
        *ngIf="lesson.topic.continue_data.hasStarted && lesson.topic.progress_data.toBeCompletedPercentage !== 0"
        (click)="goToTest()"
        size="big"
        class="card_btn-big"
        color="accent"
      >Continue
      </curr-button>
      <curr-button
        *ngIf=" lesson.topic.progress_data.toBeCompletedPercentage === 0"
        (click)="goToReview()"
        size="big"
        class="card_btn-big"
        color="accent"
      >Review
      </curr-button>
    </div>

  </div>
<!--</div>-->


<!--TODO Previous version -->
<!--  <div class="container">-->
<!--    <div class="list main" [class]="lessonClass()" [class.isToday]="isToday()">-->
<!--      <div class="day overline">-->
<!--        <mat-icon *ngIf="isToday(); else dot" color="accent" [inline]="true" svgIcon="icon-done"-->
<!--                  class="icon"></mat-icon>-->
<!--        <ng-template #dot>-->
<!--          <div class="dot" [class.opacity]="true"></div>-->
<!--        </ng-template>-->
<!--        <span class="text">{{ lesson.dateText }}</span>-->
<!--      </div>-->

<!--      <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center" (click)="goToLearningResources(lesson)">-->
<!--        <div class="text-wrapper" fxLayout="column" fxLayoutAlign="center" fxFlex="60%" [class.m-t-16]="true">-->
<!--          <div class="mat-h1 m-b-0 name desktop">{{ lesson?.learningObjectiveTitle }}</div>-->
<!--          <div class="mat-h1 m-b-0 name mobile">{{ lesson?.learningObjectiveTitle }}</div>-->

<!--          <div-->
<!--            class="status mat-body-2 m-t-8"-->
<!--            fxLayout="row"-->
<!--            fxLayout.xs="column"-->
<!--            fxLayoutAlign="start center"-->
<!--            fxLayoutAlign.xs="start start"-->
<!--          >-->
<!--            <ng-container *ngFor="let text of bottomTitles; let last = last">-->
<!--              <div fxLayout="row" fxLayoutAlign="start center">-->
<!--                <mat-icon *ngIf="text?.icon" [inline]="true" [svgIcon]="text?.icon"-->
<!--                          class="status-icon m-r-4"></mat-icon>-->

<!--                <span-->
<!--                  class="m-r-4"-->
<!--                  *ngIf="text.label.length > 1"-->
<!--                  [class.text-underline]="text?.isUnderline"-->
<!--                  [class.label]="text.isBold"-->
<!--                >{{ text.label }}-->
<!--              </span>-->

<!--                <div class="value" [style.color]="text.color">{{ text.value }}</div>-->

<!--                <span class="divider m-l-8 m-r-8" [class.last]="last" fxHide.xs>|</span>-->
<!--              </div>-->
<!--            </ng-container>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div-->
<!--          *ngIf="buttons?.length > 0"-->
<!--          class="action-btn"-->
<!--          fxLayout="column"-->
<!--          fxLayoutAlign="center"-->
<!--          fxFlex.xs-->
<!--          fxFlex="254px"-->
<!--          fxLayoutGap="4px"-->
<!--        >-->
<!--          <ng-container *ngFor="let b of buttons">-->
<!--            <curr-button-->
<!--              (click)="$event.stopPropagation(); b.callback()"-->
<!--              [variant]="b.variant || 'contained'"-->
<!--              [style]="b.style"-->
<!--              [isDisabled]="b.isDisabled"-->
<!--            >-->
<!--              {{ b.label }}-->
<!--            </curr-button>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
