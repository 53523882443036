<div *ngIf="errors.length === 0">
  <webcam
    [height]="500"
    [width]="500"
    [trigger]="triggerObservable"
    (imageCapture)="handleImage($event)"
    *ngIf="showWebcam"
    [allowCameraSwitch]="allowCameraSwitch"
    [switchCamera]="nextWebcamObservable"
    [videoOptions]="videoOptions"
    (cameraSwitched)="cameraWasSwitched($event)"
    (initError)="handleInitError($event)"
  ></webcam>

  <curr-button variant="contained" class="m-b-8" (btnClick)="triggerSnapshot()">Make photo</curr-button>
  <curr-button variant="contained" (btnClick)="showNextWebcam(true)">Switch camera</curr-button>
</div>
