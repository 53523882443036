import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GanttUnit } from './gantt-unit';

@Injectable({ providedIn: 'root' })
export class GanttService {

  constructor(private httpClient: ApplicationHttpClient) {
  }

  refreshGantt$: Subject<boolean> = new Subject();

  public getGanttData(courseId: string): Observable<GanttUnit[]> {
    return this.httpClient
      .get('/gantt', {
        params: {
          school_year_course_id: courseId
        } as any
      })
      .pipe(
        map((res: any) => {
          if (!res.data.items || res.data.items.length === 0) {
            return null;
          } else {
            return res.data.items;
          }
        }),
        catchError(err => {
          return of(null);
        })
      );
  }

  public updateGanttData(data, schoolYearCourseId): Observable<GanttUnit[]> {
    return this.httpClient.put('/gantt', data, { params: { schoolYearCourseId } as any }).pipe(
      map((res: any) => {
        if (!res.data.items || res.data.items.length === 0) {
          return null;
        } else {
          return res.data.items;
        }
      })
    );
  }

  public refreshGantt() {
    this.refreshGantt$.next(true);
  }

  public getRevisionSnackBarText(oldValue: number, newValue: number): string {
    let result = '';
    if (oldValue > newValue) {
      result = `Revision period has decreased to ${ newValue } lessons`;
    } else {
      result = `Revision period has increased to ${ newValue } lessons`;
    }
    return result;

    // TODO: can we modify to:
    // return `Revision period has ${oldValue > newValue ? 'decreased' : 'decreased'} to ${ newValue } lessons` ?
  }

  public scrollAnimation(gantt: any, x: number, y: number, cb?: () => void) {
    const { x: posX, y: posY } = gantt.getScrollState();
    if (posX < x - 500 || posY < y - 500) {
      gantt.scrollTo(posX + 50, posY + 50);
      setTimeout(() => this.scrollAnimation(gantt, x, y, cb), 1);
    } else {
      cb();
    }
  }
}

export const fakeResponse = {
  token: null,
  message: null,
  data: {
    items: [
      {
        unit_id: 1828,
        unit_title: 'Paper 1 Biology AS',
        topics: [
          {
            topic_id: 1828,
            topic_title: 'Paper 1 Biology AS',
            cumulative_tests: [
              {
                cumulative_test_id: 0,
                cumulative_test_name: 'NO_CUM_TEST',
                lessons: [
                  {
                    date: '2020-10-07',
                    lesson: [
                      {
                        lesson_id: 50032,
                        lesson_seq_number: 5,
                        learning_objectives: [
                          {
                            topic_id: 1828,
                            topic_seq_number: 10,
                            topic_ref_number: '1',
                            topic_title: 'new topic',
                            topic_duration: 1330,
                            lesson_part: 1.0
                          }
                        ]
                      },
                      {
                        lesson_id: 50031,
                        lesson_seq_number: 1,
                        learning_objectives: [
                          {
                            topic_id: 1828,
                            topic_seq_number: 10,
                            topic_ref_number: '1',
                            topic_title: 'new topic',
                            topic_duration: 1330,
                            lesson_part: 1.0
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                cumulative_test_id: 1122,
                cumulative_test_name: 'New cumulative test 6',
                lessons: [
                  {
                    date: '2020-10-08',
                    lesson: [
                      {
                        lesson_id: 50025,
                        lesson_seq_number: 1,
                        learning_objectives: [
                          {
                            topic_id: 1829,
                            topic_seq_number: 6,
                            topic_ref_number: '1',
                            topic_title: 'Topi 5 sor SA test',
                            topic_duration: 20,
                            lesson_part: 0.33
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
};
