/*
* Created by Max Bon. on 17/08/2020
* Copyright © 2020 Curriculum Ltd. All rights reserved.
*/
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'curr-student-add-comment-to-note-popup',
  templateUrl: './student-add-comment-to-note-popup.component.html',
  styleUrls: ['./student-add-comment-to-note-popup.component.scss']
})
export class StudentAddCommentToNotePopupComponent implements OnInit {
  highlightColors = environment.highlightColors;
  colors: { color?: string, checked?: boolean }[] = [];
  inEditNoteMode = false;
  comment = '';
  selectedColor = '';

  constructor(public dialogRef: MatDialogRef<StudentAddCommentToNotePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.inEditNoteMode = this.data.inEditNoteMode || false;
    this.comment = this.data.comment || '';
    this.colors = this.highlightColors.map(c => {
      return { color: c, checked: false };
    });
    if (this.data.selectedColor) {
      this.selectedColor = this.data.selectedColor;
    }
    if (this.selectedColor) {
      let b = 0;
      this.colors.forEach((i, index) => {
        if (i.color === this.selectedColor) {
          b = index;
        }
      });
      this.setCheckedColor(b);
    } else {
      this.setCheckedColor(0);
    }

  }

  saveNote() {
    this.dialogRef.close({
      comment: this.comment,
      color: this.selectedColor
    });
  }

  setCheckedColor(i: number) {
    this.colors.forEach(c => {
      c.checked = false;
    });
    this.colors[i].checked = true;
    this.selectedColor = this.colors[i].color;
  }


}
