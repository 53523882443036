/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener, Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IZone } from './ai-zones-marker.interface';
import { IResizeEvent } from 'angular2-draggable/lib/models/resize-event';
import { IPosition } from 'angular2-draggable';

@Component({
  selector: 'curr-ai-zones-marker',
  templateUrl: './ai-zones-marker.component.html',
  styleUrls: ['./ai-zones-marker.component.scss']
})
export class AiZonesMarkerComponent implements OnInit {

  @Input() oldZones: IZone[] = [];
  @Input() imageLink: string;
  @Output() zonesList = new EventEmitter<IZone[]>();

  @ViewChild('workBoundsParent') workBoundsParentElement: ElementRef;
  @ViewChild('workBounds') workBoundsElement: ElementRef;
  @ViewChildren('selectedZone') selectedZoneElements: QueryList<ElementRef>;

  imageSafe: any;
  zones: IZone[] = [];
  selectedZoneIndex = -1;
  isFirstLevelOpened = false;
  isIgnoreActive = false;

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (!this.workBoundsElement.nativeElement.contains(event.target)) {
      this.unselectAll();
    }
  }

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.imageSafe = this.sanitizer.bypassSecurityTrustUrl(this.imageLink);
    if (this.oldZones && this.oldZones.length > 0) {
      setTimeout(() => {
        this.zones = this.oldZones;
        this.sendZonesList();
      }, 500);
    }
  }

  switchFirstLevel() {
    this.isFirstLevelOpened = !this.isFirstLevelOpened;
  }

  switchIgnore() {
    if (this.selectedZoneIndex < 0) return;
    this.isIgnoreActive = !this.isIgnoreActive;
    this.zones[this.selectedZoneIndex].isIgnoreActive = this.isIgnoreActive;
    this.sendZonesList();
  }

  addZone(): void {
    const workBoundsParentElement = this.workBoundsParentElement.nativeElement as HTMLElement;
    const workBoundsParentRect = workBoundsParentElement.getBoundingClientRect();

    const workBoundsElement = this.workBoundsElement.nativeElement as HTMLElement;
    const workBoundsRect = workBoundsElement.getBoundingClientRect();

    const calculatePosition = (workBoundsRect.height - workBoundsRect.top) - workBoundsParentRect.height;

    const startY = workBoundsParentRect.height < workBoundsRect.height ? calculatePosition : 0;

    const newZone: IZone = {
      description: '',
      type: '',
      position: {
        start_x: 0,
        start_y: startY,
        width: 100,
        height: 100
      },
      isResizing: false,
      isSelected: false,
      isIgnoreActive: false,
      styles: {
        width: '100px',
        height: '100px',
      }
    };
    this.zones.push(newZone);
    this.selectedZoneIndex = -1;
    this.sendZonesList();
  }

  removeZone(): void {
    if (this.selectedZoneIndex < 0) return;
    this.zones.splice(this.selectedZoneIndex,1);
    this.isIgnoreActive = false;
    this.selectedZoneIndex = -1;
    this.sendZonesList();
  }

  onMoveEnd(event: IPosition, index: number): void {
    this.distanceCalculation(index);
  }

  onResizing(event: IResizeEvent, index: number) {
    this.zones[index].isResizing = true;
  }

  onResizeStop(event: IResizeEvent, index: number): void {
    this.zones[index].isResizing = false;

    this.zones[index].position.width = event.size.width;
    this.zones[index].position.height = event.size.height;

    this.zones[index].styles.width = `${event.size.width}px`;
    this.zones[index].styles.height = `${event.size.height}px`;

    this.distanceCalculation(index);
  }

  distanceCalculation(index: number): void {
    const parentElement = this.workBoundsElement.nativeElement as HTMLElement;

    this.selectedZoneElements.forEach((childRef, i) => {
      const childElement = childRef.nativeElement as HTMLElement;

      const parentRect = parentElement.getBoundingClientRect();
      const childRect = childElement.getBoundingClientRect();

      const distanceLeft = childRect.left - parentRect.left;
      const distanceTop = childRect.top - parentRect.top;

      this.zones[i].position.start_x = distanceLeft;
      this.zones[i].position.start_y = distanceTop;
    });

    this.sendZonesList();
  }

  selectZone(index: number): void {
    this.unselectAll();
    this.zones[index].isSelected = true;
    this.isFirstLevelOpened = true;
    this.selectedZoneIndex = index;
    this.isIgnoreActive = this.zones[index].isIgnoreActive;
    this.sendZonesList();
  }

  unselectAll(): void {
    this.zones = this.zones.map(item => {
      item.isSelected = false;
      return item;
    });
    this.selectedZoneIndex = -1;
    this.isIgnoreActive = false;
    this.sendZonesList();
  }

  private sendZonesList(): void {
    this.zonesList.emit(this.zones);
  }
}
