<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="ai-zones-marker">
  <div class="actions-list">
    <div class="actions-list-first" [class.actions-list-first-opened]="isFirstLevelOpened">
      <!-- <mat-icon
        [svgIcon]="'float-mark'"
        [class.action-disabled]="selectedZoneIndex < 0">
      </mat-icon> -->
      <mat-icon
        [class.action-disabled]="selectedZoneIndex < 0"
        [class.action-active]="isIgnoreActive"
        [svgIcon]="'float-ignore-area'"
        (click)="switchIgnore()">
      </mat-icon>
      <mat-icon
        *ngIf="selectedZoneIndex >= 0"
        [svgIcon]="'float-remove-selection'"
        (click)="removeZone()">
      </mat-icon>
      <mat-icon
        *ngIf="selectedZoneIndex < 0"
        [svgIcon]="'float-select-area'"
        (click)="addZone()">
      </mat-icon>
    </div>

    <div class="open-close-actions" (click)="switchFirstLevel()" [class.first-level-opened]="isFirstLevelOpened">
      <div class="open-close-actions-bg">
        <mat-icon [svgIcon]="'close-dialog'"></mat-icon>
      </div>
    </div>
  </div>

  <div class="work-section" #workBoundsParent>
    <div class="work-section-inside" #workBounds>
      <!--    <div class="work-section-img" [style]="{'background-image': 'url('+imageLink+')'}" (click)="unselectAll()"></div>-->
      <img [src]="imageSafe" alt="" (click)="unselectAll()">

      <div
        *ngFor="let zone of zones; let i = index"
        #selectedZone
        class="selected-zone"
        [class.resizing]="zone.isResizing"
        [class.selected]="zone.isSelected"
        [ngStyle]="zone.styles"
        ngDraggable
        ngResizable
        [rzHandles]="'se'"
        [rzContainment]="workBounds"
        [rzMinWidth]="50"
        [rzMinHeight]="50"
        [bounds]="workBounds"
        [inBounds]="true"
        [trackPosition]="true"
        [position]="{ x: zone.position.start_x, y: zone.position.start_y }"
        (click)="selectZone(i)"
        (endOffset)="onMoveEnd($event, i)"
        (rzResizing)="onResizing($event, i)"
        (rzStop)="onResizeStop($event, i)">
      </div>
    </div>
  </div>
</div>

