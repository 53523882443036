/**
 * Created by Daniil V. on 16/02/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */

import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[currCustomScroll]'
})
export class CustomScrollDirective implements AfterViewInit {
  @Input() suppressScrollX = true;
  perfectScroll: PerfectScrollbar;

  constructor(@Inject(DOCUMENT) private document: HTMLDocument, private el: ElementRef) {
  }

  ngAfterViewInit() {
    if (!this.perfectScroll && this.el.nativeElement) {
      this.initScroll();
    }
  }

  public initScroll(containerSelector = '') {
    this.perfectScroll?.destroy();
    const conatiner = containerSelector ? this.document.querySelector(containerSelector) : this.el.nativeElement;
    this.perfectScroll = new PerfectScrollbar(conatiner, {
      suppressScrollX: this.suppressScrollX,
      wheelPropagation: true
    });
  }
}
