/**
 * Created by Alex Poh. on 29/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import * as p5 from 'p5';

@Injectable()
export class DrawingBoardService {
  private p5;
  color = 'black';
  strokeWeight = 5;
  backgroundColor = 'white';
  parentId;

  createCanvas(parentId) {
    this.parentId = parentId;
    this.p5 = new p5(this.sketch);
  }

  private sketch = (p: any) => {
    p.setup = () => {
      const canvas = p.createCanvas(700, 600);
      canvas.parent(this.parentId);
      p.background(this.backgroundColor);
    };

    p.draw = () => {
      p.strokeWeight(this.strokeWeight);
      p.stroke(this.color);
      if (p.mouseIsPressed === true) {
        p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
      }
    };
  };

  clear() {
    this.p5.clear();
    this.p5.background(this.backgroundColor);
  }
}
