<!-- Created by Alex Poh. on 09/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-my-question-section">
  <curr-my-question-item [question]="question"></curr-my-question-item>

  <div class="multi-level-section" *ngIf="question.children.length > 0">
    <div class="pagination">
      <curr-button
        class="pagination-btn prev-btn"
        [size]="'big'"
        [isDisabled]="currentPage === 0"
        [variant]="'outlined'"
        (btnClick)="prevPage()">
        <mat-icon svgIcon="arrow-left"></mat-icon>
      </curr-button>
    
      <div class="pagination-middle">
        <div class="pagination-item" *ngFor="let page of getDisplayedPages(); let i = index">
          <a
            *ngIf="page !== '...'"
            href="javascript:void(0)"
            [class]="page?.levelClass"
            [class.active]="selectedQuestion.id === page.id"
            (click)="goToPage(page.id, i)">
            {{ page.label }}
          </a>

          <span class="page-dots" *ngIf="page === '...'">...</span>  
          <span *ngIf="i < getDisplayedPages().length - 1"> - </span>
        </div>
      </div>

      <curr-button
        class="pagination-btn next-btn"
        [size]="'big'"
        [isDisabled]="currentPage === (totalPages - 1)"
        [variant]="'outlined'"
        (btnClick)="nextPage()">
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </curr-button>
    </div>
  
    <curr-my-question-item [question]="selectedQuestion"></curr-my-question-item>
  </div>
</div>
