<!--Created by Maxim B. on 16/06/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-24">
  <h2 class="mat-h2 m-b-0" fxFlex>Question Database</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close(selectedQuestions)"></curr-icon-button>
</div>

<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-24">

  <div fxLayout="row" fxLayoutAlign="start start" class="m-b-0" fxLayoutGap="16px">
    <curr-dropdown fxFlex="200px" placeholder="All Question Types" [options]="ddQuestionTypeOptions"
                   (valueChange)="selectType($event)"></curr-dropdown>
  </div>

  <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
    <curr-input [isBordered]="false" placeholder="Search" fxFlex="50" (keyup)="filterEntries($event)"></curr-input>
    <mat-icon [svgIcon]="'search_black'"></mat-icon>
  </div>
</div>

<div class="full-width questions-container hidden-y-scroll">
  <curr-loader [diameter]="40" [isLoading]="isDataLoading" *ngIf="isDataLoading"></curr-loader>

  <div class="full-width" fxLayout="column" fxLayoutAlign="center center"
       *ngIf="!cumulativeQuestions.length && !isDataLoading">
    <span class="mat-body-2">We found 0 questions for this Topic.</span>
<!--    <div class="full-width" fxLayout="row" fxLayoutAlign="center center">-->
<!--      <curr-button-->
<!--        variant="text" (btnClick)="dialogRef.close([])"-->
<!--        class="add-question-btn m-t-16"> CLOSE-->
<!--      </curr-button>-->
<!--      <curr-button-->
<!--        variant="contained" (btnClick)="dialogRef.close({addManually:true})"-->
<!--        class="add-question-btn m-t-16"> Add Manually-->
<!--      </curr-button>-->
<!--    </div>-->
  </div>

  <curr-question-card
    [question]="question"
    [isAddToCTView]="true"
    [isInCT]="isQuestionInCT(question)"
    (onAddToCT)="onAddRemoveToCT($event, question)"
    [questionNumber]="i + 1"
    *ngFor="let question of cumulativeQuestions; let i = index"
  >
  </curr-question-card>
</div>
