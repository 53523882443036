/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../../shared/utils/custom-validators';

@Component({
  selector: 'curr-edit-test-name-popup',
  templateUrl: './edit-test-name-popup.component.html',
  styleUrls: ['./edit-test-name-popup.component.scss']
})
export class EditTestNamePopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditTestNamePopupComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data.name, [Validators.required, Validators.maxLength(125)]]
    });
  }

  saveName() {
    this.dialogRef.close(this.form.get('name').value);
  }
}
