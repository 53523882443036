<div class="pagination-wrapper button" fxLayout="row" fxLayoutAlign="center center">
  <div class="nav-item" fxLayoutAlign="start center" *ngIf="isSideBtnVisible">
    <curr-button
      (btnClick)="prevQuestion()"
      class="curr-button"
      size="big"
      [isDisabled]="currentSelectedPaginationIndex == 0"
      variant="outlined">
      <mat-icon svgIcon="arrow-left"></mat-icon>
      PREV
    </curr-button>
  </div>
  <div #pagination class="pagination-items" fxLayout="row">
    <div
      class="pagination-list"
      fxLayoutAlign="center center"
      *ngFor="let q of paginationLabels; let index = index"
      [class.current]="q.id === selectedLabel.id"
      [ngStyle]="{color: getColor(q.isAutomarked) }"
      [class.checked]="checkedQuestions.length && checkedQuestions.includes(index) && !isTTTeacher"
      [class.not-checked]="checkedQuestions.length && !checkedQuestions.includes(index)"
      (click)="selectQuestion(index)"
    >
      <a class="cursor-pointer step" fxLayoutAlign="center center">
        <span>{{ q.label }}</span>
      </a>
    </div>
  </div>

  <div class="nav-item" fxLayoutAlign="end center" *ngIf="isSideBtnVisible">
    <curr-button
      (btnClick)="nextQuestion()"
      class="curr-button"
      size="big"
      [isDisabled]="currentSelectedPaginationIndex == paginationLabels.length - 1"
      variant="outlined">
      NEXT
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </curr-button>
  </div>
</div>
