import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { getRestApiProvider, RestApiService } from '../../../../shared/api/rest-api.service';
import { COUResponse, LearningObjective } from '../../api-subject-details';
import { Observable, Subject } from 'rxjs';
import { auditTime, switchMap, takeUntil } from 'rxjs/operators';
import { ConfirmPopupComponent } from '../../../../shared/components/confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'curr-cou',
  templateUrl: './cou.component.html',
  styleUrls: ['./cou.component.scss'],
  providers: [getRestApiProvider<COUResponse>('curriculumCoUQuestions')]
})
export class CouComponent implements OnInit, OnDestroy {
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  btnStyle = { padding: 0 };

  @Input()
  subjectDetailsId: string;

  @Input()
  topic: LearningObjective;

  @Output()
  topicUpdated = new EventEmitter<boolean>();

  disabledStatus = true;
  isAddingNewCou = false;
  options: any[] = [];
  isEmpty = true;
  isAddFormVisible = false;
  questions: COUResponse[];
  getAll$: Observable<COUResponse[]>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  couUpdates$: Subject<COUResponse> = new Subject<COUResponse>();
  newCou: COUResponse;
  selectedIndex = 0;

  constructor(private couService: RestApiService<COUResponse>,
              private toastr: ToastrService,
              public dialog: MatDialog) {
  }

  isEmptyFields(element) {
    return element.length > 0;
  }

  ngOnInit() {
    this.getAll$ = this.couService.getAll({ curriculum_id: this.subjectDetailsId });
    this.getAll$.subscribe(res => {
      this.questions = res;

      this.tabGroup.selectedIndex = this.selectedIndex;
    });
    this.couUpdates$
      .pipe(auditTime(2000))
      .pipe(
        switchMap(res => {
          return this.couService.update(res, res.id);
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe(r => {
        this.topicUpdated.emit(true);
        this.toastr.info('Cou was updated');
      });
    this.newCou = {
      question: '',
      curriculum_id: this.subjectDetailsId,
      is_published: false,
      is_right: !this.selectedIndex
    };
  }

  saveUpdates() {
    this.disabledStatus = true;
  }

  enableCouForm() {
    this.newCou = {
      question: '',
      curriculum_id: this.subjectDetailsId,
      is_published: false,
      is_right: !this.selectedIndex
    };

    this.isAddFormVisible = true;
  }

  addNew() {
    if (this.isAddingNewCou) {
      return;
    }
    const newCou = Object.assign({}, this.newCou);
    this.isAddingNewCou = true;
    this.couService.add(newCou).subscribe(
      res => {
        this.topicUpdated.emit(true);
        this.questions.push({ ...newCou, id: res.id });
        this.couUpdates$.next({ ...newCou, id: res.id });
        this.isAddFormVisible = false;
        this.toastr.success('New Cou was added!');
      },
      () => {},
      () => {
        this.isAddingNewCou = false;
        this.disabledStatus = false;
      }
    );
  }

  updateCou(question: COUResponse, newText: string) {
    if (!question.question) {
      return;
    }
    this.couUpdates$.next({ ...question, question: newText });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  deleteCou(i: number) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      data: {
        questionTitle: 'Delete this COU?'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.couService.remove(this.questions[i].id).subscribe(resp => {
          this.questions.splice(i, 1);
          this.toastr.info('Cou was deleted');
        });
      }
    });
  }

  setCou(val: string) {
    this.newCou.question = val;
    if (val.length) {
      this.addNew();
    }
  }

  tabSelectedIndexChange(event) {
    this.selectedIndex = event;
    this.newCou.is_right = !this.selectedIndex;
  }
}
