/**
 * Created by Alex Poh. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { NavigationAnimationService } from '../../shared/animations/navigation-animation.service';
import { navigationAnimation } from '../../shared/animations/navigation.animation';
import { catchError, filter, map, pairwise, switchMap } from 'rxjs/operators';
import { Paper, SubjectDetails } from './api-subject-details';
import { EditTestNamePopupComponent } from '../edit-test/edit-test-name-popup/edit-test-name-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { getRestApiProvider, RestApiService } from '../../shared/api/rest-api.service';
import { Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { SubjectDetailsService } from './subject-details.service';
import { Location } from '@angular/common';

@Component({
  selector: 'curr-subject-details',
  templateUrl: './subject-details.component.html',
  styleUrls: ['./subject-details.component.scss'],
  animations: [navigationAnimation],
  providers: [
    getRestApiProvider<Paper>('curriculumPapers', 'curriculumPapers'),
    getRestApiProvider<{ id: string; is_published: boolean }>('publishedStatus', 'publishedStatus'),
    getRestApiProvider<{ id: string; scheme_work_title: string }>('updateTitleSchemeWork')
  ]
})
export class SubjectDetailsComponent implements OnInit, OnDestroy {
  subject: SubjectDetails;
  isNewSubject = false;
  ddOptionsSubject = [];
  ddOptionsGrade = [];
  ddOptionsLevels = [];
  buttons: Array<{ label: string; action?: () => void }> = [];
  animationData: { animationState: string } = { animationState: undefined };
  yearStart;
  yearEnd;

  getPapers$: Observable<Paper[]>;
  path = [
    {
      label: 'Subjects',
      url: ''
    }
  ];
  paperBackBtnSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private navigationAnimationService: NavigationAnimationService,
    @Inject('curriculumPapers') private paperService: RestApiService<Paper>,
    public dialog: MatDialog,
    @Inject('publishedStatus') private publishedStatusService: RestApiService<{ id: string; is_published: boolean }>,
    private updateTitleSchemeWorkService: RestApiService<{ id: string; scheme_work_title: string }>,
    private toastr: ToastrService,
    private subjectDetailsService: SubjectDetailsService,
    private location: Location) {
  }

  ngOnInit(): void {
    const snapshotData = this.route.snapshot.data;
    this.isNewSubject = snapshotData.isNew;
    // this.isNewSubject = true;

    if (this.isNewSubject) {
      this.path = [
        {
          label: 'Home',
          url: '/dashboard'
        },
        {
          label: 'Courses',
          url: '/subjects'
        },
        {
          label: snapshotData.course
            ? `${ snapshotData.subjectDetails.subject }, ${ snapshotData.subjectDetails.title }, ${ snapshotData.course?.courseid }`
            : `${ snapshotData.subjectDetails.subject }, ${ snapshotData.subjectDetails.title }`,
          url: ''
        }
      ];

      this.subject = this.route.snapshot.data.subjectDetails;
      this.getPapers$ = this.paperService
        .getAll({ curriculum_id: this.subject.id })
        .pipe(
          map(res => {
            this.subject.papers = res;
            return res;
          })
        )
        .pipe(
          map(res => {
            if (res.length === 0) {
              this.addPaper();
            }
            return res;
          })
        );
      this.getPapers$.subscribe();

      // this.route.snapshot.data.subjectDetails = this.subject;
      this.initOptionsForFilters();
    } else {
      this.subject = this.route.snapshot.data.subjectDetails;
      // this.resolveButtons();
    }
    this.resolveButtons();

    this.navigationAnimationService.init(); // Disables default animation.
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        pairwise()
      )
      .subscribe((events: [RoutesRecognized, RoutesRecognized]) => {
        this.updateAnimationData(events[0].url, events[1].url);
      });

    this.paperBackBtnSubscription = this.subjectDetailsService.paperBackBtn$.subscribe(isBack => {
      if (isBack) {
        this.location.back();
      } else {
        if (!this.route.children[0]?.snapshot.paramMap.get('paperId') && this.subject.papers[0]) {
          this.router.navigate(['paper', this.subject.papers[0].id], {
            relativeTo: this.route
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.navigationAnimationService.destroy();
    this.paperBackBtnSubscription?.unsubscribe();
    this.subjectDetailsService.paperBackBtnSubject.next(null);
  }

  resolveButtons() {
    const lastSegment = this.router.url.substring(this.router.url.lastIndexOf('/') + 1);
    if (lastSegment === 'ganttChartD3' || lastSegment === 'sunburst') {
      this.buttons = [{ label: 'Sunburst diagram' }, { label: 'Snake diagram' }, { label: 'Detailed View' }];
    } else {
      this.buttons = [
        {
          label: 'Publish',
          action: () => {
            this.publish();
          }
        }
      ];
    }
  }

  onChartClick = () => {
    this.router.navigateByUrl(this.router.url.split('?')[0] + '/ganttChart');
  };

  save() {
  }

  changeSelection(val: any, prop: string) {
    if (prop === 'yearStart') {
      this.yearStart = val;
    }
    if (prop === 'yearEnd') {
      this.yearEnd = val;
    }
    this.subject[prop] = val;
  }

  initOptionsForFilters() {
    for (let i = 1; i <= 12; i++) {
      this.ddOptionsGrade.push({ value: i, label: `Year ${ i }` });
      this.ddOptionsLevels.push({ value: i, label: `Level ${ i }` });
    }
    this.ddOptionsSubject = [
      { value: 'biology', label: 'Biology' },
      { value: 'math', label: 'Math' },
      { value: 'physics', label: 'Physics' }
    ];
  }

  // TODO: migrate this to api model
  addPaper() {
    //   this.subject.papers.push({
    //     name: 'New Paper',
    //     about: [],
    //     topicGroups: [],
    //     examStartDate: new Date(),
    //     examEndDate: new Date()
    //   });
    let newPaperId: string;
    this.paperService
      .add({
        curriculum_id: this.subject.id + '',

        is_published: false,

        sequence_num: this.subject.papers?.length + 1 + '',

        title: `Paper ${ this.subject.papers?.length + 1 }`
      })
      .pipe(
        switchMap(res => {
          newPaperId = res.id;
          return this.getPapers$;
        })
      )
      .subscribe(res => {
        this.router.navigate(['paper', newPaperId], { relativeTo: this.route });
      });
  }

  updateAnimationData(oldUrl, newUrl) {
    if (
      newUrl &&
      (!oldUrl ||
        oldUrl.indexOf('ganttChart') > -1 ||
        oldUrl.indexOf('sunburst') > -1 ||
        newUrl.indexOf('ganttChart') > -1 ||
        newUrl.indexOf('sunburst') > -1)
    ) {
      this.animationData = {
        animationState: newUrl
      };
    }
  }

  editName() {
    const dialogRef = this.dialog.open(EditTestNamePopupComponent, {
      width: '424px',
      data: this.subject
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // @ts-ignore
        this.updateTitleSchemeWorkService
          .updateWithoutId({ id: this.subject.sowId, scheme_work_title: result })
          .subscribe(res => {
            this.subject.title = result;
          });
      }
    });
  }

  getYearLabel() {
    if (this.yearStart === 10 && this.yearEnd === 11) {
      return 'Year range 10-11 = IGCSE';
    }
    if (this.yearStart === 11 && this.yearEnd === 12) {
      return 'Year range 11-12 = A-Level';
    }
    return '';
  }

  publish() {
    this.publishedStatusService
      .updateWithoutId({ id: this.subject.id, is_published: true })
      .pipe(
        catchError(err => {
          this.toastr.error('Something went wrong');
          // Commented of reason that of operator is unreachable.
          // throw new Error('');
          return of(null);
        })
      )
      .subscribe(res => {
        this.toastr.success('Your sow was successfully published.');
      });
  }
}
