<!--
 Created by Volodymyr Mak. on 6/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<!-- <div class="curr-progress-bar-gradient progress-container">
  <div class="progress-bar" [style.--progress.%]="progress"></div>
</div> -->

<div class="curr-progress-bar-gradient progress-container">
  <div
    class="progress-bar"
    [ngClass]="getPerformanceClass()"
    [style.--progress.%]="getProgress()">
  </div>
</div>
