<!-- Created by Alex Poh. on 07/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-card fxLayout="column center center" (click)="navigateToComponent()">
  <div class="img-container">
    <img *ngIf="!isFake" class="preview-image" [src]="'assets/images/demo/pages/' + component.image" alt="image" />
    <img *ngIf="isFake" class="preview-image" [src]="'assets/images/demo/fake-pages/' + component.image" alt="image" />
  </div>
  <div fxFlex>
    {{ component.title }}
  </div>
</mat-card>
