/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YearSettings, YearTermType } from '../../../../school-admin/year-settings/year-settings';

@Component({
  selector: 'curr-ys-step-one',
  templateUrl: './ys-step-one.component.html',
  styleUrls: ['./ys-step-one.component.scss']
})
export class YsStepOneComponent implements OnInit {

  @Input() isNew = true;
  @Input() isInPopup = false;
  @Input() ys: YearSettings;
  @Output() ysChange = new EventEmitter<YearSettings>();
  @Output() onNextCancel = new EventEmitter<string>();

  ytt = YearTermType;

  ddYearGroupOption = [
    { label: this.ytt.YEAR, value: this.ytt.YEAR },
    { label: this.ytt.CLASS, value: this.ytt.CLASS },
    { label: this.ytt.FORM, value: this.ytt.FORM },
    { label: this.ytt.GRADE, value: this.ytt.GRADE },
    { label: 'Different terms for different years', value: this.ytt.DIFFERENT }
  ];
  ddYearGroupOptionForGrade = [
    { label: this.ytt.YEAR, value: this.ytt.YEAR },
    { label: this.ytt.CLASS, value: this.ytt.CLASS },
    { label: this.ytt.FORM, value: this.ytt.FORM },
    { label: this.ytt.GRADE, value: this.ytt.GRADE },
    { label: 'Other', value: this.ytt.DIFFERENT }
  ];

  constructor() {
  }

  ngOnInit(): void {
  }

  setYearType(val: string) {
    this.ys.yearTermType = val;
    this.ys.setYearTypeForEachYear();

  }

  setYearTypeForGrade(val: string, i: number) {
    this.ys.yearTypeForEachYear[i].termType = val;
  }

  goToNext(step:string) {
    this.onNextCancel.emit(step)
  }

  validateData() {
    return this.ys.yearTypeForEachYear.find(s => {
      return s.termType === this.ytt.DIFFERENT && s.customType.length === 0;
    });
  }

}
