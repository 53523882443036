<!--
 Created by Dmytro Sav. on 5/11/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div fxLayout="row" fxLayoutGap="30px">
  <div class="add_photo cursor-pointer" fxLayout="row" fxLayoutAlign="center center">
    <span *ngIf="!preview" class="text-underline" (click)="uploadPhoto()">Add photo</span>
    <img *ngIf="preview" src="{{ preview }}" alt="image" />
  </div>

  <div fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="38px" class="m-b-16">
      <span>Add teacher by CSV</span>
      <span class="text-underline cursor-pointer">Download Template</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <curr-button (btnClick)="chooseFile()">Choose file</curr-button>
      <curr-button (btnClick)="loadInfo()">Load info</curr-button>
    </div>

    <form [formGroup]="form" fxLayout="column" fxFlex="326px" fxLayoutGap="15px" fxLayoutAlign="center">
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
        <curr-input placeholder="First Name" controlName="name" [formGroup]="form"></curr-input>
        <curr-input placeholder="ID" controlName="id" [formGroup]="form"></curr-input>
        <curr-input placeholder="Phone" controlName="phoneNumber" [formGroup]="form"></curr-input>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px">
        <curr-input placeholder="Last Name" controlName="surname" [formGroup]="form"></curr-input>
        <curr-input placeholder="Email" controlName="email" [formGroup]="form"></curr-input>
        <curr-dropdown
          fxFlex="126px"
          controlName="country"
          [formGroup]="form"
          placeholder="Country"
          [options]="options"
          (valueChange)="getCountry($event)"
        ></curr-dropdown>
      </div>
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" *ngIf="form.controls['school']">
        <curr-input placeholder="School" controlName="school" [formGroup]="form"></curr-input>
      </div>
    </form>
  </div>
</div>

<input
  type="file"
  #photoUpload
  id="fileUpload"
  name="fileUpload"
  multiple="multiple"
  accept="image/jpeg,image/png,image/gif"
  hidden
/>
