/**
 * Created by Dmytro Sav. on 4/30/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService } from '../../shared/user.service';
import { Role } from '../../shared/role';
import { CourseDetailsService } from '../../teacher/services/course-details.service';
// import { FirebaseNotificationService } from '../../shared/services/firebase-notification.service';

@Component({
  selector: 'curr-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnInit, OnDestroy {

  availableCourses: [];
  studentCourses: [];
  isStudent = false;
  isLoading = false;
  dataOffset = 0;
  hint = {
    icon: 'warn',
    label: '',
    color: 'warn',
    isUnderLine: false
  };
  cards = [];
  isExamPreparation: boolean = false;
  isLoadedSubscriptionInfo: boolean = false;

  private subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private courseDetailsService: CourseDetailsService,
    public userService: UserService,
    // private notificationService: FirebaseNotificationService,
  ) {
  }

  ngOnInit(): void {
    // this.notificationService.showNotification('You have a Tutor session in 5 minutes');
    this.subscription.add(
      this.userService.hasRoles([Role.Teacher])
        .subscribe(isTeacher => {
          if (isTeacher) {
            this.cards = [];
          }
        }));

    this.subscription.add(
      this.userService.hasRoles([Role.Student])
        .subscribe(isStudent => {
          this.isStudent = isStudent;
          if (isStudent) {
            this.studentCourses = this.route.snapshot.data.studentCourse;
            // if (!this.studentCourses.length) {
            //   this.addSubject();
            // }
          }
        }));

    this.getUserSubscriptionInfo();
  }

  private getUserSubscriptionInfo() {
    this.subscription.add(
      this.userService.getSubscriptioninfo().subscribe(resp => {
        if (resp.length > 0) {
          this.isExamPreparation = resp[0].paymentPlanType === 'EXAM_PREPARATION';
        }
        this.isLoadedSubscriptionInfo = true;
      }));
  }

  btnClick(id): void {
    const result = this.cards.filter(card => {
      return card.id === id;
    });

    this.courseDetailsService.updateData(result[0]);
    this.router.navigateByUrl('/subject-details');
  }

  goToAddSubjectsPage(): void {
    this.router.navigateByUrl('/add-subjects');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // addSubject() {
  //   const dialogRef = this.dialog.open(AddCoursePopupComponent, {
  //     width: '424px',
  //     data: { availableCourses: this.availableCourses }
  //   });
  //
  //   dialogRef.afterClosed().subscribe((result: []) => {
  //     if (this.isStudent) {
  //       this.loadStudentCourses();
  //     }
  //   });
  // }

  // loadStudentCourses() {
  //   this.isLoading = true;
  //   this.subscription.add(
  //   this.httpClient
  //     .post(`/schoolYearCourses/getStudentSubjects`, {
  //       period_from: '2020-06-20 12:44:33',
  //       period_to: '2023-06-21 12:44:33',
  //       rows_count: 10,
  //       row_offset: this.dataOffset
  //     })
  //     .pipe(
  //       map((res: any) => {
  //         return (res).data.items;
  //       })
  //     )
  //     .subscribe(courses => {
  //       this.studentCourses = courses;
  //     }, () => {
  //     }, () => {
  //       if ((this.studentCourses.length % 10) === 0) {
  //         this.dataOffset += 10;
  //       }
  //       this.isLoading = false;
  //     }));
  // }
}
