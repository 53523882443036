/**
 * Created by Alex Poh. on 09/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionType } from '../../../../../student/test.model';

@Component({
  selector: 'curr-my-question-section',
  templateUrl: './my-question-section.component.html',
  styleUrls: ['./my-question-section.component.scss'],
  providers: []
})
export class MyQuestionSectionComponent implements OnInit {

  @Input() question: Question;
  @Input() questionNumber: number;

  currentPage = 0;
  currentPageId = null;
  totalPages: number;
  pages: {levelClass: string, label: string, id: number}[] = [];
  childQuestions: Question[] = [];
  selectedQuestion: Question;

  constructor() {}

  ngOnInit(): void {
    if(this.question.children.length > 0) {
      this.generatePageList();
    }
  }

  getQuestionById() {
    this.selectedQuestion = this.childQuestions.find(item => item.id === this.currentPageId);
  }

  generatePageList() {
    this.question.children.forEach((item, index) => {
      this.pages.push({levelClass: 'first-level', label: `${this.questionNumber}.${index + 1}`, id: item.id});
      this.childQuestions.push(item);

      if (item.children.length > 0) {
        item.children.forEach((itemSecond, indexChild) => {
          this.pages.push({levelClass: 'second-level', label: `${this.questionNumber}.${index + 1}.${indexChild + 1}`, id: itemSecond.id});
          this.childQuestions.push(itemSecond);
        })
      }
    });

    this.totalPages = this.childQuestions.length;
    this.currentPageId = this.pages[0].id;
    this.getQuestionById();
  }

  prevPage() {
    if (this.currentPage > 0) {
      this.currentPage--;

      if (!this.pages[this.currentPage].id) {
        this.currentPageId = this.pages[this.currentPage - 1].id;
      } else {
        this.currentPageId = this.pages[this.currentPage].id;
      }
      this.getQuestionById();
    }
  }

  nextPage() {
    if (this.currentPage < (this.totalPages - 1)) {
      this.currentPage++;

      if (!this.pages[this.currentPage].id) {
        this.currentPageId = this.pages[this.currentPage + 1].id;
      } else {
        this.currentPageId = this.pages[this.currentPage].id;
      }
      this.getQuestionById();
    }
  }

  goToPage(pageId: number, pageIndex: number) {
    this.currentPage = pageIndex;
    this.currentPageId = pageId;
    this.getQuestionById();
  }

  getDisplayedPages() {
    const totalPages = this.pages.length;
    const currentPage = this.currentPage;
    const maxPagesToShow = 8;

    if (totalPages <= maxPagesToShow) {
      return this.pages;
    }

    let pages = this.pages;
    
    let nextFirstPageIndex = 1;
    if (totalPages > 3) {
      pages = [this.pages[0], this.pages[1]];
      nextFirstPageIndex = 2;
    } else {
      pages = [this.pages[1]];
    }

    if (currentPage > 2) {
      pages.push({ levelClass: '', label: '...', id: null });
    }

    const startPage = Math.max(nextFirstPageIndex, currentPage - 1);
    const endPage = Math.min(totalPages - 2, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(this.pages[i]);
    }

    if (currentPage < totalPages - 3) {
      pages.push({ levelClass: '', label: '...', id: null });
    }

    pages.push(this.pages[totalPages - 1]);

    return pages;
  }
}
