<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="doughnut-chart-container">
  <canvas baseChart
          class="doughnut-chart"
          [data]="doughnutChartData"
          [type]="doughnutChartType"
          [options]="doughnutChartOptions">
  </canvas>
  <div class="doughnut-chart-data" *ngIf="statisticData">
    <curr-statistic-expansion-panel
      [classSpanName]="'color-1'"
      [isDisabled]="statisticData.correct.length === 0"
      [title]="'Correct Answers ('+statisticData.correctAnswers+'/'+statisticData.answers+')'" [isShowed]="false">
      <div class="users-list">
        <div class="user-line" *ngFor="let correct of statisticData.correct" (click)="scrollToSection(correct)">{{ correct }}</div>
      </div>
    </curr-statistic-expansion-panel>
    <curr-statistic-expansion-panel
      [classSpanName]="'color-2'"
      [isDisabled]="statisticData.partiallyCorrect.length === 0"
      [title]="'Partially Correct Answers ('+statisticData.partiallyCorrectAnswers+'/'+statisticData.answers+')'" [isShowed]="false">
      <div class="users-list">
        <div class="user-line" *ngFor="let partiallyCorrect of statisticData.partiallyCorrect" (click)="scrollToSection(partiallyCorrect)">{{ partiallyCorrect }}</div>
      </div>
    </curr-statistic-expansion-panel>
    <curr-statistic-expansion-panel
      [classSpanName]="'color-3'"
      [isDisabled]="statisticData.incorrect.length === 0"
      [title]="'Incorrect Answers ('+statisticData.incorrectAnswers+'/'+statisticData.answers+')'" [isShowed]="false">
      <div class="users-list">
        <div class="user-line" *ngFor="let incorrect of statisticData.incorrect" (click)="scrollToSection(incorrect)">{{ incorrect }}</div>
      </div>
    </curr-statistic-expansion-panel>
  </div>
</div>
