/*
 * Created by Dmytro Sav. on 5/11/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curr-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  currSelectedTabIndex = 0;
  tabLabels = ['Statistics', 'Notes'];
  @Input() data: any;

  constructor() {}

  changeTab(event) {
    this.currSelectedTabIndex = event;
  }

  ngOnInit() {}
}
