import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { IAIMarkSchemeResponse, IAIQuestionResponse, IAIRequest } from './ai-assistant-dialog.interface';

@Injectable()
export class AiAssistantDialogService {

  constructor(private httpClient: ApplicationHttpClient){}

  sendQuestionAI(aiData: IAIRequest): Observable<IAIQuestionResponse> {
    return this.httpClient.post<IAIQuestionResponse>(`/recognition/question`, aiData);
  }

  sendMarkSchemeAI(aiData: IAIRequest): Observable<IAIMarkSchemeResponse> {
    return this.httpClient.post<IAIMarkSchemeResponse>(`/recognition/mark-scheme`, aiData);
  }
}
