<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="movable-section">
  <div class="vertical-line first-line"></div>
  <div class="vertical-line second-line"></div>
  <div class="vertical-line third-line"></div>

  <mat-icon
    *ngIf="isShowAIBtn"
    class="recognized-text-icon"
    svgIcon="menu-book"
    (click)="toggleRecognizedTextDialog()"
  ></mat-icon>
  <curr-movable-dialog
    *ngIf="isRecognizedTextShow"
    [stepNumber]="stepNumber"
    [originalQuestionContent]="originalQuestionContent"
    [originalMarkSchemeContent]="originalMarkSchemeContent"
    (onCloseDialog)="toggleRecognizedTextDialog()"
  ></curr-movable-dialog>

  <div class="movable-section-header">
    <div class="level-item">Question Level</div>
    <div class="level-item">Second Level</div>
    <div class="level-item">Third Level</div>
  </div>
  <div
    class="movable-section-item first-level"
    *ngFor="let firstLevel of questions; let i = index; let firstLevelLast = last"
  >
    <curr-movable-item
      [form]="form"
      [stepNumber]="stepNumber"
      [itemData]="firstLevel"
      [firstLevelAmount]="questions.length"
      [itemIndexWithParents]="[i]"
      [itemFirst]="i === 0"
      [itemLast]="firstLevelLast"
      [topics]="topics"
      [dialogMode]="dialogMode"
      [isMultiLevel]="firstLevel.children.length > 0"
      (onAddNew)="onAddItem($event)"
      (onChangePosition)="onChangeItemPosition($event)"
    ></curr-movable-item>

    <div
      class="movable-section-item second-level"
      *ngFor="let secondLevel of firstLevel.children; let j = index; let secondLevelLast = last"
    >
      <curr-movable-item
        [form]="form"
        [stepNumber]="stepNumber"
        [itemData]="secondLevel"
        [itemIndexWithParents]="[i, j]"
        [itemFirst]="j === 0"
        [itemLast]="secondLevelLast"
        [topics]="topics"
        [dialogMode]="dialogMode"
        [isMultiLevel]="firstLevel.children.length > 0"
        (onAddNew)="onAddItem($event)"
        (onChangePosition)="onChangeItemPosition($event)"
      ></curr-movable-item>

      <div
        class="movable-section-item third-level"
        *ngFor="let thirdLevel of secondLevel.children; let k = index; let thirdLevelLast = last"
      >
        <curr-movable-item
          [form]="form"
          [stepNumber]="stepNumber"
          [itemData]="thirdLevel"
          [itemIndexWithParents]="[i, j, k]"
          [itemFirst]="k === 0"
          [itemLast]="thirdLevelLast"
          [topics]="topics"
          [dialogMode]="dialogMode"
          [isMultiLevel]="firstLevel.children.length > 0"
          (onAddNew)="onAddItem($event)"
          (onChangePosition)="onChangeItemPosition($event)"
        ></curr-movable-item>
      </div>
    </div>
  </div>
</div>
