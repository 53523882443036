/**
 * Created by Alex Poh. on 18/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

export enum Role {
  Unauthorized = 'unauthorized',
  Student = 'STUDENT',
  Teacher = 'TEACHER',
  SchoolAdmin = 'SCHOOL_ADMIN',
  HubAdmin = 'HUB_ADMIN'
}
