import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TimeDateService {

  public convertToLocalTime(date: string, time: string = ''): Date {
    const formatedTime = moment.utc(`${date} ${time}`, 'yyyy-MM-DD HH:mm A').format('yyyy-MM-DDTHH:mm:ss');
    const localTime = moment.utc(formatedTime).local().format('YYYY-MM-DDTHH:mm:ss');
    return new Date(localTime);
  }

  public convertToLocalTimeFromMs(dateTime : string): Date {
    const dateCreateDone = moment(new Date(dateTime)).format('yyyy-MM-DDTHH:mm');
    return new Date(dateCreateDone);
  }
}
