/**
 * Created by Maxim B. on 07/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import YouTubePlayer from 'node_modules/youtube-player';
import Player from '@vimeo/player';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../user.service';
import { Role } from '../../role';
import { Subscription } from 'rxjs';
import { LinkPreviewService } from './link-preview.service';
import { MediaItem, MediaType } from './api-media-item';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { ImageViewPopupDialogComponent } from './image-view-popup-dialog/image-view-popup-dialog.component';
import { CustomValidators } from '../../utils/custom-validators';

@Component({
  selector: 'curr-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss'],
  providers: []
})
export class MediaItemComponent implements OnChanges, AfterViewInit, OnDestroy, OnInit {
  player: any;
  mediaItemForm: FormGroup;
  @Input() isEditable = true;
  @Input() setupOnEditMode = false;
  @Input() mediaItem: MediaItem;
  @Output() onNewlyCreatedItem = new EventEmitter<MediaItem>();
  @Output() onEditItem = new EventEmitter<MediaItem>();
  @Output() onDeleteItem = new EventEmitter<MediaItem>();
  @Output() onCancelNewlyCreatedItem = new EventEmitter<boolean>();
  @ViewChild('mediaContainer') container: ElementRef;
  inEditMode = false;
  isMediaItemVisible = false;
  isVideoDescVisible = false;
  videoId = '';
  isVimeoVideo = false;
  isVimeoPlayerOn = false;
  containerId = '';
  currUser: any;
  roles: any;
  userSubscription: Subscription;
  imgUrl;
  docFile;
  fileInputBtnStyle = { padding: '0 12px', textAlign: 'start' };

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private fileUploadService: FileUploadService,
    private linkPreviewService: LinkPreviewService
  ) {
    this.roles = Role;
    this.userSubscription = this.userService.getCurrentUser().subscribe(resp => {
      this.currUser = resp;
    });
  }

  ngOnInit(): void {
    if (this.setupOnEditMode) {
      this.editMediaItem();
    }
    if (this.mediaItem.resource_type === MediaType.image && this.mediaItem.resourceData) {
      this.imgUrl = this.fileUploadService.getImageFromBytes(this.mediaItem.resourceData);
    }
    if (this.mediaItem.resource_type === MediaType.document && this.mediaItem.resourceData) {
      this.docFile = this.sanitizer.bypassSecurityTrustUrl(
        'data:application/octet-stream;base64,' + this.mediaItem.resourceData
      );
    }
  }

  ngAfterViewInit(): void {
    if (this.mediaItem.resource_type === MediaType.video) {
      if (!this.isVimeoVideo) {
        this.extractYoutubeVideoId();
        this.createYoutubePlayer();
      } else {
        this.createVimeoPlayer();
      }
    }
  }

  capitalize(control: AbstractControl) {
    control.valueChanges.subscribe(() => {
      if (control.value) {
        control.patchValue(control.value[0].toUpperCase() + control.value.substr(1), { emitEvent: false });
      }
    });
  }

  ngOnChanges(): void {
    if (this.mediaItem.resource_type !== MediaType.document) {
      this.isMediaItemVisible = true;
    }
    if (this.mediaItem.resource_type === MediaType.video) {
      this.extractVimeoVideoId();
      if (!this.isVimeoVideo) {
        this.extractYoutubeVideoId();
      }
    }
    if (this.mediaItem.resource_type === MediaType.link) {
      this.linkPreviewService.getLinkPreview(this.mediaItem.resource).subscribe((resp: any) => {
        if (resp.image) {
          this.mediaItem.previewUrl = resp.image;
        }
      });
    }
  }

  expand() {
    this.isMediaItemVisible = !this.isMediaItemVisible;
    if (!this.isMediaItemVisible && this.mediaItem.resource_type === MediaType.video) {
      this.stopVideo();
    }
  }

  expandDesc() {
    this.isVideoDescVisible = !this.isVideoDescVisible;
  }

  openImagePreviewDialog() {
    this.dialog.open(ImageViewPopupDialogComponent, {
      data: { url: this.imgUrl }
    });
  }

  editMediaItem() {
    this.stopVideo();
    this.isMediaItemVisible = false;
    if (this.mediaItem.resource_type !== MediaType.image && this.mediaItem.resource_type !== MediaType.document) {
      this.mediaItemForm = this.fb.group({
        title: new FormControl(this.mediaItem.title, [Validators.required]),
        description: new FormControl(this.mediaItem.description, [Validators.required]),
        resource: new FormControl(this.mediaItem.resource, [Validators.required]),
        estimated_time: new FormControl(this.mediaItem.estimated_time, [Validators.required])
      });
    } else {
      this.mediaItemForm = this.fb.group({
        title: new FormControl(this.mediaItem.title, [Validators.required]),
        description: new FormControl(this.mediaItem.description, [Validators.required])
      });
    }
    if (this.mediaItem.resource_type === MediaType.video) {
      this.mediaItemForm.controls.resource.setValidators([Validators.required, CustomValidators.validateVideoUrl]);
    }
    this.inEditMode = true;
    // const title = this.mediaItemForm.get('title');
    // const description = this.mediaItemForm.get('description');
    // this.capitalize(title);
    // this.capitalize(description);
  }

  deleteMediaItem() {
    this.onDeleteItem.emit(this.mediaItem);
    this.stopVideo();
  }

  onSubmit() {
    if (this.player) {
      this.inEditMode = false;
      this.extractVimeoVideoId();
      this.extractYoutubeVideoId();
      this.checkIfNeedToChangePlayer();
    }

    if (this.mediaItem.resource_type === MediaType.image || this.mediaItem.resource_type === MediaType.document) {
      this.mediaItem = {
        ...this.mediaItem,
        ...this.mediaItemForm.value,
        resource: this.mediaItem.resource
      };
    } else {
      this.mediaItem = {
        ...this.mediaItem,
        ...this.mediaItemForm.value,
        resource: this.mediaItemForm.value.resource ? this.mediaItemForm.value.resource.replace(/\&.*/, '') : ''
      };
    }

    this.inEditMode = false;
    if (this.mediaItem.id) {
      this.onEditItem.emit(this.mediaItem);
    } else {
      this.onNewlyCreatedItem.emit(this.mediaItem);
    }
    // this.onCancelNewlyCreatedItem.emit(false);
    if (this.mediaItem.resource_type === MediaType.link) {
      this.linkPreviewService.getLinkPreview(this.mediaItem.resource).subscribe((resp: any) => {
        if (resp.image) {
          this.mediaItem.previewUrl = resp.image; // TODO: probably need to separete previewUrl to another variable.
        }
      });
    }
  }

  cancelEdit() {
    this.inEditMode = false;
    if (this.setupOnEditMode) {
      this.onCancelNewlyCreatedItem.emit(false);
    }
  }

  onLoadedFile(event, type: any) {
    this.fileUploadService
      .upload(event[0], type)
      .subscribe((resp: { uuidName: string; oldName: string; state: string }[]) => {
        const i = resp[0];
        if (i.uuidName) {
          this.mediaItem.resource = i.uuidName + '' || '';
        }
      });
  }

  stopVideo() {
    if (this.player) {
      this.isVimeoVideo ? this.player.pause() : this.player.stopVideo();
    }
  }

  private extractYoutubeVideoId() {
    const link = this.mediaItem.resource;
    const ind = link.indexOf('v=');
    if (ind >= 0) {
      this.videoId = link.substr(ind + 2);
      if (!this.containerId || this.containerId === 'curriculumPlayer-') {
        this.containerId = 'curriculumPlayer-' + this.videoId;
      }
    }
  }

  private extractVimeoVideoId() {
    const link = this.mediaItem.resource;
    const ind = link.indexOf('vimeo.com/');
    if (ind >= 0) {
      this.isVimeoVideo = true;
      this.videoId = link.substr(ind + 10);
    } else {
      this.isVimeoVideo = false;
    }
    if (!this.containerId || this.containerId === 'curriculumPlayer-') {
      this.containerId = 'curriculumPlayer-' + this.videoId;
    }
  }

  // TODO: what purpose?
  // tslint:disable-next-line:no-unused-variable
  private loadVimeoVideo() {
    if (!this.player && !this.isVimeoPlayerOn) {
      return;
    }
    this.player
      .loadVideo(this.videoId)
      .then(id => {
        // the video successfully loaded
      })
      .catch(error => {
        this.snackBar.open('An error happen while loading video: ' + error.name, 'OK', {
          duration: 3000
        });
      });
  }

  // TODO: what purpose?
  // tslint:disable-next-line:no-unused-variable
  private loadYoutubeVideo() {
    this.player.loadVideoById(this.videoId).then(i => {
      this.stopVideo();
    });
  }

  private checkIfNeedToChangePlayer() {
    if (!this.isVimeoVideo && this.isVimeoPlayerOn) {
      this.player.destroy().then(() => {
        this.createYoutubePlayer();
      });
    }
    if (this.isVimeoVideo && !this.isVimeoPlayerOn) {
      this.player.destroy().then(() => {
        this.createVimeoPlayer();
      });
    }
  }

  private createVimeoPlayer() {
    const containerPadding = 16;
    const w = this.container.nativeElement.offsetWidth - containerPadding;
    this.player = new Player(this.containerId, {
      id: this.videoId,
      width: w
    });
    this.isVimeoPlayerOn = true;
  }

  private createYoutubePlayer() {
    this.player = YouTubePlayer(this.containerId, {
      videoId: this.videoId,
      height: '315',
      width: '100%',
      playerVars: { autoplay: 0 }
    });
    this.isVimeoPlayerOn = false;
  }

  // TODO: remove?
  saveMediaItem() {
  }

  // TODO: remove?
  createTask() {
  }

  // TODO: remove?
  addNote() {
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  replaceLink(url: string) {
    return url
      .replace('https://', '')
      .replace('http://', '')
      .replace('www.', '');
  }

  MediaType = MediaType;
}
