import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question, QuestionType } from '../../../../../student/test.model';
import { COUResponse } from '../../../../../shared-pages/subject-details/api-subject-details';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../../../utils/custom-validators';

@Component({
  selector: 'curr-mustistage-subquestion-block',
  templateUrl: './mustistage-subquestion-block.component.html',
  styleUrls: ['./mustistage-subquestion-block.component.scss']
})
export class MustistageSubquestionBlockComponent implements OnInit {
  @Input() question: Question;
  @Input() cou: COUResponse[] = [];
  @Input() inSowPage = false;
  @Input() isCTQuestion = false;
  @Input() questionLabel = 'Question';
  @Input() questionPlaceholder = 'Question';
  @Output() saveQuestionData = new EventEmitter<string>();
  @Output() onDeleteSubQuestion = new EventEmitter<number>();

  isEditSubQuestionMode = true;
  questionType = QuestionType;
  ddCOUQuestions: any = [];

  mediaFormGroup: FormGroup;

  constructor(private fb: FormBuilder) {}

  get isSAQuestion(): boolean {
    return this.question.base_type === 'sa';
  }
  ngOnInit(): void {
    if (this.cou.length) {
      this.ddCOUQuestions = this.cou.map(i => {
        return { value: i.id, label: i.question, is_right: i.is_right };
      });
      this.ddCOUQuestions = [{ value: 1, label: 'no cou', is_right: null }, ...this.ddCOUQuestions];
    }
    this.initMediaFormGroup();
  }

  private initMediaFormGroup() {
    this.mediaFormGroup = this.fb.group({
      video_url: [this.question?.video_url || '', [CustomValidators.validateVideoUrl]]
    });
  }
  canSaveSubQuestion() {
    return !this.question.question || !this.mediaFormGroup.valid;
  }

  saveSubQuestion() {
    this.isEditSubQuestionMode = true;
    this.saveQuestionData.emit('');
  }

  cancelEditQubQuestion() {
    this.isEditSubQuestionMode = true;
  }

  setSubQuestionMark(val: string | number) {
    this.question.mark = val;
  }

  deleteQuestion() {
    this.onDeleteSubQuestion.emit(this.question.id);
  }

  selectCou(val: any | []) {
    this.question.cou_right_id = val;
  }

  selectWrongCou(val: any | []) {
    this.question.cou_wrong_id = val;
  }

  setVideoUrl(url: string) {
    this.question.video_url = url?.replace(/\&.*/, '');
  }
}
