import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class QuestionFormQuestionBlockService {

  constructor(private httpClient: ApplicationHttpClient) { }

  getExaminationBoardList(): Observable<any> {
    return this.httpClient.get('/examination-boards');
  }
}
