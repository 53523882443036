<!-- Created by Maxim Bon. on 12/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-edit-test">
  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="m-b-24 header-section" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="start start">
      <div class="name-container" fxLayout="row" fxLayoutAlign="start center">
        <span class="name" [class.four-btn]="test?.lobjective_ids?.length && isTestHasTopics">{{ test?.test_name }}</span>
        <button mat-icon-button (click)="editName()">
          <mat-icon svgIcon="edit" color="primary"></mat-icon>
        </button>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <curr-button
        *ngIf="test?.lobjective_ids?.length && isTestHasTopics"
        (btnClick)="saveTestAsDraft()"
        [isDisabled]="!test?.test_name || !test?.start_time || !test?.end_time"
        class="m-r-16"
        size="big"
        variant="outlined">SAVE DRAFT
      </curr-button>
      <curr-button
        *ngIf="test?.lobjective_ids?.length && isTestHasTopics"
        (btnClick)="editTopics()"
         class="m-r-16"
         size="big"
         variant="outlined">REVISE TOPICS COVERED</curr-button>
      <curr-button
        (btnClick)="cancelTest()"
        color="warn"
        size="big"
        [class.m-r-16]="test?.lobjective_ids?.length && isTestHasTopics"
        variant="outlined"> CANCEL</curr-button>

      <div class="publish-btn" *ngIf="test?.lobjective_ids?.length && isTestHasTopics">
        <curr-button (btnClick)="saveTest()" [isDisabled]="canSaveTest()" variant="contained" size="big" icon="check">{{
          test?.id && test?.is_published ? 'PUBLISHED' : 'PUBLISH TEST' }}
        </curr-button>
      </div>
    </div>
  </div>

  <div class="m-b-16 date-filters" fxLayout="row" fxLayoutAlign="start center"
       *ngIf="test?.lobjective_ids?.length && isTestHasTopics">
    <div class="date-filters-section">
      <div class="date-filters-label">Choose Start Date and Time:</div>
      <div class="date-filters-section-inside">
        <curr-datepicker
          [placeholder]="'Start date'"
          [activeFromToday]="true"
          [isReadOnly]="true"
          [suffix]="'calendar-today'"
          [value]="oldStartDate"
          (valueChange)="setStartDate($event)"></curr-datepicker>
        <curr-timepicker
          [placeholder]="'Start Time'"
          [suffix]="'time'"
          [value]="oldStartTime"
          [isReadOnly]="true"
          (valueChange)="setStartTime($event)"></curr-timepicker>
      </div>
    </div>
    <div class="date-filters-section">
      <div class="date-filters-label">Choose Due Date and Time:</div>
      <div class="date-filters-section-inside">
        <curr-datepicker
          [placeholder]="'Due Date'"
          [activeFromToday]="true"
          [isReadOnly]="true"
          [value]="oldDueDate"
          [suffix]="'calendar-today'"
          (valueChange)="setDueDate($event)"></curr-datepicker>
        <curr-timepicker
          [placeholder]="'Due Time'"
          [suffix]="'time'"
          [isReadOnly]="true"
          [value]="oldDueTime"
          (valueChange)="setDueTime($event)"></curr-timepicker>
      </div>
    </div>
  </div>

  <div *ngIf="isOffline" fxLayout="column" fxLayoutAlign="start start">
    <h1 class="mat-h1">This is Practical/Offline Topic Test</h1>
    <curr-button [color]="'primary'" [isDisabled]="!test?.lobjective_ids.length" [variant]="'contained'" size="big"
      (btnClick)="createOnlineTest()">
      CREATE ONLINE TEST
      <mat-icon [svgIcon]="'arrow-right'" class="next-btn-icon"></mat-icon>
    </curr-button>
  </div>

  <div *ngIf="!isOffline">
    <div *ngIf="!test?.lobjective_ids?.length || !isTestHasTopics">
      <div class="h2-section">
        <h2 class="mat-h2 m-b-8" *ngIf="paperTopics.length">
          Please select topics to be included it the test:
        </h2>

        <mat-checkbox *ngIf="paperTopics.length" (change)="addToTestTopicsAll($event.checked)" [checked]="isSelectedAllTopics" class="m-b-0 full-width check">
          {{ 'Select All Topics' }}
        </mat-checkbox>
      </div>

      <div class="select-topics-container m-b-16" fxLayout="row" fxLayoutAlign="center center"
        *ngIf="!paperTopics.length">
        <span class="mat-body-2">No unused topics to create test</span>
      </div>
      <div class="select-topics-container m-b-16" fxLayout="row" fxLayoutAlign="start"
        *ngIf="paperTopics.length">
        <div class="topic" *ngFor="let t of paperTopics; let i = index" [ngClass]="{
            'disabled-topic': t.alreadyUsed,
            'active-topic': !t.alreadyUsed,
            'selected-topic': isTopicSelected(t)
          }" fxLayout="row" fxLayoutAlign="start center" (click)="addToTestTopics(t)">
          <mat-checkbox (click)="topicCheckBoxClick($event, t)" class="m-b-0 full-width check"
            [(ngModel)]="t.is_passed">
            {{ ' ' }}
          </mat-checkbox>
          <div class="question-text-block">
            <div [currMath]="t.title"></div>
            <div class="no-questions-list" *ngIf="!t.has_questions">No questions</div>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" class="m-t-40" *ngIf="paperTopics.length">
        <curr-button [color]="'primary'" [isDisabled]="!test?.lobjective_ids?.length && !updated" [variant]="'contained'"
          fxFlex="119px" size="big" (btnClick)="addTopics()">NEXT
          <mat-icon [svgIcon]="'arrow-right'" class="next-btn-icon"></mat-icon>
        </curr-button>
      </div>
    </div>

    <div *ngIf="test?.lobjective_ids?.length && isTestHasTopics" fxLayout="column">
      <div class="h2-question-bank">
        <div class="question-bank-inside">
          <h2 class="mat-h2" *ngIf="paperTopics.length">Question Bank</h2>
<!--          <div class="question-bank-timer">Remaining available time: {{ getRemainedTestTime() }} min</div>-->
        </div>
        <div class="question-bank-actions">
          <curr-question-type-dropdown
            (onClickAI)="addQuestionWithAIAssistant()"
            (onClickManual)="addQuestionManually()">
          </curr-question-type-dropdown>

          <curr-button
            variant="contained"
            (btnClick)="openDataBaseQuestionPopup()"
            icon="plus"
            class="add-question-btn">ADD FROM QUESTION BANK
          </curr-button>
        </div>
      </div>

      <div class="question-wrapper-filters">
        <div class="question-wrapper-filters-left">
          <curr-dropdown
            class="curr-dropdown topics-filter"
            [options]="ddFilteredOptionsTopic"
            [isDisabled]="!cTQuestionsFiltered?.length" 
            placeholder="All Topics"
            (valueChange)="changeTopicFilter($event)">
          </curr-dropdown>
          <curr-dropdown
            class="curr-dropdown"
            [options]="ddOptionsQuestionTypes"
            [isDisabled]="!cTQuestionsFiltered?.length" 
            placeholder="All Question Types"
            (valueChange)="changeQuestionTypesFilter($event)">
          </curr-dropdown>
          <mat-checkbox
            [disabled]="!cTQuestionsFiltered?.length" 
            (change)="setPastPaper($event.checked)" 
            class="m-b-0 last-checkbox">
              {{ 'Past Paper' }}
          </mat-checkbox>
        </div>
        <div class="question-wrapper-filters-right">
          <div class="filter-title">All Questions Mark Type:</div>
          <mat-radio-group (change)="setQuestionsMarkType($event)" [(ngModel)]="markTypeItem">
            <mat-radio-button [value]="'AI_MARKED'" [disabled]="!cTQuestionsFiltered?.length" class="m-r-24">AI Marked</mat-radio-button>
            <mat-radio-button [value]="'SELF_MARKED'" [disabled]="!cTQuestionsFiltered?.length" >Self Marked</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <div *ngIf="!cTQuestionsFiltered?.length" class="no-questions">
        <img src="/assets/images/colored/empty-task-list.svg" />
        <div class="empty-text">No Questions Yet!</div>
      </div>

      <div class="question-wrapper" *ngIf="cTQuestionsFiltered?.length">
        <div class="question-wrapper-inside">
          <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="4px" class="full-width">

            <div class="m-t-16" *ngIf="isImagesLoading">
              <curr-loader [diameter]="40" [isLoading]="isImagesLoading"></curr-loader>
            </div>
            <div @fade *ngIf="!isImagesLoading" class="full-width question-container-outside">
              <div class="question-container"
                *ngFor="let q of cTQuestionsFiltered; let i = index"
                [class.selected-topic]="q.isSelected"
                fxLayout="row"
                fxLayoutAlign="start start"
                [id]="'desc-'+q.id" (click)="selectQuestion(q, i)">

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex>
                  <div fxFlex *ngIf="q.previewQuestion" [currMath]="q.previewQuestion"></div>
                  <div fxFlex *ngIf="q.question && !q.previewQuestion" [currMath]="q.question"></div>
                </div>
                <curr-icon-button class="delete-question" icon="trash" (click)="deleteQuestion($event, i)"></curr-icon-button>
              </div>
            </div>
          </div>
        </div>

        <div class="question-wrapper-inside">
          <div *ngIf="selectedQuestion" class="test-question-view-container">
            <curr-test-question-view
              [question]="selectedQuestion"
              [topics]="paperTopics"
              (onEditQuestion)="editQuestion($event)"
              (onChangeMarkType)="changeMarkType($event)">
            </curr-test-question-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="loader-container" *ngIf="isLoading">
  <mat-spinner [strokeWidth]="5" [diameter]="40"></mat-spinner>
</div>
