/*
 * Created by Volodymyr Mak. on 6/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'curr-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() mainColor: string = '#05dd0c55';
  @Input() secondColor: string = '#04a109d4';
  @Input() value: number = 0;
  @Input() secondValue: number = 0;
  @Input() thirdValue: number = 0;
  @Input() width: string = '500px';
  @Input() height: string = '10px';
  @Input() color: string = '#000';
  @Input() borderRadius: string = '10px';


  ngOnInit(): void {
    if (this.value > 100) {
      this.value = 100;
    }
  }
}
