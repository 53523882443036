<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="create-edit-topic">
  <div class="change-topic-form">
    <div class="bg-section">
      <div class="topic-label">Topic Name</div>
      <curr-tiny-editor
        [formGroup]="form"
        controlName="topicName"
        [options]="{ height: 120, disabled: false, menubar: 'menubar', statusbar: false }"
        (valueChange)="updateTopicValue($event)"
        (onFocusOut)="updateOptionOutFocus()"
      ></curr-tiny-editor>
    </div>

    <div class="bg-section question-section">
      <div class="question-title">Select Questions</div>
      <div class="question-text">
        Please feel free to select questions from the question bank below. You'll be able to add your own questions at a
        later stage, and they will be added to the question bank.
      </div>

      <div class="show-past-paper">
        <curr-checkbox
          [isMultipleSelect]="false"
          [options]="[{ label: 'Show Past Paper Questions Only', value: true }]"
          [value]="[showPastPaper]"
          (valueChange)="changeShowPastPaper($event)"
        >
        </curr-checkbox>

        <curr-dropdown-component-v2
          *ngIf="showPastPaper"
          class="year-select"
          placeholder="Year"
          [options]="pastExamOptions"
          (selectedValueChange)="setPastExamYear($event)"
        ></curr-dropdown-component-v2>
      
        <curr-dropdown-component-v2
          *ngIf="showPastPaper"
          class="exam-select"
          placeholder="Exam Board"
          [options]="examinationBoard"
          (selectedValueChange)="setExaminationBoard($event)"
        ></curr-dropdown-component-v2>
      </div>

      <div class="question-list">
        <div class="question-panel" *ngFor="let masterQuestion of masterQuestions; let i = index">
          <curr-expansion-panel-topic
            [title]="masterQuestion.title"
            [isShowed]="false">
            <div class="question-content">
              <div class="question-select-all">
                <curr-checkbox
                  [value]="[checkSelectedAll(masterQuestion.questions)]"
                  [options]="[{label: 'Select All From Topic', value: true}]"
                  [isMultipleSelect]="false"
                  type="checkbox"
                  (valueChange)="changeQuestionsAll($event, i)">
                </curr-checkbox>
              </div>
              <div *ngFor="let question of masterQuestion.questions; let k = index">
                <div class="question-title-section">
                  <curr-checkbox
                    [value]="[question.isChecked]"
                    [options]="[{label: '', value: true}]"
                    [isMultipleSelect]="false"
                    type="checkbox"
                    (valueChange)="changeQuestion($event, i, k)">
                  </curr-checkbox>
                  <div class="question-title">Question {{k + 1}} <span>{{question.examinationBoardName}}, {{question.questionYear}}</span></div>
                </div>
                <div class="question-content" [currMath]="question.question"></div>
              </div>
            </div>
          </curr-expansion-panel-topic>
        </div>

        <div *ngIf="masterQuestions.length === 0" class="no-questions">
          <img src="/assets/images/colored/empty-task-list.svg" />
          <div class="empty-text">No Questions Yet!</div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions-section">
    <curr-button variant="text" (click)="cancel()" size="big">CANCEL</curr-button>
    <curr-button variant="contained" [isDisabled]="!form.valid" (btnClick)="save()" size="big">SUBMIT</curr-button>
  </div>
</div>
