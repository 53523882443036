import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IQuestionWithMostLostMarks } from '../../interfaces/question-with-most-lost-marks';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { Observable, forkJoin } from 'rxjs';
import { ImagePopupComponent } from '../../../shared/components/image-popup/image-popup.component';
import { fadeAnimation } from '../../../shared/animations/fade.animation';

@Component({
  selector: 'curr-questions-list-with-most-lost-marks',
  templateUrl: './questions-list-with-most-lost-marks.component.html',
  styleUrls: ['./questions-list-with-most-lost-marks.component.scss'],
  animations: [fadeAnimation]
})
export class QuestionsListWithMostLostMarksComponent implements OnInit {
  questionsList: IQuestionWithMostLostMarks[];
  imageKeyValue: { [key: string]: string } = {};
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<QuestionsListWithMostLostMarksComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IQuestionWithMostLostMarks[],
    private fileUploadService: FileUploadService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.data?.some(question => !!~question.question.indexOf('<img src'))) {
      for (const question of this.data) {
        this.replaceImages(question);
      }
    } else {
      this.questionsList = this.data;
    }
  }

  replaceImages(question: IQuestionWithMostLostMarks) {
    this.loading = true;
    this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.question);
    let questionText = question.question;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(this.imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          this.imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          questionText = questionText.replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            `(<a class="show-image" href="${key}">show image</a>) `
          );
        }
        question.question = questionText;
      },
      complete: () => {
        this.questionsList = this.data;
        setTimeout(() => (this.loading = false), 500);
      }
    });
  }

  @HostListener('click', ['$event'])
  onMouseEnter(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.classList.contains('show-image')) {
      const imgKey = (e.target as HTMLElement).getAttribute('href');

      this.dialog.open(ImagePopupComponent, {
        data: {
          imgSrc: this.imageKeyValue[imgKey]
        }
      });
    }
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
