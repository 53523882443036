/**
 * Created by Maxim Bon. on 15/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionType } from '../../../../student/test.model';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NewQuestionService } from '../new-question.service';

@Component({
  selector: 'curr-mark-scheme-section',
  templateUrl: './mark-scheme-section.component.html',
  styleUrls: ['./mark-scheme-section.component.scss'],
})
export class MarkSchemeSectionComponent implements OnInit {
  @Input()
  question: Question;

  @Input()
  form: FormGroup;

  @Input()
  isCTQuestion = false;

  @Input()
  itemIndexWithParents: number[];

  questionType = QuestionType;
  isCommentsTinyEditor = false;
  isLoadEditor = true;
  commentsForm: FormGroup;

  constructor(private fb: FormBuilder,private newQuestionService: NewQuestionService) {}

  get commentsData() {
    return this.commentsForm.controls.commentsData as FormArray;
  }

  ngOnInit(): void {
    setTimeout(() => this.isLoadEditor = false, 500);

    this.commentsForm = this.fb.group({
      commentsData: [this.question.comments ?? '']
    });
  }

  updateQuestionData(question: Question) {
    this.newQuestionService.changeQuestion({question, itemIndexs: this.itemIndexWithParents});
  }

  setMark(m: string) {
    // @ts-ignore
    this.question?.mark = m;
    this.newQuestionService.changeQuestion({question: this.question, itemIndexs: this.itemIndexWithParents});
  }

  updateKeyword(keywords: { key: string; mark: number }[]) {
    this.question.oeq_keywords = keywords;
    this.newQuestionService.changeQuestion({question: this.question, itemIndexs: this.itemIndexWithParents});
  }

  setCorrectTrueFalseAnswer(i: number) {
    // @ts-ignore
    this.question?.correct_answer_options[0] = i;
  }

  updateQuestionCommentValue(event: string): void {
    this.question.comments = event;
  }

  updateCommentOutFocus() {
    this.newQuestionService.changeQuestion({question: this.question, itemIndexs: this.itemIndexWithParents});
  }
}
