/**
 * Created by Maxim B. on 06/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-create-period-dialog',
  templateUrl: './create-period-dialog.component.html',
  styleUrls: ['./create-period-dialog.component.scss']
})
export class CreatePeriodDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreatePeriodDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createPeriod(){
    this.dialogRef.close([]);
  }
}
