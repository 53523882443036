<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="test-question-view" *ngIf="question">
  <div class="test-question-view-row">
    <div class="view-row-title">
      {{ question.children.length > 0 ? 'Introduction' : 'Question' }}
    </div>
    <div class="view-row-question" [currMath]="question.question"></div>
  </div>

  <div *ngIf="question.children.length === 0">
    <div class="test-question-view-row" *ngIf="isOpenAnswer()">
      <div class="view-row-title">Answer(s):</div>
      <div class="view-row-question">
        <div class="answers-list" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
          <div
            fxLayout="row"
            fxLayoutAlign="start flex-start"
            *ngFor="let option of question?.answer_options; let i = index"
          >
            <mat-icon
              [class.correct-circle]="isAnswerCorrect(i)"
              [svgIcon]="!isAnswerCorrect(i) ? 'lens-outlined' : 'lens-filled'"
              class="circle-question m-r-8"
            ></mat-icon>
            <span [class.correct-label]="isAnswerCorrect(i)" [innerHTML]="option"></span>
          </div>
          <div *ngIf="!question?.answer_options?.length">No setup</div>
        </div>
      </div>
    </div>
    <div class="test-question-view-row" *ngIf="!isOpenAnswer()">
      <div class="view-row-title">Answer(s):</div>
      <div class="m-b-32">Answer type - {{ getQuestionTypeLabel() }}</div>
      <div *ngIf="questionType.OPEN_ENDED == question.answer_type">
        <div class="view-row-title">Mark Scheme:</div>
        <div class="open-ended-answers">
          <div class="open-ended-answers-row" *ngFor="let keyword of question.oeq_keywords; let i = index">
            <div class="open-ended-cell open-ended-answer">
              <div class="open-ended-title">Answer</div>
              <div class="open-ended-desc" [currMath]="keyword.key"></div>
            </div>
            <div class="open-ended-cell open-ended-mark">
              <div class="open-ended-title">Mark</div>
              <div class="open-ended-desc">{{ keyword.mark }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="test-question-view-row"
      *ngIf="question.is_past_exam_question && (question.examination_board || question.question_year) && isFirstLevel"
    >
      <div class="view-row-title">Is Past Exam Question: <span>Yes</span></div>
      <div class="view-row-question">
        <div class="past-exam-question">
          <div class="past-exam-question-item" *ngIf="question.examination_board">
            Examination Board: <span>{{ question.examination_board.name }}</span>
          </div>
          <div class="past-exam-question-item" *ngIf="question.question_year">
            Year: <span>{{ question.question_year }}</span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="test-question-view-row" *ngIf="!question.is_master">
      <div class="view-row-title total-section">
        Total Available Marks: <span>{{ question.mark ?? '-' }}</span>
      </div>
    </div>
  </div>
</div>
