/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { YearSemesterDate, YearSettings } from '../../../../school-admin/year-settings/year-settings';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'curr-ys-step-two',
  templateUrl: './ys-step-two.component.html',
  styleUrls: ['./ys-step-two.component.scss']
})
export class YsStepTwoComponent implements OnInit {
  @Input() isNew = true;
  @Input() isInPopup = false;
  @Input() ys: YearSettings;
  @Output() ysChange = new EventEmitter<YearSettings>();
  @Output() onNextCancel = new EventEmitter<string>();
  selectedGrade = 1;
  selectedYearSemester: YearSemesterDate;

  constructor() {
  }

  ngOnInit(): void {
    this.selectedYearSemester = this.ys.semestersDates[this.selectedGrade - 1];
  }

  goToNext(step: string) {
    this.onNextCancel.emit(step);
  }

  reCreateSemesters(val: number) {
    this.ys.semestersQuantity = val;
    this.ys.setSemestersDates();
    this.selectedYearSemester = this.ys.semestersDates[this.selectedGrade - 1];
  }

  setDate(val: Date, d: string, i: number) {
    this.selectedYearSemester.periods[i][d] = val;
  }

  validateData() {
    return false;
  }

  changeSelectedSemester(num: number) {
    this.selectedGrade = num;
    this.selectedYearSemester = this.ys.semestersDates[this.selectedGrade - 1];
  }

  isAllSemestersSelect($event: MatRadioChange) {
    if ($event.value) {
      this.selectedYearSemester = this.ys.semestersDatesForAll;
    } else {
      this.selectedYearSemester = this.ys.semestersDates[this.selectedGrade - 1];
    }

  }
}
