/**
 * Created by Max Bon. on 17/08/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageViewPopupDialogComponent } from '../media-item/image-view-popup-dialog/image-view-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StudentAddCommentToNotePopupComponent } from '../student-add-comment-to-note-popup/student-add-comment-to-note-popup.component';

@Component({
  selector: 'curr-student-notes-image-preview',
  templateUrl: './student-notes-image-preview.component.html',
  styleUrls: ['./student-notes-image-preview.component.scss']
})
export class StudentNotesImagePreviewComponent implements OnInit {

  @Input() imageSource: any;
  @Input() highlightColor = '#FFEA00';
  @Input() isHighlighted = false;
  @Input() previewWidth = 800;
  @Input() noteText = '';
  @Output() createImageNote = new EventEmitter<{ comment?: string, color?: string }>();
  isContextNoteMenuVisible = false;

  constructor(
    public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  openImagePreviewDialog() {
    // TODO: remove ref?
    // tslint:disable-next-line:no-unused-variable
    const dialogRef = this.dialog.open(ImageViewPopupDialogComponent, {
      data: { url: this.imageSource }
    });
  }

  openNoteMenu() {
    this.isContextNoteMenuVisible = true;
  }

  closeContextMenu() {
    this.isContextNoteMenuVisible = false;
  }

  onColorSelectEvent(c: string) {
    if (c === 'note') {
      this.openCreateNotePopup();
    } else {
      this.createImageNote.emit({ color: c });
      // this.note.selected_text_color = c;
      // this.createStudentNote();
    }
    this.closeContextMenu();
  }

  openCreateNotePopup() {
    const dialogRef = this.dialog.open(StudentAddCommentToNotePopupComponent, {
      width: '522px',
      data: {
        inEditNoteMode: false
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.comment && r.color) {
        this.createImageNote.emit({ color: r.color, comment: r.comment });
      }
    });
  }

}
