/**
 * Created by Alex Poh. on 09/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Question } from '../../../../student/test.model';
import { getRestApiProvider, RestApiService, restApiServiceCreator } from '../../../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../../../shared/api/application-http-client';
import { COUResponse, LearningObjective } from '../../api-subject-details';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { FullScreenPopupService } from 'src/app/shared/components/full-screen-popup/full-screen-popup.service';
import { CreateEditDialogComponent } from 'src/app/shared/components/new-question-block/create-edit-dialog/create-edit-dialog.component';
import { DeleteAboutPaperPopupComponent } from '../delete-about-paper-popup/delete-about-paper-popup.component';
import { PaperDetailsService } from '../paper-details.service';

@Component({
  selector: 'curr-my-question-bank',
  templateUrl: './my-question-bank.component.html',
  styleUrls: ['./my-question-bank.component.scss'],
  providers: [getRestApiProvider<COUResponse>('curriculumCoUQuestions')]
})
export class MyQuestionBankComponent implements OnInit, OnChanges {
  @Input() curriculumId: number;
  @Input() learningObjective: LearningObjective;
  @Input() examinationBoard = [];

  cumulativeTestService: RestApiService<Question>;
  cumulativeQuestions: Question[] = [];
  showPastPaper = false;
  pastExamOptions = [];
  selectedQuestionYear: number;
  selectedExaminationBoard: string;
  questionTypeFilter = [];
  isLoadingQuestions = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private httpClient: ApplicationHttpClient,
              public dialog: MatDialog,
              public fullScreenPopupService: FullScreenPopupService,
              private toastr: ToastrService,
              private paperDetailsService: PaperDetailsService) {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(this.httpClient);
    this.initPastExamOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.learningObjective) {
      this.isLoadingQuestions = true;
      if (changes?.learningObjective?.currentValue?.id !== changes?.learningObjective?.previousValue?.id) {
        this.getQuestions();
      }
    }
  }

  ngOnInit(): void {
    this.getQuestions();

    this.paperDetailsService.onUpdateQuestionList().pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.getCumulativeQuestions();
    });
  }

  getQuestions() {
    if (this.learningObjective?.id) {
      this.getCumulativeQuestions();
    }
  }

  checkQuestionFilterType(isChecked: boolean, type: string) {
    if (isChecked) {
      this.questionTypeFilter.push(type);
    } else {
      const checkIndex = this.questionTypeFilter.indexOf(type);
      if (checkIndex > -1) {
        this.questionTypeFilter.splice(checkIndex, 1);
      }
    }

    if (this.questionTypeFilter.length > 0) {
      const result = this.questionTypeFilter.join(',');
      this.getCumulativeQuestions(this.selectedQuestionYear ?? null, this.selectedExaminationBoard ?? null, result);
    } else {
      this.getCumulativeQuestions(this.selectedQuestionYear ?? null, this.selectedExaminationBoard ?? null);
    }
  }

  changeShowPastPaper(isChecked: boolean[]) {
    this.showPastPaper = isChecked.length > 0;
    if (isChecked.length === 0) {
      this.selectedQuestionYear = null;
      this.selectedExaminationBoard = null;

      if (this.questionTypeFilter.length > 0) {
        const result = this.questionTypeFilter.join(',');
        this.getCumulativeQuestions(null, null, result);
      } else {
        this.getCumulativeQuestions();
      }
    }
  }

  setPastExamYear(val: number) {
    this.selectedQuestionYear = val;
    this.getCumulativeQuestions(this.selectedQuestionYear, this.selectedExaminationBoard ?? null);
  }

  setExaminationBoard(val: string) {
    this.selectedExaminationBoard = JSON.parse(val).name;
    this.getCumulativeQuestions(this.selectedQuestionYear ?? null, this.selectedExaminationBoard);
  }

  private initPastExamOptions() {
    let year = 2002;
    const currentYear = new Date().getFullYear();
    while (year <= currentYear) {
      this.pastExamOptions.push({ label: year, value: year });
      year++;
    }
  }

  private getCumulativeQuestions(questionYear?: number, examinationBoard?: string, testTypes?: string) {
    let params: { curriculum_LearningObjective_id: string; questionYear?: number; examinationBoard?: string, testTypes?: string } = {
      curriculum_LearningObjective_id: this.learningObjective?.id
    };

    if (questionYear) params.questionYear = questionYear;
    if (examinationBoard) params.examinationBoard = examinationBoard;
    if (testTypes) params.testTypes = testTypes;

    this.cumulativeTestService
      .getAll(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.isLoadingQuestions = false;
        this.cumulativeQuestions = res.length > 0 ? res.sort((a, b) => a.id - b.id) : [];
      });
  }

  createManually() {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'Create Manually',
      },
      data: {
        closeDialogTitle: 'Close Manual Creation?',
        dialogType: 'manual',
        dialogMode: 'create',
        dialogSubTitle: 'Add Question',
        selectedTopicId: this.learningObjective.id,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res?.data?.questions.length > 0) {
        this.getCumulativeQuestions();
        this.toastr.success('The question was created successfully');
      }
    });
  }

  aiAssistant() {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'AI Assistant',
        isFullScreen: true,
        showFullScreenBtn: false,
      },
      data: {
        closeDialogTitle: 'Close AI Assistant?',
        dialogSubTitle: 'Add Question',
        dialogMode: 'create',
        dialogType: 'ai',
        selectedTopicId: this.learningObjective.id,
        curriculumId: this.curriculumId,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res?.data?.questions.length > 0) {
        this.getCumulativeQuestions();
        this.toastr.success('The question was created successfully');
      }
    });
  }

  editQuestion(question: Question) {
    const ref = this.fullScreenPopupService.open(CreateEditDialogComponent, {
      options: {
        dialogTitle: 'Edit Manually',
      },
      data: {
        closeDialogTitle: 'Close Edit Question?',
        dialogSubTitle: 'Edit Question',
        dialogType: 'manual',
        dialogMode: 'edit',
        selectedTopicId: this.learningObjective.id,
        question,
      },
    });

    ref.instance.onClose.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res?.data?.questions && res.data.questions.length > 0) {
        this.getCumulativeQuestions();
        this.toastr.success('The question was updated successfully');
      }
    });
  }

  deleteQuestion(question: Question) {
    const dialogRef = this.dialog.open(DeleteAboutPaperPopupComponent, {
      width: '424px',
      data: {
        title: 'Delete Question?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.paperDetailsService.deleteMasterQuestion(question.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe(res => {
              this.getCumulativeQuestions();
              this.toastr.success('Question Was Successfully Deleted!');
            });
        }
      });
  }
}
