<!-- Created by Maxim B. on 20/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="container">
  <div class="loader-container" *ngIf="isDataLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div *currHasRoles="[userRole.SchoolAdmin, userRole.Teacher]" class="tabs-container" fxLayout="row"
    fxLayoutAlign="space-between center">
    <mat-tab-group class="custom-ink-size" [dynamicHeight]="false" fxFlex="80" mat-align-tabs="start"
      animationDuration="0ms" (selectedIndexChange)="tabSelectedIndexChange($event)" [selectedIndex]="selectedIndex">
      <mat-tab *ngFor="let table of baseTables; let i = index" [disabled]="inCreateTableMode && i != selectedIndex">
        <ng-template mat-tab-label>
          <span *ngIf="i !== editNameComparator">{{ customTables[table].name }}</span>
          <mat-form-field *ngIf="i === editNameComparator" color="primary" appearance="outline" class="edit-table-name">
            <input #nameInput tabindex="1" (keyup.enter)="editNameOnLostFocus()" matInput
              [(ngModel)]="customTables[table].name" (focusout)="editNameOnLostFocus()" />
          </mat-form-field>
          <button mat-icon-button class="icon-tab-menu" (click)="$event.stopPropagation()"
            [matMenuTriggerFor]="beforeMenu" #menuTrigger="matMenuTrigger"
            *ngIf="i !== editNameComparator && i > (baseTables.length - 1)"
            [disabled]="inCreateTableMode && i != selectedIndex">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #beforeMenu="matMenu" xPosition="before" class="meeting-menu">
            <button class="tab-btn-menu" mat-menu-item (click)="tabMenuClick($event,  i, 'rename', beforeMenu)">
              <mat-icon>text_fields</mat-icon>
              Rename
            </button>
            <button class="tab-btn-menu" mat-menu-item (click)="tabMenuClick($event,  i, 'delete', beforeMenu)">
              <mat-icon>delete</mat-icon>
              Delete
            </button>
            <button class="tab-btn-menu" mat-menu-item (click)="tabMenuClick($event,  i, 'edit', beforeMenu)">
              <mat-icon>edit</mat-icon>
              Edit
            </button>
          </mat-menu>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
    <ng-container *ngIf="false">
      <button class="create-table-btn" *currHasRoles="[userRole.SchoolAdmin]" mat-button [disabled]="inCreateTableMode"
        (click)="createNewTable()">
        <mat-icon [svgIcon]="'plus'"></mat-icon>
        CREATE NEW TABLE
      </button>
    </ng-container>
  </div>
  <div class="drop-down-container" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="!inCreateTableMode">
    <div class="drop-downs" fxLayout="row" fxLayoutAlign="start center">
      <!-- Table filters -->
      <curr-select-courses-filters [preselectedCourseId]="preselectedCourseId" [filterOptionsData]="filterData"
        [filterFields]="coursesFilterFields" (onFilterChange)="onSelectFilterChange($event)">
      </curr-select-courses-filters>
    </div>

    <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
      <curr-input class="curr-input" [isBordered]="false" placeholder="Search" fxFlex="50"
        (keyup)="filterEntries($event)"></curr-input>
      <mat-icon [svgIcon]="'search_black'"></mat-icon>
    </div>
  </div>

  <ng-container *ngIf="!inCreateTableMode && isDataPresented">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Name</th>
          <td mat-cell *matCellDef="let element">
            <a *ngIf="baseTables[selectedIndex] === 'STUDENT' || baseTables[selectedIndex] === 'STUDENT_UNDERSTANDING'"
              [routerLink]="'/studentProfile/' + (element.student_id || '1')" class="user-name">
              <p>{{ element.name }}</p>
            </a>
            <a *ngIf="baseTables[selectedIndex] === 'TEACHER' || baseTables[selectedIndex] === 'TEACHER_UNDERSTANDING'"
              [routerLink]="'/teacherProfile/' + (element.teacher_id || '1')" class="user-name">
              <p>{{ element.name }}</p>
            </a>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 1">
          <ng-container *ngIf="item == 'predicted_grade'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
              {{ 'dropdownItemLabels.' + item | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              <a [matMenuTriggerFor]="predictedGradeMenu" (menuClosed)="closePredictedGradeMenu()" class="user-name"
                (click)="clickPredictedGrade($event, element)">
                <p>{{ element.predicted_grade }}</p>
                <mat-icon *ngIf="isPredictedGradeMenuOpen && clickedPendingUser == element.name" svgIcon="up"
                  class="dd-icon"></mat-icon>
                <mat-icon *ngIf="!isPredictedGradeMenuOpen || clickedPendingUser != element.name" svgIcon="down"
                  class="dd-icon"></mat-icon>
              </a>
              <mat-menu #predictedGradeMenu="matMenu" xPosition="after" class="meeting-menu">
                <ng-container *ngFor="let b of ddOptionsPredictedGrade">
                  <button mat-menu-item class="icon-tab-menu-item mat-body-1" fxLayoutAlign="start center"
                    [class.active]="b.value === element.predicted_grade" (click)="setPredictedGrade(b.value, element)">
                    <mat-icon *ngIf="b.value === element.predicted_grade" svgIcon="icon-done" class="m-r-0 grade-icon">
                    </mat-icon>
                    {{ b.label }}
                  </button>
                  <mat-divider></mat-divider>
                </ng-container>
              </mat-menu>
            </td>
          </ng-container>

          <ng-container *ngIf="item == 'prev_week_meetings'">
            <th mat-header-cell *matHeaderCellDef [class.th-meeting]="item == 'prev_week_meetings'" scope="col"
              scope="col">
              <div>
                {{ 'dropdownItemLabels.' + item | translate }}
                <p *ngIf="item == 'prev_week_meetings'" [matMenuTriggerFor]="meetingMenu"
                  (click)="$event.stopPropagation(); isMeetingDropdownOpen = true" class="dont-brake-rows">
                  {{ isMeetingThisWeek ? 'This week' : 'Prev. week' }}
                  <mat-icon *ngIf="isMeetingDropdownOpen">arrow_drop_up</mat-icon>
                  <mat-icon *ngIf="!isMeetingDropdownOpen">arrow_drop_down</mat-icon>
                </p>
                <mat-menu #meetingMenu="matMenu" xPosition="after" class="meeting-menu">
                  <button mat-menu-item (click)="selectMeetingWeek(true)">
                    This week
                  </button>
                  <button mat-menu-item (click)="selectMeetingWeek(false)">
                    Prev. week
                  </button>
                </mat-menu>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="item != 'prev_week_meetings'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
              {{ 'dropdownItemLabels.' + item | translate }}
            </th>
          </ng-container>

          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="item === 'performance'">
              <curr-concern-widget-row [concern]="element[item]"></curr-concern-widget-row>
            </ng-container>
            <ng-container *ngIf="item === 'prev_week_meetings'" [ngSwitch]="element[item]">
              <span *ngSwitchCase="'Yes'" class="m-b-0">{{ element[item] }}</span>
              <a *ngSwitchCase="'Pending'" [matMenuTriggerFor]="pendingMenu" class="user-name"
                (click)="clickPending($event, element, pendingMenu)">
                <p>{{element.prev_week_meetings}}</p>
                <mat-icon *ngIf="isInTableDropdownOpen && clickedPendingUser == element.name">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!isInTableDropdownOpen || clickedPendingUser!=element.name">arrow_drop_down</mat-icon>
              </a>
              <mat-menu #pendingMenu="matMenu" xPosition="after" class="meeting-menu">
                <button mat-menu-item class="icon-tab-menu-item" (click)="setMeetingToDone(element, 'Pending')">
                  Pending
                </button>
                <button mat-menu-item class="icon-tab-menu-item" (click)="setMeetingToDone(element, 'Yes')">
                  Yes
                </button>
              </mat-menu>
              <span *ngSwitchDefault>{{ element[item] | percent }}</span>
            </ng-container>

            <ng-container *ngIf="item !== 'performance' && item !== 'prev_week_meetings'"
              [ngSwitch]="+element[item] >= 0 && +element[item] <= 1">
              <span *ngSwitchCase="true">{{ +element[item] | percent }}</span>
              <span *ngSwitchDefault>{{ element[item] }}</span>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <mat-paginator #tablePaginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
  </ng-container>

  <ng-container *ngIf="!inCreateTableMode && !isDataPresented">
    <div class="no-data-container">
      <div>
        <div class="mat-display-1 m-b-24">Please invite your students to view this data</div>
        <div class="mat-h2">Please share your course ID with students</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="inCreateTableMode">
    <curr-table-constructor [table]="currentEditedTable" (saveTable)="saveTable($event)"
      (cancelCreateEmitter)="cancelCreateTable($event)"></curr-table-constructor>
  </ng-container>
</div>
