/**
 * Created by Daniil Vat. on 28/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { COLORS } from '../consts';
@Component({
  selector: 'curr-colored-list-item',
  templateUrl: './colored-list-item.component.html',
  styleUrls: ['./colored-list-item.component.scss']
})
export class ColoredListItemComponent implements OnInit {
  @Input() text: string;
  @Input() value: string;
  @Input() index: number = 0;

  get color(): string {
    return COLORS[this.index];
  }
  constructor() {}

  ngOnInit() {}
}
