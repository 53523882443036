/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ThemeService } from '../../shared/theme/theme.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { InputComponent } from '../../shared/components/input/input.component';
import { initElementData } from './story-book-items-init-data';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface ComponentNode {
  name: string;
  children?: ComponentNode[];
}

const TREE_DATA: ComponentNode[] = [
  {
    name: 'Custom Fields',
    children: [
      { name: 'Button' },
      { name: 'Input' },
      { name: 'Checkbox' },
      { name: 'TextArea' },
      { name: 'TinyEditor' },
      { name: 'ToggleButton' },
      { name: 'Dropdown' },
      { name: 'Camera' }
    ]
  },
  {
    name: 'Components',
    children: [
      { name: 'UploadFiles' },
      { name: 'RedLabel' },
      { name: 'DrawingBoard' },
      { name: 'CourseCard' },
      { name: 'CustomerPlate' },
      { name: 'UserCard' }
    ]
  },
  {
    name: 'Layout',
    children: [{ name: 'Header' }, { name: 'Footer' }, { name: 'Breadcrumb' }]
  },
  {
    name: 'Test',
    children: [
      { name: 'Drawing' },
      { name: 'TextInput' },
      { name: 'FileInput' },
      { name: 'TrueOrFalse' },
      { name: 'Workings' },
      { name: 'SelectMultiple' },
      { name: 'SelectOne' }
    ]
  },
  {
    name: 'Teacher',
    children: [{ name: 'Self Assessment Test Card' }]
  },
  {
    name: 'Student',
    children: [{ name: 'Test Card' }]
  }
];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

@Component({
  selector: 'curr-story-book',
  templateUrl: './story-book.component.html',
  styleUrls: ['./story-book.component.scss']
})
export class StoryBookComponent implements OnInit {
  primary = '#ffa448';
  accent = '#b92990';
  initData = initElementData;
  @ViewChild('componentcontainer', { read: ViewContainerRef }) container;
  componentRef: any;
  currentDisplayNode = '';
  path = [
    {
      label: 'Demo',
      url: ''
    },
    {
      label: 'Breadcrumb',
      url: ''
    },
    {
      label: 'Example',
      url: ''
    }
  ];

  inHTML = `<curr-input [(value)]="val" placeholder="User Name"></curr-input>`;

  private transformer = (node: ComponentNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(private themeService: ThemeService, private resolver: ComponentFactoryResolver) {
    this.dataSource.data = TREE_DATA;
  }

  ngOnInit(): void {}

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  createNewScheme() {
    this.themeService.addColorVariablesToPage(this.primary, this.accent);
  }

  itemClicked(node: any) {
    this.currentDisplayNode = node.name;
    // this.createComponent();
  }

  createComponent() {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(InputComponent);
    this.componentRef = this.container.createComponent(factory);
    const componentRef2 = this.container.createComponent(factory);
    this.componentRef.instance.value = 'contained';
    this.componentRef.instance.placeholder = 'placeholder';
    componentRef2.instance.value = 'contained';
    componentRef2.instance.placeholder = 'placeholder';
  }
}
