<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="curr-underlying-factors">
  <div class="list-section">
    <div class="reasons-list" *ngFor="let item of displayedData">
      <div class="reason-block">
        <div class="underlying-factor" [currMath]="item.underlyingFactor"></div>
        <div class="reason" [currMath]="item.reason"></div>
      </div>
      <div class="times" *ngIf="item.count">{{ item.count }} time{{ item.count > 1 ? 's' : '' }}</div>
    </div>

    <div
      class="load-more-section"
      *ngIf="
        receivedData?.underlyingFactorsStatistics.length >= limit &&
        displayedData?.length < receivedData?.underlyingFactorsStatistics.length
      ">
      <curr-button size="big" class="load-more-btn" variant="outlined" (btnClick)="showMore()">load more</curr-button>
    </div>
  </div>
  <div class="chart-section">
    <curr-underlying-factors-chart [data]="receivedData"></curr-underlying-factors-chart>
  </div>
</div>
