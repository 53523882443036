<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="center">
  <div class="chart-wrapper">
    <div class="chart-container" *ngIf="chartData">
      <canvas baseChart [data]="chartData" [labels]="false" [chartType]="type" [colors]="colors" [options]="options">
      </canvas>
    </div>
  </div>
  <div fxLayout="column" fxLayoutAlign="center">
    <curr-colored-list-item *ngFor="let question of quuestionsList; let i = index" [text]="question.label"
      [value]="question.value" [index]="i">
    </curr-colored-list-item>
  </div>
</div>
