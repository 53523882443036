import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpcomingVideoService } from '../upcoming-video.service';
import { UpcomingVideo } from '../upcoming-lessons-videos.component';

@Component({
  selector: 'curr-upcomming-videos-view-popup',
  templateUrl: './upcomming-videos-view-popup.component.html',
  styleUrls: ['./upcomming-videos-view-popup.component.scss'],
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    class: 'hidden-y-scroll'
  }
})
export class UpcommingVideosViewPopupComponent implements OnInit, AfterViewInit {
  video: UpcomingVideo;
  isVideoDescVisible = false;

  constructor(public dialogRef: MatDialogRef<UpcommingVideosViewPopupComponent>,
              private upcomingVideoService: UpcomingVideoService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.video = Object.assign({}, this.data.video);
    if (!this.video.isVimeoVideo) {
      this.video.videoId = this.upcomingVideoService.extractYoutubeVideoId(this.video);
      this.video.containerId = `container2-${this.video.videoId}`;
    } else {
      this.video.videoId = this.upcomingVideoService.extractVimeoVideoId(this.video);
      this.video.containerId = `container2-${this.video.videoId}`;
    }
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  initPlayer() {
    this.video.player = this.upcomingVideoService.createVideoPlayer(this.video, true);
  }

  expandDesc() {
    this.isVideoDescVisible = !this.isVideoDescVisible;
  }
}
