/**
 * Created by Max Bon. on 29/07/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { StudentAddCommentToNotePopupComponent } from '../student-add-comment-to-note-popup/student-add-comment-to-note-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { ResourceStudentNotesService } from './resource-student-notes.service';
import { StudentNote } from './student-note';
import { DomSanitizer } from '@angular/platform-browser';
import { StudentNotesHelperService } from './student-notes-helper.service';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UpdateNetesSubjectService } from './update-netes-subject';

interface NoteToDisplay {
  title?: string;
  note?: any;
  curriculum_topic_id?: number;
  lenDifference?: number;
  studentNotes?: StudentNote[];
  alreadyChangeCoreText?: boolean;
  id?: number;
}

@Component({
  selector: 'curr-student-notes-resources',
  templateUrl: './student-notes-resources.component.html',
  styleUrls: ['./student-notes-resources.component.scss']
})
export class StudentNotesResourcesComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Output() newNoteAdded = new EventEmitter<StudentNote>();
  @Output() noteClicked = new EventEmitter<number>();
  @Input() teacherNotesForStudent: any[] = [];
  @Input() studNotes: StudentNote[] = [];
  @Input() courseStudId: number;
  @Input() selectedlearningObjective: any;

  screenWidth = window.innerWidth;

  hintOnHover = '';
  imageHintOnHover = '';
  currentImageUrlOnHover = '';
  currentImageColorOnHover = '';
  isImageHighlighted = false;
  selectedText = '';
  previewWidth = 0;
  contextMenuCoordinates = {
    x: 0,
    y: 0
  };
  hintCoordinates = {
    x: 0,
    y: 0
  };
  imagePreviewCoordinates = {
    x: 0,
    y: 0
  };
  imageUrlPreviewCoordinates = {
    x: 0,
    y: 0
  };
  note: StudentNote = {};
  notes: NoteToDisplay[] = [];
  currentSelectedNoteId: NoteToDisplay = {};
  unssSubscription: Subscription;
  unssDeleteNoteSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private noteService: ResourceStudentNotesService,
    private unss: UpdateNetesSubjectService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef,
    private notesHelperService: StudentNotesHelperService
  ) {
  }

  ngOnChanges(): void {
    this.currentImageUrlOnHover = '';
    setTimeout(() => {
      this.setupTeacherNotesForStudent();

      if (this.studNotes.length) {
        this.injectStudentTextNotes();
        this.processImagesNotes();
      }

      timer(1000)
        .pipe(take(1))
        .subscribe(resp => {
          this.addEventListenersToStudentsTextNotes();
          this.addEventListenersToStudentsImageNotes();
          this.cdr.detectChanges();
        });
    }, 1000);
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.displayNotes();
    this.unssSubscription = this.unss.get().subscribe(() => {
      this.clearSelection();
      setTimeout(() => {
        this.setupTeacherNotesForStudent();
  
        if (this.studNotes.length) {
          this.injectStudentTextNotes();
          this.processImagesNotes();
        }
  
        timer(1000)
          .pipe(take(1))
          .subscribe(resp => {
            this.addEventListenersToStudentsTextNotes();
            this.addEventListenersToStudentsImageNotes();
            this.cdr.detectChanges();
          });
      }, 1000);
    });

    this.unssDeleteNoteSubscription = this.unss.onDeleteNote().subscribe((nodeId: number) => {
      this.removeNote(nodeId);

      timer(1000)
          .pipe(take(1))
          .subscribe(resp => {
            this.addEventListenersToStudentsTextNotes();
            this.addEventListenersToStudentsImageNotes();
            this.cdr.detectChanges();
          });
    });
  }

  ngOnDestroy(): void {
    this.unssSubscription.unsubscribe();
    this.unssDeleteNoteSubscription.unsubscribe();
  }

  displayNotes() {
    setTimeout(() => {
      this.setupTeacherNotesForStudent();

      setTimeout(() => {
        if (this.studNotes.length) {
          this.injectStudentTextNotes();
          this.processImagesNotes();
        }
      }, 1000);

      timer(1000)
        .pipe(take(1))
        .subscribe(resp => {
          this.addEventListenersToStudentsTextNotes();
          this.addEventListenersToStudentsImageNotes();
          this.cdr.detectChanges();
        });
    }, 1000);
  }

  setupTeacherNotesForStudent() {
    this.notes = this.teacherNotesForStudent.map((i, index) => {
      i.note = this.decodeHtml(i.note);
      i.note = i.note.replace('/↵/gi', '');
      return {
        title: i.title,
        note: this.sanitizeHtml(i.note),
        curriculum_topic_id: i.curriculumTopicId,
        lenDifference: 0,
        studentNotes: this.studNotes.filter(a => a.curriculum_learning_objective_note_id === i.id),
        alreadyChangeCoreText: false,
        id: i.id
      };
    });
    this.notes.forEach(i => {
      i.studentNotes.sort(this.compareNumbers);
    });
  }

  decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  // injectStudentTextNotes() {
  //   this.notes.forEach(note => {
  //     note.studentNotes.forEach(n => {
  //       if (n.selected_image || !n.selected_text) {
  //         // if note is image note - do nothing here
  //         return;
  //       }
  //       let html = note.note.changingThisBreaksApplicationSecurity.toString();
  //       let textToReplace = html.substring(n.start_idx_num + note.lenDifference, n.end_idx_num + note.lenDifference);

  //       // let plug = `<span id="hl-${n.id}" style="background-color: ${n.selected_text_color};">${this.addOpenCloseSpanTag(newString.trim(), n.selected_text_color)}</span>${lastClosingTag}<a id="ahl-${n.id}"></a>`;
        
  //       let plug = `<span id="hl-${n.id}" style="background-color: ${n.selected_text_color};">${this.addOpenCloseSpanTag(textToReplace.trim(), n.selected_text_color)}</span><a id="ahl-${n.id}"></a>`;
  //       const ld = note.lenDifference;
  //       if (!note.alreadyChangeCoreText) {
  //         note.alreadyChangeCoreText = true;
  //         note.lenDifference = ld + plug.length - (n.end_idx_num - n.start_idx_num);
  //       } else {
  //         note.lenDifference = ld + plug.length - (n.end_idx_num - n.start_idx_num);
  //       }
  //       html = html.replace(textToReplace, plug);
  //       note.note = this.sanitizeHtml(html);
  //     });
  //   });
  // }

  injectStudentTextNotes() {
    this.notes.forEach(note => {
      note.studentNotes.forEach(n => {
        if (n.selected_image || !n.selected_text) {
          // If note is an image note, do nothing here
          console.log('If note is an image note, do nothing here')
          return;
        }
        let html = note.note.changingThisBreaksApplicationSecurity.toString();
        // Old version
        // let startIndex = n.start_idx_num + note.lenDifference;
        // let endIndex = n.end_idx_num + note.lenDifference;

        let startIndex = n.start_idx_num;
        let endIndex = n.end_idx_num;
        
        // Ensure indices are within bounds
        if (startIndex < 0 || endIndex > html.length || startIndex >= endIndex) {
          console.log(startIndex < 0, endIndex > html.length, startIndex >= endIndex)
          console.log('Ensure indices are within bounds')
          return;
        }

        const noteContainer = document.querySelector(".note-text") as HTMLElement;
        if (!noteContainer) {
            return;
        }

        let updatedText = this.highlightTextInRange(noteContainer, startIndex, endIndex, n);
        if (updatedText) {
          note.note = updatedText;
        }

        // Old version
        // let plug = `<span id="hl-${n.id}" style="background-color: ${n.selected_text_color};">${this.addOpenCloseSpanTag(textToReplace.trim(), n.selected_text_color)}</span>`;
        // // Replace only the specific occurrence by reconstructing the string
        // html = html.substring(0, startIndex) + plug + html.substring(endIndex);
        // // Update length difference due to text length change
        // let lengthDifference = plug.length - (endIndex - startIndex);
        // note.lenDifference += lengthDifference;
        // note.note = this.sanitizeHtml(html);
      });
    });
  }

  highlightTextInRange(rootElement: HTMLElement, startIndex: number, endIndex: number, n: any): any {
    let range = this.getRangeFromIndexes(rootElement, startIndex + 1, endIndex);
    if (!range) {
      console.log('No Range')
      return;
    }

    const textToReplace = range.toString().trim();
    if (!textToReplace) {
      console.log('No Text To Replace')
      return;
    }

    const highlightSpan = document.createElement("span");
    highlightSpan.id = `hl-${n.id}`;
    highlightSpan.style.backgroundColor = n.selected_text_color;
    highlightSpan.classList.add("noselect", "hover-cursor");
    highlightSpan.textContent = textToReplace;

    const spanElement = document.getElementById(highlightSpan.id);
    if (spanElement) {
      return this.sanitizeHtml(rootElement.innerHTML);
    }

    const anchorElement = document.createElement("a");
    anchorElement.id = `ahl-${n.id}`;

    range.deleteContents();
    range.insertNode(highlightSpan);

    highlightSpan.insertAdjacentElement("afterend", anchorElement);

    this.movePreviousLetterIntoSpan(highlightSpan.id);

    return this.sanitizeHtml(rootElement.innerHTML);
  }

  movePreviousLetterIntoSpan(spanId: string): void {
    const spanElement = document.getElementById(spanId);
    if (!spanElement) {
        return;
    }

    let prevNode = spanElement.previousSibling;

    if (!prevNode) {
        return;
    }

    if (prevNode.nodeType === Node.TEXT_NODE) {
        let prevText = prevNode.nodeValue || "";
        if (prevText.length > 0) {
            let lastChar = prevText.slice(-1);
            prevNode.nodeValue = prevText.slice(0, -1);
            spanElement.textContent = lastChar + spanElement.textContent;
        }
    }
  }

  getRangeFromIndexes(rootElement: HTMLElement, startIndex: number, endIndex: number): Range | null {
    let index = 0;
    const walker = document.createTreeWalker(rootElement, NodeFilter.SHOW_TEXT, null);
    const range = document.createRange();
    
    let startNode: Node | null = null;
    let endNode: Node | null = null;
    let startOffset = 0;
    let endOffset = 0;

    while (walker.nextNode()) {
        const currentNode = walker.currentNode;
        const nodeLength = currentNode.nodeValue?.length || 0;

        if (!startNode && index + nodeLength >= startIndex) {
            startNode = currentNode;
            startOffset = startIndex - index;
        }

        if (!endNode && index + nodeLength >= endIndex) {
            endNode = currentNode;
            endOffset = endIndex - index;
            break;
        }

        index += nodeLength;
    }

    if (startNode && endNode) {
        range.setStart(startNode, startOffset);
        range.setEnd(endNode, endOffset);
        return range;
    }

    return null;
  }

  removeNote(noteId: number) {
    this.notes.forEach(note => {
      let updatedText = this.removeHighlight(noteId);
      if (updatedText) {
        note.note = updatedText;
      }
    });
  }

  removeHighlight(noteId: number): any {
    const noteContainer = document.querySelector(".note-text") as HTMLElement;
    if (!noteContainer) {
        return;
    }

    const highlightedElements = noteContainer.querySelectorAll(`span#hl-${noteId}`);

    if (!highlightedElements.length) {
        return;
    }

    highlightedElements.forEach((span) => {
        const parent = span.parentNode;
        if (parent) {
            parent.replaceChild(document.createTextNode(span.textContent || ""), span);
        }
    });

    return this.sanitizeHtml(noteContainer.innerHTML);
}

  fixHtml(html) {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerHTML;
  }

  addOpenCloseSpanTag(html, color) {
    html = this.fixHtml(html);
    const div = document.createElement('div');
    div.innerHTML = html;
    let children = div.childNodes;
    if (children.length > 1) {
      children.forEach((node, i) => {
        if (i === 0 || i === children.length - 1) {
          const text = node.nodeValue || node.childNodes[0].nodeValue;
          const sp = document.createElement('span');
          sp.innerText = text;
          div.insertBefore(sp, node);
          div.removeChild(node);
        }
      });
    }
    children = div.childNodes;
    children.forEach((node, i) => {
      if ((node as HTMLElement)?.style) {
        // @ts-ignore
        (node as HTMLElement)?.style['background-color'] = color;
      }
    });
    return div.innerHTML;
  }

  private addEventListenersToImages() {
    this.notes.forEach(n => {
      const root = document.getElementById(`notesContainer-${n.id}`);
      if (!root) {
        return;
      }
      const allImgs = root.querySelectorAll('img');
      if (allImgs.length) {
        allImgs.forEach(e => {
          if (e.width > this.screenWidth) {  // For mobile, decrease width
            e.setAttribute('width', '100%');
          }
          if (e.classList.contains('already-used')) {
            return;
          }
          e.classList.add('height-unset');
          e.addEventListener('mouseenter', () => {
            // tslint:disable-next-line:triple-equals
            if (this.currentImageUrlOnHover == e.getAttribute('src')) {
              return;
            }
            this.currentImageUrlOnHover = e.getAttribute('src');
            if (this.currentImageUrlOnHover) {
              this.currentSelectedNoteId = n;
              this.isImageHighlighted = false;
              this.imageHintOnHover = '';
              this.currentImageColorOnHover = '';
              const rect = e.getBoundingClientRect();
              this.previewWidth = rect.right - rect.left;
              this.imageUrlPreviewCoordinates = {
                x: rect.left,
                y: rect.top + window.pageYOffset
              };
            }
          });
        });
      }
    });
  }

  addEventListenersToStudentsImageNotes() {
    this.studNotes.forEach(n => {
      if (!n.selected_image) {
        // if note is image note - do nothing here
        return;
      }
      const imgContainerEl = document.getElementById(`ichl-${ n.id }`);

      if (imgContainerEl) {
        const sibling = imgContainerEl.nextSibling as HTMLElement;
        imgContainerEl.classList.add('image-note-container');
        if (sibling && sibling.tagName?.toLowerCase() === 'img') {
          sibling.classList.add('already-used', 'height-unset');
          imgContainerEl.appendChild(imgContainerEl.nextSibling);
          if (sibling.style.float) {
            imgContainerEl.style.float = sibling.style.float ? sibling.style.float : 'left';
          }
        }
        imgContainerEl.addEventListener('mouseenter', () => {
          // tslint:disable-next-line:triple-equals
          if (this.currentImageUrlOnHover == n.selected_image) {
            return;
          }
          this.isImageHighlighted = true;
          this.imageHintOnHover = n.image_note || '';
          this.currentImageUrlOnHover = n.selected_image || '';
          this.currentImageColorOnHover = n.selected_text_color || '';
          if (this.currentImageUrlOnHover) {
            this.setImagePreviewCoordinates(n);
          }
        });
      }
    });
    this.addEventListenersToImages();
  }

  addEventListenersToStudentsTextNotes() {
    this.studNotes.forEach(n => {
      if (n.selected_image) {
        // if note is image note - do nothing here
        return;
      }
      const noteElement = document.getElementById(`hl-${ n.id }`);
      if (noteElement) {
        noteElement.classList.add('noselect', 'hover-cursor');
        noteElement.addEventListener('mouseenter', () => {
          this.hintOnHover = n.text_note ? n.text_note.trim() : '';
          if (this.hintOnHover) {
            this.setHintCoordinates(n);
          }
        });
        noteElement.addEventListener('click', () => {
          this.noteClicked.emit(n.id);
        });
        noteElement.addEventListener('mouseleave', () => {
          this.hintOnHover = '';
          this.hintCoordinates = {
            x: -1000,
            y: -1000
          };
        });
      }
    });
  }

  setHintCoordinates(n: StudentNote) {
    const anchorElement = document.getElementById(`ahl-${ n.id }`);
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();
      this.hintCoordinates = {
        x: rect.right + 4,
        y: rect.bottom - 4 + window.pageYOffset
      };
    }
  }

  setImagePreviewCoordinates(n: StudentNote) {
    const anchorElement = document.getElementById(`ichl-${ n.id }`);
    if (anchorElement) {
      const rect = anchorElement.getBoundingClientRect();
      this.previewWidth = rect.right - rect.left;
      this.imagePreviewCoordinates = {
        x: rect.right + 4,
        y: rect.bottom - 4
      };
      this.imageUrlPreviewCoordinates = {
        x: rect.left,
        y: rect.top + window.pageYOffset
      };
    }
  }

  onSelect(e: MouseEvent, note: any) {
    // const noteContainer = document.querySelector(".note-text"); 
    // if (noteContainer) {
    //   this.toastr.info('Please select only a single word. Do not select equations or images.', 'Select more');
    //   this.clearSelection();
    //   return;
    // }

    this.currentSelectedNoteId = note;
    const noteHtml = this.teacherNotesForStudent.find(i => i.id === note.id)?.note;
    this.contextMenuCoordinates.x = e.pageX;
    this.contextMenuCoordinates.y = e.pageY;
    let text = '';
    let textWithSpaces = '';
    const doc = document as any;
    if (window.getSelection) {
      text = window.getSelection().toString();
      textWithSpaces = text;
      let selection = window.getSelection() as any;
      if (!selection || selection.rangeCount === 0) {
        this.clearSelection();
        return;
      }

      text = this.removeBrackets(text).trim();

      let sel = window.getSelection();

      if (sel.rangeCount) {
        let html = '';
        var container = document.createElement("div");
        for (var i = 0, len = sel.rangeCount; i < len; ++i) {
            container.appendChild(sel.getRangeAt(i).cloneContents());
        }
        html = container.innerHTML;

        const mathTagsRegex = /<\/?(math|mrow|mstyle|semantics|mo|mi|mn|ms|mtext|msup|msub|msubsup|mfrac|mroot|msqrt|mtable|mtr|mtd|mover|munder|munderover|mphantom|mspace|mpadded|mfenced|menclose|base|mord|mbin|msupsub|vlist-t|vlist-r|vlist|vlist-t2|sizing|mtight|reset-size6|size3|katex|katex-html|text|mrel)[^>]*>/;

        if (mathTagsRegex.test(html)) {
          this.toastr.info('Sorry, adding notes to formulas is currently unavailable.', 'Select more');
          this.clearSelection();
          return;
        }        
      }

      // if (this.isFindFewOccurrences(text, noteHtml) > 1) {
      //   this.toastr.info('The selected word is not unique. Select another unique word. Do not select an equation or image.', 'Select more');
      //   this.clearSelection();
      //   return;
      // }

      if (this.countWords(text) > 1) {
        this.toastr.info('Please select only a single word. Do not select equations or images.', 'Select more');
        this.clearSelection();
        return;
      }

      if (text.length === 1) {
        this.toastr.info('Select text should have more than one symbol.', 'Select more');
        this.clearSelection();
        return;
      }

      text = this.tripHtmlTags(text).trim();

      let range = selection.getRangeAt(0);
      let selectedText = range.toString();
      let trimmedText = selectedText.trim();
      if (!trimmedText) {
        this.clearSelection();
        return;
      }

      const startNode = selection.baseNode.nodeValue;
      const endNode = selection.focusNode.nodeValue;
      // If noteHtml dont includes startNode or endNode - ignore selection
      if (!noteHtml.includes(startNode) || !noteHtml.includes(endNode)) {
        this.clearSelection();
        return;
      }
      this.setupStartEndIndexesForNoteInNode(noteHtml, startNode, endNode, text, note, range, textWithSpaces);
    } else if (doc.selection && doc.selection.type !== 'Control') {
      text = doc.selection.createRange().text;
    }
    this.selectedText = text;
    this.note.selected_text = text;
  }

  countWords(str) {
    return str.trim().split(/\s+/).length;
  }

  tripHtmlTags(html) {
    return html.replace(/<[^>]*>/g, '');
  }

  removeBrackets(str) {
    return str.replace(/[\[\]]/g, '');
  }

  isFindFewOccurrences(text: string, noteHtml: any) {
    const regexp = new RegExp(text, 'g'); // if we found more than one text occurrences
    const array = [...noteHtml.matchAll(regexp)];
    return array.length;
  }

  setupStartEndIndexesForNoteInNode(
    noteHtml: string,
    startNode: string,
    endNode: string,
    selectedText: string,
    note: any,
    range: any,
    textWithSpaces: string
  ) {
    // const startNodeIndex = noteHtml.indexOf(startNode);
    // const endNodeIndex = noteHtml.indexOf(endNode);

    const noteContainer = document.querySelector(".note-text") as HTMLElement; 
    let startNodeIndex = this.getTextIndexInHtml(range, noteContainer);
    let endNodeIndex = this.getTextIndexInHtml(range, noteContainer, true);

    let { leadingSpaces, trailingSpaces } = this.countSpacesAroundText(textWithSpaces);
    startNodeIndex = startNodeIndex + leadingSpaces;
    endNodeIndex = endNodeIndex - trailingSpaces;
    
    // this.note.start_idx_num =
    //   this.notesHelperService.findStartIndex(startNode, selectedText, startNodeIndex > endNodeIndex) + startNodeIndex;
    // this.note.end_idx_num =
    //   this.notesHelperService.findEndIndex(endNode, selectedText, !(startNodeIndex > endNodeIndex)) + endNodeIndex;

    this.note.start_idx_num = startNodeIndex;
    this.note.end_idx_num = endNodeIndex;

    if (this.note.end_idx_num < this.note.start_idx_num) {
      const ind = this.note.start_idx_num;
      this.note.start_idx_num = this.note.end_idx_num;
      this.note.end_idx_num = ind;
    }

    if (this.isTextAlreadyHighlighted(this.note.start_idx_num, this.note.end_idx_num, note)) {
      this.clearSelection();
      return;
    }
  }

  countSpacesAroundText(text: string): { leadingSpaces: number; trailingSpaces: number } {
    let leadingSpaces = text.length - text.trimStart().length;
    let trailingSpaces = text.length - text.trimEnd().length;

    return { leadingSpaces, trailingSpaces };
  }

  getTextIndexInHtml(range: Range, rootElement: any, isEndIndex: boolean = false): number {
    let index = 0;
    const walker = document.createTreeWalker(rootElement, NodeFilter.SHOW_TEXT, null);

    while (walker.nextNode()) {
        if (walker.currentNode === (isEndIndex ? range.endContainer : range.startContainer)) {
            return index + (isEndIndex ? range.endOffset : range.startOffset);
        }
        index += walker.currentNode.nodeValue?.length || 0;
    }

    return -1;
  }

  /*
   * forbid to select already selected text
   * */
  isTextAlreadyHighlighted(start: number, end: number, note: any): boolean {
    const id = note.id;
    const existingNotes = this.studNotes.filter(i => i.curriculum_learning_objective_note_id === id);
    let res = false;
    if (existingNotes.length) {
      existingNotes.forEach((i: StudentNote) => {
        if (res) {
          return;
        }
        const { end_idx_num, start_idx_num } = i;
        if (start >= start_idx_num && start <= end_idx_num) {
          res = true;
        }
        if (end >= start_idx_num && end <= end_idx_num) {
          res = true;
        }
        if (start <= start_idx_num && end >= end_idx_num) {
          res = true;
        }
      });
    }
    return res;
  }

  openCreateNotePopup() {
    const dialogRef = this.dialog.open(StudentAddCommentToNotePopupComponent, {
      width: '522px',
      data: {
        inEditNoteMode: false
      }
    });

    dialogRef.afterClosed().subscribe(r => {
      if (r && r.comment && r.color) {
        this.note.text_note = r.comment;
        this.note.selected_text_color = r.color;
        this.createStudentNote();
      }
    });
  }

  onColorSelectEvent(c: string) {
    if (c === 'note') {
      this.openCreateNotePopup();
    } else {
      this.note.selected_text_color = c;
      this.createStudentNote();
    }
  }

  createStudentNote() {
    this.note.school_year_course_student_id = this.courseStudId;
    this.note.curriculum_topic_id = this.selectedlearningObjective.curriculum_topic_id;
    this.note.curriculum_learning_objective_note_id = this.currentSelectedNoteId.id;
    this.note.curriculum_learning_objective_id = this.selectedlearningObjective.id;

    if (this.note.selected_image) {
      this.addStartIndexToSelectedImage();
    }
    this.noteService.addNote(this.note).subscribe(
      resp => {
        this.newNoteAdded.emit((resp as any).data);
        this.toastr.success('Note was saved!');
      },
      error => {
        this.toastr.warning('Error happened!');
      }
    );
    this.clearSelection();
    
    setTimeout(() => {
      this.setupTeacherNotesForStudent();

      if (this.studNotes.length) {
        this.injectStudentTextNotes();
        this.processImagesNotes();
      }

      timer(1000)
        .pipe(take(1))
        .subscribe(resp => {
          this.addEventListenersToStudentsTextNotes();
          this.addEventListenersToStudentsImageNotes();
          this.cdr.detectChanges();
        });
    }, 1000);
  }

  addStartIndexToSelectedImage() {
    if (!this.note.selected_image) {
      return;
    }

    const note = this.teacherNotesForStudent.find(i => this.note.curriculum_learning_objective_note_id === i.id);
    if (note) {
      this.note.start_idx_num = note.note.indexOf(this.note.selected_image);
      this.note.end_idx_num = this.note.start_idx_num + this.note.selected_image.length;
    }
  }

  clearSelection() {
    this.selectedText = '';
    this.currentImageUrlOnHover = '';
    this.contextMenuCoordinates = {
      x: -200,
      y: -200
    };
    this.note = {};
  }

  sanitizeHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  compareNumbers(a, b) {
    return a.end_idx_num - b.start_idx_num;
  }

  private processImagesNotes() {
    this.notes.forEach(note => {
      note.studentNotes.forEach(n => {
        if (n.selected_image) {
          // if note is image note - do magic
          let text = note.note.changingThisBreaksApplicationSecurity.toString();
          const indexOfSelectedImage = text.indexOf(n.selected_image);
          const indexOfCloseTag = text.indexOf('>', indexOfSelectedImage + n.selected_image.length);
          const indexOfOpenTag = text.lastIndexOf('<img', indexOfSelectedImage);
          const allTag = text.substring(indexOfOpenTag, indexOfCloseTag + 1);
          text = text.replace(
            allTag,
            `<div id="ichl-${n.id}" style="border-color: ${n.selected_text_color};background-color: ${n.selected_text_color};border-radius: 4px;position: relative;"></div>${allTag}`
          );
          note.note = this.sanitizeHtml(text);
        }
      });
    });
  }

  createImageNote(e: { comment?: string; color?: string }) {
    this.note.image_note = e.comment;
    this.note.selected_text_color = e.color;
    this.note.selected_image = this.currentImageUrlOnHover;
    this.createStudentNote();
  }

}
