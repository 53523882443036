<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<!--<div class="chart-container" *ngIf="dataNew">-->
<!--  <canvas baseChart [colors]="colors" [datasets]="chartData" [labels]="labels" [options]="options" [plugins]="plugins"-->
<!--          [legend]="false" (chartClick)="onChartClick($event)">-->
<!--  </canvas>-->
<!--</div>-->

<div class="chart-container">
  <canvas *ngIf="dataNew" baseChart [data]="dataNew" [options]="barChartOptions"
          [legend]="false" (chartClick)="onChartClick($event)">
  </canvas>

  <div class="loader-section" *ngIf="!dataNew">
    <curr-loader [diameter]="48"></curr-loader>
  </div>
</div>

