import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { HistoricalAssessmentService } from './historical-assessment.service';
import { Observable, Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { fadeAnimation } from '../../shared/animations/fade.animation';
import { Assessment } from '../historical-assesment-widget/historical-assesment-widget.component';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '../../shared/components/breadcrumb/breadcrumb';
import { UserService } from '../../shared/user.service';

@Component({
  selector: 'curr-historical-assessment',
  templateUrl: './historical-assessment.component.html',
  styleUrls: ['./historical-assessment.component.scss'],
  animations: [fadeAnimation]
})
export class HistoricalAssessmentComponent implements OnInit, OnDestroy {
  assessments$: Observable<Assessment[]>;
  path: Breadcrumb[];
  config: PaginationInstance = { id: 'pagination', itemsPerPage: 10, currentPage: 0 };
  filtering = [undefined, undefined];
  isLoading: boolean = true;
  disabled: boolean = false;
  courseId: string;
  curriculumId: string;
  private subscription$ = new Subscription();

  constructor(
    private historicalAssessmentService: HistoricalAssessmentService,
    private userService: UserService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.courseId = this.route.snapshot.data.subjectDetails.courseId;
    this.initBreadcrumbs();
    this.assessments$ = this.historicalAssessmentService
      .getAllAssessments(+this.courseId,'2019-06-20 12:44:33','2021-06-23 12:44:33', 0, 1000)
      .pipe(map(res => {
        res.data.items.forEach(el => {
          if (el.course_id === this.courseId) {
            this.curriculumId = el.curriculum_id;
          }
        })
        return res.data.items;
        }),
        finalize(() => {
          this.isLoading = false
          this.path[1] = { label: this.route.snapshot.data.subjectDetails.subject, url: `/courses-details/subject-details/${ this.courseId }/${ this.curriculumId }` };
        })
      )
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  private initBreadcrumbs(): void {
    this.path = [
      { label: 'Subject', url: '/courses-details' },
      { label: this.route.snapshot.data.subjectDetails.subject, url: `/courses-details/subject-details/${ this.courseId }/${ this.curriculumId }` },
      { label: 'Historical assessments', url: '' }
    ];
  }

  public filter(event, testType: string): void {
    this.isLoading = true;
    this.disabled = true;
    if(testType === 'sa') {
      this.filtering[0] = event[0];
    }
    if(testType === 'topic_test') {
      this.filtering[1] = event[0];
    }
    const test_type = this.filtering.filter(el => el !== undefined)
    this.assessments$ = this.historicalAssessmentService
      .getAllAssessments(+this.courseId,'2019-06-20 12:44:33','2021-06-23 12:44:33', 0, 1000, test_type)
      .pipe(
        map(res => res.data.items),
        finalize(() => {
          this.isLoading = false;
          this.disabled = false;
          this.config = {...this.config, currentPage: 0};
        })
      );
  }
}
