/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Question } from '../../../student/test.model';

@Component({
  selector: 'curr-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit {
  @Input() isCumulativeTests?: boolean;
  @Input() questionNumber: number;
  @Input() question: Question;

  supportedQuestionsType = [
    'selectOne',
    'selectMultiple',
    'trueOrFalse',
    'textInput',
    'fileInput',
    'drawing',
    'workings',
    'cou'
  ];
  constructor() {}

  prevent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  ngOnInit() {}
}
