/*
 * Created by Volodymyr Mak. on 28/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SchoolAdmin, UserRole } from 'src/app/shared/components/profile-card/profile-card.model';
import { tap, delay } from 'rxjs/operators';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';

@Injectable()
export class SchoolAdminService {
  private adminData: SchoolAdmin = {
    role: UserRole.SCHOOL_ADMIN,
    avatar: '',
    country: 'UK',
    email: {value: 'adminTest@mail.com', verify: true},
    id: 'id1',
    name: 'Test Name',
    personalID: 'YE1232312434CA',
    phone: {value: '+2221312312312', verify: false},
    school: 'Some School'
  }

  constructor(
    private _profileDataModel: ProfileDataService,
  ) { }

  getSchoolAdminProfile(userId: string): Observable<SchoolAdmin> {
    return of(this.adminData).pipe(
      delay(500),
      tap((data: SchoolAdmin) => {
        this._profileDataModel.UserProfile.next(data);
      })
    )
  }

  updateStudent(schoolAdmin: SchoolAdmin): Observable<any> {
    return of('updated')
    .pipe(
      delay(500),
      tap({
        next: () => {
          this._profileDataModel.UserProfile.next(schoolAdmin)
        }
      })
    )
  }
}
