import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Question } from '../../../../student/test.model';
import { FileUploadService } from '../file-upload.service';
import { RestApiService, restApiServiceCreator } from '../../../api/rest-api.service';
import { ApplicationHttpClient } from '../../../api/application-http-client';
import { Observable, of, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, switchMap } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { QuestionFormQuestionBlockService } from './question-form-question-block.service';

@Component({
  selector: 'curr-question-form-question-block',
  templateUrl: './question-form-question-block.component.html',
  styleUrls: ['./question-form-question-block.component.scss']
})
export class QuestionFormQuestionBlockComponent implements OnInit, OnChanges, OnDestroy {
  @Input() question: Question;
  @Input() inSowPage = false;
  @Input() questionLabel = 'Question';
  @Input() questionPlaceholder = 'Question';

  @Output() saveQuestionData = new EventEmitter<string>();

  saTestService: RestApiService<Question>;
  cumulativeTestService: RestApiService<Question>;
  isQuestionTinyEditor = false;
  isEditQuestionWordingMode = true;
  pastExamOptions = [];
  form: FormGroup;
  subscription: Subscription;
  examinationBoardList = [];
  selectedBoard: string;

  constructor(private httpClient: ApplicationHttpClient,
              private fb: FormBuilder,
              private imageUploaderService: FileUploadService,
              private questionFormQuestionBlockService: QuestionFormQuestionBlockService,
  ) {
    this.initPastExamOptions();
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.intQuestionTitleForm();
  }

  ngOnInit(): void {
    this.cumulativeTestService
      = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(this.httpClient);
    this.saTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveSAQuestions')(this.httpClient);
    if (this.inSowPage) {
      this.isEditQuestionWordingMode = true;
    }
    if (this.question.question.includes('<p>')) {
      this.isQuestionTinyEditor = true;
    }
    this.questionFormQuestionBlockService.getExaminationBoardList().pipe(first()).subscribe((list: any) => {
      list.forEach(board => {
        if (board.id === this.question.examination_board?.id) {
          this.selectedBoard = JSON.stringify({ name: board.name, country: board.country, id: board.id });
        }
        this.examinationBoardList.push({ label: board.name, value: JSON.stringify({ name: board.name, country: board.country, id: board.id }) })
      })
      if (!this.selectedBoard) {
        this.selectedBoard = this.examinationBoardList[0].value;
      }
    });
  }

  editQuestion(variable: string) {
    this[variable] = true;
  }

  cancelEdit(variable: string) {
    this[variable] = false;
  }

  public onLoadFile(fileList: FileList): void {
    const file = fileList[0];
    this.imageUploaderService.upload(file, 'QUESTION').subscribe(
      (resp: { uuidName: string, oldName: string, state: string }[]) => {
        const i = resp[0];
        if (i.uuidName) {
          this.question.question_file = i.uuidName + '' || '';
        }
      });
  }

  updateValue($event): void {
    this.form.controls.title.patchValue($event.event.target.innerText)
    // this.question.question = $event.event.target.innerText;
  }

  updateName(val: string) {
    this.question.question = val;
    this.updateQuestion(val);
  }

  saveQuestion(mode: string) {
    this.saveQuestionData.emit(mode);
    this[mode] = false;
  }

  updateQuestion(val: string) {
    of(val).pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap((text) => {
        this.question.question = text;
        return this.createUpdateQuestionRequest(this.question);
      })
    ).subscribe(resp => {
    });
  }

  createUpdateQuestionRequest(q: Question) {
    let req: Observable<Question>;
    if (this.question.base_type !== 'ct') {
      req = this.saTestService.update(q, q.id + ''); // SA
    } else {
      req = this.cumulativeTestService.update(q, q.id + ''); // CT
    }
    return req;
  }

  private initPastExamOptions() {
    let year = 2002;
    const currentYear = (new Date).getFullYear();
    while (year <= currentYear) {
      this.pastExamOptions.push({ label: year, value: year });
      year++;
    }
  }

  setIsPastExam(val: any[]) {
    this.question.is_past_exam_question = val[0];
  }

  setPastExamYear(val: any | []) {
    this.question.question_year = val;
  }

  setExaminationBoard(val: any) {
    this.question.examination_board = JSON.parse(val);
  }

  private intQuestionTitleForm() {
    this.form = this.fb.group({
      title: new FormControl(this.question.question)
    });
    this.subscription = this.form.controls.title.valueChanges.subscribe(val => {
      this.question.question = val;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
