import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { AuthService } from 'src/app/auth/auth.service';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { User } from 'src/app/shared/user';
import { COUNTRIES } from 'src/app/shared/const/countries';
import { GENDERS } from 'src/app/shared/const/genders';
import { Subscription } from 'rxjs';
import { fadeAnimation } from '../../shared/animations/fade.animation';

@Component({
  selector: 'curr-student-bio-card',
  templateUrl: './student-bio-card.component.html',
  styleUrls: ['./student-bio-card.component.scss'],
  animations: [fadeAnimation]
})
export class StudentBioCardComponent implements OnInit, OnChanges, OnDestroy {
  profileData: User;

  editPersonalData = false;
  curUser;
  editUser = false;
  initialProfileData: User;
  cancelButtonStyle = { color: '#006878', 'text-decoration': 'underline' };
  countries = [];
  genders = [];
  max = new Date();

  dateFormat = 'dd/MM/yyyy';
  private subscription = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private httpClient: ApplicationHttpClient,
    private authService: AuthService
  ) {
    this.genders = GENDERS;
  }

  ngOnInit(): void {
    this.max = new Date(this.max.getFullYear() - 10, this.max.getMonth(), this.max.getDate());
    this.getStudentBio();

    COUNTRIES.forEach(country => {
      this.countries.push({ value: country.name, label: country.name });
    });
  }

  ngOnChanges(): void {
    this.curUser = this.localStorageService.get('currentUser');
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
  
  toggleEditpersonalDataMode(isTrue: boolean) {
    this.editPersonalData = isTrue;
  }

  setCountry(value: any) {
    this.profileData.country = value;
  }

  setGender(value: any) {
    this.profileData.gender = value;
  }

  cancelChanges() {
    this.toggleEditpersonalDataMode(false);
  }

  saveTask(date: any) {
    const newDate = date.selected;
    this.profileData.dateOfBirth = this.toIsoString(new Date(newDate));
  }

  pad(num) {
    if (num < 10) {
      return '0' + num;
    }
    return num;
  }

  toIsoString(date: Date) {
    this.pad(date.getDate() + '-' + this.pad(date.getMonth() + 1) + '-' + date.getFullYear());
    return date.getFullYear() + '-' + this.pad(date.getMonth() + 1) + '-' + this.pad(date.getDate());
  }

  updateUserProfile() {
    this.subscription.add(
      this.httpClient.put(`/users/students`, this.profileData).subscribe(resp => {
        this.toggleEditpersonalDataMode(false);
        this.initialProfileData = { ...this.profileData };
      })
    );
  }

  getStudentBio() {
    this.subscription.add(
      this.authService.getCurrentUser().subscribe(resp => {
        this.profileData = resp;
      })
    );
  }
}
