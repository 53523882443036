import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CourseResolver implements Resolve<{ courseid: string }> {
  constructor(private httpClient: ApplicationHttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ courseid: string }> {
    const schoolYearCourseId = route.paramMap.get('schoolYearCourseId');
    const curriculumId = route.paramMap.get('subjectId');
    return this.httpClient
      .get(`/schoolYearCourses/${schoolYearCourseId}`, {
        curriculum_id: curriculumId
      } as any)
      .pipe(map(res => (res as any).data));
  }
}
