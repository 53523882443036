<!-- Created by Maxim B. on 23/07/20.-->
<!-- Copyright © 2020 SEVEN. All rights reserved.-->

<div class="full-width">
  <div fxLayout="row" fxLayoutAlign="space-between center" id="nav-sow" class="" [ngStyle]="{height: '36px'}">
    <div class="overline last-modified ">Last modified: {{ topic?.updated_at | date }}</div>
    <curr-button *ngIf="!inEditMode && notes.length > 0" color="primary" variant="outlined" size="big" (btnClick)="editNotes()">EDIT</curr-button>
    <curr-button *ngIf="inEditMode && notes.length > 0" [isDisabled]="isEditFieldsValid" size="big" color="primary" variant="outlined"
      (btnClick)="editNotes()">
      SAVE
    </curr-button>
  </div>
</div>

<div class="m-t-16">
  <curr-loader [diameter]="40" [isLoading]="isNotesLoading" *ngIf="isNotesLoading"></curr-loader>
</div>

<div @fade class="m-t-16 full-width" *ngIf="!isNotesLoading">
  <div *ngIf="!notes.length && !isNotesFormVisible" class="no-student-handout">
    <img src="/assets/images/colored/empty-task-list.svg" />
    <div class="empty-text">No Student Handout Yet!</div>
  </div>

  <ng-container *ngFor="let note of notes; let i = index">
    <div fxLayout="column" fxLayoutAlign="start stretch" class="m-t-16">
      <div class="title mat-body-2 m-b-16">
        <curr-input *ngIf="inEditMode" [isRequired]="true" [(value)]="note.title" fxFlex="70"
          placeholder="Enter note title" errorMessage="Title can't be empty">
        </curr-input>
        <ng-container *ngIf="!inEditMode">{{ note.title }}:</ng-container>
      </div>

      <div fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container">
        <curr-tiny-editor-disabled [topic]="topic" [(value)]="note.note" [disabled]="!inEditMode"
          [options]="{ height: 500, placeholder: '', menubar: 'file edit view  insert format table help' }"
          fxFlex="100"></curr-tiny-editor-disabled>

        <button mat-icon-button (click)="deleteNote(i)" *ngIf="inEditMode">
          <mat-icon [svgIcon]="'trash'"></mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

  <div class="m-b-8 m-t-16" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="isNotesFormVisible">
    <curr-input placeholder="Enter note title" [(value)]="newNote.title" fxFlex="70" class="no-hint-input"></curr-input>
    <curr-button (btnClick)="saveNote()" size="big" [isDisabled]="!newNote.title" [variant]="'contained'">
      SAVE
    </curr-button>
    <curr-button (btnClick)="isNotesFormVisible = false" size="big" variant="text">CANCEL</curr-button>
  </div>

  <div class="m-b-8 m-t-16" fxLayout="row" fxLayoutAlign="space-between center">
    <curr-button [icon]="'plus'" size="big" [variant]="'contained'" class="student-btn" (btnClick)="isNotesFormVisible = true; inEditMode = true">
      ADD STUDENT HANDOUT
    </curr-button>
  </div>
</div>