import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { UserService } from '../../user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'curr-refferal-pop-up',
  templateUrl: './refferal-pop-up.component.html',
  styleUrls: ['./refferal-pop-up.component.scss']
})
export class RefferalPopUpComponent implements OnInit {
  referralIcone = 'referral-data';
  userReferralInfo$: Observable<any>;
  origin: string = '';

  constructor(
    private userService: UserService,
    private clipboard: Clipboard,
    public dialogRef: MatDialogRef<RefferalPopUpComponent>,
    private toastr: ToastrService,
  ) {
    dialogRef.addPanelClass('refferal-pop-up-dialog-container');
  }

  ngOnInit(): void {
    this.origin = location.origin;
    this.userReferralInfo$ = this.userService.getUserReferralInfo().pipe(map(res => res.data.referralCode));
   }

  copyLink(code: string): void {
    this.clipboard.copy(`${ this.origin }/sign-up/${ code }`);
    this.toastr.success('Link copied!');
  }

  close(): void {
    this.dialogRef.close();
  }
}
