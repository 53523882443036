/**
 * Created by Iryna Iak. on 18/10/21.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChooseYearService } from '../stepper/choose-year.service';
import { fadeAnimation } from '../../animations/fade.animation';
import { UserService } from '../../user.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'curr-choose-year-popup',
  templateUrl: './choose-year-popup.component.html',
  styleUrls: ['./choose-year-popup.component.scss'],
  animations: [fadeAnimation]
})
export class ChooseYearPopupComponent implements OnInit, OnDestroy {
  selectedYear: string;
  years$: Observable<any>;
  emitHighlight: boolean;
  destroyed$: Subject<void> = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ChooseYearPopupComponent>,
    private choosedYearService: ChooseYearService,
    private userService: UserService,
    ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.years$ = this.userService.getSchoolYears();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  confirmYear(): void {
    this.years$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      const result = res.filter(item => item.id === this.selectedYear);
      this.userService.setStudyYear({ schoolStudyYearId: result[0].id as number })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
        this.choosedYearService.changeYear(this.selectedYear);
        this.choosedYearService.setHighlight$.next(false);
        this.dialogRef.close(this.selectedYear);
      });
    });
  }

  selectYear(year: string): void {
    this.selectedYear = year;
  }
}
