/*
 * Created by Dmytro Sav. on 4/23/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GettingStartedTaskService } from '../../../teacher/dashboard/getting-started-task.service';

interface Buttons {
  label: string;
  callback: () => void;
  size?: string;
  link: string;
  color: string;
  variant: string;
  isDisabled?: boolean;
  icon?: string;
}

interface TaskValue {
  label: string;
  checked: false;
  color?: string;
}

@Component({
  selector: 'curr-getting-started-tasks',
  templateUrl: './getting-started-tasks.component.html',
  styleUrls: ['./getting-started-tasks.component.scss']
})
export class GettingStartedTasksComponent implements OnInit {
  percentage: string = '';

  constructor(private gettingStartedTaskService: GettingStartedTaskService,
              public dialog: MatDialog) {
  }

  @Input() tasks: {
    title: string;
    id: number;
    value: TaskValue;
    buttons: Array<Buttons>;
  } [] = [];

  ngOnInit() {
    this.tasks.forEach(({ value }, index) => {
      value.color = value.color || 'accent';
    });

    this.updateValue();
  }

  updateValue(id: number = 0) {
    if (id) {
      this.gettingStartedTaskService.setTaskChecked(id).subscribe(resp => {
        this.upd();
      });
    } else {
      this.upd();
    }
  }

  upd(){
    const checked = this.tasks
      .map(({ value }) => {
        return value.checked;
      })
      .filter(Boolean).length;

    this.percentage = this.calcPercentage(checked, this.tasks.length);
  }

  calcPercentage(a: number, b: number): string {
    if (b === 0) {
      return '0';
    }
    return ((a * 100) / b).toFixed(0);
  }
}
