/**
 * Created by Mykola Dan. 02/11/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Assessment } from '../historical-assesment-widget.component';
import { Router } from '@angular/router';
import { TimeDateService } from 'src/app/shared/services/time-date.service';

@Component({
  selector: 'curr-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  @HostListener('click')
  goToTest() {
    if(this.assessment.test_type === 'topic_test'){
      this.router.navigateByUrl(`/courses-details/ct-review/${ this.assessment.test_id }/${ this.assessment.course_student_id }/${ this.assessment.course_id }`);
    }
    else {
      this.router.navigateByUrl(`/courses-details/sa-review/${ this.assessment.student_test_id }/${ this.assessment.course_id }`);
    }
  }
  @Input() assessment: Assessment;
  date: string = '';
  time;
  gradeColor: string = '';
  icon: string = 'coin';
  answers: string = '';

  constructor(
    private router: Router,
    public timeDateService: TimeDateService
    ) { }

  ngOnInit(): void {
    this.date = this.transformDate();
    this.time = this.timeDateService.convertToLocalTimeFromMs(this.assessment.finished_at);
    this.answers = this.transformAnswers();
    this.setGradeColor();
  }

  msToTime(milliseconds: number) {
    let seconds: any = Math.floor((milliseconds / 1000) % 60);
    let minutes: any = Math.floor((milliseconds / (1000 * 60)) % 60);
    let hours: any = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    if(hours === '00') {
      return minutes + ':' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  transformDate(): string {
    const convertDate = new Date(this.assessment.finished_at)
    return `${convertDate.toLocaleString('default', { month: 'short' })} ${convertDate.getDay()}  ${convertDate.getFullYear()}`;
  }

  // transformTime() {
  //   // const convertTime = Math.abs(new Date(this.assessment.finished_at).getDate() - new Date(this.assessment.start_time).getDate());
  //   // @ts-ignore
  //   this.time = new Date(this.assessment.finished_at).getDate();
  //   // return this.msToTime(convertTime);
  // }

  transformAnswers(): string {
    if(this.assessment.correct_answers === 0 || this.assessment.correct_answers){
      return this.assessment.test_type === 'sa' ?
        `Correct answers: ${ this.assessment.correct_answers}/${ this.assessment.total_answers}` :
        `Grade: ${ this.assessment.correct_answers}/${ this.assessment.total_answers}`;
    }
  }

  setGradeColor(): void {
    if(this.assessment.test_type === 'topic_test') {
      return;
    }
    if(this.assessment.correct_answers === this.assessment.total_answers) {
      this.gradeColor = 'green';
      this.icon = 'coin-green-4';
    } else if(this.assessment.correct_answers === 0) {
      this.gradeColor = 'orange';
      this.icon = 'orange'
    } else {
      this.gradeColor = 'amber';
      this.icon = 'coin-gold-4';
    }
  }

  goToReDoPage(event: Event): void {
    event.stopPropagation();
    this.router.navigateByUrl(`courses-details/${ this.assessment.course_id }/self-assessment/${ this.assessment.lobjective_id }`);
    this.router.navigateByUrl(`courses-details/learning-resources/prior/${ this.assessment.curriculum_id }/${ this.assessment.course_id }/${ this.assessment.topic_id }/prior`)
    const lOInfo = { reDo:  true, loId: this.assessment.lobjective_id };
    localStorage.setItem('reDo', JSON.stringify(lOInfo));
  }

  goToMaterials(event: Event): void {
    event.stopPropagation();
    // TODO add topic test
    this.router.navigateByUrl(`courses-details/learning-resources/prior/${ this.assessment.curriculum_id }/${ this.assessment.course_id }/${ this.assessment.topic_id }/prior`)
  }
}
