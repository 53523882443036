/**
 * Created by Maxim Bon. on 12/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ETestType } from '../../teacher/teacher.enum';

export class CumulativeTest {
  constructor(name = 'Common test', id: number = -1) {
    this.test_name = name;
    this.curriculum_paper_id = id;
    this.lobjective_ids = [];
    this.lobjective_cum_question_ids = [];
    this.can_be_downloaded = false;
    // this.date = new Date();
  }
  id?: number;
  test_name?: string;
  markType?: 'AI_MARKED' | 'SELF_MARKED';
  type?: ETestType;
  sequence_num?: string;
  lobjective_ids?: number[];
  start_time?: string | Date | null;
  end_time?: string | Date | null;
  curriculum_paper_id?: number;
  school_year_course_id?: number;
  lobjective_cum_question_ids?: number[];
  can_be_downloaded?: boolean;
  is_offline?: boolean;
  is_published?: boolean;
}
