/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'curr-credits-error',
  templateUrl: './credits-error.component.html',
  styleUrls: ['./credits-error.component.scss']
})
export class CreditsErrorComponent {}
