<!--
 Created by Dmytro Sav. on 5/11/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div fxLayout="column" class="container">
  <div class="m-b-40" fxLayout="row" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
      <curr-button variant="contained" color="primary" size="small">
        CANCEL
      </curr-button>

      <curr-button variant="contained" color="primary" size="small">
        EDIT
      </curr-button>

      <curr-button variant="contained" color="primary" size="small">
        SAVE
      </curr-button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between">
    <div fxLayout="column">
      <curr-edit-info [userInfo]="userInfo" fxFlex="326px"></curr-edit-info>

      <div class="mat-body-2">Courses</div>

      <div fxLayout="row wrap" fxLayoutGap="20px">
        <curr-edit-courses
          [courseOptions]="courseOptions"
          [yearsOptions]="yearsOptions"
          [formOptions]="formOptions"
          [gradeOptions]="gradeOptions"
          [isEdited]="true"
          (selectedValues)="getSelectedValues($event)"
        ></curr-edit-courses>

        <curr-edit-courses
          [courseOptions]="courseOptions"
          [yearsOptions]="yearsOptions"
          [formOptions]="formOptions"
          [gradeOptions]="gradeOptions"
          *ngFor="let subject of subjects"
          [subject]="subject"
          [isEdited]="false"
        ></curr-edit-courses>
      </div>
    </div>

    <curr-sidebar [data]="sidebarData"></curr-sidebar>
  </div>
</div>
