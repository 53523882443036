/*
 * Created by Dmytro Sav. on 5/14/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'curr-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent {
  @Input() title: string;
  @Input() initDate: Date;
  @Input() pickerType: 'both' | 'calendar' | 'timer' = 'both';
  @Input() dateFormat = 'dd/MM/yyyy, hh:mm a';

  @Output() dateEmit = new EventEmitter<Date>();

  @ViewChild('dateInput', { static: true }) dateInputRef: ElementRef<HTMLElement>;
  saveTask(date) {
    const newDate = new Date(date.selected);

    if (newDate) {
      this.dateEmit.emit(newDate);
    }
  }

  public open(): void {
    this.dateInputRef?.nativeElement.click();
  }
}
