<!--
 Created by Yaroslav S. on 10/07/2024
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="ai-subjects-table">
  <div class="top-section">
    <div class="top-section-item item-text">Please select the Subjects and Years for which AI should be applied</div>
    <div class="top-section-item">
      <mat-slide-toggle [color]="'primary'" [checked]="isApplyAll" [(ngModel)]="isApplyAll">
        <span class="slide-toggle-label">Apply for all Subjects and Years</span>
      </mat-slide-toggle>
    </div>
  </div>

  <div class="loader-container" *ngIf="isDataLoading">
    <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
  </div>

  <ng-container *ngIf="isDataPresented">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" aria-describedby="users desc">
        <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 0: -1">

          <ng-container>
            <th mat-header-cell *matHeaderCellDef scope="col">
              <div *ngIf="item !== 'subjectName'">Year {{ item }}</div>
            </th>
          </ng-container>

          <td mat-cell *matCellDef="let element" [ngClass]="element.editMode ? 'edit-line' : ''">
            <ng-container *ngIf="item === 'subjectName'">
              {{element[item]}}
            </ng-container>
            <ng-container *ngIf="item !== 'subjectName'">
              <div *ngFor="let levelSetting of element.levelSettings">
                <div *ngIf="levelSetting.levelId == item" class="level-setting-item">
                  <div *ngIf="!element.editMode" class="view-mode-text">{{transformAiTitle(levelSetting.aiSubscriptionSettings)}}</div>
                  <mat-radio-group (change)="setItemValue($event, levelSetting.levelId)" *ngIf="element.editMode">
                    <mat-radio-button [value]="'NOT_APPLIED'" [checked]="levelSetting.aiSubscriptionSettings === 'NOT_APPLIED'">Not Applied</mat-radio-button>
                    <mat-radio-button [value]="'GPT_4_O'" [checked]="levelSetting.aiSubscriptionSettings === 'GPT_4_O'">GPT-4</mat-radio-button>
                    <mat-radio-button [value]="'GPT_4_O_MINI'" [checked]="levelSetting.aiSubscriptionSettings === 'GPT_4_O_MINI'">GPT-4 Mini</mat-radio-button>
                    <mat-radio-button [value]="'O1_PREVIEW'" [checked]="levelSetting.aiSubscriptionSettings === 'O1_PREVIEW'">o1-preview</mat-radio-button>
                    <mat-radio-button [value]="'O1_MINI'" [checked]="levelSetting.aiSubscriptionSettings === 'O1_MINI'">o1-mini</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef scope="col"></th>
          <td mat-cell *matCellDef="let element" [ngClass]="element.editMode ? 'edit-line' : ''">
            <curr-button
              *ngIf="!element.editMode"
              variant="contained"
              [isDisabled]="isEditLine"
              (btnClick)="editTableItem(element.index)"
              size="big">
              <mat-icon>edit</mat-icon>
              <span>EDIT</span>
            </curr-button>

            <curr-button
              *ngIf="element.editMode"
              variant="contained"
              (btnClick)="saveTableItem(element.index)"
              size="big">
              <mat-icon>save</mat-icon>
              <span>SAVE</span>
            </curr-button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <curr-table-pagination
      [totalResults]="resultsLength"
      [pageSize]="10"
      [currentPageIndex]="currentPageIndex"
      [showingText]="'Subjects'"
      (changePage)="onPageChange($event)">
    </curr-table-pagination>
  </ng-container>

  <ng-container *ngIf="!isDataPresented">
    <div class="no-data-container">
      <img src="/assets/images/colored/amico.svg" />
      <div class="no-data-title">Please invite your teachers to view this data</div>
      <div class="no-data-subtitle">Please share your course ID with teachers</div>
    </div>
  </ng-container>
</div>
