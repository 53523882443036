import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CourseFilters, FilterItem, TreeFilterNode } from '../course-filters';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { CourseFilterService } from '../course-filter.service';

@Component({
  selector: 'curr-inline-course-filter',
  templateUrl: './inline-course-filter.component.html',
  styleUrls: ['./inline-course-filter.component.scss'],
  animations: [fadeAnimation]
})
export class InlineCourseFilterComponent implements OnInit {
  filters: CourseFilters;

  filtersSelect: FilterItem[] = [];

  labelMinLength = 3;

  itemsPerBatch = 10;

  itemsToDisplay: { value: string; label: string }[] = [];

  @Output()
  filterChanged = new EventEmitter<string>();

  constructor(private courseFilterService: CourseFilterService) {
  }

  ngOnInit(): void {
    this.courseFilterService.getCourseFilter().subscribe(res => {
      // generates items for filters
      this.filtersSelect = this.courseFilterService.generateFilterItems(res);

      // TODO: old version of filter generator could be removed in the next revision
      // const treeFilter = new TreeFilter(this.filters.subjects);
      // treeFilter.addLevel(this.filters.levels, this.filters.subjectToLevel);
      // treeFilter.addLevel(this.filters.courses, this.filters.levelToCourse);
      // treeFilter.traverse(node => {
      //   if (!node.children && node.item.value) {
      //     const label = this.constructLabelForFilter(node);
      //     if (label) {
      //       this.filtersSelect.push(label);
      //     }
      //   }
      // });

      this.filtersSelect.sort((a, b) => a.label.localeCompare(b.label));

      this.getNextBatch();

      if (this.filtersSelect[0]) {
        this.filterChanged.emit(this.filtersSelect[0].value);
      } else {
        this.filterChanged.emit(null);
      }
    });
  }

  constructLabelForFilter(node: TreeFilterNode): { value: string; label: string } {
    const labelParts = [];
    let currentNode = node;
    while (currentNode) {
      if (currentNode.item) {
        labelParts.unshift(currentNode.item.label);
      }
      currentNode = currentNode.parent;
    }
    if (labelParts.length >= this.labelMinLength) {
      return { value: node.item.value, label: labelParts.join(' - ') };
    } else {
      return undefined;
    }
  }

  getNextBatch() {
    this.itemsToDisplay.push(
      ...this.filtersSelect.slice(this.itemsToDisplay.length, this.itemsToDisplay.length + this.itemsPerBatch)
    );
  }
}
