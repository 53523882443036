/**
 * Created by Maxim B. on 17/06/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LearningObjective } from '../../api-subject-details';
import { getRestApiProvider, RestApiService } from '../../../../shared/api/rest-api.service';
import { LessonPlan } from './lesson-plan';
import { DomSanitizer } from '@angular/platform-browser';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'curr-lesson-plan',
  templateUrl: './lesson-plan.component.html',
  styleUrls: ['./lesson-plan.component.scss'],
  providers: [getRestApiProvider<LessonPlan>('curriculumLearningObjectiveLessonPlans')]
})
export class LessonPlanComponent implements OnInit, OnChanges {
  @Input()
  learningObjective: LearningObjective;

  @Input()
  learningObjectives: LearningObjective[];

  @Output()
  learningObjectiveChanged = new EventEmitter<LearningObjective>();

  file: File;

  inEditTopicCoveredMode = false;
  levels = [
    { label: 'FOUNDATION', value: 'FOUNDATION' },
    { label: 'HIGHER', value: 'HIGHER' }
  ];
  coveredTopicsOptions = [];
  lessonPlans: LessonPlan[] = [];

  wopiUrls: string[] = [];

  isAdding = false;
  isDisplayFrame = false;

  getAll$: Observable<LessonPlan[]>;

  constructor(private lessonPlanService: RestApiService<LessonPlan>, public sanitizer: DomSanitizer) {}

  ngOnInit() {

  }

  ngOnChanges() {
    this.isDisplayFrame = false;
    setTimeout(() => {
      this.isDisplayFrame = true;
    }, 8000);

    if (this.learningObjectives) {
      this.coveredTopicsOptions = this.learningObjectives.map(topic => {
        return { label: topic.title, value: topic.id };
      });
    }
    this.getAll$ = this.lessonPlanService.getAll({ curriculum_learning_objective_id: this.learningObjective.id }).pipe(
      map((res: LessonPlan[]) => {
        res.forEach(lessonPlan => {
          if (lessonPlan.wopi_url) {
            this.wopiUrls[lessonPlan.id] = this.bypass(lessonPlan.wopi_url as string);
          }
          if (!lessonPlan.lesson_plan_lobj_ids) {
            lessonPlan.lesson_plan_lobj_ids = [];
          }
        });
        return res;
      })
    );
    this.getAll$.subscribe(res => {
      this.lessonPlans = res;
    });
  }

  updateLessonPlan(lessonPlan: LessonPlan) {
    this.inEditTopicCoveredMode = false;
    lessonPlan.sequence_number = lessonPlan.sequence_number || lessonPlan.sequence_num;
    // this.lessonPlanService.update(lessonPlan, lessonPlan.id).subscribe();
    this.lessonPlanService.updateWithFormData(lessonPlan, lessonPlan.id).subscribe((res: any) => {
      lessonPlan.wopi_url = res;
      this.wopiUrls[lessonPlan.id] = this.bypass(lessonPlan.wopi_url as string);
    });
    // this.lessonPlanService.updateWithQueryParams(lessonPlan.id, null, lessonPlan).subscribe();
  }

  addLessonPlan() {
    this.lessonPlanService
      .addWithFormData({
        curriculumLearningObjectiveId: this.learningObjective.id,
        lessonPlanLearningObjectiveIds: [this.learningObjective.id],
        difficulty_level: 'FOUNDATION',
        sequence_number: this.lessonPlans[this.lessonPlans.length - 1]?.sequence_num
          ? this.lessonPlans[this.lessonPlans.length - 1].sequence_num + 1
          : 1,
        file: this.file
      } as any)
      .pipe(
        switchMap(res => {
          // return this.lessonPlanService.getById(res.id);
          return this.getAll$;
        })
      )
      .subscribe(res => {
        this.lessonPlans = res;
        this.isAdding = false;
      });
  }

  delete(index: number) {
    this.lessonPlanService.remove(this.lessonPlans[index].id).subscribe(res => this.lessonPlans.splice(index, 1));
  }

  onLoadedFile($event) {
    this.file = $event[0];
  }

  onLoadedFileToUpdate($event, lessonPlan: LessonPlan) {
    lessonPlan.file = $event[0];
  }

  bypass(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getTopicById(id: string): LearningObjective {
    return this.learningObjectives.find(topic => topic.id === id);
  }
}
