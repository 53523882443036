/**
 * Created by Maxim Bon. on 01/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curr-shared-pages',
  templateUrl: './shared-pages.component.html',
  styleUrls: ['./shared-pages.component.scss']
})
export class SharedPagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
