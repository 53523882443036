/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input } from '@angular/core';
import {ChartConfiguration, ChartType} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { IUnderlyingFactors } from 'src/app/teacher/interfaces/underlying-factors';

@Component({
  selector: 'curr-underlying-factors-chart',
  templateUrl: './underlying-factors-chart.component.html',
  styleUrls: ['./underlying-factors-chart.component.scss']
})
export class UnderlyingFactorsChartComponent {
  @Input() set data(value: IUnderlyingFactors) {
    if (value) {
      this.statisticData = value;

      this.doughnutChartData = {
        labels: ['Lack of Technique', 'Lack of Revision/Knowledge', 'Lack of Understanding', 'Time Management Issues', 'Health and Wellbeing', 'General Issues'],
        datasets: [
          {
            data: [
              Number(value.lackOfTechnique),
              Number(value.lackOfRevisionKnowledge),
              Number(value.lackOfUnderstanding),
              Number(value.timeManagementIssues),
              Number(value.healthAndWellbeing),
              Number(value.generalIssues)
            ],
            backgroundColor: ['#D06563', '#FCC333', '#D27D33', '#4DA2C9', '#66BBAA', '#9EA3A5'],
            hoverBackgroundColor: ['#D06563', '#FCC333', '#D27D33', '#4DA2C9', '#66BBAA', '#9EA3A5'],
            borderColor: ['#D06563', '#FCC333', '#D27D33', '#4DA2C9', '#66BBAA', '#9EA3A5'],
            hoverBorderColor: ['#D06563', '#FCC333', '#D27D33', '#4DA2C9', '#66BBAA', '#9EA3A5'],
            borderWidth: 1
          },
        ],
      };
    }
  }

  type: ChartType = 'doughnut';

  chartData: number[];
  labels: string[];
  doughnutChartData;
  statisticData: IUnderlyingFactors;

  public doughnutChartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    spacing: 2,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        align: 'center',
        color: '#000A12',
        font: {
          weight: 500,
          size: 10
        }
      },
    },
    cutout: '60%',
  };

  constructor() {}

  scrollToSection(section: string) {
    const element = document.querySelector(`[data-titleName="${section}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
