<div class="container" currDragDrop (onFileDropped)="onClick($event)" fxLayout="column" fxLayoutAlign="center center">
  <span class="mat-h4 hint" *ngIf="hint">{{ hint }}</span>
  <div class="wrap-content" fxLayout="column" fxLayoutAlign="center center">
    <div class="upload_header" [class.column]="type === 'answer-question'" [class.row]="type === 'edit-resource'">
      <mat-icon svgIcon="drop-files" class="icon"></mat-icon>
      <div class="mat-h2 text-centered title">{{ title }}</div>
    </div>

    <div class="m-b-16 overline">OR</div>
    <div class="wrapper m-b-24">
      <curr-button variant="contained" class="btn m-b-8 m-r-8" (btnClick)="onClick()">SELECT FROM COMPUTER</curr-button>
      <curr-button variant="contained" class="btn m-b-8 m-r-8" *ngIf="displayPhotoBtn">TAKE PHOTO</curr-button>
    </div>

    <div class="supported overline" [class.m-b-32]="files.length > 0">SUPPORTED FORMAT: {{ supportedConverted }}</div>
<!--    <igx-toast-->
<!--      #toast-->
<!--      class="toast mat-body-2 m-b-8"-->
<!--      displayTime="1000"-->
<!--      [message]="notSupportedMessage"-->
<!--      [position]="toastPosition"-->
<!--    ></igx-toast>-->

    <mat-divider *ngIf="files.length > 0 && enableProgressbard" class="mat-divider"></mat-divider>
  </div>

  <ul class="files-process m-t-24" *ngIf="files.length > 0">
    <li *ngFor="let file of files.slice().reverse(); let last = last" [class.m-b-32]="!last" [class.m-b-0]="last">
      <div class="row file-row">
        <span class="mat-h4 m-b-4">{{ file.data.name }}</span>
        <ng-container *ngIf="enableProgressbard">
          <span id="file-label">
            <a *ngIf="!file.canRetry && file.isCompleted">
              <mat-icon class="complete-icon" svgIcon="completed-upload"></mat-icon>
              <span class="remove-label" (click)="cancelFile(file)">
                <mat-icon class="m-r-8 close-icon inline-flex" svgIcon="close"></mat-icon>
                <span class="button">REMOVE</span>
              </span>
            </a>

            <a title="Retry" (click)="retryFile(file)" *ngIf="file.canRetry">
              <mat-icon svgIcon="retry" class="icon-process"></mat-icon>
            </a>
            <a title="Cancel" (click)="cancelFile(file)" *ngIf="!file.isCompleted">
              <mat-icon svgIcon="close" class="icon-process"></mat-icon>
            </a>
          </span>
        </ng-container>
      </div>
      <ng-container *ngIf="enableProgressbard">
        <mat-progress-bar
          class="progress-bar m-b-4"
          [color]="file.color"
          [value]="file.progress"
          mode="determinate"
        ></mat-progress-bar>
        <span class="completed mat-body-2" *ngIf="file.isCompleted">Completed</span>
        <span *ngIf="file.canRetry">Uploading error</span>
        <span class="mat-body-2" *ngIf="!file.isCompleted && !file.canRetry"> {{ file?.progress }}% </span>

        <span *ngIf="file.timeLeft"> - Time left: {{ file?.timeLeft }} </span>
      </ng-container>
    </li>
  </ul>

  <div class="camera" fxLayout="column" fxLayoutAlign="center center">
    <curr-camera *ngIf="isShowedCamera" (pictureTaken)="handleImage($event)"></curr-camera>

    <div class="snapshot" *ngIf="webcamImage">
      <img [src]="webcamImage.imageAsDataUrl" alt="image" />
    </div>
  </div>
</div>

<input
  type="file"
  #fileUpload
  id="fileUpload"
  name="fileUpload"
  enctype="multipart/form-data"
  multiple="multiple"
  accept="{{ supported }}"
  hidden
/>
