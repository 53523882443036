/**
 * Created by Alex Poh. on 20/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthInputComponent } from './auth-input/auth-input.component';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailOrPhoneInputComponent } from './email-or-phone-input/email-or-phone-input.component';
import { SharedModule } from '../../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [EmailOrPhoneInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    RouterModule,
    FlexLayoutModule
  ],
  exports: [
    AuthInputComponent,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    FlexLayoutModule,
    EmailOrPhoneInputComponent
  ]
})
export class AuthSharedModule {
}
