/**
 * Created by Maxim B. on 22/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ordinal' })
export class OrdinalPipe implements PipeTransform {
  transform(int) {
    const ones = +int % 10;
    const tens = +int % 100 - ones;
    return int + ['th', 'st', 'nd', 'rd'][tens === 10 || ones > 3 ? 0 : ones];
  }
}
