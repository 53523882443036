/**
 * Created by Alex Poh. on 15/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  setLanguageForTranslation(language: string) {
    this.translateService.use(language);
  }
}
