import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../../shared/user.service';
import { takeUntil } from 'rxjs/operators';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { TimeDateService } from '../../../shared/services/time-date.service';
import { Subject } from 'rxjs';
interface Event {
  activityType: string,
  endTime: string,
  startTime: string,
  subject: string,
}

interface Day {
  dayOfWeek: string,
  date: string,
  selected: boolean,
  events: Event[],
  isToday?: boolean,
}

@Component({
  selector: 'curr-dashboard-calendar',
  templateUrl: './dashboard-calendar.component.html',
  styleUrls: ['./dashboard-calendar.component.scss'],
  animations: [fadeAnimation]
})
export class DashboardCalendarComponent implements OnInit, OnDestroy {
  currentWeekNumber: number = 0;
  days: Day[] = [];
  selectedDay: Day;
  unsubscribe: Subject<void> = new Subject<void>();
  currentMonth: string;
  currentYear: number;
  monthNames: string[] = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  currentDate: Date = new Date();

  constructor(
    private userService: UserService,
    public timeDateService: TimeDateService,
    ) { }

  ngOnInit(): void {
    this.getWeekInfo(this.currentWeekNumber);
  }

  public getPrevWeek(): void {
    if (this.currentWeekNumber === 0) {
      return;
    }
    this.getWeekInfo(--this.currentWeekNumber);
  }

  public getNextWeek(): void {
    this.getWeekInfo(++this.currentWeekNumber);
  }

  private getWeekInfo(weekNumber: number): void {
    this.userService.getUserCalendarInfo(weekNumber)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
      this.days = res.data.items.map((el: Day) => {
        if (new Date(el.date).getDate() < this.currentDate.getDate()) {
          return {...el, selected: false, events: el.events};
        }
        return {...el, selected: false};
      });
      this.currentMonth = this.monthNames[new Date(this.days[0].date).getMonth()];
      this.currentYear = new Date(this.days[0].date).getFullYear();
      this.findToday();
    })
  }

  private findToday(): void {
    const todayDay = this.currentDate.getDate();

    this.days = this.days.map((el: Day, index) => {
      if (new Date(el.date).getDate() === todayDay
        && new Date().getMonth() === new Date(el.date).getMonth()) {
        this.selectedDay = this.days[index];
        return ({ ...el, isToday: true, selected: true });
      }
      return el;
    })
  }

  public selectDay(index: number): void {
    this.days.forEach((el: Day, i) => {
      el.selected = i === index;
      if(el.selected) {
        this.selectedDay = el;
      }
    })
  }

  public hasDayEvent(events: Event[], event: string): boolean {
    return !!events.find(el => el.activityType === event)
  }

  public capitalize(str: string): string {
    return str[0].toUpperCase() + str.toLowerCase().slice(1);
  }

  public getDayTitle(): string {
    const todayDay = this.currentDate.getDate();
    if(new Date(this.selectedDay.date).getDate() === todayDay
      && new Date().getMonth() === new Date(this.selectedDay.date).getMonth()) {
      return 'Today';
    }
    if(new Date(this.selectedDay.date).getDate() - todayDay === 1
      && new Date().getMonth() === new Date(this.selectedDay.date).getMonth()) {
      return 'Tomorrow';
    }
    else {
      return `${this.capitalize(this.selectedDay.dayOfWeek)} ${new Date(this.selectedDay.date).getDate()}`;
    }
  }

  public getActivityType(type: string): string {
    return type.split('_').map(el => this.capitalize(el)).join(' ');
  }

  public getDayNumber(date: string): string {
    return new Date(date).getDate().toString();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
