import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question, QuestionType, ITag } from '../../../../student/test.model';
import { FormGroup } from '@angular/forms';
import { QuestionService } from '../question.service';

@Component({
  selector: 'curr-question-answer-variant',
  templateUrl: './question-answer-variant.component.html',
  styleUrls: ['./question-answer-variant.component.scss']
})
export class QuestionAnswerVariantComponent implements OnInit {
  @Input() question: Question;
  @Input() isQuestionOptionsTinyEditor: boolean;
  @Input() checkBoxCorrectAnswers: any[] = [];
  @Output() onSetCorrectAnswer = new EventEmitter<number>();
  @Output() onCheckBox = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<number>();
  questionType = QuestionType;
  @Input() form: FormGroup;

  questionCompetences = [
    { value: 'LOU', label: 'LOU' },
    { value: 'LOR', label: 'LOR' },
    { value: 'LOT', label: 'LOT' }
  ];

  suggestedTags: ITag[];
  isShowEditor: boolean = false;
  isEditQuestionWordingMode = true;

  public formula: string | null = null;

  constructor(private questionService: QuestionService) {
  }

  ngOnInit(): void {
    if (!this.question.answer_options_tags) {
      this.question.answer_options_tags = {};
    }
    this.question.hint = '';
  }

  updateOption(event, index: number): void {
    this.question.answer_options[index] = event;
  }

  updateOptionEditor(event, index: number): void {
    this.question.answer_options[index] = event.event.target.innerText;
  }

  showEditor(): void {
    this.isShowEditor = true;
  }

  track(value): string {
    return value;
  }
  update(val: any){
    // console.log(val);
  }

  setCorrectRadioAnswer(i: number) {
    this.onSetCorrectAnswer.emit(i);
  }

  setOption($event: string | number, i: number) {
    // this.onSetOption.emit({i, event: $event});
    // const copy = Array.from(this.question.answer_options);
    // copy.forEach((opt, h) => {
    //   if (h===i){
    //     // @ts-ignore
    //     this.question?.answer_options[i] = $event + '';
    //   } else {
    //     // @ts-ignore
    //     this.question?.answer_options[h] = copy[h] || '';
    //   }
    // });
    // @ts-ignore
    this.question?.answer_options[i] = $event + '';
    // this.cdr.detectChanges();
  }

  deleteOption(i: number) {
    this.onDelete.emit(i);
  }

  checkBoxClick($event: MouseEvent, i: number) {
    this.onCheckBox.emit({ i, event: $event });
  }

  onCompetenceSelect(competence: string, answerOption: number) {
    if (!this.question.answer_options_competence) {
      this.question.answer_options_competence = {};
    }
    this.question.answer_options_competence[answerOption] = competence;
  }

  editQuestion(variable: string): void {
    this[variable] = true;
  }

  onChipInputChange(searchStr: string) {
    this.questionService.getQuestionTags(searchStr).subscribe(res => {
      this.suggestedTags = res;
    });
  }

  onTagsChange(tags: ITag[], optionIndex: number) {
    this.question.answer_options_tags[optionIndex.toString()] = tags;
  }

  addValue(formula: any): void {
    if(!formula) { return; }
    this.question.answer_options_tags = formula;
  }
}
