/*
 * Created by Volodymyr Mak. on 4/29/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnChanges, Input, EventEmitter, Output } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';

/**
 * Example
 * 1. Import PaginationModule module.
 * 2. Create config object that will include configuration data for pagination.
 * 3. Bind 'paginate' on target list data and pass config obj to it.
 * 4. Add whereve you need <curr-pagination> component and pass config object
 *    into [config] property of <curr-pagination> component.
 *
 * const someDataList = [...]
 * const config = {
 *    id: string // Indicator for paginator instance
 *    currentPage: number // Active page to display
 *    itemsPerPage: number // Items per page to display
 * }
 *
 * onChangePagination(value) {}
 *
 *
 * <ul class="meal-list">
 *     <li *ngFor="let item of someDataList | paginate: config">
 *         {{ item }}
 *     </li>
 * </ul>
 *
 * Also it is possible to control pagination onChange event by using changePage event binding.
 * <curr-pagination [config]="config" (changePage)="onChangePagination($event)"></curr-pagination>
 */

// TODO: Why this component declared in multiple modules?
@Component({
  selector: 'curr-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() config: PaginationInstance;
  @Input() maxSize: string = '20';
  @Input() checkedPage?: Array<number> = [];
  @Input() navType: 'arrows' | 'buttons' = 'buttons';
  @Output() changePage: EventEmitter<number> = new EventEmitter();

  constructor() {}

  ngOnChanges() {
    setTimeout(() => { 
       if (localStorage.getItem !== null) {
      this.config.currentPage = JSON.parse(localStorage.getItem('currentPageHA'));
    }
    localStorage.removeItem('currentPageHA');
    })
  }

  pageChanged(activePage: number) {
    this.changePage.emit(activePage);
    this.config.currentPage = activePage;
    localStorage.setItem('currentPageHA', JSON.stringify(this.config.currentPage))
  }
}
