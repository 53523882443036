/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
