<!--Created by Maxim B. on 16/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="book-container" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
  <div class="side-list-container" fxFlex="30">
    <div class="color-picker-container" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
      <label class="mat-h2">STORY BOOK</label>
    </div>

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <button mat-button (click)="itemClicked(node)">
          {{ node.name }}
        </button>
      </mat-tree-node>
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
          {{ node.name }}
        </button>
      </mat-tree-node>
    </mat-tree>
  </div>

  <div class="main-content-container" fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="10px">
    <!--     color form -->
    <div class="color-picker-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <label for="primary-color">Primary:</label>
      <input type="color" id="primary-color" [(ngModel)]="primary" />
      <label for="secondary-color"> Accent:</label>
      <input type="color" id="secondary-color" [(ngModel)]="accent" />

      <curr-button variant="contained" color="primary" (click)="createNewScheme()" size="small">
        CREATE NEW COLOR SCHEME
      </curr-button>
    </div>
    <!-- end color form -->

    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Button'"
    >
      <curr-button
        *ngFor="let b of initData.buttonVariants"
        [variant]="b.variant"
        [size]="b.size"
        [icon]="b.icon"
        [color]="b.color"
        >BUTTON</curr-button
      >
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Input'"
    >
      <curr-input
        *ngFor="let b of initData.inputVariants"
        [(value)]="b.value"
        [placeholder]="b.placeholder"
        [userHint]="b.userHint"
        fxFlex
        [isBordered]="b.isBordered"
        [prefix]="b.prefix"
        [inputType]="b.inputType"
        [isGreyBackground]="b.isGreyBackground"
      ></curr-input>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Checkbox'"
    >
      <curr-checkbox
        *ngFor="let b of initData.checkboxVariants"
        [(value)]="b.value"
        [options]="b.options"
        [color]="b.color"
        [type]="b.type"
        [isMultipleSelect]="b.isMultipleSelect"
      ></curr-checkbox>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'TextArea'"
    >
      <curr-textarea
        *ngFor="let b of initData.textareaVariants"
        [(value)]="b.value"
        [label]="b.label"
        [rows]="b.rows"
      ></curr-textarea>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'TinyEditor'"
    >
      <curr-tiny-editor
        *ngFor="let b of initData.tinyEditorVariants"
        [(value)]="b.value"
        [options]="b.options"
      ></curr-tiny-editor>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'ToggleButton'"
    >
      <curr-toggle-button
        *ngFor="let b of initData.checkboxVariants"
        [(value)]="b.value"
        [options]="b.options"
        [color]="b.color"
      ></curr-toggle-button>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Dropdown'"
    >
      <curr-dropdown
        *ngFor="let b of initData.dropdownVariants"
        [placeholder]="b.label"
        [options]="b.options"
        [selectedValue]="b.selectedValue"
        [defaultValue]="b.defaultValue"
      ></curr-dropdown>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'UploadFiles'"
    >
      <curr-upload-files></curr-upload-files>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'RedLabel'"
    >
      <curr-red-label [text]="'You can put any warning text here'"></curr-red-label>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'DrawingBoard'"
    >
      <curr-drawing-board></curr-drawing-board>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Camera'"
    >
      <curr-camera></curr-camera>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'CourseCard'"
    >
      <curr-course-card [course]="initData.courseCard"></curr-course-card>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'CustomerPlate'"
    >
      <curr-customer-plate [customer]="initData.customerCard"></curr-customer-plate>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start start"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'UserCard'"
    >
      <curr-customer-plate [customer]="initData.userCard"></curr-customer-plate>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Header'"
    >
      <curr-header></curr-header>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Footer'"
    >
      <curr-footer></curr-footer>
    </div>
    <div
      class="content"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
      *ngIf="currentDisplayNode == 'Breadcrumb'"
    >
      <curr-breadcrumb [path]="path" position="start"></curr-breadcrumb>
    </div>
  </div>
</div>
