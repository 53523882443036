import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  constructor(private httpClient: ApplicationHttpClient
  ) {
  }

  getAdmins(limit = 10, offset = 0) {
    return this.httpClient.get(`/users/admins?limit=${limit}&offset=${offset}`);
  }

  getStudents(limit = 10, offset = 0) {
    return this.httpClient.get(`/users/students?limit=${limit}&offset=${offset}`);
  }

  getTeachers(limit = 10, offset = 0) {
    return this.httpClient.get(`/users/teachers?limit=${limit}&offset=${offset}`);
  }
}
