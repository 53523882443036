<!--
 Created by Yaroslav S. on 10/07/2024
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="managing-api-keys">
  <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>

  <div class="managing-api-keys-title">Managing API Keys</div>
  <div class="managing-api-keys-title-text">Do not share API Keys with others. Keep private and limit administrative access to this area.</div>

  <div class="managing-api-keys-content">
    <div class="content-item">
      <curr-open-ai-section [apiKeys]="apiKeys" (onSaveKey)="saveKeys($event)"></curr-open-ai-section>
    </div>
    <div class="content-item">
      <curr-mathpix-section [apiKeys]="apiKeys" (onSaveKey)="saveKeys($event)"></curr-mathpix-section>
    </div>
  </div>

  <curr-ai-subjects-table [tableInitData]="apiKeysSettings"></curr-ai-subjects-table>
</div>
