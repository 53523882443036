<!--
 Created by Dmytro Sav. on 5/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="container">
  <div fxLayout="row" class="m-b-40" fxLayoutAlign="space-between center">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>

    <div fxLayout="row" fxLayoutGap="10px">
      <curr-button  size="big"  (btnClick)="navigate('/self-assessments')" variant="contained">Self Assessment Tests</curr-button>
      <curr-button  size="big"  (btnClick)="navigate('/cumulative-tests')" variant="contained">Topic Tests</curr-button>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="24px" class="m-b-40 task-chart-container">
    <div fxFlex="2 1">
      <curr-dashboard-chart></curr-dashboard-chart>
    </div>
  </div>
  <div class="m-b-16 mat-h2">User Statistic</div>
  <mat-divider class="m-b-16"></mat-divider>
  <curr-user-statistic-table selectedTabIndex="1"></curr-user-statistic-table>
</div>
