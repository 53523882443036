<!--
 Created by Dmytro Sav. on 5/11/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<mat-tab-group mat-align-tabs="start" (selectedIndexChange)="changeTab($event)" [selectedIndex]="currSelectedTabIndex">
  <mat-tab *ngFor="let tabLabel of tabLabels; let i = index" [label]="tabLabel"></mat-tab>
</mat-tab-group>

<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="column" fxLayoutAlign="center" fxLayoutGap="20px" *ngIf="currSelectedTabIndex == 0">
    <ng-container *ngFor="let d of data">
      <div fxLayout="column" fxLayoutAlign="center">
        <div class="mat-h4">{{ d.name }}</div>

        <div fxLayout="column" fxLayoutAlign="center" *ngFor="let course of d.courses" class="m-b-16">
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="column" fxLayoutAlign="center start">
              <div>Course ID: {{ course.courseId }}</div>
              <div>Form {{ course.form }}</div>
              <div>Year: {{ course.year }}</div>
              <div>Ex. Avg. Grade Form 1: {{ course.avg }}</div>
            </div>
            <curr-concern-widget [concern]="12"></curr-concern-widget>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="currSelectedTabIndex == 1">
    NOTES
  </div>
</div>
