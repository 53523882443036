<!-- Created by Alex Poh. on 22/06/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<ng-container *ngIf="disabled">
  <curr-loader *ngIf="isLoading"></curr-loader>
  <div [currMath]="valueForPreview" *ngIf="valueForPreview && !isLoading"></div>
</ng-container>

<ng-container *ngIf="!disabled">
  <curr-tiny-editor
    [options]="options"
    [(value)]="value"
    [formGroup]="formGroup"
    [controlName]="controlName"
    [topic]="topic"
    [isHigherBtnVisible]="true"
    (valueChange)="valueChange.emit($event)"
    (onFocusOut)="onFocusOut.emit($event)"
  ></curr-tiny-editor>
</ng-container>
