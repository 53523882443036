import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

interface Buttons {
  label: string;
  callback: () => void;
  link: string;
  color: string;
  variant: string;
  isDisabled?: boolean;
  style?: {};
}

interface Text {
  label: string;
  color?: string;
  isUnderline: boolean;
  value: string;
  isBold?: boolean;
  isAttention?: boolean;
  attention?: string;
  icon: string;
}

type TestCard = 'future' | 'not-active' | 'complete';

@Component({
  selector: 'curr-cumulative-test-block',
  templateUrl: './cumulative-test-block.component.html',
  styleUrls: ['./cumulative-test-block.component.scss']
})
export class CumulativeTestBlockComponent {
  @Input() tests: {
    type: TestCard;
    status?: string;
    icon: string;
    isIconOpacity: boolean;
    buttons: Array<Buttons>;
    name: string;
    hint: string;
    isToday?: boolean;
    bottomTitle: Text[];
  }[] = [];

  constructor(private router: Router) {
  }

  goToLearningResources(test: any) {
    this.router.navigate(['/learning-resources', (test.id || 32)]);
  }

  btnClick($event: MouseEvent, button: Buttons) {
    $event.stopPropagation();
    if (button.callback) {
      // @ts-ignore
      button.callback(button);
    }

  }
}
