<curr-modal-popup [@fade] class="choose-year-popup">
  <div class="choose-year-container">
    <div class="choose-year-title mat-h1 m-t-24">
      Please choose your level of study
    </div>

    <div
      *ngIf="years$ | async as years; else loader"
      class="select-year-form m-t-24 m-b-24">
<!--      <div-->
<!--        (click)="selectYear('AS')"-->
<!--        [ngClass]="{'background-selected' : selectedYear === 'AS' }"-->
<!--        class="year-box m-r-24">-->
<!--        <div class="year-title m-t-16">AS</div>-->
<!--        <span class="year-level mat-body-2">Level</span>-->
<!--        <div class="year-total m-t-16">Year 12</div>-->
<!--      </div>-->
<!--      <div-->
<!--        (click)="selectYear('A2')"-->
<!--        [ngClass]="{'background-selected' : selectedYear === 'A2' }"-->
<!--        class="year-box">-->
<!--        <div class="year-title m-t-16">A2</div>-->
<!--        <span class="year-level mat-body-2">Level</span>-->
<!--        <div class="year-total m-t-16">Year 13</div>-->
<!--      </div>-->
      <div
        *ngFor="let year of years"
        (click)="selectYear(year.id)"
        [ngClass]="{'background-selected' : selectedYear === year.id }"
        class="year-box {{'order-' + year.studyYear}}"
        [class.headStart]="year.studyYearName === 'IGCSE HeadStart'">
        <ng-container *ngIf="year.studyYearName === 'IGCSE'">
          <div class="year-title m-t-16">{{ year.studyYear === 1 ? '1st Year' : '2nd Year' }}</div>
          <span class="year-level mat-body-2">IGCSE</span>
        </ng-container>
 
        <ng-container *ngIf="year.studyYearName !== 'IGCSE'">
          <div class="year-title m-t-16" >{{ year.studyYearName === 'IGCSE HeadStart' ? ('Pre-IGCSE') : year.studyYearName }}</div>
          <span class="year-level mat-body-2">{{ year.studyYearName === 'IGCSE HeadStart' ? 'HeadStart' : 'A-Level' }}</span>
        </ng-container>

        <!-- <div class="year-total m-t-16">Year {{ year.studyYear }}</div> -->
      </div>
    </div>
    <curr-button
      size="big"
      (btnClick)="confirmYear()"
      [isDisabled]="!selectedYear"
      class="confirm-btn"
    >
      Confirm</curr-button>
  </div>
</curr-modal-popup>

<ng-template #loader>
  <curr-loader></curr-loader>
</ng-template>
