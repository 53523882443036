/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITask } from '../task-list.interface';
import * as moment from 'moment/moment';

@Component({
  selector: 'curr-task-popup',
  templateUrl: './task-popup.component.html',
  styleUrls: ['./task-popup.component.scss']
})
export class TaskPopupComponent implements OnInit {

  oldDeadlineTime: any;
  oldDeadlineDate: any;
  deadlineTime = '';
  deadlineDate = '';
  task: ITask;

  constructor(
    public dialogRef: MatDialogRef<TaskPopupComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  form: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data.name, [Validators.required, Validators.maxLength(100)]],
      deadlineDate: ['', [Validators.required]],
      deadlineTime: ['', [Validators.required]],
    });

    if (this.data.dayTask) {
      this.form.controls.name.patchValue(this.data.dayTask.name);
      this.task = this.data.dayTask;

      if (this.data.dayTask.deadline) {
        this.deadlineTime = this.oldDeadlineTime = moment(this.data.dayTask.deadline.slice(0, -2)).format('HH:mm');
        this.form.controls.deadlineTime.patchValue(this.oldDeadlineTime);

        this.deadlineDate = this.oldDeadlineDate = moment(this.data.dayTask.deadline.slice(0, -2)).format('YYYY-MM-DD');
        this.form.controls.deadlineDate.patchValue(this.oldDeadlineDate);
      }
    } else {
      this.task = {
        name: '',
        deadline: '',
        is_completed: false
      };
    }
  }

  setStartTime(time: any) {
    this.oldDeadlineTime = time;
    this.deadlineTime = time;
    this.form.controls.deadlineDate.patchValue(time);
  }

  setStartDate(date: any) {
    if (date && date.value) {
      this.oldDeadlineDate = date.value;
      this.deadlineDate = date ? moment(date.value).format('YYYY-MM-DD') : '';
      this.form.controls.deadlineTime.patchValue(date.value);
    }
  }

  save() {
    this.task.name = this.form.get('name').value;
    this.task.deadline = moment(`${this.deadlineDate} ${this.deadlineTime}`).format('YYYY-MM-DD HH:mm:ss') + '.000';
    this.dialogRef.close(this.task);
  }
}
