<!-- Created by Maxim B. on 06/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="plate-container" fxLayout="column" fxLayoutAlign="center center">
  <div class="logo-container">
    <img src="assets/images/school-logo2.png" alt="School Logo">
  </div>
  <span [title]="customer?.name" class="school-name">{{customer?.name}}</span>
  <span [title]="customer?.location" class="school-location">{{customer?.location}}</span>
  <div class="members-container">
    <p>Teachers: {{customer?.teachersQuantity}}</p>
    <span>|</span>
    <p>Students: {{customer?.studentsQuantity}}</p>
  </div>
  <div class="performance" [ngClass]="[customer?.performance < 0 ? 'down': 'up']">
    <p>Performance:
      <span>{{customer?.performance < 0 ? '' : '+'}}</span>
      <span>{{customer?.performance | percent}}</span>
      <mat-icon>{{customer?.performance < 0 ? 'trending_down' : 'trending_up'}}</mat-icon>
    </p>
  </div>
  <span class="live-date">live date: {{customer?.liveDate | date : 'dd.MM.yyyy'}}</span>

  <div *ngIf="customer?.status" class="status-container" [ngClass]="[customer?.status]">
      <p class="dot"></p> <p>{{customer?.status}}</p>
  </div>
</div>
