import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkPreviewService {
  private api = environment.apiLinkPreviewUrl;
  constructor(public http: HttpClient) {
  }

  getLinkPreview(link: string) {
    return this.http.get(this.api + link);
  }
}
