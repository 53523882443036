/**
 * Created by Maxim B. on 21/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
export class StatisticTable {

  constructor(public name = 'TABLE') {
    this.firstColumn = { fieldName: 'Name', fieldFilter: '' };
    this.fields = [];
  }

  firstColumn: TableField;
  fields: TableField[];

}

interface TableField {
  fieldName: string;
  fieldFilter: string;
}
