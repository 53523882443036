/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ImageViewPopupDialogComponent } from '../../../shared/components/media-item/image-view-popup-dialog/image-view-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReviewQuestion, StudentReviewInfo } from '../questions-page.interface';
import { QuestionType } from '../../../student/test.model';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { Subject } from 'rxjs';
import { StudentAnswer, Reply } from './student-answer.interface';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'curr-student-answer',
  templateUrl: './student-answer.component.html',
  styleUrls: ['./student-answer.component.scss']
})
export class StudentAnswerComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  cou;
  questionType = QuestionType;

  @Input() studentData: any;
  @Input() answerData: {
    question: ReviewQuestion;
    answers: string[] | Array<{}>;
    student: StudentReviewInfo;
    result: string;
    reviewState: string;
    resultMark?: number;
    mark?: number;
    correctAnswer: number[];
    cou?: {
      label: string;
      studentAnswer: string;
      date: Date;
    };
    reply?: Reply;
    key: string;
  };
  studentCumTestQuestionId;

  btnStyle = { padding: 0 };
  fileInputBtnStyle = { padding: '0 12px', textAlign: 'start' };

  blob: { blobUrl?: string; blobFile?: any } = {};
  imagePreviewFile;
  previewFile;
  fileExtension;
  question: ReviewQuestion;
  studentAnswer: StudentAnswer;
  qt = QuestionType;
  audioFile;
  image;
  imageResultAnswer;
  isImage: boolean = false;
  isReplyImage: boolean = false;
  isDescriptionImage: boolean = false;
  isResultImage: boolean = false;
  reviewState: string;
  isFileLoading = false;

  constructor(public dialog: MatDialog, private fileUploadService: FileUploadService) {}

  ngOnInit() {
    if (this.answerData) {
      this.studentCumTestQuestionId = this.answerData.question.answers[this.studentData.key].id;
      this.question = this.answerData.question;
      this.studentAnswer = this.question.answers[this.studentData.key];
      this.reviewState = this.studentAnswer.reviewState;
    }
    if (this.studentAnswer?.studentAnswerText?.includes('img') && this.question?.answerType === this.qt.OPEN_ENDED) {
      this.isResultImage = true;
      this.getResultAnswerImg(this.studentAnswer.studentAnswerText);
    } else if (this.studentAnswer?.studentAnswerText?.includes('img') && this.question?.answerType === this.qt.SINGLE_ANSWER) {
      this.isImage = true;
      this.getAnswerImg(this.studentAnswer.studentAnswerText);
    }

    if (this.studentAnswer?.answerSolution?.includes('img')) {
      this.isDescriptionImage = true;
      this.getAnswerImg(this.studentAnswer.answerSolution);
    }

    if (this.studentAnswer?.textAnswer?.includes('img')) {
      this.isReplyImage = true;
      this.getAnswerImg(this.studentAnswer.textAnswer);
    }
    // @ts-ignore
    if (this.answerData?.answers[this.studentAnswer?.studentCorrectAnswerOptions]?.includes('img')) {
      this.isImage = true;
      // @ts-ignore
      this.getAnswerImg(this.answerData.answers[this.studentAnswer.studentCorrectAnswerOptions]);
    }
    if (this.studentAnswer.audioAnswer) {
      this.fileUploadService.download(this.studentAnswer.audioAnswer, 'AUDIO').subscribe((audio: any) => {
        this.audioFile = 'data:audio/ogg;base64,' + audio.data.bytes;
      });
    }
    this.getFileIfExists();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAnswerImg(img) {
      if (img.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(img);
        this.uploadAnswerOption(imageKeyValue, img);
      }
    return this.image
  }

  private async uploadAnswerOption(imageKey: any, img: any) {
    let previewAnswerOption = img;
    // tslint:disable-next-line:forin
    for (const key in imageKey) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      imageKey[key] = 'data:image/png;base64,' + bytes;
      previewAnswerOption = previewAnswerOption.replace(key, imageKey[key]);
    }
    return this.image = previewAnswerOption;
  }

  getResultAnswerImg(img) {
      if (img.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(img);
        this.uploadResultAnswerOption(imageKeyValue, img);
      }
    return this.imageResultAnswer
  }

  private async uploadResultAnswerOption(imageKey: any, img: any) {
    let previewAnswerOption = img;
    // tslint:disable-next-line:forin
    for (const key in imageKey) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      imageKey[key] = 'data:image/png;base64,' + bytes;
      previewAnswerOption = previewAnswerOption.replace(key, imageKey[key]);
    }
    return this.imageResultAnswer = previewAnswerOption;
  }

  openImagePreviewDialog(url: string) {
    this.dialog.open(ImageViewPopupDialogComponent, {
      data: { url }
    });
  }

  onErr(err) {
  }

  onComplete() {
    this.isFileLoading = false;
  }

  isStudentAnswerCorrect(i: number) {
    const correctAnswers = this.answerData.correctAnswer || [];
    return correctAnswers.includes(i);
  }

  isFileTypeOfImage() {
    const name = this.studentAnswer?.studentAnswerFile?.toString()?.toLowerCase();
    return name?.includes('.jpg') || name?.includes('.png') || name?.includes('.jpeg');
  }

  private getFileIfExists() {
    if (this.studentAnswer.studentAnswerFile && this.isFileTypeOfImage()) {
      this.isFileLoading = true;
      this.fileUploadService.download(this.studentAnswer.studentAnswerFile, 'IMAGE').pipe(takeUntil(this.destroy$)).subscribe(
        (audio: any) => {
          this.isFileLoading = false;
          this.imagePreviewFile = 'data:image/png;base64,' + audio.data.bytes;
        },
        this.onErr,
        this.onComplete
      );
    }
    if (this.studentAnswer.studentAnswerFile && !this.isFileTypeOfImage()) {
      // TODO: remove?
      // const fileExtension = this.studentAnswer.studentAnswerFile.toString().split('.')[1];
      this.isFileLoading = true;
      this.fileUploadService.download(this.studentAnswer.studentAnswerFile, 'DOC').pipe(takeUntil(this.destroy$)).subscribe(
        (audio: any) => {
          this.isFileLoading = false;
          this.previewFile = `data:application/octet-stream;base64,` + audio.data.bytes;
        },
        this.onErr,
        this.onComplete
      );
    }
  }
}
