/**
 * Created by Max B. on 27/07/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StudentProfileService } from './student-profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataResolver implements Resolve<any>{
  constructor(private studentProfileService: StudentProfileService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const id = route.paramMap.get('studentId');
    return this.studentProfileService.getProfileDataByUserId(+id);
  }

}
