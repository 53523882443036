/**
 * Created by Maxim B. on 10/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-input-disabled',
  templateUrl: './input-disabled.component.html',
  styleUrls: ['./input-disabled.component.scss']
})
export class InputDisabledComponent implements OnInit {
  @Input() placeholder = '';
  @Input() disabled = true;
  @Input() value: string | number;
  @Input() userHint = '';
  @Input() controlName: string;
  @Input() errorMessage = '';
  @Input() prefix = '';
  @Input() inputType = 'string';
  @Input() numberInputWidth = '100';
  @Input() isBordered = true;
  @Input() isCentered = false;
  @Input() isGreyBackground = false;
  @Input() formGroup: FormGroup;
  @Output() valueChange = new EventEmitter<string | number>();

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.value)
      });
      this.controlName = 'item';
    }
  }

  updateValue(val) {
    this.valueChange.emit(val);
    this.formGroup.controls[this.controlName].patchValue(val);
  }
}
