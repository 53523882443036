<!-- Created by Maxim B. on 08/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="course-info-container">
  <div class="head-container" [class]="course?.title?.aligned" [ngClass]="{ 'p-b-16': course.type === 'multiple' }">
    <span
      class="m-b-8 ls head-title"
      *ngIf="course?.title?.primary.value"
    >
      {{ course?.title?.primary.value }}
    </span>

    <span class="m-b-4 mat-h4 secondary" *ngIf="course?.title?.secondary?.value">
      {{ course?.title?.secondary?.value }}
    </span>

    <span
      class="tertiary"
      *ngIf="course?.title?.tertiary"
      [ngClass]="{ 'mat-body-2': course.type === 'single', 'overline-without-upper-case': course.type === 'multiple' }"
    >
      <span>{{ course?.title?.tertiary?.label }}</span>
      <span class="link" [class.isUnderlined]="course?.title?.tertiary?.isUnderLine">{{
        course?.title?.tertiary?.value
      }}</span>
    </span>

    <span class="m-t-24" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="center" *ngIf="course?.title?.hint?.icon">
      <mat-icon
        [class]="course?.title?.hint?.icon"
        [svgIcon]="course?.title?.hint?.icon"
        [color]="course?.title?.hint?.color"
      ></mat-icon>
      <span class="mat-body-2">{{ course?.title?.hint?.label }}</span>
    </span>

    <div
      class="title-icons cursor-pointer"
      fxLayout="row"
      fxLayoutGap="13px"
      *ngIf="false && course?.title?.topRightIcons"
    >
      <span *ngFor="let icon of course?.title?.topRightIcons">
        <mat-icon
          (click)="onIconClick(icon)"
          [ngClass]="[icon?.icon, icon.viewToSwitch === view ? 'active' : 'not-active']"
          [svgIcon]="icon?.icon"
        >
        </mat-icon>
      </span>
    </div>
  </div>

  <div class="info-container">
    <ng-container *ngIf="view === View.StandardView">
      <!-- BLOCKS -->
      <ng-container *ngFor="let block of course.blocks; let first = first; let last = last">
        <div
          fxLayoutGap="4px"
          fxLayout="column"
          fxLayoutAlign="start start"
          [class.p-t-0]="first"
          [class.p-t-16]="!first"
          [class.last]="last"
          class="p-b-16 has-bottom-border"
        >
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="block-text">
            <span class="">{{ block.title }}</span>
            <mat-icon
              class="cursor-pointer"
              *ngIf="block.icon"
              [matTooltip]="infoIconText$ | async"
              matTooltipPosition="above"
              [class.info-icon]="block.icon === 'info'"
              [svgIcon]="block.icon"
            >
            </mat-icon>
            <span class="block-text-mark">{{ block.bottomTitle.label }}</span>
          </div>
          <curr-progress-bar
            *ngIf="block?.showProgressBar"
            class="curr-progress-bar"
            [class.first]="first"
            [value]="block?.progressBar.value"
            borderRadius="4px"
            width="100%"
            height="4px"
          >
          </curr-progress-bar>

          <span
            fxLayout="row"
            fxLayoutGap="8px"
            fxLayoutAlign="center center"
            class="cursor-pointer mat-body-2"
            [class.text-underline]="block.hint.isUnderLine"
            *ngIf="block.hint.label"
          >
            <mat-icon [class]="block?.hint?.icon" [color]="block?.hint?.color" [svgIcon]="block?.hint?.icon"></mat-icon>
            <span [routerLink]="block?.hint?.link?.rout" [fragment]="block?.hint?.link?.fragment">{{
              block?.hint?.label
            }}</span>
            <mat-icon class="postIcon" [color]="block?.hint?.color" [svgIcon]="block?.hint?.postIcon"></mat-icon>
          </span>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="view === View.GraphView">
      <div class="graph-container">
        <div class="mat-h1 text-centered">
          Knowledge level
        </div>
        <curr-school-performance-histogram *ngIf="course.chartData" [chartData$]="course.chartData" [width]="430">
        </curr-school-performance-histogram>
      </div>
    </ng-container>

    <!-- BUTTONS -->
    <div class="full-width-btn">
      <div
        fxLayout="column"
        class="full-width btn-item"
        *ngFor="let button of course.buttons; first as firstBtn; last as lastBtn"
      >
        <curr-button
          ngClass.lt-sm="smaller-font-size"
          [color]="button.color"
          [variant]="button.variant"
          (btnClick)="
          button.callback ? button.callback() : router.navigateByUrl(button.link);
          setCourseIdToLocalStorage(button.courseId)
        "
          [isDisabled]="button.isDisabled"
          size="big"
          class="m-b-8 full-width curr-button"
          [class.lastBtn]="lastBtn"
        >
          {{ button.label }}
        </curr-button>
      </div>
    </div>

<!--    <div fxLayout="column" class="full-width">-->
<!--      <curr-button-->
<!--        ngClass.lt-sm="smaller-font-size"-->
<!--        [icon]="tutorSession"-->
<!--        color="accent"-->
<!--        variant="contained"-->
<!--        size="big"-->
<!--        class="m-b-8 full-width curr-button"-->
<!--        ngClass="m-t-24"-->
<!--        (btnClick)="joinTutorSession()"-->
<!--      >-->
<!--        Join tutor session-->
<!--      </curr-button>-->
<!--    </div>-->
  </div>
</div>
