/**
 * Created by Alex Poh. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'curr-fake-gantt-chart',
  templateUrl: './fake-gantt-chart.component.html',
  styleUrls: ['./fake-gantt-chart.component.scss']
})
export class FakeGanttChartComponent {

}
