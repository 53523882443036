<!-- Created by Alex Poh. on 09/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="full-width m-t-32 m-b-32 create-question-container default-card-border-view" fxLayout="column"
  fxLayoutAlign="start start">
  <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon svgIcon="icon-success-checkmark" class="m-r-8  green-checkmark" *ngIf="enableToggleBtn()"></mat-icon>
    <mat-icon svgIcon="warning-icon" class="m-r-8" *ngIf="!enableToggleBtn()"></mat-icon>
    <span class="create-test">{{ setCreateTestText() }}</span>
  </div>
</div>

<mat-tab-group mat-align-tabs="start" [dynamicHeight]="true" [selectedIndex]="selectedIndex"
  (selectedIndexChange)="tabSelectedIndexChange($event)" animationDuration="0ms"
  class="nav-panel-questions m-t-40 custom-ink-size">
  <mat-tab [label]="'SELF ASSESSMENT QUESTIONS(' + saQuestions.length + ')'"></mat-tab>
  <mat-tab [label]="'TOPIC TEST QUESTIONS(' + cumulativeQuestions.length + ')'"></mat-tab>
</mat-tab-group>

<div class="full-width m-t-32 m-b-32" fxLayout="row" fxLayoutAlign="space-between center">
  <div class="overline last-modified">LAST MODIFIED: {{ learningObjective?.created_at | date: 'dd MMM yyyy' }}</div>
  <div fxLayout="row" fxLayoutAlign="end center">
    <curr-question-type-button-dropdown (typeSelected)="addQuestion('manually', $event)"
      [isCtQuestion]="selectedIndex === 1"></curr-question-type-button-dropdown>
    <curr-button variant="outlined" class="m-l-8" (click)="addQuestion('db', '')">ADD FROM DATABASE</curr-button>
  </div>
</div>


<div class="create-question-container default-card-border-view m-b-8" *ngIf="isAddNewQuestionFormVisible">
  <curr-edit-question-block [isCTQuestion]="selectedIndex === 1" [inSowPage]="true" [question]="newQuestion"
    [topics]="learningObjectives" [cou]="cou" #editQuestionBlock></curr-edit-question-block>
  <div fxLayoutAlign="start start" fxLayout="row" class="m-t-16">
    <curr-button variant="contained" (btnClick)="saveQuestion()"
      *ngIf="!newQuestion.id" class="m-r-8">SAVE</curr-button>
    <curr-button variant="text" (click)="isAddNewQuestionFormVisible = false">CANCEL</curr-button>
  </div>
</div>


<ng-container *ngIf="selectedIndex === 0">
  <curr-question-card
    [inEditQuestionMode]="i === 0 && question.answer_type == questionType.MULTIPART && instantlyEditMultipart"
    [cou]="cou" [topics]="learningObjectives" (onQuestionDeleted)="removeQuestion($event, 'sa')" questionTestType="sa"
    [question]="question" [questionNumber]="i + 1" *ngFor="let question of saQuestions; let i = index">
  </curr-question-card>

</ng-container>
<ng-container *ngIf="selectedIndex === 1">
  <curr-question-card
    [inEditQuestionMode]="i === 0 && question.answer_type == questionType.MULTIPART && instantlyEditMultipart"
    [cou]="cou" [topics]="learningObjectives" (onQuestionDeleted)="removeQuestion($event, 'ct')" questionTestType="ct"
    [question]="question" [questionNumber]="i + 1" *ngFor="let question of cumulativeQuestions; let i = index">
  </curr-question-card>
</ng-container>
