/**
 * Created by Daniil Vat. on 29/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
export const COLORS = [
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3',
  '#4DA3B1',
  '#65BAAA',
  '#83CCA5',
  '#A2D9A3'
];
