/**
 * Created by Alex Poh. on 12/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './shared/shared.module';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { FakeApiService } from './shared/api/fake-api.service';
import { ApiService } from './shared/api/api.service';
import { DemoModule } from './demo/demo.module';
import { SchoolAdminModule } from './school-admin/school-admin.module';
import { ApplicationHttpClient, applicationHttpClientCreator } from './shared/api/application-http-client';
import { TokenInterceptor } from './shared/interceptors/token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { AuthSharedModule } from './auth/shared/auth-shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxStripeModule } from 'ngx-stripe';

const httpLoaderFactory = http => new TranslateHttpLoader(http);

export const translationConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: httpLoaderFactory,
    deps: [HttpClient]
  },
  defaultLanguage: 'en'
};

const newStripeKey = 'pk_live_51KHq5xB70LQWGym4FuJuY7l7MeL0QLL60IIT3GHNt9LdKm7u364XVjd82AAG659WqA7lW1iyEo0RAeTKlyXiBE42000hBndVEw';

function getStripeKey(): string {
  return newStripeKey;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    SchoolAdminModule,
    FlexLayoutModule,
    DemoModule,
    AuthSharedModule,
    NgxStripeModule.forRoot(getStripeKey()),
    ToastrModule.forRoot({
      timeOut: 3000,
      preventDuplicates: true
    }),
    NgxLocalStorageModule.forRoot(),
    TranslateModule.forRoot(translationConfig)
  ],
  providers: [
    { provide: ApiService, useClass: FakeApiService },
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
