<!--
  ~ Created by Yaroslav S. on 16/04/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div fxLayout="row" fxLayoutAlign="end center">
  <curr-button
    variant="outlined"
    (menuClosed)="isAddNewOpen = false"
    [matMenuTriggerFor]="addNewMenu"
    (click)="isAddNewOpen = true"
    fxLayout="row"
    fxLayoutAlign="center center"
    [isHighlighted]="false">
    Create a new question
    <mat-icon svgIcon="down" class="btn-icon-arrow" *ngIf="!isAddNewOpen"></mat-icon>
    <mat-icon svgIcon="up" class="btn-icon-arrow" *ngIf="isAddNewOpen"></mat-icon>
  </curr-button>
</div>

<mat-menu #addNewMenu="matMenu" class="btn-list-menu" xPosition="before">
  <button mat-menu-item (click)="onClickAI.emit()">AI Assistant</button>
  <button mat-menu-item (click)="onClickManual.emit()">Create Manually</button>
</mat-menu>
