/**
 * Created by Maxim B. on 22/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { CommonFields } from './common-fields';

export class Teacher extends CommonFields {
  meetings = '';
  avgStudentGrade = '';
  avgStudentTargetGrade = '';
  numberOfYearsEmployed = '';
  usingTheSchoolsMaterials = '';
  gifted='';
  onTrack='';
  underPerforming='';
  seriesConcerns='';
}
