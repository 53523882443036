/**
 * Created by Alex Poh. on 12/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import { filter, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { NavigationPart, SunburstApiResponse } from './sunburst-api-response';

@Component({
  selector: 'curr-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss']
})
export class SunburstComponent implements AfterViewInit, OnDestroy {

  @ViewChild('sunburstContainer') sunburstContainer: ElementRef;
  @Input()
  isShadowed: Observable<boolean>;

  @Input()
  sunburstApiResponse: SunburstApiResponse;
  svg;
  data;
  root;
  path;
  g;
  label;
  parent;
  options = { width: 750, height: 750 };

  radius = this.options.width / 6;
  symbolLimitToCut = 23;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) {
  }

  ngAfterViewInit(): void {
    this.data = this.sunburstApiResponse;
    this.root = this.partition(this.data);
    this.root.each(d => (d.current = d));
    this.initSunburst();
    this.isShadowed
      .pipe(
        filter(res => res),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.addShadow();
      });
    this.isShadowed
      .pipe(
        filter(res => !res),
        takeUntil(this.destroy$)
      )
      .subscribe(res => {
        this.removeShadow();
      });
  }

  initSunburst() {
    this.svg = d3.select(this.sunburstContainer.nativeElement);
    this.svg.attr('viewBox', [0, 0, this.options.width, this.options.width]).style('font', '10px sans-serif');
    this.g = this.svg.append('g').attr('transform', `translate(${ this.options.width / 2 },${ this.options.width / 2 })`);
    const color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, this.data.children.length + 1));
    this.path = this.g
      .append('g')
      .selectAll('path')
      .data(this.root.descendants().slice(1))
      .join('path')
      .attr('fill', d => {
        const isContentMissing = !d.data.hasContent;
        while (d.depth > 1) {
          d = d.parent;
        }
        if (isContentMissing) {
          return 'grey';
        } else {
          return color(d.data.name);
        }
      })
      .attr('fill-opacity', d => (this.arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0))
      .attr('d', d => {
        return this.arc(d.current);
      });
    this.path.style('cursor', 'pointer').on('click', this.clicked);
    this.path.append('title').text(
      d =>
        `${ d
          .ancestors()
          .map(j => j.data.name)
          .reverse()
          .join('/') }\n${ this.format(d.value) }`
    );
    this.label = this.g
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(this.root.descendants().slice(1))
      .join('text')
      .attr('dy', '0.35em')
      .attr('fill-opacity', d => +this.labelVisible(d.current))
      .attr('transform', d => this.labelTransform(d.current))
      .text(d => this.cutText(d.data.name));
    this.parent = this.g
      .append('circle')
      .datum(this.root)
      .attr('r', this.radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('click', this.clicked);
  }

  clicked = p => {
    if (p.parent) {
      this.parent.attr('class', 'cursor-pointer');
    } else {
      this.parent.attr('class', null);
    }
    this.parent.datum(p.parent || this.root);
    if (p.children) {
      this.initLevel(p);
    } else {
      this.navigateUrlForNode(p);
    }
  };

  navigateUrlForNode(node: any) {
    let nodeToProcess = node;
    const urlParts = [];
    const queryParts = [];
    while (nodeToProcess.parent) {
      const navigationPart: NavigationPart = nodeToProcess.data.navigationPart;
      if (navigationPart?.urlPart) {
        urlParts.push(navigationPart.urlPart);
      }
      if (navigationPart?.query) {
        queryParts.push(navigationPart.query);
      }
      nodeToProcess = nodeToProcess.parent;
    }
    if (nodeToProcess.data.navigationPart?.urlPart) {
      urlParts.push(nodeToProcess.data.navigationPart.urlPart);
    }
    if (nodeToProcess.data.navigationPart?.query) {
      queryParts.push(nodeToProcess.data.navigationPart.query);
    }
    const url = [...urlParts].reverse().join('/');
    const query = queryParts.reduce((acc, val) => {
      return { ...acc, ...val };
    }, []);
    this.router.navigate([url], {
      queryParams: query
    });
  }

  reset() {
    this.parent.datum(this.root);
    this.initLevel(this.root);
  }

  initLevel(p) {
    this.root.each(
      d =>
        (d.target = {
          x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth)
        })
    );
    const t = this.g.transition().duration(750);
    const context = this;
    this.path
      .transition(t)
      .tween('data', d => {
        const i = d3.interpolate(d.current, d.target);
        return j => (d.current = i(j));
      })
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || context.arcVisible(d.target);
      })
      .attr('fill-opacity', d => (this.arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0))
      .attrTween('d', d => () => this.arc(d.current));

    this.label
      .filter(function (d) {
        return +this.getAttribute('fill-opacity') || context.labelVisible(d.target);
      })
      .transition(t)
      .attr('fill-opacity', d => +this.labelVisible(d.target))
      .attrTween('transform', d => () => this.labelTransform(d.current));
  }

  arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  }

  labelVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  }

  labelTransform(d) {
    const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
    const y = ((d.y0 + d.y1) / 2) * this.radius;
    return `rotate(${ x - 90 }) translate(${ y },0) rotate(${ x < 180 ? 0 : 180 })`;
  }

  partition = data => {
    const root = d3
      .hierarchy(data)
      .sum(d => {
        return 1;
      })
      .sort((a, b) => {
        return 0;
      });
    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  };

  color = data => {
    return d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));
  };

  format = d3.format(',d');

  arc = d3
    .arc()
    .startAngle(d => {
      return d.x0;
    })
    .endAngle(d => d.x1)
    .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(this.radius * 1.5)
    .innerRadius(d => d.y0 * this.radius)
    .outerRadius(d => Math.max(d.y0 * this.radius, d.y1 * this.radius - 1));

  cutText(text: string): string {
    if (text.length < this.symbolLimitToCut) {
      return text;
    } else {
      return text.substring(0, this.symbolLimitToCut - 1) + '.';
    }
  }

  addShadow() {
    d3.selectAll('svg').interrupt();
    if (this.svg) {
      this.svg
        .transition()
        .duration(300)
        .style('box-shadow', '0px 0px 60px 20px');
    }
  }

  removeShadow() {
    d3.selectAll('svg').interrupt();
    if (this.svg) {
      this.svg.style('box-shadow', '0 0 0 0');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
