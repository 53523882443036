<div class="pagination-wrapper button" fxLayout="row" fxLayoutAlign="center center">
  <div class="nav-item" fxLayoutAlign="start center">
    <curr-button
      *ngIf="selectedIndex !== 0"
      (btnClick)="prevQuestion()"
      class="curr-button"
      size="big"
      [isDisabled]="isDisabledPrev"
      variant="outlined">
      <mat-icon svgIcon="arrow-left"></mat-icon>
      PREV
    </curr-button>
  </div>
  <div #pagination class="pagination-items" fxLayout="row">
    <div
      class="pagination-list"
      fxLayoutAlign="center center"
      *ngFor="let item of items; let index = index"
      [class.current]="selectedIndex === index"
      [class.checked]="selectedIndex > index"
      [class.not-checked]="false"
      (click)="selectQuestion(index)"
    >
      <a class="cursor-pointer step" fxLayoutAlign="center center">
        <span>{{ item + 1 }}</span>
      </a>
    </div>
  </div>

  <div class="nav-item" fxLayoutAlign="end center">
    <curr-button
      *ngIf="selectedIndex !== 2"
      (btnClick)="nextQuestion()"
      class="curr-button"
      size="big"
      [isDisabled]="isDisabledNext"
      variant="outlined">
      NEXT
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </curr-button>
  </div>
</div>
