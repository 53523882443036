/**
 * Created by Maxim Bon. on 15/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Question, QuestionType, QuestionsLabels } from '../../../../student/test.model';
import { COUResponse } from 'src/app/shared-pages/subject-details/api-subject-details';
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../../utils/custom-validators';
import { NewQuestionService } from '../new-question.service';
import { first } from 'rxjs';

interface SubjectTestSelectTopic {
  id: number;
  title: string;
  alreadyUsed: boolean;
  selected?: boolean;
}

@Component({
  selector: 'curr-question-section',
  templateUrl: './question-section.component.html',
  styleUrls: ['./question-section.component.scss'],
})
export class QuestionSectionComponent implements OnInit {
  @Input()
  questionEdit: Question;

  @Input()
  cou: COUResponse[] = [];

  @Input()
  topics: SubjectTestSelectTopic[] = [];

  @Input()
  isCTQuestion = false;

  @Input()
  itemIndexWithParents: number[];

  @Input()
  stepNumber: number;

  questionType = QuestionType;
  ddLevels = [];
  mediaFormGroup: FormGroup;
  questionForm: FormGroup;
  explanation: string = '';
  pastExamOptions = [];
  examinationBoardList = [];
  selectedBoard: string;
  testTypes = [];
  isQuestionTinyEditor = false;
  answerFormatOptions: { value: string, label: string }[];
  preSelectedTopic: { value: string, label: string };
  isLoadEditor = true;

  constructor(
    private fb: FormBuilder,
    private newQuestionService: NewQuestionService,
  ) {
    this.ddLevels = [
      { value: undefined, label: 'None' },
      { value: 'FOUNDATION', label: 'Foundation' },
      { value: 'HIGHER', label: 'Higher' },
    ];

    this.initPastExamOptions();
  }

  get questionData() {
    return this.questionForm.controls.questionData as FormArray;
  }

  ngOnInit(): void {
    this.questionForm = this.fb.group({
      questionData: [this.questionEdit.question]
    });

    setTimeout(() => this.isLoadEditor = false, 500);

    this.explanation = this.questionEdit.video_url;
    if (this.questionEdit?.answer_type === this.questionType.TRUE_OR_FALSE) {
      // @ts-ignore
      this.question?.answer_options = ['True', 'False'];
    }
    this.initMediaFormGroup();

    this.answerFormatOptions = [
      { value: QuestionType.OPEN_ENDED, label: QuestionsLabels.OPEN_ENDED },
      { value: QuestionType.SELECT_MULTIPLE, label: QuestionsLabels.MULTI_SELECT },
      { value: QuestionType.SELECT_ONE, label: QuestionsLabels.SINGLE_ANSWER },
      { value: QuestionType.TRUE_OR_FALSE, label: QuestionsLabels.TRUE_FALSE },
      { value: QuestionType.FILE_INPUT, label: QuestionsLabels.FILE }
    ];

    this.newQuestionService
      .getExaminationBoardList()
      .pipe(first())
      .subscribe((list: any) => {
        list.forEach((board) => {
          if (board.id === this.questionEdit.examination_board?.id) {
            this.selectedBoard = JSON.stringify({ name: board.name, country: board.country, id: board.id });
          }
          this.examinationBoardList.push({
            label: board.name,
            value: JSON.stringify({ name: board.name, country: board.country, id: board.id }),
          });
        });
        if (!this.selectedBoard) {
          this.selectedBoard = this.examinationBoardList[0].value;
        }
      });
  }

  updateQuestionData(question: Question) {
    this.newQuestionService.changeQuestion({question, itemIndexs: this.itemIndexWithParents});
  }

  changeAnswerFormat(answerFormat: string) {
    this.newQuestionService.changeAnswerFormat({type: answerFormat, itemIndexs: this.itemIndexWithParents});
  }

  selectTopic(topicId: number) {
    this.newQuestionService.changeTopic({topicId, itemIndexs: this.itemIndexWithParents});
  }

  updateQuestionValue(event: string): void {
    this.questionEdit.question = event;
  }

  updateOptionOutFocus() {
    this.newQuestionService.changeQuestion({question: this.questionEdit, itemIndexs: this.itemIndexWithParents});
  }

  setIsPastExam(val: any[]) {
    this.questionEdit.is_past_exam_question = val[0];
    this.newQuestionService.changeQuestion({question: this.questionEdit, itemIndexs: this.itemIndexWithParents});
  }

  setPastExamYear(val: any | []) {
    this.questionEdit.question_year = val;
    this.newQuestionService.changeQuestion({question: this.questionEdit, itemIndexs: this.itemIndexWithParents});
  }

  setExaminationBoard(val: any) {
    this.questionEdit.examination_board = JSON.parse(val);
    this.newQuestionService.changeQuestion({question: this.questionEdit, itemIndexs: this.itemIndexWithParents});
  }

  checkBoxClick(isChecked: boolean, type: string) {
    this.testTypes = this.questionEdit.testAndQuestionTypes;
    if (isChecked && !this.testTypes.find((item) => item === type)) {
      this.testTypes.push(type);
    }

    if (!isChecked) {
      this.testTypes = this.testTypes.filter((item) => item !== type);
    }

    this.questionEdit.testAndQuestionTypes = this.testTypes;
    this.newQuestionService.changeQuestion({question: this.questionEdit, itemIndexs: this.itemIndexWithParents});
  }

  private initPastExamOptions() {
    let year = 2002;
    const currentYear = new Date().getFullYear();
    while (year <= currentYear) {
      this.pastExamOptions.push({ label: year, value: year });
      year++;
    }
  }

  private initMediaFormGroup() {
    this.mediaFormGroup = this.fb.group({
      video_url: [this.questionEdit?.video_url || '', [CustomValidators.validateVideoUrl]],
    });
  }
}
