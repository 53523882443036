<!--
 Created by Volodymyr Mak. on 20/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container" fxLayout="row space-between" fxLayoutAlign="space-between">
  <article class="left-p" fxLayout="column">
    <section>
      <curr-profile-card
        [isEditable]="false"
        [profileData]="profileData"
      ></curr-profile-card>
    </section>

    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Subjects</span>
      </div>
      <mat-divider></mat-divider>
      <div class="subjects card" fxLayout="column center">
        <div class="subject-item" *ngFor="let subject of subjectsResultList"
          fxLayout="row center"
          fxFlex="50"
          fxLayoutAlign="space-between center">
          <span class="name">{{subject.subjectName}}</span>
          <div class="predicted-grade" fxLayout="row center" fxFlex="50" fxLayoutAlign="space-between">
            <div class="diagram" fxFlex="60">
              <curr-concern-widget-row [concern]="subject.progress"></curr-concern-widget-row>
            </div>
            <div class="grade-wrapper" fxLayout="row center" fxLayoutAlign="space-between center" fxFlex="40">
              <div class="grade">{{subject.grade}}</div>
              <div class="edit-grade">
                <mat-icon class="delete" svgIcon="trash" (click)="removeSubject()"></mat-icon>
                <mat-icon class="edit" svgIcon="edit" (click)="editSubject()"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Notes about student</span>
        <curr-button (btnClick)="addNote()"
          color="primary"
          icon="plus"
          size="small"
          variant="text">
         Add note
        </curr-button>
      </div>
      <mat-divider></mat-divider>
      <div class="card">
        <mat-tab-group [dynamicHeight]="false"
               mat-align-tabs="start"
               animationDuration="0ms"
               >
          <mat-tab label="All Notes">
            <div class="notes-wrapper">
              <div class="note" *ngFor="let note of notesAboutStudent">
                <div class="text">
                  <span class="author">{{note.authorName}}, {{note.authorRole}}:</span>
                  <span class="note-date">{{note.date | date:'medium'}}</span>
                  <span>{{note.note}}</span>
                </div>
              </div>
            </div>
          </mat-tab>
          <mat-tab label="My Notes">
            <div class="notes-wrapper">
              <div class="note" *ngFor="let note of myNotes">
                <div class="text">
                  <span class="author">{{note.authorName}}, {{note.authorRole}}:</span>
                  <span class="note-date">{{note.date | date:'medium'}}</span>
                  <span>{{note.note}}</span>
                </div>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </section>

    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Meeting archive</span>
      </div>
      <mat-divider></mat-divider>
      <div class="card">
        <div class="meetings-archive-wrapper">
          <div class="note" *ngFor="let meeting of archiveMeetings">
            <div class="text">
              <span class="meeting-with">Meeting with <span class="person">{{meeting.personName}}</span></span>
              <span class="note-date">{{meeting.date | date:'medium'}}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>

  <article class="right-p" fxLayout="column">
    <section class="profile-soft-q" fxLayout="column">
      <div class="title" fxLayoutAlign="space-between">
        <span>Soft Questions</span>
      </div>
      <mat-divider></mat-divider>
      <div class="q-content card" fxLayout="column">
        <div class="q-block">
          <div class="title">How do you learn best?</div>
          <div class="tag-wrapper" fxLayout="row wrap">
              <div class="tag" *ngFor="let chips of softQuestion.learnBestChips">
                  {{chips}}
              </div>
          </div>
        </div>
        <div class="q-block">
          <div class="title">Your passion and interests</div>
          <div class="tag-wrapper" fxLayout="row wrap">
              <div class="tag" *ngFor="let chips of softQuestion.interestsChips">
                {{chips}}
              </div>
          </div>
        </div>
        <div class="q-block">
          <div class="title">How do you learn best?</div>
          <div class="answer">
            {{softQuestion.learnBestText}}
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span fxFlex="35">Test Archive</span>
        <div class="ctrl-wrapper" fxFlex="65" fxLayoutAlign="space-between">
          <curr-button size="small" color="primary" variant="text">All</curr-button>
          <curr-button size="small" color="primary" variant="text">Cumulative Test</curr-button>
          <curr-button size="small" color="primary" variant="text">Seft Assessment</curr-button>
          <curr-dropdown [options]="subjects" selectedValue="all"></curr-dropdown>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="card test-wrapper">
        <div class="archive-test" *ngFor="let test of archiveTestList" fxLayoutAlign="space-between center">

          <div class="info" fxLayout="column center">
            <div class="date">
              {{test.type}}, {{test.date | date:'medium'}}
            </div>
            <div class="name">{{test.title}}</div>
            <div class="subject">{{test.subject}}</div>
          </div>

          <div class="result-wrapper">
            <curr-red-label *ngIf="test.isAttention"
              text="Student response require your attention">
            </curr-red-label>
            <div class="result" *ngIf="!test.isAttention">
              <span *ngIf="test.type === 'sa'">
                Self Assessment Complete
              </span>
              <span *ngIf="test.type === 'ct'">
                Test result: <span class="grade">{{test.result}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</div>
