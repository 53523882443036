/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-delete-about-paper-popup',
  templateUrl: './delete-about-paper-popup.component.html',
  styleUrls: ['./delete-about-paper-popup.component.scss']
})
export class DeleteAboutPaperPopupComponent implements OnInit {

  dialogTitle!: string;
  
  constructor(
    public dialogRef: MatDialogRef<DeleteAboutPaperPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.dialogTitle = this.data.title;
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
