import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartFilter } from '../../../../shared-pages/course-filters/course-filters';

@Component({
  selector: 'curr-chart-filter',
  templateUrl: './chart-filter.component.html',
  styleUrls: ['./chart-filter.component.scss']
})
export class ChartFilterComponent implements OnInit {
  @Input()
  chartFilter: ChartFilter;

  filterParams: any = {};

  @Output()
  filterParamsChanged = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    // debugger;
  }

  selectSubject(event) {
    this.chartFilter.selectFilteredItem('subject', event);
    this.filterParams.subjects = event;
    this.emit();
  }

  selectCourse(event) {
    this.filterParams.courses = event;
    this.emit();
  }

  selectLevel(event) {
    this.filterParams.levels = event;
    this.emit();
  }

  emit() {
    Object.keys(this.filterParams).forEach(key =>
      this.filterParams[key] === undefined ? delete this.filterParams[key] : {}
    );
    this.filterParamsChanged.emit(this.filterParams);
  }
}
