<div class="container" ngClass.lt-sm="mob-container">
  <div class="m-b-16" fxLayout="row" fxLayoutAlign="space-between end" fxLayout.lt-sm="column"
       fxLayoutAlign.lt-sm="center center">
    <curr-breadcrumb position="start" class="m-b-xs-16" [path]="path"></curr-breadcrumb>
  </div>

  <div fxLayout="row" fxLayoutGap="24px" fxFlex.gt-xs="100%" fxLayoutAlign="space-between" fxLayout.lt-sm="column"
       fxLayoutAlign.lt-sm="center" class="d-flex justify-content-center">
    <div class="paper_container">
      <ng-container *ngFor="let unitLevel of navigationTree.unitLevels; let i = index">
        <div class="paper_item">
          <div class="unit_title mat-h2">Unit {{ i + 1 }}: {{ unitLevel.unit.title | titlecase }}</div>
          <div class="card_wrapper">
            <curr-student-lesson-card
              *ngFor="let l of unitLevel.topicLevels; index as index"
              [lesson]="l"
              [subjectId]="subjectId"
              [subjectName]="subjectName"
              [subjectTopics]="subjectTopics"
              [studentProfile]="studentProfile"
              [studentTopicTestSchoolData]="studentTopicTestSchoolData"
              [index]="index + i * (navigationTree.unitLevels.length + 1)"
              [class.highlighted]="l.topic.highlighted"
              [style]="l.topic.highlighted ? style : ''"
              class="card-style m-b-8"
            ></curr-student-lesson-card>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="widgets-container">
<!--      <curr-topic-test-widget-->
<!--        [isShow]="true"-->
<!--        (onDataLoad)="onTopicTestWidgetLoad($event)"></curr-topic-test-widget>-->
      <curr-historical-assesment-widget [schoolYearCourseId]="schoolYearCourseId"></curr-historical-assesment-widget>
    </div>
  </div>
</div>


<!--&lt;!&ndash;TODO Previous Version&ndash;&gt;-->
<!--<div class="container" ngClass.lt-sm="mob-container">-->
<!--  <div fxFlex.xs="70%" fxFlex>-->
<!--&lt;!&ndash;    <div fxLayout="column" fxLayoutAlign="start start" class="m-t-0">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="header-container main-header m-b-0" fxLayout="row" fxLayoutAlign="space-between center">&ndash;&gt;-->
<!--&lt;!&ndash;        <h2 class="mat-h2 m-b-16 m-l-16">Pending Topic Tests</h2>&ndash;&gt;-->
<!--&lt;!&ndash;        <div></div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;      <mat-divider class="m-b-16 full-width"></mat-divider>&ndash;&gt;-->
<!--&lt;!&ndash;      <ng-container *ngIf="cumulativeTests.length; else noRecords">&ndash;&gt;-->
<!--&lt;!&ndash;        <curr-student-cumulative-test-card *ngFor="let ct of cumulativeTests" [test]="ct" class="full-width m-b-8">&ndash;&gt;-->
<!--&lt;!&ndash;        </curr-student-cumulative-test-card>&ndash;&gt;-->
<!--&lt;!&ndash;      </ng-container>&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="full-width m-t-32" fxLayoutAlign="center center" fxLayout="column">&ndash;&gt;-->
<!--&lt;!&ndash;        <curr-loader [isLoading]="isLoading" [diameter]="48" class="m-b-8"></curr-loader>&ndash;&gt;-->
<!--&lt;!&ndash;        <curr-button variant="contained" size="big" (click)="loadMoreCt()" *ngIf="isLoadMoreCumulativeTests">LOAD MORE&ndash;&gt;-->
<!--&lt;!&ndash;        </curr-button>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--    <div fxLayout="column" fxLayoutAlign="start start" class="m-t-24">-->
<!--      <div class="header-container main-header m-b-0" fxLayout="row" fxLayoutAlign="space-between center">-->
<!--        <h2 class="mat-h2 m-b-16 m-l-16">Lessons</h2>-->
<!--        <div></div>-->
<!--      </div>-->
<!--      <mat-divider class="m-b-16"></mat-divider>-->
<!--      <ng-container *ngIf="lessons.length; else noRecords">-->
<!--        <div fxLayout="row" fxLayoutAlign="start center" class="today-tomorrow m-b-8">-->
<!--          <div class="icon m-r-8"></div>-->
<!--          <span>Today</span>-->
<!--        </div>-->
<!--        <curr-student-lesson-card *ngFor="let l of lessons" [lesson]="l" class=" m-b-8">-->
<!--        </curr-student-lesson-card>-->
<!--      </ng-container>-->
<!--&lt;!&ndash;      <div class=" m-t-32" fxLayoutAlign="center center" fxLayout="row" *ngIf="!isAllLessonsFetched">&ndash;&gt;-->
<!--&lt;!&ndash;        <curr-loader [isLoading]="isLessonsLoading" [diameter]="48" class="m-b-8"></curr-loader>&ndash;&gt;-->
<!--&lt;!&ndash;        <curr-button *ngIf="!isLessonsLoading && isLoadMoreLessons" (btnClick)="getLessons()" variant="contained"&ndash;&gt;-->
<!--&lt;!&ndash;                     size="big">LOAD MORE&ndash;&gt;-->
<!--&lt;!&ndash;        </curr-button>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<ng-template #noRecords>
  <div class="full-width" fxLayoutAlign="center center" fxLayout="row">
    <span class="mat-body-2">No items for you yet</span>
  </div>
</ng-template>
