import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoricalAssessmentService } from '../../../student/historical-assessment/historical-assessment.service';
import { UserService } from '../../user.service';
import { TimeDateService } from 'src/app/shared/services/time-date.service';

@Component({
  selector: 'curr-topic-test-widget',
  templateUrl: './topic-test-widget.component.html',
  styleUrls: ['./topic-test-widget.component.scss'],
})
export class TopicTestWidgetComponent implements OnInit {
  @Output() onDataLoad: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() public isShow: boolean;
  topicIds: number[] = [];
  nextTopicTest$: Observable<any>;
  testDate: number;
  studentId: number;
  topicId: number;
  date: number;
  curriculumId: number;
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  zeroValue: number = 0;
  testStart: number;
  startTime: number;
  isDisplayed: boolean = false;
  isAvailable: boolean = true;
  isAutoContinueTest: boolean = false;
  schoolYearCourseStudentId: string;
  public timeDifference;
  public secondsToDay;
  public minutesToDay;
  public hoursToDay;
  public daysToDay;


  constructor(
    private historicalAssessment: HistoricalAssessmentService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private timeDateService: TimeDateService
  ) { }

  ngOnInit(): void {
    this.nextTopicTest$ = this.route.paramMap
      .pipe(
        switchMap(res => {
          return this.historicalAssessment
          .getNextTopicTest(+res.get('courseId') || +res.get('subjectId'))
          .pipe(map(resp => {
            this.schoolYearCourseStudentId = resp.data.schoolYearCourseStudentId;
            if (resp?.data?.curriculumTopicsIds?.length) {
              this.topicIds = resp.data.curriculumTopicsIds;
            }
            return resp.data;
          }));
        })
      )
    this.curriculumId = +this.route.snapshot.params.courseId;
    if (!this.curriculumId) {
      this.curriculumId = +this.route.snapshot.params.subjectId;
    }

    this.getData();
    this.userService.getCurrUserProfileInfo().pipe(first()).subscribe(resp => this.studentId = resp.id);
  }


  getData(): void {
    this.nextTopicTest$.pipe(first()).subscribe(res => {
      if (res.courseType === 'EXAM_PREPARATION') {
        this.isAvailable = true;
        this.isAutoContinueTest = true;
      } else {
        this.date = new Date(res.startTime).getTime()
        this.startTime = 2 * this.hoursInADay * this.minutesInAnHour * this.SecondsInAMinute * this.milliSecondsInASecond;
        this.testDate = this.date - this.startTime - new Date().getTime();
        this.allocateTimeUnits(this.testDate);
        this.testStart = this.testDate / (this.milliSecondsInASecond * this.minutesInAnHour);
        if(this.testStart > this.zeroValue) {
          this.isAvailable = false;
        }
      }
    })
  }

  private allocateTimeUnits (timeDifference): void {
    this.secondsToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDay = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  public navigateToTopicTest(): void {
    this.nextTopicTest$.pipe(first())
      .subscribe(response => {
        this.router
          .navigate([`/courses-details/${this.curriculumId}/cumulative-test/${ response.id }/${ this.studentId }/${ this.schoolYearCourseStudentId }`])
      })
  }

  public convertToLocalTime(dateTime): Date {
    return this.timeDateService.convertToLocalTime(dateTime);
  }
}

