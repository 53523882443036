/*
 * Created by Volodymyr Mak. on 30/6/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { BehaviorSubject, of, Subscription } from 'rxjs';

import { ActivatedRoute } from '@angular/router';
import { getRestApiProvider, Paginated, RestApiService } from '../../shared/api/rest-api.service';
import { Course, CourseDetails } from '../../shared-pages/courses/course';
import { switchMap } from 'rxjs/operators';
import { View } from '../../shared/components/course-card/course-card.component';
import { CoursesService } from '../../shared-pages/courses/courses.service';
import { Series } from '../../shared/graphs/school-performance-histogram/series.model';

@Component({
  selector: 'curr-teacher-profile',
  templateUrl: './teacher-profile.component.html',
  styleUrls: ['./teacher-profile.component.scss'],
  providers: [getRestApiProvider<Course>('schoolYearCourses/getTeacherSubjects', 'getTeacherSubjects')]
})
export class TeacherProfileComponent implements OnInit, OnDestroy {
  filterChangedSubscription: Subscription;
  filterChanged$ = new BehaviorSubject({});
  profileData: any;
  options = [
    { value: 1, label: 'Able to view students profile', disabled: false },
    { value: 2, label: 'Able to view curriculums', disabled: false },
    { value: 3, label: 'Able to view courses', disabled: false }
  ];
  value = [1, 3];
  cards = [];
  paginated$ = new BehaviorSubject(new Paginated(10, 0, 10));
  chartData: Series[] = [
    {
      data: [
        {
          date: '2012-1-1',
          value: 2
        },
        {
          date: '2012-2-2',
          value: 3
        },
        {
          date: '2012-3-3',
          value: 4
        },
        {
          date: '2012-4-4',
          value: 3
        },
        {
          date: '2012-5-5',
          value: 2
        },
        {
          date: '2012-6-6',
          value: 3
        },
        {
          date: '2012-7-7',
          value: 4
        },
        {
          date: '2012-8-8',
          value: 3
        }
      ],
      name: 'test',
      cssClasses: { line: 'green-statistic', dot: 'green-statistic-dot' }
    }
  ];
  path = [
    { label: 'Home', url: '/dashboard' },
    { label: 'Users', url: '' }
  ];
  private subscription = new Subscription();

  constructor(private route: ActivatedRoute,
              private coursesService: CoursesService,
              @Inject('getTeacherSubjects') private teacherCoursesService: RestApiService<CourseDetails>
  ) {
  }

  ngOnInit(): void {
    this.profileData = this.route.snapshot.data.profileData;
    this.path.push({ label: `${this.profileData.first_name} ${this.profileData.last_name}`, url: '' });
    this.init();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  init() {
    this.paginated$ = new BehaviorSubject(new Paginated(10, 0, 10));
    this.cards = [];
    this.filterChangedSubscription = this.filterChanged$
      .pipe(
        switchMap(res => {
          return this.teacherCoursesService.getAllWithPostPaginated(this.paginated$, res);
        })
      )
      .subscribe(res => {
        this.cards.push(...this.coursesService.createCourseCards(res));
        this.cards.forEach((card, index) => {
          card.title.topRightIcons = [
            { icon: 'sandwich', link: '/#', viewToSwitch: View.StandardView },
            { icon: 'graph', link: '/#', viewToSwitch: View.GraphView }
          ];
          // tslint:disable-next-line
          card['chartData'] = of(this.chartData);
        });
      });
  }

  filterChanged(event) {
    this.filterChangedSubscription.unsubscribe();
    this.filterChanged$.next(event);
    this.init();
  }


  showMore() {
    this.paginated$.next(this.paginated$.value.next());
  }

}
