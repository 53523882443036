<ng-container [@fade]>
  <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="space-between" [@fade] >
    <div fxFlex class="m-b-16 mat-h2  m-l-16">Overview</div>
    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutGap="32px" class="m-t-8">
      <div class="button"
           [ngClass]="currentPeriod === ChartPeriod.MONTH ? 'button-selected' : ''"
           (click)="refreshGraphWithPeriod(ChartPeriod.MONTH)">
        MONTH
      </div>
      <div class="button"
           [ngClass]="currentPeriod === ChartPeriod.SEMESTER ? 'button-selected' : ''"
           (click)="refreshGraphWithPeriod(ChartPeriod.SEMESTER)">
        SEMESTER
      </div>
      <div class="button"
           [ngClass]="currentPeriod === ChartPeriod.YEAR ? 'button-selected' : ''"
           (click)="refreshGraphWithPeriod(ChartPeriod.YEAR)">
        YEAR
      </div>
    </div>
  </div>
  <mat-divider class="m-b-16"></mat-divider>
  <mat-card class="rounded-border card">
    <div fxLayout="row start"
         fxLayout.xs="column start"
         fxLayoutAlign="space-between"
         fxLayoutGap.xs="12px"
         class="m-b-8">
      <div fxLayout="row wrap" fxLayoutGap="10px" fxFlexAlign="center" fxFlexAlign.xs="start">
        <div fxFlex="0 1" fxLayoutGap="10px" fxLayout="row">
          <div class="mat-h3 m-b-0">
            Avg.Grade: {{ averageGrade || '-' }} &nbsp;
          </div>
        </div>
        <div fxLayout="row" fxFlex="0 1"></div>
      </div>
      <div fxFlex="0 0 auto" fxFlex.xs="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="8px">
        <div fxFlex="100%" fxFlex.xs="100%" class="mat-body-2">
<!--          <curr-inline-course-filter-->
<!--            *ngIf="areFiltersPresented"-->
<!--            (filterChanged)="filterChanged($event)"-->
<!--          ></curr-inline-course-filter>-->
        </div>
      </div>
    </div>
    <mat-divider class="m-l-16 m-r-16 divider"></mat-divider>
    <ng-container *ngIf="studentData">
      <curr-student-grades-statistic [currentPeriod]="currentPeriod"
        [width]="width" [chartData$]="displayData$"></curr-student-grades-statistic>
    </ng-container>
    <div class="no-data-image"  *ngIf="!studentData" style.background-image="url('{{ noDataImage }}')" [@fade]>
      <div class="m-b-64 mat-display-1">
        This graph will be available when your students complete their first Topic Test
      </div>
    </div>
  </mat-card>
</ng-container>
