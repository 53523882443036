import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-edit-note-about-student-popup',
  templateUrl: './edit-note-about-student-popup.component.html',
  styleUrls: ['./edit-note-about-student-popup.component.scss']
})
export class EditNoteAboutStudentPopupComponent implements OnInit {
  text: string;

  constructor(public dialogRef: MatDialogRef<EditNoteAboutStudentPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.text = this.data?.note || '';
  }

}
