/*
 * Created by Dmytro Sav. on 5/18/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { getRestApiProvider } from '../../api/rest-api.service';
import { ResultsTableService } from './results-table.service';
import { Router } from '@angular/router';

export interface PeriodicElement {
  title: string;
  saTest: string;
  cumTest: string;
}

@Component({
  selector: 'curr-historical-assessments-results-table',
  templateUrl: './results-table.component.html',
  styleUrls: ['./results-table.component.scss'],
  providers: [getRestApiProvider<PeriodicElement>('schoolYearCourses/getHistoricalAssessment')]
})
export class ResultsTableComponent implements OnInit {
  @Input() subjectId: string;
  @Input() studentId: string;

  displayedColumns: string[] = ['topicName', 'selfAssessment'];
  dataSource;

  constructor(private resultsTableService: ResultsTableService,
              private router: Router) {
  }

  ngOnInit() {
    let req;

    if (!this.studentId) {
      req = this.resultsTableService.getData(this.subjectId);
    } else {
      req = this.resultsTableService.getStudentData(this.subjectId, this.studentId);
    }
    req.subscribe(res => {
      this.dataSource = res;
    });
  }

  goToCTReview(element) {
    this.router.navigate([`/ct-review/${ element.test_id }/${ element.course_student_id }/${ element.course_id }`]);
  }

  goToTest(lesson: any) {
    this.router.navigate([
      `/courses-details/${ lesson.course_id }/self-assessment/${ lesson.lobjective_id }/${ lesson.course_student_id }`
    ]);
  }

  goToLearningResources(lesson: any) {
    if (lesson.test_type === 'topic_test') {
      this.goToCTReview(lesson);
      return;
    }

    this.router.navigate([
      `/learning-resources/${ lesson.curriculum_id }/${ lesson.lobjective_id }/${ lesson.course_student_id }`
    ]);
  }

  goToResults(lesson: any) {
    this.router.navigate([`/courses-details/sa-review/${ lesson.student_test_id }/${ lesson.curriculum_id }/${ lesson.course_student_id }`]);
  }
}
