/**
 * Created by Maxim B. on 28/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { NotificationMessage } from './notification-message';
import { DatePipe } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'curr-notifications-bar',
  templateUrl: './notifications-bar.component.html',
  styleUrls: ['./notifications-bar.component.scss'],
  animations: [
    trigger('showNotificationBar', [
      state('minimized', style({
        'overflow-x': 'hidden', width: '0px'
      })),
      state('opened', style({
        'overflow-x': 'hidden',
        width: window.innerWidth < 600 ? window.innerWidth + 'px' : '390px'
      })),
      transition('minimized => opened', [
        animate('500ms')

      ]),
      transition('opened => minimized', [
        animate('500ms')
      ])
    ])
  ]
})
export class NotificationsBarComponent implements OnInit, OnDestroy {
  @Input() currentState = 'minimized';
  @Input() subscriptionObject: Observable<NotificationMessage> = new Observable();
  @Output() onCloseBar = new EventEmitter<string>();
  subscription: Subscription;
  isVisibleBar = false;
  itm = {
    title: 'Reviewed your Biology test',
    message: 'Lorem ipsum dolor sit amet,  nisi ut aliquip ex ea commodo consequat.',
    date: new Date(),
    url: ''
  };
  notificationsArr = [
    { date: '1588081237000', items: [this.itm, this.itm, this.itm, this.itm, this.itm] },
    { date: '1587994792000', items: [this.itm] },
    { date: '1587908392000', items: [this.itm] },
    { date: '1587821992000', items: [this.itm] }
  ];


  constructor(private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    this.subscription = this.subscriptionObject.subscribe(notification => {
      this.notificationsArr[0].items.unshift(notification);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDayLabel(value: string) {
    const val = Number(value);

    const dif = Math.floor(((Date.now() - val) / 1000) / 86400);

    if (dif === 0) {
      return 'Today';
    }
    if (dif === 1) {
      return 'Yesterday';
    }

    return this.datePipe.transform(val, 'dd MMMM');
  }

  remove($event: string, i: number, n: number) {
    this.notificationsArr[n].items.splice(i, 1);
  }

  closeNotificationsBar() {
    this.onCloseBar.emit('');
  }
}
