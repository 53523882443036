/*
 * Created by Volodymyr Mak. on 3/7/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { pluck, map } from 'rxjs/operators';

import { ApplicationHttpClient } from '../../api/application-http-client';

export interface TimeTable {
  school_year_settings_id: number;
  semester_count: number;
  courses: Course[];
  weeks: Weeks;
}

export interface Course {
  lesson: string;
  course: string;
  location: string;
  lessonNum: number;
}

export enum DayNames {
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
  SUNDAY = 'SUNDAY'
}

interface Days {
  [DayNames.MONDAY]: Course[];
  [DayNames.TUESDAY]: Course[];
  [DayNames.WEDNESDAY]: Course[];
  [DayNames.THURSDAY]: Course[];
  [DayNames.FRIDAY]: Course[];
  [DayNames.SATURDAY]: Course[];
  [DayNames.SUNDAY]: Course[];
}

export interface Weeks {
  [key: string]: { days: Days };
}

export interface AcademicYear {
  id: number;
  school_id: number;
  academ_year_range: string;
  academ_year_start: number;
  has_diff_year_terms: boolean;
  contact_hours: number;
  lesson_duration: number;
  school_years_equal_semesters: boolean;
}

export interface SchoolYearSettings {
  token: string;
  message: string;
  data: {
    items: AcademicYear[];
  };
}

@Injectable()
export class TimeTableService {
  constructor(private httpClient: ApplicationHttpClient) {}

  uploadTimeTable(uploadingFiles: File[]): Observable<TimeTable> {
    const formData: FormData = new FormData();
    formData.append('file', uploadingFiles[0]);

    return this.httpClient.post('/service/parse/timetable', formData);
  }

  saveTimeTable(timetable: TimeTable): Observable<any> {
    return this.httpClient.post('/uploadTimetable', timetable);
  }

  getTimetablePeriod(): Observable<{ value: number; label: string; selected: boolean }[]> {
    return this.httpClient.get('/schoolYearWithSemesters').pipe(
      pluck('data', 'items'),
      map((data: AcademicYear[]) => {
        return data.map((year, index) => {
          const endPeriod = year.academ_year_range.split(',')[1].slice(0, 4);

          // Parse data appicable for dropdown component.
          return {
            value: year.id,
            label: `Academic year ${year.academ_year_start} - ${endPeriod}`,
            selected: !index
          };
        });
      })
    );
  }

  getTeacherCourses(){
    return this.httpClient.post(`/schoolYearCourses/getTeacherSubjects`,{
      limit: 1000,
      offset: 0,
      row_offset: 0,
      rows_count: 1000,
    })
  }

}
