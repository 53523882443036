import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export interface Options {
  value: string;
  label: string;
  selected?: boolean;
  disabled?: boolean;
}

/*
   Usage example:
 <curr-dropdown
  [options]="[
    { value: '1', label: 'Year 1' },
    { value: '2', label: 'Year 2' },
    { value: '3', label: 'Year 3' },
    { value: '4', label: 'Year 4' }
  ]"
  placeholder="Select Year"
  [selectedValue]="drValues"
  (valueChange)="getValue($event)"
  [defaultValue]="drDefaultValue"
>
</curr-dropdown>

  [options]: [{ value: string, label: string }]
  placeholder : string
  [selectedValue] : number | string | []
  (valueChange): return value
  [defaultValue] : number | string | []
  [isMultiSelected] : boolean (if isMultiSelected === true than selectedValue and defaultValue must be Array)
  [isDisabled] : boolean

*/
@Component({
  selector: 'curr-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  animations: [fadeAnimation]
})
export class DropdownComponent implements OnChanges, OnInit {
  @Input() label?: boolean;
  @Input() isCustomPlaceholder?: boolean;
  @Input() placeholder: string;
  @Input() options: Array<Options>;
  @Input() isDisabled? = false;
  @Input() isMultiSelected? = false;
  @Input() isMultiSelectedChips? = false;
  @Input() defaultValue: Options;
  @Input() selectedValue;
  @Input() formGroup?: FormGroup;
  @Input() controlName? = '';
  @Input() icon?: string;

  @Output() valueChange = new EventEmitter<any | []>();

  isShowed = false;
  isError = false;
  selected;
  selectedLabel;
  // newOptions need for work with animation
  newOptions = [];
  copyOptions = [];
  isHighlighted: boolean;

  constructor(private fb: FormBuilder) {
  }

  getNewPlaceholder(): string{
    if (this.isCustomPlaceholder) return this.placeholder;
    return this.placeholder === 'EXAM_PREPARATION' ? 'Exam Preparation' : 'Exam Boost'
  }

  ngOnChanges(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.selected)
      });
      this.controlName = 'item';
    }
    this.updateOptions();
  }

  updateOptions() {
    this.copyOptions = this.deepCopy<Options[]>(this.options);
    const getValidValue = this.selectedValue || this.defaultValue;
    this.selected = new Set(this.getValueByOption(getValidValue));
    if (this.selectedValue) {
      this.selectValue(this.getValueByOption(this.selectedValue)[0]);
    }

    this.selectedLabel = [...this.selected].map(({ label }) => label).join(', ');
  }

  deepCopy<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
  }

  getValueByOption(value): Options[] {
    let filtered = [];
    if (Array.isArray(value)) {
      filtered = this.copyOptions.filter(option => value.includes(option.value));
    } else {
      filtered = this.copyOptions.filter(option => option.value === value);
    }

    return filtered.map(option => Object.assign(option, { selected: true }));
  }

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
      this.newOptions = this.newOptions.length ? [] : [...this.copyOptions];
    }
  }

  selectValue(option: Options, event?: Event) {
    if (!option) {
      return;
    }
    if (option.disabled) {
      return;
    }
    let emittedValue;
    if (this.isMultiSelected) {
      event?.stopPropagation();

      if (this.selected.has(option)) {
        option.selected = false;
        this.selected.delete(option);
      } else {
        option.selected = true;
        this.selected.add(option);
      }
      emittedValue = [...this.selected].map(({ value }) => value);
    } else {
      this.selected.clear();
      this.newOptions.forEach(newOption => (newOption.selected = false));
      this.selected.add(option);
      option.selected = true;
      emittedValue = [...this.selected][0].value;
    }
    this.valueChange.emit(emittedValue);
    this.formGroup.controls[this.controlName].setValue(emittedValue);

    this.selectedLabel = [...this.selected].map(({ label }) => label).join(', ');
    this.isError = this.selected.size === 0;
    this.closeDropDown();
  }

  closeDropDown() {
    this.isShowed = false;
    this.newOptions = [];
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.selected)
      });
      this.controlName = 'item';
    }
  }
}
