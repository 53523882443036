/**
 * Created by Alex Poh. on 10/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Gantt } from '../../../../assets/dhtmlx/dhtmlxgantt';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GanttService } from './gantt.service';
import { GanttUnit, Lesson, LessonDate } from './gantt-unit';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

export interface DateDuration {
  date: Date;
  duration: number[];
}

export interface FakeUnitDates {
  unitName: string;
  topics: FakeTopicDates[];
}

export interface FakeTopicDates {
  topicName: string;
  fakeLearningObjective: FakeLearningObjectiveDates;
}

export interface FakeLearningObjectiveDates {
  date: Date;
  learningObjectives: GanttLearningObjective[];
}

export interface GanttLearningObjective {
  id: string;
  name: string;
  dateRange: DateRange;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

@Component({
  selector: 'curr-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GanttChartComponent implements AfterViewInit, OnDestroy {
  @ViewChild('chart') ganttContainer: ElementRef;

  // TODO: check if need
  // tslint:disable-next-line:no-unused-variable

  num: number;
  gantt;

  @Input()
  courseId: string;

  isDataPresented = true;

  ganttUnits: GanttUnit[];
  dateDurations: DateDuration[] = [];
  dateDurationProgression: DateDuration[] = [];
  fakeUnitDates: FakeUnitDates[] = [];
  cellsInSingleLesson = 6;

  lessonDuration = 60;

  columnWidth = 30;

  chartData: { data: any[]; links: any[] } = { data: [], links: [] };

  destroy$: Subject<boolean> = new Subject<boolean>();
  fakeStartingDate = new Date(0);
  startDate: Date;
  endDate: Date;
  revisionTimeId: number;
  examTaskId: number;

  ganttIdMap = new Map();

  isInitUpdate = true;
  revisionTimeDuration: number;
  snackBarRefSubscription: Subscription;
  scrollContainer: string;

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private snackBar: MatSnackBar,
    private ganttService: GanttService
  ) {
  }

  private get startedDate(): Date {
    const [year, month, day] = this.ganttUnits[0].topics[0].lessons[0].date.split('-');
    return new Date(parseFloat(year), parseFloat(month) - 1, parseFloat(day));
  }

  ngAfterViewInit(): void {
    this.ganttService.getGanttData(this.courseId).subscribe((res: any) => {
      if (res) {
        this.ganttUnits = res;
        this.configureGantt();
        this.gantt.init(this.ganttContainer.nativeElement.id);
        this.initChart();
        this.revisionTimeDuration = res[0].left_lessons;
      } else {
        this.isDataPresented = false;
      }
    });
  }

  ngOnDestroy() {
    try {
      // @ts-ignore
      this.gantt?.tooltip_hide_timeout = 0;
      this.gantt?.destructor();
      this.destroy$.next(true);
    } catch (error) {
      console.error(error);
    }

    this.snackBarRefSubscription?.unsubscribe();
  }

  configureGantt() {
    this.gantt = Gantt.getGanttInstance({
      container: this.ganttContainer.nativeElement.id,
      plugins: {
        auto_scheduling: true,
        tooltip: true,
        undo: true
      }
    });
    this.gantt.config.auto_scheduling = true;
    this.gantt.config.auto_scheduling_compatibility = true;
    this.gantt.config.auto_scheduling_strict = true;

    this.gantt.config.show_errors = false;
    this.gantt.config.autosize = 'n';
    this.gantt.config.grid_width = 450;
    this.gantt.config.static_background = true;

    this.gantt.config.columns = [
      {
        name: 'text',
        label: '<div style="color:red"></div>',
        tree: false,
        template: d => {
          let result;
          if (d.$level < 2) {
            result = `<div class="left-row mat-body-2 m-b-0"><b>${ d.text }</b></div>`;
          } else {
            result = `<div class="left-row mat-body-2 m-b-0">${ d.text }</div>`;
          }
          return result;
        }
      }
    ];

    this.gantt.templates.task_text = (start, end, task) => {
      if ((task.id + '').indexOf('unit') !== -1) {
        return `Unit ${ task.text }`;
      }
      if ((task.id + '').indexOf('topic') !== -1) {
        return `Topic ${ task.text }`;
      }
      return ``;
    };
  }

  initChart() {
    this.fillDateDurations();
    this.fillLinksAndData();
    this.configureStartAndEndDate();
    this.attachAutoScheduleEvent();
    this.configureScalesAndColumns();
    this.gantt.parse(this.chartData);
    this.scrollToClosestLesson();
  }

  fillDateDurations() {
    let currentDate = this.startedDate;
    const startingDuration = 0;
    this.ganttUnits.forEach(ganttUnit => {
      const fakeUnitDate: FakeUnitDates = { unitName: ganttUnit.unit_title, topics: [] };
      ganttUnit.topics.forEach(ganttTopic => {
        const fakeTopicDate: FakeTopicDates = { topicName: ganttTopic.topic_title, fakeLearningObjective: undefined };
        ganttTopic.lessons.forEach((lessonDate: LessonDate, i) => {
          if (!this.dateDurations[i]) {
            this.dateDurations[i] = { date: new Date(lessonDate.date), duration: [] };
          }
          if (!this.dateDurationProgression[i]) {
            this.dateDurationProgression[i] = { date: new Date(lessonDate.date), duration: [] };
          }
          if (!fakeTopicDate.fakeLearningObjective) {
            fakeTopicDate.fakeLearningObjective = { date: new Date(lessonDate.date), learningObjectives: [] };
          }
          lessonDate.lesson.forEach((lesson: Lesson) => {
            this.dateDurations[i].duration.push(lesson.learning_objectives.length);
            this.dateDurationProgression[i].duration.push(startingDuration);
            lesson.learning_objectives.forEach(t => {
              const endDate = this.addDaysToDate(currentDate, Math.round(this.cellsInSingleLesson * t.lesson_part));
              fakeTopicDate.fakeLearningObjective.learningObjectives.push({
                id: t.id,
                name: t.topic_title,
                dateRange: {
                  startDate: currentDate,
                  endDate
                }
              });
              currentDate = endDate;
            });
          });
        });
        fakeUnitDate.topics.push(fakeTopicDate);
      });
      this.fakeUnitDates.push(fakeUnitDate);
    });
  }

  fillLinksAndData() {
    let dataId = 1;
    let linkId = 1;
    let previousTopic;
    let previousTopicendDate;
    this.fakeUnitDates.forEach((fud, unitIndex) => {
      this.chartData.data.push({
        id: `unit-${ unitIndex }`,
        text: fud.unitName,
        open: true
      });
      fud.topics.forEach((fakeTopic, topicIndex) => {
        this.chartData.data.push({
          id: `topic-${ unitIndex }-${ topicIndex }`,
          text: fakeTopic.topicName,
          parent: `unit-${ unitIndex }`,
          open: true
        });
        fakeTopic.fakeLearningObjective.learningObjectives.forEach((topic: GanttLearningObjective, index) => {
          dataId = parseInt(topic.id, 10);
          const foundTopic = this.chartData.data.find(topicInChartData => topicInChartData.id === dataId);
          if (foundTopic) {
            foundTopic.end_date = this.parseDate(topic.dateRange.endDate);
            previousTopicendDate = foundTopic.end_date;
          } else {
            this.chartData.data.push({
              id: dataId,
              text: topic.name,
              start_date: this.parseDate(topic.dateRange.startDate),
              end_date: this.parseDate(topic.dateRange.endDate),
              parent: `topic-${ unitIndex }-${ topicIndex }`,
              open: true
            });
          }
          this.ganttIdMap.set(dataId, { topicId: topic.id });
          dataId++;
          if (previousTopic && previousTopic?.id !== topic.id) {
            this.chartData.links.push({
              id: linkId,
              source: previousTopic.id,
              target: topic.id,
              type: 0
            });
            linkId++;
          }
          if (previousTopic?.id !== topic.id) {
            previousTopic = topic;
          }
        });
      });
    });
    this.initReviseTimeRow(dataId, linkId, previousTopicendDate, this.getLastChartElByType('unit')?.id);
    linkId++;
    dataId++;
    this.initExamRow(dataId, linkId, this.getLastChartElByType()?.id);
  }

  configureStartAndEndDate() {
    this.gantt.config.start_date = this.startedDate;
    this.gantt.config.end_date = new Date(this.ganttUnits[0].semester_end);
  }

  attachAutoScheduleEvent() {
    this.gantt.attachEvent(
      'onAfterAutoSchedule',
      (taskId, updatedTasks) => {
        try {
          this.updateRevisionTime();
          let tasks = this.getTopicTasks();
          const targetTaskIndex = tasks.findIndex(task => task.id === taskId);
          if (targetTaskIndex !== -1 && tasks[targetTaskIndex - 1]) {
            const targetTask = tasks[targetTaskIndex];
            tasks[targetTaskIndex - 1].end_date = targetTask.start_date;
            this.gantt.updateTask(tasks[targetTaskIndex - 1].id);
            this.gantt.refreshData();
            tasks = this.getTopicTasks();
          }

          if (targetTaskIndex !== -1 && tasks[targetTaskIndex + 1]) {
            const targetTask = tasks[targetTaskIndex];
            tasks[targetTaskIndex + 1].start_date = targetTask.end_date;
            this.gantt.updateTask(tasks[targetTaskIndex + 1].id);
            this.gantt.refreshData();
            tasks = this.getTopicTasks();
          }
          const taskUpdateData = [];
          tasks.forEach(task => {
            taskUpdateData.push({
              curriculum_topic_id: task.id,
              duration: Math.round((task.duration / this.cellsInSingleLesson) * this.lessonDuration * 100) / 100
            });
          });

          this.ganttService
            .updateGanttData(taskUpdateData, this.courseId)
            .subscribe(res => this.updateGanttDataHandler(res));
          return true;
        } catch (error) {
          console.error(error);
        }
      },
      { thisObject: this }
    );
  }

  private initReviseTimeRow(dataId: number, linkId: number, endDate: string, linkSourceId: number | string) {
    this.revisionTimeId = dataId;
    this.chartData.data.push({
      id: this.revisionTimeId,
      text: 'Revision time',
      end_date: this.parseDate(this.getLessonsEndDate()),
      start_date: endDate,
      auto_scheduling: true,
      color: 'var(--theme-color-accent-5)'
    });
    this.chartData.links.push({
      id: linkId,
      source: linkSourceId,
      target: this.revisionTimeId,
      auto_scheduling: false,
      type: 0
    });
  }

  private initExamRow(dataId: number, linkId: number, linkSourceId: number | string): void {
    const endDate = new Date(this.ganttUnits[0].semester_end);
    endDate.setHours(endDate.getHours() + 24);
    this.examTaskId = dataId;
    this.chartData.data.push({
      id: dataId,
      text: 'Exam',
      start_date: this.parseDate(this.getLessonsEndDate()),
      end_date: this.parseDate(endDate),
      auto_scheduling: false,
      color: '#e04845'
    });
    this.chartData.links.push({
      id: linkId,
      source: linkSourceId,
      target: dataId,
      type: 0
    });
  }

  private getLastChartElByType(type?: 'topic' | 'unit'): any {
    if (!type) {
      return this.chartData.data[this.chartData.data.length - 1];
    }
    return [...this.chartData.data]
      .reverse()
      .find(chartEl => typeof chartEl.id === 'string' && !!~chartEl.id.indexOf(type));
  }

  private getLessonsEndDate() {
    const date = new Date(this.ganttUnits[0].semester_end);
    date.setDate(date.getDate() - 1);
    date.setHours(date.getHours() - 12);
    return date;
  }

  getTopicTasks() {
    return this.gantt.getTaskByTime().filter(task => {
      return (task.id + '').indexOf('unit') === -1 && (task.id + '').indexOf('topic') === -1;
    });
  }

  configureScalesAndColumns() {
    this.gantt.config.scales = [
      {
        unit: 'day',
        step: 1,
        format: d => {
          return `L${ Math.ceil(
            (this.calculateLessonNumber(this.startedDate.getTime(), d) + 1) / this.cellsInSingleLesson
          ) }`;
        }
      },
      {
        unit: 'day',
        step: this.cellsInSingleLesson,
        format: (d: any) => {
          return this.gantt.date.date_to_str('%d %M')(d);
        }
      },
      {
        unit: 'day',
        step: this.cellsInSingleLesson,
        format: d => {
          return `lesson ${ Math.ceil(this.calculateLessonNumber(this.startedDate, d) / this.cellsInSingleLesson) + 1 }`;
        },
        css: d => 'lesson_sign'
      }
    ];

    this.gantt.config.min_column_width = this.columnWidth;

    this.gantt.templates.tooltip_text = (start, end, task) => {
      if (task.$level === 2) {
        return '<div><b>Topic:</b> ' + task.text;
      }
    };
  }

  calculateLessonNumber(startDate, targetDate) {
    return Math.ceil((targetDate - startDate) / (1000 * 60 * 60 * 24));
  }

  private scrollToClosestLesson() {
    const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

    const targetTask = this.gantt
      .getTaskByTime()
      .filter(task => Number.isInteger(task.id))
      .sort((a, b) => a.start_date - b.start_date)
      .filter(
        task => currentDate.getTime() >= task.start_date.getTime() && currentDate.getTime() <= task.end_date.getTime()
      )[0];

    // Custom scrollTo animation
    if (targetTask.end_date < this.ganttUnits[0].semester_end) {
      const { top, left } = this.gantt.getTaskPosition(targetTask);
      this.ganttService.scrollAnimation(this.gantt, left, top, () =>
        (this.document.querySelector(`[task_id='${ targetTask.id }'].gantt_row_task`) as any).click()
      );
    }
  }

  private updateRevisionTime(): void {
    const revisionTask = this.gantt.getTask(this.revisionTimeId);
    const examTask = this.gantt.getTask(this.examTaskId);
    const revisionStartDate = this.getRevisionTimeStartDate();

    revisionTask.start_date = !isNaN(revisionStartDate.getTime()) ? revisionStartDate : revisionTask.start_date;
    revisionTask.end_date = examTask.start_date;
  }

  private parseDate(date: Date): string {
    const parse = this.gantt.date.date_to_str('%d-%m-%Y');
    return parse(date);
  }

  getRevisionTimeStartDate() {
    const tasks = this.gantt.getTaskByTime();
    const result = new Date(tasks[tasks.length - 1].end_date?.getTime());
    result.setDate(result.getDate());
    return result;
  }

  private addDaysToDate(date: Date, days: number) {
    const result = new Date(date.valueOf());
    result.setDate(result.getDate() + days);
    return result;
  }

  private updateGanttDataHandler(data: GanttUnit[]): void {
    // Shows revision time notification
    const revisionTimeDurationFromResponse = data[0]?.left_lessons || 0;
    if (!this.isInitUpdate && this.revisionTimeDuration !== revisionTimeDurationFromResponse) {
      this.openSnackBar(this.revisionTimeDuration, revisionTimeDurationFromResponse);

      this.revisionTimeDuration = revisionTimeDurationFromResponse;
    }
    this.isInitUpdate = false;
  }

  private openSnackBar(oldValue: number, newValue: number): void {
    const snackBarRef = this.snackBar.open(this.ganttService.getRevisionSnackBarText(oldValue, newValue), 'UNDO', {
      duration: 5000
    });
    this.snackBarRefSubscription = snackBarRef
      .onAction()
      .pipe(take(1))
      .subscribe(() => {
        this.gantt.undo();
      });
  }
}
