/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IMovebleItem, directionType } from '../movable-sections.interface';
import { Question } from 'src/app/student/test.model';
import { FormGroup } from '@angular/forms';
import { SubjectTestSelectTopic } from 'src/app/teacher/questions-page/questions-page.interface';

@Component({
  selector: 'curr-movable-item',
  templateUrl: './movable-item.component.html',
  styleUrls: ['./movable-item.component.scss'],
})
export class MovableItemComponent implements OnInit, OnChanges {
  @Input()
  itemData: Question;

  @Input()
  form: FormGroup;

  @Input()
  topics: SubjectTestSelectTopic[] = [];

  @Input()
  itemIndexWithParents: number[];

  @Input()
  itemFirst: boolean;

  @Input()
  itemLast: boolean;

  @Input()
  stepNumber: number;

  @Input()
  dialogMode: 'create' | 'edit';

  @Input()
  isMultiLevel: boolean;

  @Input()
  firstLevelAmount: number ;

  @Output()
  onChangePosition = new EventEmitter<IMovebleItem>();

  @Output()
  onAddNew = new EventEmitter<number[]>();

  questionTitle = 'Question';
  questionNumber: string;
  showActions = true;

  isLeftArrowActive = false;
  isRightArrowActive = false;
  isTopArrowActive = false;
  isBottomArrowActive = false;

  constructor() {}

  get isCreateMode(): boolean {
    return this.dialogMode === 'create';
  }

  ngOnInit() {
    this.setQuestionNumber();
    this.positionChanged();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setQuestionNumber();
    this.positionChanged();
  }

  moveAction(direction: directionType, isActive: boolean) {
    if (isActive) {
      this.onChangePosition.emit({direction, itemIndexs: this.itemIndexWithParents});
      this.positionChanged();
    }
  }

  addNewItem() {
    this.onAddNew.emit(this.itemIndexWithParents);
  }

  setIsShowed(isShowed: boolean) {
    this.showActions = isShowed;
  }

  private positionChanged() {
    this.isLeftArrowActive = !this.isCreateMode ? this.itemIndexWithParents.length > 2 : this.itemIndexWithParents.length > 1;
    this.isRightArrowActive = this.itemIndexWithParents.length < 3 && !this.itemFirst;
    this.isTopArrowActive = !this.itemFirst;
    this.isBottomArrowActive = !this.itemLast;
  }

  private setQuestionNumber() {
    this.questionTitle = this.itemData.is_master ? 'Introduction' : (this.stepNumber === 1 ? 'Mark Scheme' : 'Question');

    if (this.itemIndexWithParents.length === 1)
      this.questionNumber = `${this.itemIndexWithParents[0] + 1}.`;

    if (this.itemIndexWithParents.length === 2)
      this.questionNumber = `${this.itemIndexWithParents[0] + 1}.${this.itemIndexWithParents[1] + 1}.`;

    if (this.itemIndexWithParents.length === 3)
      this.questionNumber = `${this.itemIndexWithParents[0] + 1}.${this.itemIndexWithParents[1] + 1}.${this.itemIndexWithParents[2] + 1}.`;
  }
}
