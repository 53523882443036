<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="card_wrapper cursor" (click)="goToDetails(card, $event)">
  <img class="card_image" (load)="notLoaded = false" src="./assets/images/{{ card.img | lowercase }}.png" alt="math">
  <div style="width: 356px; height: 112px" *ngIf="notLoaded"></div>
  <div class="card_description">
    <div class="card_title-box">
      <div class="card_title mat-h2">{{ card.subject | titlecase }}</div>
      <div class="card_coins">
        <mat-icon class="card_coins__icon" [svgIcon]="'coin-10'"></mat-icon>
      </div>
    </div>
    <curr-progress-bar
      [value]="card.proficientPercentage * 100 || 0"
      [secondValue]="card.couldBeImprovedPercentage * 100 || 0"
      [thirdValue]="card.toBeCompletedPercentage * 100 || 0"
      width="100%"
      height="6px"
      mainColor="#F5F5F6"
      secondColor="#5DB605">
    </curr-progress-bar>
    <div class="activities__subject-steps">
      <span class="activities__subject-step">{{ ((card.proficientPercentage * 100) | number: '1.0-0') || 0 }}% Proficient</span>
      <span class="activities__subject-step">{{ ((card.couldBeImprovedPercentage * 100) | number: '1.0-0') || 0 }}% Could be Improved</span>
      <span class="activities__subject-step">{{ ((card.toBeCompletedPercentage * 100) | number: '1.0-0') || 0 }}% To be completed</span>
    </div>
    <curr-button
      (click)="goToDetails(card, $event)"
      size="big"
      class="card_btn-big"
      color="accent">
      {{ card.continueData.hasStarted ? 'Continue' : 'Begin' }}
    </curr-button>
<!--    <curr-button-->
<!--      *ngIf="card.continueData.hasStarted"-->
<!--      (click)="goToDetails(card, $event)"-->
<!--      size="big"-->
<!--      class="card_btn-big"-->
<!--      color="accent">-->
<!--      Continue-->
<!--    </curr-button>-->
  </div>
</div>


<!--&lt;!&ndash; TODO Previous version&ndash;&gt;-->

<!--<div class="course-info-container">-->
<!--  <div class="head-container left">-->
<!--    <span class="m-b-8 ls mat-h1">-->
<!--      {{ card.subject }}-->
<!--    </span>-->

<!--    <span class="tertiary" *ngIf="!isDetailView">-->
<!--      <span class="m-r-8">COURSE ID:</span>-->
<!--      &lt;!&ndash; TODO: ADD LINK &ndash;&gt;-->
<!--      <a class="link" href="" [routerLink]="'/subject-details/' + card.courseId" target="_blank">{{ card.course }}</a>-->
<!--    </span>-->

<!--    <span class="teacher" *ngIf="isDetailView">-->
<!--      <span class="m-r-8">Teacher: </span>-->
<!--      &lt;!&ndash; TODO: ADD LINK &ndash;&gt;-->
<!--      <a class="link" href="" [routerLink]="" target="_blank">{{ card.teacher }}</a>-->
<!--    </span>-->

<!--    <span class="m-t-24" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="center" *ngIf="isDetailView && card.nextTest">-->
<!--      <mat-icon [class]="'calendar'" [svgIcon]="'calendar'" [color]="'primary'"></mat-icon>-->
<!--      <span class="mat-body-2">Next Topic test: {{ card.nextTest }}</span>-->
<!--    </span>-->
<!--  </div>-->

<!--  <div class="info-container">-->
<!--    &lt;!&ndash; BLOCKS &ndash;&gt;-->
<!--    <ng-container *ngFor="let block of blocks; let first = first; let last = last">-->
<!--      <div fxLayoutGap="4px" fxLayout="column" fxLayoutAlign="start start" [class.p-t-0]="first" [class.p-t-16]="!first"-->
<!--        [class.last]="last" class="p-b-16 has-bottom-border">-->
<!--        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">-->
<!--          <span class="mat-h4 m-b-0">{{ block.title }}</span>-->
<!--          <mat-icon class="cursor-pointer" *ngIf="block.icon" [matTooltip]="infoIconText$ | async"-->
<!--            matTooltipPosition="above" [class.info-icon]="block.icon === 'info'" [svgIcon]="block.icon">-->
<!--          </mat-icon>-->
<!--        </div>-->

<!--        <curr-progress-bar class="curr-progress-bar" [class.first]="first" [value]="block?.progressBar.value"-->
<!--          borderRadius="4px" width="100%" height="4px">-->
<!--        </curr-progress-bar>-->

<!--        <span class="bottom-label mat-body-2 m-b-8">{{ block.bottomTitle.label }}</span>-->
<!--        <span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="cursor-pointer mat-body-2"-->
<!--          *ngIf="block?.hnt">-->
<!--          <mat-icon [class]="block?.hnt?.icon" [color]="block?.hnt?.color" [svgIcon]="block?.hnt?.icon"></mat-icon>-->
<!--          <span>{{ block?.hnt?.label }}</span>-->
<!--        </span>-->
<!--      </div>-->
<!--    </ng-container>-->

<!--    <div fxLayout="column" class="full-width" *ngFor="let b of buttons; let firstBtn = first; let lastBtn = last">-->
<!--      <curr-button ngClass.lt-sm="smaller-font-size" class="m-b-8 full-width curr-button" (btnClick)="b.callback()"-->
<!--        [isDisabled]="b.isDisabled" [ngClass]="{ 'm-t-24': firstBtn }" [class.lastBtn]="lastBtn">-->
<!--        {{ b.label }}-->
<!--      </curr-button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
