/**
 * Created by Alex Poh. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curr-school-admin',
  templateUrl: './school-admin.component.html',
  styleUrls: ['./school-admin.component.scss']
})
export class SchoolAdminComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
