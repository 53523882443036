<!-- Created by Maxim B. on 10/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="disabled-input-container" [ngClass]="[!disabled ? 'is-enabled' : '']">
  <div class="disable-layer" *ngIf="disabled"></div>
  <curr-input
    [placeholder]="placeholder"
    [userHint]="userHint"
    [controlName]="controlName"
    [formGroup]="formGroup"
    [prefix]="prefix"
    [inputType]="inputType"
    [numberInputWidth]="numberInputWidth"
    [isBordered]="isBordered"
    [isCentered]="isCentered"
    [isGreyBackground]="isGreyBackground"
    (valueChange)="updateValue($event)"
    [errorMessage]="errorMessage">

  </curr-input>

</div>
