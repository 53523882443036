<!-- Created by Maxim B. on 20/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <div class="m-b-40">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="m-b-24" fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-h1">School Year Settings</h1>
    <span class="mat-body-1" *ngIf="isNew">{{ selectedTabIndex + 1 }}/3</span>
    <span class="mat-body-1" *ngIf="!isNew">{{ ys.yearLabel }}</span>
  </div>

  <div *ngIf="!isNew" class="tabs-container m-b-32" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-tab-group
      class="custom-ink-size"
      [dynamicHeight]="false"
      fxFlex="80"
      mat-align-tabs="start"
      animationDuration="0ms"
      (selectedIndexChange)="tabSelectedIndexChange($event)"
      [selectedIndex]="selectedTabIndex"
    >
      <mat-tab *ngFor="let tab of customTabs; let i = index">
        <ng-template mat-tab-label>
          <span>{{ tab.name }}</span>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <button class="create-table-btn" mat-button (click)="save()">
      SAVE
    </button>
  </div>

  <!--  STEP 1-->
  <ng-container *ngIf="selectedTabIndex == 0">
    <curr-ys-step-one
      [(ys)]="ys"
      [isInPopup]="false"
      (onNextCancel)="goToNextStep($event)"
      [isNew]="isNew"
    ></curr-ys-step-one>
  </ng-container>

  <!--  STEP 2-->
  <ng-container *ngIf="selectedTabIndex == 1">
    <curr-ys-step-two
      [(ys)]="ys"
      [isInPopup]="false"
      (onNextCancel)="goToNextStep($event)"
      [isNew]="isNew"
    ></curr-ys-step-two>
  </ng-container>

  <!--  STEP 3-->
  <ng-container *ngIf="selectedTabIndex == 2">
    <curr-ys-step-three
      [(ys)]="ys"
      [isInPopup]="false"
      (onNextCancel)="goToNextStep($event)"
      [isNew]="isNew"
    ></curr-ys-step-three>
  </ng-container>
</div>
