<!-- Created by Maxim B. on 03/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-card [ngClass]="{'no-bottom-padding': selectedPeriod}">


  <mat-card-content>
    <div class="header-container"
         fxLayout="row"
         fxLayoutAlign="space-between center">
      <div class="class-select"
           fxLayout="row"
           fxLayoutAlign="start center">
        <div class="select-container">
          <curr-years-picker [disabledYears]="disabledYears"
                             [(options)]="yearPickerOptions"
                             (onAddNewOption)="addNewOption($event)"
                             (onSelectPeriod)="setCurrentPeriod($event)"></curr-years-picker>
        </div>

      </div>
    </div>

    <div class="main-content-container" fxLayout="row wrap" fxLayoutGap="16px">
      <ng-container *ngIf="!selectedPeriod">Please select years period</ng-container>
        <ng-container *ngIf="selectedPeriod">
        <div class="add-new-period-container period-container"
             fxLayout="row" fxLayoutAlign="center center" (click)="createPeriod()">
          <mat-icon>add_circle_outline</mat-icon>
          <label class="add"> ADD PERIOD </label>
        </div>

        <div class="period-container period-item"
             fxLayout="row" fxLayoutAlign="space-between center"
             *ngFor="let period of selectedPeriod.periods;let i = index">
          <div class="name-and-date" fxLayout="column" fxLayoutAlign="space-between start">
            <p class="period-name">{{period.name}}</p>
            <div class="dates">
              <p class="period-dates">
                {{period.startDate| date: 'dd.mm.yyyy'}}-{{period.endDate | date: 'dd.mm.yyyy'}}
              </p>
            </div>
          </div>
          <div class="btns-container" >
            <mat-icon (click)="deletePeriod(i)">delete</mat-icon>
            <mat-icon (click)="editPeriod(period, i)">edit</mat-icon>

          </div>
        </div>
      </ng-container>

    </div>


  </mat-card-content>
</mat-card>

