<!--
 Created by Daniil Vat. on 18/2/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="wrapper">
  <div class="panel" [ngClass]="{ open: isShowed }" (clickOutside)="closeDropDown()">
    <div class="custom-select" (click)="toggleDropdown()" [attr.data-titleName]="title">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text panel-header-left">
          <div header class="panel-title">
            {{title}}
          </div>
          <div class="panel-actions" *ngIf="isShowed && stepNumber !== 1 && itemIndexWithParents.length < 3">
            <mat-slide-toggle
            [color]="'primary'"
            [checked]="isIntroduction"
            [disabled]="true"
            (click)="stopPropagationIntroduction($event)">
              <span class="editor-toggle">Introduction</span>
            </mat-slide-toggle>
            <div class="remove-item">
              <mat-icon *ngIf="showRemoveBtn" class="remove-item-btn" [svgIcon]="'close-dialog'" (click)="removeItem($event)"></mat-icon>
            </div>
          </div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="arrow-wrapper m-r-24 m-l-8"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
