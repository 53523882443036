/**
 * Created by Daniil Vat. on 28/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { IUnderPerformingStudent } from '../../interfaces';

@Component({
  selector: 'curr-under-performing-students',
  templateUrl: './under-performing-students.component.html',
  styleUrls: ['./under-performing-students.component.scss']
})
export class UnderPerformingStudentsComponent implements OnInit {
  @Input() set data(value: IUnderPerformingStudent[]) {
    this.receivedData = value;
    this.displayedData = value?.slice(this.offset, this.limit);
  }

  offset = 0;
  limit = 4;

  receivedData: IUnderPerformingStudent[];
  displayedData: IUnderPerformingStudent[];

  constructor() {}

  ngOnInit() {}

  showMore() {
    this.offset += 4;
    this.displayedData = [...this.displayedData, ...this.receivedData?.slice(this.offset, this.limit + this.offset)];
  }
}
