/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { OnDestroy, OnInit, TemplateRef, ViewContainerRef, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from '../user.service';
import { User } from '../user';

@Directive()
export abstract class AbstractHasAccessDirective implements OnInit, OnDestroy {
  isVisible = false;
  stop$ = new Subject();

  protected constructor(
    protected viewContainerRef: ViewContainerRef,
    protected templateRef: TemplateRef<any>,
    public userService: UserService
  ) {}

  abstract hasAccess(user: User): Observable<boolean>;

  ngOnInit(): void {
    this.userService.getCurrentUser().subscribe(user => {
      this.hasAccess(user).subscribe(res => {
        if (res) {
          if (!this.isVisible) {
            this.isVisible = true;
            this.viewContainerRef.createEmbeddedView(this.templateRef);
          }
        } else {
          this.isVisible = false;
          this.viewContainerRef.clear();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.stop$.next(true);
  }
}
