/*
 * Created by Volodymyr Mak. on 25/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProfileFieldsNames } from '../profile-card.model';

interface CtrlsList {
  email: FormControl,
  phone: FormControl,
  personalID: FormControl,
  country: FormControl,
  school: FormControl,
  year: FormControl,
}

@Injectable()
export class UserProfileService {
  fromControlsDictionary: CtrlsList;
  formGroup: FormGroup;
  namesToDisplay: string[];
  formValues: any = {};

  constructor(
    private _fb: FormBuilder,
  ) {
    this.fromControlsDictionary = {
      [ProfileFieldsNames.EMAIL]: this._fb.control('', [Validators.required]),
      [ProfileFieldsNames.PHONE]: this._fb.control('', [Validators.required]),
      [ProfileFieldsNames.PERSONAL_ID]: this._fb.control('', [Validators.required]),
      [ProfileFieldsNames.COUNTRY]: this._fb.control('', [Validators.required]),
      [ProfileFieldsNames.SCHOOL]: this._fb.control('', [Validators.required]),
      [ProfileFieldsNames.YEAR]: this._fb.control('', [Validators.required]),
    };
  }

  createFormGroup(ctrlNamesToDisaplay: {name: string, label: string}[]): FormGroup {
    this.formGroup = this._fb.group({});
    ctrlNamesToDisaplay.forEach((formControl: {name: string, label: string}) => {
      this.formGroup.registerControl(formControl.name, this.fromControlsDictionary[formControl.name]);
    })

    return this.formGroup;
  }

  setFormValues(formValues: {[key:string]: any}) {
    this.formGroup.setValue(formValues);
  }

}
