/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { IAiKeys, IAiSubjectSettings, IAiSubjectSettingsParams, IDataToSave } from './managing-api-keys.interface';

@Injectable()
export class ManagingApiKeysService {

  constructor(private httpClient: ApplicationHttpClient){}

  getAiKeys(): Observable<IAiKeys> {
    return this.httpClient.get<IAiKeys>(`/ai-keys`);
  }

  saveAiKeys(data: { type: string; key: string; appId: string | null }): Observable<void> {
    let appIdParam = '';
    if (data.appId) appIdParam = `&appId=${data.appId}`;
    return this.httpClient.post<void>(`/ai-keys?type=${data.type}&key=${data.key}${appIdParam}`, {});
  }

  getAiSubjectSettings(o: IAiSubjectSettingsParams): Observable<IAiSubjectSettings> {
    let url = `/ai-subject-settings?`;
    o.rowsCount ? (url += `&size=${o.rowsCount}`) : (url += `&size=10000`);
    o.rowOffset ? (url += `&page=${o.rowOffset}`) : (url += `&page=0`);

    return this.httpClient.get<IAiSubjectSettings>(url);
  }

  saveAiSubjectSettings(data: IDataToSave, applyToAll: boolean): Observable<void> {
    return this.httpClient.put<void>(`/ai-subject-settings?subject=${data.subjectName}&applyToAll=${applyToAll}`, data.children);
  }
}
