import { KeyValue } from '../../api/models/key-value';

export interface CreateSchemeOfWorkModel {
  id?: string;
  examination_board_id: string;
  scheme_work_title: string;
  study_year_id: string;
  subject: string;
}

export class CreateCurriculumModel {
  id?: string;
  curriculum_type = 'CUSTOM';
  is_published = false;
  master_curriculum_id;
  sow_descriptions: KeyValue[] = [];
  scheme_work_id; // THIS VALUE IS NOT HARDCODED
}

export interface Subject {
  id: string;
  subjectId: string;
}
