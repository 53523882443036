<mat-card class="card">

  <p class="overline m-b-0">TOPIC TEST</p>
  <p class="mat-h2">{{ct.test_name || 'No name'}}</p>

  <mat-card-content class="content">
    <div class="info mat-body-2">
      <div>Time: 1 hour</div>
      <div>Possible attempts: 1</div>
       <div>Date: {{ct.start_time | date : 'dd/MM/yyyy'}}</div>
    </div>

    <ng-container *ngTemplateOutlet="dropdown"></ng-container>

    <div class="view-answer" *ngIf="isViewed">
      <curr-button class="curr-button" variant="contained" color="primary">VIEW ANSWERS</curr-button>
    </div>

    <div fxLayoutAlign="center center" fxLayout="row"  class="icon-group upload-icon-wrapper">
      <curr-button class="download-btn curr-button" variant="text" icon="trash" (click)="deleteCT()"></curr-button>
      <curr-button class="download-btn curr-button" *ngIf="!ct.is_offline"
                   variant="text" icon="edit" (click)="editCT()"></curr-button>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #dropdown>
  <div class="dropdown m-b-16" [class.online]="isOnline" [class.offline]="!isOnline" [ngClass]="{ open: isShowed }">
    <div class="container">
      <div class="custom-select__trigger">
        <div class="mat-h4 m-b-0 text status">
          {{ status[0] }}
        </div>
      </div>
    </div>
    <div class="custom-options" *ngIf="isShowed">
      <span class="mat-h4 m-b-0 custom-option status inside" (click)="selectValue($event)">
        {{ status[1] }}
      </span>
    </div>
  </div>
</ng-template>
