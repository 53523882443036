<!--
 Created by Dmytro Sav. on 4/15/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-curriculum-management">
  <div class="m-b-24" fxLayout="row" fxLayoutAlign="space-between center">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>

    <curr-button
      variant="contained"
      fxFlex="133px"
      color="primary"
      size="small"
      [matMenuTriggerFor]="addCurriculumMenu"
    >
      ADD NEW
    </curr-button>
  </div>
  <mat-menu #addCurriculumMenu="matMenu">
    <button mat-menu-item (click)="showImportPopup()">Import from file</button>
    <button (click)="showCreateManuallyPopup()" mat-menu-item>Create manually</button>
  </mat-menu>

  <!-- <div class="header-container main-header m-b-24">
    <div class="tabs-container">
      <mat-tab-group
        class="custom-ink-size"
        [dynamicHeight]="false"
        mat-align-tabs="start"
        animationDuration="0ms"
        (selectedIndexChange)="tabSelectedIndexChange($event)"
        [(selectedIndex)]="currSelectedTabIndex"
      >
        <mat-tab [label]="'COURSES'"></mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <curr-course-filters (filterParamsChanged)="filterChanged($event)"></curr-course-filters> -->

  <div *ngIf="currSelectedTabIndex === 0">
    <div>
      <div class="block m-b-48" *ngIf="publishedCurriculums.length > 0">
        <div class="header-container m-b-16">
          <h2 class="mat-h2 m-l-16 ls">Published</h2>
          <mat-divider></mat-divider>
        </div>
        <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch">
          <div *ngFor="let published of publishedCurriculums; index as i">
            <div
              class="sun-container full-width"
              fxLayout="column"
              fxLayoutAlign="end stretch"
              [@simpleFadeAnimation]="'in'"
            >
              <!-- <div
                *ngIf="publishedCurriculumData[i]?.curriculumManagementModel"
                class="sunburst-animation full-width"
                [@simpleFadeAnimation]="'in'"
                [ngClass]="sunburstContainer['isOpened'] ? 'opened' : 'minimized'"
              >
                <div
                  #sunburstContainer
                  (mouseover)="onMouseOver(publishedCurriculumData[i].curriculumManagementModel, sunburstContainer)"
                  (mouseout)="onMouseOut(publishedCurriculumData[i].curriculumManagementModel, sunburstContainer)"
                  [@openMinimized]="sunburstContainer['isOpened'] ? 'opened' : 'minimized'"
                >
                  <div
                    class="overlay"
                    *ngIf="!sunburstContainer['isOpened']"
                    (click)="
                      openSunburst(sunburstContainer, publishedCurriculumData[i].curriculumManagementModel, $event)
                    "
                  ></div>
                  <div class="curr-sunburst-wrapper">
                    <curr-sunburst
                      [sunburstApiResponse]="publishedCurriculumData[i].sunburst"
                      [isShadowed]="isShadowedMap.get(publishedCurriculumData[i].curriculumManagementModel)"
                      #sunburst
                    ></curr-sunburst>
                  </div>
                  <curr-button
                    class="close-button"
                    (btnClick)="
                      closeSunburst(sunburst, publishedCurriculumData[i].curriculumManagementModel, sunburstContainer)
                    "
                    variant="contained"
                    size="big"
                    >HIDE DIAGRAM
                    <mat-icon svgIcon="arrow-down" class="icon"></mat-icon>
                  </curr-button>
                </div>
                <curr-button
                  *ngIf="!sunburstContainer['isOpened']"
                  [style]="btnViewStyle"
                  (click)="
                    openSunburst(sunburstContainer, publishedCurriculumData[i].curriculumManagementModel, $event)
                  "
                  variant="outlined"
                  color="primary"
                  size="small"
                  class="view-sunburst m-r-16 m-b-8"
                >
                  <mat-icon class="arrow-up" svgIcon="arrow-down"></mat-icon>
                  VIEW
                </curr-button>
              </div> -->

              <div class="mat-app-background card-container" [@simpleFadeAnimation]="'in'">
                <curr-curriculum-managment-card
                  [card]="{
                    name: published.curriculum_name,
                    id: published.curriculum_id,
                    totalLessons: published.total_lessons,
                    Author: published.owner_name,
                    isInComplete: false
                  }"
                  [type]="'published'"
                ></curr-curriculum-managment-card>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutAlign="center center">
            <div class="load-more-btn" *ngIf="!isPublishedLoading">
              <curr-button class="card-view-all-btn" size="big" (click)="initDiagramsLoading(diagramLoadingLimit)">Load More</curr-button>
            </div>
            <curr-loader [isLoading]="isPublishedLoading" diameter="48"></curr-loader>
          </div>
        </div>
      </div>

      <div class="block" *ngIf="unPublishedCurriculums.length > 0">
        <div class="header-container m-b-16">
          <h2 class="mat-h2 m-l-16 m-b-16 ls">My Drafts</h2>
          <mat-divider></mat-divider>
        </div>
        <div class="items-container">
          <ng-container *ngFor="let card of unPublishedCurriculums">
            <curr-curriculum-managment-card
              [card]="{
                name: card.curriculum_name,
                id: card.curriculum_id,
                totalLessons: card.total_lessons,
                Author: card.owner_name,
                isInComplete: false
              }"
              [type]="'draft'"
              (publish)="onPublish(card)"
              (delete)="onDelete(card)"
            ></curr-curriculum-managment-card>
          </ng-container>
        </div>
        <div class="load-more-btn">
          <curr-button class="card-view-all-btn" size="big" (click)="loadMoreUnpublished()">Load More</curr-button>
        </div>
        <!-- <div>
          <curr-button (btnClick)="loadMoreUnpublished()" variant="text">Load more</curr-button>
        </div> -->
      </div>
    </div>
    <curr-loader [isLoading]="isUnpublishedLoading" diameter="48"></curr-loader>
  </div>

  <div *ngIf="currSelectedTabIndex === 1">
    <div fxLayout="row" fxLayoutAlign="start center" class="m-b-16">
      <curr-button variant="text" (btnClick)="createQuestion()" icon="plus" class="m-r-24">
        CREATE NEW QUESTION
      </curr-button>
      <curr-button variant="text" (btnClick)="importQuestions()" icon="plus" class="">
        IMPORT QUESTIONS FROM A FILE
      </curr-button>
    </div>
  </div>

  <ng-container *ngIf="currSelectedTabIndex === 1">
    <curr-question-card
      *ngFor="let q of questions; let i = index"
      [question]="q"
      [questionNumber]="i + 1"
    ></curr-question-card>
  </ng-container>
</div>
