import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CumulativeTest } from '../../../shared-pages/subject-details/api-subject-details';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { RestApiService, restApiServiceCreator } from '../../api/rest-api.service';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'curr-cumulative-test-card',
  templateUrl: './cumulative-test-card.component.html',
  styleUrls: ['./cumulative-test-card.component.scss']
})
export class CumulativeTestCardComponent implements OnInit {

  isShowed = false;
  restApiService:RestApiService<CumulativeTest>;
  @Input() ct: CumulativeTest;
  @Input() isOnline = true;
  @Input() isViewed = false;
  @Output() onCardDelete = new EventEmitter<any>();

  status = ['Online', 'Practical/Offline'];

  constructor(public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private httpClient: ApplicationHttpClient) {
  }


  selectValue(event) {
  }

  ngOnInit() {
    if (!this.isOnline) {
      this.status.reverse();
    }
    this.restApiService = restApiServiceCreator<CumulativeTest>('curriculumCumTests')(this.httpClient);
  }

  deleteCT() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        questionTitle: `Delete this ${this.ct.test_name || 'No name'} test?`,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.restApiService.remove(this.ct.id).subscribe(resp => {
          this.onCardDelete.emit();
        })
      }
    });
  }

  editCT() {
    this.router.navigate(['edit-test', this.route.snapshot.params.paperId, this.ct.id, this.ct.school_year_course_id]);
  }
}
