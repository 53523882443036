/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'curr-edit-paper-name-popup',
  templateUrl: './edit-paper-name-popup.component.html',
  styleUrls: ['./edit-paper-name-popup.component.scss']
})
export class EditPaperNamePopupComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditPaperNamePopupComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {}

  form: FormGroup;

  public get name(): FormControl {
    return this.form.get('name') as FormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.data, [Validators.required, Validators.maxLength(100)]]
    });
  }

  saveName() {
    this.dialogRef.close(this.form.get('name').value);
  }
}
