/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'curr-statistic-expansion-panel',
  templateUrl: './statistic-expansion-panel.component.html',
  styleUrls: ['./statistic-expansion-panel.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class StatisticExpansionPanelComponent implements OnInit {
  @Input() title: string;
  @Input() classSpanName: string;
  @Input() isDisabled? = false;
  @Input() isShowed = true;
  @Input() status: 'approved' | 'pending';
  isError = false;
  inEditMode = false;

  constructor() {}

  ngOnInit() {}

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }
}
