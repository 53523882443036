/**
 * Created by Daniil Vat. on 28/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { IWrongAnswerExample } from '../../interfaces';

@Component({
  selector: 'curr-answers-examples',
  templateUrl: './answers-examples.component.html',
  styleUrls: ['./answers-examples.component.scss']
})
export class AnswersExamplesComponent implements OnInit {
  @Input() data: IWrongAnswerExample[];
  constructor() {}

  ngOnInit() {}

  generateListItemText({ firstName, lastName, answerMessage }: IWrongAnswerExample): string {
    return `${firstName} ${lastName} responded with ${answerMessage ? answerMessage : 'no answer'}`;
  }
}
