<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-8">
  <h2 class="mat-h2 m-b-0" [class.white]="isTestSaved" fxFlex>Publish test?</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close('cancel')"></curr-icon-button>
</div>

<div *ngIf="!isTestSaved">
  <div fxLayout="column" fxLayoutAlign="center center" class="m-b-24">
    <span class="full-width">The test will be available to students from {{ fromDate | date : 'dd MMMM yyyy hh:mm a' }} to {{ toDate | date : 'dd MMMM yyyy hh:mm a' }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <curr-button variant="text" size="big" (btnClick)="saveTest('draft')">SAVE DRAFT</curr-button>
    <curr-button variant="contained" class="m-l-16" size="big" (btnClick)="saveTest('publish')">PUBLISH</curr-button>
  </div>

</div>


<div *ngIf="isTestSaved" fxLayout="column" fxLayoutAlign="center center">
  <curr-loader [diameter]="40" [isLoading]="testInSavingProgress" *ngIf="testInSavingProgress"></curr-loader>

  <mat-icon *ngIf="!testInSavingProgress" svgIcon="check" class="all-is-ok-icon"></mat-icon>

  <h1 class="success-msg mat-h1" *ngIf="saveVariant == 'publish'">
    You have successfully published topic test
  </h1>
  <h1 class="success-msg mat-h1" *ngIf="saveVariant == 'draft'">
    You have successfully saved topic test as a draft</h1>
</div>

