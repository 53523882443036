<!--
 Created by Alex Poh. on 14/07/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<ng-container *ngIf="chartFilter">
  <div fxFlex="130px">
    <curr-dropdown-component-v2
      [options]="chartFilter.subjects"
      placeholder="Select subject"
      (selectedValueChange)="selectSubject($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="130px">
    <curr-dropdown-component-v2
      [options]="chartFilter.levels"
      placeholder="Select level"
      (selectedValueChange)="selectLevel($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="130px">
    <curr-dropdown-component-v2
      [options]="chartFilter.courses"
      placeholder="Select forms"
      (selectedValueChange)="selectCourse($event)"
    ></curr-dropdown-component-v2>
  </div>
</ng-container>
