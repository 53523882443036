<!--Created by Maxim Bon. on 01/05/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<!--
 Created by Volodymyr Mak. on 3/7/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<ng-content *ngTemplateOutlet="dialogType === 'timetable' ? timeTableContainer : course"></ng-content>

<ng-template #timeTableContainer>
  <h2 class="mat-h2" class="header-create" fxLayoutAlign="start">
    <ng-container *ngIf="currentStep === 0 || currentStep === 1">Import timetable</ng-container>
    <ng-container *ngIf="currentStep === 2">Import successfully done!</ng-container>
  </h2>

  <div class="step-0 container" fxLayout="column" fxLayoutAlign="center" *ngIf="currentStep === 0">
    <p class="explain-text mat-body-2" fxLayoutAlign="start">
      Import .xlsx file of your timetable which you want to import into the system.
    </p>

    <div fxLayoutAlign="start center">
      <a href="../../../../assets/shedule.xlsx" download="example.xlsx" class="no-underline">
      <curr-button icon="file" class="m-b-32" variant="outlined">
        download timetable document example
      </curr-button></a>
    </div>

    <curr-upload-files
      class="add-paddings curr-upload-files"
      type="edit-resource"
      [hint]="''"
      title="Drop file here"
      [displayPhotoBtn]="false"
      [automaticallyImport]="false"
      [enableProgressbard]="false"
      (onloadedFile)="onLoadedFile($event)"
      [formats]="['.xlsx']"
    ></curr-upload-files>

    <div fxLayout="column" class="m-t-24" fxLayoutGap="8px">
      <span>Timetable period:</span>
      <div fxLayout="row" fxFlexAlign="start center" fxLayoutGap="8px">
        <curr-dropdown
          class="curr-dropdown custom-w"
          [placeholder]="academicYearList[0]?.label"
          (valueChange)="changeAcademicYearId($event)"
          [options]="academicYearList"
        ></curr-dropdown>
        <curr-dropdown
          class="curr-dropdown custom-w"
          placeholder="All semesters"
          (valueChange)="changeSemesterCount($event)"
          [options]="semester"
        ></curr-dropdown>
      </div>
    </div>

    <div class="btns" fxLayout="row" fxLayoutAlign="end center">
      <curr-button variant="text" (btnClick)="dialogRef.close()">Cancel</curr-button>
      <curr-button variant="contained" [isDisabled]="isDisabled" (btnClick)="importTimeTable()">Import</curr-button>
    </div>
  </div>

  <div
    class="step-1 container"
    fxLayout="column"
    fxLayoutAlign="center center"
    *ngIf="currentStep === 1 || currentStep === 3"
  >
    <curr-loader [isLoading]="true" [diameter]="48"></curr-loader>
  </div>

  <div class="step-2 container" fxLayout="column" fxLayoutAlign="center" *ngIf="currentStep === 2">
    <div class="step-2 container" fxLayout="column" fxLayoutAlign="center" *ngIf="currentStep === 2">
      <p class="explain-text mat-body-2" fxLayoutAlign="start">
        The course from your file has been successfully imported into the system. Please review it and confirm that
        everything is correct:
      </p>
      <div class="timetable-preview" fxLayout="column" fxLayoutGap="24px">
        <div
          class="table-wrapper"
          *ngFor="let week of timeTableList | keyvalue; let i = index"
          fxLayout="column"
          fxLayoutGap="4px"
        >
          <span class="mat-subheading-1" *ngIf="weeksCount > 1">{{ i + 1 }} week:</span>
          <table aria-describedby="weeks-desc">
            <tr class="days">
              <th class="mat-subheading-1" *ngFor="let day of days" scope="col">{{ day.slice(0, 3) }}</th>
            </tr>
            <tr class="courses">
              <td *ngFor="let day of days">
                <div *ngFor="let dayShudule of week.value.days[day]" class="lesson mat-body-2">
                  <b>Course:</b> <br/>
                  {{ dayShudule.course }}
                  <br/><b>Subject:</b> <br/>
                  {{ dayShudule.lesson }}
                  <br/><b>Location:</b> <br/>
                  {{ dayShudule.location }}
                  <br/><b>Lesson:</b> {{ dayShudule.lessonNum }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between start" class="p-t-16">
        <div *ngIf="teacherUnClaimedCoursesId.length" fxLayout="column" fxLayoutAlign="start start" class="create-courses">
          <div>To be able to save timetable, please create this courses first:</div>
          <div>

            <curr-button class="unclaimed-course m-b-4" (click)="createCourse(item)"
                         variant="outlined" color="warn"
                  *ngFor="let item of teacherUnClaimedCoursesId; let i = index">
              Create {{ item }} course
            </curr-button>
          </div>
        </div>
        <div *ngIf="!teacherUnClaimedCoursesId.length" fxLayout="column" fxLayoutAlign="start start" class="create-courses">
        </div>
        <div fxLayoutAlign="end center">
          <curr-button variant="text" (btnClick)="dialogRef.close()">redo import</curr-button>
          <curr-button (btnClick)="saveTimetable()" [isDisabled]="teacherUnClaimedCoursesId.length > 0">
            confirm and add to system
          </curr-button>
        </div>
      </div>
    </div>
  </div>

  <div class="step-4 container" fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 4">
    <mat-icon [inline]="true" class="icon-success m-b-40" svgIcon="icon-success-checkmark"></mat-icon>
    <p class="explain-text mat-body-1">
      Successfully uploaded!
    </p>
    <div class="btns" fxLayout="row" fxLayoutAlign="center center">
      <curr-button variant="contained" (btnClick)="dialogRef.close()">Close</curr-button>
    </div>
  </div>

  <div class="step-5 container" fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 5">
    <mat-icon [inline]="true" class="icon-failed m-b-40" svgIcon="exclamation-mark"></mat-icon>
    <p class="explain-text mat-body-1 m-b-16">Something went wrong. Please, try again later <br/></p>
    <strong>;(</strong>
    <div class="btns" fxLayout="row" fxLayoutAlign="center center">
      <curr-button variant="contained" (btnClick)="dialogRef.close()">Close</curr-button>
    </div>
  </div>
</ng-template>

<ng-template #course>
  <h2 class="mat-h2" class="header-create" fxLayoutAlign="start">
    <ng-container *ngIf="currentStep === 0 || currentStep === 1">Import scheme of work</ng-container>
    <ng-container *ngIf="currentStep === 2">Import successfully done!</ng-container>
  </h2>

  <div class="step-0 container" fxLayout="column" fxLayoutAlign="center" *ngIf="currentStep === 0">
    <p class="explain-text mat-body-2" fxLayoutAlign="start">
      Import .xlsx file of scheme which you want to import into the system
    </p>

    <curr-upload-files
      class="add-paddings curr-upload-files"
      type="edit-resource"
      [hint]="''"
      title="Drop file here"
      [displayPhotoBtn]="false"
      [automaticallyImport]="false"
      [enableProgressbard]="false"
      (onloadedFile)="onLoadedFile($event)"
      [formats]="['.xlsx']"
    ></curr-upload-files>

    <div class="btns" fxLayout="row" fxLayoutAlign="end center">
      <curr-button variant="text" (btnClick)="dialogRef.close()">Cancel</curr-button>
      <curr-button variant="contained" [isDisabled]="isDisabled" (btnClick)="importFile()">Import</curr-button>
    </div>
  </div>

  <div class="step-1 container" fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 1">
    <curr-loader [isLoading]="true" [diameter]="48"></curr-loader>
  </div>

  <div class="step-2 container" fxLayout="column" fxLayoutAlign="center" *ngIf="currentStep === 2">
    <p class="explain-text mat-body-2" fxLayoutAlign="start">
      The course from your file has been successfully imported into the system
    </p>
    <div class="btns" fxLayout="row" fxLayoutAlign="end center">
      <curr-button variant="text" (btnClick)="viewImport()">view imported process</curr-button>
      <curr-button variant="contained" [isDisabled]="isDisabled" (btnClick)="dialogRef.close()">done</curr-button>
    </div>
  </div>

  <div class="step-3 container" fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 3">
    <mat-spinner class="m-b-40"></mat-spinner>
    <p class="explain-text mat-body-2">
      Importing... please wait.
      <br/>
      Time left: 11 min.
    </p>
    <div class="btns" fxLayout="row" fxLayoutAlign="center center">
      <curr-button variant="contained" (btnClick)="stopImport()">Stop Import</curr-button>
    </div>
  </div>

  <div class="step-4 container" fxLayout="column" fxLayoutAlign="center center" *ngIf="currentStep === 4">
    <mat-icon [inline]="true" class="icon-success m-b-40" svgIcon="icon-success-checkmark"></mat-icon>
    <p class="explain-text mat-body-2">
      Curriculum successfully uploaded!
    </p>
    <div class="btns" fxLayout="row" fxLayoutAlign="center center">
      <curr-button variant="contained" (btnClick)="createManually()">View</curr-button>
    </div>
  </div>
</ng-template>
