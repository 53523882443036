import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { map } from 'rxjs/operators';
import { StudentGrade } from '../../components/student-dashboard-chart/student-dashboard-chart.component';

@Injectable({
  providedIn: 'root'
})
export class LoadGradesStatisticService {

  constructor(private httpClient: ApplicationHttpClient
  ) {
  }

  getMonthStatistic(study_level_id: string = '',
                    fromDate: string = '2019-06-20', toDate: string = '2025-06-23') {
    return this.httpClient.post(`/studentProcedure/studentStatDaily`, {
      study_level_id,
      period_from: fromDate,
      period_to: toDate
    }).pipe(
      map((resp: any) => this.addPeriodPropertyToEachItem(resp.data.items))
    );
  }

  getYearStatistic(study_level_id: string = '', period: 'YEAR' | 'SEMESTER' = 'YEAR', fromDate: string = '2019-06-20',
                   toDate: string = '2025-06-23') {
    return this.httpClient.post(`/studentProcedure/studentStatMonthly`, {
      period_from: fromDate,
      period_to: toDate,
      study_level_id,
      stat_period: period
    }).pipe(
      map((resp: any) => this.addPeriodPropertyToEachItem(resp.data.items))
    );
  }

  private addPeriodPropertyToEachItem(arr: StudentGrade[]): StudentGrade[] {
    return arr.map(i => {
      return {
        ...i,
        courseId: i.courceId,
        period: `${i.ctMonth}-${i.ctDay ? i.ctDay.toString().padStart(2, '0') : '01'}-${i.ctYear}`
      };
    });
  }
}
