<!--
 Created by Daniil Vat. on 7/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<div class="m-b-32" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column" fxLayoutAlign="center">
    <h2 class="mat-display-2 m-b-8" *ngIf="!isTitleLoading; else loader">
      {{ title }}
    </h2>

  </div>

  <curr-button size="big"  variant="contained" [routerLink]="navigationLink">VIEW ANSWERS</curr-button>
</div>

<curr-report-stats [data]="reportStats"></curr-report-stats>

<div class="m-b-16">
  <curr-expansion-panel title="Questions most commonly answered incorrectly">
    <div *ngIf="!(mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length)">
      There is no data</div>
    <div *ngIf="(mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length)">
      <curr-hardest-questions [data]="mostIncorrectQuestions?.hardestQuestions"></curr-hardest-questions>
      <div fxLayout="row">
        <div fxFlex="26%"></div>
        <div fxLayout="column">
          <curr-answers-examples [data]="mostIncorrectQuestions?.wrongAnswersExamples"></curr-answers-examples>
        </div>
      </div>
    </div>
  </curr-expansion-panel>
</div>
<div class="m-b-16" fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Type of questions answered incorrectly">
      <curr-type-of-questions-answered-incorrectly [data]="typeOfQuestionsAnsweredIncorrectly">
      </curr-type-of-questions-answered-incorrectly>
    </curr-expansion-panel>
  </div>
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Questions types where most marks are lost">
      <curr-questions-types-where-most-marks-are-lost [data]="questionsTypesWhereMostMarksAreLost" [courseId]="courseId"
        [learningObjectiveId]="learningObjectiveId">
      </curr-questions-types-where-most-marks-are-lost>
    </curr-expansion-panel>
  </div>
</div>
<div class="m-b-16" fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Most frequently left unanswered">
      <curr-most-frequently-left-unanswered-questions [data]="frequentlyLeftUnansweredQuestions">
      </curr-most-frequently-left-unanswered-questions>
    </curr-expansion-panel>
  </div>
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Learning objectives with lowest comprehension">
      <curr-lowest-comprehension-objects [data]="lowestComprehensionObjects"></curr-lowest-comprehension-objects>
    </curr-expansion-panel>
  </div>
</div>

<div class="m-b-16" fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Outperforming">
      <div *ngIf="!(outPerformingStudents?.length)">
        There is no data</div>
      <curr-under-performing-students *ngIf="(outPerformingStudents?.length)" [data]="outPerformingStudents">
      </curr-under-performing-students>
    </curr-expansion-panel>
  </div>
  <div fxFlex="49%">
    <curr-expansion-panel [isShowed]="false" title="Under performing">
      <div *ngIf="!(underPerformingStudents?.length)">
        There is no data</div>
      <curr-under-performing-students *ngIf="(underPerformingStudents?.length)" [data]="underPerformingStudents">
      </curr-under-performing-students>
    </curr-expansion-panel>
  </div>
</div>

<ng-template #loader>
  <curr-loader></curr-loader>
</ng-template>
