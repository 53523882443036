<div class="container p-t-24 p-l-8 p-r-8 p-b-16" fxLayout="column">
  <div class="full-width nav-container-sow m-b-16">
    <div id="nav-sow">
      <div class="overline last-modified">Last modified: {{ topic?.updated_at | date: 'dd MMM yyyy' }}</div>
      <curr-button *ngIf="disabledStatus" fxFlex="66px" color="primary" variant="outlined"
        (btnClick)="disabledStatus = false">EDIT
      </curr-button>
      <curr-button *ngIf="!disabledStatus" fxFlex="88px" color="primary" variant="outlined" (btnClick)="saveUpdates()">
        SAVE
      </curr-button>
    </div>
  </div>
  <div class="full-width">
    <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="tabSelectedIndexChange($event)" animationDuration="0ms"
      class="nav-panel-questions m-b-16 custom-ink-size">
      <mat-tab label="COU right">
      </mat-tab>
      <mat-tab label="COU wrong">
      </mat-tab>
    </mat-tab-group>
  </div>
  <ng-container *ngFor="let question of questions | couRight: !selectedIndex; let i = index">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container">
      <curr-textarea-disabled [disabled]="disabledStatus" [value]="question.question"
        (onLostFocus)="updateCou(question, $event)" class="textarea" rows="auto" fxFlex></curr-textarea-disabled>

      <curr-icon-button [icon]="'trash'" (click)="deleteCou(i)" class="m-l-8" *ngIf="!disabledStatus">
      </curr-icon-button>
    </div>
  </ng-container>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container" *ngIf="isAddFormVisible">
    <curr-textarea-disabled [disabled]="false" [value]="newCou.question" (onLostFocus)="setCou($event)"
      [label]="'Please complete'" class="textarea" rows="auto" fxFlex>
    </curr-textarea-disabled>

    <!--    <curr-icon-button [icon]="'icon-done'" [disabled]="!newCou.question" (click)="addNew()" class="m-l-8" *ngIf="isAddFormVisible"></curr-icon-button>-->
  </div>
  <div *ngIf="!isEmpty" class="error-message m-t-16">Fields must not be empty</div>

  <div fxLayout="row" fxLayoutAlign="start" class="m-t-24">
    <curr-button fxFlex="122px" [style]="btnStyle" color="primary" variant="contained" (btnClick)="enableCouForm()">
      <mat-icon svgIcon="icon-plus" class="icon-plus"></mat-icon>
      ADD NEW
    </curr-button>
  </div>
</div>
