<!-- Created by Maxim B. on 31/03/20.-->
<!-- Copyright © 2020 SEVEN. All rights reserved.-->

<curr-loader [diameter]="40" [isLoading]="!isEditorReady" *ngIf="!isEditorReady"></curr-loader>

<!-- ToDo:: Test data for Math formula -->
<!-- <div [currMath]="'\\(\\sqrt[n]{\\sqrt[m]{a}} = \\sqrt[nm]{a}\\)'"></div> -->

<div class="editor-container" [class.visible-editor]="isEditorReady" [id]="editorId">
  <editor [apiKey]="key" [init]="editorOptions" [(ngModel)]="valueForTinyEditor" (onInit)="onEditorInitialized()"
          (onClick)="updateValue()" (onFocusOut)="focusOut()" (onChange)="updateValue()"></editor>
</div>

<!--<button (click)="test()">destroy galaxy</button>-->
