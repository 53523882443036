<!--
 Created by Dmytro Sav. on 5/12/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="row" fxFlex="auto">
  <mat-card class="card m-b-40" fxLayout="column" fxLayoutAlign="start" fxFlex="350px">
    <div class="placeholder m-b-16" fxLayout="column" fxLayoutAlign="center center">
      <img src="../../../../assets/images/placeholder.svg" alt="icon" />
    </div>

    <div *ngIf="isEdited" fxLayout="column" fxLayoutAlign="center" fxFlex="auto" fxLayoutGap="11px">
      <curr-dropdown
        fxFlex="auto"
        [options]="courseOptions"
        placeholder="Select Course"
        (valueChange)="getCourseOptions($event)"
      ></curr-dropdown>

      <curr-dropdown
        fxFlex="auto"
        placeholder="Select Year"
        [options]="yearsOptions"
        (valueChange)="getYearsOptions($event)"
      ></curr-dropdown>

      <curr-button variant="contained" size="small">
        ADD
      </curr-button>
    </div>

    <div *ngIf="!isEdited" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="11px">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div>Astronomy</div>
        <div>
          <mat-icon class="icon cursor-pointer" svgIcon="edit"></mat-icon>
          <mat-icon class="icon cursor-pointer" svgIcon="trash"></mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start">Year 10</div>
    </div>
  </mat-card>
</div>
