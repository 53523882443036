/*
 * Created by Volodymyr Mak. on 7/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { of, Observable } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';

import { StudentSoftQuestions } from './profile.interface';
import { Student, UserRole } from 'src/app/shared/components/profile-card/profile-card.model';
import { ProfileDataService } from './profile-data.service';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable()
export class StudentProfileService {
  private mockStudentData: Student = {
    name: 'Jessie Jefferson',
    email: { value: 'j.jefferson@gmail.com', verify: true },
    phone: { value: '+44 08787676', verify: false },
    personalID: '435GG56YRE',
    avatar: '',
    id: '1',
    role: UserRole.STUDENT,
    year: 10
  };

  private softQuestions: StudentSoftQuestions = {
    id: '1',
    learnBestChips: ['Vusial', 'Verbal'],
    interestsChips: ['Football', 'Singing', 'Dancing'],
    learnBestText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Volutpat blandit aliquam etiam erat velit scelerisque in dictum non. Blandit turpis cursus in hac habitasse. Cras adipiscing enim eu turpis egestas. Sed elementum tempus egestas sed sed risus pretium quam.'
  };

  constructor(
    private _profileDataModel: ProfileDataService,
    private httpClient: ApplicationHttpClient) {
  }

  getStudentProfile(studentId: string): Observable<Student> {
    return of(this.mockStudentData).pipe(
      delay(500),
      tap((student: Student) => {
        this._profileDataModel.UserProfile.next(student);
      })
    );
  }

  updateStudent(student: Student): Observable<any> {
    return of('updated')
      .pipe(
        delay(500),
        tap({
          next: () => {
            this._profileDataModel.UserProfile.next(student);
          }
        })
      );
  }

  getStudentSoftQ(studentId: string): Observable<StudentSoftQuestions> {
    return of(this.softQuestions);
  }

  getStudentGrades(studId: number = 2020) {
    return this.httpClient.post(`/studentProcedure/studentGrades`, {
      student_id: studId,
      period_from: '2019-06-20 12:44:33',
      period_to: '2025-06-23 12:44:33',
      row_offset: 0,
      rows_count: 100
    }).pipe(
      map((resp: any) => this.fillGapsInGrades(resp.data.items))
    );
  }

  getStudentTests(studId: number = 2020) {
    return this.httpClient.post(`/studentProcedure/studentTests`, {
      student_id: studId,
      period_from: '2019-06-20 12:44:33',
      period_to: '2025-06-23 12:44:33',
      row_offset: 0,
      rows_count: 100
    }).pipe(
      map((resp: any) => this.fillGapsInTests(resp.data.items))
    );
  }

  getProfileDataByUserId(userId: number = 2020): Observable<any>{
    return this.httpClient.get(`/users/${userId}`).pipe(
      map((resp: any) => resp.data)
    );
  }

  updateStudentGrades(student_id: number, predicted_grade: string, course_id: string) {
    return this.httpClient.put(
      `/userStatistic/predictedGrade/student?student_id=${student_id}&predicted_grade=${predicted_grade}&courseName=${course_id}`,
      {}
    );
  }

  private fillGapsInGrades(arr: any[]): any[] {
    return arr.map(i => {
      return {
        ...i,
        performance: i.performance ? i.performance : 0,
        predicted_grade: i.predictedGrade === '-' ? 'F' : i.predictedGrade,
        avg_grade: i.avgGrade === '-' ? 'F' : i.avgGrade
      };
    });
  }

  private fillGapsInTests(arr: any[]): any[] {
    return arr.map(i => {
      return {
        ...i,
        status: this.getTestStatus(i),
    }
    });
  }

  private getTestStatus(i: any){
    if (i.testResult &&  i.testResult !== 'Not passed'){
      return 'RESULT';
    }
    if (i.testResult === 'Not passed'){
      return 'NOT_PASSED';
    }
    return 'PENDING';
  }

}
