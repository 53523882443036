/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-localstorage';
import { ApplicationHttpClient } from './api/application-http-client';
import { Role } from './role';
import { ISubject } from './components/choose-subject/choose-subject.component';
import { HttpParams } from '@angular/common/http';
import { User } from 'src/app/shared/user';
import { RefferalWidget } from 'src/app/shared/widget.info';
import { StudentReferral } from 'src/app/shared/student.referrals';
import { SchoolYearRemainingTime } from 'src/app/shared/school.year.remaining.time';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  currentUser$: BehaviorSubject<User> = new BehaviorSubject({ role: Role.Unauthorized });
  activeUser$: BehaviorSubject<User> = new BehaviorSubject(null);
  private apiSignUpLink: string = '/auth/user/sign-up';

  constructor(private httpClient: ApplicationHttpClient, private localStorageService: LocalStorageService) { }

  getCurrentUser(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  getCurrentUserId(): number{
    return this.currentUser$.getValue().userId
  }

  hasRoles(role: Role[]): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user: User) => {
        return role.includes(user.role);
      })
    );
  }

  isISGSE(): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user: User) => {
        if (user.schoolStudyYear) {
          return user.schoolStudyYear.studyYearName === 'IGCSE';
        }
        return false;
      })
    );
  }

  hasPermissions(permissions: string[]): Observable<boolean> {
    return this.getCurrentUser().pipe(
      map((user: User) => {
        return permissions.every(item => user.permissions.indexOf(item) !== -1);
      })
    );
  }

  getStudentReferralWidgetInfo(): Observable<RefferalWidget> {
    return this.httpClient.get(`/users/students/referrals/widget`).pipe(
      map((userRefferel: RefferalWidget) => {
        return userRefferel;
      })
    );
  }

  getSubscriptioninfo() {
    return this.httpClient.get(`/student/my-subscriptions`).pipe(
      map((subscription: any) => {
        return subscription.data;
      })
    );
  }

  getStudentReferralInfo(): Observable<StudentReferral> {
    return this.httpClient.get(`/users/students/referrals`).pipe(
      map((userRefferel: StudentReferral) => {
        return userRefferel;
      })
    );
  }

  getSchoolYearRemainingTime(): Observable<SchoolYearRemainingTime> {
    return this.httpClient.get(`/schoolYearSettings/remaining-time`).pipe(
      map((schoolrYearRemainingTime: SchoolYearRemainingTime) => {
        return schoolrYearRemainingTime.data;
      })
    );
  }

  saveUser(user: User) {
    this.currentUser$.next(user);
    if (user.role === 'unauthorized') {
      this.localStorageService.set('showpopup', true);
    }
    this.localStorageService.set('currentUser', user);
  }

  attemptAuthFromLocalStorage(): Observable<boolean> {
    const user = this.localStorageService.get('currentUser');
    if (user) {
      this.currentUser$.next(user);
      return of(true);
    } else {
      return of(false);
    }
  }

  getCurrUserProfileInfo() {
    return this.httpClient.get(`/profile`).pipe(
      map((user: any) => {
        return user.data;
      })
    );
  }

  getSchoolSubjects(schoolStudyYearId: string) {
    return this.httpClient.get('/auth/user/school-subjects', { params: { schoolStudyYearId } } as any);
  }

  getCalculatedPrice(): Observable<any> {
    return this.httpClient.get('/auth/user/sign-up-calculate-price');
  }

  getSubjectLearning(subjectId: number): Observable<any> {
    return this.httpClient.get(`/curriculumPapersWithChildren/${subjectId}`)
  }

  setStudyYear(id: any): Observable<any> {
    return this.httpClient.put('/auth/user/sign-up-select-year',
      {},
      { params: id } as any);
  }

  setSubjects(subjects: object[], isUniversityBoostSelected: boolean): Observable<any> {
    return this.httpClient.post('/auth/user/sign-up-select-subjects',
      { subjects, isUniversityBoostSelected }
    );
  }

  setUpdatedPlan({ subjectId, paymentPlanType }: ISubject): Observable<any> {
    const params = new HttpParams()
      .set('subjectId', subjectId.toString())
      .set('planType', paymentPlanType);
    return this.httpClient.put('/auth/user/sign-up-update-plan', {}, { params });
  }


  setFirstName(firstName: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-first-name`, {}, { params: { firstName } as any });
  }

  setLastName(lastName: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-last-name`, {}, { params: { lastName } } as any);
  }

  setBirthDate(dateOfBirth: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-birth-date`, {}, { params: { dateOfBirth } } as any);
  }

  setGender(gender: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-gender`, {}, { params: { gender } } as any);
  }

  setCountry(country: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-country`, {}, { params: { country } } as any);
  }

  setHobbies(hobbies: string[]): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-hobbies`, {}, { params: { hobbies } } as any);
  }

  setSchool(schoolName: string): Observable<any> {
    return this.httpClient.put(`${this.apiSignUpLink}-set-school`, {}, { params: { schoolName } } as any);
  }

  getSchoolYears(): Observable<any> {
    return this.httpClient.get('/auth/user/school-study-years');
  }

  getUserReferralInfo(): Observable<any> {
    return this.httpClient.get('/users/students/referrals/widget');
  }

  getUserCalendarInfo(weekNumber: number): Observable<any> {
    return this.httpClient.get('/calendar/student-activities', { params: { weekNumber } } as any);
  }

  getRecentStudentActivities(currentPage: number = 0, size: number = 4, courseId?: number): Observable<any> {
    let requestParams: {
      size: number;
      page: number;
      courseId?: number;
    } = { size, page: currentPage };

    if (courseId) requestParams = { ...requestParams, courseId };
    return this.httpClient.get('/calendar/recent-student-activities', { params: requestParams } as any);
  }

  getToDoStudentActivities(currentPage: number = 1, size: number = 4, courseId?: number): Observable<any> {
    let requestParams: {
      size: number;
      page: number;
      courseId?: number;
    } = { size, page: currentPage };

    if (courseId) requestParams = { ...requestParams, courseId };
    return this.httpClient.get('/calendar/todo-activities', { params: requestParams } as any);
  }

  getSubscribedSubjects(): Observable<any> {
    return this.httpClient.get('/subscribed-subjects');
  }

  cancelSubscription(id: number) {
    return this.httpClient.delete(`/student/my-subscriptions/${id}`);
  }

  RefundPaymentSubscription(): Observable<any> {
    return this.httpClient.get('/student/refund-subscription');
  }

  getTutorSessionLinks(): Observable<any> {
    return this.httpClient.get('/tutorial-session/sharpen-session-list-url');
  }
}
