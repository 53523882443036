/**
 * Created by Daniil Vat. on 7/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CourseDetailsService } from '../../services/course-details.service';
import {
  IUnderPerformingStudent,
  IMostIncorrectQuestions,
  IReportStats,
  IFrequentlyLeftUnansweredQuestion,
  ILowestComprehensionObject,
  IQuestionsAnsweredIncorrectly,
  IMostLostMarks
} from '../../interfaces';
import { LearningObjective } from '../../../shared-pages/subject-details/api-subject-details';
import { StudentCheckTestService } from '../../questions-page/student-check-test.service';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'curr-tt-report',
  templateUrl: './tt-report.component.html',
  styleUrls: ['./tt-report.component.scss']
})
export class TtReportComponent implements OnInit {
  @Input() courseId: string;
  @Input() learningObjectiveId: string;

  reportStats: IReportStats;
  mostIncorrectQuestions: IMostIncorrectQuestions;
  underPerformingStudents: IUnderPerformingStudent[];
  outPerformingStudents: IUnderPerformingStudent[];
  frequentlyLeftUnansweredQuestions: IFrequentlyLeftUnansweredQuestion[];
  lowestComprehensionObjects: ILowestComprehensionObject[];
  typeOfQuestionsAnsweredIncorrectly: IQuestionsAnsweredIncorrectly;
  questionsTypesWhereMostMarksAreLost: IMostLostMarks;

  learningObjective: LearningObjective;
  title: string = '';
  isTitleLoading: boolean = true;

  constructor(
    private courseDetailsService: CourseDetailsService,
    private studentCheckTestService: StudentCheckTestService,
    private route: ActivatedRoute) {}

  get navigationLink(): string {
    return `/report/:testType/${this.courseId}/${this.learningObjectiveId}/:modeType`;
  }
  ngOnInit() {
    // fetch data
    this.getLearningObjectiveById();
    this.getReportStats();
    this.getTtMostIncorrectQuestions();
    this.getTtOutperformingStudents();
    this.getTtUnderPerformingStudents();
    this.getMostFrequentlyLeftUnansweredQuestions();
    this.getLowestComprehensionObjects();
    this.getFindListOfIncorrectAnswersByType();
    this.getTypesOfQuestionsWithMostLostMarks();
    this.getTestTitle();
  }

  getTestTitle(): void {
    const courseId = this.route.snapshot.params.courseId;
    const ctId = this.route.snapshot.params.ctId;

    this.studentCheckTestService.getCTTestQuestion(ctId, courseId)
      .pipe(first())
      .subscribe((res: any) => {
        this.title = res.data.testName
        this.isTitleLoading = false;
      })
  }

  getLearningObjectiveById() {
    this.courseDetailsService
      .getLearningObjectiveById(this.learningObjectiveId)
      .subscribe(data => (this.learningObjective = data));
  }

  getReportStats() {
    this.courseDetailsService
      .getReportStats(this.courseId, this.learningObjectiveId)
      .subscribe(res => (this.reportStats = res));
  }

  getTtMostIncorrectQuestions() {
    this.courseDetailsService
      .getTtMostIncorrectQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.mostIncorrectQuestions = data));
  }

  getTtUnderPerformingStudents() {
    this.courseDetailsService
      .getTtUnderPerformingStudents(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.underPerformingStudents = data));
  }

  getTtOutperformingStudents() {
    this.courseDetailsService
      .getTtOutperformingStudents(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.outPerformingStudents = data));
  }

  getMostFrequentlyLeftUnansweredQuestions() {
    this.courseDetailsService
      .getMostFrequentlyLeftUnansweredQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.frequentlyLeftUnansweredQuestions = data));
  }

  getLowestComprehensionObjects() {
    this.courseDetailsService
      .getLowestComprehensionObjects(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.lowestComprehensionObjects = data));
  }

  getFindListOfIncorrectAnswersByType() {
    this.courseDetailsService
      .getFindListOfIncorrectAnswersByType(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.typeOfQuestionsAnsweredIncorrectly = data));
  }

  getTypesOfQuestionsWithMostLostMarks() {
    this.courseDetailsService
      .getTypesOfQuestionsWithMostLostMarks(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.questionsTypesWhereMostMarksAreLost = data));
  }
}
