/**
 * Created by Maxim B. on 08/04/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */

/*
 * Edited by Dmytro Sav. on 4/21/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Role } from '../../role';
import { Observable } from 'rxjs';
import { Series } from '../../graphs/school-performance-histogram/series.model';
import { CommonService } from '../../services/common.service';
import { LocalStorageService } from 'ngx-localstorage';
import { first } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../api/application-http-client';

interface Title {
  aligned?: string;
  primary?: { label: string; value: string };
  secondary?: { label: string; value: string };
  tertiary?: { label: string; value: string; isUnderLine?: boolean };
  hint: { label: string; icon: string; color: string };
  topRightIcons: Array<IconButton>;
}

interface Buttons {
  label: string;
  callback: () => void;
  link: string;
  color: string;
  variant: string;
  isDisabled?: boolean;
}

interface Blocks {
  title: string;
  icon?: string;
  hint: {
    icon: string;
    label: string;
    postIcon?: string;
    isUnderLine?: boolean;
    color: string;
    link?: { rout: string; fragment: string };
  };
  bottomTitle: { label: string; isUnderLine?: boolean };
  showProgressBar: boolean;
  progressBar: { color: string; value: number };
}

interface IconButton {
  icon: string;
  link: string;
  cb?: () => void;
  viewToSwitch?: View;
}

export enum View {
  StandardView = 'standardView',
  GraphView = 'graphView'
}

@Component({
  selector: 'curr-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss']
})
export class CourseCardComponent implements OnInit {
  @Input() course: {
    type: string; // 'single' | 'multiple';
    title: Title;
    blocks: Array<Blocks>;
    buttons: Array<Buttons>;
    chartData?: Observable<Series[]>;
  };
  userRole = Role;

  view: View = View.StandardView;
  View = View;

  infoIconText$: Observable<string>;
  tutorSession = 'tutorSession';

  constructor(
    public router: Router,
    private commonService: CommonService,
    private localStorageService: LocalStorageService,
    private http: ApplicationHttpClient,
  ) {}

  ngOnInit(): void {
    if (!this.course?.type) {
      this.course.type = 'multiple';
    }
    this.infoIconText$ = this.commonService.select<string>('infoText');
  }

  setCourseIdToLocalStorage(courseId: number) {
    this.localStorageService.set('schoolYearCourseId', courseId);
  }

  onIconClick(icon: IconButton) {
    if (icon.viewToSwitch) {
      this.view = icon.viewToSwitch;
    }
    if (icon.cb) {
      icon.cb();
    }
  }

  getNextTTLink(): Observable<any> {
    return this.http.get('/tutorial-session/session-sharpen-url');
  }

  public joinTutorSession(): void {
    this.getNextTTLink()
      .pipe(first())
      .subscribe(res => {
        if (res.data) {
          window.open(res.data);
        }
    });
  }
}
