/**
 * Created by Maxim B. on 01/04/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
/*
* Usage example
* <curr-textarea [(value)]="cbValues"
                 [formGroup]="mediaItemForm"
                 controlName="description"
                 errorMessage="Must not be empty"
                 rows="20"
                 label="Why do you feel your answer is correct?"></curr-textarea>
* WARN you can use or VALUE or FORMGROUP and CONTROLNAME. not Both at once
* value will be auto assigned back to your cbValues
* */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() value: string;
  @Input() label: string;
  @Input() rows = 5;
  @Input() maxRows = 5;
  @Input() formGroup: FormGroup;
  @Input() controlName = '';
  @Input() errorMessage = '';
  @Input() hint = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() onLostFocus = new EventEmitter<string>();

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.value)
      });
      this.controlName = 'item';
    }
  }

  updateValue(){
    const val = this.formGroup.controls[this.controlName].value;
    this.valueChange.emit(val);
  }

  onBlur(){
    const val = this.formGroup.controls[this.controlName].value;
    this.onLostFocus.emit(val);
  }

}
