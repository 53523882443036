/**
 * Created by Max B. on 07/07/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import {CommonQuestionAnswer, SAQuestionAnswer, Test} from '../test.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {CumulativeTest} from '../../shared-pages/edit-test/cumulative-test';
type ResourceType = 'VIDEO' | 'AUDIO' | 'IMAGE' | 'DOC' | 'ARTICLE' | 'LESSON_PLAN' | 'QUESTION';

@Injectable({
  providedIn: 'root'
})
export class StudentCumulativeTestService {

  constructor(private httpClient: ApplicationHttpClient) {
  }

  saveStudentCTResult(testAnswers: CommonQuestionAnswer[]) {
    return this.httpClient.put(`/studentCumTestQuestions`, testAnswers);
  }

  saveStudentCTQuestionResult(questionData: CommonQuestionAnswer) {
    return this.httpClient.put(`/studentCumTestQuestions/${questionData.student_cum_test_question_id}`, questionData);
  }

  saveStudentCTQuestionFile(dataFile: any, resourceType: ResourceType) {
    const formData = new FormData();
    formData.append('files', dataFile);
    formData.append('resourceType', resourceType);
    return this.httpClient.post(`/s3/upload`, formData).pipe(
      map(res =>{
        return (res as any).data.items;
      })
    );
  }

  saveStudentSAResult(testAnswers: SAQuestionAnswer[]) {
    return this.httpClient.put(`/studentSATestQuestion`, testAnswers);
  }

  saveStudentSAQuestionResult(questionData: any) {
    return this.httpClient.put(`/studentSATestQuestion/${questionData.student_sa_test_question_id}`, questionData);
  }

  selfMarkDialogData(questionID: number) {
    return this.httpClient.get(`/studentCumTestQuestions/${questionID}/self-mark`);
  }

  checkStudentCumTestAvailability(curriculum_cum_test_id: number, school_year_course_student_id: number): Observable<any> {
    return this.httpClient.post('/checkStudentCumTestAvailability',
      { curriculum_cum_test_id, school_year_course_student_id } as any);
  }

  getStudentCumTest(studentCumTestId: number): Observable<any> {
    return this.httpClient.get(`/studentCumTests/${studentCumTestId}`);
  }

  getCT(id: string, studentId: string): Observable<CumulativeTest> {
    return this.httpClient.post(`/studentCumTests`, {
      curriculum_cum_test_id: id,
      school_year_course_student_id: studentId
    });
  }

  skipQuestion(questionId: number) {
    return this.httpClient.put(`/studentSATestQuestion/${questionId}/skip`, {});
  }

  synchronizeWithAI(questionID: number) {
    return this.httpClient.get(`/studentCumTestQuestions/${questionID}`);
  }

  saveSelfMark(selfMarkData: any) {
    return this.httpClient.put(`/studentCumTestQuestions/self-mark`, selfMarkData);
  }

  getSelfMarkReasons(): Observable<any> {
    return this.httpClient.get(`/self-mark/reasons`);
  }
}
