<div class="container" fxLayout="column">
  <h2 class="label m-b-8">Historical Assessments</h2>
  <div class="m-b-27">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>
  <div fxLayout="row" class="wrapper">
      <div class="left">
        <div class="filter" fxLayout="row">
          <curr-checkbox
            class="checkbox"
            [options]="[{label: 'Topic Tests', value: 'topic_test', disabled: disabled}]"
            (valueChange)="filter($event, 'topic_test')"
            [value]="[]"
          >
          </curr-checkbox>
          <curr-checkbox
            [options]="[{label: 'Self Assessments', value: 'sa', disabled: disabled}]"
            (valueChange)="filter($event, 'sa')"
            [value]="[]">
          </curr-checkbox>
        </div>
        <curr-loader *ngIf="isLoading"></curr-loader>
        <div *ngIf="assessments$ | async as assessments">
          <div *ngIf="assessments.length; else noAssessments">
            <div class="body" fxLayout="row">
              <ng-container *ngFor="let assessment of assessments | paginate: config">
                  <curr-assessment
                    [@fade]
                    class="assessment"
                    *ngIf="!isLoading"
                    [assessment]="assessment"
                  ></curr-assessment>
              </ng-container>
            </div>
            <div class="pagination m-t-24" fxLayout="row" fxLayoutAlign="center">
              <curr-pagination [config]="config" [navType]="'arrows'"></curr-pagination>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
<!--        <curr-topic-test-widget></curr-topic-test-widget>-->
      </div>
  </div>
</div>

<ng-template #noAssessments>
  <h2 class="label start">You have no assessment yet.</h2>
</ng-template>
