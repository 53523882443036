/**
 * Created by Maxim B. on 30/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
/**
 * data: { questionTitle: 'Are you sure in deleting?',
 *      questionDescription: 'If you delete this table - you will be unable to recover it',
 *      confirmLabel?: 'yes',
 *      cancelLabel?: 'no'}
 */

export class ConfirmPopupComponent implements OnInit {

  title = '';
  description = '';

  constructor(public dialogRef: MatDialogRef<ConfirmPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.title = this.data.questionTitle;
    this.description = this.data.questionDescription;
    this.dialogRef.disableClose = true;
  }

  submitAnswer(answer) {
    this.dialogRef.close(answer);
  }

}
