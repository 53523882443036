<!--Created by Maxim B. on 31/03/20.-->
<!-- Copyright © 2020 SEVEN. All rights reserved.-->
<p *ngIf="error.length">{{ error }}</p>
<div class="cb-container" *ngIf="!error.length">
  <mat-slide-toggle
    *ngFor="let item of componentOptions"
    (change)="updateValue()"
    [color]="color"
    [ngClass]="[color]"
    [disabled]="item.disabled"
    [(ngModel)]="item.checked"
    [checked]="item.checked"
    [labelPosition]="labelPosition"
    >{{ item.label }}</mat-slide-toggle
  >
</div>
