/**
 * Created by Maxim B. on 05/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'curr-initials-avatar',
  templateUrl: './initials-avatar.component.html',
  styleUrls: ['./initials-avatar.component.scss']
})
export class InitialsAvatarComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() size: string = '50px';
  initials = '';

  constructor() {
  }

  ngOnInit(): void {
    if (this.name) {
      const nameSplit = this.name.split(' ');
      this.initials = nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
    }

  }

  ngOnChanges(): void {
    if (this.name) {
      const nameSplit = this.name.split(' ');
      this.initials = nameSplit[0].charAt(0).toUpperCase() + nameSplit[1].charAt(0).toUpperCase();
    }
  }
}
