<!--
 Created by Dmytro Sav. on 4/5/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-sow-topics-list">
  <div
    *ngIf="topics.length > 0"
    cdkDropList
    class="sortable-list"
    (cdkDropListDropped)="drop($event)"> 
    <div
      class="sortable-list-item"
      [ngClass]="topic.id === activeItem.id ? 'active-topic-item' : ''"
      *ngFor="let topic of sortedTopics; let k = index"
      [cdkDragData]="k"
      (click)="selectTopicItem(topic, k)"
      cdkDrag>
      <mat-icon class="drag-icon" svgIcon="drag" cdkDragHandle (click)="$event.stopPropagation()"></mat-icon>
      <div class="topic-title" [currMath]="topic.title"></div>
      <mat-icon class="more-icon" (click)="$event.stopPropagation()" [matMenuTriggerFor]="beforeMenu">more_vert</mat-icon>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="editTopicItem(topic)">
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button mat-menu-item (click)="deleteTopicItem(topic)">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </div>
  </div>
</div>
