<!--
 Created by Dmytro Sav. on 5/11/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="row" fxFlex="auto">
  <mat-card class="card m-b-40" fxLayout="column" fxLayoutAlign="start" fxFlex="350px">
    <div class="placeholder m-b-16" fxLayout="column" fxLayoutAlign="center center">
      <img src="../../../../assets/images/placeholder.svg" alt="icon" />
    </div>

    <div *ngIf="isEdited" fxLayout="column" fxLayoutAlign="center" fxFlex="auto" fxLayoutGap="11px">
      <curr-dropdown
        fxFlex="auto"
        [options]="courseOptions"
        [selectedValue]="selectedCourse"
        placeholder="Select Course ID"
        (valueChange)="getCourseOptions($event)"
      ></curr-dropdown>

      <curr-dropdown
        fxFlex="auto"
        placeholder="Select Year"
        [selectedValue]="selectedYear"
        [options]="yearsOptions"
        (valueChange)="getYearsOptions($event)"
      ></curr-dropdown>

      <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" *ngFor="let form of formCount">
        <curr-dropdown
          fxFlex="auto"
          placeholder="Select Form"
          [selectedValue]="selectedForm"
          [options]="formOptions"
          (valueChange)="getFormOptions($event)"
        ></curr-dropdown>

        <curr-dropdown
          fxFlex="auto"
          placeholder="Ex. Avg. Grade"
          [options]="gradeOptions"
          [isMultiSelected]="true"
          (valueChange)="getGradeOptions($event)"
        ></curr-dropdown>
      </div>

      <curr-button variant="text" size="small" (btnClick)="addForm()">
        <mat-icon svgIcon="plus"></mat-icon>
        Add one more Form
      </curr-button>

      <curr-button variant="contained" size="small" (btnClick)="addNewCourse()">
        ADD
      </curr-button>
    </div>

    <div *ngIf="!isEdited" fxLayout="column" fxLayoutAlign="center" fxLayoutGap="11px">
      <div fxLayout="row" fxLayoutAlign="start">Course ID: {{ subject.course.label }}</div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div>{{ subject.name }}</div>
        <div>
          <mat-icon class="icon cursor-pointer" svgIcon="edit" (click)="editSubject()"></mat-icon>
          <mat-icon class="icon cursor-pointer" svgIcon="trash"></mat-icon>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start">{{ subject.year.label }}</div>

      <div fxLayout="row" fxLayoutAlign="start" *ngFor="let grade of subject.grade">
        Ex. Avg. Grade Form 1: {{ grade.label }}
      </div>
    </div>
  </mat-card>
</div>
