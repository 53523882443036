<!--
 Created by Volodymyr Mak. on 24/6/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="container" fxLayout="column" fxLayoutGap="40px">
  <div class="m-t-40 m-b-32" fxLayout="row" fxLayoutAlign="space-between center">
    <curr-breadcrumb position="start" class="m-b-0" [path]="path"></curr-breadcrumb>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
      <curr-button variant="outlined">
        deactivete this user
      </curr-button>
      <curr-button icon="icon-done">
        add user
      </curr-button>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="20px">
    <article class="profile-card" fxLayout="column" fxLayoutAlign="stretch center">
      <div class="avatar item">
        <img [src]="" alt="" />
        <curr-image-upload (imgCropped)="setProfilePhoto($event)"></curr-image-upload>
      </div>

      <section class="user-info">
        <form [formGroup]="form">
          <section class="edit-user" fxLayout="column">
            <curr-auth-input
              fxFlex="auto"
              controlName="firstName"
              placeholder="First Name"
              [formGroup]="form"
            ></curr-auth-input>
            <curr-auth-input
              fxFlex="auto"
              placeholder="ID"
              controlName="personalID"
              [formGroup]="form"
            ></curr-auth-input>
            <curr-auth-input fxFlex="auto" placeholder="Phone" controlName="phone" [formGroup]="form"></curr-auth-input>
            <curr-auth-input
              fxFlex="auto"
              placeholder="Last Name"
              controlName="lastName"
              [formGroup]="form"
            ></curr-auth-input>
            <curr-auth-input fxFlex="auto" placeholder="Email" controlName="email" [formGroup]="form"></curr-auth-input>
            <curr-auth-input
              fxFlex="auto"
              placeholder="Country"
              controlName="country"
              [formGroup]="form"
            ></curr-auth-input>
            <curr-auth-input
              fxFlex="auto"
              placeholder="School"
              controlName="school"
              [formGroup]="form"
            ></curr-auth-input>
            <curr-button class="save-btn profile-btn" (btnClick)="create()" [isDisabled]="form.invalid">
              Save</curr-button
            >
          </section>
        </form>
      </section>
    </article>

    <article class="right-section" fxLayout="column" fxLayoutGap="40px" fxLayoutAlign="start">
      <section class="delete-permission-card" fxLayout="column">
        <div class="title" fxLayoutAlign="space-between center">
          <span>Permission</span>
        </div>
        <mat-divider></mat-divider>
        <div class="card">
          <curr-checkbox
            class="permission-box m-b-4"
            [options]="checkboxList.options"
            [color]="checkboxList.color"
            [(value)]="checkboxList.value"
            [type]="checkboxList.type"
            [isMultipleSelect]="checkboxList.isMultipleSelect"
          ></curr-checkbox>
        </div>
      </section>

      <section class="import-schedule">
        <div class="title" fxLayoutAlign="space-between center">
          <span>Schedule</span>
        </div>
        <mat-divider></mat-divider>
        <div class="card" fxLayout="column" fxLayoutAlign="center center">
          <span class="mat-headline">Please import teacher lesson timetableto view schedule</span>
          <span class="mat-body-1 m-b-32">You do not have to do this, the teacher can do it yoursalfe</span>
          <curr-button (btnClick)="importTimetable()">import timetable</curr-button>
        </div>
      </section>
    </article>
  </div>

  <article fxLayout="column" fxLayoutGap="20px">
    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Notes</span>
        <curr-button variant="text" icon="icon-plus">add note</curr-button>
      </div>
      <mat-divider class="m-t-16 m-b-16"></mat-divider>
      <div class="notes-wrapper">
        <span *ngIf="!notes.length">Not notes yet</span>
      </div>
    </section>

    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Courses</span>
        <curr-button variant="text" icon="icon-plus">add new</curr-button>
      </div>
      <mat-divider class="m-t-16 m-b-16"></mat-divider>
      <div class="courses-wrapper">
        <span *ngIf="!courses.length">Not courses yet</span>
      </div>
    </section>
  </article>
</div>
