<!--
 Created by Dmytro Sav. on 4/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<mat-card class="m-b-16 card" *ngIf="supportedQuestionsType.includes(question?.answer_type)">
  <div class="m-b-16 mat-h4">Question {{ questionNumber }}:</div>
  <div class="mat-body-1 m-b-24" [innerHTML]="question?.question"></div>

  <div class="mat-h4 m-b-8">Answer:</div>
  <div *ngIf="!question.answer_options">
    {{ question?.answer_type }}
  </div>

  <ul class="answers-list mat-body-1" *ngIf="question.answer_options">
    <li *ngFor="let answer of question?.answer_options; let i = index" fxLayout="row" fxLayoutAlign="start baseline">
      <div
        *ngIf="
          question?.correct_answer_options === i ||
            (question?.correct_answer_options?.length > 0 && question?.correct_answer_options.includes(i));
          else inCorrect
        "
        class="correct-label m-b-8"
        fxLayout="row"
        fxLayoutAlign="center"
      >
        <mat-icon svgIcon="circle" class="circle correct-circle m-r-8"></mat-icon>
        <span [currMath]="answer"></span>
      </div>

      <ng-template #inCorrect>
        <div fxLayout="row" fxLayoutAlign="center" class="incorrect-label m-b-8">
          <mat-checkbox class="radio" (click)="prevent($event)">
            <span [currMath]="answer"></span>
          </mat-checkbox>
        </div>
      </ng-template>
    </li>
  </ul>
  <img class="img m-t-8" [src]="question.question_image" alt="" />
  <div class="m-t-24" fxLayout="column" fxLayoutAlign="center" *ngIf="question?.cou_right_id">
    <div class="m-b-8 mat-h4">Confirmation of Understanding:</div>
    <div class="mat-body-1">{{ question?.cou_right_id }}</div>
  </div>

  <div *ngIf="question?.curriculum_lobj_id" class="m-t-24">
    <div class="m-b-8 mat-h4">Topic Covered:</div>
    <div class="m-b-8 mat-body-1">{{ question?.curriculum_lobj_id }}</div>
  </div>

  <div *ngIf="question?.answer_type === 'textInput'" fxLayout="column" fxLayoutAlign="start start" class="m-t-24">
    <div class="mat-h4 m-b-8">
      Keywords:
    </div>
    <curr-table></curr-table>
  </div>

  <div class="m-t-24" fxLayout="row" fxLayoutAlign="space-between end">
    <div *ngIf="question?.difficulty_level">
      <div class="m-b-8 mat-h4">Level:</div>
      <div class="m-b-8 mat-body-1">{{ question?.difficulty_level }}</div>
    </div>

    <div fxLayout="row" fxLayoutGap="25px" *ngIf="isCumulativeTests" class="align-right">
      <div fxLayout="row" fxLayoutAlign="start center" class="mat-h4 m-b-0 ">
        <div class="m-r-8 mat-h4 m-b-0">Marks:</div>
        <curr-input [isCentered]="true" [value]="2" fxFlex="56px"></curr-input>
      </div>

      <div class="mat-h4 m-b-0">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="m-r-8">Answer time:</div>
          <curr-input fxFlex="56px"></curr-input>
          <span class="m-l-8">min.</span>
        </div>
      </div>
    </div>
  </div>
</mat-card>
