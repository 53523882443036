import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  pure: false,
  name: 'couRight'
})
export class CouRightPipe implements PipeTransform {
  transform(items: any[], isRight: boolean): any {
    if (!items) {
      return items;
    }

    return items.filter(item => item.value === null || item.is_right === isRight || item.is_right === null);
  }
}
