/**
 * Created by Alex Poh. on 19/07/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { MathServiceImpl } from './math.service';
import { take, takeUntil } from 'rxjs/operators';
import { MathContent } from './math-content';

@Directive({
  selector: '[currMath]'
})
export class MathDirective implements OnInit, OnChanges, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  @Input() private currMath: MathContent;
  private readonly _el: HTMLElement;

  constructor(private service: MathServiceImpl, private el: ElementRef) {
    this._el = el.nativeElement as HTMLElement;
  }

  ngOnInit(): void {
    this.service
      .ready()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(res => {
        this.service.render(this._el, this.currMath);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._el.innerHTML = this.currMath as string;
    // @ts-ignore
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.el.nativeElement]);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
