import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionsLabels, QuestionType } from '../../../../student/test.model';

@Component({
  selector: 'curr-question-type-button-dropdown',
  templateUrl: './question-type-button-dropdown.component.html',
  styleUrls: ['./question-type-button-dropdown.component.scss']
})
export class QuestionTypeButtonDropdownComponent implements OnInit {
  @Input() btnLabel = 'CREATE NEW';
  @Input() isCtQuestion = false;
  @Output() typeSelected = new EventEmitter<string>();
  questionType = QuestionType;
  questionsLabels = QuestionsLabels;
  isAddNewOpen = false;

  constructor() {
  }

  // TODO: remove?
  ngOnInit(): void {
  }

  selectType(type: string) {
    this.typeSelected.emit(type);
  }
}
