/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Question,
  QuestionGlobalType,
  QuestionGlobalTypeLabels,
  QuestionsLabels,
  QuestionType,
} from '../../../../student/test.model';
import { FilterListItem } from 'igniteui-angular/lib/grids/filtering/excel-style/common';
import { RestApiService, restApiServiceCreator } from '../../../api/rest-api.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from '../../edit-question-block/file-upload.service';
import { AIImages } from './ai-upload-files/ai-upload-files.interface';
import { takeUntil } from 'rxjs/operators';
import { AiAssistantDialogService } from './ai-assistant-dialog.service';
import { NewQuestionService } from '../new-question.service';
import { IAIQuestions } from './ai-assistant-dialog.interface';

interface SubjectTestSelectTopic {
  id: number;
  title: string;
  alreadyUsed: boolean;
  is_passed: boolean;
}

@Component({
  selector: 'curr-create-edit-dialog',
  templateUrl: './create-edit-dialog.component.html',
  styleUrls: ['./create-edit-dialog.component.scss'],
  providers: [AiAssistantDialogService],
})
export class CreateEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inputData!: any;

  private readonly _onOutputData = new Subject<{ data: any; shouldClose: boolean, confirmDialog: boolean }>();
  public onOutputData = this._onOutputData.asObservable();
  protected readonly QuestionType = QuestionType;

  isSaveDisabled = true;
  isLoading = false;
  isLoadingContent = false;

  questions: Question[] = [];
  questionsToSave: Question[];
  dialogSubTitle: string;
  questionTypesOptions: { value: string; label: string }[];
  answerFormatOptions: { value: string; label: string }[];
  topicsList: FilterListItem[] = [];
  paperTopics: SubjectTestSelectTopic[];
  preSelectedTopic: { value: string; label: string };
  questionType: string;
  questionTypeLabel: string;
  answerFormat: string;
  answerFormatValue: string;
  answerTypes = QuestionType;
  originalQuestionContent: string;
  originalMarkSchemeContent: string;
  setStepValue: number;
  dialogType: 'manual' | 'ai';
  dialogMode: 'create' | 'edit' = 'create';
  imagesStore: {questionImages: any, markSchemeImages: any} = {questionImages: null, markSchemeImages: null}
  selectedTopicId: number | null;

  selectedIndex = 0;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private aiAssistantDialogService: AiAssistantDialogService,
    private fileUploadService: FileUploadService,
    private newQuestionService: NewQuestionService,
  ) {}

  get steperPrevDisabled() {
    return this.questions.length === 0;
  }

  get steperNextDisabled() {
    return this.questions.length === 0;
  }

  ngOnInit(): void {
    this.initFormOptions();
    this.questionChangeActions();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private questionChangeActions() {
    this.newQuestionService
      .onChangeQuestion()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        const indexes = result.itemIndexs;

        if (indexes.length === 1) this.questions[indexes[0]] = result.question;
        if (indexes.length === 2) this.questions[indexes[0]].children[indexes[1]] = result.question;
        if (indexes.length === 3)
          this.questions[indexes[0]].children[indexes[1]].children[indexes[2]] = result.question;

        this.questionChange(this.questions);
      });

    this.newQuestionService
      .onChangeAnswerFormat()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.changeQuestionAnswerFormat(result);
      });

    this.newQuestionService
      .onChangeTopic()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.changeTopic(result);
      });

    this.newQuestionService
      .onRemoveItem()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.removeQuestion(result);
      });

    this.newQuestionService
      .onChangeIntroStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        this.changeQuestionIntroStatus(result);
      });
  }

  setStepNumber(stepValue) {
    this.setStepValue = stepValue;
  }

  addNewQuestion(itemIndexs: number[]) {
    if (itemIndexs.length === 1) {
      this.pushNewItem(this.questions, itemIndexs[0]);
    }
    if (itemIndexs.length === 2) {
      this.pushNewItem(this.questions[itemIndexs[0]].children, itemIndexs[1]);
    }
    if (itemIndexs.length === 3) {
      this.pushNewItem(this.questions[itemIndexs[0]].children[itemIndexs[1]].children, itemIndexs[2]);
    }

    this.questionChange(this.questions);
  }

  private pushNewItem(parentQuestion: any, index: number) {
    const newItem = new Question('New question', this.selectedTopicId ? this.selectedTopicId : this.topicsList[0].value, QuestionType.OPEN_ENDED);
    newItem.oeq_keywords = [{ key: 'Answer', mark: 1 }];
    newItem.is_past_exam_question = false;

    if (parentQuestion.length === 0) {
      this.questions.push(newItem);
    } else {
      parentQuestion.splice(index + 1, 0, newItem);
    }
  }

  private changeQuestionIntroStatus(data: { introStatus: boolean; itemIndexs: number[] }) {
    const indexs = data.itemIndexs;

    if (indexs.length === 1) {
      this.questions[indexs[0]].is_master = data.introStatus;
      this.questions[indexs[0]].mark = null;
      this.questions[indexs[0]].answer_type = data.introStatus ? QuestionType.MULTIPART : QuestionType.OPEN_ENDED;
    }
    if (indexs.length === 2) {
      this.questions[indexs[0]].children[indexs[1]].is_master = data.introStatus;
      this.questions[indexs[0]].children[indexs[1]].mark = null;
      this.questions[indexs[0]].children[indexs[1]].answer_type = data.introStatus ? QuestionType.MULTIPART : QuestionType.OPEN_ENDED;
    }
    if (indexs.length === 3) {
      this.questions[indexs[0]].children[indexs[1]].children[indexs[2]].is_master = data.introStatus;
      this.questions[indexs[0]].children[indexs[1]].children[indexs[2]].mark = null;
      this.questions[indexs[0]].children[indexs[1]].children[indexs[2]].mark = null;
    }

    this.questionChange(this.questions);
  }

  private removeQuestion(indexes: number[]) {
    if (indexes.length === 1) this.questions.splice(indexes[0], 1);
    if (indexes.length === 2) this.questions[indexes[0]].children.splice(indexes[1], 1);
    if (indexes.length === 3) this.questions[indexes[0]].children[indexes[1]].children.splice(indexes[2], 1);
    this.questionChange(this.questions);
  }

  private initFormOptions() {
    this.questionTypesOptions = [
      { value: QuestionGlobalType.SIMPLE, label: QuestionGlobalTypeLabels.SIMPLE },
      { value: QuestionGlobalType.MULTI_LEVEL, label: QuestionGlobalTypeLabels.MULTI_LEVEL },
    ];

    this.answerFormatOptions = [
      { value: QuestionType.OPEN_ENDED, label: QuestionsLabels.OPEN_ENDED },
      { value: QuestionType.SELECT_MULTIPLE, label: QuestionsLabels.MULTI_SELECT },
      { value: QuestionType.SELECT_ONE, label: QuestionsLabels.SINGLE_ANSWER },
      { value: QuestionType.TRUE_OR_FALSE, label: QuestionsLabels.TRUE_FALSE },
      { value: QuestionType.FILE_INPUT, label: QuestionsLabels.FILE },
    ];

    this.dialogSubTitle = this.inputData.data.dialogSubTitle;
    this.topicsList = this.inputData.data.topicList ?? [];
    this.selectedTopicId = this.inputData.data.selectedTopicId ?? null;
    this.dialogType = this.inputData.data.dialogType;
    this.dialogMode = this.inputData.data.dialogMode;
    const oldQuestion = this.inputData.data.question;
    
    if (this.dialogType === 'manual') {
      this.setStepValue = 2;
      this.selectedIndex = 2;

      if (oldQuestion) {
        const newItem = new Question(oldQuestion.question, oldQuestion.topicId, oldQuestion.type);
        newItem.id = oldQuestion.id;
        newItem.mark = oldQuestion.mark;
        newItem.children = oldQuestion.children;
        newItem.testAndQuestionTypes = oldQuestion.testAndQuestionTypes;
        newItem.markType = oldQuestion.markType;
        newItem.curriculum_lobj_id = oldQuestion.curriculum_lobj_id;
        newItem.answer_type = oldQuestion.answer_type;
        newItem.correct_answer_options = oldQuestion.correct_answer_options;
        newItem.oeq_keywords = oldQuestion.oeq_keywords;
        newItem.is_past_exam_question = oldQuestion.is_past_exam_question;
        newItem.is_master = oldQuestion.is_master;
        newItem.question_year = oldQuestion.is_past_exam_question ? oldQuestion.question_year : null;
        newItem.examination_board = oldQuestion.examination_board;
        this.questions.push(newItem)
      } else {
        this.addNewQuestion([0]);
      }
    }
  }

  private changeTopic(data: { topicId: number; itemIndexs: number[] }) {
    const indexs = data.itemIndexs;

    if (indexs.length === 1) this.questions[indexs[0]].curriculum_lobj_id = data.topicId;
    if (indexs.length === 2) this.questions[indexs[0]].children[indexs[1]].curriculum_lobj_id = data.topicId;
    if (indexs.length === 3)
      this.questions[indexs[0]].children[indexs[1]].children[indexs[2]].curriculum_lobj_id = data.topicId;

    this.questionChange(this.questions);
  }

  private changeQuestionAnswerFormat(data: { type: string; itemIndexs: number[] }) {
    this.answerFormatValue = data.type;
    this.answerFormat = this.answerFormatOptions.find((item) => item.value === data.type).label;

    const indexs = data.itemIndexs;
    let q = null;

    if (indexs.length === 1) q = this.questions[indexs[0]];
    if (indexs.length === 2) q = this.questions[indexs[0]].children[indexs[1]];
    if (indexs.length === 3) q = this.questions[indexs[0]].children[indexs[1]].children[indexs[2]];

    q.answer_type = data.type;

    if (data.type === QuestionType.MULTIPART) {
      q.is_master = true;
    }
    if (data.type === QuestionType.TRUE_OR_FALSE) {
      q.answer_options = ['True', 'False'];
    }
    if (data.type === QuestionType.SELECT_ONE || data.type === QuestionType.SELECT_MULTIPLE) {
      q.answer_options = ['Option 1', 'Option 2'];
      q.correct_answer_options = [0];
    }
    if (data.type === QuestionType.OPEN_ENDED) {
      q.oeq_keywords = [{ key: 'Answer', mark: 1 }];
    }

    q.is_past_exam_question = false;

    if (indexs.length === 1) this.questions[indexs[0]] = q;
    if (indexs.length === 2) this.questions[indexs[0]].children[indexs[1]] = q;
    if (indexs.length === 3) this.questions[indexs[0]].children[indexs[1]].children[indexs[2]] = q;

    this.questionChange(this.questions);
  }

  saveNewQuestion() {
    if (!this.questionsToSave || this.questionsToSave.length === 0) {
      return false;
    }

    this.questionsToSave.forEach((questionToSave, j) => {
      if (questionToSave.testAndQuestionTypes.length === 0) {
        this.toastr.error('Please choose Test Type');
        return;
      }

      if (questionToSave.question.replace(/(<([^>]+)>)/gi, '') === '') {
        this.toastr.error('Question field is empty');
        return;
      }

      if (questionToSave.answer_type !== QuestionType.FILE_INPUT && !questionToSave.correct_answer_options) {
        this.toastr.error('Please add Mark Scheme');
        return;
      }

      this.questionsToSave[j].is_past_exam_question = questionToSave.is_past_exam_question ? true : false;
      this.questionsToSave[j].questionYear = questionToSave.is_past_exam_question ? questionToSave.questionYear : null;
      this.questionsToSave[j].question_year = questionToSave.is_past_exam_question ? questionToSave.question_year : null;
      this.questionsToSave[j].examination_board = questionToSave.is_past_exam_question ? questionToSave.examination_board : null;
    });

    this.newQuestionService
      .saveQuestions(this.questionsToSave)
      .pipe(takeUntil(this.destroy$))
      .subscribe((resp) => {
        this._onOutputData.next({ data: { questionsIds: resp, questions: this.questionsToSave }, shouldClose: true, confirmDialog: false });
      });
  }

  questionChange(questions: Question[]) {
    this.questionsToSave = questions;

    this.isSaveDisabled = false;
    this.questionsToSave.forEach((questionToSave, j) => {
      if (
        questionToSave.testAndQuestionTypes.length === 0 ||
        questionToSave.question.replace(/(<([^>]+)>)/gi, '') === '' ||
        (questionToSave.answer_type !== QuestionType.FILE_INPUT &&
          questionToSave.correct_answer_options.length === 0) ||
        (questionToSave.answer_type === QuestionType.TRUE_OR_FALSE &&
          questionToSave.correct_answer_options.length > 0 &&
          !questionToSave.correct_answer_options.includes(0) &&
          !questionToSave.correct_answer_options.includes(1))
      ) {
        this.isSaveDisabled = true;
      }
    });
  }

  cancelQuestion() {
    this._onOutputData.next({ data: {}, shouldClose: true, confirmDialog: true });
  }

  onLoadedFile(event) {
    // this.fileUploadService
    //   .upload(event[0], 'IMAGE')
    //   .subscribe((resp: { uuidName: string; oldName: string; state: string }[]) => {
    //     console.log(resp)
    //   });
  }

  onSendToAI(data: { collectedFiles: AIImages[]; aiFilesType: 'question' | 'markScheme', originalFiles: any }) {
    this.isLoadingContent = true;

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    const aiData = {
      curriculumId: this.inputData.data.curriculumId,
      images: data.collectedFiles,
    };

    if (data.aiFilesType === 'markScheme') {
      this.imagesStore.markSchemeImages = data.originalFiles;
      this.aiAssistantDialogService
        .sendMarkSchemeAI(aiData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (resp) => {
            if (!resp.mark_schemes) {
              this.isLoadingContent = false;
              this.toastr.error("AI didn't recognize input data");
              return;
            }

            let tempMarkSchemes = [];
            resp.mark_schemes.forEach(item => {
              tempMarkSchemes.push(item);
            });

            tempMarkSchemes.forEach((markItem, i) => {
              const setMarkItem = this.searchQuestionKeys(markItem);
              tempMarkSchemes[i].wasUsed = setMarkItem;
            });

            let notMatchItems = tempMarkSchemes.filter(markItem => !markItem.wasUsed);

            if (notMatchItems.length > 0) {
              tempMarkSchemes.forEach((markItem, i) => {
                if (!markItem.wasUsed) this.setMarkSckemeByIndex(markItem, i);
              });
            }

            this.originalMarkSchemeContent = resp.original_content;
            if (resp.included_images && resp.included_images.length > 0) {
              this.uploadIncludedImagesMarkScheme(resp.included_images);
            }

            this.questionChange(this.questions);
            this.cdr.detectChanges();
            this.isLoadingContent = false;
          },
          (error) => {
            this.isLoadingContent = false;
            this.toastr.error('Something went wrong with the AI Assistant. Please try again later.');
          },
        );
    } else {
      this.imagesStore.questionImages = data.originalFiles;
      this.questions = [];
      this.aiAssistantDialogService
        .sendQuestionAI(aiData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (resp) => {
            if (!resp.questions) {
              this.isLoadingContent = false;
              this.toastr.error("AI didn't recognize input data");
              return;
            }

            resp.questions.forEach((itemFirst, i) => {
              const answerType = this.checkAnswerType(itemFirst.answer_format);
              const hasChildrenFirst = itemFirst.questions && itemFirst.questions.length > 0;
              const questionFirstLevel = this.setupQuestion(answerType, itemFirst, hasChildrenFirst, `${i}`);
              this.questions.push(questionFirstLevel);

              if (hasChildrenFirst) {
                itemFirst.questions.forEach((itemSecond, j) => {
                  const answerTypeSecond = this.checkAnswerType(itemSecond.answer_format);
                  const hasChildrenSecond = itemSecond.questions && itemSecond.questions.length > 0;
                  const questionSecondLevel = this.setupQuestion(answerTypeSecond, itemSecond, hasChildrenSecond, `${i}.${j}`);
                  this.questions[i].children.push(questionSecondLevel);

                  if (hasChildrenSecond) {
                    itemSecond.questions.forEach((itemThird, k) => {
                      const answerTypeThird = this.checkAnswerType(itemThird.answer_format);
                      const questionThirdLevel = this.setupQuestion(answerTypeThird, itemThird, false, `${i}.${j}.${k}`);
                      this.questions[i].children[j].children.push(questionThirdLevel);
                    });
                  }
                });
              }
            });

            this.questionChange(this.questions);
            this.originalQuestionContent = resp.content;

            if (resp.included_images && resp.included_images.length > 0) {
              this.uploadIncludedImages(resp.included_images);
            }

            this.cdr.detectChanges();
            this.isLoadingContent = false;
          },
          (error) => {
            this.isLoadingContent = false;
            this.toastr.error('Something went wrong with the AI Assistant. Please try again later.');
          },
        );
    }
  }

  private searchQuestionKeys(markItem) {
    let foundMatch = false;
    this.questions.forEach((itemFirst, i) => {
      const hasChildrenFirst = itemFirst.children && itemFirst.children.length > 0;
      if (!itemFirst.is_master && itemFirst.matchNumber === markItem.number) {
        this.setupMarkSchemes([i], markItem);
        foundMatch = true;
      }

      if (hasChildrenFirst) {
        itemFirst.children.forEach((itemSecond, j) => {
          const hasChildrenSecond = itemSecond.children && itemSecond.children.length > 0;
          if (!itemSecond.is_master && itemSecond.matchNumber === markItem.number) {
            this.setupMarkSchemes([i, j], markItem);
            foundMatch = true;
          }

          if (hasChildrenSecond) {
            itemSecond.children.forEach((itemThird, k) => {
              if (!itemThird.is_master && itemThird.matchNumber === markItem.number) {
                this.setupMarkSchemes([i, j, k], markItem);
                foundMatch = true;
              }
            });
          }
        });
      }
    });

    return foundMatch;
  }

  private setMarkSckemeByIndex(markItem, markItemIndex) {
    this.questions.forEach((itemFirst, i) => {
      const hasChildrenFirst = itemFirst.children && itemFirst.children.length > 0;
      if (!itemFirst.is_master && markItemIndex === i) {
        this.setupMarkSchemes([i], markItem);
      }

      if (hasChildrenFirst) {
        itemFirst.children.forEach((itemSecond, j) => {
          const hasChildrenSecond = itemSecond.children && itemSecond.children.length > 0;
          if (!itemSecond.is_master && markItemIndex === j) {
            this.setupMarkSchemes([i, j], markItem);
          }

          if (hasChildrenSecond) {
            itemSecond.children.forEach((itemThird, k) => {
              if (!itemThird.is_master && markItemIndex === k) {
                this.setupMarkSchemes([i, j, k], markItem);
              }
            });
          }
        });
      }
    });
  }

  private setupMarkSchemes(itemIndexes, markItem) {
    if (itemIndexes.length === 1) {
      const question = this.questions[itemIndexes[0]];
      this.questions[itemIndexes[0]] = this.setMatchInQuestions(question, markItem, `${itemIndexes[0]}`);
    }

    if (itemIndexes.length === 2) {
      const question = this.questions[itemIndexes[0]].children[itemIndexes[1]];
      this.questions[itemIndexes[0]].children[itemIndexes[1]] = this.setMatchInQuestions(question, markItem, `${itemIndexes[0]}.${itemIndexes[1]}`);
    }

    if (itemIndexes.length === 3) {
      const question = this.questions[itemIndexes[0]].children[itemIndexes[1]].children[itemIndexes[2]];
      this.questions[itemIndexes[0]].children[itemIndexes[1]].children[itemIndexes[2]] = this.setMatchInQuestions(question, markItem, `${itemIndexes[0]}.${itemIndexes[1]}.${itemIndexes[2]}`);
    }
  }

  private setMatchInQuestions(question, markItem, questionNumber) {
    question.mark = markItem.total_marks;
    question.comments = markItem.comments;

    markItem.answers.forEach((answerItem) => {
      if (question.answer_type === QuestionType.OPEN_ENDED) {
        question.oeq_keywords.push({ key: answerItem.content, mark: +answerItem.marks });
        this.newQuestionService.updateOeqKeywords({ key: answerItem.content, mark: +answerItem.marks, questionNumber });
      }

      if (question.answer_type === QuestionType.SELECT_ONE || question.answer_type === QuestionType.SELECT_MULTIPLE) {
        question.answer_options.push(answerItem.content);
        this.newQuestionService.updateAnswerOptions({ option: answerItem.content, questionNumber });
      }
    });

    return question;
  }

  private async uploadIncludedImages(includedImages: []) {
    for (let i = 0; i < includedImages.length; i++) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(includedImages[i].src, 'IMAGE').toPromise())?.data?.bytes;
      const image = 'data:image/png;base64,' + bytes;
      // @ts-ignore
      includedImages[i].imgHTML = `<img src="${image}" alt="" />`;
      // @ts-ignore
      const imgHTML = `<img src="${image}" alt="" />`;
      // @ts-ignore
      this.replaceImagesInQuestions(includedImages[i].code, imgHTML);
    }

    includedImages.forEach(item => {
      // @ts-ignore
      this.originalQuestionContent = this.originalQuestionContent.replace(item.code, item.imgHTML);
    });
    
    this.cdr.detectChanges();
  }

  private replaceImagesInQuestions(code: string, imgHTML: string) {
    this.questions.forEach((itemFirst, i) => {
      const hasChildrenFirst = itemFirst.children && itemFirst.children.length > 0;
      this.questions[i].question = this.questions[i].question.replace(code, imgHTML);

      if (hasChildrenFirst) {
        itemFirst.children.forEach((itemSecond, j) => {
          const hasChildrenSecond = itemSecond.children && itemSecond.children.length > 0;
          this.questions[i].children[j].question = this.questions[i].children[j].question.replace(code, imgHTML);

          if (hasChildrenSecond) {
            itemSecond.children.forEach((itemThird, k) => {
              this.questions[i].children[j].children[k].question = this.questions[i].children[j].children[k].question.replace(code, imgHTML);
            });
          }
        });
      }
    });

    this.questionChange(this.questions);
  }

  private async uploadIncludedImagesMarkScheme(includedImages: []) {
    for (let i = 0; i < includedImages.length; i++) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(includedImages[i].src, 'IMAGE').toPromise())?.data?.bytes;
      const image = 'data:image/png;base64,' + bytes;
      // @ts-ignore
      includedImages[i].imgHTML = `<img src="${image}" alt="" />`;
      // @ts-ignore
      const imgHTML = `<img src="${image}" alt="" />`;
      // @ts-ignore
      this.replaceImagesInMarkScheme(includedImages[i].code, imgHTML);
    }

    includedImages.forEach(item => {
      // @ts-ignore
      this.originalMarkSchemeContent = this.originalMarkSchemeContent.replace(item.code, item.imgHTML);
    });
    
    this.cdr.detectChanges();
  }

  private replaceImagesInMarkScheme(code: string, imgHTML: string) {
    this.questions.forEach((itemFirst, i) => {
      const hasChildrenFirst = itemFirst.children && itemFirst.children.length > 0;
      this.questions[i].question = this.questions[i].question.replace(code, imgHTML);

      if (hasChildrenFirst) {
        itemFirst.children.forEach((itemSecond, j) => {
          const hasChildrenSecond = itemSecond.children && itemSecond.children.length > 0;
          this.questions[i].children[j].question = this.questions[i].children[j].question.replace(code, imgHTML);

          if (hasChildrenSecond) {
            itemSecond.children.forEach((itemThird, k) => {
              this.questions[i].children[j].children[k].question = this.questions[i].children[j].children[k].question.replace(code, imgHTML);
            });
          }
        });
      }
    });

    this.questionChange(this.questions);
  }

  setupQuestion(answerFormat: string, itemData: IAIQuestions, hasChildren: boolean, number: string) {
    this.answerFormatValue = answerFormat;
    this.answerFormat = this.answerFormatOptions.find((item) => item.value === answerFormat).label;

    let questionText = '';
    if (itemData.intro) questionText = itemData.intro;
    if (itemData.content) questionText = itemData.content;
    if (itemData.intro && itemData.content) questionText = itemData.intro + itemData.content;

    const q = new Question(questionText, this.selectedTopicId ? this.selectedTopicId : this.topicsList[0].value, answerFormat);

    if (itemData.number) q.matchNumber = itemData.number + '';
    if (number) q.itemNumber = number;

    if (hasChildren) {
      q.is_master = true;
      q.mark = null;
      q.answer_type = QuestionType.MULTIPART;
    }
    if (q.answer_type === QuestionType.TRUE_OR_FALSE) {
      q.answer_options = ['True', 'False'];
    }
    if (q.answer_type === QuestionType.SELECT_ONE || q.answer_type === QuestionType.SELECT_MULTIPLE) {
      q.answer_options = ['Option 1', 'Option 2'];
      q.correct_answer_options = [0];
    }
    if (q.answer_type === QuestionType.OPEN_ENDED) {
      q.oeq_keywords = [{ key: 'Answer', mark: 1 }];
    }

    q.is_past_exam_question = false;

    return q;
  }

  private checkAnswerType(type: string): string {
    if (type === 'open-ended') return QuestionType.OPEN_ENDED;
    if (type === 'multiple-choice') return QuestionType.SELECT_ONE;
    if (type === 'multi-select') return QuestionType.SELECT_MULTIPLE;
    if (type === 'true-false') return QuestionType.TRUE_OR_FALSE;
    if (type === 'file') return QuestionType.FILE_INPUT;
  }

  onPaginationQuestionSelected(id: number) {
    this.selectedIndex = id;
  }
}
