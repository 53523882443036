<div fxLayout="column" fxLayoutAlign="start start" class="full-width">
  <div fxFlex="100" fxLayoutAlign="end center" class="full-width">
    <mat-slide-toggle class="example-margin" [color]="'primary'" [checked]="isQuestionOptionsTinyEditor" [(ngModel)]="isQuestionOptionsTinyEditor">
  <span class="mat-body-1">Editor</span>
</mat-slide-toggle>
  </div>
  <curr-dropdown *ngIf="!isQuestionOptionsTinyEditor" (valueChange)="selectQuestionType($event)" class="dp-width"
    placeholder="Type" [defaultValue]="ddQuestionTypeOptions[0].value" [options]="ddQuestionTypeOptions"
    [selectedValue]="question?.answer_type"></curr-dropdown>

  <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
    <ng-container [ngSwitch]="question?.answer_type">
      <!--        RADIO-->
      <div class="full-width m-t-24" *ngSwitchCase="questionType.SELECT_ONE">
        <span *ngIf="!question?.answer_options.length" class="mat-body-2 m-b-16"> Please add answers </span>
        <curr-question-answer-variant [question]="question" [form]="form"
          [isQuestionOptionsTinyEditor]="isQuestionOptionsTinyEditor" (onDelete)="deleteOption($event)"
          (onSetCorrectAnswer)="setCorrectRadioAnswer($event)">
        </curr-question-answer-variant>

      </div>

      <!--          OPEN ANSWER-->
      <div class="m-t-24 full-width" *ngSwitchCase="questionType.SINGLE_ANSWER">
        <ng-container *ngTemplateOutlet="rubrics"></ng-container>
      </div>
      <!--          FILE-->
      <div class="m-t-24 full-width" *ngSwitchCase="questionType.FILE_INPUT">
        <ng-container *ngTemplateOutlet="rubrics"></ng-container>
      </div>
      <!--          OPEN-ENDED-->
      <div class="m-t-24 full-width" *ngSwitchCase="questionType.OPEN_ENDED">
        <ng-container *ngTemplateOutlet="rubrics"></ng-container>
        <ng-container *ngTemplateOutlet="keywordsTable"></ng-container>
      </div>

      <!--        TRUEFALSE-->
      <div class="m-t-24" *ngSwitchCase="questionType.TRUE_OR_FALSE">
        <mat-radio-group fxLayoutGap="24px" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="start center" class="editable-option"
            [class.right-answer]="question?.correct_answer_options[0] === i"
            *ngFor="let option of question?.answer_options; let i = index">
            <mat-radio-button [value]="i" [checked]="question?.correct_answer_options[0] == i"
              (click)="setCorrectRadioAnswer(i)" color="accent"></mat-radio-button>
            <span>{{ option }}</span>
          </div>
        </mat-radio-group>
      </div>

      <!--        CHECKBOX-->
      <div class="full-width m-t-24" *ngSwitchCase="questionType.SELECT_MULTIPLE">
        <span *ngIf="!question?.answer_options?.length" class="mat-body-2 m-t-16 m-b-16"> Please add answers </span>
        <curr-question-answer-variant [question]="question" [form]="form"
          [checkBoxCorrectAnswers]="checkBoxCorrectAnswers" [isQuestionOptionsTinyEditor]="isQuestionOptionsTinyEditor"
          (onDelete)="deleteOption($event)" (onCheckBox)="checkBoxClick($event.event, $event.i)"
          (onSetCorrectAnswer)="setCorrectRadioAnswer($event)">
        </curr-question-answer-variant>
      </div>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px"
      *ngIf="question?.answer_type === questionType.SELECT_ONE || question?.answer_type === questionType.SELECT_MULTIPLE">

      <curr-button variant="outlined" icon="plus" (btnClick)="addAnswer()">ADD ANSWER
      </curr-button>
<!--      <curr-button (btnClick)="saveOptions()">SAVE OPTIONS</curr-button>-->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="m-t-32"
    *ngIf="!inSowPage && !isMultipartQuestion">
    <curr-button variant="contained" [isDisabled]="canSaveAnswer()" (btnClick)="saveQuestion('isEditQuestionMode')">SAVE
    </curr-button>
    <curr-button variant="text" (btnClick)="cancelEdit('isEditQuestionMode')">CANCEL</curr-button>
  </div>
</div>


<ng-template #rubrics>
  <div fxLayoutAlign="space-between start" fxLayout="column" class="full-width"
    *ngIf="question?.answer_type === questionType.OPEN_ENDED">
    <curr-checkbox  [options]="[{label: 'Show workings', value: false}]"
      (valueChange)="setWorkings($event)">
    </curr-checkbox>
  </div>
  <div fxLayoutAlign="start" fxLayout="column" class="full-width">
    <span class="header mat-h4 m-b-16" fxFlex>{{isCTQuestion ? 'Mark scheme' : 'Answer'}}:</span>
    <curr-textarea *ngIf="!isQuestionOptionsTinyEditor" [currMath]="question.rubric"
      [label]="isCTQuestion ? 'Mark scheme' : 'Answer'" rows="auto" class="full-width"></curr-textarea>

    <curr-tiny-editor *ngIf="isQuestionOptionsTinyEditor" (valueChange)="update($event)"
     [formGroup]="form" controlName="scheme"  [value]="question.rubric"
      [options]="{height: 300, statusbar: false}"></curr-tiny-editor>
  </div>
</ng-template>

<ng-template #keywordsTable>
  <div fxLayout="column" fxLayoutAlign="start start" class="m-t-24">
    <div class="mat-h4 m-b-8">
      Keywords:
    </div>
    <curr-table [inEditMode]="isEditQuestionMode" [tableData]="question.oeq_keywords"
      (onKeywordsUpdate)="updateKeyword($event)"></curr-table>
  </div>
</ng-template>
