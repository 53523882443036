<!-- Created by Maxim Bon. on 15/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="question-row" *ngIf="question.is_master">
  <div [currMath]="question.question"></div>
</div>

<ng-container *ngIf="!question.is_master">
  <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
    <ng-container [ngSwitch]="question?.answer_type">
      <!-- RADIO -->
      <div *ngSwitchCase="questionType.SELECT_ONE" class="full-width">
        <curr-new-question-answer-variant
          [form]="form"
          [question]="question"
          (onUpdateQuestion)="updateQuestionData($event)"
        >
        </curr-new-question-answer-variant>
      </div>
  
      <!-- OPEN-ENDED -->
      <div *ngSwitchCase="questionType.OPEN_ENDED" class="full-width">
        <ng-container *ngTemplateOutlet="keywordsTable"></ng-container>
      </div>
  
      <!-- TRUE/FALSE -->
      <div class="question-row m-b-8" *ngSwitchCase="questionType.TRUE_OR_FALSE">
        <div class="question-row-subtitle m-b-0"></div>
        <mat-radio-group>
          <div
            class="true-false-radio"
            [class.right-answer]="question?.correct_answer_options[0] === i"
            *ngFor="let option of question?.answer_options; let i = index"
          >
            <mat-radio-button
              [value]="i"
              [checked]="question?.correct_answer_options[0] == i"
              (click)="setCorrectTrueFalseAnswer(i)"
              color="accent"
            ></mat-radio-button>
            <span>{{ option }}</span>
          </div>
        </mat-radio-group>
      </div>
  
      <!-- CHECKBOX -->
      <div *ngSwitchCase="questionType.SELECT_MULTIPLE" class="full-width">
        <curr-new-question-answer-variant
          [form]="form"
          [question]="question"
          (onUpdateQuestion)="updateQuestionData($event)"
        >
        </curr-new-question-answer-variant>
      </div>
    </ng-container>
  </div>
  
  <div class="marks-section m-b-16">
    <span class="marks-section-title" *ngIf="question.answer_type !== questionType.MULTIPART">Marks: </span>
    <curr-input
      inputType="number"
      [isCentered]="true"
      *ngIf="question.answer_type !== questionType.MULTIPART"
      class="number-input curr-input"
      [value]="question?.mark"
      (valueChange)="setMark($event)"
    ></curr-input>
  </div>
  
  <div class="question-row m-b-16">
    <div class="question-row-subtitle">
      <span>Comments / Guidelines</span>
      <mat-slide-toggle [color]="'primary'" [checked]="isCommentsTinyEditor" [(ngModel)]="isCommentsTinyEditor">
        <span class="editor-toggle">Editor</span>
      </mat-slide-toggle>
    </div>
  
    <curr-tiny-editor
      *ngIf="isCommentsTinyEditor && !isLoadEditor"
      [formGroup]="commentsForm"
      controlName="commentsData"
      [options]="{
        placeholder: 'Comments / Guidelines',
        height: 116,
        disabled: false,
        menubar: 'menubar',
        statusbar: false
      }"
      [value]="question.comments ?? ''"
      (valueChange)="updateQuestionCommentValue($event)"
      (onFocusOut)="updateCommentOutFocus()"
    ></curr-tiny-editor>
  
    <div [currMath]="question.comments ?? ''" *ngIf="!isCommentsTinyEditor"></div>
  </div>
</ng-container>

<ng-template #keywordsTable>
  <div class="question-row full-width m-b-8">
    <curr-new-keywords
      [form]="form"
      [question]="question"
      (onKeywordsUpdate)="updateKeyword($event)"
    ></curr-new-keywords>
  </div>
</ng-template>
