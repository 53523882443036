import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Question } from '../../../student/test.model';

export interface PaginationLabel {
  label: string;
  isAutomarked: boolean;
  id: number;
  questionInfoId?: number;
}

@Component({
  selector: 'curr-question-pagination',
  templateUrl: './question-pagination.component.html',
  styleUrls: ['./question-pagination.component.scss']
})
export class QuestionPaginationComponent implements OnInit, OnChanges {
  @ViewChild('pagination') pagination: ElementRef;
  @Input() questionList: Question[] = [];
  @Input() checkedQuestions: number[] = [];
  @Input() isSideBtnVisible = false;
  @Input() selectedIndex = 0;
  @Input() isTTTeacher: boolean = false;
  @Output() questionSelected = new EventEmitter<string>();
  @Output() paginationLabelsReady = new EventEmitter<PaginationLabel[]>();

  paginationLabels: PaginationLabel[] = [];
  selectedLabel: PaginationLabel;
  currentSelectedPaginationIndex: number;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isTTTeacher) {
      if (!changes.selectedIndex.isFirstChange()) {
        if (Math.abs(changes.selectedIndex.currentValue - changes.selectedIndex.previousValue) === 1) {
          this.selectQuestion(this.selectedIndex);
        }
      }
    }
  }

  ngOnInit(): void {
    this.createPaginationLabels(this.questionList);
  }

  prevQuestion() {
    if (this.currentSelectedPaginationIndex > 0) {
      const i = this.currentSelectedPaginationIndex - 1;
      this.selectQuestion(i);
      // this.pagination.nativeElement.scrollLeft  -= 73;
    }
  }

  nextQuestion() {
    if (this.currentSelectedPaginationIndex < (this.paginationLabels.length - 1)) {
      const i = this.currentSelectedPaginationIndex + 1;
      this.selectQuestion(i);
      // this.pagination.nativeElement.scrollLeft += 73;
    }
  }

  selectQuestion(i: number) {
    if (this.paginationLabels.length === i) {
      const lastQuestionIndex = i - 1;
      this.selectedLabel = this.paginationLabels[lastQuestionIndex];
      this.currentSelectedPaginationIndex = lastQuestionIndex;
    }
    if (i === this.currentSelectedPaginationIndex || !this.paginationLabels[i]) {
      return;
    }
    if (i < this.currentSelectedPaginationIndex && this.pagination) {
      this.pagination.nativeElement.scrollLeft  -= 73;
    }
    if (i > this.currentSelectedPaginationIndex && this.pagination) {
      this.pagination.nativeElement.scrollLeft += 73;
    }
    if (this.paginationLabels[i]) {
      this.selectedLabel = this.paginationLabels[i];
      this.currentSelectedPaginationIndex = i;
    }
    this.questionSelected.emit(this.selectedLabel.id.toString());
  }

  private createPaginationLabels(list: Question[]) {
    if (!list.length) {
      return;
    }
    // list.forEach((q, i) => {
    //   this.getPaginationChildrenLabels(q, `${i + 1}`, q.id);
    // });
    this.extractPaginationLLabels(this.questionList)
    this.paginationLabelsReady.emit(this.paginationLabels);
    this.selectQuestion(this.selectedIndex);
  }

  private extractPaginationLLabels(questions: Question[], parentLabel?: PaginationLabel) {
    questions.forEach((q, i) => {
      const label = parentLabel ? `${parentLabel?.label}.${i + 1}` : `${i + 1}`;
      const paginationLabel: PaginationLabel = {
        id: q.id,
        label,
        isAutomarked: q.isAutomarked,
        questionInfoId: q.id,
      };
      this.paginationLabels.push(paginationLabel)
      if (q?.children) {
        this.extractPaginationLLabels(q.children, paginationLabel)
        return
      }
    })
  }


  // getPaginationChildrenLabels(question: Question, prependIndex: string, questionInfoId: number) {
  //   const paginationLabel = { id: question.id, label: `${prependIndex}`, isAutomarked: question.isAutomarked, questionInfoId };
  //   if (question?.children?.length) {
  //     this.paginationLabels.push(paginationLabel);
  //     question.children.forEach((q, i) => {
  //       this.getPaginationChildrenLabels(q, `${prependIndex}.${i + 1}`, questionInfoId);
  //     });
  //     return;
  //   }
  //   this.paginationLabels.push(paginationLabel);
  // }

  getColor(isAutomarked: boolean): string {
    switch (isAutomarked) {
      case true:
        return 'green';
      case false:
        return 'red';
      default:
        return 'black';
    }
  }

  setItemLineWidth(): void {
    // let elements = document.getElementsByClassName('.pagination-list');
    // let width = elements[0].;
    // debugger
  }
}
