<div class="wrapper" [@fade]>
  <div class="dropdown" (click)="toggleDropdown()" [ngClass]="{ open: isShowed, error: isError }" currClickOutside
    (clickOutside)="closeDropDown()">
    <div class="content-wrapper" [ngClass]="{ disabled: isDisabled }" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="content">
        <div class="custom-select__trigger mat-body-2 ">
          <div class="cursor-pointer text placeholder-text" *ngIf="selected.size === 0">{{ label ? getNewPlaceholder() : placeholder }}</div>

          <div class="chips-section" *ngIf="selected.size > 0 && isMultiSelectedChips">
            <div class="chips-item" *ngFor="let selectedItem of selected; let i = index;">
              <div class="chips-item-text">{{selectedItem.label}}</div>
              <curr-icon-button icon="close" (click)="selectValue(selectedItem, $event)"></curr-icon-button>
            </div>
          </div>

          <div class="cursor-pointer text" *ngIf="selected.size > 0 && !isMultiSelectedChips" [currMath]="selectedLabel"></div>
        </div>
        <div class="arrow-wrapper" ><i class="arrow up"></i></div>
      </div>
    </div>
    <div class="custom-options" (click)="$event.stopImmediatePropagation()" [@listAnimation]="newOptions.length" *ngIf="options.length > 0 && isShowed">
      <div currCustomScroll class="custom-options-wrapper ps">
        <span *ngFor="let option of newOptions" class="custom-option cursor-pointer mat-body-2 text"
          (click)="selectValue(option, $event)"
          [class.selected]="option.selected"
          [class.disabled]="option.disabled"
          [currMath]="option.label">
          <mat-icon *ngIf="icon" [svgIcon]="icon" class="option-icon" [class]="icon"></mat-icon>
        </span>
      </div>
    </div>
  </div>

  <span *ngIf="isError" class="error-message">Please select value</span>
</div>
