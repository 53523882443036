<!-- Created by Max Bon. on 29/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div *ngIf="!teacherNotesForStudent.length" fxLayout="row" fxLayoutAlign="center center">
  <span class="mat-body-1">No notes</span>
</div>
<ng-container *ngFor="let note of notes; let i = index">
  <div fxLayout="column" fxLayoutAlign="start stretch" class="m-t-16"
       [id]="'notesContainer-'+note.id">
    <div class="title mat-body-2 m-b-8 noselect">{{ note.title }}:</div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container">
      <div class="note-text mat-body-1 m-b-32" [innerHTML]="note.note"
           (mouseup)="onSelect($event, note)">
      </div>

      <!--      TEXT HINT-->
      <div class="popup-container hint" *ngIf="hintOnHover.length"
           [style.left.px]="hintCoordinates.x"
           [style.top.px]="hintCoordinates.y">
        <div class="hint-container" fxLayout="row" fxLayoutAlign="start center">
          <span class="mat-body-1">{{hintOnHover}}</span>
        </div>
      </div>

<!--  HOVERED IMAGE-->
      <div class="popup-container" *ngIf="currentImageUrlOnHover.length"
           [style.left.px]="imageUrlPreviewCoordinates.x"
           [style.top.px]="imageUrlPreviewCoordinates.y">
        <curr-student-notes-image-preview [imageSource]="currentImageUrlOnHover"
                                          [isHighlighted]="isImageHighlighted"
                                          [previewWidth]="previewWidth"
                                          [noteText]="imageHintOnHover"
                                          [highlightColor]="currentImageColorOnHover"
                                          (createImageNote)="createImageNote($event)"
        ></curr-student-notes-image-preview>
      </div>

      <!--      CONTEXT MENU -->
      <div class="popup-container" *ngIf="selectedText.length"
           [style.left.px]="contextMenuCoordinates.x"
           [style.top.px]="contextMenuCoordinates.y">
        <curr-student-create-note-custom-context-menu (colorEvent)="onColorSelectEvent($event)">
        </curr-student-create-note-custom-context-menu>
      </div>
      <div class="popup-background" *ngIf="selectedText.length" (click)="clearSelection()"></div>
    </div>
  </div>
</ng-container>
