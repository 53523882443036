/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractHasAccessDirective } from './abstract-has-access.directive';
import { User } from '../user';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';
import { Role } from '../role';

@Directive({
  selector: '[currHasRoles]'
})
export class HasRolesDirective extends AbstractHasAccessDirective {
  @Input() currHasRoles: Role[];

  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected templateRef: TemplateRef<any>,
    public userService: UserService
  ) {
    super(viewContainerRef, templateRef, userService);
  }

  hasAccess(user: User): Observable<boolean> {
    return this.userService.hasRoles(this.currHasRoles);
  }
}
