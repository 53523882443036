/*
 * Created by Dmytro Sav. on 4/29/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';

interface Question {
  label: string;
  count: number;
}

@Component({
  selector: 'curr-answers-incorrect',
  templateUrl: './answers-incorrect.component.html',
  styleUrls: ['./answers-incorrect.component.scss']
})
export class AnswersIncorrectComponent implements OnInit {
  @Input() questions: Array<Question>;

  constructor() {}

  ngOnInit() {
  }
}
