/**
 * Created by Maxim B. on 25/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'curr-concern-widget',
  templateUrl: './concern-widget.component.html',
  styleUrls: ['./concern-widget.component.scss']
})
export class ConcernWidgetComponent implements OnInit, AfterViewInit {

  @Input() concern: number;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('progressValGreen') private progressValGreen: ElementRef;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('progressValYellow') private progressValYellow: ElementRef;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('progressValRed') private progressValRed: ElementRef;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('svgGreen') private svgGreen: ElementRef;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('svgYellow') private svgYellow: ElementRef;
  // tslint:disable-next-line:no-unused-variable
  @ViewChild('svgRed') private svgRed: ElementRef;
  @ViewChild('secondHalf') private secondHalf: ElementRef;
  @ViewChild('firstHalf') private firstHalf: ElementRef;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initProgress();
  }

  initProgress() {
    if (this.concern < 0.5) {
      const angle = 180 * this.concern;
      this.renderer.setStyle(this.firstHalf.nativeElement, 'transform', `rotate(-${ angle * 2 }deg)`);
    } else if (this.concern > 0.5 && this.concern < 1) {
      // const angle = 180 * (this.concern - 0.5) * 100;
      const angle = 180 * ((this.concern - 0.5) * 2);
      this.renderer.setStyle(this.secondHalf.nativeElement, 'transform', `rotate(${ angle }deg)`);
    } else if (this.concern >= 1) {
      this.renderer.setStyle(this.secondHalf.nativeElement, 'transform', `rotate(180deg)`);
    }

  }

}
