<!-- Created by Maxim B. on 02/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->


<!--<div style="border: 1px solid black; padding: 8px; margin:8px">-->
<!--  <curr-question-pagination *ngIf="previewQ.length"-->
<!--                            [questionList]="previewQ"-->
<!--                            [checkedQuestions]="[]"-->
<!--                            [isSideBtnVisible]="false"-->
<!--                            (questionSelected)="selectQuestion($event)"-->
<!--                            (paginationLabelsReady)="pagiReady($event)"-->
<!--  ></curr-question-pagination>-->
<!--</div>-->
<!--<curr-question-card-->
<!--  *ngIf="selectedQuestion"-->
<!--  [cou]="[]"-->
<!--  [topics]="[]"-->
<!--  (onQuestionDeleted)="info()"-->
<!--  questionTestType="ct"-->
<!--  [question]="selectedQuestion"-->
<!--  [questionNumber]="selectedIndex">-->
<!--</curr-question-card>-->
<!--<curr-question-card-->
<!--  [cou]="[]"-->
<!--  [topics]="[]"-->
<!--  (onQuestionDeleted)="info()"-->
<!--  questionTestType="ct"-->
<!--  [question]="newQuestion"-->
<!--  [questionNumber]="1"-->
<!--&gt;-->
<!--</curr-question-card>-->

<br><br>
<!--<div class="create-question-container default-card-border-view m-b-16 m-r-16 m-l-16 m-t-16" >-->
<div class="full-width" fxLayoutAlign="center start" fxLayout="row">
  <div style="border: 1px solid black; padding: 8px; margin:8px; width:800px">
    <curr-edit-question-block [isCTQuestion]="true" [inSowPage]="false"
                              [question]="newQuestion" [topics]="[]" [cou]="[]"></curr-edit-question-block>

  </div>
</div>

<curr-button (btnClick)="saveQuestion()">SAVE</curr-button>
<!--  <div fxLayoutAlign="start start" fxLayout="row" class="m-t-16">-->
<curr-button (click)="info()">INFO</curr-button>
<!--  </div>-->
<!--</div>-->
