import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { map } from 'rxjs/operators';

export interface StatisticRequestParams {
  schoolYearSettingsId?: number;
  tabName?: 'STUDENT' | 'TEACHER' | 'STUDENT_UNDERSTANDING' | 'TEACHER_UNDERSTANDING';
  statPeriod?: string;
  rowsCount?: number;
  rowOffset?: number;

  subjectName?: string | null;
  stCourseId?: number | null;
  studyYearId?: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class StatisticTableService {
  constructor(private httpClient: ApplicationHttpClient) {}

  /*
  * statPeriod - MONTH, SEMESTER, YEAR
    tabName - STUDENT, TEACHER, STUDENT_UNDERSTANDING, TEACHER_UNDERSTANDING
    schoolYearSettingsId -
    rowsCount -
    rowOffset -
  * */
  getStatistic(o: StatisticRequestParams) {
    let url = `/userStatistic?`;
    o.statPeriod ? (url += `statPeriod=${o.statPeriod}`) : (url += `statPeriod=YEAR`);
    o.tabName ? (url += `&tabName=${o.tabName}`) : (url += `&tabName=STUDENT`);
    o.schoolYearSettingsId
      ? (url += `&schoolYearSettingsId=${o.schoolYearSettingsId}`)
      : (url += `&schoolYearSettingsId=6`);
    o.rowsCount ? (url += `&rowsCount=${o.rowsCount}`) : (url += `&rowsCount=10000`);
    o.rowOffset ? (url += `&rowOffset=${o.rowOffset}`) : (url += `&rowOffset=0`);
    o.subjectName ? (url += `&subjectName=${o.subjectName}`) : (url += ``);
    o.stCourseId ? (url += `&stCourseId=${o.stCourseId}`) : (url += ``);
    o.studyYearId ? (url += `&studyYearId=${o.studyYearId}`) : (url += ``);

    return this.httpClient.get(url).pipe(map(r => (r as any).data.items));
  }

  getFilters() {
    return this.httpClient.get('/filter/options/basic').pipe(map(r => (r as any).data));
  }

  setMeeting(course_student_id: number, statistic_meeting_type: string) {
    return this.httpClient.post(
      `/userStatistic/meeting?course_student_id=${course_student_id}&statistic_meeting_type=${statistic_meeting_type}`,
      {}
    );
  }

  setPredictedGradeForStudent(course_student_id: number, predicted_grade: string) {
    return this.httpClient.put(
      `/userStatistic/predictedGrade/student?course_student_id=${course_student_id}&predicted_grade=${predicted_grade}`,
      {}
    );
  }

  setPredictedGradeForTeacher(curriculum_teacher_id: number, predicted_grade: string) {
    return this.httpClient.put(
      `/userStatistic/predictedGrade/teacher?curriculum_teacher_id=${curriculum_teacher_id}&predicted_grade=${predicted_grade}`,
      {}
    );
  }
}
