<div class="calendar" fxLayout="column" [@fade]>
  <span class="title">Calendar</span>
  <div class="body" fxLayout="column">
    <div class="upper m-b-20" fxLayout="row" fxLayoutAlign="space-between">
      <div class="date">
        <span class="month m-r-4">{{ currentMonth }}</span>
        <span class="year">{{ currentYear }}</span>
      </div>
      <div class="arrows">
        <mat-icon (click)="getPrevWeek()" class="arrow">keyboard_arrow_left</mat-icon>
        <mat-icon (click)="getNextWeek()" class="arrow">keyboard_arrow_right</mat-icon>
      </div>
    </div>
    <div class="middle m-b-16" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="day" fxLayout="column" fxLayoutAlign="center center"
           (click)="selectDay(i)"
           [ngClass]="{'selected': day.selected, 'now': day.isToday}"
           *ngFor="let day of days; index as i">
        <span class="day-title">{{ day.dayOfWeek.substring(0, 3) }}</span>
        <div class="day-body">
          <span class="day-number">{{ getDayNumber(day.date) }}</span>
          <div fxLayout="row">
            <div class="day-event tutor-session" *ngIf="hasDayEvent(day.events, 'TOPIC_TEST')"></div>
            <div class="day-event topic-test" *ngIf="hasDayEvent(day.events, 'TUTORIAL_SESSION')"></div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="selectedDay" class="lower" [@fade]>
      <span class="title-tomorrow" [@fade]>{{ getDayTitle() }}</span>
      <div class="events" fxLayout="column">
        <div class="event m-t-8" fxLayout="row" *ngFor="let event of selectedDay.events" [@fade]>
          <div class="icon topic-test m-r-8" *ngIf="event.activityType === 'TUTORIAL_SESSION'"></div>
          <div class="icon tutor-session m-r-8" *ngIf="event.activityType === 'TOPIC_TEST'"></div>
          <div class="event-body">
            <div class="time" fxLayout="row" fxLayoutAlign="space-between center">
              <span class="time-text"> {{timeDateService.convertToLocalTime(selectedDay.date, event.startTime) | date:'shortTime'}} - {{timeDateService.convertToLocalTime(selectedDay.date, event.endTime) | date:'shortTime'}}</span>
              <mat-icon class="icon-meet" [svgIcon]="'meet'"></mat-icon>
            </div>
            <span class="event-info">{{ event.subject }}: {{ getActivityType(event.activityType) }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="!selectedDay.events.length" class="no-events" fxLayout="column" fxLayoutAlign="space-between center" [@fade]>
        <mat-icon class="no-events-img" [svgIcon]="'no-events'"></mat-icon>
        <span class="no-events-title">You have no events yet.</span>
      </div>
    </div>
  </div>
</div>
