import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../../../shared/api/application-http-client';

const endpoint = 'curriculumLearningObjectiveNotes';

@Injectable({
  providedIn: 'root'
})
export class StudentNotesService {
  constructor(private httpService: ApplicationHttpClient) {}

  getNotes(curriculumLearningObjectiveId: number) {
    return this.httpService.get(`/${endpoint}?curriculumLearningObjectiveId=${curriculumLearningObjectiveId}`);
  }

  getNotesById(curriculumLearningObjectiveId: number) {
    return this.httpService.get(`/${endpoint}?curriculumLearningObjectiveId=${curriculumLearningObjectiveId}`);
  }

  saveNote(note: any) {
    return this.httpService.post(`/${endpoint}`, note);
  }

  updateNote(note: any) {
    return this.httpService.put(`/${endpoint}`, note);
  }

  deleteNote(curriculumTopicId: number) {
    return this.httpService.delete(`/${endpoint}/${curriculumTopicId}`);
  }

  getSATests(curriculum_learning_objective_id: number, schoolYearCourseStudentId: number) {
    return this.httpService.get(`/studentSaTests/studentNotes/${curriculum_learning_objective_id}/${schoolYearCourseStudentId}`);
  }
}
