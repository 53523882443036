<!--
 Created by Dmytro Sav. on 4/15/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="tests-container" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="center center">
    <div class="left-container" fxLayout="column" fxLayoutAlign="center start">
      <span [routerLink]="'/subjectsUpdate/' + card.id" class="name mat-h2">{{ card?.name }}</span>

      <div class="details-container" fxLayout="row" fxLayoutAlign="start center">
        <span>
          <span class="field">ID: </span>
          <span class="value mat-body-2">{{ card?.id }}</span>
        </span>
        <span class="divider">|</span>

        <span>
          <span class="field">Total Lessons: </span>
          <span class="mat-body-2">{{ card?.totalLessons }}</span>
        </span>

        <span class="divider">|</span>

        <span>
          <span class="field">Author: </span>
          <span class="mat-body-2">{{ card?.Author }}</span>
        </span>
      </div>
    </div>
    <curr-red-label
      *ngIf="card?.isInComplete"
      [text]="'Self Assesments are incomplete'"
      class="m-l-40"
    ></curr-red-label>
  </div>

  <div *ngIf="type === 'published'" class="right-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
  </div>

  <div *ngIf="type === 'draft'" class="right-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <curr-button
      [style]="btnPublishStyle"
      (btnClick)="publish.emit(true)"
      variant="contained"
      color="primary"
      size="small"
      icon="icon-done"
    >
      PUBLISH
    </curr-button>
    <curr-button
      [style]="btnTrashStyle"
      (btnClick)="delete.emit(true)"
      variant="contained"
      color="warn"
      size="small"
      icon="trash"
    >
      DELETE
    </curr-button>
  </div>
</div>
