import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditNoteAboutStudentPopupComponent } from './edit-note-about-student-popup/edit-note-about-student-popup.component';
import { NotesAboutStudentService } from './notes-about-student.service';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../../shared/user';

export interface NoteAboutStudent {
  created_at?: string;
  id?: number;
  note?: string;
  student_id?: number;
  teacher_first_name?: string;
  teacher_id?: number;
  teacher_last_name?: string;
  updated_at?: string;
}

@Component({
  selector: 'curr-notes-about-student',
  templateUrl: './notes-about-student.component.html',
  styleUrls: ['./notes-about-student.component.scss']
})
export class NotesAboutStudentComponent implements OnInit {
  notes: NoteAboutStudent[] = [];
  @Input() studId = 0;
  @Input() currUser: User = {};
  selectedIndex = 0;
  itemsPerPage = 10;
  isLoading = false;
  dataFromApi;

  constructor(public dialog: MatDialog,
              private toastr: ToastrService,
              private nas: NotesAboutStudentService) {
  }

  ngOnInit(): void {
    this.loadNotes();
  }

  selectedIndexChange(ind: number) {
    this.selectedIndex = ind;
  }

  deleteNote(note: NoteAboutStudent, i: number) {
    this.nas.deleteNoteById(note.id)
      .subscribe(resp => {
        this.toastr.success('Note was successfully deleted');
        this.notes.splice(i, 1);
      });
  }

  openAddNote() {
    const dialogRef = this.dialog.open(EditNoteAboutStudentPopupComponent, {
      width: '500px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.saveNote(result);
      }
    });
  }

  changeNote(note: NoteAboutStudent, i: number) {
    const dialogRef = this.dialog.open(EditNoteAboutStudentPopupComponent, {
      width: '500px',
      data: note
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notes[i].note = result;
        this.updateNote(result, note.id);
      }
    });
  }

  saveNote(note: string) {
    this.nas.createNote(note, this.studId)
      .subscribe((resp: any) => {
        this.toastr.success('Note was successfully added');
        this.loadNotes();
      });
  }

  updateNote(note: string, id: number) {
    this.nas.updateNote(note, id)
      .subscribe((resp: any) => {
        this.toastr.success('Note was successfully updated');
      });
  }

  private loadNotes(limit: number = 10, offset: number = 0) {
    if (this.studId) {
      this.isLoading = true;
      this.nas.getNotesByStudId(this.studId, limit, offset)
        .subscribe((resp: any) => {
          this.notes = resp?.data?.content;
          this.dataFromApi = resp?.data;
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
        });
    }
  }

  pageSelected(page: number) {
    this.loadNotes(this.itemsPerPage, page);
  }

  checkOwnNotes() {
    if (this.notes && this.notes.find(item => item.teacher_id === this.currUser.userId)) return false;
    return true;
  }
}
