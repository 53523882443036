<!-- Created by Alex Poh. on 26/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row">
    <mat-card
      class="container m-b-40"
      fxFlex="552px"
      fxFlex.lt-sm="90vw"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="mat-h6 m-b-8 text-centered email-phone" fxFlex>{{ 'signIn.enterEmailOrPhone' | translate }}</div>
      <div class="mat-h1 m-b-8 text-centered signin-text" fxFlex>{{ 'signIn.weWillSend' | translate }}</div>
      <div class="mat-h1 m-b-24 text-centered signin-text" fxFlex>{{ 'signIn.noPasswordNeeded' | translate }}</div>
      <div fxLayout="row" ngClass.lt-sm="full-width">
        <form
          [formGroup]="form"
          fxLayout="column"
          fxFlex.gt-xs="500px"
          fxFlex.lt-sm="100%"
          fxLayoutGap="11px"
          fxLayoutAlign="center stretch"
        >
          <curr-auth-input
            fxFlex="auto"
            class="curr-auth-input"
            ngClass.lt-sm="full-width w100"
            placeholder="{{ 'signIn.emailOrPhone' | translate }}"
            controlName="emailOrPhone"
            [formGroup]="form"
            errorMessage="Enter correct email"
          ></curr-auth-input>
          <div fxLayout="row" fxLayoutAlign="center" class="m-b-40">
            <curr-button
              class="next-btn"
              *ngIf="!isLoading; else loading"
              (btnClick)="submit()"
              variant="contained"
              icon="arrow-right"
              size="big"
              fxFlex="125px"
              fxFlexAlign="center"
            >
              {{ 'signIn.next' | translate }}
            </curr-button>
          </div>
        </form>
      </div>
    </mat-card>
  </div>
  <div class="mat-body-1 text-centered" ngClass.lt-sm="m-l-8 m-r-8">
    Not your computer? Use guest mode to sign in privately.
    <a mat-button color="primary" routerLink="." class="text-underline p-l-8 p-r-8"
      ><span class="mat-body-2">Learn more</span></a
    >
  </div>
</div>

<ng-template #loading><curr-loader [isLoading]="isLoading" [diameter]="48"></curr-loader></ng-template>
