import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

@Component({
  selector: 'curr-student-profile-subject-table',
  templateUrl: './student-profile-subject-table.component.html',
  styleUrls: ['./student-profile-subject-table.component.scss',
    '../../../shared/components/user-statistic-table/user-statistic-table.component.scss']
})
export class StudentProfileSubjectTableComponent implements OnInit, OnChanges {
  @Input() tableData = [];
  studentId;


  tableFields = [
    { fieldName: 'subject', fieldFilter: 'all' },
    { fieldName: 'predicted_grade', fieldFilter: 'all' },
    { fieldName: 'avg_grade', fieldFilter: 'all' },
    { fieldName: 'performance', fieldFilter: 'all' }

  ];
  displayedColumns = ['subject', 'predicted_grade', 'avg_grade', 'performance'];
  STUDENTS_DATA = [];
  dataSource: any;
  isPredictedGradeMenuOpen = false;
  clickedPendingUser = '';
  ddOptionsPredictedGrade = [];
  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    // @ts-ignore
    this.dataSource?.sort = sort;
  }

  constructor(private route: ActivatedRoute) {
    this.ddOptionsPredictedGrade = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'F', label: 'F' }
    ];
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.initTableData();
  }


  ngOnInit(): void {
    this.route.params.pipe(take(1))
      .subscribe(res => {
        this.studentId = res?.studentId;
      });
    this.initTableData();
  }

  initTableData() {
    if (this.tableData.length) {
      this.STUDENTS_DATA = [];

      this.STUDENTS_DATA.push(...this.tableData);
      this.STUDENTS_DATA.forEach(i => {
        i.sa_completion_rate = i.sa_completion_rate > 1 ? i.sa_completion_rate / 100 || 0 : i.sa_completion_rate;
        i.performance = i.performance > 1 ? i.performance / 100 || 0 : i.performance;
      });
      this.dataSource = new MatTableDataSource(this.STUDENTS_DATA);
    }
  }

  clickPredictedGrade($event: MouseEvent, elem: any) {
    this.clickedPendingUser = elem.subject;
    $event.stopPropagation();
    this.isPredictedGradeMenuOpen = true;
  }

  closePredictedGradeMenu() {
    this.isPredictedGradeMenuOpen = false;
  }

  setPredictedGrade(grade: string, el: any) {
    // this.statisticTableService.setPredictedGradeForStudent(el.course_student_id, grade).subscribe(resp => {
    el.predicted_grade = grade;
    // });
  }
}
