import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NotificationMessage } from './notification-message';

@Injectable({ providedIn: 'root' })
export class SendTestNotificationsService {
  private subject = new Subject<NotificationMessage>();

  sendNotification(noti: NotificationMessage) {
    this.subject.next(noti);
  }

  getNotificationSubscription(): Observable<NotificationMessage> {
    return this.subject.asObservable();
  }
}
