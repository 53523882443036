/*
 * Created by Dmytro Sav. on 5/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'curr-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss']
})
export class FormPageComponent implements OnInit {
  path = [
    {
      label: 'Home',
      url: ''
    },
    {
      label: 'Courses',
      url: ''
    }
  ];

  constructor(private router: Router) {}

  ngOnInit() {}

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }
}
