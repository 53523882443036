import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'curr-self-assessments',
  templateUrl: './self-assessments.component.html',
  styleUrls: ['./self-assessments.component.scss']
})
export class SelfAssessmentsComponent implements OnInit {
  currSelectedTabIndex = 0;
  tabLabels = [];
  ddOptions = [];
  tests: any;
  path = [];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.tests = this.route.snapshot.data.tests;
    this.path = [
      {
        label: 'Home',
        url: ''
      },
      {
        label: 'Self Assessments',
        url: ''
      }
    ];
    let lbl = `ALL (${this.tests?.passedCard.length + this.tests?.notPassedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '0' });
    lbl = `PASSED (${this.tests?.passedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '1' });
    lbl = `NOT PASSED (${this.tests?.notPassedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '2' });
  }

  selectTab(val: string) {
    this.currSelectedTabIndex = +val;
  }
}
