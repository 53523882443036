<div class="choose-subject-container" fxLayout="column" fxLayoutAlign="inherit center">
  <div class="choose-subject-header m-t-24 m-l-24 m-r-24">
    <div *ngIf="emptyPlan?.length && !isLoading" class="choose-subject-title mat-h1">Select your services
    </div>
    <div *ngIf="!emptyPlan?.length  && !isLoading" class="choose-subject-title mat-h1">No items to show
    </div>
    <curr-loader *ngIf="isLoading"></curr-loader>
    <div *ngIf="years.length === 2" class="year-level-btn">
      <span class="year-level-title m-r-16 mat-body-2">{{ years[0].studyYearName }} - level</span>
      <mat-slide-toggle [checked]="!checked" (change)="switchYear()"></mat-slide-toggle>
      <span class="year-level-title m-l-16 mat-body-2">{{ years[1].studyYearName }} - level</span>
    </div>
  </div>
  <div class="subject-cards-container" fxLayout="row wrap" fxLayoutAlign="center">
    <!--  TODO: extract as separate sub component  -->
    <ng-container *ngIf="emptyPlan?.length">
      <ng-container *ngFor="let subject of schoolSubjects$ | async; index as index">
        <div [attr.data-id]="subject.id" class="subject-card">

          <div class="subject-img biology" [ngClass]="subject.img | lowercase">
            <img class="image-subject"  src="./assets/images/{{ subject.img | lowercase }}.png" alt="{{subject.name}}">
          </div>
          <div class="subject-description-wrapper biology">
            <div class="subject-description m-t-16 m-l-16 m-b-16">
              <p class="subject-description-title mat-h2">{{ subject.name }}</p>
              <a (click)="goToSubjectInfo(subject.id)" class="subject-description-info mat-button-3">
                Topics to be covered
              </a>
            </div>
            <div class="subject-select m-r-16 m-b-16">
              <div class="cb-container">
                <!--            <curr-checkbox-->
                <!--              type="checkbox"-->
                <!--              class="cb-subject"-->
                <!--              [value]="[]"-->
                <!--              [options]="[{value: '', label: ''}]"-->
                <!--              [color]="cbColor"-->
                <!--              (valueChange)="chooseSubject(subject.id)"-->
                <!--            >-->
                <!--            </curr-checkbox>-->
              </div>
              <!-- TODO in case we will use dropdown -->
              <!-- <curr-dropdown #dropDownElement
              [ngClass]="{ open: isShowed,  highlight: isHighlighted }"
              class="dropdown-subject"
              placeholder="Choose plan"
              [options]="planOptions"
              (valueChange)="choosePlan($event, subject.id)"
            ></curr-dropdown> -->
              <curr-button [color]="isSubjectChosen(subject.id) ? 'primary' : 'accent'"
                (click)="chooseSubject(subject.id)" size="big">
                {{ isSubjectChosen(subject.id) ? 'Remove Subject' : 'Add Subject'}}
              </curr-button>
            </div>
          </div>
        </div>
      </ng-container>
      <div data-id="admission-boost" class="subject-card choose-boost">

        <div class="subject-img biology">
          <img class="image-subject" src="./assets/images/university.png">
        </div>
        <div class="subject-description-wrapper biology">
          <div class="subject-description m-t-16 m-l-16">
            <p class="subject-description-title mat-h2">Uni-Prep Boost</p>
          </div>
          <div class="subject-select m-r-16 m-b-16">
            <div class="cb-container">
            </div>
            <curr-button [color]="isUniversityBoostSelected ? 'primary' : 'accent'"
 
              (click)="chooseUniversityBoost()" size="big">
              {{ isUniversityBoostSelected ? 'Remove Boost' : 'Add Boost'}}
            </curr-button>
          </div>
        </div>
      </div>

      <div class="btn-continue-container">
        <curr-loader *ngIf="isLoading"></curr-loader>
        <button *ngIf="!isLoading" [disabled]="!selectedSubjects?.length && !isUniversityBoostSelected"
          [ngClass]="{'btn-disabled' : !selectedSubjects?.length && !isUniversityBoostSelected}" class="continue-btn-text" mat-button
          (click)="confirmSubjects()">Continue
        </button>
      </div>
    </ng-container>
    <button *ngIf="!emptyPlan?.length && !selectedSubjects?.length" (click)="goWithoutSubjects()"
      class="continue-btn-text" mat-button>Continue
    </button>
  </div>
</div>