/**
 * Created by Daniil Vat. on 7/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { CourseDetailsService } from '../../services/course-details.service';
import { IMostIncorrectQuestions } from '../../interfaces/mostIncorrect-questions';
import { IUnderPerformingStudent } from '../../interfaces/under-performing-student';
import { LearningObjective } from '../../../shared-pages/subject-details/api-subject-details';

@Component({
  selector: 'curr-sa-report',
  templateUrl: './sa-report.component.html',
  styleUrls: ['./sa-report.component.scss']
})
export class SaReportComponent implements OnInit {
  @Input() courseId: string;
  @Input() learningObjectiveId: string;

  mostIncorrectQuestions: IMostIncorrectQuestions;
  underPerformingStudents: IUnderPerformingStudent[];
  learningObjective: LearningObjective;

  constructor(private courseDetailsService: CourseDetailsService) {}

  get navigationLink(): string {
    return `/sa-questions/${this.courseId}/${this.learningObjectiveId}`;
  }

  ngOnInit() {
    // fetch data
    this.getLearningObjectiveById();
    this.getSaMostIncorrectQuestions();
    this.getSaUnderPerformingStudents();
  }

  getLearningObjectiveById() {
    this.courseDetailsService
      .getLearningObjectiveById(this.learningObjectiveId)
      .subscribe(data => (this.learningObjective = data));
  }

  getSaMostIncorrectQuestions() {
    this.courseDetailsService
      .getSaMostIncorrectQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.mostIncorrectQuestions = data));
  }

  getSaUnderPerformingStudents() {
    this.courseDetailsService
      .getSaUnderPerformingStudents(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.underPerformingStudents = data));
  }
}
