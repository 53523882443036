/**
 * Created by Maxim B. on 11/05/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ManageUsersService } from './manage-users.service';

@Component({
  selector: 'curr-school-admin-manage-users',
  templateUrl: './school-admin-manage-users.component.html',
  styleUrls: ['./school-admin-manage-users.component.scss']
})
export class SchoolAdminManageUsersComponent implements OnInit {
  selectedTabIndex = 0;
  itemsPerPage = 12;
  customTabs = [{ name: 'TEACHERS' }, { name: 'STUDENTS' }, { name: 'ADMINS' }];
  users = [];
  admins = [];
  dataFromApi;
  ddOptionsAssigned = [{ label: 'Assigned', value: '1' }];
  ddOptionsActivated = [{ label: 'Activated', value: '1' }];
  ddOptionsSubject = [];
  ddOptionsGrade = [];
  ddOptionsForm = [];
  isExportMenuOpen = false;
  isSortMenuOpen = false;
  isAddNewOpen = false;
  isArchived = '0';
  isLoading = false;
  selectedFilters = {
    isArchived: false,
    grade: '',
    form: '',
    subject: '',
    assigned: '',
    activated: ''
  };
  isInListView = false;
  sortOption = 'name';
  displayedColumns = ['name'];
  dataSource: any;
  checkedPage = [];
  path = [
    {
      label: 'Home',
      url: '/dashboard'
    },
    {
      label: 'Users',
      url: ''
    }
  ];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = sort;
    }
  }

  constructor(private manageUsersService: ManageUsersService) {
    // this.initOptionsForFilters();
  }

  ngOnInit(): void {
    this.loadData(this.itemsPerPage, 0);
    if (this.isInListView) {
      this.initTable();
    }
  }

  initTable() {
    this.displayedColumns = ['name', 'id', 'status', 'activate', 'edit'];
    this.dataSource = new MatTableDataSource(this.users);
  }

  tabSelectedIndexChange($event: any) {
    this.selectedTabIndex = $event;
    this.loadData();
  }

  filterEntries($event: KeyboardEvent) {
  }

  clearFilters() {
    this.selectedFilters = {
      isArchived: false,
      grade: '',
      form: '',
      subject: '',
      assigned: '',
      activated: ''
    };
  }

  changeSelection(e: any, type: string) {
    this.selectedFilters[type] = e;
  }

  initOptionsForFilters() {
    this.ddOptionsForm.push({ value: 'all', label: `All Form` });
    this.ddOptionsGrade.push({ value: 'all', label: `All Years` });
    for (let i = 1; i <= 12; i++) {
      this.ddOptionsGrade.push({ value: i, label: `Year ${ i }` });
      this.ddOptionsForm.push({ value: i, label: `Form ${ i }` });
    }
    this.ddOptionsSubject = [
      { value: 'biology', label: 'Biology' },
      { value: 'math', label: 'Math' },
      { value: 'chemistry', label: 'Chemistry' },
      { value: 'english', label: 'English' },
      { value: 'physics', label: 'Physics' }
    ];
  }

  setUsersView() {
    this.isInListView = !this.isInListView;
    if (this.isInListView) {
      this.initTable();
    }
  }

  activateUser(element: any) {
    element.deactivated = false;
  }

  deleteUser(el: any) {
    const id = el.id;
    const ind = this.dataSource.data.findIndex(e => e.id === id);
    if (ind > -1) {
      this.dataSource.data.splice(ind, 1);
      this.dataSource._updateChangeSubscription();
    }
  }

  importUsers(all: string) {

  }

  addUsers(file: string) {

  }

  sortUsers(by: string) {
    this.sortOption = by;
  }

  private loadData(limit: number = 12, offset: number = 0) {
    let req;
    if (this.selectedTabIndex === 0) {
      req = this.manageUsersService.getTeachers(limit, offset);
    }
    if (this.selectedTabIndex === 1) {
      req = this.manageUsersService.getStudents(limit, offset);
    }
    if (this.selectedTabIndex === 2) {
      req = this.manageUsersService.getAdmins(limit, offset);
    }
    if (!req) {
      return;
    }
    this.isLoading = true;
    this.users = [];
    req.subscribe(resp => {
      this.dataFromApi = resp.data;
      this.users = resp.data?.content;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
    });
  }

  pageSelected(page: number) {
    this.loadData(this.itemsPerPage, page);
  }
}
