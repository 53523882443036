/*
 * Created by Dmytro Sav. on 5/11/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

interface Options {
  label: string;
  value: string;
}

interface Subject {
  course: Options;
  name: string;
  year: Options;
  form: Options;
  grade: Options[];
}

@Component({
  selector: 'curr-edit-courses',
  templateUrl: './edit-courses.component.html',
  styleUrls: ['./edit-courses.component.scss']
})
export class EditCoursesComponent implements OnInit {
  @Input() isEdited: boolean;
  @Output() selectedValues = new EventEmitter<{}>();

  @Input() subject?: Subject;
  @Input() courseOptions?: Options[];
  @Input() yearsOptions?: Options[];
  @Input() formOptions?: Options[];
  @Input() gradeOptions?: Options[];

  form: FormGroup = this.fb.group({});

  selectedValue = '1';
  selectedCourse = '';
  selectedYear = '';
  selectedForm = '';
  selectedGrade = '';

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  addNewCourse() {
    const emit = {
      selectedCourse: this.selectedCourse,
      selectedYear: this.selectedYear,
      selectedForm: this.selectedForm,
      selectedGrade: this.selectedGrade
    };

    if (this.selectedCourse !== '' && this.selectedYear !== '' && this.selectedForm !== '') {
      this.selectedValues.emit(emit);
    }
  }

  getCourseOptions(value) {
    this.selectedCourse = value;
  }

  getYearsOptions(value) {
    this.selectedYear = value;
  }

  getFormOptions(value) {
    this.selectedForm = value;
  }

  getGradeOptions(value) {
    this.selectedGrade = value;
  }

  editSubject() {
    this.selectedCourse = this.subject.course.value;
    this.selectedYear = this.subject.year.value;
    this.selectedForm = this.subject.form.value;

    if (this.selectedCourse !== '' && this.selectedYear !== '' && this.selectedForm !== '') {
      this.isEdited = true;
    }
    // this.selectedGrade = this.subject.grade;
  }

  formCount = [0];
  addForm() {
    let lastElement = this.formCount.slice(-1)[0];
    this.formCount.push(++lastElement);
  }
}
