<!--
 Created by Volodymyr Mak. on 1/6/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<nav currClickOutside (clickOutside)="closeMenu()" class="mobile-menu" [ngClass]="{'active': isActive}">
  <section class="user"  fxLayoutGap="30px">
    <img *ngIf="userInfo.image" [src]="userInfo.image" alt="">
    <div *ngIf="!userInfo.image" class="initials-wrapper">
      <curr-initials-avatar class="avatar-wrapper" [name]="userInfo.name" size="24px"></curr-initials-avatar>
    </div>
    <div class="user-info" fxLayout="column">
      <span class="name">{{userInfo.name}}</span>
      <span *ngIf="userInfo.year" class="year">Year {{userInfo.year}}</span>
    </div>
  </section>
  <section class="user-pages">
    <ul>
      <li *ngFor="let page of userPages" [routerLinkActive]="!page?.disabled ? 'active' : ''" (click)="closeMenu()">
        <a (click)="goTutorSession(page.icon)"  class="link-color" [routerLink]="!page?.disabled ? page.rout : null" fxLayoutAlign="start center" [class.disabled]="page.disabled">
          <mat-icon class="link-color" [svgIcon]="page.icon"></mat-icon>
          <span>{{page.name}}</span>
        </a>
      </li>
    </ul>
  </section>
  <section class="general-pages">
    <ul>
      <li *ngFor="let page of generalPages" routerLinkActive="active"
          (click)="closeMenu()"
          [ngClass]="{'red': page.isRed}">
        <a [routerLink]="page.rout" fxLayoutAlign="start center">
          <mat-icon [svgIcon]="page.icon"></mat-icon>
          <span>{{page.name}}</span>
        </a>
      </li>
      <li routerLinkActive="active"
          (click)="closeMenu()"
          class="red">
        <a fxLayoutAlign="start center"
           (click)="menuClick('logout')">
          <mat-icon [svgIcon]="'logout'"></mat-icon>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </section>
</nav>
