/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[appInsertion]'
})
export class FullScreenPopupInsertionDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
