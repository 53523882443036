<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="item-inside-section">
  <div class="item-inside">
    <div class="item-inside-actions">
      <mat-icon
        *ngIf="showActions && stepNumber !== 1"
        svgIcon="arrow-top-move-list"
        class="arrow-left"
        matTooltip="Adjust Left"
        matTooltipPosition="below"
        matTooltipClass="arraws-tooltip"
        [class.active-arrow]="isLeftArrowActive"
        (click)="moveAction('left', isLeftArrowActive)"
      ></mat-icon>
      <mat-icon
        *ngIf="showActions && stepNumber !== 1"
        svgIcon="arrow-top-move-list"
        class="arrow-right"
        matTooltip="Adjust Right"
        matTooltipPosition="below"
        matTooltipClass="arraws-tooltip"
        [class.active-arrow]="isRightArrowActive"
        (click)="moveAction('right', isRightArrowActive)"
      ></mat-icon>
      <mat-icon
        *ngIf="showActions && stepNumber !== 1"
        svgIcon="arrow-top-move-list"
        class="arrow-top"
        matTooltip="Adjust Up"
        matTooltipPosition="below"
        matTooltipClass="arraws-tooltip"
        [class.active-arrow]="isTopArrowActive"
        (click)="moveAction('top', isTopArrowActive)"
      ></mat-icon>
      <mat-icon
        *ngIf="showActions && stepNumber !== 1"
        svgIcon="arrow-top-move-list"
        class="arrow-bottom"
        matTooltip="Adjust Down"
        matTooltipPosition="below"
        matTooltipClass="arraws-tooltip"
        [class.active-arrow]="isBottomArrowActive"
        (click)="moveAction('bottom', isBottomArrowActive)"
      ></mat-icon>
    </div>
    <curr-movable-item-panel
      [title]="questionNumber + ' ' + questionTitle"
      [isIntroduction]="itemData.is_master"
      [stepNumber]="stepNumber"
      [firstLevelAmount]="firstLevelAmount"
      [itemIndexWithParents]="itemIndexWithParents"
      (onShowed)="setIsShowed($event)"
    >
      <curr-question-section
        *ngIf="stepNumber === 0"
        [form]="form"
        [isCTQuestion]="true"
        [topics]="topics"
        [stepNumber]="stepNumber"
        [questionEdit]="itemData"
        [itemIndexWithParents]="itemIndexWithParents"
      ></curr-question-section>

      <curr-mark-scheme-section
        *ngIf="stepNumber === 1"
        [form]="form"
        [question]="itemData"
        [itemIndexWithParents]="itemIndexWithParents"
      ></curr-mark-scheme-section>

      <div class="third-step" *ngIf="stepNumber === 2">
        <div class="third-step-item" [ngClass]="[itemData.is_master ? 'master-question' : '']">
          <div class="step-title" *ngIf="!itemData.is_master">Question</div>
          <curr-question-section
            [form]="form"
            [isCTQuestion]="true"
            [topics]="topics"
            [stepNumber]="stepNumber"
            [questionEdit]="itemData"
            [itemIndexWithParents]="itemIndexWithParents"
          ></curr-question-section>
        </div>

        <div class="third-step-item" *ngIf="!itemData.is_master">
          <div class="step-title">Mark scheme</div>
          <curr-mark-scheme-section
            [form]="form"
            [question]="itemData"
            [itemIndexWithParents]="itemIndexWithParents"
          ></curr-mark-scheme-section>
        </div>
      </div>
    </curr-movable-item-panel>
  </div>
  <div class="add-item-section" *ngIf="isCreateMode || isMultiLevel">
    <div class="add-item-line" *ngIf="stepNumber !== 1"></div>
    <mat-icon svgIcon="cross" (click)="addNewItem()" *ngIf="stepNumber !== 1"></mat-icon>
  </div>
</div>
