<ng-container
  *ngIf="nextTopicTest$ | async as nextTopicTest">
  <div class="topic_test__widget" *ngIf="nextTopicTest.id">
    <p class="topic_test__heading">Topic Test</p>
    <div class="topic_test__countdown">
      <div class="topic_countdown-wrapper" *ngIf="!isAvailable">
        <p class="topic_test__countdown-title">
          Next Topic Test will begin in:
        </p>
        <div fxLayout="row" fxLayoutAlign="start start" class="count-down-box">
          <div class="countdown-wrapper" fxLayout="column">
            <curr-circle-progress fxFlexAlign="center" class="count-down-item" [percent]="daysToDay">
            </curr-circle-progress>
            <div class="count-down-label">DAYS</div>
          </div>
          <div class="countdown-wrapper" fxLayout="column">
            <curr-circle-progress fxFlexAlign="center" class="count-down-item" [percent]="hoursToDay">
            </curr-circle-progress>
            <div class="count-down-label">HOURS</div>
          </div>
          <div class="countdown-wrapper" fxLayout="column">
            <curr-circle-progress fxFlexAlign="center" class="count-down-item" [percent]="minutesToDay">
            </curr-circle-progress>
            <div class="count-down-label">MINUTES</div>
          </div>
        </div>
      </div>
      <div class="start-test" *ngIf="isAvailable">
        <mat-icon class="icon-test-start" [svgIcon]="'start-test'"></mat-icon>
        <div class="text-test-wrapper">
          <div class="start-test_title">Topic test is now live</div>
          <curr-button (btnClick)="navigateToTopicTest()" class="button-start-test" color="primary" size="small">Start
            Topic Test
          </curr-button>
        </div>
      </div>
    </div>

    <div class="next_topic__test">
      <p class="next_topic__test-title">
        {{ nextTopicTest?.testName }}
      </p>
      <div class="next_topic__test-calendar" *ngIf="!isAutoContinueTest">
        <mat-icon class="icon-calendar" [svgIcon]="'calendar'"></mat-icon>
        <span class="next_topic__test-calendar--date">
            {{ convertToLocalTime(nextTopicTest.startTime) | date : 'mediumDate' }}
          </span>
      </div>
      <div class="next_topic__test-time" *ngIf="!isAutoContinueTest">
        <mat-icon class="icon-calendar" [svgIcon]="'watch'"></mat-icon>
        <span class="next_topic__test-calendar--date">
            {{ convertToLocalTime(nextTopicTest.startTime) | date : 'shortTime' }}
          </span>
      </div>
      <div class="next_topic__test-progress" *ngIf="isDisplayed || isShow">
        <p class="next_topic__test-progress-heading">Your progress</p>
        <div class="next_topic__test-progress-content">
          <mat-icon class="progress-step-icon" [svgIcon]="'progress-widget'"></mat-icon>
          <div>
            <p class="next_topic__test-progress-title">
              You are almost at the finish line!
            </p>
            <p class="what-include-link" (click)="onDataLoad.emit(topicIds)">
              What's included in this test?
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
