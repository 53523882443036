/**
 * Created by Daniil V. on 02/06/21.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { map } from 'rxjs/operators';
import { ITag } from '../../../student/test.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  constructor(private httpClient: ApplicationHttpClient) {}

  getQuestionTags(searchStr = '') {
    return this.httpClient.get<{ data: ITag[] }>(`/questionTags/${searchStr}`).pipe(map(response => response?.data));
  }

  createTag(questionTagDTO: ITag) {
    return this.httpClient.post('/questionTags', questionTagDTO);
  }
}
