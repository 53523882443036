<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="header-container main-header m-b-0 m-t-16" fxLayout="row"
     fxLayoutAlign="space-between center">
  <h2 class="mat-h2 m-b-16 m-l-16">Subjects</h2>

</div>

<mat-divider class="m-b-16 full-width"></mat-divider>
<mat-card>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Subject</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="'/subjects/1110/grade-results/' + studentId" class="user-name"><p>{{ element.subject }}</p></a>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 1">
        <ng-container *ngIf="item == 'predicted_grade'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
            {{ 'dropdownItemLabels.' + item | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <a
              [matMenuTriggerFor]="predictedGradeMenu"
              (menuClosed)="closePredictedGradeMenu()"
              class="user-name"
              (click)="clickPredictedGrade($event, element)"
            >
              <p>{{ element.predicted_grade }}</p>
              <mat-icon
                *ngIf="isPredictedGradeMenuOpen && clickedPendingUser == element.subject"
                svgIcon="up"
                class="dd-icon"
              ></mat-icon>
              <mat-icon
                *ngIf="!isPredictedGradeMenuOpen || clickedPendingUser != element.subject"
                svgIcon="down"
                class="dd-icon"
              ></mat-icon>
            </a>
            <mat-menu #predictedGradeMenu="matMenu" xPosition="after" class="meeting-menu">
              <ng-container *ngFor="let b of ddOptionsPredictedGrade">
                <button
                  mat-menu-item
                  class="icon-tab-menu-item mat-body-1"
                  fxLayoutAlign="start center"
                  [class.active]="b.value === element.predicted_grade"
                  (click)="setPredictedGrade(b.value, element)"
                >
                  <mat-icon *ngIf="b.value === element.predicted_grade" svgIcon="icon-done"
                            class="m-r-0 grade-icon"></mat-icon>
                  {{ b.label }}
                </button>
                <mat-divider></mat-divider>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container *ngIf="item != 'prev_week_meetings'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
            {{ 'dropdownItemLabels.' + item | translate }}
          </th>
        </ng-container>

        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngIf="item !== 'performance' && item !== 'prev_week_meetings'"
            [ngSwitch]="+element[item] >= 0 && +element[item] <= 1"
          >
            <span *ngSwitchCase="true">{{ +element[item] | percent }}</span>
            <span *ngSwitchDefault>{{ element[item] }}</span>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</mat-card>
