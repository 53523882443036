<!--
 Created by Dmytro Sav. on 4/29/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div mat-dialog-content fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center" class="container">
  <mat-icon [svgIcon]="'close'" class="close-icon cursor-pointer" (click)="close()"></mat-icon>
  <div class="start-record" *ngIf="stateRecord === 'init'">
    <curr-button size="big" variant="contained" (btnClick)="startRecording()" fxFlex="319px">
      <mat-icon [svgIcon]="'microphone'"></mat-icon>
      START RECORDING AUDIO REPLY
    </curr-button>
  </div>

  <div *ngIf="stateRecord === 'start' || stateRecord === 'pause'" class="text-centered">
    <div class="mat-display-2 m-b-16" *ngIf="recordedTime">
      {{ recordedTime }}
    </div>

    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center">
      <curr-button
        *ngIf="stateRecord !== 'pause'"
        size="small"
        variant="contained"
        (btnClick)="pauseRecording()"
        fxFlex="93px"
        [style]="btnStyle"
      >
        <mat-icon class="pause-icon" [svgIcon]="'pause'"></mat-icon>
        PAUSE
      </curr-button>

      <curr-button
        class="continue-btn"
        *ngIf="stateRecord === 'pause'"
        size="small"
        variant="contained"
        (btnClick)="resumeRecording()"
        fxFlex="128px"
        [style]="btnStyle"
      >
        <mat-icon class="'microphone'" [svgIcon]="'microphone'"></mat-icon>
        CONTINUE
      </curr-button>

      <curr-button
        [isDisabled]="time <= 1000"
        fxFlex="85px"
        [style]="btnStyle"
        (click)="stopRecording()"
        size="small"
        variant="contained"
        [color]="'warn'"
      >
        <mat-icon [svgIcon]="'stop'" class="stop-icon"></mat-icon>
        STOP
      </curr-button>
    </div>
  </div>

  <div class="player" *ngIf="stateRecord === 'stop'">
    <div *ngIf="data.blobUrl" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="24px">
      <audio controls>
        <source [src]="data.blobUrl" type="audio/webm" />
      </audio>

      <div fxLayout="row" fxLayoutGap="24px">
        <curr-button color="primary" size="small" variant="contained" (btnClick)="dialogRef.close(data)">
          SHARE REPLAY
        </curr-button>
        <curr-button color="primary" size="small" variant="text" (btnClick)="recordAnother()">
          RECORD ANOTHER
        </curr-button>
      </div>
    </div>
  </div>
</div>
