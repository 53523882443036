/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'curr-teacher-self-assessment-tests',
  templateUrl: './teacher-self-assessment-tests.component.html',
  styleUrls: ['./teacher-self-assessment-tests.component.scss']
})
export class TeacherSelfAssessmentTestsComponent implements OnInit {
  tests: any;
  subject: any;
  currSelectedTabIndex = 0;
  tabLabels = [];
  path = [];

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.tests = this.route.snapshot.data.tests;
    this.subject = this.route.snapshot.data.subject.data;
    this.path = [
      { label: 'Home', url: '/dashboard' },
      { label: `Courses`, url: '/subjects' },
      { label: 'Self Assessment Tests', url: '' }
    ];
    this.tabLabels.push(`ALL (${this.tests?.passed.length + this.tests?.notPassed.length})`);
    this.tabLabels.push(`PASSED (${this.tests?.passed.length})`);
    this.tabLabels.push(`NOT PASSED (${this.tests?.notPassed.length})`);
  }
}
