import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class CourseUsersService {

  constructor(private httpClient: ApplicationHttpClient
  ) {
  }

  getStudents(courseId, limit = 10, offset = 0) {
    return this.httpClient.get(`/users/students?limit=${limit}&offset=${offset}&courseId=${courseId}`);
  }
}
