/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'curr-assessment-results-chart',
  templateUrl: './assessment-results-chart.component.html',
  styleUrls: ['./assessment-results-chart.component.scss']
})
export class AssessmentResultsChartComponent {
  @Input() set data(values: number[]) {
    if (values) {
      const hasItems = values.filter(item => !!item);
      this.isChartBlur = hasItems.length === 0;

      this.lineChartData = [
        {
          data: values,
          label: 'Cumulative Test Results',
          fill: false,
          tension: 0.4,
          borderColor: '#006878',
          backgroundColor: '#006878',
          pointBackgroundColor: '#006878',
          pointBorderColor: '#006878',
          pointHoverBackgroundColor: '#006878',
          pointHoverBorderColor: '#006878',
          borderWidth: 3,
        },
      ];
    }
  }

  @Input() set month(months: string[]) {
    if (months) {
      this.lineChartLabels = months;
    }
  }

  isChartBlur: boolean = false;
  lineChartLegend: boolean = true;
  lineChartType: string = 'line';
  lineChartData;
  lineChartLabels: Array<any> = [];

  lineChartOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 12,
          },
          padding: 24,
          color: '#000A12',
          borderRadius: 4,
          boxHeight: 12,
          boxWidth: 36,
          useBorderRadius: true,
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        boxPadding: 8,
      },
    },
    scales: {
      x: {
        type: 'category',
      },
      y: {
        type: 'category',
        labels: ['', 'A', 'B', 'C', 'D', 'E', 'F'],
        ticks: {
          callback: (value) => {
            if (!value) return '';
            const gradeLabels = ['', 'A', 'B', 'C', 'D', 'E', 'F'];
            return gradeLabels[value];
          },
        },
        // beginAtZero: true,
        // min: 0,
        // max: 105,
        // ticks: {
        //   stepSize: 10,
        //   callback: function (value: number) {
        //     if (value > 100) return '';
        //     if (value > 90) return 'A';
        //     if (value > 80) return 'B';
        //     if (value > 70) return 'C';
        //     if (value > 60) return 'D';
        //     if (value > 50) return 'E';
        //     if (value <= 50) return 'F';
        //     return '';
        //   },
        // },
      }
    },
  };
}
