<!-- Created by Alex Poh. on 22/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-school-admin-chart">
  <div fxLayout="row" fxFlexAlign="space-between">
    <div fxFlex class="m-b-16 mat-h2 m-l-16 ls">School Statistic</div>
    <!-- <div fxFlex="250px" class="task-chart-container" fxLayout="row" fxLayoutGap="32px" class="m-t-8">
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.MONTH ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.MONTH)">
        MONTH
      </div>
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.SEMESTER ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.SEMESTER)">
        SEMESTER
      </div>
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.YEAR ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.YEAR)">
        YEAR
      </div>
    </div> -->
  </div>
  <mat-divider class="m-b-16"></mat-divider>
  <div class="chart-card">
    <div class="chart-card-filters">
        <div class="mat-h3 m-b-0">Avg. Grade: {{ isStatisticVisible ? generalGraphData.averageMark : '-' }}&nbsp;</div>
        <curr-select-courses-filters
          [isAllItemsOption]="false"
          (onFilterChange)="filterChanged($event)"
          [filterFields]="coursesFilterFields"
          [filterOptionsData]="filtersData">
        </curr-select-courses-filters>
    </div>
    <mat-divider class="divider"></mat-divider>
    <!-- <div *ngIf="isStatisticVisible" fxLayout="row" class="m-t-16 m-b-32 legend-container" fxLayoutAlign="space-between">
      <div *ngIf="displayData$ | async as displayData" fxFlex="575px" fxLayout="row wrap">
        <div fxFlex="250px" class="legend-item  overline" [ngClass]="(showLegend$ | async) ? 'legend-item_visible' : ''"
          fxLayoutAlign="row" fxLayoutGap="6px" *ngFor="let legend of displayData.series">
          <div fxFlex="120px">
            <mat-icon [inline]="true" class="legend-line" [ngClass]="legend.cssClasses.line" svgIcon="legend-line">
            </mat-icon>
          </div>
          <div fxFlex>{{ legend.name }}</div>
        </div>
      </div>
      <div fxFlex="195px">
        <button fxFlex="195px" mat-raised-button class="active-text view-all-button m-t-8">VIEW ALL STATISTICS</button>
      </div>
    </div> -->
    <curr-school-performance-histogram
      *ngIf="isStatisticVisible"
      class="school-performance-histogram"
      [chartData$]="displayData$"
      [width]="chartWidth">
    </curr-school-performance-histogram>

    <div class="loader-container" *ngIf="isLoading">
      <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
    </div>
  
    <div class="no-data-image" *ngIf="!isStatisticVisible && !isLoading">
      <mat-icon [inline]="true" class="image" [svgIcon]="'chart-no-data'"></mat-icon>
    </div>
  </div>
</div>
