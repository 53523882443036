<!-- Created by Alex Poh. on 23/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<footer>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="center start"
        class="container">
    <div class="logo-wrapper">
      <mat-icon fxLayoutAlign="start start" [inline]="true" svgIcon="app-logo"></mat-icon>
    </div>
    <div fxFlex="223px" fxFlex.xs class="copyright mat-body-2"
         ngClass.lt-sm="full-width m-b-8"
         fxLayout="row" fxLayoutAlign="center center">Copyright © {{ date | date: 'y' }} - Curriculum LTD</div>
  </div>
</footer>
