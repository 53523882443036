<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <div class="list main" [class]="testClass()" [class.isToday]="isToday()" [class.isClickable]="buttons.length === 1"
    (click)="$event.stopPropagation();buttons[0].callback()">
    <div class="day overline">
      <mat-icon *ngIf="test.testState !== testTypes.notPassed; else dot" color="accent" [inline]="true"
        svgIcon="icon-done" class="icon"></mat-icon>
      <ng-template #dot>
        <div class="dot" [class.opacity]="true"></div>
      </ng-template>
      <span class="text">{{ test.dateText }}</span>
    </div>

    <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.sm="row">
      <div class="text-wrapper" fxLayout="column" fxLayoutAlign="center" fxFlex="85%" [class.m-t-16]="true">
        <div class="mat-h1 m-b-0 name desktop">{{ test?.title }}</div>
        <div class="mat-h1 m-b-0 name mobile">{{ test?.title }}</div>

        <div class="status mat-body-2 m-t-8" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
          fxLayoutAlign.xs="start start">
          <ng-container *ngFor="let text of bottomTitles; let last = last">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon *ngIf="text?.icon" [inline]="true" [svgIcon]="text?.icon" class="status-icon m-r-4"></mat-icon>

              <span class="m-r-4" *ngIf="text.label.length > 1" [class.text-underline]="text?.isUnderline"
                [class.label]="text.isBold">{{ text.label }}
              </span>

              <div class="value" [style.color]="text.color">{{ text.value }}</div>

              <span class="divider m-l-8 m-r-8" [class.last]="last" fxHide.xs>|</span>
            </div>
          </ng-container>
        </div>
      </div>
      <div *ngIf="buttons?.length > 0 && buttons?.length > 1" ngClass.sm="m-r-8" class="action-btn" fxLayout="row"
        fxLayoutAlign="end center" fxFlex.xs>
        <a class="arrow-link" target="_blank" [matMenuTriggerFor]="ctMenu">
          <mat-icon>
            arrow_right
          </mat-icon>
        </a>
        <mat-menu #ctMenu="matMenu" xPosition="before" class="meeting-menu">
          <ng-container *ngFor="let b of buttons">
            <button class="tab-btn-menu" mat-menu-item (click)="$event.stopPropagation();b.callback()">
              {{ b.label }}
            </button>
          </ng-container>
        </mat-menu>
      </div>
      <div *ngIf="buttons?.length > 0 && buttons?.length == 1" class="action-btn" fxLayout="row"
        fxLayoutAlign="end center" fxFlex.xs>
        <a class="arrow-link" target="_blank" (click)="$event.stopPropagation();buttons[0].callback()">
          <mat-icon>
            arrow_right
          </mat-icon>
        </a>
      </div>
    </div>
  </div>
</div>
