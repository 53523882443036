import { Component, OnInit, OnDestroy } from '@angular/core';
import { SchoolAdmin, ProfileCardData, ProfileFieldsNames } from 'src/app/shared/components/profile-card/profile-card.model';
import { SchoolAdminService } from './school-admin.service';
import { Subscription } from 'rxjs';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';

@Component({
  selector: 'curr-school-admin-profile',
  templateUrl: './school-admin-profile.component.html',
  styleUrls: ['./school-admin-profile.component.scss'],
  providers: [SchoolAdminService]
})
export class SchoolAdminProfileComponent implements OnInit, OnDestroy {
  adminData: SchoolAdmin;
  profileCardData: ProfileCardData;
  private subscription = new Subscription();

  constructor(
    private _schoolAdminService: SchoolAdminService,
    private _profileDataService: ProfileDataService
  ) {
  }

  ngOnInit(): void {
    this._schoolAdminService.getSchoolAdminProfile('userId')
    .subscribe(
      {
        next: (schoolAdmin: SchoolAdmin) => {
          this.listenOnUserProfileChanges();
        }
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateUserProfile(data: SchoolAdmin) {
    this._schoolAdminService.updateStudent(data).subscribe((val: any) => {

    })
  }

  private listenOnUserProfileChanges() {
    this.subscription.add(
      this._profileDataService.UserProfile.subscribe({
        next: (schoolAdmin: SchoolAdmin) => {
         this.adminData = schoolAdmin
         this.profileCardData = {
          formFieldsToDisplay: [
            {label: 'Email', name: ProfileFieldsNames.EMAIL, verify: {valid: schoolAdmin.email.verify}},
            {label: 'Phone', name: ProfileFieldsNames.PHONE, verify: {valid: schoolAdmin.phone.verify}}
          ],
          userData: schoolAdmin
        }
        }
      })
    )
  }
}
