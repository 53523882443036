<!--
 Created by Dmytro Sav. on 5/14/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="row" fxLayoutAlign="start center" class="calendar-container" [owlDateTimeTrigger]="dt1">
  <span class="m-r-8 trigger">
    <mat-icon color="primary" svgIcon="calendar" class="cursor-pointer"></mat-icon>
  </span>
  <owl-date-time #dt1 [pickerType]="'calendar'"></owl-date-time>
  <input #dateInput class="deadline-date button cursor-pointer" (dateTimeChange)="saveTask(dt1)" [owlDateTime]="dt1"
    [placeholder]="(initDate | date: dateFormat) || title" onkeypress="return false;"
    [value]="initDate | date: dateFormat" />
</div>