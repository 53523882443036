import { Component, Output, EventEmitter, Input, OnChanges, ChangeDetectorRef } from '@angular/core';

export interface CoursesFilterOptions {
  [key: string]: string | number;
}

export interface CoursesFilterFields {
  subject: string;
  course: string;
  grade: string;
  semester?: string;
}

interface FilterListItem {
  label: string;
  value: string | number;
  courseIds?: number[];
}

@Component({
  selector: 'curr-select-courses-filters',
  templateUrl: './select-courses-filters.component.html',
  styleUrls: ['./select-courses-filters.component.scss']
})
export class SelectCoursesFiltersComponent implements OnChanges {
  @Output() onFilterChange = new EventEmitter<CoursesFilterOptions>();
  @Input() filterOptionsData: any;
  @Input() filterFields: CoursesFilterFields = {} as any;
  @Input() isSelected: boolean;
  @Input() isAllItemsOption: boolean = true;

  @Input() set preselectedCourseId(value: string | number) {
    if (value) {
      this.coursesDefault = value;
    }
  }

  // FILTERS - DROPDOWN
  ddOptionsSubjects: FilterListItem[] = [];
  basedDdOptionsSubjects: FilterListItem[] = [];
  ddOptionsGrades: FilterListItem[] = [];
  baseDdOptionsGrades: FilterListItem[] = [];
  ddOptionsCourses: FilterListItem[] = [];
  baseDdOptionsCourses: FilterListItem[] = [];
  ddOptionsSemesters: FilterListItem[] = [];
  baseDdOptionsSemesters: FilterListItem[] = [];

  baseFilterOptions: CoursesFilterOptions = {};
  filterOptions: CoursesFilterOptions = {};

  subjectDefault: string | number = null;
  gradesDefault: string | number = null;
  coursesDefault: string | number = null;
  semestersDefault: string | number = null;

  constructor(private cd: ChangeDetectorRef) {
  }

  ngOnChanges() {
    this.initBaseFilters();
    this.initOptionsForFilters();
  }

  // Prepares data for dd
  public initOptionsForFilters() {
    if (!this.filterOptionsData) {
      return;
    }
    this.filterOptions = { ...this.baseFilterOptions };
    const {
      subjects = [],
      levels = [],
      courses = [],
      subjectToCourse = [],
      levelToCourse = [],
      semesters = [],
      courseToSemester = []
    } = this.filterOptionsData;

    this.ddOptionsSubjects = this.basedDdOptionsSubjects = [
      ...subjects.map(({ value }) => {
        return {
          value,
          label: value,
          courseIds: subjectToCourse.filter(({ subjectName }) => subjectName === value).map(({ courseId }) => courseId)
        };
      })
    ];

    this.ddOptionsGrades = this.baseDdOptionsGrades = [
      ...levels.map(({ value, id }) => {
        return {
          value: id,
          label: value,
          // tslint:disable-next-line:no-shadowed-variable
          courseIds: levelToCourse.filter(({ levels }) => levels === id).map(({ courses }) => courses)
        };
      })
    ];

    this.ddOptionsCourses = this.baseDdOptionsCourses = [
      ...courses.map(({ value, id }) => {
        return {
          value: id,
          label: value
        };
      })
    ];

    this.ddOptionsSemesters = this.baseDdOptionsSemesters = [
      ...semesters.map(({ value, id }) => {
        return {
          value: id,
          label: value || 'N/A',
          // tslint:disable-next-line:no-shadowed-variable
          courseIds: courseToSemester.filter(({ semesters }) => semesters === id).map(({ courses }) => +courses)
        };
      })
    ];

    if (this.isAllItemsOption) {
      this.ddOptionsSubjects = [{ value: undefined, label: 'All subjects' }, ...this.ddOptionsSubjects];
      this.ddOptionsGrades = [{ value: undefined, label: 'All years' }, ...this.ddOptionsGrades];
      this.ddOptionsCourses = [{ value: undefined, label: 'All courses' }, ...this.ddOptionsCourses];
      this.ddOptionsSemesters = [{ value: undefined, label: 'All semesters' }, ...this.ddOptionsSemesters];
    } else {
      this.changeSelection(this.ddOptionsSubjects[0].value, this.filterFields.subject);
      this.gradesDefault = this.ddOptionsGrades[1].value;
      this.coursesDefault = this.ddOptionsCourses[1].value;
      this.filterOptions[this.filterFields.course] = this.coursesDefault;
      this.semestersDefault = this.ddOptionsSemesters[1].value;
      this.cd.detectChanges();
      this.onFilterChange.emit(this.filterOptions);
    }
  }

  // TODO: remove?
  addAllItemsOptions() {
  }

  initBaseFilters() {
    for (const filterField of Object.values(this.filterFields)) {
      this.baseFilterOptions[filterField] = null;
    }
  }

  changeSelection(filterValue: number | string, type: string) {
    (this.filterOptions as { [key: string]: any })[type] = filterValue || null;

    // Reset previous selected filters
    if (type === this.filterFields.subject && !filterValue) {
      this.initOptionsForFilters();
    }
    if (filterValue) {
      if (type === this.filterFields.subject) {
        this.filterOptions[this.filterFields.course] = null;
        this.filterOptions[this.filterFields.grade] = null;
        this.filterOptions[this.filterFields.semester] = null;

        const courseIdsInSelectedSubject = this.basedDdOptionsSubjects.find(({ value }) => value === filterValue)
          .courseIds;
        // Filters courses dd options
        this.ddOptionsCourses = [
          { value: undefined, label: 'All courses' },
          ...this.baseDdOptionsCourses.filter(
            ({ value: courseId }) => !!courseIdsInSelectedSubject?.find(id => id === courseId)
          )
        ];
        // Filters grades dd options
        this.ddOptionsGrades = [
          { value: undefined, label: 'All years' },
          ...this.baseDdOptionsGrades.filter(({ courseIds }) =>
            courseIds?.some(id => courseIdsInSelectedSubject?.find(courseId => courseId === id))
          )
        ];

        // Filters semesters dd options
        this.ddOptionsSemesters = [
          { value: undefined, label: 'All semesters' },
          ...this.baseDdOptionsSemesters.filter(({ courseIds }) =>
            courseIds?.some(id => courseIdsInSelectedSubject?.find(courseId => courseId === id))
          )
        ];
      }

      if (type === this.filterFields.grade) {
        const courseIdsInSelectedGrade = this.baseDdOptionsGrades.find(({ value }) => value === filterValue).courseIds;
        // Filters courses dd options
        this.ddOptionsCourses = [
          { value: undefined, label: 'All courses' },
          ...this.baseDdOptionsCourses.filter(
            ({ value: courseId }) => !!courseIdsInSelectedGrade?.find(id => id === courseId)
          )
        ];

        // Filters semesters dd options
        this.ddOptionsSemesters = [
          { value: undefined, label: 'All semesters' },
          ...this.baseDdOptionsSemesters.filter(({ courseIds }) =>
            courseIds?.some(id => courseIdsInSelectedGrade?.find(courseId => courseId === id))
          )
        ];
      }

      if (type === this.filterFields.course) {
        // Filters grades dd options
        this.ddOptionsGrades = [
          ...this.baseDdOptionsGrades.filter(({ courseIds }) => courseIds?.find(id => id === filterValue))
        ];

        // Filters semesters dd options
        this.ddOptionsSemesters = [
          ...this.baseDdOptionsSemesters.filter(({ courseIds }) => courseIds?.find(id => id === filterValue))
        ];
      }

      if (type === this.filterFields.semester) {
        const courseIdsInSelectedSemester = this.baseDdOptionsSemesters.find(({ value }) => value === filterValue)
          .courseIds;

        // Filters grades dd options
        this.ddOptionsGrades = [
          { value: undefined, label: 'All years' },
          ...this.baseDdOptionsGrades.filter(({ courseIds }) =>
            courseIds?.some(id => courseIdsInSelectedSemester?.find(courseId => courseId === id))
          )
        ];

        // Filters courses dd options
        this.ddOptionsCourses = [
          { value: undefined, label: 'All courses' },
          ...this.baseDdOptionsCourses.filter(
            ({ value: courseId }) => !!courseIdsInSelectedSemester?.find(id => id === courseId)
          )
        ];
      }
    }

    this.onFilterChange.emit(this.filterOptions);
  }
}
