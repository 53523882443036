import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CourseFilters, TreeFilter, TreeFilterNode } from '../course-filters';
import { CourseFilterService } from '../course-filter.service';

@Component({
  selector: 'curr-course-separate-filter',
  templateUrl: './course-separate-filter.component.html',
  styleUrls: ['./course-separate-filter.component.scss']
})
export class CourseSeparateFilterComponent implements OnInit {
  filters: CourseFilters;

  firstLevelValue: TreeFilterNode;
  secondLevelValue: TreeFilterNode;
  thirdLevelValue: TreeFilterNode;

  @Output()
  filterChanged = new EventEmitter<string>();

  treeFilter: TreeFilter;

  constructor(private courseFilterService: CourseFilterService) {
  }

  ngOnInit(): void {
    this.courseFilterService.getCourseFilter().subscribe(res => {
      this.filters = res;
      this.treeFilter = new TreeFilter(this.filters.subjects);
      this.treeFilter.addLevel(this.filters.levels, this.filters.subjectToLevel);
      this.treeFilter.addLevel(this.filters.courses, this.filters.levelToCourse);
      this.firstLevelChanged(this.getOptionsFromChildrenArray(this.treeFilter.root.children)[0].value);
    });
  }

  getOptionsFromChildrenArray(children: TreeFilterNode[]) {
    const result = [];
    children?.forEach(child => {
      if (child.item.value) {
        result.push(child.item);
      }
    });
    return result;
  }

  firstLevelChanged(value) {
    this.secondLevelValue = this.treeFilter.getNodeByValue(this.treeFilter.root, value);
    this.secondLevelChanged(this.secondLevelValue.children[0].item.value);
  }

  secondLevelChanged(value) {
    this.thirdLevelValue = this.treeFilter.getNodeByValue(this.secondLevelValue, value);
    this.thirdLevelChanged(this.thirdLevelValue.children[0].item.value);
  }

  thirdLevelChanged(value) {
    if (value) {
      this.filterChanged.emit(value);
    }
  }
}
