/**
 * Created by Maxim B. on 22/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { CommonFields } from './common-fields';
import { Teacher } from './teacher';

export class Student extends CommonFields {

  avgGrade = '';
  targetGrade = '';
  predictedGrade = '';
  meetingThisWeek = true;
  latest_ct = '';
  learnBest: 'verbal' | 'visual' = 'visual';
  percentageOfCompletedSelfAssessments = '';
  howLongHasThisStudentBeenAtSchool = '';

  teachers: Teacher[] = [];

}
