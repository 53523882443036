/**
 * Created by Volodymyr Mak. on 7/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProfileDataService {

  constructor() {
  }

  UserProfile = new BehaviorSubject(null)

}
