/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
const label = 'Label';
const value = 'Value';
const options =[{label: 'Label 1', value:0},{label: 'Label 2', value:1},{label: 'Label 3', value:2, disabled:true}];
export const initElementData = {
  // BUTTONS
  buttonVariants: [
    { size: 'small', icon: 'edit', isDisabled: false, color: 'primary', variant: 'outlined' },
    { size: 'small', icon: '', isDisabled: false, color: 'primary', variant: 'contained' },
    { size: 'big', icon: '', isDisabled: false, color: 'primary', variant: 'contained' },
    { size: 'small', icon: 'edit', isDisabled: false, color: 'primary', variant: 'contained' },
    { size: 'small', icon: '', isDisabled: false, color: 'primary', variant: 'text' },
    { size: 'small', icon: '', isDisabled: false, color: 'accent', variant: 'outlined' },
    { size: 'small', icon: '', isDisabled: false, color: 'accent', variant: 'contained' },
    { size: 'big', icon: '', isDisabled: false, color: 'accent', variant: 'contained' },
    { size: 'small', icon: 'edit', isDisabled: false, color: 'accent', variant: 'contained' },
    { size: 'small', icon: '', isDisabled: false, color: 'accent', variant: 'text' }
  ],
  inputVariants: [
    { value, placeholder: 'Label', userHint: 'Hint', isBordered: true, prefix: '', inputType: 'string', errorMessage: 'Error' },
    { value, placeholder: 'Label', userHint: '', isBordered: true, prefix: 'edit', inputType: 'string', errorMessage: 'Error' },
    { value, placeholder: 'Label', userHint: '', isBordered: false, prefix: '', inputType: 'string', errorMessage: 'Error' },
    { value, placeholder: 'Label', userHint: '', isBordered: false, isGreyBackground: true,prefix: '', inputType: 'string' },
    { value: 2, placeholder: 'Number', userHint: '', isBordered: true, prefix: '', inputType: 'number', errorMessage: 'Error' },
  ],
  checkboxVariants:[
    {value:[1], options, color:'primary', type:'checkbox',isMultipleSelect:true},
    {value:[1], options, color:'primary', type:'checkbox',isMultipleSelect:false},
    {value:[1], options, color:'accent', type:'radio',isMultipleSelect:true},
    {value:[1], options, color:'accent', type:'radio',isMultipleSelect:false},
  ],
  textareaVariants:[
    {value, label, rows: 5},
    {value, label, rows: 1},
  ],
  tinyEditorVariants:[
    {options: {height: 200, disabled: false}, value},
    {options: {height: 400, disabled: 100}, value},
  ],
  dropdownVariants: [
    {label, options, selectedValue: options[2].value},
    {label, options, defaultValue: options[1].value},
    {label, options}
  ],
  courseCard: {
    name:'Astronomy',
    teacher: 'Ashley Bales',
    nextCumulativeTestDate: new Date(),
    avgGrade: 90,
    avgGradeStr: 'A',
    allLessons: 95,
    completedLessons:43,
    allTests: 25,
    completedTests:20,
    allSelfAssessments: 32
  },
  customerCard: {
    name: 'St Paul\'s School',
    location: 'London, United Kingdom',
    teachersQuantity: 18,
    studentsQuantity: 10,
    performance: .134,
    liveDate: new Date(),
    logo: '',
    status: 'setup'
  },
  userCard: {
    name: 'Lincoln Philips',
    id: '32352535236',
    subject: 'Astronomy',
    role: 'hubAdmin',
    status: 'Lead',
    img: 'avatar'
  }



};
