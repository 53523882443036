<!-- Created by Maxim B. on 25/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="wrap">
  <div class="round"></div>
  <div class="safari-widget-container" >
    <div class="bg-container"></div>
    <div class="central-circle"></div>
    <div class="second-half">
      <div class="white-circle" #secondHalf></div>
    </div>
    <div class="first-half" *ngIf="concern < 0.5">
      <div class="white-circle" #firstHalf>

      </div>
    </div>
  </div>
</div>


