var powerArray = require("./power_array");
var utils = require("../../utils/utils");
var helpers = require("../../utils/helpers");
var DataStore = require("./datastore");

// TODO: remove workaround for mixup with es5 and ts imports
if(DataStore.default){
	DataStore = DataStore.default;
}

var TreeDataStore = function(config){
	DataStore.apply(this, [config]);
	this._branches = {};

	this.pull = {};
	this.$initItem = config.initItem;
	this.$parentProperty = config.parentProperty || "parent";

	if(typeof config.rootId !== "function"){
		this.$getRootId = (function(val){
			return function(){return val;};
		})(config.rootId || 0);
	}else{
		this.$getRootId = config.rootId;
	}

	// TODO: replace with live reference to gantt config
	this.$openInitially = config.openInitially;

	this.visibleOrder = powerArray.$create();
	this.fullOrder = powerArray.$create();
	this._searchVisibleOrder = {};
	this._skip_refresh = false;

	this._ganttConfig = null;
	if(config.getConfig){
		this._ganttConfig = config.getConfig();
	}

	this.attachEvent("onFilterItem", function(id, item){

		var canOpenSplitTasks = false;
		if(this._ganttConfig){
			var canOpenSplitTasks = this._ganttConfig.open_split_tasks;
		}

		var open = true;
		this.eachParent(function(parent){
			open = open && parent.$open && (canOpenSplitTasks || !this._isSplitItem(parent));
		}, item);
		return !!open;
	});

	return this;
};

TreeDataStore.prototype = utils.mixin({

		_buildTree: function(data){
			var item = null;
			var rootId = this.$getRootId();
			for (var i = 0, len = data.length; i < len; i++){
				item = data[i];
				this.setParent(item, this.getParent(item) || rootId);
			}

			// calculating $level for each item
			for (var i = 0, len = data.length; i < len; i++){
				item = data[i];
				this._add_branch(item);
				item.$level = this.calculateItemLevel(item);
				item.$local_index = this.getBranchIndex(item.id);

				if (!utils.defined(item.$open)) {
					item.$open = utils.defined(item.open) ? item.open : this.$openInitially();
				}

			}
			this._updateOrder();
		},
		_isSplitItem: function(item){
			return (item.render == "split" && this.hasChild(item.id));
		},
		parse: function(data){
			this.callEvent("onBeforeParse", [data]);
			var loaded = this._parseInner(data);
			this._buildTree(loaded);
			this.filter();
			this.callEvent("onParse", [loaded]);
		},

		_addItemInner: function(item, index){

			var parent = this.getParent(item);

			if(!utils.defined(parent)){
				parent = this.$getRootId();
				this.setParent(item, parent);
			}

			var parentIndex = this.getIndexById(parent);
			var targetIndex = parentIndex + Math.min(Math.max(index, 0), this.visibleOrder.length);

			if(targetIndex*1 !== targetIndex){
				targetIndex = undefined;
			}
			DataStore.prototype._addItemInner.call(this, item, targetIndex);
			this.setParent(item, parent);

			if(item.hasOwnProperty("$rendered_parent")){
				this._move_branch(item, item.$rendered_parent);
			}
			this._add_branch(item, index);
		},
		_changeIdInner: function(oldId, newId){
			var children = this.getChildren(oldId);
			var visibleOrder = this._searchVisibleOrder[oldId];

			DataStore.prototype._changeIdInner.call(this, oldId, newId);

			var parent = this.getParent(newId);

			this._replace_branch_child(parent, oldId, newId);
			for(var i = 0; i < children.length; i++){
				this.setParent(this.getItem(children[i]), newId);
			}

			this._searchVisibleOrder[newId] = visibleOrder;
			delete this._branches[oldId];
		},

		_traverseBranches: function(code, parent){
			parent = parent || this.$getRootId();
			var branch = this._branches[parent];
			if (branch) {
				for (var i = 0; i < branch.length; i++) {
					var itemId = branch[i];
					code.call(this, itemId);
					if (this._branches[itemId])
						this._traverseBranches(code, itemId);
				}
			}
		},

		_updateOrder: function(code){

			this.fullOrder = powerArray.$create();
			this._traverseBranches(function(taskId){
				this.fullOrder.push(taskId);
			});

			if(code)
				DataStore.prototype._updateOrder.call(this, code);
		},

		_removeItemInner: function(id){

			var items = [];
			this.eachItem(function(child){
				items.push(child);
			}, id);

			items.push(this.getItem(id));

			for(var i = 0; i < items.length; i++){

				this._move_branch(items[i], this.getParent(items[i]), null);
				DataStore.prototype._removeItemInner.call(this, items[i].id);
				this._move_branch(items[i], this.getParent(items[i]), null);
			}
		},

		move: function(sid, tindex, parent){
			//target id as 4th parameter
			var id = arguments[3];
			if (id) {
				if (id === sid) return;

				parent = this.getParent(id);
				tindex = this.getBranchIndex(id);
			}
			if(sid == parent){
				return;
			}
			parent = parent || this.$getRootId();
			var source = this.getItem(sid);
			var source_pid = this.getParent(source.id);

			var tbranch = this.getChildren(parent);

			if (tindex == -1)
				tindex = tbranch.length + 1;
			if (source_pid == parent) {
				var sindex = this.getBranchIndex(sid);
				if (sindex == tindex) return;
			}

			if(this.callEvent("onBeforeItemMove", [sid, parent, tindex]) === false)
				return false;

			this._replace_branch_child(source_pid, sid);
			tbranch = this.getChildren(parent);

			var tid = tbranch[tindex];
			if (!tid) //adding as last element
				tbranch.push(sid);
			else
				tbranch = tbranch.slice(0, tindex).concat([ sid ]).concat(tbranch.slice(tindex));

			this.setParent(source, parent);
			this._branches[parent] = tbranch;

			var diff = this.calculateItemLevel(source) - source.$level;
			source.$level += diff;
			this.eachItem(function(item){
				item.$level += diff;
			}, source.id, this);


			this._moveInner(this.getIndexById(sid), this.getIndexById(parent) + tindex);

			this.callEvent("onAfterItemMove", [sid, parent, tindex]);
			this.refresh();
		},

		getBranchIndex: function(id){
			var branch = this.getChildren(this.getParent(id));
			for (var i = 0; i < branch.length; i++)
				if (branch[i] == id)
					return i;

			return -1;
		},
		hasChild: function(id){
			return (utils.defined(this._branches[id]) && this._branches[id].length);
		},
		getChildren: function(id){
			return utils.defined(this._branches[id]) ? this._branches[id] : powerArray.$create();
		},

		isChildOf: function(childId, parentId){
			if (!this.exists(childId))
				return false;
			if (parentId === this.$getRootId())
				return true;

			if (!this.hasChild(parentId))
				return false;

			var item = this.getItem(childId);
			var pid = this.getParent(childId);

			var parent = this.getItem(parentId);
			if(parent.$level >= item.$level){
				return false;
			}

			while (item && this.exists(pid)) {
				item = this.getItem(pid);

				if (item && item.id == parentId)
					return true;
				pid = this.getParent(item);
			}
			return false;
		},

		getSiblings: function(id){
			if(!this.exists(id)){
				return powerArray.$create();
			}
			var parent = this.getParent(id);
			return this.getChildren(parent);

		},
		getNextSibling: function(id){
			var siblings = this.getSiblings(id);
			for(var i= 0, len = siblings.length; i < len; i++){
				if(siblings[i] == id)
					return siblings[i+1] || null;
			}
			return null;
		},
		getPrevSibling: function(id){
			var siblings = this.getSiblings(id);
			for(var i= 0, len = siblings.length; i < len; i++){
				if(siblings[i] == id)
					return siblings[i-1] || null;
			}
			return null;
		},
		getParent: function(id){
			var item = null;
			if(id.id !== undefined){
				item = id;
			}else{
				item = this.getItem(id);
			}

			var parent;
			if(item){
				parent = item[this.$parentProperty];
			}else{
				parent = this.$getRootId();
			}
			return parent;

		},

		clearAll: function(){
			this._branches = {};
			DataStore.prototype.clearAll.call(this);
		},

		calculateItemLevel: function(item){
			var level = 0;
			this.eachParent(function(){
				level++;
			}, item);
			return level;
		},

		_setParentInner: function(item, new_pid, silent){
			if(!silent){
				if(item.hasOwnProperty("$rendered_parent")){
					this._move_branch(item, item.$rendered_parent, new_pid);
				}else{
					this._move_branch(item, item[this.$parentProperty], new_pid);
				}
			}
		},
		setParent: function(item, new_pid, silent){
			this._setParentInner(item, new_pid, silent);

			item[this.$parentProperty] = new_pid;
		},
		eachItem: function(code, parent){
			parent = parent || this.$getRootId();


			var branch = this.getChildren(parent);
			if (branch)
				for (var i=0; i<branch.length; i++){
					var item = this.pull[branch[i]];
					code.call(this, item);
					if (this.hasChild(item.id))
						this.eachItem(code, item.id);
				}
		},
		eachParent: function(code, startItem) {
			var parentsHash = {};
			var item = startItem;
			var parent = this.getParent(item);

			while (this.exists(parent)) {
				if (parentsHash[parent]) {
					throw new Error("Invalid tasks tree. Cyclic reference has been detected on task " + parent);
				}
				parentsHash[parent] = true;
				item = this.getItem(parent);
				code.call(this, item);
				parent = this.getParent(item);
			}
		},
		_add_branch: function(item, index, parent){
			var pid = parent === undefined ? this.getParent(item) : parent;
			if (!this.hasChild(pid))
				this._branches[pid] = powerArray.$create();
			var branch = this.getChildren(pid);
			var added_already = false;
			for(var i = 0, length = branch.length; i < length; i++){
				if(branch[i] == item.id){
					added_already = true;
					break;
				}
			}
			if(!added_already){
				if(index*1 == index){

					branch.splice(index, 0, item.id);
				}else{
					branch.push(item.id);
				}

				item.$rendered_parent = pid;
			}
		},
		_move_branch: function(item, old_parent, new_parent){
			//this.setParent(item, new_parent);
			//this._sync_parent(task);
			this._replace_branch_child(old_parent, item.id);
			if(this.exists(new_parent) || new_parent == this.$getRootId()){

				this._add_branch(item, undefined, new_parent);
			}else{
				delete this._branches[item.id];
			}
			item.$level =  this.calculateItemLevel(item);
			this.eachItem(function(child){
				child.$level = this.calculateItemLevel(child);
			}, item.id);
		},

		_replace_branch_child: function(node, old_id, new_id){
			var branch = this.getChildren(node);
			if (branch && node !== undefined){
				var newbranch = powerArray.$create();
				for (var i=0; i<branch.length; i++){
					if (branch[i] != old_id)
						newbranch.push(branch[i]);
					else if (new_id)
						newbranch.push(new_id);
				}
				this._branches[node] = newbranch;
			}

		},

		sort: function(field, desc, parent){
			if (!this.exists(parent)) {
				parent = this.$getRootId();
			}

			if (!field) field = "order";
			var criteria = (typeof(field) == "string") ? (function(a, b) {
				if (a[field] == b[field] ||
					(helpers.isDate(a[field]) && helpers.isDate(b[field]) && a[field].valueOf() == b[field].valueOf()))
				{
					return 0;
				}

				var result = a[field] > b[field];
				return result ? 1 : -1;
			}) : field;

			if (desc) {
				var original_criteria = criteria;
				criteria = function (a, b) {
					return original_criteria(b, a);
				};
			}

			var els = this.getChildren(parent);

			if (els){
				var temp = [];
				for (var i = els.length - 1; i >= 0; i--)
					temp[i] = this.getItem(els[i]);

				temp.sort(criteria);

				for (var i = 0; i < temp.length; i++) {
					els[i] = temp[i].id;
					this.sort(field, desc, els[i]);
				}
			}
		},

		filter: function(rule){
			for(var i  in this.pull){
				if(this.pull[i].$rendered_parent !== this.getParent(this.pull[i])){
					this._move_branch(this.pull[i], this.pull[i].$rendered_parent, this.getParent(this.pull[i]));
				}
			}
			return DataStore.prototype.filter.apply(this, arguments);
		},

		open: function(id){
			if(this.exists(id)){
				this.getItem(id).$open = true;
				this.callEvent("onItemOpen", [id]);
			}
		},

		close: function(id){
			if(this.exists(id)){
				this.getItem(id).$open = false;
				this.callEvent("onItemClose", [id]);
			}
		},

		destructor: function(){
			DataStore.prototype.destructor.call(this);
			this._branches = null;
		}
	},
	DataStore.prototype
);

module.exports = TreeDataStore;