/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { ISAChart, ISAChartSubject } from './school-admin-dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class SchoolAdminDashboardService {

  constructor(private httpClient: ApplicationHttpClient) { }

  getAssessmentChart(subject: string, studyYearId: number, courseId: number): Observable<ISAChart> {
    return this.httpClient.get<ISAChart>(`/schoolUserStat/student?subjectName=${subject}&studyYearId=${studyYearId}&courseId=${courseId}`);
  }

  getAssessmentChartSubjects(): Observable<ISAChartSubject[]> {
    return this.httpClient.get<ISAChartSubject[]>('/school/subjects');
  }
}
