/**
 * Created by Maxim B. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
export class Period {

  constructor(public name: string = '',
              public startDate: Date = new Date,
              public endDate: Date = new Date) {
  }

}
