import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'curr-pagi-for-loading-data',
  templateUrl: './pagi-for-loading-data.component.html',
  styleUrls: ['./pagi-for-loading-data.component.scss']
})
export class PagiForLoadingDataComponent implements OnInit, OnChanges {

  @Input() currPage = 0;
  @Input() totalPages = 1;
  @Input() showSideBtns = true;
  @Output() pageSelected = new EventEmitter<number>();
  arr=[];

  constructor() { }


  ngOnChanges(changes: SimpleChanges): void {
    this.arr = new Array(this.totalPages);
  }

  ngOnInit(): void {
  }

  setCurrentPage(index: number) {
    this.currPage = index;
    this.pageSelected.emit(this.currPage);
  }
}
