<!--
  ~ Created by Yaroslav S. on 16/04/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="full-screen-popup" (animationend)="animationDone($event)" (click)="onDialogClicked($event)">
  <div class="full-screen-popup-header">
    <div class="popup-header-title">{{ dialogTitle }}</div>
    <div class="popup-header-actions">
      <mat-icon [svgIcon]="isSizeMini ? 'maximize' : 'minimize'" (click)="minimizePopup()"></mat-icon>
      <mat-icon *ngIf="showFullScreenBtn" [svgIcon]="isFullScreen ? 'close-fullscreen' : 'open-in-full'" (click)="openIn()"></mat-icon>
      <mat-icon [svgIcon]="'close-dialog'" (click)="close()"></mat-icon>
    </div>
  </div>
  <div class="full-screen-popup-content">
      <ng-template appInsertion></ng-template>
  </div>
</div>
