<!-- Created by Maxim B. on 07/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="video-container" [ngClass]="isMediaItemVisible || inEditMode ? '' : 'allow-hover-effect'" *ngIf="mediaItem">
  <div class="media-container no-border-top" #mediaContainer fxLayout="column" fxLayoutAlign="start start"
       *ngIf="mediaItem.resource_type === MediaType.video"
       [ngClass]="{'no-height-for-media':!isMediaItemVisible || (inEditMode && !isMediaItemVisible)}">
    <div class="video-frame" id="{{ containerId }}"></div>
  </div>

  <div
    class="head-container"
    fxLayout="row"
    fxLayoutAlign="space-between flex-start"
    fxLayoutGap="16px"
    [ngClass]="{ 'icon-top': !inEditMode }"
    [ngClass]="{ 'm-t-16': mediaItem.resource_type === MediaType.video }"
  >
    <div class="play-btn-container" fxFlex="initial">
      <mat-icon *ngIf="mediaItem.resource_type === MediaType.video">play_arrow</mat-icon>
      <mat-icon class="card-no-bg" *ngIf="mediaItem.resource_type === MediaType.image">image</mat-icon>
      <mat-icon class="card-no-bg doc" *ngIf="mediaItem.resource_type === MediaType.document">note</mat-icon>
      <mat-icon class="card-no-bg" *ngIf="mediaItem.resource_type === MediaType.link">link</mat-icon>
    </div>
    <!--    Form block -->
    <div class="info-block" fxFlex *ngIf="inEditMode">
      <form [formGroup]="mediaItemForm"
            fxLayout="column" class="add-paddings form">
        <curr-input
          fxFlex
          placeholder="Title"
          [isBordered]="true"
          inputType="string"
          [formGroup]="mediaItemForm"
          controlName="title"
          errorMessage="Must not be empty"></curr-input>
        <curr-textarea [formGroup]="mediaItemForm"
                       controlName="description"
                       errorMessage="Must not be empty"
                       rows="4"
                       label="Description"></curr-textarea>
        <!--        not image -->
        <ng-container *ngIf="mediaItem.resource_type != MediaType.image&&mediaItem.resource_type != MediaType.document">
          <curr-input
            fxFlex
            placeholder="URL"
            [isBordered]="true"
            inputType="string"
            [formGroup]="mediaItemForm"
            controlName="resource"
            errorMessage="ex. https://youtube.com/video/uid"
          ></curr-input>
          <curr-input
            fxFlex
            placeholder="Estimated view time (min)"
            [isBordered]="true"
            inputType="number"
            [formGroup]="mediaItemForm"
            controlName="estimated_time"
            errorMessage="Must not be empty and number"
          ></curr-input>
        </ng-container>
        <!--        image-->
        <ng-container *ngIf="mediaItem.resource_type === MediaType.image">
          <curr-upload-files
            class="add-paddings"
            type="edit-resource"
            [hint]="''"
            title="Drop image here"
            [displayPhotoBtn]="false"
            [automaticallyImport]="false"
            [formats]="['image/jpeg', 'image/jpg','image/png']"
            [enableProgressbard]="false"
            (onloadedFile)="onLoadedFile($event,'IMAGE')"
          ></curr-upload-files>
        </ng-container>
        <!-- document-->
        <ng-container *ngIf="mediaItem.resource_type === MediaType.document">
          <curr-upload-files
            class="add-paddings"
            type="edit-resource"
            [hint]="''"
            title="Drop document here"
            [displayPhotoBtn]="false"
            [automaticallyImport]="false"
            [formats]="['.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.pdf']"
            [enableProgressbard]="false"
            (onloadedFile)="onLoadedFile($event, 'DOC')"
          ></curr-upload-files>
        </ng-container>

        <div class="submit-container" fxLayout="row" fxLayoutAlign="start center">

          <curr-button variant="contained" color="primary" fxFlex="92px"
                       [isDisabled]="!mediaItemForm.valid"
                       (btnClick)="onSubmit()">
            SAVE
          </curr-button>

          <curr-button variant="outlined" (btnClick)="cancelEdit()" class="m-l-8" fxFlex="92px">
            CANCEL
          </curr-button>
        </div>
      </form>
    </div>
    <!--    End form block -->
    <div class="info-block" fxFlex *ngIf="!inEditMode" (click)="expand()">
      <p class="title">{{ mediaItem.title }}</p>
      <p class="description" fxHide.lt-sm
         *ngIf="mediaItem.resource_type !== MediaType.video">{{ mediaItem.description }}</p>
      <p class="duration">
        <mat-icon svgIcon="watch-later"></mat-icon>
        <span>{{ mediaItem.estimated_time || '--'}} min.</span>
      </p>
    </div>

    <div class="options" fxFlex="initial" *ngIf="!inEditMode && isEditable">
      <mat-icon [matMenuTriggerFor]="beforeMenu">more_horiz</mat-icon>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="editMediaItem()">
          <mat-icon>edit</mat-icon>
          Edit
        </button>
        <button mat-menu-item (click)="deleteMediaItem()">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
        <div *ngIf="currUser.role === roles.Student">
          <button mat-menu-item (click)="saveMediaItem()">
            <mat-icon [svgIcon]="'save-bookmark'"></mat-icon>
            Save
          </button>
          <button mat-menu-item (click)="createTask()">
            <mat-icon [svgIcon]="'plus'"></mat-icon>
            Create Task
          </button>
          <button mat-menu-item (click)="addNote()">
            <mat-icon [svgIcon]="'edit'"></mat-icon>
            Add Note
          </button>

        </div>
      </mat-menu>
    </div>

    <div class="arrow-container" fxFlex="initial" *ngIf="!inEditMode">
      <mat-icon
        (click)="expand()"
        *ngIf="
          mediaItem.resource_type === MediaType.video ||
          mediaItem.resource_type === MediaType.image ||
          mediaItem.resource_type === MediaType.link
        "
      >
        {{ !isMediaItemVisible ? 'arrow_drop_down' : 'arrow_drop_up' }}
      </mat-icon>
      <a *ngIf="mediaItem.resource_type === MediaType.document"
        class="download-file"
        [href]="docFile"
        [download]="mediaItem.resource"
        target="_blank">
        <curr-button variant="outlined" [icon]="'download'" [style]="fileInputBtnStyle">
          Download file
        </curr-button>
      </a>
    </div>
  </div>


  <div *ngIf="mediaItem.resource_type === MediaType.video"
       class="video-desc-cont no-border-top" fxLayout="row" fxFlexAlign="space-between start">
    <p class="description m-l-80" [class.collapsed-desc]="!isVideoDescVisible" fxHide.lt-sm>{{ mediaItem.description }}</p>
    <div class="arrow-container" fxFlex="initial" *ngIf="!inEditMode">
      <mat-icon class="video-desc" (click)="expandDesc()">
        {{ !isVideoDescVisible ? 'arrow_drop_down' : 'arrow_drop_up' }}
      </mat-icon>
    </div>
  </div>

  <div class="media-container" fxLayout="column" fxLayoutAlign="start start"
       *ngIf="mediaItem.resource_type !== MediaType.video"
       [ngClass]="{'no-height-for-media':!isMediaItemVisible || (inEditMode && !isMediaItemVisible)}">

    <div
      class="image-container"
      *ngIf="mediaItem.resource_type === MediaType.image"
    >
      <div class="hover-container" (click)="openImagePreviewDialog()">
        <mat-icon>zoom_out_map</mat-icon>
      </div>
      <img [src]="imgUrl" *ngIf="imgUrl" alt="image">

    </div>
    <div class="link-preview-container" *ngIf="mediaItem.resource_type === MediaType.link">
      <div class="image-container">
        <img [src]="mediaItem.previewUrl || mediaItem.resource" alt="">
      </div>
      <div
        fxLayout="column"
        fxLayoutAlign="start start"
        class="link-container full-width"
        *ngIf="mediaItem.resource_type === MediaType.link"
      >
        <span class="link-title m-t-16">{{ mediaItem.title }}</span>
        <span class="link-desc">{{ mediaItem.description }}</span>
        <div class="m-b-0" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon svgIcon="link" color="primary" class="m-r-16"></mat-icon>
          <a [href]="mediaItem.resource" target="_blank">{{ replaceLink(mediaItem.resource) }}</a>
        </div>
      </div>


    </div>
  </div>

</div>

