/**
 * Created by Maxim B. on 26/05/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
// @ts-ignore
import terms from './terms.json'


@Injectable()
export class GlossaryTermsResolver implements Resolve<any> {
  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
      return of(terms);
  }
}
