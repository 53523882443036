<!-- Created by Alex Poh. on 23/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<header class="header">
  <div fxHide.xs fxLayout="row" class="wide-header" fxLayoutAlign="space-between">
    <div fxFlex="257px" class="logo" (click)="goToDashboard()"
         fxLayoutAlign="start start" fxLayout="row">
      <mat-icon [inline]="true" [svgIcon]="'logo-new'"></mat-icon>
    </div>
    <div *currHasRoles="[userRole.HubAdmin]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel m-t-32">
      <a
        [routerLink]="['/dashboard']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
      >
        <span>Dashboard</span>
      </a>
      <a
        [routerLink]="['/customers']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
      >
        <span>Customers</span>
      </a>
      <a
        [routerLink]="['/curriculums']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
      >
        <span>Curriculums</span>
      </a>
      <a
        [routerLink]="['/countries']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
      >
        <span>Countries</span>
      </a>
      <a [routerLink]="['/admins']" routerLinkActive="active-text border-bottom" class="link mat-body-1 courses-link">
        <span>Administrators</span>
      </a>
      <a
        [routerLink]="['/university']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
      >
        <span>University</span>
      </a>
    </div>
    <div *currHasRoles="[userRole.Student]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel m-t-20">
      <a
        [routerLink]="['/dashboard']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
        data-html="true"
        data-tooltip="Dashboard"
      >
        <span>
          <mat-icon [svgIcon]="'dashboard'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="!isISGSE ? ['/courses-details']: null"
        [class.disabled]="isISGSE"
        [routerLinkActive]="!isISGSE ? 'active-text border-bottom' : ''"
        class="link mat-body-1 courses-link subjects-link"
        data-html="true"
        data-tooltip="Subjects"
      >
        <span>
          <mat-icon class="hat" [svgIcon]="'hat'"></mat-icon>
        </span>
      </a>
      <!-- <a
        (click)="navigateToTutorSession()"
        *ngIf="!isExamPreparation && isLoadedSubscriptionInfo"
        class="link mat-body-1 courses-link disabled-menu-item"
        data-html="true"
      >
        <span>
          <mat-icon class="header-icon" [svgIcon]="'tv'"></mat-icon>
        </span>
      </a> -->
<!--      <a-->
<!--        [routerLink]="['/#']"-->
<!--        routerLinkActive="active-text border-bottom"-->
<!--        class="link mat-body-1 courses-link"-->
<!--        data-html="true"-->
<!--        data-tooltip="Notification"-->
<!--      >-->
<!--        <span>-->
<!--          <mat-icon class="header-icon" [svgIcon]="'bell-notification'"></mat-icon>-->
<!--        </span>-->
<!--      </a>-->
    </div>
<!--    <div *currHasRoles="[userRole.Student]" class="coins m-t-20 m-r-40">-->
<!--      <span class="coins-number mat-h4 m-r-12">1234</span>-->
<!--      <mat-icon class="header-icon" [svgIcon]="'coin'"></mat-icon>-->
<!--    </div>-->
    <div *currHasRoles="[userRole.Teacher]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel m-t-20">
      <a
        [routerLink]="['/dashboard']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
        data-html="true"
        data-tooltip="Dashboard"
      >
        <span>
          <mat-icon [svgIcon]="'dashboard'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/subjects']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Courses"
      >
        <span>
          <mat-icon [svgIcon]="'hat'"></mat-icon>
        </span>
      </a>
      <!-- <a
        (click)="navigateToTutorSession()"
        *ngIf="!isExamPreparation && isLoadedSubscriptionInfo"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link disabled-menu-item"
        data-html="true"
      >
        <span>
          <mat-icon [svgIcon]="'tv'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/curriculum-management']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Curriculum management"
      >
        <span>
          <mat-icon [svgIcon]="'files'"></mat-icon>
        </span>
      </a> -->
      <!-- <a
        [routerLink]="['/5']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Students"
      >
        <span>
          <mat-icon [svgIcon]="'students'"></mat-icon>
        </span>
      </a> -->
<!--      <a-->
<!--        [routerLink]="['/2']"-->
<!--        routerLinkActive="active-text border-bottom"-->
<!--        class="link mat-body-1 courses-link  courses-link"-->
<!--      >-->
<!--        <span>-->
<!--          <mat-icon class="header-icon" [svgIcon]="'bell-notification'"></mat-icon>-->
<!--        </span>-->
<!--      </a>-->
<!--      <a-->
<!--        [routerLink]="['/1']"-->
<!--        routerLinkActive="active-text border-bottom"-->
<!--        class="link mat-body-1 courses-link  courses-link"-->
<!--      >-->
<!--        <span>-->
<!--          <mat-icon [svgIcon]="'settings'"></mat-icon>-->
<!--        </span>-->
<!--      </a>-->
    </div>
    <!-- <div *currHasRoles="[userRole.Teacher]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel-add m-t-20">
      <a
        [routerLink]="['/2']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Notification"
      >
        <span>
          <mat-icon class="header-icon" [svgIcon]="'bell-notification'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/1']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Settings"
      >
        <span>
          <mat-icon [svgIcon]="'settings'"></mat-icon>
        </span>
      </a>
    </div> -->
    <div *currHasRoles="[userRole.SchoolAdmin]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel m-t-20">
      <a
        [routerLink]="['/dashboard']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link"
        data-html="true"
        data-tooltip="Dashboard"
      >
        <span>
          <mat-icon [svgIcon]="'dashboard'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/subjects']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Courses"
      >
        <span>
          <mat-icon [svgIcon]="'hat'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/curriculum-management']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Curriculum management"
      >
        <span>
          <mat-icon [svgIcon]="'files'"></mat-icon>
        </span>
      </a>
      <a
        [routerLink]="['/users']"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Users"
      >
        <span>
          <mat-icon [svgIcon]="'students'"></mat-icon>
        </span>
      </a>
    </div>
    <!-- <div *currHasRoles="[userRole.SchoolAdmin]" fxFlex fxLayout="row" fxLayoutAlign="center" class="nav-panel-add m-t-20">
      <a
        [matMenuTriggerFor]="settingsMenu"
        routerLinkActive="active-text border-bottom"
        class="link mat-body-1 courses-link  courses-link"
        data-html="true"
        data-tooltip="Settings"
      >
        <span>
          <mat-icon [svgIcon]="'settings'"></mat-icon>
        </span>
      </a>
    </div> -->
    <div fxLayout="row" fxLayoutAlign="end center" class="header-label">
      <!-- <mat-menu #settingsMenu="matMenu" xPosition="before" class="user-menu">
        <button mat-menu-item (click)="menuClick('managing-api-keys')" class="user-menu-btn">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>Managing API Keys</span>
          </div>
        </button>
        <button mat-menu-item (click)="menuClick('profile')" class="user-menu-btn">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>System entities</span>
          </div>
        </button>
        <button mat-menu-item (click)="menuClick('notes')" class="user-menu-btn">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>Report generator</span>
          </div>
        </button>
        <button mat-menu-item (click)="menuClick('policy')" class="user-menu-btn">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>School Year Settings</span>
          </div>
        </button>
        <button mat-menu-item (click)="menuClick('tos')" class="user-menu-btn no-bottom-border">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span>Settings</span>
          </div>
        </button>
      </mat-menu> -->
      <div
        fxFlex
        fxLayout="row"
        fxLayoutAlign="end center"
        class="menu-trigger-container"
        [matMenuTriggerFor]="userMenu"
        (click)="$event.stopPropagation(); isNameDDOpen = true"
      >
        <img class="profile" [src]="imagePreviewFile" *ngIf="imagePreviewFile" fxFlex="40px" alt="image" />
        <div fxFlex="40px" *ngIf="!imagePreviewFile" class="initials-wrapper">
          <curr-initials-avatar [name]="name" size="24px"></curr-initials-avatar>
        </div>
        <span class="name">{{ name }}</span>
        <mat-icon class="arrow-down-menu" [svgIcon]="'arrow_down'"></mat-icon>
        <mat-menu #userMenu="matMenu" xPosition="before" class="user-menu">
          <button mat-menu-item (click)="menuClick('profile')" class="user-menu-btn">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="user"></mat-icon>
              My profile
            </div>
          </button>
          <button *currHasRoles="[userRole.SchoolAdmin]" mat-menu-item (click)="menuClick('managing-api-keys')" class="user-menu-btn">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="settings"></mat-icon>
              Managing API Keys
            </div>
          </button>
          <button *currHasRoles="[userRole.Student, userRole.Teacher]" mat-menu-item (click)="menuClick('video')" class="user-menu-btn">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="orientation-video"></mat-icon>
              Orientation Video
            </div>
          </button>
          <!-- <button
            mat-menu-item
            (click)="menuClick('dashboard')"
            *currHasRoles="[userRole.Teacher]"
            class="user-menu-btn"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <span>Schedule</span>
            </div>
          </button> -->
          <!-- <button mat-menu-item (click)="menuClick('referral')" class="user-menu-btn">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="referral-program"></mat-icon>
              Referral Program
            </div>
          </button> -->
          <!-- <button mat-menu-item (click)="menuClick('settings')" class="user-menu-btn">
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="settings"></mat-icon>
              Settings
            </div>
          </button> -->
          <button
            mat-menu-item
            (click)="menuClick('logout')"
            class="user-menu-btn"
            fxLayout="row"
            fxLayoutAlign="start center"
          >
            <div fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="m-r-14" svgIcon="logout-green"></mat-icon>
              Logout
            </div>
          </button>
<!--          <button mat-menu-item (click)="menuClick('policy')" class="user-menu-btn">-->
<!--            <div fxLayout="row" fxLayoutAlign="start center">-->
<!--              <span>Privacy Policy</span>-->
<!--            </div>-->
<!--          </button>-->
<!--          <button mat-menu-item (click)="menuClick('tos')" class="user-menu-btn">-->
<!--            <div fxLayout="row" fxLayoutAlign="start center">-->
<!--              <span>Terms of service</span>-->
<!--            </div>-->
<!--          </button>-->
        </mat-menu>
      </div>
    </div>

    <div class="notification-wrapper">
      <curr-notifications-bar
        (onCloseBar)="showNotificationBar()"
        [subscriptionObject]="notificationSubscription"
        [currentState]="switchState"
      ></curr-notifications-bar>
    </div>
  </div>

  <div class="mobile-header" fxHide.gt-xs>
    <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-icon svgIcon="view-headline" class="menu-btn" (click)="toggleStudentMenu()"></mat-icon>
      <div class="logo-mobile" (click)="goToDashboard()">
        <mat-icon [inline]="true" svgIcon="curr-logo-mobile"></mat-icon>
      </div>
    </div>
    <div class="notification-wrapper">
      <curr-notifications-bar
        (onCloseBar)="showNotificationBar()"
        [subscriptionObject]="notificationSubscription"
        [currentState]="switchState"
      ></curr-notifications-bar>
    </div>
  </div>
</header>

<curr-mobile-menu
  fxHide.gt-xs
  *currHasRoles="[userRole.Student]"
  [isActive]="isActive"
  [userPages]="studentPages"
  [generalPages]="generalPages"
  [userInfo]="{name: name, image: imagePreviewFile}"
  (emitTutorSession)="navigateToTutorSession()"
  (menuClosed)="menuClosing()"
  (emitMenu)="menuClick($event)"
></curr-mobile-menu>
