<!-- Created by Maxim B. on 03/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="years-picker-container"  fxLayout="row"
     fxLayoutAlign="start center" fxLayoutGap="16px">
  <div class="drop-down-container">
    <curr-dropdown
      [options]="selectOptions"
      [defaultValue]="selectOptions[0]"
      placeholder="Select Years"
      (valueChange)="setSelectedYearsRange($event)">
    </curr-dropdown>
  </div>
  <curr-button variant="contained" color="primary"
               (click)="addNewYearsRange()" size="small" icon="building">
    CREATE NEW YEAR RANGE
  </curr-button>
</div>
