<!-- Created by Alex Poh. on 20/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-school-admin-dashboard">
  <div class="two-section-row">
    <div class="two-section-row-item m-b-24">
      <div class="dashboard-outside-title">
        <div class="title-text">Task List</div>
        <div class="title-actions">
          <curr-button class="add-new-tasks" variant="overflow" icon="cross" size="big" (click)="addNewTask()"
            >Add New</curr-button
          >
        </div>
      </div>
      <div class="dashboard-item-container">
        <curr-task-list #tasksList [showTitleSection]="false"></curr-task-list>
      </div>
    </div>
    <div class="two-section-row-item m-b-24">
      <div class="dashboard-outside-title">
        <div class="title-text">School Statistic</div>
      </div>
      <div *ngIf="assessmentChartNoData" class="dashboard-item-container">
        <div class="assessment-chart-no-data">
          <img src="/assets/images/colored/amico.svg" />
          <div class="assessment-chart-no-data-text">No data to show.</div>
        </div>
      </div>
      <div *ngIf="!assessmentChartNoData" class="dashboard-item-container">
        <div class="chart-title">Cumulative Tests Results</div>

        <div class="assessment-chart-loader" *ngIf="assessmentChartLoading">
          <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
        </div>

        <ng-container *ngIf="!assessmentChartLoading">
          <div class="chart-filters">
            <div class="chart-filters-item"><span>Avg. Grade: {{assessmentResultsMark ?? '-'}}</span></div>
            <div class="chart-filters-item assessment-chart-subjects">
              <div class="chart-teacher-link" (click)="navigateToTeacher()">{{assessmentChartSelectedSubject.teacherName}}</div>
              <curr-dropdown-component-v2
              [selectedValue]="assessmentChartSubjects.length > 0 ? assessmentChartSubjects[0].value : ''"
              [options]="assessmentChartSubjects"
              (selectedValueChange)="assessmentChartSubjectsChange($event)">
              </curr-dropdown-component-v2>
            </div>
          </div>
          <div class="chart-date">{{ assessmentResultDate }}</div>
          <curr-assessment-results-chart [data]="assessmentResultsChart" [month]="assessmentResultsChartMonth"></curr-assessment-results-chart>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="dashboard-outside-title">
    <div class="title-text">Teachers Statistic</div>
  </div>
  <div class="dashboard-section">
    <curr-teachers-statistic-table [averageBenchmark]="chartAverageBenchmark" [selectedCourseId]="preselectedCourseId"></curr-teachers-statistic-table>
  </div>
</div>
