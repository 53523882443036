<!-- Created by Maxim B. on 20/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="hint-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon" svgIcon="exclamation-mark" color="primary"></mat-icon>
  <span class="mat-body-1">
  Questions 7–9 are about the amount of teaching contact time per year group
  </span>
</div>

<div class="block m-t-16" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    7. What is your number of minutes for the lesson?</h2>
  <curr-input [(value)]="ys.lessonMinutes" inputType="number"
              placeholder="Lesson Minutes" class="semesters-input p-l-8"></curr-input>
</div>

<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    8. For each year group, please indicate any weeks or days throughout the school year during which teaching will cease
    (for example due to exams, school trips, etc.):</h2>
  <div class="grade-select-container m-b-16 full-width p-l-8"
       fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-0 m-l-16">{{1}} Year</h2>
    <div fxLayout="row" fxLayoutAlign="end center">

    </div>
  </div>

</div>

<mat-divider *ngIf="isNew"></mat-divider>
<div class="bottom-btns-container m-t-32" fxLayout="row" fxLayoutAlign="center center" *ngIf="isNew">
  <curr-button variant="contained" fxFlex="200px" class="m-r-32" (btnClick)="saveSettings('save')" >COMPLETE AND SAVE</curr-button>
  <curr-button variant="text" fxFlex="150px"  (btnClick)="goToNext('prev')">PREVIOUS STEP</curr-button>
</div>
