/**
 * Created by Daniil Vat. on 28/3/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { IHardestQuestion } from '../../interfaces';
import { fadeAnimation } from '../../../shared/animations/fade.animation';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagePopupComponent } from '../../../shared/components/image-popup/image-popup.component';

@Component({
  selector: 'curr-hardest-questions',
  templateUrl: './hardest-questions.component.html',
  styleUrls: ['./hardest-questions.component.scss'],
  animations: [fadeAnimation]
})
export class HardestQuestionsComponent implements OnInit {
  @Input() set data(value: IHardestQuestion[]) {
    this.receivedData = value;

    if (this.receivedData?.some(question => !!~question.questionMessage.indexOf('<img src'))) {
      for (const question of this.receivedData) {
        this.uploadImages(question);
      }
    } else {
      this.displayedData = value?.slice(this.offset, this.limit);
    }
    this.displayedData = value?.slice(this.offset, this.limit);
  }

  offset = 0;
  limit = 4;

  receivedData: IHardestQuestion[];
  displayedData: IHardestQuestion[];

  imageKeyValue: { [key: string]: string } = {};
  constructor(private fileUploadService: FileUploadService, private dialog: MatDialog) {}

  ngOnInit() {}

  uploadImages(questions: IHardestQuestion) {
    this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(questions.questionMessage);
    let questionMessage = questions.questionMessage;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(this.imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          this.imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          questionMessage = questionMessage.replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            `(<a class="show-image" href="${key}">show image</a>) `
          );
        }
        questions.questionMessage = questionMessage;
      },
      complete: () => {
        this.displayedData = this.receivedData?.slice(this.offset, this.limit);
      }
    });
  }

  @HostListener('click', ['$event'])
  onMouseEnter(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.classList.contains('show-image')) {
      const imgKey = (e.target as HTMLElement).getAttribute('href');

      this.dialog.open(ImagePopupComponent, {
        data: {
          imgSrc: this.imageKeyValue[imgKey]
        }
      });
    }
  }

  showMore() {
    this.offset += 4;
    this.displayedData = [...this.displayedData, ...this.receivedData?.slice(this.offset, this.limit + this.offset)];
  }
}
