/**
 * Created by Alex Poh. on 07/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { P5DemoComponent } from './p5-demo/p5-demo.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

import { UiKitComponent } from './ui-kit/ui-kit.component';
import { ViewSelectorComponent } from './view-selector/view-selector.component';
import { PageCardPreviewComponent } from './view-selector/page-card-preview/page-card-preview.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageAsImageComponent } from './page-as-image/page-as-image.component';
import { StoryBookComponent } from './story-book/story-book.component';
import { MatTreeModule } from '@angular/material/tree';
import { TestDemoViewSelectorComponent } from './view-selector/test-demo-view-selector.component';

const routes: Routes = [
  {
    path: 'test-demo',
    component: TestDemoViewSelectorComponent
  },

  {
    path: 'demo',
    component: ViewSelectorComponent
  },
  {
    path: 'demo/p5',
    component: P5DemoComponent
  },

  {
    path: 'demo/ui-kit',
    component: UiKitComponent
  },
  {
    path: 'demo/story-book',
    component: StoryBookComponent,
    data: {
      breadcrumb: [
        {
          label: 'Demo',
          url: ''
        },
        {
          label: 'Breadcrumb',
          url: ''
        },
        {
          label: 'Example',
          url: ''
        }
      ]
    }
  }
];

@NgModule({
  declarations: [
    P5DemoComponent,
    TestDemoViewSelectorComponent,
    UiKitComponent,
    ViewSelectorComponent,
    StoryBookComponent,
    PageCardPreviewComponent,
    PageAsImageComponent
  ],
  imports: [CommonModule, MatTreeModule, FlexLayoutModule, SharedModule, RouterModule.forChild(routes)]
})
export class DemoModule {}
