/**
 * Created by Alex Poh. on 25/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SchoolPerformanceHistogramService } from './school-performance-histogram.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GraphOptions } from '../graph-options';
import { Histogram } from './series.model';

@Component({
  selector: 'curr-school-performance-histogram',
  templateUrl: './school-performance-histogram.component.html',
  styleUrls: ['./school-performance-histogram.component.scss'],
  providers: [SchoolPerformanceHistogramService]
})
export class SchoolPerformanceHistogramComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('chartContainer') chartContainer: ElementRef;

  @Input()
  chartData$: Observable<Histogram>;

  @Input()
  width: number;

  options: GraphOptions;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private histogramService: SchoolPerformanceHistogramService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.options = {
      width: changes.width.currentValue,
      height: 300,
      margin: {
        top: 20,
        bottom: 20,
        right: 25,
        left: 50
      }
    };
    this.chartContainer.nativeElement.style.height = this.options.height;
    this.chartContainer.nativeElement.style.width = changes.width.currentValue;
    this.histogramService.init(this.chartContainer.nativeElement, this.options);
    this.chartData$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(dataValue => this.histogramService.updateHistogram(dataValue));
  }

  ngAfterViewInit(): void {
    this.options = {
      width: this.width ? this.width : window.innerWidth / 2,
      height: 300,
      margin: {
        top: 20,
        bottom: 20,
        right: 25,
        left: 50
      }
    };
    this.chartContainer.nativeElement.style.height = this.options.height;
    this.chartContainer.nativeElement.style.width = this.options.width;
    this.histogramService.init(this.chartContainer.nativeElement, this.options);
    this.chartData$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(dataValue => this.histogramService.updateHistogram(dataValue));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
}
