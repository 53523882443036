/**
 * Created by Alex Poh. on 26/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { SunburstComponent } from './subject-details/sunburst/sunburst.component';
import { GanttChartD3Component } from './subject-details/gantt-chart-d3/gantt-chart-d3.component';
import { SubjectDetailsResolver } from './subject-details/subject-details.resolver';
import { CoursesComponent } from './courses/courses.component';
import { CurriculumManagementComponent } from './curriculum-managment/curriculum-management.component';
import { TestIncludedTopicsResolver } from './edit-test/test-included-topics.resolver';
import { GanttChartComponent } from './subject-details/gantt-chart/gantt-chart.component';
import { SubjectDetailsComponent } from './subject-details/subject-details.component';
import { CumulativeTestResolver } from './edit-test/cumulative-test.resolver';
import { EditTestComponent } from './edit-test/edit-test.component';
import { PaperDetailsComponent } from './subject-details/paper-details/paper-details.component';
import { FakeGanttChartComponent } from './subject-details/fake-gantt-chart/fake-gantt-chart.component';
import { CourseStudentProfileComponent } from './courses/course-student-profile/course-student-profile.component';
import { PaperDetailsResolver } from './subject-details/paper-details/paper-details.resolver';
import { CourseResolver } from './subject-details/course.resolver';

const subjectsRoutes = [
  {
    path: 'paper/:paperId',
    component: PaperDetailsComponent,
    resolve: {
      paper: PaperDetailsResolver
    }
  },
  {
    path: 'paper/:paperId/ganttChart',
    component: GanttChartComponent
  },
  {
    path: 'paper/:paperId/ganttChartD3',
    component: GanttChartD3Component
  },
  {
    path: 'paper/:paperId/ganttChart2',
    component: FakeGanttChartComponent
  },
  {
    path: 'paper/:paperId/sunburst',
    component: SunburstComponent
  }
];

export const sharedPages = [
  {
    path: 'curriculum-management',
    component: CurriculumManagementComponent,
    data: {
      breadcrumb: {
        label: 'Curriculum Management',
        url: ''
      }
    }
  },
  {
    path: 'subjects',
    component: CoursesComponent,
    data: {}
  },
  {
    path: 'edit/:testType/:paperId/:testId/:schoolYearCourseId',
    component: EditTestComponent,
    resolve: {
      subjectTest: CumulativeTestResolver,
      subjectTopics: TestIncludedTopicsResolver
    }
  },
  {
    path: 'subjects/:subjectId',
    component: SubjectDetailsComponent,
    resolve: {
      subjectDetails: SubjectDetailsResolver
    },
    children: subjectsRoutes
  },
  {
    path: 'subjectsUpdate/:subjectId',
    component: SubjectDetailsComponent,
    resolve: {
      subjectDetails: SubjectDetailsResolver
    },
    data: {
      isNew: true
    },
    children: subjectsRoutes
  },
  {
    path: 'subjectsUpdate/:subjectId/:schoolYearCourseId',
    component: SubjectDetailsComponent,
    resolve: {
      subjectDetails: SubjectDetailsResolver,
      course: CourseResolver
    },
    data: {
      isNew: true
    },
    children: subjectsRoutes
  },
  {
    path: 'courses/:subjectId/:studentId',
    component: CourseStudentProfileComponent
  }
];
