import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { StudentNote } from './student-note';

@Injectable({
  providedIn: 'root'
})
export class ResourceStudentNotesService {
  constructor(private httpClient: ApplicationHttpClient) {}

  addNote(note: StudentNote) {
    return this.httpClient.post(`/studentLearningObjectiveNotes`, note);
  }

  editNote(note: StudentNote) {
    return this.httpClient.put(`/studentLearningObjectiveNotes`, note);
  }

  getNoteById(id: number) {
    return this.httpClient.get(`/studentLearningObjectiveNotes/${id}`);
  }

  getNoteByTopicId(studentId: number, learningObjectiveId: number) {
    return this.httpClient.get(`/studentLearningObjectiveNotes/${studentId}/lobjective/${learningObjectiveId}`);
  }

  deleteNote(id: number) {
    return this.httpClient.delete(`/studentLearningObjectiveNotes/${id}`);
  }
}
