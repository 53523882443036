/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ILowestComprehensionObject } from '../../interfaces';

@Component({
  selector: 'curr-lowest-comprehension-objects',
  templateUrl: './lowest-comprehension-objects.component.html',
  styleUrls: ['./lowest-comprehension-objects.component.scss']
})
export class LowestComprehensionObjectsComponent implements OnInit {
  @Input() set data(value: ILowestComprehensionObject[]) {
    this.receivedData = value;
    this.displayedData = value?.slice(this.offset, this.limit);
  }

  offset = 0;
  limit = 4;

  receivedData: ILowestComprehensionObject[];
  displayedData: ILowestComprehensionObject[];

  constructor() {}

  ngOnInit() {}

  showMore() {
    this.offset += 4;
    this.displayedData = [...this.displayedData, ...this.receivedData?.slice(this.offset, this.limit + this.offset)];
  }
}
