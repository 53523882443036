<div class="full-width container">

  <curr-question-form-question-block [question]="question" [inSowPage]="inSowPage" [questionLabel]="questionLabel"
    [questionPlaceholder]="questionPlaceholder" class="m-b-8"></curr-question-form-question-block>
  <div class="m-b-16 full-width"></div>
  <curr-question-form-answer-block [question]="question" [isCTQuestion]="isCTQuestion" [inSowPage]="inSowPage"
    [isMultipartQuestion]="true" [isEditQuestionMode]="isEditSubQuestionMode"></curr-question-form-answer-block>

  <div class="m-t-16 m-b-16" fxLayout="column" *ngIf="!isCTQuestion">
    <span class="header mat-h4 m-b-0" fxFlex>Explanation video:</span>
    <curr-input (valueChange)="setVideoUrl($event)" class="dp-width" [formGroup]="mediaFormGroup"
      controlName="video_url" errorMessage="ex. https://youtube.com/video/uid">
    </curr-input>
  </div>


  <div class="block full-width">
    <div class="block-header" fxLayout="row" fxLayoutAlign="space-between center">
      <span class="header mat-h4 m-b-0" fxFlex *ngIf="!isCTQuestion">
        Confirmation of understanding right:
      </span>
    </div>
    <div class="block-content">
      <div fxLayout="column" fxLayoutAlign="start start" class="full-width">
        <curr-dropdown (valueChange)="selectCou($event)" class="m-b-24 full-width" placeholder="Select Cou"
          [options]="ddCOUQuestions | couRight: true" *ngIf="!isCTQuestion" [selectedValue]="question?.cou_right_id">
        </curr-dropdown>

        <span class="header mat-h4 m-b-0" fxFlex>
          Confirmation of understanding wrong:</span>
        <curr-dropdown (valueChange)="selectWrongCou($event)" class="m-b-24 full-width" placeholder="Select Cou"
          [options]="ddCOUQuestions | couRight: false" [selectedValue]="question?.cou_wrong_id"></curr-dropdown>

      </div>
    </div>
  </div>



  <div fxLayout="row" fxLayoutAlign="start center"
    [ngClass]="question.answer_type == questionType.OPEN_ENDED ? 'm-t-24':''" *ngIf="!isSAQuestion">
    <span class="mat-h4 m-b-0 m-r-8">Mark:</span>
    <curr-input inputType="number" [isCentered]="true" fxFlex="55px" class="m-r-24 number-input curr-input "
      [value]="question?.mark" (valueChange)="setSubQuestionMark($event)"></curr-input>
  </div>

  <div fxLayout="row" fxLayoutAlign="s tart center" fxLayoutGap="8px" class="m-t-32" *ngIf="isEditSubQuestionMode">
    <curr-button variant="contained" [isDisabled]="canSaveSubQuestion()" (btnClick)="saveSubQuestion()"> {{question.id ?
      'UPDATE' : 'SAVE'}}
    </curr-button>
    <curr-button variant="text" (btnClick)="deleteQuestion()">DELETE</curr-button>
  </div>
</div>