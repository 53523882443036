export interface Genders {
  value: string;
  label: string;
}

export const GENDERS: Genders[] = [
  { value: 'MALE', label: 'Male' },
  { value: 'FEMALE', label: 'Female' },
  { value: 'PREFER_NOT_SHARE', label: 'Prefer not to share' },
];
