import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { getRestApiProvider, RestApiService } from '../../api/rest-api.service';
import { Observable } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { CreateCurriculumModel, CreateSchemeOfWorkModel, Subject } from './create-curriculum.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { CreateSubjectPopupComponent } from '../create-subject-popup/create-subject-popup.component';

interface ExaminationBoard {
  id: string;
  name: string;
  country: string;
}

interface SchoolStudyYears {
  id: string;
  study_level: string;
  study_year: number;
  study_year_name: string;
}

interface PreCreateSchemeWork {
  schoolStudyYears: SchoolStudyYears[];
  examinationBoards: ExaminationBoard[];
}

@Component({
  selector: 'curr-create-curriculum-popup',
  templateUrl: './create-curriculum-popup.component.html',
  styleUrls: ['./create-curriculum-popup.component.scss'],
  providers: [
    getRestApiProvider<PreCreateSchemeWork>('preCreateSchemeWork', 'preCreateSchemeWork'),
    getRestApiProvider<CreateSchemeOfWorkModel>('schemeWorks', 'schemeWorkService'),
    getRestApiProvider<CreateCurriculumModel>('curriculums', 'curriculums'),
    getRestApiProvider<Subject>('getSubjects', 'getSubjects')
  ]
})
export class CreateCurriculumPopupComponent implements OnInit {
  formGroup: FormGroup;

  preCreateSchemeWork$: Observable<{
    schoolStudyYears: { value: string; label: string };
    examinationBoards: { value: string; label: string };
  }>;

  subjects: { value: string; label: string }[] = [];

  areDropdownsLoading = false;

  isCreatingLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    @Inject('preCreateSchemeWork') private preCreateSchemeWorkService: RestApiService<PreCreateSchemeWork>,
    @Inject('schemeWorkService') private schemeWorkService: RestApiService<CreateSchemeOfWorkModel>,
    @Inject('curriculums') private curriculumService: RestApiService<CreateCurriculumModel>,
    @Inject('getSubjects') private getSubjectsService: RestApiService<Subject>,
    public dialogRef: MatDialogRef<CreateSubjectPopupComponent>
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      subject: new FormControl('', [Validators.required]),
      study_year_id: new FormControl('', [Validators.required]),
      examination_board_id: new FormControl('', [Validators.required]),
      scheme_work_title: new FormControl('', [Validators.required])
    });
    this.areDropdownsLoading = true;
    this.preCreateSchemeWork$ = this.preCreateSchemeWorkService.getById('').pipe(
      map(preCreateSchemeWork => {
        const result: any = {};
        result.examinationBoards = preCreateSchemeWork.examinationBoards.map(examinationBoard => {
          return { value: examinationBoard.id, label: examinationBoard.name };
        });
        result.schoolStudyYears = preCreateSchemeWork.schoolStudyYears.map(schoolStudyYear => {
          return {
            value: schoolStudyYear.id,
            label: schoolStudyYear.study_year_name + ' ' + schoolStudyYear.study_year
          };
        });
        return result;
      }),
      finalize(() => {
        this.areDropdownsLoading = false;
      })
    );

    this.getSubjectsService.getAll().subscribe(res => {
      this.subjects = res.map(r => {
        return { value: r.subjectId, label: r.subjectId };
      });
    });
  }

  create() {
    if (this.formGroup.valid) {
      this.isCreatingLoading = true;
      this.schemeWorkService
        .add(this.formGroup.value)
        .pipe(
          switchMap((res: CreateSchemeOfWorkModel) => {
            const body = new CreateCurriculumModel();
            body.scheme_work_id = res.id;
            return this.curriculumService.add(body);
          }),
          finalize(() => {
            this.isCreatingLoading = false;
          })
        )
        .subscribe(res => {
          this.dialogRef.close();
          this.router.navigate(['/subjectsUpdate', res.id]);
        });
    }
  }
}
