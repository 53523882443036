import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';


/*
* HOW TO UPDATE THIS WIDGET?
*
* 1. Make changes in widget's repository - https://bitbucket.org/SEVEN_Collab/curriculum-frontend-preact-widget
* 2. Run `npm run build:standalone` in widget's directory
* 3. Copy bundle.<hash>.esm.js as bundle.esm.js and bundle.<hash>.css as bundle.css from build directory into src/assets/questions-widget/ in this project.
*
* */


@Directive({
  selector: 'div[currQuestionsWidget]'
})
export class QuestionWidgetDirective {
  constructor(
    private readonly elementRef: ElementRef<HTMLDivElement>,
    @Inject(DOCUMENT) private readonly docRef: Document,
    private readonly activatedRoute: ActivatedRoute,
    private readonly localStorageService: LocalStorageService
  ) {
    const courseId = activatedRoute.snapshot.paramMap.get('courseId');
    const token = localStorageService.get('token').split(' ')[1];

    const container = docRef.createElement('div');
    container.dataset.curriculumWidget = 'habitat';
    container.dataset.course = courseId;
    container.dataset.token = token;
    elementRef.nativeElement.appendChild(container);

    const styles = docRef.createElement('link');
    styles.rel = 'stylesheet';
    styles.href = 'https://diu6s6kn1snxn.cloudfront.net/bundle.css';
    elementRef.nativeElement.appendChild(styles);

    const script = docRef.createElement('script');
    script.src = 'https://diu6s6kn1snxn.cloudfront.net/bundle.esm.js';
    elementRef.nativeElement.appendChild(script);
  }
}
