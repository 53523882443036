/**
 * Created by Maxim B. on 11/05/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { CourseUsersService } from './course-users.service';
import { ActivatedRoute } from '@angular/router';
import { FileUploadService } from 'src/app/shared/components/edit-question-block/file-upload.service';

@Component({
  selector: 'curr-course-users',
  templateUrl: './course-users.component.html',
  styleUrls: ['./course-users.component.scss']
})
export class CourseUsersComponent implements OnInit {

  public itemsPerPage = 12;
  public users = [];
  public dataFromApi;
  public isLoading = false;
  public isInListView = false;
  public sortOption = 'name';
  public displayedColumns = ['id', 'avatar', 'name'];
  public dataSource: any;
  public courseId;

  public path = [
    {
      label: 'Courses',
      url: '/subjects'
    },
    {
      label: 'Students',
      url: ''
    }
  ];

  @ViewChild(MatSort, { static: false }) set content(sort: MatSort) {
    if (this.dataSource) {
      this.dataSource.sort = sort;
    }
  }

  constructor(private fileUploadService: FileUploadService,
    private courseUsersService: CourseUsersService,
    private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.courseId = this.route.snapshot.data.courseId;

    this.loadData(this.itemsPerPage, 0);
    if (this.isInListView) {
      this.initTable();
    }
  }

  initTable() {
    this.dataSource = new MatTableDataSource(this.users);
  }

  setUsersView() {
    this.isInListView = !this.isInListView;
    if (this.isInListView) {
      this.initTable();
    }
  }

  sortUsers(by: string) {
    this.sortOption = by;
  }

  private loadData(limit: number = 12, offset: number = 0) {
    let req;
    req = this.courseUsersService.getStudents(this.courseId, limit, offset);
    if (!req) {
      return;
    }
    this.isLoading = true;
    this.users = [];
    req.subscribe(resp => {
      this.dataFromApi = resp.data;
      this.users = resp.data?.content;
      this.dataSource = new MatTableDataSource(this.users);
      this.isLoading = false;

      this.users.forEach((user, i) => {
        if (user.photo) this.uploadAvatar(user, i);
      });
    }, () => {
      this.isLoading = false;
    });
  }

  pageSelected(page: number) {
    this.loadData(this.itemsPerPage, page);
  }

  private uploadAvatar(user, i) {
    this.fileUploadService
      .download(user.photo, 'IMAGE')
      .subscribe((resp: any) => {
        const bytes = resp.data?.bytes;
        if (bytes != null) {
          this.users[i].avatar = 'data:image/png;base64,' + bytes;
        }
      });
  }
}
