<div class="container">
  <div *ngIf="mediaArr?.length > 1">
    <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="space-between">
      <div fxFlex class="m-b-16 mat-h2  m-l-16">Popular videos for upcoming lessons</div>
      <div fxFlex="0 1 auto" fxLayout="row" fxLayoutGap="8px" class="m-b-16">
        <curr-button icon="arrow-left" variant="text" fxFlex="50px" [isDisabled]="showIndex===0"
          (btnClick)="navigate(-1)"></curr-button>
        <curr-button icon="arrow-right" variant="text" fxFlex="50px"
          [isDisabled]="screenWidth < 600 ? showIndex ===  mediaArr.length-1 : (showIndex+2)=== (mediaArr.length-1)"
          (btnClick)="navigate(1)"></curr-button>
      </div>
    </div>
    <mat-divider class="m-b-16"></mat-divider>
  </div>

  <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="space-between start" class="videos-wrapper">

    <ng-container *ngFor="let video of mediaArr; let i = index">
      <div class="video-container" fxLayout="column" (click)="openVideoPlayer(video)"
        *ngIf="screenWidth < 600 ? i === showIndex : i >= showIndex && i <= (showIndex + 2)">
        <div class="video" [id]="video.containerId"></div>
        <span class="title full-width">{{video.title}}</span>
        <span class="info full-width">{{video.subject}} - {{video.learning_objective}}</span>
      </div>
    </ng-container>

  </div>
</div>