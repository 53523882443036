/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question, QuestionsLabels, QuestionType } from '../../../../student/test.model';
import { ISubjectTestSelectTopic } from './../test-question-view.interface';
import { MatRadioButton } from '@angular/material/radio';

@Component({
  selector: 'curr-test-question-item',
  templateUrl: './test-question-item.component.html',
  styleUrls: ['./test-question-item.component.scss']
})
export class TestQuestionItemComponent implements OnInit {
  @Input() question: Question;
  @Input() topics: ISubjectTestSelectTopic[] = [];
  @Input() questionIndexes: number[];
  @Input() showEdit: boolean;
  @Input() isFirstLevel: boolean;
  
  @Output() onEditQuestion = new EventEmitter<Question>();
  @Output() onChangeMarkType = new EventEmitter<Question>();

  filteredTopic: string;
  questionType = QuestionType;
  questionsLabels = QuestionsLabels;
  questionNumber = '';

  constructor() {}

  ngOnInit() {    
    if (this.questionIndexes.length > 0) {
      if (this.questionIndexes.length === 1) this.questionNumber = `${this.questionIndexes[0] + 1}`;
      if (this.questionIndexes.length === 2) this.questionNumber = `${this.questionIndexes[0] + 1}.${this.questionIndexes[1] + 1}`;
      if (this.questionIndexes.length === 3) this.questionNumber = `${this.questionIndexes[0] + 1}.${this.questionIndexes[1] + 1}.${this.questionIndexes[2] + 1}`;
    }

    const filteredTopic = this.topics.find(item => item.id === this.question.curriculum_lobj_id);
    if (filteredTopic) this.filteredTopic = filteredTopic.title.replace(/<[^>]*>/g, '');
  }

  editQuestion() {
    this.onEditQuestion.emit(this.question);
  }

  isOpenAnswer() {
    return (
      this.question?.answer_type === this.questionType.SELECT_ONE ||
      this.question?.answer_type === this.questionType.SELECT_MULTIPLE ||
      this.question?.answer_type === this.questionType.TRUE_OR_FALSE
    );
  }

  isAnswerCorrect(i: number) {
    if (!this.question || !this.question.correct_answer_options) {
      return false;
    }
    if (this.question?.answer_type === this.questionType.SELECT_ONE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      return this.question?.correct_answer_options.filter(a => a === i).length;
    }
  }

  getQuestionTypeLabel() {
    return this.questionsLabels[this.question.answer_type] || '';
  }

  setQuestionsMarkType(questionsMarkType: {source: MatRadioButton, value: 'AI_MARKED' | 'SELF_MARKED'}) {
    this.question.markType = questionsMarkType.value;
    this.onChangeMarkType.emit(this.question);
  }
}
