/**
 * Created by Alex Poh. on 22/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AbstractControl, Validators } from '@angular/forms';

export class CustomValidators {
  static validateEmailOrPhone = (control: AbstractControl) => {
    const phoneRegex = /^[\s()+-]*([0-9][\s()+-]*){6,20}$/;
    if (control.value.match(phoneRegex) || Validators.email(control) === null) {
      return null;
    } else {
      return {
        phoneOrEmail: true
      };
    }
  };

  static validateVideoUrl = (control: AbstractControl): { [key: string]: boolean } | null => {
    if (
      control.value &&
      (!control.value.startsWith('https') ||
        (!control.value.includes('vimeo.com') && !control.value.includes('youtube.com/watch')))
    ) {
      return { validUrl: true };
    }
    return null;
  };
}
