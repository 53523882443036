import { Expose, Transform, Type } from 'class-transformer';

export class Filter {
  selectedFilterItems: Map<string, string> = new Map<string, string>();

  selectFilteredItem(itemName: string, value: string) {
    if (this[itemName] && value) {
      this.selectedFilterItems.set(itemName, value);
    } else {
      this.selectedFilterItems.delete(itemName);
    }
  }

  getFilteredItems(itemName: string, selectedItemName: string, itemLinksName: string): FilterItem[] {
    if (this.selectedFilterItems.get(selectedItemName)) {
      return this[itemName].filter(item => {
        return this[itemLinksName].some(i => {
          return i[selectedItemName] === this.selectedFilterItems.get(selectedItemName) && i[itemName] === item.value;
        });
      });
    } else {
      return this[itemName];
    }
  }
}

export class CourseFilters extends Filter {
  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All subjects'), { toClassOnly: true })
  subjects: FilterItem[];

  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All courses'), { toClassOnly: true })
  courses: FilterItem[];

  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All semesters'), { toClassOnly: true })
  semesters: FilterItem[];

  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All levels'), { toClassOnly: true })
  levels: FilterItem[];

  @Type(() => CourseToSemester)
  courseToSemester: CourseToSemester[];

  @Type(() => LevelToCourse)
  levelToCourse: LevelToCourse[];

  @Type(() => SubjectToLevel)
  subjectToLevel: SubjectToLevel[];

  subjectToCourse: SubjectToCourse[];
}

export class SubjectsToLevel {
  subjects: string;
  levels: string;
}

export class FilterItem {
  @Expose({ name: 'id' })
  value: string;

  @Expose({ name: 'value' })
  label: string;
}

export class SubjectToLevel implements FilterReference {
  subjects: string;
  levels: string;
  @Expose({ name: 'subjects' })
  from: string;
  @Expose({ name: 'levels' })
  to: string;
}

export class LevelToCourse implements FilterReference {
  levels: string;
  courses: string;
  @Expose({ name: 'levels' })
  from: string;
  @Expose({ name: 'courses' })
  to: string;
}

export class CourseToSemester implements FilterReference {
  courses: string;
  semesters: string;
  @Expose({ name: 'courses' })
  from: string;
  @Expose({ name: 'semesters' })
  to: string;
}

export class ChartFilter extends Filter {
  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All subjects'), { toClassOnly: true })
  subjects: FilterItem[];

  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All courses'), { toClassOnly: true })
  courses: FilterItem[];

  @Type(() => FilterItem)
  @Transform((value:any) => addGeneralFilterItem(value.value, 'All levels'), { toClassOnly: true })
  levels: FilterItem[];

  @Type(() => SubjectsToLevel)
  subjectsToLevel: SubjectsToLevel[];

  @Type(() => LevelToCourse)
  levelToCourse: LevelToCourse[];
}

export function addGeneralFilterItem(value: FilterItem[], label: string) {
  if (!value) {
    value = [];
  }
  value.unshift({ value: undefined, label });

  return makeLabelNonNull(value);
}

export function makeLabelNonNull(value: FilterItem[]) {
  value.forEach(filterItem => {
    if (!filterItem.label) {
      filterItem.label = 'Empty';
    }
  });
  return value;
}

export class TreeFilter {
  public readonly root: TreeFilterNode;
  constructor(filterItems: FilterItem[]) {
    this.root = {
      item: null,
      children: filterItems.map(filterItem => {
        return { item: filterItem, children: null, parent: null };
      }),
      parent: null
    };
    this.root.children.forEach(item => {
      item.parent = this.root;
    });
  }
  addLevel(filterItems: FilterItem[], references: FilterReference[]) {
    this.traverse(node => {
      if (!node.children) {
        const newChildren = [];
        references?.forEach(ref => {
          if (ref.from && ref.from === node.item.value) {
            const itemsToAdd = filterItems.filter(item => item.value === ref.to);
            itemsToAdd.forEach(itemToAdd => {
              newChildren.push({ item: itemToAdd, children: null, parent: node });
            });
          }
        });
        node.children = newChildren;
      }
    });
  }

  traverse(callback: (node: TreeFilterNode) => void) {
    const queue = [];
    let currentNode = this.root;
    while (currentNode) {
      for (let i = 0, len = currentNode.children?.length; i < len; i++) {
        queue.push(currentNode.children[i]);
      }
      callback(currentNode);
      currentNode = queue.shift();
    }
  }

  getNodeByValue(level: TreeFilterNode, value: string) {
    return level.children.find(tfn => {
      return tfn.item.value === value;
    });
  }
}

export class TreeFilterNode {
  item: FilterItem;
  children: TreeFilterNode[];
  parent: TreeFilterNode;
}

export interface FilterReference {
  from: string;
  to: string;
}

export interface SubjectToCourse {
  courseId: number;
  subjectName: string;
}
