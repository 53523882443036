<div *ngIf="question?.answer_type === questionType.SELECT_ONE" class="m-b-16">
  <mat-radio-group [(ngModel)]="question.correct_answer_options[0]" class="">
    <!--    <div-->
    <!--    class="editable-option"-->
    <!--      [class.right-answer]="question?.correct_answer_options[0] === i"-->
    <!--      fxLayout="row"-->
    <!--      fxLayoutAlign="start center"-->
    <!--      *ngFor="let option of question?.answer_options; let i = index"-->
    <!--    >-->
    <!--      <mat-radio-button [value]="i" (click)="setCorrectRadioAnswer(i)"  [checked]="question?.correct_answer_options[0] === i" color="accent"></mat-radio-button>   -->
    <!--      <curr-input *ngIf="!isQuestionOptionsTinyEditor && form.controls['option'+i]"-->
    <!--                  class="curr-input" [formGroup]="form" [controlName]="'option'+i"-->
    <!--                  [isBordered]="false" fxFlex [placeholder]="'Option ' + (i+1)"-->
    <!--      ></curr-input>-->
    <!--      -->
    <div class="editable-option" [class.right-answer]="question?.correct_answer_options[0] === i"
         *ngFor="let option of question?.answer_options; let i = index; trackBy: track">
      <div fxLayout="row" fxLayoutAlign="start center">
        <mat-radio-button [checked]="question?.correct_answer_options[0] === i" [value]="i"
                          (click)="setCorrectRadioAnswer(i)" color="accent"></mat-radio-button>
        <curr-input *ngIf="!isQuestionOptionsTinyEditor && form.controls['option'+i]" class="curr-input"
                    [formGroup]="form" [controlName]="'option'+i" [isBordered]="false" fxFlex
                    [placeholder]="'Option ' + (i+1)" (onInputBlur)="updateOption($event, i)">
        </curr-input>

        <curr-tiny-editor *ngIf="isQuestionOptionsTinyEditor  && form.controls['option'+i]"
                          [options]="{height: 250, statusbar: false}" [value]="form.controls['option'+i].value" fxFlex
                          class="m-b-8" (valueChange)="updateOption($event, i)"
                          (onFocusOut)="updateOptionEditor($event, i)"></curr-tiny-editor>

        <curr-icon-button icon="close" class="m-l-16" fxFlex="24px" (click)="deleteOption(i)"></curr-icon-button>
      </div>
      <div *ngIf="question.base_type === 'sa' && question?.correct_answer_options[0] !== i" fxLayout="column"
           fxLayoutAlign="space-between center">
        <curr-dropdown class="full-width" placeholder="Select Lacking" [options]="questionCompetences"
                       (valueChange)="onCompetenceSelect($event, i)"
                       [selectedValue]="question.answer_options_competence ? question.answer_options_competence[i] : null">
        </curr-dropdown>
        <div class="block-header m-b-8 full-width"  fxLayout="row" fxLayoutAlign="space-between center">
          <span class="header mat-h4 m-b-0" fxFlex>Add misconception</span>
          <div fxLayout="row" fxLayoutAlign="end center">
            <mat-slide-toggle
              class="example-margin"
              [color]="'primary'" *ngIf="isEditQuestionWordingMode"
              [checked]="isShowEditor"
              [(ngModel)]="isShowEditor">
              <span class="mat-body-1">Editor</span>
            </mat-slide-toggle>
            <curr-icon-button class="m-l-8" icon="edit" *ngIf="!isEditQuestionWordingMode"
                              (click)="editQuestion('isEditQuestionWordingMode')">
            </curr-icon-button>
          </div>
        </div>
        <div class="full-width">
          <curr-chip-input-autocomplete
            class="full-width"
            *ngIf="!isShowEditor"
            (onInputChange)="onChipInputChange($event)"
            (onChipsChange)="onTagsChange($event, i)"
            [suggestedChips]="suggestedTags"
            [chips]="question.answer_options_tags[i]">
          </curr-chip-input-autocomplete>
        </div>
        <div class="m-t-16 full-width" fxLayout="column" *ngIf="isShowEditor">
          <span class="header mat-h4 m-b-0" fxFlex>Add formula</span>
          <curr-tiny-editor
            [options]="{ height: 250, placeholder: 'Add formula' }"
            (valueChange)="addValue($event)">

          </curr-tiny-editor>
        </div>
      </div>

    </div>
  </mat-radio-group>
</div>

<div *ngIf="question?.answer_type === questionType.SELECT_MULTIPLE">
  <div fxLayout="row" fxLayoutAlign="start center" class="editable-option"
       *ngFor="let option of question?.answer_options; let i = index">
    <mat-checkbox class="m-r-8" color="accent" (click)="checkBoxClick($event, i)" [checked]="checkBoxCorrectAnswers[i]"
                  [(ngModel)]="checkBoxCorrectAnswers[i]">
    </mat-checkbox>
    <curr-input *ngIf="!isQuestionOptionsTinyEditor && form.controls['option'+i]" class="curr-input" [formGroup]="form"
                [controlName]="'option'+i" [isBordered]="false" fxFlex [placeholder]="'Option ' + (i+1)"></curr-input>
    <curr-tiny-editor *ngIf="isQuestionOptionsTinyEditor  && form.controls['option'+i]"
                      [options]="{height: 250, statusbar: false}" [formGroup]="form" [controlName]="'option'+i" fxFlex
                      class="m-b-8">
    </curr-tiny-editor>
    <curr-icon-button icon="close" class="m-l-16" fxFlex="24px" (click)="deleteOption(i)"></curr-icon-button>
  </div>
</div>
