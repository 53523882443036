import { Injectable } from '@angular/core';
import { ChartData, TeacherChartData } from '../shared/components/dashboard-chart/chart-data';
import { ChartPeriod } from '../shared/components/dashboard-chart/dashboard-chart.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ApplicationHttpClient } from '../shared/api/application-http-client';
import { plainToClass } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class StudentChartService {
  constructor(private httpClient: ApplicationHttpClient) {
  }

  getChartDataForStudentProfile(period: ChartPeriod, filterData?: any): Observable<ChartData | TeacherChartData> {
    return this.httpClient
      .post('/studentProcedure/studentStatMonthly', {
        stat_period: period,
        ...filterData
      })
      .pipe(
        catchError(err => {
          return of({ data: null });
        }),
        map((res: any) => {
          return plainToClass(TeacherChartData, res.data) as any;
        })
      );
  }
}
