/**
 * Created by Maxim B. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-pick-year-dialog',
  templateUrl: './pick-year-dialog.component.html',
  styleUrls: ['./pick-year-dialog.component.scss']
})
export class PickYearDialogComponent implements OnInit {
  fields = [
    { label: '1 Year', value: 1, checked: false, disabled: false },
    { label: '2 Year', value: 2, checked: false, disabled: false },
    { label: '3 Year', value: 3, checked: false, disabled: false },
    { label: '4 Year', value: 4, checked: false, disabled: false },
    { label: '5 Year', value: 5, checked: false, disabled: false },
    { label: '6 Year', value: 6, checked: false, disabled: false },
    { label: '7 Year', value: 7, checked: false, disabled: false },
    { label: '8 Year', value: 8, checked: false, disabled: false },
    { label: '9 Year', value: 9, checked: false, disabled: false },
    { label: '10 Year', value: 10, checked: false, disabled: false },
    { label: '11 Year', value: 11, checked: false, disabled: false },
    { label: '12 Year', value: 12, checked: false, disabled: false },
    { label: '13 Year', value: 13, checked: false, disabled: false }
  ];

  selectedYears: number[];
  isDisabledCreateBtn = true;

  constructor(
    public dialogRef: MatDialogRef<PickYearDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedYears = [];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const df = this.data.disabledFields;
    this.fields.forEach(i => {
      if (df.includes(i.value)) {
        i.disabled = true;
      } else {
        this.isDisabledCreateBtn = false;
      }
    });
  }

  createRange() {
    if(this.isDisabledCreateBtn){
      this.dialogRef.close();
      return;
    }
    this.selectedYears = [];
    this.fields.forEach(i => {
      if (i.checked) {
        this.selectedYears.push(i.value);
      }
    });
    this.dialogRef.close(this.selectedYears);
  }

}
