/*
 * Created by Dmytro Sav. on 4/5/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

/*
   Usage example:
   <curr-dropdown-content label="About the paper" [isDisabled]='true'> content </curr-dropdown-content>
   label: string
   [isDisabled] : boolean
   content: any html
*/
@Component({
  selector: 'curr-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class DropdownContentComponent implements OnInit {
  @Input() about: { key: string, value: string };
  @Output() aboutChange = new EventEmitter<{ key: string, value: string }>();
  @Output() onDeleteAbout = new EventEmitter();

  @Input() isDisabled? = false;

  isShowed = false;
  isError = false;
  inEditMode = false;
  editedAbout;

  constructor() {
  }

  ngOnInit() {
    if (!this.about.key && !this.about.value){
     this.editItem();
    }
  }

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }


  editItem() {
    this.inEditMode = true;
    this.editedAbout = Object.assign({}, this.about);
  }

  deleteItem() {
    this.onDeleteAbout.emit();
  }

  cancelEdit() {
    this.inEditMode = false;
    this.editedAbout = '';
  }

  save() {
    this.inEditMode = false;
    this.about = Object.assign({}, this.editedAbout);
    this.aboutChange.emit(this.about);
  }

  update(val: any) {
    this.editedAbout.value = val;
  }

  updateTitle(val: string | number) {
    this.editedAbout.key = val;
  }
}
