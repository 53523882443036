/**
 * Created by Maxim Bon. on 15/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Question, QuestionsLabels, QuestionType } from '../../../student/test.model';
import { RestApiService, restApiServiceCreator } from '../../api/rest-api.service';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { COUResponse } from '../../../shared-pages/subject-details/api-subject-details';
import { switchMap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { CustomValidators } from '../../utils/custom-validators';

interface SubjectTestSelectTopic {
  id: number;
  title: string;
  alreadyUsed: boolean;
  selected?: boolean;
}

@Component({
  selector: 'curr-edit-question-block',
  templateUrl: './edit-question-block.component.html',
  styleUrls: ['./edit-question-block.component.scss']
})
export class EditQuestionBlockComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Input() cou: COUResponse[] = [];
  @Input() topics: SubjectTestSelectTopic[] = [];
  @Input() inSowPage = false;
  @Input() isCTQuestion = false;
  @Output() questionChange = new EventEmitter<Question>();

  questionType = QuestionType;
  questionsLabels = QuestionsLabels;

  isEditQuestionWordingMode = false;
  isEditQuestionMode = false;
  isEditCOUMode = false;

  isQuestionOptionsTinyEditor = false;
  checkBoxCorrectAnswers = [];

  ddCOUQuestions = [];
  ddTopics = [];
  ddLevels = [];
  tmpCou = '';
  tmpWrongCou = '';
  tmpTopic = '';
  tmpLevel = '';
  cTQuestionsService: RestApiService<Question>;
  sAQuestionsService: RestApiService<Question>;
  mediaFormGroup: FormGroup;
  hint: string = '';
  explanation: string = '';

  constructor(private httpClient: ApplicationHttpClient, private fb: FormBuilder) {
    this.ddLevels = [
      { value: undefined, label: 'None' },
      { value: 'FOUNDATION', label: 'Foundation' },
      { value: 'HIGHER', label: 'Higher' }
    ];
  }

  ngOnInit(): void {
    this.hint = this.question.hint;
    this.explanation = this.question.video_url;
    this.cTQuestionsService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(
      this.httpClient
    );
    this.sAQuestionsService = restApiServiceCreator<Question>('curriculumLearningObjectiveSAQuestions')(
      this.httpClient
    );

    if (this.inSowPage) {
      this.isEditQuestionWordingMode = true;
      this.isEditQuestionMode = true;
      this.isEditCOUMode = true;
    }
    this.initDropdownOptions();
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      // @ts-ignore
      this.question?.answer_options = ['True', 'False'];
    }
    this.initMediaFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.cou?.length || this.topics?.length) {
      this.initDropdownOptions();
    }
    if (this.question?.correct_answer_options?.length) {
      this.question.correct_answer_options.forEach((item, index) => {
        if (item !== null && item + '') {
          this.checkBoxCorrectAnswers[index] = true;
        }
      });
    }
    if (this.question?.answer_options?.length) {
      this.question.answer_options.forEach((item, index) => {
        if (item.includes('<p>') || item.includes('<math')) {
          this.isQuestionOptionsTinyEditor = true;
        }
      });
    }
  }

  private initDropdownOptions() {
    this.ddTopics = this.topics.map(i => {
      return { value: i.id, label: i.title };
    });
    this.ddCOUQuestions = this.cou.map(i => {
      return { value: i.id, label: i.question, is_right: i.is_right };
    });
    this.ddCOUQuestions = [{ value: 1, label: 'no cou', is_right: null }, ...this.ddCOUQuestions];
    if (!this.ddTopics.length) {
      this.ddTopics = [{ value: null, label: 'No topics' }];
    }
  }

  private initMediaFormGroup() {
    this.mediaFormGroup = this.fb.group({
      video_url: [this.question?.video_url || '', [CustomValidators.validateVideoUrl]]
    });
  }

  editQuestion(variable: string) {
    this[variable] = true;
  }

  cancelEdit(variable: string) {
    this[variable] = false;
  }

  updateValue(event): void {
    this.question.hint = event.event.target.innerText;
  }

  saveQuestion(variable: string) {
    this[variable] = false;
    if (variable === 'isEditCOUMode') {
      if (this.tmpCou) {
        // @ts-ignore
        this.question?.cou_right_id = this.tmpCou;
        // @ts-ignore
        this.question?.cou_id = this.tmpCou;
      }
      if (this.tmpWrongCou) {
        // @ts-ignore
        this.question?.cou_wrong_id = this.tmpWrongCou;
      }
      if (this.tmpTopic) {
        // @ts-ignore
        this.question?.curriculum_lobj_id = this.tmpTopic;
      }
      if (this.tmpLevel) {
        // @ts-ignore
        this.question?.difficulty_level = this.tmpLevel;
      }

    }
    if (variable === 'isEditQuestionMode') {
      if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
        // @ts-ignore
        this.question?.correct_answer_options = this.checkBoxCorrectAnswers.map((i, index) => {
          if (i) {
            return index;
          }
        });
      }
    }

    const { answer_type: t } = this.question;
    const { SELECT_MULTIPLE: sm, SELECT_ONE: so } = this.questionType;
    if (this.question.correct_answer_options === null && (t === so || t === sm)) {
      this.question.correct_answer_options = [0];
    }

    if (this.isCTQuestion) {
      this.cTQuestionsService
        .update(this.question, this.question.id + '')
        .pipe(
          switchMap(() => {
            return this.cTQuestionsService.getById(this.question.id + '');
          })
        )
        .subscribe(resp => {
          this.question = resp;
        });
    } else {
      this.sAQuestionsService.update(this.question, this.question.id + '').subscribe(resp => {
        this.question = resp;
      });
    }
  }

  selectCou(val: any | []) {
    this.tmpCou = val;
    if (this.inSowPage) {
      if (this.isCTQuestion) {
        this.question.cou_id = val;
      } else {
        this.question.cou_right_id = val;
      }
    }
  }

  selectWrongCou(val: any | []) {
    this.tmpWrongCou = val;
    if (this.inSowPage) {
      this.question.cou_wrong_id = val;
    }
  }

  selectTopic(val: any | []) {
    this.tmpTopic = val;
    if (this.inSowPage) {
      this.question.curriculum_lobj_id = val;
    }
  }

  selectLevel(val: any | []) {
    this.tmpLevel = val;
    if (this.inSowPage) {
      this.question.difficulty_level = val;
    }
  }

  isAnswerCorrect(i: number) {
    if (!this.question || !this.question.correct_answer_options) {
      return false;
    }
    if (this.question?.answer_type === this.questionType.SELECT_ONE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      return this.question?.correct_answer_options.filter(a => a === i).length;
    }
  }

  setMark(m: string) {
    // @ts-ignore
    this.question?.mark = m;
  }

  setAnswerTime(t: string) {
    // @ts-ignore
    this.question?.estimated_time = t;
  }

  setVideoUrlFocusOut(event): void {
    this.question.video_url = event.event.target.innerHTML;
  }

  setVideoUrl(url: string) {
    this.question.video_url = url;
    // // @ts-ignore
    // this.question?.video_url = !!url ? url.replace(/\&.*/, '') : null;
  }

  isOpenAnswer() {
    return (
      this.question?.answer_type === this.questionType.SELECT_ONE ||
      this.question?.answer_type === this.questionType.SELECT_MULTIPLE ||
      this.question?.answer_type === this.questionType.TRUE_OR_FALSE
    );
  }

  updateKeyword(keywords: { key: string; mark: number }[]) {
    this.question.oeq_keywords = keywords;
  }

  getQuestionTypeLabel() {
    return this.questionsLabels[this.question.answer_type] || '';
  }

  getCouLabel(cou_id: string | number) {
    if (!this.cou.length) {
      return 'No cou';
    }
    // TODO: check if this rule needed?
    // tslint:disable-next-line:triple-equals
    return this.cou.find(i => i.id == cou_id)?.question || 'No cou';
  }

  getTopicLabel(curriculum_topic_id: string | number) {
    if (!this.topics.length) {
      return 'No topic';
    }
    // tslint:disable-next-line:triple-equals
    return this.topics.find(i => i.id == curriculum_topic_id)?.title || 'No topic';
  }

  getLevelLabel(lvl: string) {
    if (!this.ddLevels.length) {
      return 'No level';
    }
    // tslint:disable-next-line:triple-equals
    return this.ddLevels.find(i => i.value == lvl)?.label || 'No level';
  }

  // TODO: remove?
  upd($event: any) {
  }

  changeHint(event: string) {
    this.question.hint = event;
  }
}
