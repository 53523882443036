/**
 * Created by Maxim B. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { restApiServiceCreator } from '../../shared/api/rest-api.service';
import { CumulativeTestForTeacher } from './cumulative-test-for-teacher';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { ETestType } from '../teacher.enum';
import * as moment from 'moment/moment';

@Injectable()
export class CumulativeTestsResolver implements Resolve<any> {

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private httpClient: ApplicationHttpClient, private localStorageService: LocalStorageService) {}

  baseButton = {
    color: 'primary',
    variant: 'contained',
    size: 'big'
  };
  schoolYearCourseId: string;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const testTypeParam = route.paramMap.get('testType');
    const curriculumIdParam = route.paramMap.get('curriculumId');
    let label = 'Cumulative Test';
    let testType = ETestType.CUMULATIVE_TEST;
    let link = 'cumulative-test';

    if (testTypeParam === 'homework') {
      label = 'Homework';
      testType = ETestType.HOMEWORK;
      link = 'homework';
    }
    if (testTypeParam === 'quiz') {
      label = 'Quiz';
      testType = ETestType.QUIZ;
      link = 'quiz';
    }

    const cumulativeTestsService = restApiServiceCreator<CumulativeTestForTeacher>('curriculumCumTests/review')(
      this.httpClient
    );
    this.schoolYearCourseId = route.paramMap.get('subjectId');
    return cumulativeTestsService.getAll({ schoolYearCourseId: this.schoolYearCourseId, testType }).pipe(
      map((res: CumulativeTestForTeacher[]) => {
        res.sort((a, b) => moment(b.start_time).valueOf() - moment(a.start_time).valueOf());

        const result = {
          subject: 'Biology',
          year: 'Year 9',
          form: 'Form 3',
          needCheckCard: this.transformTestsToCard(res.filter(test => test.state === 'PASSED' || test.state === 'NEW'), curriculumIdParam, label, testTypeParam, link),
          checkedCard: this.transformTestsToCard(res.filter(test => test.state === 'CHECKED'), curriculumIdParam, label, testTypeParam, link),
          notPassedCard: this.transformTestsToCard(res.filter(test => test.state === 'NOT_PASSED'), curriculumIdParam, label, testTypeParam, link)
        };
        // result.needCheckCard = res.filter(item => item.is_checked)
        return result;
      })
    );
  }

  private transformTestsToCard(tests: CumulativeTestForTeacher[], curriculumIdParam: string, label: string, editLink: string, link: string) {
    return tests.map(res => {
      return this.transformTestToCard(res, curriculumIdParam, label, editLink, link);
    });
  }

  private transformTestToCard(test: CumulativeTestForTeacher, curriculumIdParam: string, label: string, editLink: string, link: string) {

    const notPassedBottom = test.state === 'NOT_PASSED'
      ? [
          { label: `Assign to ${test.assignedTo} students` }
        ]
      : [
          { label: `${test.students_passed}/${test.assignedTo} students passed` },
          { label: 'Average grade: ', value: test.avg_grade, isBold: false },
          {
            label: `${test.reviewQuestions}/${test.questions} questions checked`,
            color: test.state === 'CHECKED' || moment(new Date(test.end_time)).isAfter(moment(), 'minutes') ? '#007100' : '#C43F3D',
            isAttention: (test.state === 'PASSED' || test.state === 'NEW') && moment(new Date(test.end_time)).isBefore(moment(), 'minutes'),
            attention: 'Student responses require your attention'
          }
        ];

    return {
      title: test.title_num,
      buttons: this.getButtonByTestType(curriculumIdParam, test, editLink, link),

      topText: {
        label: `${label} - `,
        value:
          moment(new Date(test.start_time)).format('DD MMM YYYY HH:mm A') +
          ' - ' +
          moment(new Date(test.end_time)).format('DD MMM YYYY HH:mm A')
      },
      bottomText: notPassedBottom
    };
  }

  getButtonByTestType(curriculumIdParam: string, test: CumulativeTestForTeacher, editLink: string, link: string) {
    if (test.state === 'PASSED' || test.state === 'NEW') {
      this.localStorageService.set('testId', test.curriculum_cum_test_id);
      return [
        {
          ...this.baseButton,
          label: 'REVIEW ANSWERS',
          link: `report/${link}/${this.schoolYearCourseId}/${test.curriculum_cum_test_id}/${curriculumIdParam}/review`
        }
      ];
    }
    if (test.state === 'CHECKED') { // CHECKED
      return [
        {
          ...this.baseButton,
          label: 'VIEW REPORT',
          // changed due to demo  link: `ct-questions/${this.schoolYearCourseId}/${test.curriculum_cum_test_id}`
          link: `report/${link}/${this.schoolYearCourseId}/${test.curriculum_cum_test_id}/${curriculumIdParam}/view`
        }
        // {
        //   ...this.baseButton,
        //   label: 'EXPORT RESULTS'
        // }
      ];
    }
    if (test.state === 'NOT_PASSED') {
      return [
        {
          ...this.baseButton,
          label: 'EDIT THE DUE DATE',
          link: `edit/${editLink}/${test.paper_id}/${test.curriculum_cum_test_id}/${this.schoolYearCourseId}`
        },
        {
          ...this.baseButton,
          label: 'EDIT',
          link: `edit/${editLink}/${test.paper_id}/${test.curriculum_cum_test_id}/${this.schoolYearCourseId}`,
          icon: 'edit'
        }
      ];
    }
  }
}
