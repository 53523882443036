/**
 * Created by Maxim B. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Period } from './Period';
import { CreatePeriodDialogComponent } from './create-period-dialog/create-period-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'curr-period-guideline',
  templateUrl: './period-guideline.component.html',
  styleUrls: ['./period-guideline.component.scss']
})

  /*
  * Usage example:
  * <curr-period-guideline [(options)]="schoolPeriods"></curr-period-guideline>
  *  options: { label: string, years: number[], periods: Period[] }[]
  * */
export class PeriodGuidelineComponent implements OnInit, OnChanges {
  @Input() options: { label: string, years: number[], periods: Period[] }[];
  @Output() valueChange = new EventEmitter<any[]>();
  selectedPeriod: { label: string, years: number[], periods: Period[] };
  yearPickerOptions: any;
  disabledYears = [];

  constructor(public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.yearPickerOptions = this.options.map(i => {
      return { label: i.label, years: i.years };
    });
  }

  saveChanges() {
    this.valueChange.emit(this.options);
  }

  setCurrentPeriod(val: any) {
    this.selectedPeriod = this.options.find(i => i.label === val.label);
  }

  deletePeriod(i: number) {
    if (this.selectedPeriod) {
      this.selectedPeriod.periods.splice(i,1);
    }

  }

  editPeriod(period: Period,i: number) {
    const dialogRef = this.dialog.open(CreatePeriodDialogComponent, {
      width: '733px',
      data: { period }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.createNewRange(result);
        // period = result;
      }
    });
  }

  createPeriod() {
    const dialogRef = this.dialog.open(CreatePeriodDialogComponent, {
      width: '733px',
      data: { period: new Period() }
    });

    dialogRef.afterClosed().subscribe(period => {
      if (period) {
        // this.selectedPeriod.periods.push(period);
      }
    });
  }

  addNewOption(opt: { label: string; years: number[] }) {
    this.options.unshift({label: opt.label, years: opt.years, periods: []});
  }

}
