<!--
 Created by Volodymyr Mak. on 4/29/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<!--
 Created by Dmytro Sav. on 07/05/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<pagination-template
  #p="paginationApi"
  [id]="config.id"
  class="curr-pagination"
  [maxSize]="maxSize"
  (pageChange)="pageChanged($event)"
  (pageBoundsCorrection)="($event)"
>
  <div class="pagination-wrapper button" fxLayout="row" fxLayoutAlign="center center">
    <div class="nav-item" fxLayoutAlign="start center">
      <button (click)="!p.isFirstPage() && p.previous()"
         class="curr-button"
         [disabled]="p.isFirstPage()">
        {{ navType === 'buttons' ? 'PREV' : '' }}
        <mat-icon *ngIf="navType === 'arrows'" class="m-r-32">keyboard_arrow_left</mat-icon>
      </button>

    </div>

    <div class="pagination-items" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
      <div
        class="pagination-list"
        fxLayoutAlign="center center"
        *ngFor="let page of p.pages; let index = index"
        [class.current]="p.getCurrent() === page.value"
        [class.checked]="checkedPage.length > 0 && p.getCurrent() !== page.value && checkedPage.includes(index)"
        [class.not-checked]="checkedPage.length > 0 && p.getCurrent() !== page.value && !checkedPage.includes(index)"
      >
        <a class="cursor-pointer" fxLayoutAlign="center center"
          (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
          <span>{{ page.label }}</span>
        </a>
        <div fxLayoutAlign="center center"
             [class.checked]="checkedPage.includes(index)"
             [class.not-checked]="!checkedPage.includes(index)"
             *ngIf="p.getCurrent() === page.value">
          <span>{{ page.label }}</span>
        </div>
      </div>
    </div>

    <div class="nav-item" fxLayoutAlign="end center">
      <button (click)="!p.isLastPage() && p.next()"
        class="curr-button"
        [disabled]="p.isLastPage()">
        {{ navType === 'buttons' ? 'NEXT' : '' }}
        <mat-icon *ngIf="navType === 'arrows'" class="m-l-32">keyboard_arrow_right</mat-icon>
      </button>
    </div>
  </div>
</pagination-template>
