<!--
  ~ Created by Yaroslav S. on 16/04/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="drag-and-drop-section">
  <div class="drag-and-drop-header" #headerContainer>
    <div class="header-title">Insert Screenshots</div>
    <div class="header-count">{{files.length}}/{{maxFiles}}</div>
  </div>

  <div class="screenshots-section"
       contenteditable="true"
       (paste)="onPasteScreenshot($event)"
       (focus)="onFocusScreenshot()"
       (blur)="onBlurScreenshot()">
    <span>Command or Control+V to insert Screenshot</span>
  </div>

  <div class="drag-and-drop-container" currDragDrop (onFileDropped)="onClick($event)">
    <div class="container-title">{{ title }}</div>
    <curr-button variant="contained" class="drag-and-drop-btn" size="big" (btnClick)="onClick()">
      <mat-icon svgIcon="drop-files" class="icon"></mat-icon><span>Browse</span>
    </curr-button>
  </div>

  <div #dropListContainer class="ai-files-process" cdkDropListGroup>
    <div
      *ngFor="let file of files; let i = index"
      #aiFilesProcessItem
      cdkDropList
      [cdkDropListData]="i"
      class="ai-files-process-item"
    >
      <div
        cdkDrag
        [cdkDragData]="i"
        (cdkDragEntered)="dragEntered($event)"
        (cdkDragMoved)="dragMoved($event)"
        (cdkDragDropped)="dragDropped($event)"
        class="ai-files-process-item-image"
        (mouseenter)="onMouseEnter(i)"
        (mouseleave)="onMouseLeave(i)"
      >
        <img src="{{file.base64}}" />
        <div class="hover-section" *ngIf="file.hovered">
          <div class="actions-list">
            <mat-icon [svgIcon]="'drag-pan'" cdkDragHandle></mat-icon>
            <mat-icon [svgIcon]="'ai-file-edit'" (click)="editImage($event, file, i)"></mat-icon>
            <mat-icon [svgIcon]="'ai-file-delete'" (click)="cancelFile(file, i)"></mat-icon>
          </div>
        </div>
        <div class="images-list-order">{{i+1}}</div>
      </div>
    </div>
  </div>

  <div class="drag-and-drop-btn-actions" *ngIf="files.length > 0">
    <curr-button variant="outlined" size="big" (click)="sendToAI()">Process</curr-button>
  </div>
</div>

<input
  type="file"
  #fileUpload
  id="fileUpload"
  name="fileUpload"
  enctype="multipart/form-data"
  multiple="multiple"
  accept="{{ supported }}"
  hidden
/>
