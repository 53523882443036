<!-- Created by Alex Poh. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="row" fxLayoutAlign="center">
  <div fxFlex="{{ diameter }}px">
    <mat-progress-spinner *ngIf="isLoading" color="primary" [diameter]="diameter" mode="indeterminate">
    </mat-progress-spinner>
  </div>
</div>
<ng-content *ngIf="!isLoading"></ng-content>
