<!--Created by Daniil V. on 01/06/21.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<mat-form-field class="chip-input-autocomplete">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let chip of chips" [selectable]="true" (removed)="remove(chip)">
      {{chip.tag}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input placeholder="Add misconception" #input [formControl]="inputCtrl" [matAutocomplete]="auto" [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let chip of chipsToRender" [value]="chip">
      {{chip.tag}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
