<!-- Created by Maxim B. on 01/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-form-field
  [formGroup]="formGroup"
  color="primary"
  fxFlexFill
  [ngClass]="[
    isGreyBackground ? 'add-grey-background' : '',
    inputType,
    'len-' + numberInputWidth,
    formGroup.controls[controlName].value?.toString().length ? 'not-empty-inp' : 'empty-inp'
  ]"
  [appearance]="isBordered ? 'outline' : ''"
>
  <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
  <mat-icon matPrefix *ngIf="prefix" class="input-prefix-icon">{{ prefix }}</mat-icon>
  <input
    #customInput
    [class.centered]="isCentered"
    [type]="inputType"
    [formControlName]="controlName"
    [maxlength]="maxlength"
    matInput
    (keyup)="updateValue()"
    (blur)="onLostFocus()"
  />
  <mat-icon *ngIf="suffix" matSuffix (click)="suffixClicked($event)" class="input-suffix">{{ suffix }}</mat-icon>
  <mat-hint *ngIf="userHint">{{ userHint }}</mat-hint>
  <mat-error *ngIf="formGroup.controls[controlName]?.errors">{{ errorMessage }}</mat-error>
</mat-form-field>
