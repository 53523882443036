/**
 * Created by Alex Poh. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { restApiServiceCreator } from '../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { SubjectDetails } from './api-subject-details';

@Injectable({
  providedIn: 'root'
})
export class SubjectDetailsResolver implements Resolve<SubjectDetails> {
  routeSet;

  constructor(private httpClient: ApplicationHttpClient) {
    this.routeSet = new Set();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SubjectDetails> {
    const restApiService = restApiServiceCreator<SubjectDetails>('curriculums')(this.httpClient);
    const id = route.paramMap.get('subjectId');

    const subject = restApiService.getById(id).pipe(
      map((res: SubjectDetails) => {
        const name = res.title;

        if (!this.routeSet.has(name)) {
          route.parent.data.breadcrumb.push({ label: res.title, url: '' });
        }

        this.routeSet.add(name);

        res.id = route.paramMap.get('subjectId');

        return res;
      })
    );
    return subject;
  }
}
