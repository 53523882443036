<!--
 Created by Daniil Vat. on 18/2/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
 
<div class="wrapper">
  <div class="panel" [ngClass]="{ open: isShowed, error: isError }" (clickOutside)="closeDropDown()">
    <div class="custom-select" [class.disabled]="isDisabled" (click)="toggleDropdown()" [attr.data-titleName]="'Question' + questionNumber">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text">
          <div class="text" [class.disabled]="isDisabled">
            <div header class="full-width panel-title-section">
              <div class="paper-title">Question <span>{{formatNumber(questionNumber)}}</span></div>
              <div class="paper-title-chips">
                <div class="paper-title-chips-item" *ngIf="question.examination_board || question.question_year">
                  <span *ngIf="question.question_year">Exam Board {{ question.question_year }}{{question.examination_board && question.examination_board.name ? ',' : ''}}</span>
                  <span *ngIf="question.examination_board && question.examination_board.name">{{ question.examination_board.name }}</span>
                </div>
                <div class="paper-title-chips-item question-type" *ngFor="let questionType of question.testAndQuestionTypes; let i = index;">
                  {{changedQuestionTypeName(questionType)}}
                </div>
              </div>
              <mat-icon class="more-icon" (click)="$event.stopPropagation()" [matMenuTriggerFor]="beforeMenu">more_vert</mat-icon>
              <mat-menu #beforeMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="editQuestionItem(question)">
                  <mat-icon>edit</mat-icon>
                  Edit
                </button>
                <button mat-menu-item (click)="deleteQuestionItem(question)">
                  <mat-icon>delete</mat-icon>
                  Delete
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="arrow-wrapper m-r-24 m-l-24"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="divider"></div>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
