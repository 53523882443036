<div class="answers-section answer-options-list m-b-8">
  <div *ngIf="question?.answer_type === questionType.SELECT_ONE">
    <div class="question-row-subtitle">
      <span></span>
      <mat-slide-toggle
        [color]="'primary'"
        [checked]="isSelectOneEditor"
        [(ngModel)]="isSelectOneEditor">
        <span class="editor-toggle">Editor</span>
      </mat-slide-toggle>
    </div>

    <div class="answers-section-list" [formGroup]="questionOptionsForm">
      <mat-radio-group formArrayName="questionOptions">
        <div class="answers-section-item"
             [class.right-answer]="question?.correct_answer_options[0] === i"
             *ngFor="let optionsForm of questionOptions.controls; let i = index" [formGroupName]="i">
          <div class="item-action">
            <mat-radio-button
              [checked]="optionsForm.value.isChecked"
              [value]="i"
              (click)="setCorrectRadioAnswer(i)"
              color="accent"></mat-radio-button>
          </div>

          <div class="item-answer">
            <div class="item-answer-read-only" [currMath]="optionsForm.value.description" *ngIf="!isSelectOneEditor"></div>

            <curr-tiny-editor
              *ngIf="isSelectOneEditor && optionsForm"
              [controlName]="'description'"
              [options]="{ height: 116, disabled: false, menubar: 'menubar', statusbar: false }"
              [value]="optionsForm.value.description"
              (valueChange)="updateEditorOption($event, i)"
              (onFocusOut)="updateOptionEditor()"></curr-tiny-editor>
          </div>

          <div class="item-delete" [class.disabled-delete]="!showDelete">
            <curr-icon-button icon="close" [disabled]="!showDelete" (click)="deleteAnswer(i)"></curr-icon-button>
          </div>
        </div>
      </mat-radio-group>
    </div>
  </div>

  <div *ngIf="question?.answer_type === questionType.SELECT_MULTIPLE">
    <div class="question-row-subtitle">
      <span>Mark Scheme</span>
      <mat-slide-toggle
        [color]="'primary'"
        [checked]="isSelectMultiEditor"
        [(ngModel)]="isSelectMultiEditor">
        <span class="editor-toggle">Editor</span>
      </mat-slide-toggle>
    </div>

    <div class="answers-section-list"[formGroup]="questionOptionsForm">
      <ng-container formArrayName="questionOptions">
        <div class="answers-section-item" *ngFor="let optionsForm of questionOptions.controls; let i = index" [formGroupName]="i">
          <div class="item-action">
            <mat-checkbox
              color="accent"
              (change)="checkBoxClick($event.checked, i)"
              [checked]="optionsForm.value.isChecked">
            </mat-checkbox>
          </div>
          <div class="item-answer" [class.with-delete]="showDelete">

            <div class="item-answer-read-only" [currMath]="optionsForm.value.description" *ngIf="!isSelectMultiEditor"></div>

            <curr-tiny-editor
              *ngIf="optionsForm && isSelectMultiEditor"
              [controlName]="'description'"
              [options]="{ height: 116, disabled: false, menubar: 'menubar', statusbar: false }"
              [value]="optionsForm.value.description"
              (valueChange)="updateEditorOption($event, i)"
              (onFocusOut)="updateOptionEditor()"></curr-tiny-editor>
          </div>
          <div class="item-delete" [class.disabled-delete]="!showDelete">
            <curr-icon-button icon="close" [disabled]="!showDelete" (click)="deleteAnswer(i)"></curr-icon-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <curr-button variant="outlined" icon="plus" class="add-answer-btn" size="big" (click)="addAnswer()">ADD MORE</curr-button>
</div>
