import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class HistoricalAssessmentWidgetService {

  constructor(private httpClient: ApplicationHttpClient) { }

  getLastTopicTestsSelfAssessments(schoolYearCourseId: number): Observable<any> {
    return this.httpClient.get(`/schoolYearCourses/getHistoricalAssessment/last?schoolYearCourseId=${schoolYearCourseId}`);
  }
}
