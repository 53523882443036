/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAiKeys } from '../managing-api-keys.interface';

@Component({
  selector: 'curr-mathpix-section',
  templateUrl: './mathpix-section.component.html',
  styleUrls: ['./mathpix-section.component.scss']
})
export class MathpixSectionComponent implements OnInit {

  @Input() apiKeys: IAiKeys;
  @Input() showError = false;
  @Output() onSaveKey = new EventEmitter<{type: string, key: string, appId: string | null}>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
  }

  public get mathPixAppId(): FormControl {
    return this.form.get('mathPixAppId') as FormControl;
  }

  public get mathPixApiKey(): FormControl {
    return this.form.get('mathPixApiKey') as FormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      mathPixAppId: [this.apiKeys.mathPixAppId, [Validators.required]],
      mathPixApiKey: [this.apiKeys.mathPixApiKey, [Validators.required]]
    });
  }

  saveApiKey(): void {
    this.onSaveKey.emit({
      type: 'MATHPIX',
      key: this.mathPixApiKey.value,
      appId: this.mathPixAppId.value
    });
  }
}
