<!-- Created by Maxim B. on 02/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-card [ngClass]="{'no-bottom-padding': currentGrades.length}">


  <mat-card-content>
    <div class="header-container"
         fxLayout="row"
         fxLayoutAlign="space-between center">
      <div class="class-select"
           fxLayout="row"
           fxLayoutAlign="start center">
        <div class="select-container">

          <curr-years-picker [disabledYears]="disabledYears"
                             [(options)]="yearPickerOptions"
                             (onAddNewOption)="addNewOption($event)"
                             (onSelectPeriod)="setCurrentGrade($event)"></curr-years-picker>
        </div>

      </div>
      <div class="share-class-grade-options-container"
           fxLayout="row"
           fxLayoutAlign="end center">
      </div>
    </div>
    <!-- END HEADER -->
    <div class="main-content-container">
      <ng-container *ngIf="currentGrades.length">
        <div class="one-grade-container" *ngFor="let item of currentGrades; let i = index"
             fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
          <input type="text" class="grade-label grade" [(ngModel)]="item.label">
          <span>-</span>
          <input type="number" class="grade" [(ngModel)]="item.max" (blur)="recalculateGradePoints(item, i)">
          <span>:</span>
          <input type="number" class=" grade" [(ngModel)]="item.min" (blur)="recalculateGradePoints(item, i)">
        </div>
      </ng-container>
      <ng-container *ngIf="!currentGrades.length">
        <span class="no-records">No grades for this year</span>
      </ng-container>

      <div class="submit-container">
        <curr-button variant="contained" color="accent"
                     [isDisabled]="!currentGradeObj.label"
                     (btnClick)="addNewGrade()" size="small" icon="building">
          ADD
        </curr-button>
      </div>

    </div>
  </mat-card-content>
</mat-card>
