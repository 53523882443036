import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curr-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit {

  @Input() percent = 0;
  @Input() maxPercent = 100;

  @Input() radius = 48;
  @Input() toFixed = 0;
  @Input() backgroundPadding = 0;
  @Input() outerStrokeLinecap: 'butt' | 'round' | 'square' | 'inherit' = 'square';
  @Input() outerStrokeWidth = 5;
  @Input() titleFontSize = '36';
  @Input() outerStrokeColor = '#5DB605';
  @Input() backgroundColor = '#EFF8E6';
  @Input() titleColor = '#5DB605';
  @Input() animation = true;
  @Input() animationDuration = 500;

  constructor() {
  }

  ngOnInit(): void {
  }

}
