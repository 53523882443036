<div fxLayout="row" fxLayoutAlign="end center">
  <curr-button variant="outlined" (menuClosed)="isAddNewOpen = false"
               [matMenuTriggerFor]="addNewMenu" (click)="isAddNewOpen = true;"
               fxLayout="row" fxLayoutAlign="center center" [isHighlighted]="true">
    {{btnLabel}}
    <mat-icon svgIcon="down" class="btn-icon-arrow " *ngIf="!isAddNewOpen"></mat-icon>
    <mat-icon svgIcon="up" class="btn-icon-arrow " *ngIf="isAddNewOpen"></mat-icon>
  </curr-button>
</div>

<mat-menu #addNewMenu="matMenu" class="custom-menu" xPosition="before">
  <button mat-menu-item *ngIf="isCtQuestion" (click)="selectType(questionType.OPEN_ENDED)">{{questionsLabels[questionType.OPEN_ENDED]}}</button>
  <button mat-menu-item class="btn-bottom-border" (click)="selectType(questionType.MULTIPART)">{{questionsLabels[questionType.MULTIPART]}}</button>
  <button mat-menu-item (click)="selectType(questionType.SELECT_ONE)">{{questionsLabels[questionType.SELECT_ONE]}}</button>
  <button mat-menu-item *ngIf="isCtQuestion" (click)="selectType(questionType.SELECT_MULTIPLE)">{{questionsLabels[questionType.SELECT_MULTIPLE]}}</button>
  <button mat-menu-item *ngIf="isCtQuestion" (click)="selectType(questionType.TRUE_OR_FALSE)">{{questionsLabels[questionType.TRUE_OR_FALSE]}}</button>
  <button mat-menu-item *ngIf="isCtQuestion" (click)="selectType(questionType.FILE_INPUT)">{{questionsLabels[questionType.FILE_INPUT]}}</button>
  <button mat-menu-item *ngIf="!isCtQuestion" (click)="selectType(questionType.SINGLE_ANSWER)">{{questionsLabels[questionType.SINGLE_ANSWER]}}</button>
</mat-menu>
