<!-- Created by Maxim Bon. on 15/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div
  class="question-row m-b-24"
  [ngClass]="[questionEdit.is_master && stepNumber === 2 ? 'master-row' : '']"
  *ngIf="itemIndexWithParents.length === 1"
>
  <curr-dropdown-component-v2
    *ngIf="topics.length > 0"
    class="curr-dropdown"
    [options]="topics"
    [selectedValue]="preSelectedTopic ? preSelectedTopic.value : topics[0].value"
    (selectedValueChange)="selectTopic($event)"
  >
  </curr-dropdown-component-v2>
</div>

<div class="question-row in-past-section m-b-24" *ngIf="itemIndexWithParents.length === 1">
  <curr-checkbox
    [options]="[{ label: 'In Past Exam Question', value: true }]"
    [value]="[questionEdit.is_past_exam_question]"
    (valueChange)="setIsPastExam($event)"
  >
  </curr-checkbox>

  <curr-dropdown-component-v2
    *ngIf="questionEdit.is_past_exam_question"
    placeholder="Select year"
    [selectedValue]="questionEdit.question_year"
    [options]="pastExamOptions"
    (selectedValueChange)="setPastExamYear($event)"
  ></curr-dropdown-component-v2>

  <curr-dropdown-component-v2
    *ngIf="questionEdit.is_past_exam_question"
    placeholder="Examination board"
    [options]="examinationBoardList"
    [selectedValue]="selectedBoard"
    (selectedValueChange)="setExaminationBoard($event)"
  ></curr-dropdown-component-v2>
</div>

<div class="question-row checkbox-list-row m-b-24" *ngIf="itemIndexWithParents.length === 1">
  <mat-checkbox
    [checked]="questionEdit.testAndQuestionTypes.includes('CUMULATIVE_TEST')"
    (change)="checkBoxClick($event.checked, 'CUMULATIVE_TEST')"
    >Cumulative Test</mat-checkbox
  >
  <mat-checkbox
    [checked]="questionEdit.testAndQuestionTypes.includes('HOMEWORK')"
    (change)="checkBoxClick($event.checked, 'HOMEWORK')"
    >Homework</mat-checkbox
  >
  <mat-checkbox
    [checked]="questionEdit.testAndQuestionTypes.includes('QUIZ')"
    (change)="checkBoxClick($event.checked, 'QUIZ')"
    >Quiz</mat-checkbox
  >
</div>

<div class="question-row answers-format m-b-24" *ngIf="!questionEdit.is_master">
  <div class="answers-format-title">Answer Format</div>
  <mat-radio-group (change)="changeAnswerFormat($event.value)">
    <mat-radio-button
      *ngFor="let answer of answerFormatOptions; let i = index"
      [checked]="questionEdit.answer_type === answer.value"
      [value]="answer.value"
    >
      {{ answer.label }}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="question-row m-b-16">
  <div class="question-row-subtitle">
    <span></span>
    <mat-slide-toggle [color]="'primary'" [checked]="isQuestionTinyEditor" [(ngModel)]="isQuestionTinyEditor">
      <span class="editor-toggle">Editor</span>
    </mat-slide-toggle>
  </div>

  <curr-tiny-editor
    *ngIf="isQuestionTinyEditor && !isLoadEditor"
    [formGroup]="questionForm"
    controlName="questionData"
    [options]="{ height: 270, disabled: false, menubar: 'menubar', statusbar: false }"
    [value]="questionEdit.question"
    (valueChange)="updateQuestionValue($event)"
    (onFocusOut)="updateOptionOutFocus()"
  ></curr-tiny-editor>

  <div [currMath]="questionEdit.question" *ngIf="!isQuestionTinyEditor" class="question-view"></div>
</div>
