/**
 * Created by Iryna Iak. on 18/10/21.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, switchMap, takeUntil, map } from 'rxjs/operators';

import { UserService } from '../../user.service';
import { ChooseYearService } from '../stepper/choose-year.service';
import { CalculatedPriceService } from '../stepper/calculated-price.service';
import { SubjectInformationService } from '../stepper/subject-information.service';


export interface ISubject {
  subjectId: number;
  paymentPlanType: string;
}

@Component({
  selector: 'curr-choose-subject',
  templateUrl: './choose-subject.component.html',
  styleUrls: ['./choose-subject.component.scss']
})
export class ChooseSubjectComponent implements OnInit, OnDestroy {
  @Input() cbOptions: { value; label; disabled? }[];
  @Input() cbColor = 'primary';
  @Input() isDisabled? = false;
  @Output() animationDone: EventEmitter<void> = new EventEmitter();
  @ViewChild('') stepper: MatStepper;

  calculatedSubjects: BehaviorSubject<any>;
  isShowed: boolean = false;
  selectedSubjects: ISubject[] = [];
  schoolSubjects$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  isLoading: boolean;
  selectedPlan: string = '';
  destroyed$: Subject<void> = new Subject();
  emptyPlan: [];
  planOptions = [
    { value: 'Choose', label: 'Choose option' },
    // { value: 'PLUS', label: 'Exam Boost PLUS' },
    { value: 'EXAM_PREPARATION', label: 'Exam Preparation', disabled: false },
    { value: 'STANDARD', label: 'Exam Boost', disabled: true }
  ];
  years = [];
  checked: boolean = false;
  isHighlighted: boolean = true;
  highlight;
  currentUser;
  isUniversityBoostSelected: boolean;

  constructor(
    private userService: UserService,
    public router: Router,
    public chooseYearService: ChooseYearService,
    public calculatedPriceService: CalculatedPriceService,
    private subjectInfoService: SubjectInformationService
  ) {
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser')).role
    this.userService.getSchoolYears()
      .pipe(first())
      .subscribe(years => {
        this.years = years;
        this.getSubjects(this.years[0].id);
        this.chooseYearService.getChosenYear.subscribe(res => {
          this.checked = this.years[0].id === res;
          const id = this.years.find(el => el.id === res)?.id;
          if (id) {
            this.getSubjects(id);
          }
        })
      });

    // this.chooseYearService
    //   .getChosenYear
    //   .pipe(
    //     takeUntil(this.destroyed$),
    //     switchMap((res: boolean) => {
    //       return this.userService.getSchoolYears()
    //         .pipe(
    //           map(years => {
    //             const yearsSort = years.sort((a, b) => a.id - b.id);
    //             return { schoolStudyYearId: yearsSort[+res].id as number };
    //           }),
    //           switchMap(response => {
    //             return this.userService.setStudyYear(response);
    //           }));
    //     }))
    //   .subscribe(() => {
    //   });
  // this.checkHighlight();
  }

  switchYear(): void {
    this.checked = !this.checked;
    const checkedValue = !this.checked ? this.years[1].studyYearName : this.years[0].studyYearName;
    const checkedId = !this.checked ? this.years[1].id : this.years[0].id;
    this.userService.setStudyYear({ schoolStudyYearId: checkedId as number })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.chooseYearService.changeYear(checkedValue);
      });
    // this.getSubjects(this.checked ? this.years[1].id : this.years[0].id)
  }

  getSubjects(id): void {
    this.isLoading = true;
    this.schoolSubjects$.next([])
    this.userService.getSchoolSubjects(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res: any) => {
        res.forEach(el => {
          el.img = el.name.replaceAll(' ', '-');
          if (el.img === 'Mathematics') {
            el.img = 'Math';
          }
        })
        this.emptyPlan = res;
        this.schoolSubjects$.next(res);
        if (this.emptyPlan.length === 0) {
          this.calculatedPrice()
            .subscribe(result => this.calculatedPriceService.changePrice(result));
        }
        this.isLoading = false;
      });
  }

  // TODO in case we will use checkboxes
  chooseSubject(id: number): void {
    const item = {
      subjectId: id,
      paymentPlanType: 'EXAM_PREPARATION'
    };
    const includesItemIdx = this.selectedSubjects.findIndex((elem: any) => elem.subjectId === id);
    if (includesItemIdx === -1) {
      this.selectedSubjects.push(item);
    } else {
      this.selectedSubjects.splice(includesItemIdx, 1);
    }
  }

  chooseUniversityBoost(): void {
    this.isUniversityBoostSelected = !this.isUniversityBoostSelected;
  }

  isSubjectChosen(id: number): boolean {
    return !!this.selectedSubjects.find((elem: any) => elem.subjectId === id);
  }

  confirmSubjects(): void {
    this.isLoading = true;
    this.userService.setSubjects(this.selectedSubjects, this.isUniversityBoostSelected)
      .pipe(
        takeUntil(this.destroyed$),
        first(),
        switchMap(() => this.calculatedPrice()))
      .subscribe((res) => {
        this.calculatedPriceService.changePrice(res);
        this.goToNextStep();
        this.isLoading = false;
      });
  }
  // checkHighlight(): void{
  //   this.chooseYearService.setHighlight$.subscribe(res => {
  //     if(res === false){
  //       setTimeout(()=> {
  //         this.isHighlighted = false
  //       },5000)
  //     }
  //   })
  // }

  toggleDropdown(): void {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  calculatedPrice(): Observable<object[]> {
    return this.userService.getCalculatedPrice();
  }

  // TODO in case we will use dropdown
  // choosePlan(value: string, id: number): void {
  //   const item = {
  //     subjectId: id,
  //     paymentPlanType: value
  //   };
  //   const includesItemIdx = this.selectedSubjects.findIndex((elem: any) => elem.subjectId === id);
  //   const isChoose = item.paymentPlanType === 'Choose';

  //   if (~includesItemIdx) {
  //     isChoose
  //       ? this.selectedSubjects.splice(includesItemIdx, 1)
  //       : this.selectedSubjects[includesItemIdx].paymentPlanType = value;
  //   } else if(!isChoose){
  //     this.selectedSubjects.push(item);
  //   }
  //   this.selectedSubjects.filter(t => t.paymentPlanType.includes('PLUS'));
  // }

  goWithoutSubjects(): void {
    this.calculatedPrice()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(result => this.calculatedPriceService.changePrice(result));
    this.goToNextStep();
  }

  goToNextStep(): void {
    this.animationDone.emit();
  }

  goToSubjectInfo(id: number): void {
    this.subjectInfoService.setSubject(id);
    if(this.currentUser === 'STUDENT'){
      window.open(`what-will-i-learn/${ id }`, '_blank').focus();
    }else{
      window.open(`sign-up/checkout/what-will-i-learn/${ id }`, '_blank').focus();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
