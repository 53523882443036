<!--
 Created by Dmytro Sav. on 4/28/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-student-answer">
  <div class="item-main-title">Data From Student</div>
  <ng-container *ngIf="question.answerType === qt.OPEN_ENDED">
    <div class="item-title">Answer</div>
    <div class="item-content {{ studentAnswer.studentAnswerText ? (studentAnswer.isCorrect ? 'correct-answer' : 'wrong-answer') : ''}}" *ngIf="!isResultImage" [currMath]="studentAnswer.studentAnswerText || 'No data'"></div>
    <div class="item-content {{ isResultImage ? (studentAnswer.isCorrect ? 'correct-answer' : 'wrong-answer') : ''}}" *ngIf="isResultImage" [currMath]="imageResultAnswer"></div>
  </ng-container>

  <ng-container *ngIf="question.answerType === qt.SINGLE_ANSWER">
    <div class="item-title">Answer</div>
    <div class="item-content" *ngIf="!isImage" [currMath]="studentAnswer.studentAnswerText"></div>
    <div class="item-content" *ngIf="isImage" [currMath]="image"></div>
  </ng-container>

  <ng-container *ngIf="question.answerType === qt.FILE_INPUT">
    <div class="item-title">Answer</div>
    <div class="item-content" [currMath]="studentAnswer.studentAnswerText"></div>
    <div class="item-content" *ngIf="!studentAnswer.studentAnswerText && !studentAnswer.studentAnswerFile">No data</div>
    <ng-container *ngIf="studentAnswer.studentAnswerFile && isFileTypeOfImage();">
      <curr-loader [isLoading]="isFileLoading" [diameter]="48"></curr-loader>

      <div class="wrapper-image full-width m-b-8" *ngIf="imagePreviewFile"
           (click)="openImagePreviewDialog(imagePreviewFile)">
        <div class="hover-container"></div>
        <mat-icon class="zoom-icon" svgIcon="zoom-icon"></mat-icon>
        <img [src]="imagePreviewFile" alt="Image answer" />
      </div>
    </ng-container>
    <div class="m-b-8 flex" *ngIf="studentAnswer.studentAnswerFile && imagePreviewFile && isFileTypeOfImage()">
      <a class="download-file" [href]="imagePreviewFile" download="file" target="_blank">
        <curr-button variant="outlined" [icon]="'download'" [style]="fileInputBtnStyle">
          Download file
        </curr-button>
      </a>
    </div>

    <div class="m-b-8 flex" *ngIf="studentAnswer.studentAnswerFile && !isFileTypeOfImage()">
      <a class="download-file" [href]="previewFile" [download]="'file.'+fileExtension" target="_blank">
        <curr-button variant="outlined" [icon]="'download'" [style]="fileInputBtnStyle">
          Download file
        </curr-button>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="question.answerType === qt.SELECT_ONE
                        || question.answerType === qt.SELECT_MULTIPLE
                        || question.answerType === qt.TRUE_OR_FALSE">
    <div class="item-title">Answer</div>
    <ng-container *ngIf="studentAnswer.studentCorrectAnswerOptions?.length; else emptyAnswerList">
      <div *ngFor="let a of studentAnswer.studentCorrectAnswerOptions; let i = index; let last = last"
           class="ls mat-body-1 reset">
        <div fxLayout="row" class="m-b-8 answer-option" [class.reset]="last"
             [class.correct]="isStudentAnswerCorrect(a)">
          <mat-icon svgIcon="circle" class="circle m-r-8"></mat-icon>
          <span *ngIf="!isImage" [currMath]="answerData.answers[a]"></span>
          <span *ngIf="isImage" [currMath]="image"></span>
        </div>
      </div>
    </ng-container>
    <ng-template #emptyAnswerList>No answer</ng-template>
  </ng-container>

  <div class="cou m-t-8" *ngIf="studentAnswer.studentCou">
    <div class="m-b-8 mat-h4">Confirmation of understanding</div>
    <div class="answer" [currMath]="studentAnswer.studentCou"></div>
  </div>
</div>
