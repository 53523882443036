/**
 * Created by Yaroslav S. on 17/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

export enum ETestType {
  CUMULATIVE_TEST = 'CUMULATIVE_TEST',
  TUTORIAL_SESSION = 'TUTORIAL_SESSION',
  HOMEWORK = 'HOMEWORK',
  QUIZ = 'QUIZ'
}
