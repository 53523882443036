/**
 * Created by Maxim B. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curr-teacher-cumulative-test-card',
  templateUrl: './teacher-cumulative-test-card.component.html',
  styleUrls: ['./teacher-cumulative-test-card.component.scss']
})
export class TeacherCumulativeTestCardComponent implements OnInit {

  @Input() testCard: any;


  constructor() { }

  ngOnInit(): void {
  }

}
