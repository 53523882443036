/**
 * Created by Alex Poh. on 30/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Observable } from 'rxjs';
import { Breadcrumb } from './breadcrumb';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  public breadcrumbLabels: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public newBreadcrumb: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);

  constructor(private route: ActivatedRoute) {}

  updateBreadcrumbLabels(labels: any) {
    this.breadcrumbLabels.next(labels);
  }

  updateBreadcrumb(newBreadcrumb: Breadcrumb[]) {
    this.newBreadcrumb.next(newBreadcrumb);
  }

  getRoutParameter(paramName: string, all?: boolean): Observable<string | string[]> {
    let param: string;
    this.route.params.subscribe({
      next: params => {
        param = all ? params : params[paramName];
      }
    });
    return of(param);
  }
}
