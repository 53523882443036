<!-- Created by Alex Poh. on 17/08/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <div class="content">
    <div class="info mat-display-1 m-b-32">Please import your lesson timetable to view your gant chart</div>
    <div class="button-container">
      <curr-button class="button" (btnClick)="importTimeTable()"> IMPORT TIMETABLE</curr-button>
    </div>
  </div>
</div>
