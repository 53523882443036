/**
 * Created by Alex Poh. on 12/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModuleLoaderService } from './shared/module-loader/module-loader.service';
import { IconRegistrarService } from './shared/icons/icon-registrar.service';
import { UserService } from './shared/user.service';
import { ThemeService } from './shared/theme/theme.service';
import { fromEvent } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { LanguageService } from './shared/i18n/language.service';
import { CommonService } from './shared/services/common.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'curr-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  constructor(private moduleLoader: ModuleLoaderService,
              private iconRegistrar: IconRegistrarService,
              private userService: UserService,
              private themeService: ThemeService,
              private languageService: LanguageService,
              private commonService: CommonService) {
  }

  ngOnInit(): void {
    this.languageService.setLanguageForTranslation('en');
    this.userService.attemptAuthFromLocalStorage().subscribe();
    this.moduleLoader.initModuleLoadingForCurrentRole();
    this.iconRegistrar.registerIcons();
    this.themeService.addColorVariablesToPage();
    this.addScreenScaleVariable(window.innerWidth);
    fromEvent(window, 'resize')
      .pipe(auditTime(1000))
      .subscribe(() => {
        this.addScreenScaleVariable(window.innerWidth);
      });

    // get common data
    this.subscription = this.commonService.infoText$.subscribe();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private addScreenScaleVariable(innerWidth) {
    const style = document.createElement('style');
    const initialWidth = 1440;
    style.innerHTML = `:root{--scale-container:scale(${ innerWidth / initialWidth })}`;
    document.head.appendChild(style);
  }
}
