/**
 * Created by Alex Poh. on 26/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'curr-email-or-phone-input',
  templateUrl: './email-or-phone-input.component.html',
  styleUrls: ['./email-or-phone-input.component.scss']
})
export class EmailOrPhoneInputComponent implements OnInit {
  @Output()
  formSubmit: EventEmitter<FormGroup> = new EventEmitter();

  @Input()
  isLoading = false;

  form: FormGroup = this.fb.group({
    emailOrPhone: ['', [Validators.required, Validators.email]] // CustomValidators.validateEmailOrPhone
  });

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
  }

  submit() {
    this.formSubmit.next(this.form);
  }
}
