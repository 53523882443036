/**
 * Created by Alex Poh. on 29/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'curr-p5-demo',
  templateUrl: './p5-demo.component.html',
  styleUrls: ['./p5-demo.component.scss']
})
export class P5DemoComponent implements OnInit {
  constructor() {}

  clear$: Subject<boolean> = new Subject();

  ngOnInit(): void {}

  clear() {
    this.clear$.next(true);
  }
}
