<!--
 Created by Dmytro Sav. on 4/23/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container">
  <div class="p-l-16 p-r-16" fxLayout="row" fxLayoutAlign="space-between baseline">
    <div class="mat-h2 m-0 p-0 title">{{ tasks[0]?.title }}</div>
    <div class="mat-h4 m-0 p-0 primary">{{ percentage }}% completed</div>
  </div>
  <mat-divider class="m-t-16 m-b-16"></mat-divider>
  <div class="card">
    <ng-container *ngFor="let task of tasks; let index = index; let last = last; let first = first">
      <div class="task ">
        <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <mat-checkbox [color]="task.value.color" (change)="updateValue(task.id)" [checked]="task.value.checked"
            [(ngModel)]="task.value.checked">
          </mat-checkbox>
          <div class="m-l-16 task-label">
            {{ task.value.label | translate}}
          </div>
          <div class="right-aligned" fxLayout="row" fxLayoutGap="4px">
            <ng-container *ngFor="let button of task.buttons">
              <mat-icon class="btn-mobile" [svgIcon]="'open_in'"></mat-icon>

              <curr-button class="btn" (btnClick)="button.callback(button, dialog)" [color]="button.color"
                [variant]="button.variant" [size]="button.size" [icon]="button.icon">{{ button.label }}</curr-button>
            </ng-container>
          </div>
        </div>

        <div class="divider" [class.last]="last"></div>
      </div>
    </ng-container>
  </div>
</div>