<!--
  ~ Created by Yaroslav S. on 04/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="ai-zones-marker-popup">
  <div class="ai-zones-marker-popup-title" *ngIf="title">{{title}}</div>
  <div class="ai-zones-marker-popup-content">
    <curr-ai-zones-marker [imageLink]="imageLink" [oldZones]="oldZones" (zonesList)="onZonesList($event)"></curr-ai-zones-marker>
  </div>
  <div class="ai-zones-marker-popup-actions">
    <curr-button variant="text" size="big" (click)="close()">Cancel</curr-button>
    <curr-button variant="contained" [isDisabled]="zones.length <= 0" size="big" (click)="submit()">Save</curr-button>
  </div>
</div>
