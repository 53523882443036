/**
 * Created by Maxim Bon. on 13/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { IMasterQuestion } from '../paper-details.interfaces';
import { PaperDetailsService } from '../paper-details.service';

@Component({
  selector: 'curr-create-edit-topic',
  templateUrl: './create-edit-topic.component.html',
  styleUrls: ['./create-edit-topic.component.scss'],
})
export class CreateEditTopicComponent implements OnInit {
  @Input() inputData!: any;

  masterQuestions: IMasterQuestion[];
  private readonly _onOutputData = new Subject<{ data: any; shouldClose: boolean; confirmDialog: boolean }>();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public onOutputData = this._onOutputData.asObservable();

  constructor(
    private fb: FormBuilder,
    private paperDetailsService: PaperDetailsService,
  ) {
    this.initPastExamOptions();
  }

  form: FormGroup;
  aboutText: string;
  selectedIds: number[] = [];
  showPastPaper = false;
  pastExamOptions = [];
  examinationBoard = [];
  selectedQuestionYear: number;
  selectedExaminationBoard: string;

  ngOnInit(): void {
    this.masterQuestions = this.inputData.data.masterQuestions;
    this.examinationBoard = this.inputData.data.examinationBoard;
    
    this.form = this.fb.group({
      topicName: [
        this.inputData.data && this.inputData.data.topicTitle ? this.inputData.data.topicTitle.replace(/<\/?[^>]+(>|$)/g, '') : '',
        [Validators.required, Validators.maxLength(100)],
      ],
    });

    if (this.masterQuestions && this.masterQuestions.length > 0) {
      this.clearSelectedItems();
    }

    // ToDo:: logic for next development steps
    // if (this.inputData.data.dialogMode === 'edit') this.initSelectedTopicQuestions();
  }

  updateTopicValue(event: string): void {}

  updateOptionOutFocus() {}

  // ToDo:: logic for next development steps
  // initSelectedTopicQuestions() {
  //   this.paperDetailsService
  //     .getTopicQuestions(this.inputData.data.topicExistedId)
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((res: any) => {
  //       if (res.data.items && res.data.items.length > 0) {
  //         const ids = res.data.items.map(item => {
  //           return item.id;
  //         });

  //         this.masterQuestions.forEach((masterQuestion, parentIndex) => {
  //           masterQuestion.questions.forEach((question, index) => {
  //             console.log(this.masterQuestions[parentIndex].questions[index])
  //             if (ids.includes(this.masterQuestions[parentIndex].questions[index].id)) {
  //               console.log(this.masterQuestions[parentIndex])
  //               this.selectedIds.push(this.masterQuestions[parentIndex].questions[index].id);
  //               this.masterQuestions[parentIndex].questions[index].isChecked = true;
  //             }
  //           })
  //         });
  //       }
  //     });
  // }

  checkSelectedAll(masterQuestions) {
    const checkedTrue = masterQuestions.filter((item) => item.isChecked);
    return checkedTrue.length === masterQuestions.length;
  }

  changeQuestion(isChecked: boolean[], parentIndex: number, index: number) {
    if (isChecked.length > 0) {
      this.selectedIds.push(this.masterQuestions[parentIndex].questions[index].id);
      this.masterQuestions[parentIndex].questions[index].isChecked = true;
    } else {
      const checkIndex = this.selectedIds.indexOf(this.masterQuestions[parentIndex].questions[index].id);
      if (checkIndex > -1) {
        this.selectedIds.splice(checkIndex, 1);
        this.masterQuestions[parentIndex].questions[index].isChecked = false;
      }
    }
  }

  changeQuestionsAll(isChecked: boolean[], parentIndex: number) {
    if (isChecked.length > 0) {
      this.masterQuestions[parentIndex].questions.forEach((item, index) => {
        this.selectedIds.push(item.id);
        this.masterQuestions[parentIndex].questions[index].isChecked = true;
      });
    } else {
      this.masterQuestions[parentIndex].questions.forEach((item, index) => {
        const checkIndex = this.selectedIds.indexOf(item.id);
        if (checkIndex > -1) {
          this.selectedIds.splice(checkIndex, 1);
          this.masterQuestions[parentIndex].questions[index].isChecked = false;
        }
      });
    }
  }

  changeShowPastPaper(isChecked: boolean[]) {
    this.showPastPaper = isChecked.length > 0;
    if (isChecked.length === 0) {
      this.masterQuestions = this.inputData.data.masterQuestions;
      this.selectedQuestionYear = null;
      this.selectedExaminationBoard = null;
    }
  }

  setPastExamYear(val: number) {
    this.selectedQuestionYear = val;
    this.paperDetailsService
      .getMasterQuestionsList({
        subject: this.inputData.data.subject,
        studyYear: this.inputData.data.subjectYear,
        questionYear: this.selectedQuestionYear,
        examinationBoard: this.selectedExaminationBoard,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IMasterQuestion[]) => {
        this.masterQuestions = res;
      });
  }

  setExaminationBoard(val: string) {
    this.selectedExaminationBoard = JSON.parse(val).name;
    this.paperDetailsService
      .getMasterQuestionsList({
        subject: this.inputData.data.subject,
        studyYear: this.inputData.data.subjectYear,
        questionYear: this.selectedQuestionYear,
        examinationBoard: this.selectedExaminationBoard,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: IMasterQuestion[]) => {
        this.masterQuestions = res;
      });
  }

  save(): void {
    const dataToCreate = {
      curriculumLearningObjectiveId: null,
      curriculumLearningObjectiveName: this.form.get('topicName').value,
      questionIds: this.selectedIds,
      curriculumLearningObjective: {
        ref_num: 1,
        lessons: 'Big12',
        is_published: false,
        duration: 20,
        curriculum_topic_id: this.inputData.data.topicId,
        curriculum_paper_id: this.inputData.data.paperId,
      },
    };

    const dataToUpdate = {
      curriculumLearningObjectiveId: this.inputData.data.topicExistedId,
      curriculumLearningObjectiveName: this.form.get('topicName').value,
      questionIds: this.selectedIds,
    };

    this.paperDetailsService
      .createUpdateTopic(this.inputData.data.dialogMode === 'edit' ? dataToUpdate : dataToCreate)
      .pipe(takeUntil(this.destroy$))
      .subscribe((id: number) => {
        this._onOutputData.next({
          data: {
            topicId: id,
            topicTitle: this.form.get('topicName').value,
            createdData: dataToCreate
          },
          shouldClose: true,
          confirmDialog: false,
        });
      });
  }

  cancel(): void {
    this._onOutputData.next({ data: {}, shouldClose: true, confirmDialog: true });
  }

  private initPastExamOptions() {
    let year = 2002;
    const currentYear = new Date().getFullYear();
    while (year <= currentYear) {
      this.pastExamOptions.push({ label: year, value: year });
      year++;
    }
  }

  private clearSelectedItems() {
    this.selectedIds = [];
    this.masterQuestions.forEach((masterQuestion, parentIndex) => {
      masterQuestion.questions.forEach((question, index) => {
        this.masterQuestions[parentIndex].questions[index].isChecked = false;
      })
    });
  }
}
