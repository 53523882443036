<div class="answers-section">
  <div class="question-row-subtitle">
    <span></span>
    <mat-slide-toggle
      [color]="'primary'"
      [checked]="isMarkSchemeTinyEditor"
      [(ngModel)]="isMarkSchemeTinyEditor">
      <span class="editor-toggle">Editor</span>
    </mat-slide-toggle>
  </div>

  <div class="answers-section-list" [formGroup]="oeqKeywordsForm">
    <ng-container formArrayName="oeqKeywords">
      <div class="answers-section-item" *ngFor="let oeqKeywordsForm of oeqKeywords.controls; let i = index" [formGroupName]="i">
        <div class="item-answer" [class.with-delete]="showDelete">
          <div [currMath]="oeqKeywordsForm.value.key" *ngIf="!isMarkSchemeTinyEditor"></div>

          <curr-tiny-editor
            class="editor-field"
            *ngIf="oeqKeywordsForm && isMarkSchemeTinyEditor"
            [controlName]="'key'"
            [value]="oeqKeywordsForm.value.key"
            [options]="{ height: 116, disabled: false, menubar: 'menubar', statusbar: false }"
            (valueChange)="updateEditorOption($event, i)"
            (onFocusOut)="updateOptionOutFocus()">
          </curr-tiny-editor>
        </div>
        <div class="item-mark">
          <curr-input
            *ngIf="oeqKeywordsForm"
            class="custom-val"
            [controlName]="'mark'"
            [value]="oeqKeywordsForm.value.mark"
            (valueChange)="updateRowValue($event, i, 'mark')"
            inputType="number"
            [isBordered]="false"
            [isCentered]="true"
          ></curr-input>
        </div>
        <div class="item-delete" [class.disabled-delete]="!showDelete">
          <curr-icon-button icon="close" [disabled]="!showDelete" (click)="deleteElement(i)"></curr-icon-button>
        </div>
      </div>
    </ng-container>
  </div>

  <curr-button variant="outlined" icon="plus" class="add-answer-btn" size="big" (click)="addKeyword()">ADD MORE</curr-button>
</div>
