<!--Created by Maxim B. on 16/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <div class="m-b-27">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="header-container main-header">
    <h2 class="mat-h2">{{ subject?.subject }} - Self Assessment Tests</h2>
    <div class="tabs-container">
      <curr-teacher-tabs [tabLabels]="tabLabels" [(value)]="currSelectedTabIndex"></curr-teacher-tabs>
    </div>
  </div>



  <div class="tests-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
    <ng-container *ngIf="!tests?.passed?.length && !tests?.notPassed?.length && currSelectedTabIndex == 0">
      <ng-container *ngTemplateOutlet="noRecords"></ng-container>
    </ng-container>
    <div class="block" *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 1">
      <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <ng-container *ngIf="!tests?.passed?.length && currSelectedTabIndex == 1">
          <ng-container *ngTemplateOutlet="noRecords"></ng-container>
        </ng-container>
        <curr-teacher-self-assessment-test-card
          *ngFor="let card of tests?.passed"
          [testCard]="card"
        ></curr-teacher-self-assessment-test-card>
      </div>
    </div>

    <div class="block" *ngIf="currSelectedTabIndex == 0 || currSelectedTabIndex == 2">
      <div class="items-container" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px">
        <ng-container *ngIf="!tests?.notPassed?.length  && currSelectedTabIndex == 2">
          <ng-container *ngTemplateOutlet="noRecords"></ng-container>
        </ng-container>
        <curr-teacher-self-assessment-test-card
          *ngFor="let card of tests?.notPassed"
          [testCard]="card"
        ></curr-teacher-self-assessment-test-card>
      </div>
    </div>
  </div>
</div>

<ng-template #noRecords>
  <div fxLayout="column" fxLayoutAlign="center center" class="full-width">
    <span class="mat-body-1">No records</span>
  </div>
</ng-template>
