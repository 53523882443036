<div class="container">
  <div class="list main" [class]="tests[0]?.type" [class.isToday]="tests[0]?.isToday">
    <div class="day overline">
      <mat-icon
        *ngIf="tests[0].icon == 'icon-complete'; else dot"
        color="accent"
        [inline]="true"
        svgIcon="icon-done"
        class="icon"
      ></mat-icon>
      <ng-template #dot>
        <div class="dot" [class.opacity]="tests[0].isIconOpacity"></div>
      </ng-template>
      <span class="text">{{ tests[0]?.hint }}</span>
    </div>

    <ng-container *ngFor="let test of tests; let first = first">
      <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center" (click)="goToLearningResources(test)">
        <div class="text-wrapper" fxLayout="column" fxLayoutAlign="center" fxFlex="60%" [class.m-t-16]="first">
          <div class="mat-h1 m-b-0 name desktop">{{ test?.name }}</div>
          <div class="mat-h1 m-b-0 name mobile">{{ test?.name }}</div>

          <div class="status mat-body-2 m-t-8" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
               fxLayoutAlign.xs="start start">
            <ng-container *ngFor="let text of test.bottomTitle; let last = last">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon *ngIf="text?.icon" [inline]="true" [svgIcon]="text?.icon"
                          class="status-icon m-r-4"></mat-icon>

                <span
                  class="m-r-4"
                  *ngIf="text.label.length > 1"
                  [class.text-underline]="text?.isUnderline"
                  [class.label]="text.isBold"
                >{{ text.label }}
              </span>

                <div class="value" [style.color]="text.color">{{ text.value }}</div>

                <span class="divider m-l-8 m-r-8" [class.last]="last" fxHide.xs>|</span>
              </div>
            </ng-container>
          </div>
        </div>
        <span *ngIf="test?.status"
          class="status-section mat-body-1"
          fxLayout="column" fxLayoutAlign="center end" fxFlex.xs fxFlex="254px" fxLayoutGap="4px">
          {{test?.status}}
        </span>
        <!-- BUTTONS -->
        <div
          *ngIf="test?.buttons?.length > 0"
          class="action-btn" fxLayout="column" fxLayoutAlign="center" fxFlex.xs fxFlex="254px" fxLayoutGap="4px">
          <ng-container *ngFor="let button of test?.buttons">
            <curr-button
              [color]="button.color"
              [variant]="button.variant"
              (click)="btnClick($event, button)"
              [style]="button.style"
              [isDisabled]="button.isDisabled"
            >
              {{ button.label }}
            </curr-button>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
