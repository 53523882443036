<div mat-dialog-content class="container" *ngIf="referralIcone && userReferralInfo$ | async as referralUrl">
  <span class="refferal-title">Welcome!</span>
  <div class="orientation-video">
    <iframe
        src="https://loom.com/embed/c7f6a3595cbb437487c7ad8c45ef6b60?hideEmbedTopBar=true" frameborder="0" webkitallowfullscreen
        mozallowfullscreen allowfullscreen>
    </iframe>
</div>
<div class="ios-overlapped">
  <curr-button class="btn-copy-referral btn-got-it" color="accent" variant="contained" size="big" (click)="close()">
    NEXT
  </curr-button>
</div>
  <div>
    <p class="refferral-description">You have successfully signed up to Curriculum.com</p>
    <p class="refferral-description">Over the coming week, we will be in touch to schedule a welcome call where we’ll be looking to gain insight into your abilities and ambitions. This will then be passed onto your tutors and councillors for them to begin preparing your program.</p>
    <p class="refferral-description">Once you click “Next” please visit our quick introduction to the system video that can be found by clicking your icon in the top right hand corner and then selecting “Orientation videos.”</p>
    <p class="refferral-description">You have also been successfully accepted into our referral program which could help you reduce our fees by inviting friends to Curriculum.com.</p>
    <p class="refferral-description">Here is your unique referral link:</p>
    <div class="main">
      <div class="code-container">
        <input
          disabled=true
          class="refferal-curriculum ios-overlapped"
          [value]="origin + '/sign-up/' + referralUrl" />
        <curr-button
          class="btn-copy-referral"
          color="accent"
          variant="contained"
          size="big"
          (btnClick)="copyLink(referralUrl)"
        >
          Copy
        </curr-button>
      </div>
    </div>
  </div>
  <p class="refferral-description">
    Please feel free to share this link with anyone who might find our service useful. For each <span class="underline">qualified</span> referral you will receive <span class="refferal-subtitle">£250 cashback.</span> cashback upon the successful completion of your course up to the amount of your fees.
  </p>

  <p class="refferral-description">You can always find your referral link by clicking on the icon in the top right corner of the screen. <span class="refferal-subtitle">We look forward to meeting you soon!</span></p>
</div>
