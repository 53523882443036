<!--
 Created by Daniil Vat. on 8/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<curr-loader [isLoading]="!data" [diameter]="48"></curr-loader>
<div @fade class="report-stats" *ngIf="data" fxLayout="row" fxLayoutAlign="space-between">
  <div *ngFor="let stat of reportStatsItems" class="report-stats--item" fxLayout="row">
    <div class="report-stats--icon">
      <mat-icon color="primary" [svgIcon]="stat.icon"></mat-icon>
    </div>
    <div>
      <p class="report-stats--stat">{{stat.generateValue(data[stat.label])}}</p>
      <p>{{stat.text}}</p>
    </div>

  </div>
</div>