/**
 * Created by Alex Poh. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'curr-page-as-image',
  templateUrl: './page-as-image.component.html',
  styleUrls: ['./page-as-image.component.scss']
})
export class PageAsImageComponent implements OnInit {
  imageSrc = '';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.imageSrc = this.route.snapshot.data.imageSrc;
  }
}
