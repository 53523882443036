import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Question, QuestionType } from '../../../../student/test.model';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-question-form-answer-block',
  templateUrl: './question-form-answer-block.component.html',
  styleUrls: ['./question-form-answer-block.component.scss']
})
export class QuestionFormAnswerBlockComponent implements OnInit, OnChanges {
  @Input() question: Question;
  @Input() isCTQuestion = false;
  @Input() isMultipartQuestion = false;
  @Input() isEditQuestionMode = false;
  @Input() inSowPage = false;
  @Input() checkBoxCorrectAnswers: any[] = [];
  @Input() isQuestionOptionsTinyEditor = false;

  @Output() closeEditMode = new EventEmitter<string>();
  @Output() saveQuestionData = new EventEmitter<string>();

  form: FormGroup;
  questionType = QuestionType;
  ddQuestionTypeOptions = [];

  questionTypeOptions = [
    { value: QuestionType.SELECT_ONE, label: 'Multiple choice' },
    { value: QuestionType.SELECT_MULTIPLE, label: 'Multiple select' },
    { value: QuestionType.OPEN_ENDED, label: 'Open answer' },
    { value: QuestionType.TRUE_OR_FALSE, label: 'True/False' },
    { value: QuestionType.FILE_INPUT, label: 'File' },
    { value: QuestionType.SINGLE_ANSWER, label: 'Single answer' }
  ];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initDropdownOptions();
    /* tslint:disable:no-string-literal */
    if (this.form && !this.form?.controls['scheme']) {
      this.form.addControl('scheme', new FormControl(changes.question?.currentValue?.rubric || ''));
    }
    if (this.form && this.form?.controls['scheme']) {
      this.form.controls['scheme'].patchValue(changes.question?.currentValue?.rubric || '');
    }
    /* tslint:disable:no-string-literal */
  }

  ngOnInit(): void {
    this.createForm();
  }

  update(val: any) {
    this.question.rubric = val;
  }

  createForm() {
    if (this.question?.answer_options?.length) {
      this.form = this.fb.group({});
      this.question.answer_options.forEach((opt, i) => {
        this.form.addControl(`option${i}`, new FormControl(this.question.answer_options[i]));
      });
    }
  }

  selectQuestionType(t: string) {
    if (t === this.question.answer_type) {
      return;
    }
    if (t === this.questionType.SELECT_MULTIPLE) {
      this.checkBoxCorrectAnswers = [];
      this.question.answer_options = [];
      // @ts-ignore
      this.question?.answer_options.push('', '');
      // @ts-ignore
      this.question?.correct_answer_options = [0];
    }
    if (t === this.questionType.SELECT_ONE) {
      this.checkBoxCorrectAnswers = [];
      this.question.answer_options = [];
      // @ts-ignore
      this.question?.answer_options.push('', '');
      // @ts-ignore
      this.question?.correct_answer_options[0] = 0;
    }
    if (t === this.questionType.TRUE_OR_FALSE) {
      this.checkBoxCorrectAnswers = [];
      // @ts-ignore
      this.question?.answer_options = [];
      this.question?.answer_options.push('True', 'False');
      // @ts-ignore
      this.question?.correct_answer_options[0] = 0;
    }
    // @ts-ignore
    this.question?.answer_type = t;
    // this.cdr.detectChanges();
  }

  updateKeyword(keywords: { key: string; mark: number }[]) {
    this.question.oeq_keywords = keywords;
  }

  private initDropdownOptions() {
    if (this.question?.base_type === 'sa') {
      this.ddQuestionTypeOptions = [this.questionTypeOptions[0], this.questionTypeOptions[5]];
    } else {
      this.ddQuestionTypeOptions = [...this.questionTypeOptions];
    }
    if (this.isMultipartQuestion) {
      this.ddQuestionTypeOptions = [this.questionTypeOptions[2], this.questionTypeOptions[5]];
    }
  }

  deleteOption(ii: number) {
    const old = [];
    Array.from(this.question.answer_options).forEach((itm, i) => {
      old[i] = this.form.controls[`option${i}`].value;
    });
    this.question.answer_options.splice(ii, 1);
    old.splice(ii, 1);
    this.createForm();
    old.forEach((item, i) => {
      this.form.controls[`option${i}`].patchValue(item);
    });
  }

  setCorrectRadioAnswer(i: number) {
    // @ts-ignore
    this.question?.correct_answer_options[0] = i;
  }

  checkBoxClick($event: MouseEvent, i: number) {
    $event.preventDefault();
    this.checkBoxCorrectAnswers[i] = !this.checkBoxCorrectAnswers[i];
    this.question.correct_answer_options = [];
    this.checkBoxCorrectAnswers.forEach((item, index) => {
      if (item) {
        this.question.correct_answer_options.push(index);
      }
    });
    this.cdr.detectChanges();
  }

  addAnswer() {
    const old = [];
    const nextOptionNum = this.question.answer_options.length + 1;
    Array.from(this.question.answer_options).forEach((itm, i) => {
      old[i] = this.form.controls[`option${i}`].value;
    });
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      this.checkBoxCorrectAnswers.push('');
    }
    this.question?.answer_options.push(`Option ${ nextOptionNum }`);
    this.createForm();
    old.forEach((item, i) => {
      this.form.controls[`option${i}`].patchValue(item);
    });
  }

  canSaveAnswer() {
    if (this.question?.answer_type === this.questionType.SELECT_ONE) {
      return this.question?.correct_answer_options[0] < -1;
    }
    if (this.question?.answer_type === this.questionType.OPEN_ENDED) {
      return this.question?.oeq_keywords.length <= 0;
    }
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correct_answer_options[0] < -1;
    }
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      return this.checkBoxCorrectAnswers.filter(a => a === true).length <= 0;
    }
  }

  cancelEdit(variable: string) {
    this.closeEditMode.emit(variable);
  }

  saveQuestion(mode: string) {
    this.saveQuestionData.emit(mode);
  }

  saveOptions() {
    Array.from(this.question.answer_options).forEach((itm, i) => {
      this.question.answer_options[i] = this.form.controls[`option${i}`].value;
    });
  }

  setWorkings(val: any[]) {
    this.question.need_working = !(typeof val[0] === 'undefined');
    return this.question.need_working;
  }
}
