/**
 * Created by Maxim B. on 30/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Question, QuestionType } from '../../student/test.model';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { RestApiService, restApiServiceCreator } from '../../shared/api/rest-api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'curr-ui-kit',
  templateUrl: './ui-kit.component.html',
  styleUrls: ['./ui-kit.component.scss']
})
export class UiKitComponent implements OnInit {
  cumulativeTestService: RestApiService<Question>;

  newQuestion: Question;
  selectedQuestion: Question;
  selectedIndex = '1';

  previewQ = [
    {
      id: 1,
      correct_answer_options: [1],
      oeq_keywords: [],
      hasCou: false,
      is_past_exam_question: false,
      question: 'MULTIPART MAXAFAKA',
      curriculum_topic_id: '',
      question_file: '',
      answer_file: '',
      question_image: '',
      answer_image: '',
      answer_options: ['', ''],
      answer_type: 'MULTIPART',
      mark: 7,
      base_type: 'ct',
      estimated_time: 15,
      isSelected: false,
      is_published: false,
      cou_right_id: null,
      cou_id: null,
      cou_wrong_id: null,
      question_code: 0,
      question_order: 0,
      change_status: 'NO_CHANGE',
      difficulty_level: 'FOUNDATION',
      childQuestions: [
        {
          id: 2,
          correct_answer_options: [1],
          oeq_keywords: [{ key: 'riba', mark: 1 }, { key: 'karas', mark: 1 }],
          hasCou: false,
          is_past_exam_question: false,
          question: 'just question',
          curriculum_topic_id: '',
          question_file: '',
          answer_file: '',
          question_image: '',
          answer_image: '',
          answer_options: ['', ''],
          answer_type: 'OPEN_ENDED',
          mark: 1,
          base_type: 'ct',
          estimated_time: 1,
          isSelected: false,
          is_published: false,
          cou_right_id: null,
          cou_id: null,
          cou_wrong_id: null,
          question_code: 0,
          question_order: 0,
          change_status: 'NO_CHANGE',
          difficulty_level: 'FOUNDATION',
          childQuestions: [],
          rubric: null
        },
        {
          id: 4,
          correct_answer_options: [1],
          oeq_keywords: [],
          hasCou: false,
          is_past_exam_question: false,
          question: 'sub 2 instruction',
          curriculum_topic_id: '',
          question_file: '',
          answer_file: '',
          question_image: '',
          answer_image: '',
          answer_options: ['', ''],
          answer_type: 'SINGLE_ANSWER',
          mark: 1,
          base_type: 'ct',
          estimated_time: 1,
          isSelected: false,
          is_published: false,
          cou_right_id: null,
          cou_id: null,
          cou_wrong_id: null,
          question_code: 0,
          question_order: 0,
          change_status: 'NO_CHANGE',
          difficulty_level: 'FOUNDATION',
          childQuestions: [
            {
              id: 4,
              correct_answer_options: [1],
              oeq_keywords: [],
              hasCou: false,
              is_past_exam_question: false,
              question: 'pupsik',
              curriculum_topic_id: '',
              question_file: '',
              answer_file: '',
              question_image: '',
              answer_image: '',
              answer_options: ['', ''],
              answer_type: 'SINGLE_ANSWER',
              mark: 1,
              base_type: 'ct',
              estimated_time: 1,
              isSelected: false,
              is_published: false,
              cou_right_id: null,
              cou_id: null,
              cou_wrong_id: null,
              question_code: 0,
              question_order: 0,
              change_status: 'NO_CHANGE',
              difficulty_level: 'FOUNDATION',
              childQuestions: [],
              rubric: 'pupsik rurur'
            },
            {
              id: 5,
              correct_answer_options: [1],
              oeq_keywords: [{ key: 'ku', mark: 1 }, { key: 'ru', mark: '4' }, { key: 'za', mark: '6' }],
              hasCou: false,
              is_past_exam_question: false,
              question: 'multi riba',
              curriculum_topic_id: '',
              question_file: '',
              answer_file: '',
              question_image: '',
              answer_image: '',
              answer_options: ['', ''],
              answer_type: 'OPEN_ENDED',
              mark: '7',
              base_type: 'ct',
              estimated_time: 1,
              isSelected: false,
              is_published: false,
              cou_right_id: null,
              cou_id: null,
              cou_wrong_id: null,
              question_code: 0,
              question_order: 0,
              change_status: 'NO_CHANGE',
              difficulty_level: 'FOUNDATION',
              childQuestions: [],
              rubric: 'bururoror'
            }
          ]
        }
      ]
    },
    {
      id: 6,
      correct_answer_options: [1],
      oeq_keywords: [{ key: 'ku', mark: 1 }, { key: 'ru', mark: '4' }, { key: 'za', mark: '6' }],
      hasCou: false,
      is_past_exam_question: false,
      question: 'multi riba',
      curriculum_topic_id: '',
      question_file: '',
      answer_file: '',
      question_image: '',
      answer_image: '',
      answer_options: ['', ''],
      answer_type: 'OPEN_ENDED',
      mark: '7',
      base_type: 'ct',
      estimated_time: 1,
      isSelected: false,
      is_published: false,
      cou_right_id: null,
      cou_id: null,
      cou_wrong_id: null,
      question_code: 0,
      question_order: 0,
      change_status: 'NO_CHANGE',
      difficulty_level: 'FOUNDATION',
      childQuestions: [],
      rubric: 'bururoror'
    },
    {
      id: 7,
      correct_answer_options: [1],
      oeq_keywords: [{ key: 'ku', mark: 1 }, { key: 'ru', mark: '4' }, { key: 'za', mark: '6' }],
      hasCou: false,
      is_past_exam_question: false,
      question: 'multi riba QUE 7',
      curriculum_topic_id: '',
      question_file: '',
      answer_file: '',
      question_image: '',
      answer_image: '',
      answer_options: ['', ''],
      answer_type: 'OPEN_ENDED',
      mark: '7',
      base_type: 'ct',
      estimated_time: 1,
      isSelected: false,
      is_published: false,
      cou_right_id: null,
      cou_id: null,
      cou_wrong_id: null,
      question_code: 0,
      question_order: 0,
      change_status: 'NO_CHANGE',
      difficulty_level: 'FOUNDATION',
      childQuestions: [],
      rubric: 'bururoror'
    },
    {
      id: 8,
      correct_answer_options: [1],
      oeq_keywords: [{ key: 'ku', mark: 1 }, { key: 'ru', mark: '4' }, { key: 'za', mark: '6' }],
      hasCou: false,
      is_past_exam_question: false,
      question: 'multi riba QUE 8',
      curriculum_topic_id: '',
      question_file: '',
      answer_file: '',
      question_image: '',
      answer_image: '',
      answer_options: ['', ''],
      answer_type: 'OPEN_ENDED',
      mark: '7',
      base_type: 'ct',
      estimated_time: 1,
      isSelected: false,
      is_published: false,
      cou_right_id: null,
      cou_id: null,
      cou_wrong_id: null,
      question_code: 0,
      question_order: 0,
      change_status: 'NO_CHANGE',
      difficulty_level: 'FOUNDATION',
      childQuestions: [],
      rubric: 'bururoror'
    }
  ];

  constructor(public dialog: MatDialog,
              private httpClient: ApplicationHttpClient) {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(this.httpClient);
  }

  ngOnInit() {
    this.newQuestion = new Question('nkvd', '3561', QuestionType.SELECT_ONE);
  }

  info() {
  }

  saveQuestion() {
    let req: Observable<Question>;

    req = this.cumulativeTestService.add(this.newQuestion);
    req.subscribe(resp => {
      // this.newQuestion.id = resp;
    });
  }

}
