/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourseDetailsService } from '../services/course-details.service';
import { TeacherService } from '../services/teacher.service';

@Component({
  selector: 'curr-report-page',
  templateUrl: './report-page.component.html',
  styleUrls: ['./report-page.component.scss']
})
export class ReportPageComponent implements OnInit {
  path = [];

  isCumulativeTests: boolean;
  learningObjectiveData;
  courseId: string;
  learningObjectiveId: string;
  curriculumId$;
  curriculumId;

  constructor(
    private route: ActivatedRoute,
    private courseDetailsService: CourseDetailsService,
    private teacherService: TeacherService
  ) {}

  ngOnInit(): void {
    this.isCumulativeTests = this.route.snapshot.data.type === 'ct';
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.learningObjectiveId = this.route.snapshot.paramMap.get('ctId');
    this.getCurriculumId();
    this.getLearningObjectiveById();
    this.initBreadcrumb();
  }

  initBreadcrumb(): void {
    this.path = [
      { label: 'Home', url: '/dashboard' },
      { label: `Courses`, url: '/subjects' }
    ];
    this.route.snapshot.data.type === 'ct'
      ? this.path.push({ label: 'Topic Tests', url: `/cumulative-test/${this.courseId}/${this.curriculumId}` })
      : this.path.push({ label: 'Self Assessments Tests', url: `/self-assessment/${this.courseId}/${this.curriculumId}`});
  }

  getLearningObjectiveById(): void {
    this.courseDetailsService
      .getLearningObjectiveById(this.learningObjectiveId)
      .subscribe(data => {
        this.learningObjectiveData = data.title;
        this.path.push({ label: this.learningObjectiveData, url: '' });
      });
  }

  getCurriculumId(): void {
    this.curriculumId$ =this.teacherService.curriculumId$
    this.curriculumId = this.curriculumId$._value
  }
}

