import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'courseFilter'
})
export class CourseFilterPipe implements PipeTransform {
  transform(items: any[], arg: string): any[] {
    if (!items.length) {
      return items;
    }

    return items.filter(i => {
      return i.courseid.toString().toLowerCase().includes(arg.toLowerCase());
    });
  }
}
