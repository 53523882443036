<!-- Created by Alex Poh. on 20/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div id="scheduler_here" class="dhx_cal_container">
  <div class="dhx_cal_navline">
    <div class="dhx_cal_prev_button">&nbsp;</div>
    <div class="dhx_cal_next_button">&nbsp;</div>
    <div class="dhx_cal_today_button"></div>
    <div class="dhx_cal_date"></div>
    <div class="dhx_cal_tab" name="day_tab"></div>
    <div class="dhx_cal_tab" name="week_tab"></div>
    <div class="dhx_cal_tab" name="month_tab"></div>
  </div>
  <div class="dhx_cal_header"></div>
  <div class="dhx_cal_data"></div>
</div>
