<!--Created by Mykola Dan. on 02/11/21.-->
<!--Copyright © 2021 SEVEN. All rights reserved-->

<div class="assessment"
     [ngClass]="{'grade-green': gradeColor === 'green',
      'grade-amber': gradeColor === 'amber',
       'grade-orange': gradeColor === 'orange'}">
  <mat-icon [svgIcon]="icon" class="img" *ngIf="assessment.test_type === 'sa' && icon !== 'orange'"></mat-icon>
  <mat-icon *ngIf="assessment.test_type === 'sa' && icon === 'orange'" class="coin-orange img" [svgIcon]="'coin-gold-4'"></mat-icon>
  <!-- <div class="coin" *ngIf="assessment.test_type === 'topic_test'"> -->
  <div class="coin" *ngIf="assessment.test_type === 'topic_test' && assessment.grade !== null">
    <span class="coin-text">{{ assessment.grade }}</span>
  </div>
  <!-- <div class="checking" *ngIf="assessment.test_type === 'topic_test' && !assessment.finished_at"> -->
  <div class="checking" *ngIf="assessment.test_type === 'topic_test' && assessment.grade === null">
    <mat-icon [svgIcon]="'circleWatch'" class="checking-img"></mat-icon>
  </div>
  <div class="body">
    <span class="title" [currMath]="assessment.test_name"></span>
    <div class="info" *ngIf="date">
      <mat-icon [svgIcon]="'calendar'" class="icon"></mat-icon>
      <span class="info-text">{{ time | date: 'mediumDate' }}</span>
    </div>
    <div class="info" *ngIf="time">
      <mat-icon [svgIcon]="'timer'" class="icon"></mat-icon>
      <span class="info-text">{{ time | date: 'shortTime' }}</span>
    </div>
    <div class="info" *ngIf="assessment.correct_answers || assessment.correct_answers === 0">
      <mat-icon [svgIcon]="'answers'" class="icon"></mat-icon>
      <span *ngIf="assessment.test_type === 'topic_test' && assessment.grade !== null || assessment.test_type !== 'topic_test'" class="info-text">{{ answers }}</span>
      <span *ngIf="assessment.test_type === 'topic_test' && assessment.grade === null" class="info-text">ON CHECKING</span>
    </div>
    <div class="info" *ngIf="assessment.comment_count">
      <mat-icon [svgIcon]="'comment-bell'" class="icon"></mat-icon>
      <span class="info-text">You have {{ assessment.comment_count }} comments</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="center center" class="redo" *ngIf="gradeColor === 'amber' || gradeColor === 'orange'">
      <button class="redo-button" (click)="goToReDoPage($event)">Re Do</button>
      <!-- <button class="view m-l-8" (click)="goToMaterials($event)">View Materials</button> -->
    </div>
  </div>
</div>
