<!--
 Created by Daniil Vat. on 18/2/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<div class="container">
  <div class="m-b-40">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>
  <div *ngIf="isCumulativeTests">
    <curr-tt-report [courseId]="courseId" [learningObjectiveId]="learningObjectiveId"></curr-tt-report>

  </div>
  <div *ngIf="!isCumulativeTests">
    <curr-sa-report [courseId]="courseId" [learningObjectiveId]="learningObjectiveId"></curr-sa-report>
  </div>
</div>