<ul class="settings-list">
  <li class="settings-list-item">
    <div class="list-item">
      <mat-icon class="settings-list-icon" [svgIcon]="'lock-new'"></mat-icon>
      Security Settings
    </div>
  </li>
  <li class="settings-list-item">
    <div class="list-item">
      <mat-icon class="settings-list-icon" [svgIcon]="'bell-notification'"></mat-icon>
      Notification Settings
    </div>
  </li>
  <li class="settings-list-item">
    <div class="list-item">
      <mat-icon class="settings-list-icon" [svgIcon]="'card'"></mat-icon>
      Payment Settings and History
    </div>
  </li>
  <li class="settings-list-item">
    <div class="list-item">
      <mat-icon class="settings-list-icon" [svgIcon]="'game'"></mat-icon>
      Gamifications
    </div>
  </li>
  <li class="settings-list-item">
    <div class="list-item">
      <mat-icon class="settings-list-icon" [svgIcon]="'trash-new'"></mat-icon>
      Delete Account
    </div>
  </li>
</ul>
