/**
 * Created by Maxim B. on 28/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationMessage } from '../notification-message';
@Component({
  selector: 'curr-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnInit {

  @Input() card: NotificationMessage;
  @Output() removeNotification = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

}
