<!-- Created by Maxim B. on 11/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-course-users">

  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="page-title-section">
    <div class="page-title-section-left">
      <div class="page-title">Students</div>
    </div>
  </div>

  <div
    *ngIf="dataFromApi && dataFromApi.totalElements > 0"
    class="results-header-container m-t-16 side-m-8 m-b-16"
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-t-8">{{dataFromApi?.numberOfElements || 0}} of {{dataFromApi?.totalElements || 0}} Users</h2>
    <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="60">
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
        <button
          mat-icon-button
          class="selected-view"
          [matTooltip]="isInListView ? 'Cards view' : 'List view'"
          (click)="setUsersView()"
        >
          <mat-icon [svgIcon]="isInListView ? 'view-module' : 'list'"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="loader-container" *ngIf="isLoading">
    <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
  </div>

  <div class="table-container" *ngIf="isInListView">
    <table mat-table matSort [dataSource]="dataSource" aria-describedby="managers desc">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef scope="col">ID</th>
        <td mat-cell *matCellDef="let element" [routerLink]="'/studentProfile/' + element.id || '1'">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="avatar">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Avatar</th>
        <td mat-cell *matCellDef="let element" [routerLink]="'/studentProfile/' + element.id || '1'">
          <div class="avatar">
            <curr-initials-avatar
              class="avatar-container"
              *ngIf="!element.avatar"
              [name]="element?.first_name + ' ' + element?.last_name" size="26px">
            </curr-initials-avatar>
        
            <img *ngIf="element.avatar" [src]="element.avatar" alt="avatar"/>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Name</th>
        <td mat-cell *matCellDef="let element" [routerLink]="'/studentProfile/' + element.id || '1'">
          <div class="user-name">{{ element?.first_name + ' ' + element?.last_name }}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <div class="cards-container" *ngIf="!isInListView">
    <div class="user-container" *ngFor="let user of users">
      <curr-course-user-card [userData]="user"></curr-course-user-card>
    </div>
  </div>

  <curr-table-pagination
    *ngIf="dataFromApi && dataFromApi.totalElements > 0"
    [totalResults]="dataFromApi.totalElements"
    [pageSize]="12"
    [currentPageIndex]="dataFromApi.pageable.pageNumber"
    [showingText]="'students'"
    (changePage)="pageSelected($event)">
  </curr-table-pagination>

  <ng-container *ngIf="dataFromApi && dataFromApi.totalElements === 0">
    <div class="no-data-container">
      <img src="/assets/images/colored/amico.svg" />
      <div class="no-data-title">There are no students for this course</div>
      <div class="no-data-subtitle"></div>
    </div>
  </ng-container>
</div>
