/*
* Created by Max Bon. on 18/08/2020
* Copyright © 2020 Curriculum Ltd. All rights reserved.
*/
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'curr-student-create-note-custom-context-menu',
  templateUrl: './student-create-note-custom-context-menu.component.html',
  styleUrls: ['./student-create-note-custom-context-menu.component.scss']
})
export class StudentCreateNoteCustomContextMenuComponent implements OnInit {
  highlightColors = environment.highlightColors;

  @Output() colorEvent = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  setCheckedColor(c: string) {
    this.colorEvent.emit(c);
  }
}
