/**
 * Created by Alex Poh. on 29/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as RecordRTC from 'recordrtc';

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable()
export class AudioRecordingService {
  private stream;
  private recorder;
  private interval;
  // TODO: why we use this value?
  // private startTime;
  private recorded$ = new Subject<RecordedAudioOutput>();
  private recordingTime$ = new Subject<string>();
  private recordingFailed$ = new Subject<string>();

  isPaused = false;

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this.recorded$.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this.recordingTime$.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this.recordingFailed$.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      return;
    }

    this.recordingTime$.next('0000');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(s => {
        this.stream = s;
        this.record();
      })
      .catch(error => {
        this.recordingFailed$.next('');
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  duration = 0;

  private record() {
    this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
      type: 'audio',
      mimeType: 'audio/webm'
    });

    this.recorder.record();

    this.interval = setInterval(() => {
      if (!this.isPaused) {
        this.duration += 1000;
        this.recordingTime$.next(this.duration.toString());
      }
    }, 1000);
  }

  pauseRecording() {
    this.isPaused = true;
    this.recorder.pause();
  }

  resumeRecording() {
    this.isPaused = false;
    this.recorder.resume();
  }

  stopRecording() {
    this.isPaused = false;
    if (this.recorder) {
      this.recorder.stop(
        blob => {
          if (this.duration > 0) {
            const mp3Name = encodeURIComponent('audio_' + new Date().getTime() + '.mp3');
            this.stopMedia();
            this.recorded$.next({ blob, title: mp3Name });
          }
        },
        () => {
          this.stopMedia();
          this.recordingFailed$.next('');
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      // this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream = null;
      }
    }
  }
}
