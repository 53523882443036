<!-- Created by Maxim B. on 20/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="hint-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon" svgIcon="exclamation-mark" color="primary"></mat-icon>
  <span class="mat-body-1">
  Questions 1-3 are about your school year groups. Please complete this section for all year groups,
  including those for which you are not intending to use Curriculum.com.
  </span>
</div>

<div class="block m-b-24 m-t-16" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">1. Which term is used in your school to designate year groups?</h2>
  <curr-dropdown placeholder="Select Term Type" [options]="ddYearGroupOption"
                 [selectedValue]="ys.yearTermType" (valueChange)="setYearType($event)"
                 class="dd-term-type p-l-8 curr-dropdown"></curr-dropdown>
</div>


<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start"
     *ngIf="ys.yearTermType === ytt.DIFFERENT">
  <h2 class="question-header mat-h2 m-b-8">
    1. 2 Please indicate which year groups exist in your school (excluding pre-school/Kindergarten, etc)</h2>
  <div class="type-for-year-container p-l-8">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="type-for-year-item"
         *ngFor="let y of ys.yearTypeForEachYear;let i = index">
      <mat-checkbox
        color="primary" class="m-b-0"
        [(ngModel)]="y.isEnabled">
      </mat-checkbox>
      <span class="mat-body-1" fxFlex="20px">{{y.grade}}</span>
      <curr-dropdown placeholder="Select Term Type" [options]="ddYearGroupOptionForGrade"
                     [selectedValue]="y.termType" (valueChange)="setYearTypeForGrade($event, i)"
                     class="dd-term-type-grade curr-dropdown"></curr-dropdown>
      <curr-input [(value)]="y.customType" class="other-input"
                  placeholder="Set type" *ngIf="y.termType === ytt.DIFFERENT"></curr-input>

    </div>
  </div>
</div>

<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    2. Please select the year groups for which you intend to use Curriculum.com initially
    (additional years han be added later)</h2>
  <div class="cb-container p-l-8">
    <div fxLayout="row" fxLayoutAlign="start center" class="m-b-24"
         *ngFor="let y of ys.yearTypeForEachYear; let i = index">
      <mat-checkbox
        color="primary" [(ngModel)]="y.isEnabled">
      </mat-checkbox>
      <span class="cb-text mat-body-1">
              {{y.grade}} {{y.customType || y.termType}}
      </span>
    </div>
  </div>
</div>

<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    3. Are any of these year groups sub-divided, e.g. Year 11A & 11B?</h2>
  <mat-radio-group aria-label="Select an option" class="p-l-8"
                   color="primary" [(ngModel)]="ys.hasSubGroups">
    <mat-radio-button [value]="true" class="m-r-24">Yes</mat-radio-button>
    <mat-radio-button [value]="false">No</mat-radio-button>
  </mat-radio-group>
</div>

<mat-divider *ngIf="isNew"></mat-divider>
<div class="bottom-btns-container m-t-32" fxLayout="row" fxLayoutAlign="center center" *ngIf="isNew">
  <curr-button variant="contained" fxFlex="150px" [class.m-r-32]="isInPopup" (btnClick)="goToNext('next')" [isDisabled]="validateData()">NEXT</curr-button>
  <curr-button variant="text" fxFlex="150px" *ngIf="isInPopup" (btnClick)="goToNext('cancel')">CANCEL</curr-button>
</div>
