<!-- Created by Maxim B. on 20/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="hint-container" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon" svgIcon="exclamation-mark" color="primary"></mat-icon>
  <span class="mat-body-1">
  Questions 4-7 are about your school term/semester dates
  </span>
</div>

<div class="block m-t-16" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    4. How many semesters per year</h2>
  <curr-input [(value)]="ys.semestersQuantity" inputType="number" (valueChange)="reCreateSemesters($event)"
              placeholder="Semester Quantity" class="semesters-input p-l-8"></curr-input>
</div>

<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    5. Are your semesters dates the same for all year groups?</h2>
  <mat-radio-group class="p-l-8" (change)="isAllSemestersSelect($event)"
                   aria-label="Select an option" color="primary" [(ngModel)]="ys.isSameSemestersForAll">
    <mat-radio-button [value]="true" class="m-r-24">Yes</mat-radio-button>
    <mat-radio-button [value]="false">No</mat-radio-button>
  </mat-radio-group>
</div>

<div class="block m-b-24" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="question-header mat-h2 m-b-8">
    6. Please enter your school semesters dates:</h2>
  <div class="grade-select-container m-b-16 full-width p-l-8" *ngIf="!ys.isSameSemestersForAll"
       fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-0 m-l-16">{{selectedYearSemester.year}} Year</h2>
    <div fxLayout="row" fxLayoutAlign="end center">
      <curr-button variant="contained" class="m-r-8" [isDisabled]="selectedGrade === 1"
                   (click)="changeSelectedSemester(selectedGrade - 1)">PREV</curr-button>
      <curr-button variant="contained" class="m-r-8" [isDisabled]="selectedGrade === 13"
                   (click)="changeSelectedSemester(selectedGrade + 1)">NEXT</curr-button>
    </div>
  </div>

  <div class="semester-dates m-b-8  p-l-8" fxLayout="row" fxLayoutAlign="start center"
       *ngFor="let s of selectedYearSemester.periods;let i = index">
    <span class="mat-body-1 m-r-8" fxFlex="100px">{{s.name}}:</span>
    <curr-calendar [initDate]="s.from" [title]="'From'" class="calendar-wrapper" dateFormat="MM/dd/yyyy"
                   (dateEmit)="setDate($event, 'from', i)" [pickerType]="'calendar'"></curr-calendar>
    <curr-calendar [initDate]="s.to" [title]="'To'" class="calendar-wrapper" dateFormat="MM/dd/yyyy"
                   (dateEmit)="setDate($event, 'to', i)" pickerType="calendar"></curr-calendar>
  </div>
</div>

<mat-divider *ngIf="isNew"></mat-divider>
<div class="bottom-btns-container m-t-32" fxLayout="row" fxLayoutAlign="center center" *ngIf="isNew">
  <curr-button variant="contained" fxFlex="150px" class="m-r-32" (btnClick)="goToNext('next')" [isDisabled]="validateData()">NEXT</curr-button>
  <curr-button variant="text" fxFlex="150px" (btnClick)="goToNext('prev')">PREVIOUS STEP</curr-button>
</div>
