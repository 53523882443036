<!-- Created by Alex Poh. on 07/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="row wrap" fxLayoutGap="60px" fxLayoutAlign="start center" class="m-t-24 m-l-24 m-b-24">
  <div *ngFor="let component of components" class="m-b-64 preview">
    <curr-page-card-preview [component]="component"></curr-page-card-preview>
  </div>
</div>

<div fxLayout="row wrap" fxLayoutGap="60px" fxLayoutAlign="start center" class="m-t-24 m-l-24">
  <div *ngFor="let component of fakeComponents" class="m-b-24 preview">
    <curr-page-card-preview [isFake]="true" [component]="component"></curr-page-card-preview>
  </div>
</div>
