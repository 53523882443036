/**
 * Created by Maxim Bon. on 12/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import { CumulativeTest } from './cumulative-test';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { restApiServiceCreator } from '../../shared/api/rest-api.service';
import { Injectable } from '@angular/core';
import {ETestType} from '../../teacher/teacher.enum';

@Injectable({
  providedIn: 'root'
})
export class CumulativeTestResolver implements Resolve<{ct: CumulativeTest, testId: string, paperId: string, testTypeParam: string, schoolYearCourseId: string}> {
  constructor(private httpClient: ApplicationHttpClient, private router: Router) {
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{ct: CumulativeTest, testId: string, paperId: string, testTypeParam: string, schoolYearCourseId: string}>
    | Promise<{ct: CumulativeTest, testId: string, paperId: string, testTypeParam: string, schoolYearCourseId: string}>
    | {ct: CumulativeTest, testId: string, paperId: string, testTypeParam: string, schoolYearCourseId: string}  {

    const restApiService = restApiServiceCreator<CumulativeTest>('curriculumCumTests')(this.httpClient);
    const id = route.paramMap.get('testId');
    const paperId = route.paramMap.get('paperId');
    const testTypeParam = route.paramMap.get('testType');
    const schoolYearCourseId = route.paramMap.get('schoolYearCourseId');

    let newTestText = 'New cumulative test';
    if (testTypeParam === 'homework') {
      newTestText = 'New homework';
    }
    if (testTypeParam === 'quiz') {
      newTestText = 'New quiz';
    }

    let ct = new CumulativeTest(newTestText, +paperId);
    if (paperId && id !== 'new'){
     // @ts-ignore
      ct = restApiService.getById(id);
     return {ct, testId: id, paperId, testTypeParam, schoolYearCourseId};
    }

    if (id === 'new') {
      route.parent.data.breadcrumb.push({ label: newTestText, url: '' });
    }
    return {ct, testId: id, paperId, testTypeParam, schoolYearCourseId};
  }
}
