<!--
 Created by Daniil Vat. on 7/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="m-b-32" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="column" fxLayoutAlign="center">
    <h2 [currMath]="learningObjective?.title" class="mat-display-2 m-b-8">
    </h2>
  </div>

  <curr-button size="big"  variant="contained" [routerLink]="navigationLink">VIEW ANSWERS</curr-button>
</div>

<div class="m-b-16" fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex="66%">
    <curr-expansion-panel title="Questions most commonly answered incorrectly">
      <div
        *ngIf="!(mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length)">
        There is no data</div>
      <div *ngIf="(mostIncorrectQuestions?.hardestQuestions.length || mostIncorrectQuestions?.hardestQuestions.length)">
        <curr-hardest-questions [data]="mostIncorrectQuestions?.hardestQuestions"></curr-hardest-questions>
        <div fxLayout="row">
          <div fxFlex="26%"></div>
          <div fxLayout="column">
            <curr-answers-examples [data]="mostIncorrectQuestions?.wrongAnswersExamples"></curr-answers-examples>
          </div>
        </div>
      </div>
    </curr-expansion-panel>
  </div>
  <div fxFlex="33%">

    <curr-expansion-panel title="Under performing">
      <div *ngIf="!(underPerformingStudents?.length)">
        There is no data</div>
      <curr-under-performing-students *ngIf="(underPerformingStudents?.length)" [data]="underPerformingStudents">
      </curr-under-performing-students>
    </curr-expansion-panel>
  </div>
</div>
