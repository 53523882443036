/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input } from '@angular/core';
import { ChartConfiguration, ChartType, Chart, Plugin } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'curr-assessment-results-chart',
  templateUrl: './assessment-results-chart.component.html',
  styleUrls: ['./assessment-results-chart.component.scss']
})
export class AssessmentResultsChartComponent {
  @Input() set data(chartData: { grades: [], averageBenchmark: [] }) {
    if (chartData && chartData.grades) {
      const hasItems = chartData.grades.filter(item => !!item);
      this.isChartBlur = hasItems.length === 0;

      this.lineChartData = [
        {
          data: chartData.grades.map(value => value === null ? undefined : value),
          label: 'Cumulative Test Results',
          fill: false,
          tension: 0.3,
          spanGaps: true,
          borderColor: '#006878',
          backgroundColor: '#006878',
          pointBackgroundColor: '#006878',
          pointBorderColor: '#006878',
          pointHoverBackgroundColor: '#006878',
          pointHoverBorderColor: '#006878',
          borderWidth: 3,
        },
        {
          data: chartData.averageBenchmark,
          label: 'Benchmark',
          fill: false,
          tension: 0,
          borderColor: '#5DB605',
          backgroundColor: '#5DB605',
          borderWidth: 3,
          pointRadius: 0,
          pointHoverRadius: 0,
          hoverBorderWidth: 0,
          hitRadius: 0,
          borderDash: [],
        }
      ];
    }
  }

  @Input() set month(months: string[]) {
    if (months) {
      this.lineChartLabels = months;
    }
  }

  isChartBlur: boolean = false;
  lineChartLegend: boolean = true;
  lineChartType: string = 'line';
  lineChartData;
  lineChartLabels: Array<any> = [];

  lineChartOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          font: {
            size: 12,
          },
          padding: 24,
          color: '#000A12',
          borderRadius: 4,
          boxHeight: 12,
          boxWidth: 36,
          useBorderRadius: true,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.dataset.label === 'Benchmark') {
              return null;
            }
            return context.dataset.label + ': ' + context.raw;
          },
          title: function (context) {
            if (context[0].dataset.label === 'Benchmark') {
              return null;
            }
            return context[0].label;
          }
        },
      },
    },
    scales: {
      x: {
        type: 'category',
      },
      y: {
        type: 'category',
        labels: ['', 'A', 'B', 'C', 'D', 'E', 'F'],
        ticks: {
          callback: (value) => {
            if (!value) return '';
            const gradeLabels = ['', 'A', 'B', 'C', 'D', 'E', 'F'];
            return gradeLabels[value];
          },
        },
      }
    },
  };

  constructor() {
    Chart.register(this.shadowPlugin);
  }

  shadowPlugin: Plugin = {
    id: 'shadowPlugin',
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      chart.data.datasets.forEach((dataset, index) => {
        if (dataset.label === 'Benchmark') {
          const meta = chart.getDatasetMeta(index);
          if (!meta.hidden) {
            ctx.save();

            ctx.shadowColor = '#5DB605';
            ctx.shadowBlur = 15;
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 0;

            ctx.strokeStyle = dataset.borderColor as string;
            ctx.lineWidth = dataset.borderWidth as number;
            ctx.beginPath();
            meta.data.forEach((point, i) => {
              if (i === 0) {
                ctx.moveTo(point.x, point.y);
              } else {
                ctx.lineTo(point.x, point.y);
              }
            });
            ctx.stroke();

            ctx.restore();
          }
        }
      });
    }
  };
}
