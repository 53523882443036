/*
 * Created by Dmytro Sav. on 5/12/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-create-courses',
  templateUrl: './create-courses.component.html',
  styleUrls: ['./create-courses.component.scss']
})
export class CreateCoursesComponent implements OnInit {
  @Input() isEdited: boolean;

  form: FormGroup = this.fb.group({});

  courseOptions = [
    { value: '1', label: '# 24235 Algebra ' },
    { value: '2', label: '# 45346 Biology' },
    { value: '3', label: '# 6456464 Drawing' },
    { value: '4', label: '# 4545845 Chemistry' },
    { value: '4', label: '# 5456456 Geography' }
  ];

  yearsOptions = [
    { value: '1', label: 'Year_1' },
    { value: '2', label: 'Year_2' },
    { value: '3', label: 'Year_3' },
    { value: '4', label: 'Year_4' }
  ];

  selectedValue = '1';

  constructor(private fb: FormBuilder) {}

  formOptions;
  gradeOptions;

  ngOnInit() {
    this.formOptions = [
      { value: '1', label: 'Form_1' },
      { value: '2', label: 'Form_2' },
      { value: '3', label: 'Form_3' },
      { value: '4', label: 'Form_4' }
    ];

    this.gradeOptions = [
      { value: '1', label: 'A' },
      { value: '2', label: 'B' },
      { value: '3', label: 'C' },
      { value: '4', label: 'D' }
    ];
  }

  getCourseOptions(value) {}
  getYearsOptions(value) {}
}
