import { ApplicationHttpClient } from '../../api/application-http-client';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { IPerformanceChart, IPerformanceChartSubject } from './student-performance-chart.interface';

@Injectable({
  providedIn: 'root'
})
export class StudentPerformanceChartService {
  constructor(private httpClient: ApplicationHttpClient) {}

  getChartData(subject: string, studyYearId: number, courseId: number, studentId: number): Observable<IPerformanceChart> {
    return this.httpClient.get<IPerformanceChart>(`/schoolUserStat/student?subjectName=${subject}&studyYearId=${studyYearId}&courseId=${courseId}&studentId=${studentId}`);
  }

  getChartSubjects(studentId: number): Observable<any> {
    return this.httpClient.get(`/filter/options/courses?studentId=${studentId}`).pipe(map(r => (r as any).data));
  }
}
