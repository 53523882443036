/**
 * Created by Mykola Dan. 02/11/21.
 * Copyright © 2021 SEVEN. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { HistoricalAssessmentWidgetService } from './historical-assessment-widget.service';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface Assessment {
  can_start_sa: boolean,
  comment_count: number,
  correct_answers: number,
  course_id: number,
  course_student_id: number,
  curriculum_id: number,
  finished_at: string,
  grade: string,
  lobjective_id: number,
  mark: number,
  paper_id: number,
  start_time: string,
  student_test_id: number,
  test_id: number,
  test_name: string,
  test_type: string,
  total_answers: number,
  topic_id: string,
}

@Component({
  selector: 'curr-historical-assesment-widget',
  templateUrl: './historical-assesment-widget.component.html',
  styleUrls: ['./historical-assesment-widget.component.scss']
})
export class HistoricalAssesmentWidgetComponent implements OnInit {
  @Input() schoolYearCourseId: string;
  isOpen: boolean = true;
  assessments$: Observable<Assessment[]>;
  isLoading: boolean = true;

  constructor(
    private historicalAssessmentWidgetService: HistoricalAssessmentWidgetService,
    private router: Router) { }

  ngOnInit(): void {
    this.assessments$ = this.historicalAssessmentWidgetService
      .getLastTopicTestsSelfAssessments(+this.schoolYearCourseId)
      .pipe(
        map(res => res.data.items),
        finalize(() => this.isLoading = false)
      );
  }

  openWidget(): void {
    this.isOpen = !this.isOpen;
    this.isLoading = true;
  }

  goToHistoricalAssessments(): void {
    this.router.navigate([`/courses-details/historical-assessment/${this.schoolYearCourseId}`]);
  }
}
