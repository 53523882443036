<!-- Created by Alex Poh. on 09/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-my-question-bank">
  <div class="top-actions-line">
    <div class="last-modified">
      LAST MODIFIED: {{ learningObjective?.created_at ? (learningObjective?.created_at | date: 'dd MMM yyyy') : '-' }}
    </div>
    <div class="actions">
      <curr-button variant="outlined" [size]="'big'" (btnClick)="createManually()">Create Manually</curr-button>
      <curr-button variant="outlined" [size]="'big'" (btnClick)="aiAssistant()">AI Assistant</curr-button>
    </div>
  </div>

  <div class="type-filters">
    <mat-checkbox
      (change)="checkQuestionFilterType($event.checked, 'CUMULATIVE_TEST')"
      >Cumulative Test</mat-checkbox
    >
    <mat-checkbox
      (change)="checkQuestionFilterType($event.checked, 'HOMEWORK')"
      >Homework</mat-checkbox
    >
    <mat-checkbox
      (change)="checkQuestionFilterType($event.checked, 'QUIZ')"
      >Quiz</mat-checkbox
    >
  </div>

  <div class="show-past-paper">
    <curr-checkbox
      [isMultipleSelect]="false"
      [options]="[{ label: 'Past Paper Questions', value: true }]"
      [value]="[showPastPaper]"
      (valueChange)="changeShowPastPaper($event)"
    >
    </curr-checkbox>

    <curr-dropdown-component-v2
      *ngIf="showPastPaper"
      class="year-select"
      placeholder="Year"
      [options]="pastExamOptions"
      (selectedValueChange)="setPastExamYear($event)"
    ></curr-dropdown-component-v2>
  
    <curr-dropdown-component-v2
      *ngIf="showPastPaper"
      class="exam-select"
      placeholder="Exam Board"
      [options]="examinationBoard"
      (selectedValueChange)="setExaminationBoard($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div class="m-t-32" *ngIf="isLoadingQuestions">
    <curr-loader [diameter]="40"></curr-loader>
  </div>

  <div *ngIf="!isLoadingQuestions">
    <curr-expansion-panel-questions
      *ngFor="let question of cumulativeQuestions; let i = index"
      class="panel-question"
      [isShowed]="false"
      [question]="question"
      [questionNumber]="i + 1"
      (onEditQuestionItem)="editQuestion($event)"
      (onDeleteQuestionItem)="deleteQuestion($event)"
      >
      <curr-my-question-section [question]="question" [questionNumber]="i + 1"></curr-my-question-section>
    </curr-expansion-panel-questions>
  </div>

  <div *ngIf="cumulativeQuestions.length === 0 && !isLoadingQuestions" class="no-questions">
    <img src="/assets/images/colored/empty-task-list.svg" />
    <div class="empty-text">No Questions Yet!</div>
  </div>
</div>
