import { ApplicationHttpClient } from '../../api/application-http-client';
import { Injectable } from '@angular/core';
import { catchError, map, switchMap } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';
import { ChartData, TeacherChartData } from './chart-data';
import { Observable, of } from 'rxjs';
import { UserService } from '../../user.service';
import { Role } from '../../role';

export enum ChartPeriod {
  YEAR = 'YEAR',
  SEMESTER = 'SEMESTER',
  MONTH = 'MONTH'
}

@Injectable({
  providedIn: 'root'
})
export class DashboardChartService {
  constructor(private httpClient: ApplicationHttpClient, private userService: UserService) {}

  getChartDataForTeacherDashboard(period: ChartPeriod, filterData?: any): Observable<ChartData | TeacherChartData> {
    return this.userService.getCurrentUser().pipe(
      switchMap(user => {
        return this.httpClient.post('/schoolUserStat', { stat_period: period, ...filterData }).pipe(
          catchError(err => {
            return of({ data: null });
          }),
          map((res: any) => {
            if (user.role === Role.Teacher || user.role === Role.Student) {
              return plainToClass(TeacherChartData, res.data) as any;
            } else {
              return plainToClass(ChartData, res.data) as any;
            }
          })
        );
      })
    );
    // this.httpClient.post('/schoolUserStat', { stat_period: period, ...filterData }).pipe(
    //   map((res: any) => {
    //     return plainToClass(ChartData, res.data) as any;
    //   })
    // );
  }

  getFilters(): Observable<any> {
    return this.httpClient.get('/filter/options/courses').pipe(map(response => (response as any).data));
  }
}
