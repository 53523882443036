import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { Observable } from 'rxjs';
import { CourseFilters, FilterItem } from './course-filters';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

@Injectable({ providedIn: 'root' })
export class CourseFilterService {

  level;
  levelId;
  constructor(private httpClient: ApplicationHttpClient) {}

  getCourseFilter(): Observable<CourseFilters> {
    return this.httpClient.get('/filter/options/courses').pipe(
      map((res: any) => {
        return plainToClass(CourseFilters, res.data) as any;
      })
    );
  }

  generateFilterItems(courseFilters: CourseFilters): FilterItem[] {
    const { courses, subjectToCourse, levelToCourse, levels } = courseFilters;
    const filters = courses
      .filter(filter => filter.value)
      .map(({ value }) => {
        const { subjectName } = subjectToCourse.find(subject => subject.courseId === +value);
        levelToCourse.filter(level => level.from)
          .map(({ from }) => {
          this.level = levels.find(level => level.value === from);
            if(this.level.label === '12'){
              this.levelId = 'AS'
            } else if(this.level.label === '13'){
              this.levelId = 'A2'
            }else if(this.level.label === '1'){
              this.levelId = 'Year 1'
            } else if(this.level.label === '2'){
              this.levelId = 'Year 2'
            }  else {
              this.levelId = 'A2'
            }
        });

        // const { label: lavelLable } = levels.find(level => level.value === levelIdInCourse);
        return {
          value,
          // label: `${subjectName} - ${lavelLable} - ${courseLabel}`
          label: `${this.levelId} - ${subjectName}`
        };
      });
    return filters;
  }
}
