<!--Created by Maxim B. on 30/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<p *ngIf="error.length">{{ error }}</p>
<div class="cb-container" *ngIf="!error.length">
  <mat-checkbox
    *ngFor="let item of componentOptions; trackBy: identify"
    (change)="updateValue()"
    [color]="color"
    [ngClass]="[type, color]"
    [disabled]="item.disabled || isDisabled"
    [(ngModel)]="item.checked"
    [checked]="item.checked"
    [labelPosition]="labelPosition"
  >
    <span [currMath]="item.label"></span>
  </mat-checkbox>
</div>
