import { Injectable } from '@angular/core';
import { UpcomingVideo } from './upcoming-lessons-videos.component';
import YouTubePlayer from 'node_modules/youtube-player';
import VimeoPlayer from '@vimeo/player';

@Injectable({
  providedIn: 'root'
})
export class UpcomingVideoService {
  constructor() {}

  extractYoutubeVideoId(item: UpcomingVideo): string {
    const link = item.url;
    const ind = link.indexOf('v=');
    let videoId = '';
    if (ind >= 0) {
      videoId = link.substr(ind + 2);
    }
    return videoId;
  }

  extractVimeoVideoId(item: UpcomingVideo): string {
    const link = item.url;
    const ind = link.indexOf('vimeo.com/');
    if (ind >= 0) {
      return link.substr(ind + 10);
    }
    return '';
  }

  isVimeoVideo(url: string): boolean {
    if (url.includes('vimeo.com/')) {
      return true;
    }
    if (url.includes('v=')) {
      return false;
    }
  }

  createVimeoPlayer(item: UpcomingVideo, isPopup: boolean = false) {
    const w = isPopup ? '1021' : '285';
    const h = isPopup ? '588' : '166';
    return new VimeoPlayer(item.containerId, {
      id: item.videoId,
      width: w,
      height: h
    });
  }

  createYoutubePlayer(item: UpcomingVideo, isPopup: boolean = false) {
    return YouTubePlayer(item.containerId, {
      videoId: item.videoId,
      height: isPopup ? '588' : '166',
      width: isPopup ? '1021' : '285',
      playerVars: { autoplay: 0 }
    });
  }

  createVideoPlayer(video: UpcomingVideo, isPopup = false): YouTubePlayer | VimeoPlayer {
    return video.isVimeoVideo ? this.createVideoPlayer(video) : this.createYoutubePlayer(video, isPopup);
  }
}
