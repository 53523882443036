<!--
 Created by Volodymyr Mak. on 29/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="row" class="container" fxLayoutGap="20px">
  <article class="profile-card" fxLayout="column" fxLayoutAlign="stretch center">
    <div class="avatar item">
      <img [src]="" alt="">
      <curr-image-upload (imgCropped)="setProfilePhoto($event)"></curr-image-upload>
    </div>

    <section class="user-info">
      <form [formGroup]="form">
        <section class="edit-user" fxLayout="column">
          <curr-auth-input
          fxFlex="auto"
          controlName="firstName"
          placeholder="First Name"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="ID"
          controlName="personalID"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="Phone"
          controlName="phone"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="Last Name"
          controlName="lastName"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="Email"
          controlName="email"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="Country"
          controlName="country"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-auth-input
          fxFlex="auto"
          placeholder="School"
          controlName="school"
          [formGroup]="form"
          ></curr-auth-input>
          <curr-button
          class="save-btn profile-btn"
          (btnClick)="create()"
          [isDisabled]="form.invalid">
          Save</curr-button>
        </section>
      </form>
    </section>
  </article>

  <article class="right-section" fxLayout="column" fxLayoutAlign="start">
    <div class="m-b-40" fxLayout="row" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="end center">
        <curr-button variant="contained" color="primary" size="small">
          CANCEL
        </curr-button>

        <curr-button variant="contained" color="primary" size="small">
          EDIT
        </curr-button>

        <curr-button variant="contained" color="primary" size="small">
          SAVE
        </curr-button>
      </div>
    </div>

    <div>
      <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="38px" class="m-b-16">
        <span>Add by CSV</span>
        <span class="text-underline cursor-pointer">Download Template</span>
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between">
        <curr-button (btnClick)="chooseFile()">Choose file</curr-button>
        <curr-button (btnClick)="loadInfo()">Load info</curr-button>
      </div>
    </div>

    <section class="delete-permission-card" fxLayout="column">
      <div class="title" fxLayoutAlign="space-between">
        <span>Permission</span>
      </div>
      <mat-divider></mat-divider>
      <div class="card">
        <curr-checkbox
          class="permission-box m-b-4"
          [options]="checkboxList.options"
          [color]="checkboxList.color"
          [(value)]="checkboxList.value"
          [type]="checkboxList.type"
          [isMultipleSelect]="checkboxList.isMultipleSelect"
        ></curr-checkbox>
      </div>
    </section>
  </article>
</div>

