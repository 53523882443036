/**
 * Created by Maxim B. on 31/03/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'curr-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
/*
 * Usage example:
 * <curr-toggle-button [options]="cbOptions" [(value)]="cbValues" [color]="cbColor"></curr-toggle-button>
 *  cbOptions: { value, label, disabled }[]
 *  cbValues: any[]
 *  cbColor: string
 *  value will be auto assigned back to your cbValues
 * */
export class ToggleButtonComponent implements OnInit {
  @Input() options: { value; label; disabled }[];
  @Input() value: any[];
  @Input() color = 'primary';
  @Input() labelPosition: 'after' | 'before' = 'after';
  @Output() valueChange = new EventEmitter<any[]>();

  componentOptions: { value; label; checked; disabled }[] = [];
  error = '';

  constructor() {}

  ngOnInit(): void {
    if (this.value.length > this.options.length) {
      this.error = 'you entered wrong options for component!';
    }
    this.options.forEach(i => {
      this.componentOptions.push({
        label: i.label,
        value: i.value,
        checked: this.value.includes(i.value),
        disabled: i.disabled || false
      });
    });
  }

  updateValue() {
    this.value = [];
    this.componentOptions.forEach(i => {
      if (i.checked) {
        this.value.push(i.value);
      }
    });
    this.valueChange.emit(this.value);
  }
}
