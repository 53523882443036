<!--Created by Maxim B. on 21/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="container">
  <h1 class="please-header">Please select the data to display in the table</h1>
  <div class="type-of-users-container column-container" fxLayout="row" fxLayoutAlign="start center">
    <div class="one-select" fxLayout="column" fxLayoutAlign="start start">
      <span>Types of users</span>
      <curr-dropdown
        [options]="typeOfUsersOpt"
        class="curr-dropdown"
        [defaultValue]="typeOfUsersOpt[0]?.value"
        (valueChange)="setupFieldsOption($event)"
      ></curr-dropdown>
    </div>
  </div>

  <div class="column-container" [class.no-bottom-border]="!table?.fields.length">
    <div class="one-select" fxLayout="column" fxLayoutAlign="start start">
      <span>1st column</span>
      <curr-dropdown
        class="curr-dropdown"
        [options]="[{ value: 'name', label: 'Name & Surname' }]"
        [defaultValue]="'name'"
        [isDisabled]="true"
      ></curr-dropdown>
    </div>
  </div>

  <div
    class="column-container"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [class.no-bottom-border]="islast"
    *ngFor="let item of table?.fields; let i = index; last as islast"
  >
    <div class="column-wrapper" fxLayout="row" fxLayoutAlign="start center">
      <div class="one-select" fxLayout="column" fxLayoutAlign="start start">
        <span>{{ i + 2 | ordinal }} column</span>
        <curr-dropdown
           class="curr-dropdown"
          [options]="tableFieldsOptions"
          (valueChange)="setTableField($event, i)"
          [selectedValue]="item.fieldName"
        ></curr-dropdown>
      </div>
      <div class="one-select filter" fxLayout="column" fxLayoutAlign="start start">
        <span>Filter</span>
        <curr-dropdown
          class="curr-dropdown"
          [options]="[
            { value: 'all', label: 'All' },
            { value: 'notAll', label: 'Not All' }
          ]"
          [defaultValue]="item.fieldFilter"
        ></curr-dropdown>
      </div>
    </div>
    <div class="delete-column-container" fxLayout="column" fxLayoutAlign="center end">
      <button mat-icon-button (click)="deleteColumn(i)">
        <mat-icon [svgIcon]="'trash'"></mat-icon>
      </button>
    </div>
  </div>

  <div class="btn-container" fxLayout="column" fxLayoutAlign="start start">
    <curr-button class="curr-button" [variant]="'outlined'" [icon]="'plus'" (btnClick)="createNewField()"
      >ADD NEW COLUMN
    </curr-button>

    <div class="save-cancel-btn-container" fxLayout="row" fxLayoutAlign="start center">
      <curr-button
        class="curr-button"
        [variant]="'contained'"
        [isDisabled]="!table.fields.length"
        (btnClick)="createNewTable()"
        >GENERATE TABLE
      </curr-button>
      <curr-button [variant]="'outlined'" (btnClick)="cancelCreate()">CANCEL</curr-button>
    </div>
  </div>
</div>
