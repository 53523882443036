/**
 * Created by Alex Poh. on 12/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';
import { Observable } from 'rxjs';
import { SunburstApiResponse } from './sunburst-api-response';
import { map } from 'rxjs/operators';
import { plainToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root'
})
export class SunburstService {
  constructor(private httpClient: ApplicationHttpClient) {}

  getSunburstData(params: any): Observable<SunburstApiResponse[]> {
    return this.httpClient
      .get('/sunBurstDiagram', { params })
      .pipe(map((res: { children: SunburstApiResponse[] }) => res.children))
      .pipe(
        map(res => {
          return plainToClass(SunburstApiResponse, res);
        })
      );
  }
}
