<div class="container full-width" [class.has-border]="isHighlighted"
     fxLayout="row"
     fxLayoutAlign="center center" (mouseleave)="closeContextMenu()"
     [style]="{'border-color': highlightColor, 'background-color': highlightColor,'width': previewWidth+'px'}">
  <img [src]="imageSource" alt="image">
  <div class="hover-container" (click)="openImagePreviewDialog()">
    <mat-icon svgIcon="zoom-icon"></mat-icon>

      <div class="hint-container" fxLayout="row" fxLayoutAlign="start center" *ngIf="noteText && isHighlighted">
        <span class="mat-body-1">{{noteText}}</span>
      </div>

    <!-- <div class="leave-comment-container" xLayout="row"
         (click)="$event.stopPropagation(); openNoteMenu()"
         fxLayoutAlign="center center" *ngIf="!isHighlighted">
      <mat-icon svgIcon="leave-comment"></mat-icon>

      <div class="popup-container" *ngIf="isContextNoteMenuVisible">
        <curr-student-create-note-custom-context-menu (colorEvent)="onColorSelectEvent($event)">
        </curr-student-create-note-custom-context-menu>
      </div>
      <div class="popup-background" *ngIf="isContextNoteMenuVisible"
           (click)="$event.stopPropagation(); closeContextMenu()"></div>

    </div> -->
  </div>
</div>
