<!--
 Created by Dmytro Sav. on 4/6/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-course-user-card" (click)="navigateToProfile('/studentProfile/'+userData.id || '1')">
  <div class="avatar">
    <curr-initials-avatar
      class="avatar-container"
      *ngIf="!userData.avatar"
      [name]="userData?.first_name + ' ' + userData?.last_name" size="40px">
    </curr-initials-avatar>

    <img *ngIf="userData.avatar" [src]="userData.avatar" alt="avatar"/>
  </div>

  <div class="name">{{ userData?.first_name + ' ' + userData?.last_name}}</div>
</div>
