/**
 * Created by Alex Poh. on 22/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Observable, of } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';
import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SignInInputData } from '../../auth/sign-in/sign-in-input-data';
import { User } from '../user';
import { Role } from '../role';

@Injectable({ providedIn: 'root' })
export class FakeApiService {
  private apiDelayTime = 100;

  constructor(private localStorageService: LocalStorageService) {
  }

  // TODO: Add some tests for this functionality.

  get<T>(url): Observable<T> {
    const item = this.localStorageService.get(url);
    return of(item).pipe(delay(this.apiDelayTime));
  }

  post<T>(item, url): Observable<T> {
    if (url === 'signIn') {
      return this.processSignIn(item);
    }
    if (url === 'signUp') {
      return this.processSignUp(item);
    }
    this.saveItem(item, url);
    return of(item).pipe(delay(this.apiDelayTime));
  }

  saveItem(item, url) {
    const items = this.localStorageService.get(url);
    if (items && items.length > 0) {
      items.push(item);
      this.localStorageService.set(url, items);
    } else {
      this.localStorageService.set(url, [item]);
    }
  }

  saveItemIfNotExist(item, url) {
    this.localStorageService.set(url, item);
  }

  private processSignIn(item: SignInInputData): Observable<any> {
    const users: User[] = this.localStorageService.get('users');
    const user = users.find((u: User) => {
      return u.emailOrPhone === item.emailOrPhone;
    });
    return of(user);
  }

  private processSignUp(item: User): Observable<any> {
    const user = { ...item, role: Role.Student };
    this.saveItem(user, 'users');
    return of(user);
  }


}
