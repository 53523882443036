<!-- Created by Alex Poh. on 26/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="student-performance-chart">
  <div class="header-container main-header m-b-0" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-16 m-l-16">Results</h2>
  </div>

  <mat-divider class="m-b-16 full-width"></mat-divider>

  <div class="chart-card">
    <div class="performance-chart-no-data" *ngIf="performanceChartNoData">
      <img src="/assets/images/colored/amico.svg" />
      <div class="performance-chart-no-data-text">No data to show.</div>
    </div>

    <ng-container *ngIf="!performanceChartNoData">
      <div class="performance-chart-loader" *ngIf="performanceChartLoading">
        <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
      </div>

      <ng-container *ngIf="!performanceChartLoading">
        <div class="chart-filters">
          <div class="chart-filters-item"><span>Avg. Grade: {{performanceResultsMark ?? '-'}}</span></div>
          <div class="chart-filters-item">
            <curr-dropdown-component-v2
            [selectedValue]="performanceChartSubjects.length > 0 ? performanceChartSubjects[0].value : ''"
            [options]="performanceChartSubjects"
            (selectedValueChange)="performanceChartSubjectsChange($event)">
            </curr-dropdown-component-v2>
          </div>
        </div>
        <div class="chart-date">{{ performanceResultDate }}</div>
        <curr-assessment-results-chart [data]="performanceResultsChart" [month]="performanceResultsChartMonth"></curr-assessment-results-chart>
      </ng-container>
    </ng-container>
  </div>
</div>
