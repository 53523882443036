<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-8">
  <h2 class="mat-h2 m-b-0" fxFlex>Edit note</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div class="name-container" fxLayout="column" fxLayoutAlign="center center">
  <curr-input class="full-width" [(value)]="text" ></curr-input>
  <div class="m-t-8 full-width" fxLayout="row" fxLayoutAlign="end center">

    <curr-button variant="text" (btnClick)="dialogRef.close()">CANCEL</curr-button>
    <curr-button variant="contained" class="m-l-16"
                 [isDisabled]="!text"
                 (btnClick)="dialogRef.close(text)">SAVE</curr-button>
  </div>
</div>
