<!-- Created by Maxim B. on 19/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-card class="card m-b-8" *ngIf="!deletedQuestion && inEditQuestionMode">
  <curr-edit-question-block #editQuestionBlock [isCTQuestion]="questionTestType == 'ct'" [inSowPage]="true" [cou]="cou"
    [topics]="topics" [(question)]="question"></curr-edit-question-block>
  <div fxLayoutAlign="start start" fxLayout="row" class="m-t-16 full-width">
    <curr-button variant="contained" (btnClick)="updateQuestion()"
      class="m-r-8">UPDATE</curr-button>
    <curr-button variant="text" (click)="inEditQuestionMode = false">CANCEL</curr-button>
  </div>
</mat-card>

<div class="m-t-16">
  <curr-loader [diameter]="40" [isLoading]="isLoading" *ngIf="isLoading"></curr-loader>
</div>

<div *ngIf="!isLoading" @fade>
  <mat-card class="card m-b-8" *ngIf="!deletedQuestion && !inEditQuestionMode">
    <div class="mat-h4 m-b-8" fxLayout="row" fxLayoutAlign="space-between">
      <span>
        {{question?.answer_type === questionType.MULTIPART ? 'Multistage ' : '' }}
        Question {{ questionNumber }}:
      </span>

      <div class="right-top-corner" fxLayout="row" fxLayoutGap="2px">
        <ng-container *ngIf="!isAddToCTView">
          <curr-icon-button icon="trash" (click)="deleteQuestion()"></curr-icon-button>
          <curr-icon-button icon="edit" (click)="editQuestion()"></curr-icon-button>
        </ng-container>
        <ng-container *ngIf="isAddToCTView">
          <curr-button variant="contained" [icon]="'plus'" *ngIf="!isInCT" (click)="addToCT()">ADD TO TEST</curr-button>
          <curr-button variant="outlined" color="warn" *ngIf="isInCT" (click)="addToCT()">REMOVE</curr-button>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="question.is_past_exam_question">
      <div class="flex">
        <curr-checkbox [options]="[{label: 'Past exam question', value:true, disabled: true}]"
          [value]="[question.is_past_exam_question]"></curr-checkbox>
        <span class="mar">{{ question.examination_board?.name }}</span>
      </div>
    </ng-container>

    <div class="mat-h3 m-b-24" [id]="'desc-'+question.id" *ngIf="question.previewQuestion"
      [currMath]="question.previewQuestion || 'No Question Introduction'"></div>
    <div class="mat-h3 m-b-24 img-width" [id]="'desc-'+question.id" *ngIf="!question.previewQuestion"
      [currMath]="question.question || 'No Question Introduction'"></div>
    <div>
      <div *ngIf="
          question?.answer_type === questionType.SELECT_ONE ||
          question?.answer_type === questionType.SELECT_MULTIPLE ||
          question?.answer_type === questionType.TRUE_OR_FALSE
        " fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px">
        <div class="mat-h4 m-b-8">Answer:</div>
        <div class="options-wrapper" *ngFor="let option of question?.answer_options; let i = index">
          <div class="options-wrapper-row">
            <mat-icon [class.correct-circle]="isAnswerCorrect(i)"
              [svgIcon]="!isAnswerCorrect(i) ? 'lens-outlined' : 'lens-filled'" class="circle-question m-r-8"></mat-icon>
            <span [class.correct-label]="isAnswerCorrect(i)" [currMath]="option"></span>
          </div>
          <div *ngIf="!isAnswerCorrect(i)" class="options-wrapper">
            <div class="margin-5" *ngIf="question.answer_options_competence">
              Lacking: {{ question.answer_options_competence[i] }}
            </div>
            <div class="margin-5" *ngIf="tagsObj && tagsObj[i]">  Misconceptions: {{ tagsObj[i] }}</div>
          </div>
        </div>
        <span *ngIf="question.hint">  Hint: <span [currMath]="question.hint"></span></span>
      </div>
    </div>

    <ng-container *ngIf="
        question?.answer_type === questionType.OPEN_ENDED ||
        question?.answer_type === questionType.FILE_INPUT ||
        question?.answer_type === questionType.SINGLE_ANSWER
      ">
      <div class="mat-h4 m-b-8">Answer:</div>
      <div class="m-b-24">Open answer</div>
      <ng-container *ngIf="question.rubric">
        <span class="mat-h4 m-b-0">Mark scheme:</span>
        <div class="m-b-24" [currMath]="question.rubric || 'No mark scheme'"> </div>
      </ng-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="table;context: {q:question}"></ng-container>

    <ng-container *ngIf="question.answer_type === questionType.MULTIPART">
      <ng-container *ngTemplateOutlet="multipartQuestion"></ng-container>
    </ng-container>

    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex="300px" *ngIf="isCTQuestion">
        <ng-container *ngIf="question.cou_id">
          <div class="mat-h4 m-b-0">
            Confirmation of understanding:
          </div>
          <div class="cou-dropdown" *ngIf="ddCOUQuestions.length">
            <curr-dropdown [options]="ddCOUQuestions" [selectedValue]="question.cou_id">
            </curr-dropdown>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!isCTQuestion" fxLayout="row wrap">
        <div fxFlex="300px" class="m-b-16">
          <ng-container *ngIf="(ddCOUQuestions  | couRight:true).length > 1">
            <div class="mat-h4 m-b-0">
              Confirmation of understanding Right:
            </div>
            <div class="cou-dropdown">
              <curr-dropdown placeholder="Select cou" [options]="ddCOUQuestions | couRight:true"
                [selectedValue]="question.cou_right_id" (valueChange)="onCouSelect($event, 'right')">
              </curr-dropdown>
            </div>
          </ng-container>
          <ng-container *ngIf="(ddCOUQuestions | couRight:false).length > 1">
            <div class="mat-h4 m-t-8">
              Confirmation of understanding Wrong:
            </div>
            <div class="cou-dropdown">
              <curr-dropdown placeholder="Select cou" [options]="ddCOUQuestions | couRight:false"
                [selectedValue]="question.cou_wrong_id" (valueChange)="onCouSelect($event, 'wrong')">
              </curr-dropdown>
            </div>
          </ng-container>
        </div>
        <div *ngIf="question.video_url" fxFlex="100%">
          <div class="mat-h4 m-b-0">
            Explanation:
<!--            <curr-upcoming-lessons-videos [mediaArr]="[{url: question.video_url}]"></curr-upcoming-lessons-videos>-->
            <span [currMath]="question.video_url"></span>
          </div>
        </div>
      </div>


      <div fxLayout="row" *ngIf="isCTQuestion">
        <div fxFlex *ngIf="question.mark">
          <div class="mat-h4 m-b-0 m-r-32" fxLayout="column" *ngIf="question.answer_type !== questionType.MULTIPART">
            Marks:
            <div fxLayout="row">
              <curr-input [isCentered]="true" [isDisabled]="true" fxFlex="56px" [value]="question.mark"></curr-input>
            </div>
          </div>
        </div>
        <div fxFlex *ngIf="question.estimated_time">
          <div class="mat-h4 m-b-0">
            Answer time:
            <div fxLayout="row" fxLayoutAlign="center center">
              <curr-input [isCentered]="true" [isDisabled]="true" fxFlex="56px" [value]="question.estimated_time">
              </curr-input>
              <span class="m-l-8">min.</span>
            </div>
          </div>
        </div>
      </div>
      <div>

      </div>
    </div>
  </mat-card>


  <ng-template #multipartQuestion>
    <div fxLayout="column" fxLayoutAlign="start start" class="full-width"
      *ngFor="let q of question.children;let i = index">
      <ng-container *ngIf="q.children.length">
        <div class="multipart-container full-width">
          <div fxLayout="column">
            <span class="mat-h4"
              [innerHTML]="questionNumber+ '.' + (i+1) + ' ' + (q.previewQuestion || q.question)"></span>
          </div>
          <ng-container *ngFor="let subq of q.children;let j = index">
            <ng-container *ngTemplateOutlet="oneMultipartQuestion;
           context: {q:subq, st: questionNumber + '.' + (i+1)+ '.' + (j+1)}"></ng-container>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!q.children.length">
        <ng-container *ngTemplateOutlet="oneMultipartQuestion;
        context: {q:q, st: questionNumber + '.' + (i+1)}"></ng-container>

      </ng-container>
    </div>

  </ng-template>

  <ng-template #oneMultipartQuestion let-que="q" let-start="st">
    <div class="full-width m-24" fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="space-between start" class="full-width">
        <div fxLayout="column">
          <!--          <span class="mat-h4" [innerHTML]="start + ' ' + (que.previewQuestion || que.question)"></span>-->
          <span class="mat-h4" [currMath]="que.previewQuestion || que.question"></span>
          <span class="mat-h4 m-b-0">Answer:</span>
          <div class="m-b-24">Open answer</div>
          <ng-container *ngTemplateOutlet="table;context: {q:que}"></ng-container>
          <span class="mat-h4 m-b-0">Mark scheme:</span>
          <div class="m-b-24" [currMath]="que.rubric || 'No mark scheme'"></div>
        </div>
        <div class="mat-h4 m-b-0" fxLayout="row">
          <ng-container *ngIf="que.base_type == 'ct'">
            <span class="m-r-8">Marks:</span>
            <div fxLayout="row">
              <curr-input [isCentered]="true" [isDisabled]="true" fxFlex="56px" [value]="que.mark"></curr-input>
            </div>
          </ng-container>
        </div>


      </div>
      <div fxFlex *ngIf="que.video_url">
        <div class="mat-h4 m-b-0">
          Explanation video:
          <curr-upcoming-lessons-videos [mediaArr]="[{url: que.video_url}]"></curr-upcoming-lessons-videos>
        </div>
      </div>
      <mat-divider class="disable-absolute"></mat-divider>
    </div>
  </ng-template>


  <ng-template #table let-question="q">
    <div class="m-b-24 table-container">
      <ng-container *ngIf="question.answer_type === questionType.OPEN_ENDED">
        <div class="mat-h4 m-b-8">
          Keywords:
        </div>
        <curr-table [tableData]="question.oeq_keywords"></curr-table>
      </ng-container>
    </div>
  </ng-template>
</div>
