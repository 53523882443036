/**
 * Created by Maxim B. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { StatisticTable } from './statistic-table';
import { MatTabGroup, MatTabNav } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { Role } from '../../role';
import { StatisticRequestParams, StatisticTableService } from './statistic-table.service';
import { ToastrService } from 'ngx-toastr';
import {
  CoursesFilterOptions,
  CoursesFilterFields,
  SelectCoursesFiltersComponent
} from '../select-courses-filters/select-courses-filters.component';

const tableFields: StatisticTable[] = {
  // @ts-ignore
  STUDENT: {
    fields: [
      { fieldName: 'sa_completion_rate', fieldFilter: 'all' },
      { fieldName: 'predicted_grade', fieldFilter: 'all' },
      { fieldName: 'avg_grade', fieldFilter: 'all' },
      { fieldName: 'prev_week_meetings', fieldFilter: 'all' },
      { fieldName: 'latest_ct', fieldFilter: 'all' },
      { fieldName: 'performance', fieldFilter: 'all' }
    ],
    firstColumn: { fieldName: 'name', fieldFilter: '' },
    name: 'Students'
  },
  STUDENT_UNDERSTANDING: {
    fields: [
      { fieldName: 'lor', fieldFilter: 'all' },
      { fieldName: 'lot', fieldFilter: 'all' },
      { fieldName: 'lou', fieldFilter: 'all' }
    ],
    firstColumn: { fieldName: 'name', fieldFilter: '' },
    name: 'Students understanding'
  },
  TEACHER: {
    fields: [
      { fieldName: 'subject', fieldFilter: 'all' },
      { fieldName: 'predicted_grade', fieldFilter: 'all' },
      { fieldName: 'avg_grade', fieldFilter: 'all' },
      { fieldName: 'prev_week_meetings', fieldFilter: 'all' },
      { fieldName: 'gifted', fieldFilter: 'all' },
      { fieldName: 'on_track', fieldFilter: 'all' },
      { fieldName: 'under_performing', fieldFilter: 'all' }
    ],
    firstColumn: { fieldName: 'name', fieldFilter: '' },
    name: 'Teachers'
  },
  TEACHER_UNDERSTANDING: {
    fields: [
      { fieldName: 'lor', fieldFilter: 'all' },
      { fieldName: 'lot', fieldFilter: 'all' },
      { fieldName: 'lou', fieldFilter: 'all' }
    ],
    firstColumn: { fieldName: 'name', fieldFilter: '' },
    name: 'Teachers understanding'
  }
};

@Component({
  selector: 'curr-user-statistic-table',
  templateUrl: './user-statistic-table.component.html',
  styleUrls: ['./user-statistic-table.component.scss']
})
export class UserStatisticTableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() statistic: [];
  @Input() selectedTabIndex = 0;
  @Input() baseTables: string[] = []; // STUDENT, TEACHER, STUDENT_UNDERSTANDING, TEACHER_UNDERSTANDING
  @Input() set selectedCourseId(value: string) {
    if (value) {
      this.selectFilter[this.coursesFilterFields.course] = value;
      this.preselectedCourseId = value;
    }
  }

  preselectedCourseId: string;

  @ViewChild(SelectCoursesFiltersComponent) tableFiltersRef: SelectCoursesFiltersComponent;
  userRole = Role;
  searchValue = '';
  selectedIndex = 0;
  inCreateTableMode = false;
  isMeetingThisWeek = true;
  isMeetingDropdownOpen = false;
  isInTableDropdownOpen = false;
  isPredictedGradeMenuOpen = false;
  clickedPendingUser = '';
  pendingMenuSubscription: any;
  isDataLoading = false;
  isDataPresented = true;

  customTables: StatisticTable[] = tableFields;

  currentEditedTable: StatisticTable;
  inEditExistingTableMode = false;
  currentEditedTableIndex = -1;
  editNameComparator = -1;
  @ViewChild('nameInput') private nameInput: ElementRef;
  @ViewChild(MatTabGroup) matTabs: MatTabNav;

  // FILTERS - DROPDOWN
  ddOptionsPredictedGrade = [];
  selectFilter: CoursesFilterOptions = {};
  filterData: any;
  coursesFilterFields: CoursesFilterFields = {
    subject: 'subjectName',
    course: 'stCourseId',
    grade: 'studyYearId'
  };

  // TABLE
  STUDENTS_DATA = [];
  TEACHERS_DATA = [];
  STUDENTS_UNDERSTANDING_DATA = [];
  TEACHERS_UNDERSTANDING_DATA = [];
  ELEMENT_DATA = [];
  displayedColumns: string[] = [];
  dataSource: any;

  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('tablePaginator') paginator: MatPaginator;

  constructor(private cdr: ChangeDetectorRef,
              private toastr: ToastrService,
              public dialog: MatDialog,
              public statisticTableService: StatisticTableService) {
  }

  ngOnInit(): void {
    this.selectedIndex = this.selectedTabIndex;
    this.initTableData();
    this.getOptionsForFilters();
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
    }
  }

  initTableData() {
    if (!this.baseTables.length) {
      return;
    }
    this.baseTables.forEach(t => {
      if (t === 'STUDENT') {
        this.getStudentStatistic();
        return;
      }
      if (t === 'STUDENT_UNDERSTANDING') {
        this.getStudentUnderstandingStatistic();
        return;
      }
      if (t === 'TEACHER') {
        this.getTeacherStatistic();
        return;
      }
      if (t === 'TEACHER_UNDERSTANDING') {
        this.getTeacherUnderstandingStatistic();
        return;
      }
    });

    this.ddOptionsPredictedGrade = [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'E', label: 'E' },
      { value: 'F', label: 'F' }
    ];
  }

  updateCurrentTable() {
    // tslint:disable-next-line:triple-equals
    if (this.selectedIndex == 0) {
      this.getStudentStatistic();
      return;
    }
    // tslint:disable-next-line:triple-equals
    if (this.selectedIndex == 1) {
      this.getStudentUnderstandingStatistic();
      return;
    }
    // tslint:disable-next-line:triple-equals
    if (this.selectedIndex == 2) {
      this.getTeacherStatistic();
      return;
    }
    // tslint:disable-next-line:triple-equals
    if (this.selectedIndex == 3) {
      this.getTeacherUnderstandingStatistic();
      return;
    }
  }

  getStudentUnderstandingStatistic() {
    this.showSpinner();
    const o: StatisticRequestParams = {
      tabName: 'STUDENT_UNDERSTANDING',
      rowsCount: 10000,
      rowOffset: 0,
      ...this.selectFilter
    };
    this.statisticTableService.getStatistic(o).subscribe(
      data => {
        if (!data.length) {
          // this.toastr.info('No data to show');
          this.isDataPresented = false;
          return;
        } else {
          this.isDataPresented = true;
          this.STUDENTS_UNDERSTANDING_DATA = [];
          this.STUDENTS_UNDERSTANDING_DATA.push(...data);
        }
      },
      error => {
        this.toastr.error('Failed to load statistic');
      },
      () => {
        this.setCurrentTable();
        this.hideSpinner();
      }
    );
  }

  getTeacherUnderstandingStatistic() {
    this.showSpinner();
    const o: StatisticRequestParams = {
      tabName: 'TEACHER_UNDERSTANDING',
      rowsCount: 10000,
      rowOffset: 0,
      ...this.selectFilter
    };
    this.statisticTableService.getStatistic(o).subscribe(
      data => {
        if (!data.length) {
          // this.toastr.info('No data to show');
          this.isDataPresented = false;
          return;
        } else {
          this.isDataPresented = true;
          this.TEACHERS_UNDERSTANDING_DATA = [];
          this.TEACHERS_UNDERSTANDING_DATA.push(...data);
        }
      },
      error => {
        this.toastr.error('Failed to load statistic');
      },
      () => {
        this.setCurrentTable();
        this.hideSpinner();
      }
    );
  }

  getStudentStatistic() {
    this.showSpinner();
    const o: StatisticRequestParams = {
      tabName: 'STUDENT',
      rowsCount: 10000,
      rowOffset: 0,
      ...this.selectFilter
    };
    this.statisticTableService.getStatistic(o).subscribe(
      data => {
        if (!data.length) {
          // this.toastr.info('No data to show');
          this.isDataPresented = false;
          return;
        } else {
          this.isDataPresented = true;
          this.STUDENTS_DATA = [];
          this.STUDENTS_DATA.push(...data);
          this.setStudentsTableData();
        }
      },
      error => {
        this.toastr.error('Failed to load statistic');
      },
      () => {
        this.setCurrentTable();
        this.hideSpinner();
      }
    );
  }

  getTeacherStatistic() {
    this.showSpinner();
    const o: StatisticRequestParams = {
      tabName: 'TEACHER',
      rowsCount: 10000,
      rowOffset: 0,
      ...this.selectFilter
    };
    this.statisticTableService.getStatistic(o).subscribe(
      data => {
        if (!data.length) {
          // this.toastr.info('No data to show');
          this.isDataPresented = false;
          return;
        } else {
          this.isDataPresented = true;
          this.TEACHERS_DATA = [];
          this.TEACHERS_DATA.push(...data);
          this.setTeacherTableData();
        }
      },
      error => {
        this.toastr.error('Failed to load statistic');
      },
      () => {
        this.setCurrentTable();
        this.hideSpinner();
      }
    );
  }

  setTeacherTableData() {
    this.TEACHERS_DATA.forEach(i => {
      i.on_track = i.on_track > 1 ? i.on_track / 100 || 0 : i.on_track;
      i.under_performing = i.under_performing > 1 ? i.under_performing / 100 || 0 : i.under_performing;
      i.prev_week_meetings = i.prev_week_meetings > 1 ? i.prev_week_meetings / 100 || 0 : i.prev_week_meetings;
    });
    this.displayedColumns = ['name'];
    this.customTables[this.baseTables[this.selectedIndex]].fields.forEach(i => {
      this.displayedColumns.push(i.fieldName + '');
    });
    this.dataSource = new MatTableDataSource(this.TEACHERS_DATA);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setFilterPredicate();
  }

  setStudentsTableData() {
    this.STUDENTS_DATA.forEach(i => {
      i.sa_completion_rate = i.sa_completion_rate > 1 ? i.sa_completion_rate / 100 || 0 : i.sa_completion_rate;
      i.performance = i.performance > 1 ? i.performance / 100 || 0 : i.performance;
    });
    this.displayedColumns = ['name'];
    this.customTables[this.baseTables[this.selectedIndex]].fields.forEach(i => {
      this.displayedColumns.push(i.fieldName + '');
    });
    this.dataSource = new MatTableDataSource(this.STUDENTS_DATA);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setFilterPredicate();
  }

  setStudentsUnderstandingTableData() {
    this.STUDENTS_UNDERSTANDING_DATA.forEach(i => {
      i.lou = i.lou > 1 ? i.lou / 100 || 0 : i.lou;
      i.lor = i.lor > 1 ? i.lor / 100 || 0 : i.lor;
      i.lot = i.lot > 1 ? i.lot / 100 || 0 : i.lot;
    });
    this.displayedColumns = ['name'];
    this.customTables[this.baseTables[this.selectedIndex]].fields.forEach(i => {
      this.displayedColumns.push(i.fieldName + '');
    });
    this.dataSource = new MatTableDataSource(this.STUDENTS_UNDERSTANDING_DATA);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setFilterPredicate();
  }

  setTeachersUnderstandingTableData() {
    this.TEACHERS_UNDERSTANDING_DATA.forEach(i => {
      i.lou = i.lou > 1 ? i.lou / 100 || 0 : i.lou;
      i.lor = i.lor > 1 ? i.lor / 100 || 0 : i.lor;
      i.lot = i.lot > 1 ? i.lot / 100 || 0 : i.lot;
    });
    this.displayedColumns = ['name'];
    this.customTables[this.baseTables[this.selectedIndex]].fields.forEach(i => {
      this.displayedColumns.push(i.fieldName + '');
    });
    this.dataSource = new MatTableDataSource(this.TEACHERS_UNDERSTANDING_DATA);
    this.cdr.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.setFilterPredicate();
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data, filter: string) => {
      return (
        data.name.toLowerCase().indexOf(filter) > -1 ||
        data.subject?.toLowerCase().indexOf(this.selectFilter.subjectName) > -1 ||
        (data.year !== undefined && data.year === this.selectFilter.studyYearId) ||
        (data.form !== undefined && data.form === this.selectFilter.stCourseId)
      );
    };
  }

  filterEntries(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = this.searchValue.length ? this.searchValue : '__________';
  }

  onSelectFilterChange(selectFilter: CoursesFilterOptions) {
    this.selectFilter = selectFilter;

    this.setFilterPredicate();
    this.dataSource.filter = this.searchValue.length ? this.searchValue : '__________'; // If empty string filter won't apply
    this.updateCurrentTable();
  }

  createNewTable() {
    this.customTables.push(new StatisticTable('TABLE ' + this.customTables.length));
    this.inCreateTableMode = true;
    this.editTable(this.customTables.length - 1);
    this.selectedIndex = this.customTables.length - 1;
    this.cdr.detectChanges();
  }

  editTable(index) {
    this.currentEditedTable = this.customTables[index];
    this.inEditExistingTableMode = true;
    this.currentEditedTableIndex = index;
    this.inCreateTableMode = true;
    this.selectedIndex = index;
    this.cdr.detectChanges();
  }

  saveTable(table: StatisticTable) {
    if (this.inEditExistingTableMode && this.currentEditedTableIndex > -1) {
      this.customTables[this.currentEditedTableIndex] = table;
    } else {
      this.customTables.pop();
      this.customTables.push(table);
    }
    this.dropEditModeSettings();
    this.initTableData();
  }

  private dropEditModeSettings() {
    this.currentEditedTable = undefined;
    this.currentEditedTableIndex = -1;
    this.inEditExistingTableMode = false;
    this.inCreateTableMode = false;
  }

  tabSelectedIndexChange(event) {
    this.matTabs.selectedIndex = event;
    this.selectedIndex = event;
    this.cdr.detectChanges();
    this.updateCurrentTable();

    this.tableFiltersRef.initOptionsForFilters();
  }

  setCurrentTable() {
    if (this.selectedIndex === 0) {
      this.setStudentsTableData();
    }
    if (this.selectedIndex === 1) {
      this.setStudentsUnderstandingTableData();
    }
    if (this.selectedIndex === 2) {
      this.setTeacherTableData();
    }
    if (this.selectedIndex === 3) {
      this.setTeachersUnderstandingTableData();
    }
  }

  tabMenuClick($event: MouseEvent, i: number, action: string, menu: any) {
    $event.stopPropagation();
    if (action === 'delete') {
      if (!this.inCreateTableMode) {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
          width: '400px',
          data: {
            questionTitle: 'Are you sure in deleting?',
            questionDescription: 'If you delete this table - you will be unable to recover it'
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.customTables.splice(i, 1);
          }
        });
      }
    }
    if (action === 'edit') {
      if (this.inCreateTableMode) {
        this.dropEditModeSettings();
        this.selectedIndex = 0;
      } else {
        this.editTable(i);
      }
    }
    if (action === 'rename') {
      this.editNameComparator = i;
      setTimeout(() => this.nameInput.nativeElement.focus(), 1);
    }
    menu.close.emit();
  }

  editNameOnLostFocus() {
    this.editNameComparator = -1;
  }

  cancelCreateTable(table: StatisticTable) {
    if (!this.inEditExistingTableMode) {
      this.customTables.pop();
    }
    if (!table.fields.length) {
      this.customTables.splice(this.currentEditedTableIndex, 1);
    }
    this.dropEditModeSettings();
  }

  selectMeetingWeek(b: boolean) {
    this.isMeetingDropdownOpen = false;
    this.isMeetingThisWeek = b;
    this.dataSource.filterPredicate = (data, filter: string) => {
      return data.meetings.toLowerCase().includes(filter);
    };
    this.dataSource.filter = this.isMeetingThisWeek ? 'yes' : 'pending';
  }

  getOptionsForFilters() {
    this.statisticTableService.getFilters().subscribe(resp => {
      this.filterData = resp;
    });
  }

  showSpinner() {
    this.isDataLoading = true;
  }

  hideSpinner() {
    this.isDataLoading = false;
  }

  setMeetingToDone(element: any, status: string) {
    const st = this.isMeetingThisWeek ? 'THIS_WEEK' : 'PREV_WEEK';
    this.isInTableDropdownOpen = false;
    this.statisticTableService.setMeeting(element.course_student_id, st).subscribe(resp => {
      element.prev_week_meetings = status;
    });
  }

  clickPending($event: any, elem: any, menu: any) {
    this.clickedPendingUser = elem.name;
    $event.stopPropagation();
    this.isInTableDropdownOpen = true;
    this.pendingMenuSubscription = menu.close.subscribe(i => {
      this.clickedPendingUser = '';
      this.isInTableDropdownOpen = false;
    });
  }

  ngOnDestroy(): void {
    if (this.pendingMenuSubscription) {
      this.pendingMenuSubscription.unsubscribe();
    }
  }

  setPredictedGrade(grade: string, el: any) {
    if (this.selectedIndex == 0) {
      this.statisticTableService.setPredictedGradeForStudent(el.course_student_id, grade).subscribe(resp => {
        el.predicted_grade = grade;
      });
    }
    if (this.selectedIndex == 2) {
      this.statisticTableService.setPredictedGradeForTeacher(el.curriculum_teacher_id, grade).subscribe(resp => {
        el.predicted_grade = grade;
      });
    }
  }

  clickPredictedGrade($event: MouseEvent, elem: any) {
    this.clickedPendingUser = elem.name;
    $event.stopPropagation();
    this.isPredictedGradeMenuOpen = true;
  }

  closePredictedGradeMenu() {
    this.isPredictedGradeMenuOpen = false;
    this.clickedPendingUser = '';
  }

  courseIdChanged(courseId: string) {
    // this.statisticTableService.courseId = courseId;
    this.initTableData();
  }
}
