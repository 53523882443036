<div class="list-wrapper">
  <curr-loader [isLoading]="loading" [diameter]="48"></curr-loader>
  <div @fade *ngIf="!loading">
    <div fxLayout="column" class="list" *ngIf="questionsList?.length; else emptyList">
      <div class="list--item" *ngFor="let item of questionsList" fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="70%" class="list--name p-r-8" [innerHTML]="item.question"></div>
      </div>
    </div>
  </div>

  <ng-template #emptyList>
    <p>Question list is empty</p>
  </ng-template>
  <div class="m-t-16 m-b-8" fxLayout="row" fxLayoutAlign="end center">
    <curr-button size="big"  (click)="closeDialog()" [variant]="'text'">CLOSE</curr-button>
  </div>
</div>