/*
 * Created by Dmytro Sav. on 5/4/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {Component, OnChanges, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentCheckTestService } from './student-check-test.service';
import {take, takeUntil} from 'rxjs/operators';
import { PaginationLabel } from '../../shared/components/question-pagination/question-pagination.component';
import { TeacherService } from '../services/teacher.service';
import { ReviewQuestion, SubjectTestSelectTopic } from './questions-page.interface';
import {IFrequentlyLeftUnansweredQuestion, IMostIncorrectQuestions, IMostLostMarks} from '../interfaces';
import {CourseDetailsService} from '../services/course-details.service';
import {ETestType} from '../teacher.enum';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import { IUnderlyingFactors } from '../interfaces/underlying-factors';
import { MatDialog } from '@angular/material/dialog';
import { ShareConfirmationPopupComponent } from './share-confirmation-popup/share-confirmation-popup.component';

@Component({
  selector: 'curr-questions-page',
  templateUrl: './questions-page.component.html',
  styleUrls: ['./questions-page.component.scss']
})
export class QuestionsPageComponent implements OnInit, OnChanges, OnDestroy {

  currAnswersIndex = 0;
  courseId;
  path = [];
  test: any;
  isCumulativeTests: boolean;
  isSA: boolean;
  questions: ReviewQuestion[] = [];
  students: any;
  checkedPage = [];
  studentAnswers = [];
  incorrectlyAnsweredQuestions: any[] = [];
  cou;
  paginationLabels: PaginationLabel[] = [];
  selectedLabel: PaginationLabel;
  idAndIndexInQuestionArray: { id: number; index: number }[] = [];
  currentQuestion: ReviewQuestion;
  rawQuestions: ReviewQuestion[] = [];
  curriculumId$;
  curriculumId;
  learningObjectiveId;
  paperId;
  paperTopics: SubjectTestSelectTopic[];
  frequentlyLeftUnansweredQuestions: IFrequentlyLeftUnansweredQuestion[];
  frequentlyIncorrectQuestions: IFrequentlyLeftUnansweredQuestion[];
  questionsTypesWhereMostMarksAreLost: IMostLostMarks;
  currSelectedTabIndex = 0;
  tabLabels = ['Questions', 'Students'];
  studentsWithQuestions: any[] = [];

  mostFrequentlyLeftTabsLabels = ['Left Unanswered', 'Incorrect'];
  currMostFrequentlyLeftTabsIndex = 0;

  mostIncorrectQuestions: IMostIncorrectQuestions;
  underlyingFactors: IUnderlyingFactors;

  testLabel: string;
  testTypeValue: string;
  testTypeLink: string;

  answersForApprove = [];
  answersForApproveBtn = false;
  answersReviewed = 0;
  questionsAmount = 0;
  modeType: string;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private courseDetailsService: CourseDetailsService,
    private route: ActivatedRoute,
    private router: Router,
    private testService: StudentCheckTestService,
    private teacherService: TeacherService,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {
  }

  ngOnChanges(): void {
    this.setCheckedQuestions();
  }

  ngOnInit() {
    const testTypeParam = this.route.snapshot.params.testType;
    this.testLabel = 'Cumulative Test';
    this.testTypeValue = ETestType.CUMULATIVE_TEST;
    this.testTypeLink = 'cumulative-test';

    if (testTypeParam === 'homework') {
      this.testLabel = 'Homework';
      this.testTypeValue = ETestType.HOMEWORK;
      this.testTypeLink = 'homework';
    }
    if (testTypeParam === 'quiz') {
      this.testLabel = 'Quiz';
      this.testTypeValue = ETestType.QUIZ;
      this.testTypeLink = 'quiz';
    }

    this.getCurriculumId();
    this.courseId = this.route.snapshot.params.courseId;
    this.learningObjectiveId = this.route.snapshot.params.ctId;
    this.modeType = this.route.snapshot.params.modeType;
    this.paperId = this.route.snapshot.params.paperId;
    this.isCumulativeTests = this.route.snapshot.data.type === 'ct';
    this.test = this.route.snapshot.data.tests.data;
    this.paperTopics = this.route.snapshot.data.topics;
    this.incorrectlyAnsweredQuestions = this.route.snapshot.data.incorrectlyAnsweredQuestions.data.items || [];
    this.rawQuestions = this.test?.questionInfoList.sort((a, b) => a.id - b.id);
    this.flattenQuestions(this.rawQuestions);
    this.students = this.test?.studentInfoMap;
    this.route.params.pipe(take(1)).subscribe(res => {
      this.courseId = res?.courseId;
    });
    this.initStudentAnswers();
    this.setCheckedQuestions();
    this.isSA = this.router.url.includes('sa');
    this.initBreadcrumb();
    this.initStudentsWithQuestions();
    this.getMostFrequentlyLeftUnansweredQuestions();
    this.getMostFrequentIncorrectQuestions();
    this.getTypesOfQuestionsWithMostLostMarks();
    this.getTtMostIncorrectQuestions();
    this.getUnderlyingFactors();
    this.checkReviewedQuestions();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get isNotViewMode() {
    return this.modeType !== 'view';
  }

  checkReviewedQuestions() {
    this.studentAnswers.forEach(item => {
      const isPlus = item.filter(q => q.reviewState === 'APPROVED');
      if (isPlus.length > 0) this.answersReviewed++;
    });
  }

  createAnswersForApprove() {
    this.answersForApprove = this.studentAnswers[this.currAnswersIndex].map(item => {
      return {
        aiMark: {
          highlights: item.highlights,
          insights: item.insights
        },
        mark: item.mark,
        responseType: '',
        studentCumTestQuestionId: item.answerId,
        teacherComment: item.textAnswer,
        reviewState: item.reviewState
      }
    });

    this.answersForApproveBtn = this.answersForApprove.filter(item => item.reviewState !== 'APPROVED').length > 0;
  }

  changeApproveList(data: any, j: number) {
    this.answersForApprove[j] = data;
  }

  approveOne(data: any, j: number) {
    this.answersForApprove[j] = data;
    this.studentAnswers[this.currAnswersIndex][j].reviewState = 'APPROVED';

    const isPlus = this.studentAnswers[this.currAnswersIndex].filter(item => item.reviewState !== 'APPROVED').length;
    if (isPlus === 0) {
      this.answersReviewed++;
      this.answersForApproveBtn = false;
    }
    this.studentsWithQuestions[j].questionsWithAnswers[this.currAnswersIndex].newStudentAnswers.reviewState = 'APPROVED';
  }

  initStudentsWithQuestions(): void {
    const students = Object.keys(this.test.studentInfoMap).map((key) => [{key, data: this.test.studentInfoMap[key], questionsWithAnswers: [], hasAnswers: false}]);

    const studentsWithAnswers = [];
    const studentsHasQuestions = students.map(item => {
      this.questions.forEach((q, i) => {
        if (!q.answers[item[0].key]) {
          return undefined;
        }

        if (!studentsWithAnswers.includes(item[0].key)) studentsWithAnswers.push(item[0].key)
      });

      return item[0];
    });

    const filteredStudents = studentsHasQuestions.filter(item => studentsWithAnswers.includes(item.key));

    if (filteredStudents.length > 0) {
      let cloneQuestions = this.rawQuestions;
      this.studentsWithQuestions = filteredStudents.map(item => {
        const listWithAnswers = [];

        cloneQuestions.forEach((qFirstLevel, i) => {
          if (qFirstLevel.children.length > 0) {
            qFirstLevel.children.forEach((qSecond, j) => {

              if (qSecond.children.length > 0) {
                qSecond.children.forEach((qThird, k) => {
                  const c = this.createStudentsWithQuestions(qThird, item.key);
                  if (c) {
                    qThird.newStudentAnswers = c;
                    qFirstLevel.children[j].children[k] = qThird;
                  }
                });
              }

              const b = this.createStudentsWithQuestions(qSecond, item.key);
              if (b) {
                qSecond.newStudentAnswers = b;
                qFirstLevel.children[j] = qSecond;
              }
            });
          }
  
          const a = this.createStudentsWithQuestions(qFirstLevel, item.key);
          if (a) {
            qFirstLevel.newStudentAnswers = a;
          }

          listWithAnswers.push(qFirstLevel);
        });
  
        item.questionsWithAnswers = listWithAnswers.length > 0 ? listWithAnswers : [];
        return item;
      });
      
      return;
    }

    this.studentsWithQuestions = [];
  }

  private createStudentsWithQuestions(questionData: ReviewQuestion, key: string) {
    if (!questionData.answers[key]) {
      return undefined;
    }
    return {
      question: questionData,
      answers: questionData.answerOptions,
      student: this.students[key],
      result: '',
      mark: questionData.answers[key].resultMark,
      correctAnswer: questionData.correctAnswerOptions,
      isAnswered: questionData.answers[key].resultMark
        || questionData.answers[key].studentAnswerText
        || questionData.answers[key].studentAnswerFile
        || questionData.answers[key].answerSolution
        || questionData.answers[key].studentCorrectAnswerOptions?.length > 0,
      cou: '',
      reply: '',
      selfMark: questionData.answers[key].selfMark,
      aiMark: questionData.answers[key].aiMark,
      reviewState: questionData.answers[key].reviewState,
      textAnswer: questionData.answers[key].textAnswer,
      answerId: questionData.answers[key].id,
      highlights: questionData.answers[key].highlights,
      insights: questionData.answers[key].insights,
      key
    };
  }

  getMostFrequentlyLeftUnansweredQuestions() {
    this.courseDetailsService
      .getMostFrequentlyLeftUnansweredQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.frequentlyLeftUnansweredQuestions = data));
  }

  getMostFrequentIncorrectQuestions() {
    this.courseDetailsService
      .getMostFrequentIncorrectQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.frequentlyIncorrectQuestions = data));
  }

  getTypesOfQuestionsWithMostLostMarks() {
    this.courseDetailsService
      .getTypesOfQuestionsWithMostLostMarks(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.questionsTypesWhereMostMarksAreLost = data));
  }

  getTtMostIncorrectQuestions() {
    this.courseDetailsService
      .getTtMostIncorrectQuestions(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.mostIncorrectQuestions = data));
  }

  getUnderlyingFactors() {
    this.courseDetailsService
      .getUnderlyingFactors(this.courseId, this.learningObjectiveId)
      .subscribe(data => (this.underlyingFactors = data));
  }

  flattenQuestions(rawQuestions: ReviewQuestion[], parent?: ReviewQuestion, grandParent?: boolean) {
    if (rawQuestions) {
      rawQuestions.forEach((q, i) => {
        q.questionLevel = 0;

        if (parent && parent.subInstruction && grandParent) {
          q.questionLevel = 2;
          // q.subSecondInstruction = grandParent.question;
        }

        if (parent) {
          q.questionLevel = 1;
          // q.subInstruction = parent.question;
        }

        this.questions.push(q);
        // this.idAndIndexInQuestionArray.push({ id: q.id, index: this.questions.indexOf(q) });

        if (q.children.length === 0) this.questionsAmount++;

        if (q.children?.length) {
          if (q.questionLevel === 0) {
            this.flattenQuestions(q.children, q);
          } else if (q.questionLevel === 1) {
            this.flattenQuestions(q.children, q, true);
          }
        }
      });
    }
  }

  initStudentAnswers() {
    if (!this.test || !this.questions.length || !this.students) {
      return;
    }
      this.questions.forEach((q, i) => {
        const answersArr = [];

        // tslint:disable-next-line:forin
        for (const key in this.students) {
          const a = this.createStudentAnswerForQuestion(key, i);
          if (a) answersArr.push(a);
        }
        this.studentAnswers[i] = answersArr;
      });
  }

  initBreadcrumb() {
    this.path = [
      { label: 'Home', url: '/dashboard' },
      { label: 'Courses', url: '/subjects' }
    ];
    this.route.snapshot.data.type === 'ct'
      ? this.path.push(
      { label: this.testLabel, url: `/courses/${ this.testTypeLink }/${ this.courseId }/${ this.paperId }` })
      : this.path.push({
        label: 'Self Assessments Tests',
        url: `/self-assessment/${ this.courseId }/${ this.paperId }`
      });
    if (this.test?.learningObjective?.length) {
      this.path.push({ label: this.test.learningObjective.length > 52 ? this.test.learningObjective.substring(0, 52) + ' ...' : this.test.learningObjective, url: '' });
    } else if (this.test.testName.length) {
      this.path.push({ label: this.test.testName.length > 52 ? this.test.testName.substring(0, 52) + ' ...' : this.test.testName, url: '' });
    }
  }

  saveReview() {
    const dialogRef = this.dialog.open(ShareConfirmationPopupComponent, {
      width: '424px',
      data: {
        title: 'Share Feedback with Students?',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result) {
          this.testService.setCTTestReviewed(this.test.id, this.courseId).subscribe(resp => {
            this.modeType = 'view';
            this.toastr.success('The results were shared successfully');
            this.router.navigate([`report/${this.testTypeLink}/${this.courseId}/${this.learningObjectiveId}/${this.paperId}/view`]);
          });
        }
      });
  }

  private createStudentAnswerForQuestion(key: string, i: number, key2: string = '') {
    if (!this.questions[i].answers[key]) {
      return undefined;
    }
    return {
      question: this.questions[i],
      answers: this.questions[i].answerOptions,
      student: this.students[key] || this.students[key2],
      result: '',
      mark: this.questions[i].answers[key].resultMark,
      correctAnswer: this.questions[i].correctAnswerOptions,
      isAnswered: this.questions[i].answers[key].resultMark
        || this.questions[i].answers[key].studentAnswerText
        || this.questions[i].answers[key].studentAnswerFile
        || this.questions[i].answers[key].answerSolution
        || this.questions[i].answers[key].studentCorrectAnswerOptions?.length > 0,
      cou: '',
      reply: '',
      selfMark: this.questions[i].answers[key].selfMark,
      aiMark: this.questions[i].answers[key].aiMark,
      reviewState: this.questions[i].answers[key].reviewState,
      textAnswer: this.questions[i].answers[key].textAnswer,
      answerId: this.questions[i].answers[key].id,
      highlights: this.questions[i].answers[key].highlights,
      insights: this.questions[i].answers[key].insights,
      key
    };
  }

  changedPage($event: number) {
    this.currAnswersIndex = $event - 1;
  }

  private setCheckedQuestions() {
    this.checkedPage = [];
    if (this.questions.length) {
      this.questions.forEach((q, i) => {
        let checked = true;
        for (const key in q.answers) {
          if (!q.answers[key].resultMark || q.answers[key].resultMark === 0) {
            checked = false;
          }
        }
        if (checked) {
          this.checkedPage.push(i);
        }
      });
    } else {
      this.checkedPage = [];
    }
  }

  paginationQuestionSelect(id: string) {
    if (this.questions.length) {
      const index = this.questions.findIndex(i => i.id + '' === id);
      if (index > -1) {
        this.currentQuestion = this.questions[index];
        this.currAnswersIndex = index;
        if (this.currentQuestion.children.length === 0) {
          this.createAnswersForApprove();
        } else {
          this.answersForApproveBtn = false;
        }
      }
    }
    if (this.paginationLabels.length) {
      this.selectedLabel = this.paginationLabels.find(i => i.id + '' === id);
    }
  }

  paginationLabelsReady(labels: PaginationLabel[]) {
    this.paginationLabels = labels;
  }

  getCurriculumId(): void {
    this.curriculumId$ = this.teacherService.curriculumId$;
    this.curriculumId = this.curriculumId$._value;
  }

  approveAllQuestion(): void {
    this.answersForApprove = this.answersForApprove.filter(item => item.reviewState !== 'APPROVED');
    if (this.answersForApprove.length === 0) return;

    this.testService.approveAnswer(this.answersForApprove).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.answersForApprove = this.answersForApprove.map(item => {
        item.reviewState = 'APPROVED';
        return item;
      });

      this.studentAnswers[this.currAnswersIndex] = this.studentAnswers[this.currAnswersIndex].map(item => {
        item.reviewState = 'APPROVED';
        return item;
      });

      this.answersForApproveBtn = false;
      this.answersReviewed++;

      this.toastr.success('The all answers of this question were approved successfully');

      this.studentsWithQuestions = this.studentsWithQuestions.map(item => {
        if (item.questionsWithAnswers) {
          item.questionsWithAnswers = item.questionsWithAnswers.map(question => {
            question.newStudentAnswers.reviewState = 'APPROVED';
            return question;
          });
        }

        return item;
      });
    });
  }
}
