import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChooseYearService {
  private chosenYear$: BehaviorSubject<any> = new BehaviorSubject(null);
  public setHighlight$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  constructor() { }

  get getChosenYear(): BehaviorSubject<any> {
    return this.chosenYear$;
  }

  changeYear(year): void {
    this.chosenYear$.next(year);
  }
}
