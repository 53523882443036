/**
 * Created by Maxim B. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { Injectable } from '@angular/core';

@Injectable()
export class SubjectInfoResolver implements Resolve<any> {
  constructor(private httpClient: ApplicationHttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const schoolYearCourseId = route.paramMap.get('subjectId');
    const curriculumId = route.paramMap.get('curriculumId');
    const param = curriculumId ? `?curriculum_id=${curriculumId}` : '';
    return this.httpClient.get(`/schoolYearCourses/${schoolYearCourseId}${param}`);
  }
}
