<!-- Created by Maxim B. on 26/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="container">
  <div class="m-b-40">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <h1 class="mat-h1 m-b-32">
    Please complete this short section to help us personalise Curriculum.com for your school:
  </h1>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="m-b-32" fxLayoutGap="16px">
    <curr-input [isBordered]="false" placeholder="Search" fxFlex (keyup)="applyFilter($event)"></curr-input>
    <curr-button variant="contained" fxFlex="200px">DOWNLOAD .CSV</curr-button>
    <curr-button variant="contained" fxFlex="200px">IMPORT UPDATED .CSV</curr-button>
  </div>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" aria-describedby="desc">
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef scope="col">Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}:</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Selection</th>
      <td mat-cell *matCellDef="let element" class="value-column">
        <ng-container *ngIf="!element.isEditing">
          <span class="element-value">{{ element.customValue || element.value }}</span></ng-container
        >
        <div *ngIf="element.isEditing" fxLayout="row" fxLayoutAlign="start center">
          <curr-dropdown
            [options]="element.options"
            [selectedValue]="element.value"
            class="dropdown m-r-8"
            (valueChange)="selectValue($event, element)"
            fxFlex
          ></curr-dropdown>
          <curr-input
            class="custom-val"
            placeholder="Custom"
            fxFlex
            *ngIf="element.value === 'Other'"
            [(value)]="element.customValue"
          ></curr-input>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef class="edit-btn" scope="col"></th>
      <td mat-cell *matCellDef="let element">
        <div class="btn-container" fxLayout="row" fxLayoutAlign="center center">
          <curr-button
            variant="contained"
            class="edit-btn"
            [isDisabled]="element.value === 'Other' && element.customValue === ''"
            (click)="editItem(element)"
            >{{ element.isEditing ? 'SAVE' : 'EDIT' }}</curr-button
          >
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
