/**
 * Created by Alex Poh. on 15/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { PaperOld, TopicOld, TopicGroup } from '../subject-details';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import * as d3 from 'd3';

@Component({
  selector: 'curr-gantt-chart-d3',
  templateUrl: './gantt-chart-d3.component.html',
  styleUrls: ['./gantt-chart-d3.component.scss']
})
export class GanttChartD3Component implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('chart') chart: ElementRef;
  paper: PaperOld;
  num: number;
  destroy$: Subject<boolean> = new Subject<boolean>();
  width = 1300;
  height = 850;
  barHeight = 48;
  margin = {
    right: 0,
    left: 0,
    top: 75,
    bottom: 0
  };
  svg;

  ganttChartData = [];
  selectedTopic: TopicOld;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.num = parseInt(val.paperId, 10);
      this.paper = this.route.snapshot.parent?.data.subjectDetails.papers[this.num - 1];
      // this.ganttChartData = this.paper?.topicGroups[0].topics;
      this.paper?.topicGroups.forEach((tg: TopicGroup, i) => {
        this.ganttChartData.push(...tg.topics);
        // this.ganttChartData.push([]);
      });
    });
  }

  ngAfterViewInit(): void {
    this.ganttChartData = this.prepareData(this.ganttChartData);
    this.initGantt();
    this.selectTopic(this.paper?.topicGroups[0].topics[0], 0);
  }

  prepareData(data: TopicOld[]) {
    const parseTime = d3.timeParse('%d-%m-%Y');
    return data.map((val: TopicOld) => {
      const result = { ...val };
      result.startDate = parseTime(result.startDate);
      result.endDate = parseTime(result.endDate);
      return result;
    });
  }

  initGantt() {
    this.svg = d3.select(this.chart.nativeElement);
    this.svg.attr('viewBox', [0, 0, this.width, this.height]);
    const x = d3.scaleLinear().range([this.margin.left, this.width - this.margin.right]);

    // let y = this.d3
    //   .scaleBand()
    //   .domain(this.d3.range(this.ganttChartData.length))
    //   .rangeRound([this.margin.top, this.height - this.margin.bottom])
    //   .padding(0.1);

    const xAxis = g =>
      g
        .attr('transform', `translate(75,${ this.margin.top - 50 })`)
        .call(
          d3
            .axisTop(
              x.domain([
                0,
                (d3.max(this.ganttChartData.map(res => res.endDate)) -
                  d3.min(
                    this.ganttChartData.map(res => {
                      return res.startDate;
                    })
                  )) /
                (1000 * 60 * 60 * 24)
              ])
            )
            .tickFormat(i => {
              // if (i > 0) {
              //   return `${i} LES.`;
              // }
              return `${ i + 1 } LES.`;
            })
            .tickSize(0)
            .tickSizeOuter(0)
        )
        .call(j => j.select('.domain').remove());

    const xAxis2 = g =>
      g
        .attr('transform', `translate(75,${ this.height - 50 })`)
        .call(
          d3
            .axisBottom(
              x.domain([
                0,
                (d3.max(this.ganttChartData.map(res => res.endDate)) -
                  d3.min(
                    this.ganttChartData.map(res => {
                      return res.startDate;
                    })
                  )) /
                (1000 * 60 * 60 * 24)
              ])
            )
            .tickFormat(i => {
              const startDate = d3.min(
                this.ganttChartData.map(res => {
                  return res.startDate;
                })
              );
              const resultDate = this.addDays(startDate, i);
              return (
                d3.timeFormat('%d')(resultDate) +
                ' ' +
                d3
                  .timeFormat('%B')(resultDate)
                  .substring(0, 3)
              );
            })
            .tickSize(0)
            .tickSizeOuter(0)
        )
        .call(j => j.select('.domain').remove());
    this.svg
      .append('g')
      .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')')
      .attr('fill', 'var(--theme-color-grey-3)')
      .selectAll('rect')
      .data(this.ganttChartData)
      .join('rect')
      .attr('x', d => {
        return x(
          ((d.startDate - d3.min(this.ganttChartData.map(res => res.startDate))) / (1000 * 60 * 60 * 24) / 1000) *
          50 *
          2
        );
      })
      .attr('y', (d, i) => {
        // return y(i);
        return i * this.barHeight;
      })
      // .attr('width', d => this.x(d.endDate) - this.x(d.startDate))
      .attr('width', d => {
        return x(((d.endDate - d.startDate) / (1000 * 60 * 60 * 24) / 1000) * 50 * 2);
      })
      .attr('height', this.barHeight)
      .attr('id', (d, i) => `bar-${ i }`)
      .attr('class', 'bar');
    this.svg.append('g').call(xAxis);
    this.svg.append('g').call(xAxis2);
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  selectTopic(topic, i) {
    if (topic?.startDate) {
      this.selectedTopic = topic;
      this.svg.selectAll('rect').attr('class', null);
      this.svg.select(`#bar-${ i }`).attr('class', 'selected-bar');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
