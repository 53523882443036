<!-- Created by Maxim B. on 20/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="students-statistic-table">
  <div class="loader-container" *ngIf="isDataLoading">
    <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
  </div>
  
  <!-- <div class="drop-down-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="drop-downs" fxLayout="row" fxLayoutAlign="start center">

      <curr-select-courses-filters [preselectedCourseId]="preselectedCourseId" [filterOptionsData]="filterData"
        [filterFields]="coursesFilterFields" (onFilterChange)="onSelectFilterChange($event)">
      </curr-select-courses-filters>
    </div>

    <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
      <curr-input class="curr-input" [isBordered]="false" placeholder="Search" fxFlex="50"
        (keyup)="filterEntries($event)"></curr-input>
      <mat-icon [svgIcon]="'search_black'"></mat-icon>
    </div>
  </div> -->

  <ng-container *ngIf="isDataPresented">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
        <ng-container matColumnDef="teacherName">
          <th mat-header-cell *matHeaderCellDef scope="col">Teacher Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="user-name" (click)="navigateTo('/profile/' + element.teacherId)">{{ element.teacherName }}</div>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 1">
          <ng-container>
            <th mat-header-cell *matHeaderCellDef scope="col">
              {{ 'dropdownItemLabels.' + item | translate }}
            </th>
          </ng-container>

          <td mat-cell *matCellDef="let element">
            <ng-container>
              {{element[item]}}
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <curr-table-pagination
      [totalResults]="resultsLength"
      [pageSize]="20"
      [currentPageIndex]="currentPageIndex"
      [showingText]="'teachers'"
      (changePage)="onPageChange($event)">
    </curr-table-pagination>
  </ng-container>

  <ng-container *ngIf="!isDataPresented">
    <div class="no-data-container">
      <img src="/assets/images/colored/amico.svg" />
      <div class="no-data-title">Please invite your teachers to view this data</div>
      <div class="no-data-subtitle">Please share your course ID with teachers</div>
    </div>
  </ng-container>
</div>
