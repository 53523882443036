<!-- Created by Maxim B. on 17/06/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->


<ng-container *ngFor="let lessonPlan of lessonPlans; index as i">
  <div *ngIf="lessonPlan.wopi_url" class="lesson-plan-container m-t-32">
    <div class="mat-h2">Lesson {{ lessonPlan.sequence_num }}</div>
    <curr-loader *ngIf="!isDisplayFrame" [isLoading]="true" [diameter]="48" style="display: block;" class="m-b-24"></curr-loader>
    <iframe [style]="{ display: isDisplayFrame ? 'block' : 'none' }" width="100%" height="auto" [src]="wopiUrls[lessonPlan.id]" title="" tabindex="-1"></iframe>
    <div class="topics-covered-container">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="column" fxLayoutAlign="start start" *ngIf="!inEditTopicCoveredMode">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <span class="mat-body-2 m-r-8">Learning objectives covered:</span>
            <ng-container *ngFor="let t of lessonPlan.lesson_plan_lobj_ids; let i = index">
              <ng-container *ngIf="getTopicById(t) as topicFromList">
                <a class="link  m-r-8" (click)="learningObjectiveChanged.emit(topicFromList)">
                  {{ topicFromList.title }}{{ i + 1 == learningObjectives.length ? '' : ', ' }}</a
                >
              </ng-container>
            </ng-container>
            <span *ngIf="!lessonPlan.lesson_plan_lobj_ids || lessonPlan.lesson_plan_lobj_ids.length === 0">None</span>
          </div>
          <div fxLayout="row wrap" fxLayoutAlign="start center" class="m-t-8">
            <span class="mat-body-2 m-r-8">Level:</span>
            <span>{{ lessonPlan.difficulty_level }}</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="start start" *ngIf="inEditTopicCoveredMode">
          <span class="mat-body-2">Learning objectives:</span>
          <curr-dropdown-component-v2
            *ngIf="coveredTopicsOptions.length"
            class="topic-dropdown"
            [options]="coveredTopicsOptions"
            [isMultiSelected]="true"
            [(selectedValue)]="lessonPlan.lesson_plan_lobj_ids"
            placeholder="Topics"
          ></curr-dropdown-component-v2>
          <span class="mat- body-2 m-t-8">Levels:</span>
          <curr-dropdown-component-v2
            *ngIf="levels.length"
            class="dropdown"
            [options]="levels"
            [isMultiSelected]="false"
            placeholder="Levels"
            [(selectedValue)]="lessonPlan.difficulty_level"
          ></curr-dropdown-component-v2>
        </div>

        <curr-button variant="text" *ngIf="!inEditTopicCoveredMode" (click)="inEditTopicCoveredMode = true"
          >EDIT
        </curr-button>
        <curr-button variant="text" *ngIf="inEditTopicCoveredMode" (click)="updateLessonPlan(lessonPlan)"
          >SAVE
        </curr-button>
      </div>
      <div class="m-t-16 m-b-16">
        <curr-upload-files
          *ngIf="inEditTopicCoveredMode"
          class="add-paddings"
          type="edit-resource"
          [hint]="''"
          title="Drop file here"
          [displayPhotoBtn]="false"
          [automaticallyImport]="false"
          [formats]="['.doc', '.docx', '.pptx', '.ppt']"
          [enableProgressbard]="false"
          (onloadedFile)="onLoadedFileToUpdate($event, lessonPlan)"
        ></curr-upload-files>
      </div>
      <curr-button variant="text" *ngIf="inEditTopicCoveredMode" (click)="delete(i)">
        DELETE
      </curr-button>
    </div>
  </div>
</ng-container>
<div class="m-t-16 text-centered" *ngIf="lessonPlans.length === 0">
  No content
</div>

<curr-button class="m-t-32" (btnClick)="isAdding = true">ADD</curr-button>
<ng-container *ngIf="isAdding">
  <div class="m-t-16 m-b-16">
    <curr-upload-files
      class="add-paddings"
      type="edit-resource"
      [hint]="''"
      title="Drop file here"
      [displayPhotoBtn]="false"
      [automaticallyImport]="false"
      [formats]="['.doc', '.docx', '.pptx', '.ppt']"
      [enableProgressbard]="false"
      (onloadedFile)="onLoadedFile($event)"
    ></curr-upload-files>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <curr-button (btnClick)="addLessonPlan()" fxFlex="400px">Upload</curr-button>
  </div>
</ng-container>
