/**
 * Created by Alex Poh. on 26/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { Histogram } from '../../graphs/school-performance-histogram/series.model';
import { TimePeriod } from '../../../hub-admin/hub-admin-dashboard/hub-admin-school-statistic/time-period';
import { Options } from '../dropdown/dropdown.component';
import { ChartPeriod, DashboardChartService } from './dashboard-chart.service';
import { DataGroup, TeacherChartData } from './chart-data';
import { ChartFilter } from '../../../shared-pages/course-filters/course-filters';
import { StudentChartService } from '../../../student/student-chart.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../user.service';
import { User } from '../../user';
import { DashboardGradeStatistic } from '../school-admin-chart/dashboard-grade-statistic';
import { Observable } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { fadeAnimation } from '../../../shared/animations/fade.animation';

@Component({
  selector: 'curr-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
  animations: [fadeAnimation]
})
export class DashboardChartComponent implements OnInit {
  shouldAppear: boolean = false;
  displayData$: Subject<Histogram> = new BehaviorSubject<Histogram>({ series: [], grades: null });
  @Input()
  width = 363;

  @Input()
  noDataImage: string;

  @Input()
  areFiltersPresented = true;

  selectedFiler: any = {};

  @Input()
  set courseId(courseId: string) {
    this.selectedFiler.course_id = courseId;
  }

  @Input()
  studentId: number = null;

  filters: ChartFilter;

  currentPeriodIndex = 0;

  options: Options[];
  dashboardData: DashboardGradeStatistic[];

  generalGraphData: TeacherChartData;
  isLoading = true;
  currentGraphData: DataGroup;
  currentPeriodName: string = 'year';
  ChartPeriod = ChartPeriod;
  userSubscription: Subscription;

  currentPeriod: ChartPeriod;
  currUser: User;

  infoIconText$: Observable<string>;
  screenWidth: number;
  noDataChartIcone = 'chart-no-data';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dashboardChartService: DashboardChartService,
    private studentChartService: StudentChartService,
    private commonService: CommonService
  ) {
    this.userSubscription = this.userService.getCurrentUser().subscribe(resp => {
      this.currUser = resp;
    });
  }

  get isChartVisible(): boolean {
    return !!this.currentGraphData?.yearData.length;
  }

  get isTeacher(): boolean {
    return this.currUser.role === 'TEACHER';
  }

  ngOnInit(): void {
    this.currentPeriod = ChartPeriod.YEAR;
    this.infoIconText$ = this.commonService.select<string>('infoText');
    if (!this.areFiltersPresented) {
      this.fetchGraphData();
    }

    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  fetchGraphData() {
    if (this.studentId) {
      this.studentChartService
        .getChartDataForStudentProfile(this.currentPeriod, { ...this.selectedFiler, study_level_id: this.studentId })
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((res: TeacherChartData) => {
          this.dashboardData = [];
          this.generalGraphData = res;
          this.isLoading = false;
          this.refreshGraph();
        });
    } else {
      let studId;
      if (this.router.url.indexOf('studentProfile')) {
        studId = this.route.snapshot?.params?.studentId;
        if (studId) {
          this.selectedFiler.student_id = studId;
        }
      }

      this.dashboardChartService
        .getChartDataForTeacherDashboard(this.currentPeriod, this.selectedFiler)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
        .subscribe((res: TeacherChartData) => {
          this.dashboardData = [];
          this.generalGraphData = res;
          this.isLoading = false;
          this.refreshGraph();
        });
    }
  }

  refreshGraph() {
    if (this.generalGraphData) {
      this.currentGraphData = this.generalGraphData?.getData(this.currentPeriodIndex);

      const avgPredictedGrade = parseFloat(this.generalGraphData?.range[0]?.periods[0]?.avgPredictedGrade) || null;
      const grades = this.generalGraphData?.range[0]?.grades;
      const newSeries = [
        {
          data: this.currentGraphData.yearData,
          name: this.currentGraphData.name,
          cssClasses: { line: 'green-statistic', dot: 'green-statistic-dot' }
        },
        {
          // Hardcoded data for demo
          data: [
            { date: this.currentGraphData?.yearData[0]?.date, value: avgPredictedGrade },
            {
              date: this.currentGraphData?.yearData[this.currentGraphData?.yearData.length - 1]?.date,
              value: avgPredictedGrade
            }
          ],
          name: 'Benchmark',
          cssClasses: { line: 'statistic-predicted', dot: 'no-dot' }
        }
      ];
      this.displayData$.next({ series: newSeries, grades });
    } else {
      this.currentGraphData = null;
      this.displayData$.next({ series: [], grades: null });
    }
  }

  refreshGraphWithPeriod(period) {
    this.currentPeriod = period;
    this.fetchGraphData();
  }

  getYearToDisplay(timePeriod: TimePeriod) {
    return timePeriod.timeDisplayState.displayedStartDate.getFullYear() - this.dashboardData[0].yearStart;
  }

  nextPeriod() {
    if (this.generalGraphData.getData(this.currentPeriodIndex + 1)) {
      this.currentPeriodIndex++;
      this.refreshGraph();
    }
  }

  prevPeriod() {
    if (this.generalGraphData.getData(this.currentPeriodIndex - 1)) {
      this.currentPeriodIndex--;
      this.refreshGraph();
    }
  }

  @HostListener('window:scroll', ['$event'])
  showGraph(event) {
    if (window.pageYOffset > 300) {
      this.shouldAppear = true;
    }
  }

  filterChanged(courseId) {
    this.courseId = courseId;
    this.fetchGraphData();
  }
}
