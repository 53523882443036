/**
 * Created by Maxim B. on 19/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ITag, Question, QuestionType } from '../../../student/test.model';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, timer } from 'rxjs';
import { RestApiService, restApiServiceCreator } from '../../api/rest-api.service';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { COUResponse, LearningObjective } from '../../../shared-pages/subject-details/api-subject-details';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { fadeAnimation } from '../../animations/fade.animation';

@Component({
  selector: 'curr-question-card',
  templateUrl: './question-card.component.html',
  styleUrls: ['./question-card.component.scss'],
  animations: [fadeAnimation]
})
export class QuestionCardComponent implements OnInit, OnChanges {
  @Input() topics: LearningObjective[] = [];
  @Input() isAddToCTView = false;
  @Input() inEditQuestionMode = false;
  @Input() isInCT = false;
  @Input() questionNumber: number;
  @Input() questionTestType: 'ct' | 'sa' = 'ct';
  @Input() question: Question;
  @Input() cou: COUResponse[] = [];
  @Output() onAddToCT = new EventEmitter<boolean>();
  @Output() onQuestionDeleted = new EventEmitter<number>();
  ddCOUQuestions = [];
  isCTQuestion;
  questionType = QuestionType;
  deletedQuestion = false;
  saTestService: RestApiService<Question>;
  cumulativeTestService: RestApiService<Question>;
  imageKeyValue = {};
  answerImageKeyValue = {};
  isLoading = false;
  competence: string = '';
  tags: [string, ITag[]][];
  tagsObj: { [key: string]: string } = {};

  constructor(public dialog: MatDialog,
              private httpClient: ApplicationHttpClient,
              private fileUploadService: FileUploadService,
              private toastr: ToastrService) {
  }

  ngOnChanges(): void {
    if (this.cou?.length) {
      this.initDropdownOptions();
    }
  }

  ngOnInit(): void {
    this.formatQuestionAnswerImages();
  }

  formatQuestionAnswerImages() {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(
      this.httpClient
    );
    this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.question.question);
    this.question?.answer_options?.forEach((element, index) => {
      this.answerImageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.question.answer_options[index]);
      this.getAnswerDescription(this.question, index);
    });
    this.saTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveSAQuestions')(this.httpClient);
    this.isCTQuestion = this.questionTestType === 'ct';

    if (this.question.question.includes('<img src')) {
      this.getQuestionDescription(this.question);
    }
    if (this.question?.hint?.includes('<img src')) {
      this.getHintDescription(this.question);
    }
    if (this.question?.video_url?.includes('<img src')) {
      this.getExplanationDescription(this.question);
    }
    if (this.question?.rubric?.includes('<img src')) {
      this.getrubricQuestionDescription(this.question);
    }
    if (this.question?.children?.length) {
      this.question.children.forEach((element, index) => {
        this.getChildQuestionDescription(this.question.children[index]);
      });
    }
    if (this.question.answer_type === this.questionType.MULTIPART && this.question.children) {
      this.question.children.forEach(q => {
        this.getQuestionDescription(q);
        if (q.children.length) {
          q.children.forEach(subq => {
            this.getQuestionDescription(subq);
          });
        }
      });
    }
    if (this.question.answer_options_tags) {
      this.tags = Object.entries(this.question.answer_options_tags);
      this.tags.forEach(el => {
        let tagsForOption = '';
        el[1].forEach(tagInfo => {
          tagsForOption += `${ tagInfo.tag };`;
        });
        this.tagsObj[el[0]] = tagsForOption;
      });
    }
    // this.question.answer_options_tags.forEach(el => this.tags = this.tags + el.tag + ' ');
  }

  private async uploadImages(imageKeyValue: any, q: Question) {
    this.isLoading = true;
    let previewQuestion = q.question;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          previewQuestion = previewQuestion.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.previewQuestion = previewQuestion;
        this.setImgWidth();
      }
    });
  }

  private async uploadRubricImages(imageKeyValue: any, q: Question) {
    this.isLoading = true;
    let previewAnswer = q.rubric;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          previewAnswer = previewAnswer.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.rubric = previewAnswer;
        this.setImgWidth();
      }
    });
  }

  private async uploadHintImages(imageKeyValue: any, q: Question) {
    this.isLoading = true;
    let previewAnswer = q.hint;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          previewAnswer = previewAnswer.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.hint = previewAnswer;
        this.setImgWidth();
      }
    });
  }

  private async uploadExplanationImages(imageKeyValue: any, q: Question) {
    this.isLoading = true;
    let previewAnswer = q.video_url;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          previewAnswer = previewAnswer.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.video_url = previewAnswer;
        this.setImgWidth();
      }
    });
  }

  private async uploadChildImages(imageKeyValue: any, q: any) {
    this.isLoading = true;
    let childPreviewAnswer = q.rubric;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          childPreviewAnswer = childPreviewAnswer.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.rubric = childPreviewAnswer;
        this.setImgWidth();
      }
    });
  }

  uploadAnswerImages(answerImageKeyValue: any, q: Question, index) {
    this.isLoading = true;
    let options = q.answer_options[index];
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(answerImageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          answerImageKeyValue[key] = 'data:image/png;base64,' + bytes;
          options = options.replace(key, answerImageKeyValue[key]);
        }
      },
      complete: () => {
        this.isLoading = false;
        q.answer_options[index] = options;
        this.setImgWidth();
      }
    });
  }

  private initDropdownOptions() {
    this.ddCOUQuestions = [
      { value: null, label: 'No cou' },
      ...this.cou.map(i => {
        return { value: i.id, label: i.question, is_right: i.is_right };
      })
    ];
  }

  onCouSelect(couId: number, couType: 'right' | 'wrong') {
    if (couType === 'right') {
      this.question.cou_right_id = couId;
    } else {
      this.question.cou_wrong_id = couId;
    }
  }

  isAnswerCorrect(i: number) {
    if (this.question?.answer_type === this.questionType.SELECT_ONE) {
      return this.question?.correct_answer_options.includes(i);
    }
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      return this.question?.correct_answer_options.filter(a => a === i).length;
    }
  }

  deleteQuestion() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        questionTitle: 'Are you sure in deleting?',
        confirmLabel: 'YES',
        cancelLabel: 'NO'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let req: Observable<Question>;
        if (this.questionTestType === 'sa') {
          // SA
          req = this.saTestService.remove(this.question.id + '');
        } else if (this.questionTestType === 'ct') {
          // CT
          req = this.cumulativeTestService.remove(this.question.id + '');
        }
        req.subscribe(
          resp => {
            this.deletedQuestion = true;
            this.toastr.info('Question was deleted.');
            this.deletedQuestion = true;
            this.onQuestionDeleted.emit(this.question.id);
          },
          err => {
            if (err.error.message) {
              this.toastr.warning(err.error.message);
            }
          }
        );
      }
    });
  }

  editQuestion() {
    this.inEditQuestionMode = true;
  }

  addToCT() {
    this.isInCT = !this.isInCT;
    this.onAddToCT.emit(this.isInCT);
  }

  updateQuestion() {
    let req: Observable<Question>;
    if (this.questionTestType === 'sa') {
      // SA
      req = this.saTestService.update(this.question, this.question.id + '');
    } else if (this.questionTestType === 'ct') {
      // CT
      req = this.cumulativeTestService.update(this.question, this.question.id + '');
    }
    req.subscribe(resp => {
      // this.question = resp;
      this.toastr.success('Question updated!');
      this.inEditQuestionMode = false;
      this.formatQuestionAnswerImages();
    });
  }

  getQuestionDescription(question: Question) {
    if (question.question.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.question);
      this.uploadImages(imageKeyValue, question);
    }
    return question.question;
  }

  getHintDescription(question: Question) {
    if (question.hint.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.hint);
      this.uploadHintImages(imageKeyValue, question);
    }
    return question.hint;
  }

  getExplanationDescription(question: Question) {
    if (question.video_url.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.video_url);
      this.uploadExplanationImages(imageKeyValue, question);
    }
    return question.video_url;
  }

  getrubricQuestionDescription(question: Question) {
    if (question.rubric?.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.rubric);
      this.uploadRubricImages(imageKeyValue, question);
    }
    return question?.rubric;
  }

  getChildQuestionDescription(question: any) {
    if (question?.rubric?.includes('<img src')) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.rubric);
      this.uploadChildImages(imageKeyValue, question);
    }
    if(question?.children?.length === 1) {
      const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question?.children[0].rubric);
      this.uploadChildImages(imageKeyValue, question?.children[0]);
    }
    // if(question?.children?.length) {
    //   question?.children.forEach(childrenQuestion => {
    //     const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(childrenQuestion?.rubric);
    //     this.uploadChildImages(imageKeyValue, childrenQuestion);
    //   });
    // }
    return question?.rubric;
  }

  getAnswerDescription(answer: Question, index: number) {
    if (answer.answer_options[index].includes('<img src')) {
      const answerImageKeyValue = this.fileUploadService.getAllImageKeysFromNote(answer.answer_options[index]);
      this.uploadAnswerImages(answerImageKeyValue, answer, index);
    }
    return answer.answer_options;

  }

  setImgWidth() {
    timer(0)
      .pipe(take(1))
      .subscribe(resp => {
        const root = document.getElementById(`desc-${ this.question.id }`);
        const allImgs = root?.querySelectorAll('img');
        const rw = root?.offsetWidth;
        if (allImgs?.length) {
          allImgs.forEach(e => {
            if (e.width > rw) {
              // For mobile, decrease width
              e.setAttribute('width', '100%');
            }
          });
        }
      });
  }
}
