import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StudentNotesHelperService {

  constructor() {
  }

  findStartIndex(startNode: string, text: string, isStartSelectFromEnd: boolean): number {
    if (isStartSelectFromEnd) {
      return this.findEndIndex(startNode, text, isStartSelectFromEnd);
    }

    let ind = 0;
    let counter = 0;
    let maxMatchedStringLength = 0;
    let maxMatchedString = '';
    const txtLen = text.length;
    while (maxMatchedStringLength < txtLen) {
      counter++;
      if (counter > txtLen) {
        break;
      }
      const tmpSubStr = text.substr(0, counter);
      const matchIndex = startNode.indexOf(tmpSubStr);
      if (matchIndex < 0) {
        break;
      }
      if (matchIndex >= 0) {
        maxMatchedStringLength = counter;
        maxMatchedString = tmpSubStr;
        ind = matchIndex;
      }
    }
    return ind;
  }

  findEndIndex(endNode: string, text: string, isStartSelectFromEnd: boolean): number {
    if (!isStartSelectFromEnd) {
      return this.findStartIndex(endNode, text, isStartSelectFromEnd);
    }
    let ind = 0;
    let counter = 0;
    let maxMatchedStringLength = 0;
    let maxMatchedString = '';
    const txtLen = text.length;
    while (maxMatchedStringLength < txtLen) {
      counter++;
      if (counter > txtLen) {
        break;
      }
      const tmpSubStr = text.substring(txtLen - counter);
      const matchIndex = endNode.indexOf(tmpSubStr);
      if (matchIndex < 0) {
        break;
      }
      if (matchIndex >= 0) {
        maxMatchedStringLength = counter;
        maxMatchedString = tmpSubStr;
        ind = matchIndex + maxMatchedStringLength;
      }
    }
    return ind;
  }

  addCloseTagLength(ind: number, tag: string): number {
    if (tag[ind] === '<' && tag[ind + 1] === '/') {
      return tag.indexOf('>', ind);
    }
    return ind;
  }

  removeOpenTagLength(ind: number, tag: string): number {
    if (tag[ind - 1] === '>') {
      ind--;
      while (ind >= 0) {
        ind--;
        if (tag[ind] === '<') {
          break;
        }
      }
    } else {
      return ind;
    }
    return ind;
  }

}
