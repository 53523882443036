import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherService } from '../../../teacher/services/teacher.service';
interface Buttons {
  label: string;
  callback: () => void;
  link: string;
  color: string;
  variant: string;
  size: string;
  icon: string;
  isDisabled?: boolean;
}

interface Text {
  label: string;
  divider: string;
  color?: string;
  value: string;
  isBold?: boolean;
  isAttention?: boolean;
  attention?: string;
}

@Component({
  selector: 'curr-universal-card',
  templateUrl: './universal-card.component.html',
  styleUrls: ['./universal-card.component.scss']
})
export class UniversalCardComponent implements OnInit {
  @Input() card: {
    title: string;
    isClaimed: boolean;
    buttons: Buttons;
    topText?: Text;
    bottomText: Text[];
  };

  curriculumId;
  @Input() isRequiresReview: boolean;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private teacherService: TeacherService
  ) { }

  ngOnInit() {
    this.getSubjectId()
  }

  navigate(button: Buttons) {
    if (button.link) {
      this.router.navigateByUrl(button.link);
    }
  }
  getSubjectId(): void {
    this.curriculumId = this.route.snapshot.params.curriculumId;
    this.teacherService.curriculumId$.next(this.curriculumId);
  }
}
