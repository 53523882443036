/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { IFrequentlyLeftUnansweredQuestion } from '../../interfaces';
import { FileUploadService } from '../../../shared/components/edit-question-block/file-upload.service';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImagePopupComponent } from '../../../shared/components/image-popup/image-popup.component';

@Component({
  selector: 'curr-most-frequently-left-unanswered-questions',
  templateUrl: './most-frequently-left-unanswered-questions.component.html',
  styleUrls: ['./most-frequently-left-unanswered-questions.component.scss']
})
export class MostFrequentlyLeftUnansweredQuestionsComponent implements OnInit {
  @Input() set data(value: IFrequentlyLeftUnansweredQuestion[]) {
    if (value) {
      this.receivedData = value.length > 0 ? value : null;

      if (this.receivedData?.some(question => !!~question.text.indexOf('<img src'))) {
        for (const question of this.receivedData) {
          this.uploadImages(question);
        }
      } else {
        this.displayedData = value.length > 0 ? value?.slice(this.offset, this.limit) : null;
      }
    }
  }

  offset = 0;
  limit = 4;

  receivedData: IFrequentlyLeftUnansweredQuestion[];
  displayedData: IFrequentlyLeftUnansweredQuestion[];

  imageKeyValue: { [key: string]: string } = {};

  constructor(private fileUploadService: FileUploadService, private dialog: MatDialog) {}

  ngOnInit() {}

  uploadImages(question: IFrequentlyLeftUnansweredQuestion) {
    this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(question.text);
    let questionText = question.text;

    const observersObj: { [key: string]: Observable<any> } = {};

    for (const key of Object.keys(this.imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }

    forkJoin(observersObj).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          this.imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          questionText = questionText.replace(
            /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
            `(<a class="show-image" href="${key}">show image</a>) `
          );
        }
        question.text = questionText;
      },
      complete: () => {
        this.displayedData = this.receivedData?.slice(this.offset, this.limit);
      }
    });
  }

  @HostListener('click', ['$event'])
  onMouseEnter(e: Event) {
    e.preventDefault();
    const target = e.target as HTMLElement;
    if (target.classList.contains('show-image')) {
      const imgKey = (e.target as HTMLElement).getAttribute('href');

      this.dialog.open(ImagePopupComponent, {
        data: {
          imgSrc: this.imageKeyValue[imgKey]
        }
      });
    }
  }
  showMore() {
    this.offset += 4;
    this.displayedData = [...this.displayedData, ...this.receivedData?.slice(this.offset, this.limit + this.offset)];
  }
}
