/*
 * Created by Volodymyr Mak. on 15/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserProfileService } from './services/user-profile.service';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { LocalStorageService } from 'ngx-localstorage';
import { Subscription } from 'rxjs';
import { UserService } from '../../user.service';
import { Role } from '../../role';
import { MatDialog } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';

export interface StudentProfileData {
  email?: string;
  enabled?: boolean;
  first_name?: string;
  has_tasks_completed?: boolean;
  id?: string;
  is_graduated?: boolean;
  is_using2fa?: boolean;
  last_name?: string;
  password?: string;
  personal_id?: string;
  phone?: string;
  photo?: string;
  school_id?: number;
  secret?: string;
  study_year?: number;
  tkn?: string;
  user_type?: string;
  username?: string;
  role?: Role;
  permissions?: string[];
  personalSchoolId?: string;
  emailOrPhone?: string;
  attendingSchool?: string;
  authorities?: [];
  chosenYearId?: string;
  country?: string;
  dateOfBirth?: string;
  firstName?: string;
  gender?: string;
  hasPaid?: boolean;
  hasPaymentPlan?: boolean;
  hobbies?: string[];
  lastName?: string;
  personalId?: string;
  roles?: string[];
  schoolId?: number;
  schoolYearSettingId?: number;
  selectedSignupSubjectsIds?: string[];
  userId?: number;
}

@Component({
  selector: 'curr-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
  providers: [UserProfileService]
})
export class ProfileCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() profileData: StudentProfileData;
  @Input() isEditable = true;
  @Output() updateUserCard = new EventEmitter();
  subscription$ = new Subscription();
  @Output() imgCropped = new EventEmitter();
  @ViewChild('cropImagePopup') cropImagePopup: TemplateRef<any>;
  @ViewChild('fileInput') fileInput: ElementRef;

  cropImg: string = ''
  imageBlob: any = '';
  form: FormGroup;
  editUser = false;
  editPersonalData = false;
  profileImg: string;
  avatar: string;
  imagePreviewFile;
  idFromUrl;
  curUser;
  initialProfileData: StudentProfileData;
  editProfile = false;
  cameraIcon = 'camera';
  cancelButtonStyle = { color: '#006878', 'text-decoration': 'underline' };
  noProfileIcone = 'no-profile-image';

  constructor(
    private httpClient: ApplicationHttpClient,
    private localStorageService: LocalStorageService,
    private fus: FileUploadService,
    private userService: UserService,
    public dialog: MatDialog,
  ) { }

  ngOnChanges(): void {
    this.curUser = this.localStorageService.get('currentUser');
    if (this.profileData.photo) {
      this.updateImageonChange(this.profileData.photo)
    }
    if (this.profileData) {
      this.initialProfileData = { ...this.profileData };
    }
  }

  ngOnInit(): void {
    this.subscription$.add(
      this.userService.activeUser$.subscribe(res => {
        this.updateImageonChange(res.photo)
      }));
  }

  fileChangeEvent(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      // Conver upload img into base64 format.
      this.imageBlob = new Blob([file], { type: 'image/jpeg' });
      this.dialog.open(this.cropImagePopup, {
        maxHeight: '520px',
        height: '100%',
        width: '100%',
        maxWidth: '680px'
      }).afterClosed().subscribe(
        {
          next: () => {
            this.fileInput.nativeElement.value = '';
          },
          complete: () => {
            this.dialog.ngOnDestroy();
          }
        }
      )
    }
  }

  saveImage() {
    this.imgCropped.next(this.cropImg);
    this.dialog.closeAll();
  }

  getCroppedImg(event: ImageCroppedEvent) {
    this.cropImg = event.base64;
  }

  cancelImageSaving() {
  this.imgCropped.next(this.cropImg);
    this.cropImg = null;
    this.dialog.closeAll();
  }

  toggleEditUserMode(value: boolean) {
    this.editUser = value;
  }

  toggleEditpersonalDataMode(value: boolean) {
    this.editPersonalData = value;
  }

  cancelChanges() {
    this.toggleEditUserMode(false);
    this.toggleEditpersonalDataMode(false);
    this.profileData = { ...this.initialProfileData };
  }

  setCroppedImg(img: string) {
    if (this.cropImg) {
      this.profileImg = this.cropImg;
      this.subscription$.add(
        this.fus.upload(this.fus.base64ToFile(this.cropImg, '1.png'), 'IMAGE').subscribe(resp => {
          this.profileData.photo = resp[0].uuidName;
          this.userService.activeUser$.next(this.profileData);
          this.toggleEditProfile(false);
          this.updateUserProfile();
        }));
    }
    else {
      this.toggleEditProfile(false);
    }
  }

  updateImageonChange(image) {
    this.subscription$.add(
      this.fus.download(image, 'IMAGE').subscribe((img: any) => {
        if (img.data.bytes) {
          this.imagePreviewFile = 'data:image/png;base64,' + img.data.bytes;
        }
      })
    );
  }

  updateUserProfile() {
    this.subscription$.add(
      this.httpClient.put(`/users/${this.profileData.id}`, this.profileData).subscribe(resp => {
        this.toggleEditUserMode(false);
        this.toggleEditpersonalDataMode(false);
        this.userService.activeUser$.next(this.profileData);
        this.initialProfileData = { ...this.profileData };
      }));
  }

  toggleEditProfile(value: boolean) {
    this.editProfile = value;
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
