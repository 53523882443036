/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input } from '@angular/core';
import { Question, QuestionsLabels, QuestionType } from '../../../../../student/test.model';

@Component({
  selector: 'curr-my-question-item',
  templateUrl: './my-question-item.component.html',
  styleUrls: ['./my-question-item.component.scss']
})
export class MyQuestionItemComponent implements OnInit {
  @Input() question: Question;
  @Input() isFirstLevel: boolean = true;

  questionType = QuestionType;
  questionsLabels = QuestionsLabels;

  constructor() {}

  ngOnInit() {}

  isOpenAnswer() {
    return (
      this.question?.answer_type === this.questionType.SELECT_ONE ||
      this.question?.answer_type === this.questionType.SELECT_MULTIPLE ||
      this.question?.answer_type === this.questionType.TRUE_OR_FALSE
    );
  }

  isAnswerCorrect(i: number) {
    if (!this.question || !this.question.correct_answer_options) {
      return false;
    }
    if (this.question?.answer_type === this.questionType.SELECT_ONE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.TRUE_OR_FALSE) {
      return this.question?.correct_answer_options[0] === i;
    }
    if (this.question?.answer_type === this.questionType.SELECT_MULTIPLE) {
      return this.question?.correct_answer_options.filter(a => a === i).length;
    }
  }

  getQuestionTypeLabel() {
    return this.questionsLabels[this.question.answer_type] || '';
  }
}
