/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Role } from '../../shared/role';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CourseUsersResolver implements Resolve<any> {

  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return route.paramMap.get('subjectId');
  }
}
