// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serverUrl: 'https://api.curriculum.com',
  apiUrl: 'https://api.curriculum.com/api/v1',
  apiLinkPreviewUrl: 'https://api.linkpreview.net/?key=0e87387e75e248985a32c874b49a14f2&q=',
  translation: {
    defaultLanguage: 'en'
  },
  auth: {
    username: 'admin1234'
  },
  tinyEditorKey: 'g630c5ja07iackz9ke9dmac45xy5z1v28hziwvnpz3bxki84',
  highlightColors:['#FFEA00', '#76FF03', '#00B0FF', '#FF4081', '#B388FF'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
