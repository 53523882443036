<!-- Created by Alex Poh. on 25/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-school-performance-histogram">
  <svg class="statistic-caption mat-caption" #chartContainer></svg>

  <div class="chart-legends">
    <span class="legends-item" fxInlineFlex fxLayoutAlign="center center">
      <i class="results"></i> Topic Test Results</span>
    <span class="legends-item" fxInlineFlex fxLayoutAlign="center center">
      <i class="benchmark"></i> Benchmark</span>
  </div>
</div>
