/**
 * Created by Alex Poh. on 23/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curr-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  date = new Date();

  constructor() {}

  ngOnInit(): void {}
}
