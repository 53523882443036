import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

const ELEMENT_DATA = [
  { key: 'No Data', mark: 3 },
  { key: 'No Data', mark: 3 },
  { key: 'No Data', mark: 2 }
];

@Component({
  selector: 'curr-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnChanges {
  @Input() inEditMode = false;
  @Input() tableData:{key:string, mark: number}[] = [];
  @Output() onKeywordsUpdate = new EventEmitter<{key:string, mark: number}[]>();
  displayedColumns: string[] = ['key', 'mark'];
  dataSource;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor() {
    this.dataSource = new MatTableDataSource(this.tableData || ELEMENT_DATA);
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnInit() {
    if (this.inEditMode){
      this.displayedColumns.push('delete')
    }
    this.dataSource.sort = this.sort;
  }

  deleteElement(element: any, i: any) {
    this.onKeywordsUpdate.emit(this.tableData);
    this.tableData.splice(i,1);
    this.dataSource = new MatTableDataSource(this.tableData);
  }

  addKeyword() {
    this.tableData.push({key:'Keyword', mark: 1});
    this.dataSource = new MatTableDataSource(this.tableData);
    this.onKeywordsUpdate.emit(this.tableData);
  }



}
