import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-editor-video-popup',
  templateUrl: './editor-video-popup.component.html',
  styleUrls: ['./editor-video-popup.component.scss']
})
export class EditorVideoPopupComponent {

  constructor( private dialogRef: MatDialogRef<EditorVideoPopupComponent>){
  }

  closeModal(): void {
    this.dialogRef.close();
  }

}
