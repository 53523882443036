/**
 * Created by Maxim B. on 14/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { take } from 'rxjs/operators';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Component({
  selector: 'curr-cumulative-tests',
  templateUrl: './cumulative-tests.component.html',
  styleUrls: ['./cumulative-tests.component.scss']
})
export class CumulativeTestsComponent implements OnInit {
  tests: any;
  subject: any;
  currSelectedTabIndex = 0;
  tabLabels = [];
  ddOptions = [];
  path = [];
  subjectId;
  curriculumId;
  selectedPaperId;
  papers;
  paperOptions = [];
  courseType = 'Cumulative Test';
  editLink = 'cumulative-test-edit';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private httpClient: ApplicationHttpClient
  ) {}

  ngOnInit(): void {
    this.tests = this.route.snapshot.data.tests;
    this.subject = this.route.snapshot.data.subject.data;

    this.route.params.pipe(take(1)).subscribe(res => {
      this.subjectId = res.subjectId;
      this.curriculumId = res.curriculumId;
    });

    let lastLabel = 'Cumulative Tests';
    const testTypeParam = this.route.snapshot.paramMap.get('testType');
    this.editLink = testTypeParam;
    if (testTypeParam === 'homework') {
      this.courseType = lastLabel = 'Homework';
    }
    if (testTypeParam === 'quiz') {
      this.courseType = lastLabel = 'Quiz';
    }

    this.path = [
      { label: 'Home', url: '/dashboard' },
      // { label: `${this.subject.subject || '... subject'}, ${this.subject.studyLevel || '... level'}`, url: '/subjects' },
      { label: 'Courses', url: '/subjects' },
      {
        label: lastLabel,
        url: ''
      }
    ];
    let lbl = `ALL (${this.tests?.needCheckCard.length +
    this.tests?.checkedCard.length +
    this.tests?.notPassedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '0' });
    lbl = `Requires Review (${this.tests?.needCheckCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '1' });
    lbl = `Reviewed (${this.tests?.checkedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '2' });
    lbl = `Pending (${this.tests?.notPassedCard.length})`;
    this.tabLabels.push(lbl);
    this.ddOptions.push({ label: lbl, value: '3' });

    this.getPapers();
  }

  selectTab(val: string) {
    this.currSelectedTabIndex = +val;
  }

  private getPapers() {
    if (this.subjectId) {
      this.httpClient.get(`/curriculumPapers?curriculum_id=${this.curriculumId}`)
        .subscribe(resp => {
          this.papers = (resp as any).data?.items;
          if (this.papers.length) {
            this.selectedPaperId = this.papers[0].id
            this.papers.forEach(i => {
              this.paperOptions.push({ label: i.title, value: i.id });
            });
          }
        });
    }
  }

  selectPaper(val: any | []) {
    this.selectedPaperId = val;
  }

  createTest() {
    if (this.selectedPaperId) {
      this.router.navigate(['edit', this.editLink, this.selectedPaperId, 'new', this.subjectId]);
    }
  }
}
