<!-- Created by Maxim B. on 28/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div [@showNotificationBar]="currentState" class="notifications-container">
  <div class="notifications-wrapper">

    <div class="notification-title mat-h-1" fxLayout="row" fxLayoutAlign="space-between center">
      <h1 class="mat-body-1">Notification</h1>
      <curr-icon-button icon="close" (click)="closeNotificationsBar()"></curr-icon-button>
    </div>

    <div class="day-notifications-container"
         *ngFor="let notifDay of notificationsArr;let n = index">
      <h1 class="mat-h2 day-header">{{getDayLabel(notifDay.date)}}</h1>
      <curr-notification-card [card]="card" *ngFor="let card of notifDay.items;let i = index"
                              (removeNotification)="remove($event, i, n)"></curr-notification-card>
    </div>
  </div>


</div>
