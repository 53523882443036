import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class StudentCheckTestService {

  constructor(private httpClient: ApplicationHttpClient) {

  }

  setCTTestReviewed(id: number, courseId: number) {
    return this.httpClient.patch(`/studentCumTests/${id}/reviewed?courseId=${courseId}`);
  }


  getSATestQuestion(testId: number, courseId: number) {
    return this.httpClient.get(`/curriculumLearningObjectiveSATests/${testId}/review?course_id=${courseId}`);
  }

  getCTTestQuestion(testId: number, courseId: number) {
    return this.httpClient.get(`/curriculumCumTests/${testId}/review?course_id=${courseId}`);
  }

  getCTIncorrectlyAnsweredQuestion(testId: number) {
    return this.httpClient.get(`/studentCumTestQuestions/incorrectlyAnswered?schoolYearSettingId=${testId}`);
  }

  getSAIncorrectlyAnsweredQuestion(testId: number) {
    return this.httpClient.get(`/studentSATestQuestion/incorrectlyAnswered?schoolYearSettingId=${testId}`);
  }

  sendCTQuestionMark(mark: number, questionId: number) {
    return this.httpClient.put(`/studentCumTestQuestions/${questionId}/mark?mark=${mark}`, {
      mark
    });
  }

  sendCTQuestionComment(comment: string, questionId: number) {
    return this.httpClient.put(`/studentCumTestQuestions/${questionId}/comment`, {
      comment
    });
  }

  sendCTQuestionResponseType(responseType: string, questionId: number) {
    return this.httpClient.put(`/studentCumTestQuestions/${questionId}/responseType?responseType=${responseType}`, {
      responseType
    });
  }

  sendCTQuestionAudio(audio_file_uuid: string, questionId: number) {
    return this.httpClient.patch(`/studentCumTestQuestions/${questionId}/audioFile`, { audio_file_uuid });
  }

  removeQuestionAudio(questionId: number) {
    return this.httpClient.delete(`/studentCumTestQuestions/${questionId}/audioFile`, {});
  }

  sendSAQuestionComment(comment: string, questionId: number) {
    return this.httpClient.put(`/studentSATestQuestion/${questionId}/comment`, {
      comment
    });
  }

  sendSAQuestionResponseType(responseType: string, questionId: number) {
    return this.httpClient.put(`/studentSATestQuestion/${questionId}/responseType?responseType=${responseType}`, {
      responseType
    });
  }

  sendSAQuestionAudio(audio_file_uuid: string, questionId: number) {
    return this.httpClient.patch(`/studentSATestQuestion/${questionId}/audioFile`, { audio_file_uuid });
  }

  approveAnswer(studentTests: any) {
    return this.httpClient.post(`/studentCumTests/approve`, studentTests);
  }

}
