import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpRequest } from '@angular/common/http';
import { FileUploadModel } from './file-model';
import { catchError, last, map, tap, take } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { interval } from 'rxjs';
import { ApplicationHttpClient } from '../../api/application-http-client';

@Injectable()
export class UploadFilesService {

  constructor(
    private _httpClient: ApplicationHttpClient,
    private _http: HttpClient
  ) {}

  prettyTime(time: number, name: string) {
    if (time !== 0 && Number.isInteger(time)) {
      return ` ${time} ${name}`;
    } else {
      return '';
    }
  }

  importFiles(files) {
    const formData: FormData = new FormData();
    formData.append('files', files[0]);
    formData.append('ownerId', '3');
    formData.append('schoolId', '1');
    formData.append('curriculumType', 'MASTER');

    return this._httpClient.post('/service/import/topics', formData)
    
  }

  convertSupported(supported: string[]) {
    return supported
      .map(str => {
        if (str === 'image/jpeg') {
          str = '.jpg';
        }
        if (str === 'image/png') {
          str = '.png';
        }

        return str.toUpperCase();
      })
      .join(' ');
  }

  convertMS(milliseconds) {
    let day;
    let hour;
    let minute;
    let seconds;

    seconds = Math.floor(milliseconds / 1000);
    minute = Math.floor(seconds / 60);
    seconds = seconds % 60;
    hour = Math.floor(minute / 60);
    minute = minute % 60;
    day = Math.floor(hour / 24);
    hour = hour % 24;

    let str = '';

    str += this.prettyTime(day, 'days');
    str += this.prettyTime(hour, 'hours');
    str += this.prettyTime(minute, 'minutes');
    str += this.prettyTime(seconds, 'seconds');

    return str;
  }
  param = 'file';
  url = 'https://file.io';

  files: Array<FileUploadModel> = [];

  getRandomValue(min, max) {
    return Math.random() * (max - min) + min;
  }

  public uploadFile(file: FileUploadModel): Observable<any> {
    const fd = new FormData();
    fd.append(this.param, file.data);

    const startTime = new Date().getTime();
    file.inProgress = true;
    return interval(this.getRandomValue(300, 400)).pipe(
      take(20),

      map((ev: any) => {
        return { type: 200, loaded: ev, total: 500 };
      }),
      map((event: any) => {
        if (event.type === 200) {
          file.progress = Math.round((event.loaded * 3000) / event.total);

          const diffTime = new Date().getTime() - startTime;
          const speed = file.progress / diffTime;
          const left = 100 - file.progress;
          const timeLeftMs = left / speed;
          file.timeLeft = this.convertMS(timeLeftMs);
          if (file.progress > 98) {
            file.timeLeft = this.convertMS(0);
            return true;
          }
          return false;
        }
      }),
      catchError((error: any) => {
        file.inProgress = false;
        file.canRetry = true;
        file.isError = true;
        file.color = 'warn';
        file.progress = 0;
        return of(false);
      })
    );
  }

  public uploadFile_old(file: FileUploadModel): Observable<any> {
    const fd = new FormData();
    fd.append(this.param, file.data);

    const req = new HttpRequest('POST', this.url, fd, {
      reportProgress: true
    });

    const startTime = new Date().getTime();
    file.inProgress = true;
    return this._http.request(req).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round((event.loaded * 100) / event.total);
            const diffTime = new Date().getTime() - startTime;
            const speed = file.progress / diffTime;
            const left = 100 - file.progress;

            const timeLeftMs = left / speed;
            file.timeLeft = this.convertMS(timeLeftMs);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      tap(message => {}),
      last(),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;

        file.canRetry = true;
        file.isError = true;
        file.color = 'warn';
        return of(`${file.data.name} upload failed.`);
      })
    );
  }

  public uploadFiles(files) {
    this.files = files;
    return this.files.forEach(file => {
      if (file.sub === undefined) {
        file.sub = this.uploadFile(file).subscribe((event: any) => {
          file.isCompleted = event;
        });
      }
    });
  }

  cancelFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    this.removeFileFromArray(file);
  }

  retryFile(file: FileUploadModel) {
    file.sub.unsubscribe();
    file.sub = undefined;

    this.uploadFiles(this.files);
    file.canRetry = false;
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  public getImage(imageUrl: string) {
    const block = imageUrl.split(';');

    const contentType = block[0].split(':')[1];

    const realData = block[1].split(',')[1];

    return [realData, contentType];
  }

  public b64toFile(imageUrl: string) {
    const sliceSize = 512;

    const [b64Data, contentType] = this.getImage(imageUrl);

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const name = uuidv4();

    const blob = new Blob(byteArrays, { type: contentType });
    const file = new File([blob], name, { type: contentType });

    return file;
  }
}
