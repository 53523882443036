<!--
 Created by Dmytro Sav. on 4/5/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-paper-info">
  <div class="paper-info-title-line">
    <div class="paper-info-title">
      <span>{{ paper.title ? paper.title : 'New Paper' }}</span>
      <mat-icon svgIcon="edit" color="primary" (click)="onEditPaperName()"></mat-icon>
    </div>

    <div class="actions-section">
      <curr-button [icon]="'plus'" [size]="'big'" (btnClick)="onAddAboutItem()" [variant]="'contained'">
        About Paper
      </curr-button>
      <curr-button [size]="'big'" (btnClick)="onAiAssistant()" [variant]="'contained'">
        AI ASSISTANT
      </curr-button>
    </div>
  </div>

  <div class="about-items" *ngIf="sowDescriptions">
    <div class="about-item" *ngFor="let item of sowDescriptions; let i = index">
      <curr-paper-info-item (onEditAbout)="onEditAboutItem($event, i)" [about]="item"
        (onDeleteAbout)="onDeleteAboutItem(i)"></curr-paper-info-item>
    </div>
  </div>
</div>
