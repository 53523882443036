<div class="container">
  <div class="header-container main-header m-b-0 m-t-16" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-16 m-l-16">Notes about student</h2>
    <div fxLayout="row" fxLayoutAlign="end center">
      <curr-button class="header-add-btn" variant="text"
                   size="small" icon="icon-plus"
                   (btnClick)="openAddNote()">
        Add new
      </curr-button>
    </div>
  </div>
  <mat-divider class="m-b-16 full-width"></mat-divider>

  <curr-loader [isLoading]="isLoading" [diameter]="48"></curr-loader>

  <mat-card>
    <div class="tab-container full-width m-b-8" fxLayout="row">
      <mat-tab-group mat-stretch-tabs mat-align-tabs="center" class="full-width"
                     (selectedIndexChange)="selectedIndexChange($event)"
                     [selectedIndex]="selectedIndex">
        <mat-tab label="ALL NOTES" fxFlex="50%"></mat-tab>
        <mat-tab label="MY NOTES" fxFlex="50%"></mat-tab>
      </mat-tab-group>
    </div>

    <div fxLayout="column" class="full-width">
      <ng-container *ngFor="let note of notes;let i = index">
        <ng-container *ngIf="selectedIndex === 0">
          <ng-container *ngTemplateOutlet="noteCard;context: {n:note,i:i}"></ng-container>
        </ng-container>
        <ng-container *ngIf="selectedIndex === 1 && currUser.id == note.teacher_id">
          <ng-container *ngTemplateOutlet="noteCard;context: {n:note,i:i}"></ng-container>
        </ng-container>
      </ng-container>
    </div>

    <div>

      <curr-pagi-for-loading-data *ngIf="dataFromApi"
                                  [showSideBtns]="false"
                                  [currPage]="dataFromApi.pageable.pageNumber"
                                  [totalPages]="dataFromApi.totalPages"
                                  (pageSelected)="pageSelected($event)"
      ></curr-pagi-for-loading-data>
    </div>

  </mat-card>
</div>


<ng-template #noteCard let-note="n" let-i="i">
  <div class="note-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <span class="text">{{note.note}}</span>
      <span class="user-date">{{note.updated_at | date : 'dd/MM/yyyy'}} BY
        <span class="user">{{ note.teacher_first_name + ' ' + note.teacher_last_name}}</span></span>
    </div>
    <div class="menu-container" *ngIf="note.teacher_id === currUser.id">
      <button
        mat-icon-button
        class="icon-tab-menu"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="noteMenu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #noteMenu="matMenu" xPosition="before" class="meeting-menu">
        <button class="tab-btn-menu" mat-menu-item (click)="changeNote(note,i)">
          <mat-icon>text_fields</mat-icon>
          Edit
        </button>
        <button class="tab-btn-menu" mat-menu-item (click)="deleteNote(note,i)">
          <mat-icon>delete</mat-icon>
          Delete
        </button>
      </mat-menu>
    </div>
  </div>
</ng-template>
