import { NgModule } from '@angular/core';
import { QuestionWidgetModule } from 'src/app/teacher/shared/questions-widget/question-widget.module';
import { SchemeOfWorkComponent } from '../shared-pages/subject-details/paper-details/scheme-of-work/scheme-of-work.component';
import { SubjectDetailsComponent } from '../shared-pages/subject-details/subject-details.component';
import { LearningResourcesComponent } from '../shared-pages/subject-details/paper-details/learning-resources/learning-resources.component';
import { TeacherSelfAssessmentTestsComponent } from './teacher-self-assessment-tests/teacher-self-assessment-tests.component';
import { TeacherTabsComponent } from './shared/teacher-tabs/teacher-tabs.component';
import { TeacherCumulativeTestCardComponent } from './shared/cumulative-test-card/teacher-cumulative-test-card.component';
import { CouComponent } from '../shared-pages/subject-details/paper-details/cou/cou.component';
import { PaperDetailsComponent } from '../shared-pages/subject-details/paper-details/paper-details.component';
import { GanttChartD3Component } from '../shared-pages/subject-details/gantt-chart-d3/gantt-chart-d3.component';
import { QuestionsPageComponent } from './questions-page/questions-page.component';
import { SubjectDetailsListComponent } from '../shared-pages/subject-details-list/subject-details-list.component';
import { GanttChartComponent } from '../shared-pages/subject-details/gantt-chart/gantt-chart.component';
import { FakeGanttChartComponent } from '../shared-pages/subject-details/fake-gantt-chart/fake-gantt-chart.component';
import { CumulativeTestsComponent } from './cumulative-tests/cumulative-tests.component';
import { CourseDetailsComponent } from '../shared-pages/course-details/course-details.component';
import { SelfAssessmentsComponent } from './self-assessments/self-assessments.component';
import { TeacherSelfAssessmentTestCardComponent } from './shared/teacher-self-assessment-test-card/teacher-self-assessment-test-card.component';
import { TestQuestionsComponent } from '../shared-pages/subject-details/paper-details/test-questions/test-questions.component';
import { ImportTeacherTimeTablePopupComponent } from './dashboard/import-teacher-time-table-popup/import-teacher-time-table-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDividerModule } from '@angular/material/divider';
import { SharedPagesModule } from '../shared-pages/shared-pages.module';
import { MatListModule } from '@angular/material/list';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../shared/shared.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { FormPageComponent } from './form-page/form-page.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TeacherProfileComponent } from './teacher-profile/teacher-profile.component';
import { ReviewTestQuestionCardComponent } from './questions-page/review-test-question-card/review-test-question-card.component';
import { GanttWithoutDataComponent } from '../shared-pages/subject-details/gantt-chart/gantt-without-data/gantt-without-data.component';
import { PaperInfoComponent } from '../shared-pages/subject-details/paper-details/paper-info/paper-info.component';
import { SowTopicsListComponent } from '../shared-pages/subject-details/paper-details/sow-topics-list/sow-topics-list.component';
import { ReportPageComponent } from './report-page/report-page.component';
import { UnderPerformingStudentsComponent } from './report-page/under-performing-students/under-performing-students.component';
import { HardestQuestionsComponent } from './report-page/hardest-questions/hardest-questions.component';
import { ColoredListItemComponent } from './report-page/colored-list-item/colored-list-item.component';
import { AnswersExamplesComponent } from './report-page/answers-examples/answers-examples.component';
import { QuestionsPieChartComponent } from './report-page/questions-pie-chart/questions-pie-chart.component';
import { TtReportComponent } from './report-page/tt-report/tt-report.component';
import { SaReportComponent } from './report-page/sa-report/sa-report.component';
import { ReportStatsComponent } from './report-page/report-stats/report-stats.component';
import { MostFrequentlyLeftUnansweredQuestionsComponent } from './report-page/most-frequently-left-unanswered-questions/most-frequently-left-unanswered-questions.component';
import { LowestComprehensionObjectsComponent } from './report-page/lowest-comprehension-objects/lowest-comprehension-objects.component';
import { QuestionsTypesWhereMostMarksAreLostComponent } from './report-page/questions-types-where-most-marks-are-lost/questions-types-where-most-marks-are-lost.component';
import { TypeOfQuestionsAnsweredIncorrectlyComponent } from './report-page/type-of-questions-answered-incorrectly/type-of-questions-answered-incorrectly.component';
import { QuestionsListWithMostLostMarksComponent } from './report-page/questions-list-with-most-lost-marks/questions-list-with-most-lost-marks.component';
import { StudentsListComponent } from './questions-page/students-list/students-list.component';
import { StudentsListQuestionComponent } from './questions-page/students-list/students-list-question/students-list-question.component';
import { ExpansionPanelQuestionComponent } from './questions-page/expansion-panel-question/expansion-panel-question.component';
import { StudentAnswerComponent } from './questions-page/student-answer/student-answer.component';
import { QuestionStatisticComponent } from './report-page/question-statistic/question-statistic.component';
import { StatisticExpansionPanelComponent } from './report-page/question-statistic/statistic-expansion-panel/statistic-expansion-panel.component';
import { UnderlyingFactorsComponent } from './report-page/underlying-factors/underlying-factors.component';
import { UnderlyingFactorsChartComponent } from './report-page/underlying-factors/underlying-factors-chart/underlying-factors-chart.component';
import { MostFrequentlyIncorrectQuestionsComponent } from './report-page/most-frequently-incorrect-questions/most-frequently-incorrect-questions.component';
import { ShareConfirmationPopupComponent } from './questions-page/share-confirmation-popup/share-confirmation-popup.component';
import { MyQuestionBankComponent } from '../shared-pages/subject-details/paper-details/my-question-bank/my-question-bank.component';
import { MyQuestionSectionComponent } from '../shared-pages/subject-details/paper-details/my-question-bank/my-question-section/my-question-section.component';
import { MyQuestionItemComponent } from '../shared-pages/subject-details/paper-details/my-question-bank/my-question-item/my-question-item.component';

import { NgChartsModule} from 'ng2-charts';
// import 'chartjs-plugin-labels';
import { MathModule } from '../shared/math-ml/math.module';
import {MatRadioModule} from '@angular/material/radio';

@NgModule({
  declarations: [
    SubjectDetailsComponent,
    SubjectDetailsListComponent,
    PaperDetailsComponent,
    SchemeOfWorkComponent,
    TestQuestionsComponent,
    CouComponent,
    GanttChartComponent,
    GanttWithoutDataComponent,
    CumulativeTestsComponent,
    FormPageComponent,
    CourseDetailsComponent,
    GanttChartD3Component,
    QuestionsPageComponent,
    TeacherTabsComponent,
    TeacherSelfAssessmentTestsComponent,
    SelfAssessmentsComponent,
    TeacherCumulativeTestCardComponent,
    FakeGanttChartComponent,
    TeacherSelfAssessmentTestCardComponent,
    LearningResourcesComponent,
    ImportTeacherTimeTablePopupComponent,
    ReviewTestQuestionCardComponent,
    TeacherProfileComponent,
    PaperInfoComponent,
    SowTopicsListComponent,
    ReportPageComponent,
    UnderPerformingStudentsComponent,
    HardestQuestionsComponent,
    ColoredListItemComponent,
    AnswersExamplesComponent,
    QuestionsPieChartComponent,
    TtReportComponent,
    SaReportComponent,
    ReportStatsComponent,
    MostFrequentlyLeftUnansweredQuestionsComponent,
    UnderlyingFactorsComponent,
    UnderlyingFactorsChartComponent,
    LowestComprehensionObjectsComponent,
    QuestionsTypesWhereMostMarksAreLostComponent,
    TypeOfQuestionsAnsweredIncorrectlyComponent,
    QuestionsListWithMostLostMarksComponent,
    StudentsListComponent,
    ExpansionPanelQuestionComponent,
    StudentAnswerComponent,
    QuestionStatisticComponent,
    StatisticExpansionPanelComponent,
    MostFrequentlyIncorrectQuestionsComponent,
    ShareConfirmationPopupComponent,
    StudentsListQuestionComponent,
    MyQuestionBankComponent,
    MyQuestionSectionComponent,
    MyQuestionItemComponent
  ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        SharedModule,
        MatProgressBarModule,
        MatCardModule,
        MatDividerModule,
        DragDropModule,
        MatTabsModule,
        MatListModule,
        SharedPagesModule,
        MatDialogModule,
        RouterModule,
        MatMenuModule,
        MatSlideToggleModule,
        NgChartsModule,
        QuestionWidgetModule,
        MathModule,
        MatRadioModule
    ],
  exports: [
    SubjectDetailsComponent,
    SubjectDetailsListComponent,
    PaperDetailsComponent,
    SchemeOfWorkComponent,
    TestQuestionsComponent,
    CouComponent,
    GanttChartComponent,
    CumulativeTestsComponent,
    CourseDetailsComponent,
    GanttChartD3Component,
    QuestionsPageComponent,
    TeacherTabsComponent,
    TeacherSelfAssessmentTestsComponent,
    SelfAssessmentsComponent,
    TeacherCumulativeTestCardComponent,
    FakeGanttChartComponent,
    TeacherSelfAssessmentTestCardComponent,
    LearningResourcesComponent,
    ReviewTestQuestionCardComponent,
    ImportTeacherTimeTablePopupComponent,
    PaperInfoComponent,
    SowTopicsListComponent,
    ReportPageComponent,
    GanttWithoutDataComponent,
    MyQuestionBankComponent,
    MyQuestionSectionComponent,
    MyQuestionItemComponent
  ]
})
export class TeacherSharedModule {}
