/**
 * Created by Alex Poh. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SubjectDetailsService {
  paperBackBtnSubject = new BehaviorSubject<boolean>(null);
  paperBackBtn$ = this.paperBackBtnSubject.asObservable();

  constructor(private httpClient: ApplicationHttpClient) {}

  getLessonsByCourseId(courseId: string, rowOffset = 0, rowCount = 10): Observable<any> {
    return this.httpClient
      .get<any>(`/studentLessons?courseId=${courseId}&rowOffset=${rowOffset}&rowCount=${rowCount}`)
      .pipe(map(res => res?.data?.items || []));
  }

  getPriorLearningQuestion(lobjective_id: number) {
    return this.httpClient.get(`/curriculumSaQuestions/${lobjective_id}`)
  }
}
