/**
 * Created by Alex Poh. on 23/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SendTestNotificationsService } from '../notifications-bar/send-test-notifications.service';
import { EMPTY, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { catchError, first } from 'rxjs/operators';
import { Role } from '../../role';
import { UserService } from '../../user.service';
import { FileUploadService } from '../edit-question-block/file-upload.service';

@Component({
  selector: 'curr-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  notificationSubscription: any;
  switchState = 'minimized';
  isNotiMinimized = true;
  isNameDDOpen = false;
  isActive = false;
  name = '';
  private subscription$ = new Subscription();
  studentPages = [
    { icon: 'home', name: 'Home', rout: '/dashboard' },
    { icon: 'hat', name: 'Subject', rout: '/courses-details', disabled: false },
    { icon: 'tv', name: 'Recordings', rout: '/dashboard' },
    { icon: 'avatar', name: 'My Profile', rout: '/profile' },
    { icon: 'save-bookmark', name: 'My Saved Materials', rout: '/subject-notes' },
    { icon: 'orientation-video', name: 'Orientation Video', rout: '/orientation-video' },
    // { icon: 'referral-program', name: 'Referral Program', rout: '/referral' },
    // { icon: 'settings', name: 'Settings', rout: '/dashboard' }
  ];
  generalPages = [
    { icon: 'lock', name: 'Privacy Policy', rout: '/policy' },
    { icon: 'assignment', name: 'Terms of service', rout: '/tos' }
    // { icon: 'logout', name: 'Logout', rout: '/logout', isRed: true }
  ];
  logo = 'logo-c';
  profile;
  imagePreviewFile;
  isSafari: boolean;
  isExamPreparation: boolean = false;
  isLoadedSubscriptionInfo: boolean = false;
  isISGSE: boolean = false;

  constructor(
    private router: Router,
    private fus: FileUploadService,
    private stn: SendTestNotificationsService,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.notificationSubscription = this.stn.getNotificationSubscription();
  }

  ngOnInit(): void {
    this.userService.getCurrUserProfileInfo().subscribe(resp => {
      this.profile = resp;
      this.name = this.profile.first_name + ' ' + this.profile.last_name;
      this.studentPages[2].rout = `/profile/${ this.profile.id }`;
      if (this.profile.photo) {
        this.getPofileImageOnChange(this.profile.photo);
      }
    });
    this.getLogo();
    this.subscription$.add(
      this.userService.activeUser$.subscribe(res => {
        if (res && res.photo) {
          this.getPofileImageOnChange(res.photo);
        }
      }));
    this.checkSafari();

    this.getUserSubscriptionInfo();

    this.userService.isISGSE().subscribe(isISGSE => {
      this.isISGSE = isISGSE;
      this.studentPages.find(page => page.name === 'Subject').disabled = isISGSE;
    })
  }

  private getUserSubscriptionInfo() {
    this.subscription$.add(
      this.userService.getSubscriptioninfo().subscribe(resp => {
        if (resp.length > 0) {
          this.isExamPreparation = resp[0].paymentPlanType === 'EXAM_PREPARATION';
        }
        this.isLoadedSubscriptionInfo = true;
      }));
  }

  getPofileImageOnChange(image) {
    this.subscription$.add(
      this.fus.download(image, 'IMAGE').subscribe((img: any) => {
        if (img.data.bytes) {
          this.imagePreviewFile = 'data:image/png;base64,' + img.data.bytes;
        }
      }));
  }

  goToDashboard(): void {
    this.router.navigateByUrl('/dashboard');
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }

  showNotificationBar(): void {
    this.isNotiMinimized = !this.isNotiMinimized;
    this.isNotiMinimized ? (this.switchState = 'minimized') : (this.switchState = 'opened');
  }

  userRole = Role;

  toggleStudentMenu(): void {
    this.isActive = !this.isActive;
  }

  menuClosing(): void {
    this.isActive = false;
  }

  menuClick(path: string): void {
    if (path === 'managing-api-keys') {
      this.router.navigate([`/managing-api-keys`]);
      return;
    }
    if (path === 'profile' || path === 'avatar') {
      this.router.navigate([`/profile/${ this.profile.id }`]);
      return;
    }
    if (path === 'video') {
      this.router.navigate([`/orientation-video`]);
      return;
    }
    // if (path === 'referral') {
    //   this.router.navigate([`/referral`]);
    //   return;
    // }
    // if (path === 'settings') {
    //   // navigate to settings
    // }
    // if (path === 'dashboard') {
    //   this.goToDashboard();
    //   // TODO: check if fix is working!!!
    //   if (this.profile.user_type === this.userRole.Teacher) {
    //     const ganttWithoutDataComponent = new GanttWithoutDataComponent(this.dialog);
    //     ganttWithoutDataComponent.importTimeTable();
    //   }
    // }
    // if (path === 'notes') {
    //   this.router.navigate(['/subjects/1/notes']);
    // }
    if (path === 'logout') {
      this.userService.saveUser({ role: Role.Unauthorized });
      window.localStorage.clear();
      this.router.navigateByUrl('');
      return;
    }
  }

  getLogo(): void {
    const url = window.location.href;
    if (url.includes('demo')) {
      this.logo = 'school-logo';
    }
    if (url.includes('c.curr')) {
      this.logo = 'logo-c';
    }
    if (url.includes('live.curr')) {
      this.logo = 'school-logo';
    }
    if (url.includes('test.curr')) {
      this.logo = 'school-logo';
    }
  }

  checkSafari() {
    const safariAgent = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    const chromeAgent = navigator.userAgent.toLowerCase().indexOf('chrome/') > -1;
    if ((chromeAgent) && (safariAgent)) {
      this.isSafari = false;
    }
  }

  navigateToTutorSession(): void {
    // this.userService.getTutorSessionLinks()
    //   .pipe(
    //     first(),
    //     catchError(err => {
    //       if (err) {
    //         this.toastr.info('No tutor sessions yet.');
    //       }
    //       return EMPTY;
    //     }))
    //   .subscribe(res => {
    //     if (this.isSafari === false) {
    //       window.open(res.data, '_blank');
    //     } else {
    //       window.location.assign(res.data);
    //     }
    //   });
  }
}
