import { Component, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { SchoolAdminUserProfileService } from './school-admin-user-profile.service';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';
import { ProfileFieldsNames, ProfileCardData, SchoolAdmin } from 'src/app/shared/components/profile-card/profile-card.model';
import { Router } from '@angular/router';

@Component({
  selector: 'curr-shool-admin-user-profile',
  templateUrl: './shool-admin-user-profile.component.html',
  styleUrls: ['./shool-admin-user-profile.component.scss']
})
export class ShoolAdminUserProfileComponent implements OnInit {
  profileCardData: ProfileCardData;
  profileFormFields = [
    {label: 'Email', fieldId: ProfileFieldsNames.EMAIL},
    {label: 'Phone', fieldId: ProfileFieldsNames.PHONE},
    {label: 'School', fieldId: ProfileFieldsNames.SCHOOL},
    {label: 'Year', fieldId: ProfileFieldsNames.YEAR},
  ]
  user: any;
  checkboxList = {
    options: [
      { label: 'Able to delete students', value: 0 },
      { label: 'Able to delete administators', value: 1 },
      { label: 'Able to delete teachers', value: 2 }
    ],
    value: [0, 1, 2],
    color: 'primary',
    type: 'checkbox',
    isMultipleSelect: true
  };
  private subscription = new Subscription();

  constructor(
    private _userService: SchoolAdminUserProfileService,
    private _profileDataService: ProfileDataService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._userService.getUserProfile().subscribe({
      next: user => {
        this.listenUserProfileCahnges();
      }
    });
  }

  updateUserProfile(updatedStudentData: any) {
    this._userService.updateUser(updatedStudentData).subscribe({
      next: (data: any) => {}
    });
  }

  private listenUserProfileCahnges() {
    this.subscription.add(
      this._profileDataService.UserProfile.subscribe({
        next: ((schoolAdmin: SchoolAdmin) => {
          this.profileCardData = {
            formFieldsToDisplay: [
              {label: 'Email', name: ProfileFieldsNames.EMAIL, verify: {valid: schoolAdmin.email.verify}},
              {label: 'Phone', name: ProfileFieldsNames.PHONE, verify: {valid: schoolAdmin.phone.verify}},
              {label: 'School', name: ProfileFieldsNames.SCHOOL},
              {label: 'Country', name: ProfileFieldsNames.COUNTRY},
              {label: 'Personal ID', name: ProfileFieldsNames.PERSONAL_ID}
            ],
            userData: schoolAdmin
          }
          this.user = schoolAdmin;
        })
      })
    );
  }

  deactivateUser() {
    this._router.navigateByUrl('/users');
  }

  deleteUser() {
    this._router.navigateByUrl('/users');
  }
}
