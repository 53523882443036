/**
 * Created by Max Bon. on 01/07/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ApplicationHttpClient } from '../../api/application-http-client';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';

 export interface StudentCard {
  id?: number;
  avgGrade?: number;
  avgMark?: number;
  course?: string;
  courseId?: number;
  ctAll?: number;
  ctCompleted?: number;
  ctPassed?: number;
  lastCumTest?: number;
  lessAll?: number;
  lessCompleted?: number;
  lessPassed?: number;
  saCompleted?: number;
  saRemained?: number;
  studyLevel?: string;
  subject?: string;
  teacher?: string;
  nextTest?: string;
  nextCumTest?: string;
  curriculumId?: number;
  img?: string;
  toBeCompletedPercentage?: number;
  couldBeImprovedPercentage?: number;
  proficientPercentage?: number;
  continueData?: any;
}

@Component({
  selector: 'curr-student-course-card',
  templateUrl: './student-course-card.component.html',
  styleUrls: ['./student-course-card.component.scss']
})
export class StudentCourseCardComponent implements OnInit {
  blocks = [];
  buttons = [];
  @Input() card: StudentCard = {};
  @Input() isDetailView = false;
  hint = {};
  infoIconText$: Observable<string>;
  notLoaded: boolean = true;

  constructor(
    private router: Router,
    private httpClient: ApplicationHttpClient,
    private commonService: CommonService,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.card.nextTest = '10.10.2020';
    this.transformImgTitle();
    this.getHintText();
    this.createBlocks();
    this.createButtons();
    this.infoIconText$ = this.commonService.select<string>('infoText');
  }

  goToTest(card, event): void {
    event.stopPropagation();
    if (card.continueData.materialType) {
      if (card.continueData.materialType === 'SA_TEST') {
        localStorage.setItem('curriculumId', card.curriculumId);
        this.router.navigate([`courses-details/${ card.courseId }/self-assessment/${ card.continueData.learningObjectiveId }`]);
      } else {
        localStorage.setItem('curriculumId', card.curriculumId);
        this.router.navigate([`courses-details/${ card.courseId }/cumulative-test/${ card.continueData.topicTestId }/${ card.continueData.userId }/${ card.continueData.schoolCourseStudentId }`]);
      }
    } else {
      this.router.navigate([`/courses-details/learning-resources/prior/${ card.curriculumId }/${ card.courseId }/${ card.continueData.nextTopicId }/prior`]);
    }
  }

  transformImgTitle(): void {
    if (this.card.subject === 'Mathematics') {
      this.card.img = 'Math';
      return;
    }
    // @ts-ignore
    this.card.img = this.card.subject.replaceAll(' ', '-');
  }

  createBlocks(): void {
    const {
      lessAll,
      lessPassed,
      ctAll,
      ctCompleted,
      ctPassed,
      avgGrade,
      avgMark,
      nextTest,
      saCompleted,
      saRemained
    } = this.card;
    this.blocks = [];

    this.blocks.push({
      title: 'Average grade for your CT',
      icon: 'info',
      // @ts-ignore
      tooltipText: this.hint?.data || 'Mystery course',
      bottomTitle: { label: avgGrade || 'Not evaluated' },
      progressBar: { color: 'accent', value: avgMark || 0 }
    });

    this.blocks.push({
      title: 'Lessons',
      bottomTitle: { label: this.getPercentageLabel(lessAll, lessPassed) },
      progressBar: { color: 'accent', value: Math.ceil((lessPassed / lessAll) * 100) || 0 }
    });

    const d = new Date(nextTest);
    this.blocks.push({
      title: 'Cummulative Tests',
      hnt: nextTest
        ? {
          label: 'Next Cummulative Test: ' + `${ d.getFullYear() }/${ d.getMonth() }/${ d.getDate() }`,
          icon: 'calendar',
          color: 'primary'
        }
        : undefined,
      bottomTitle: { label: `${ ctAll }/${ ctPassed } completed - ${ ctCompleted }%` },
      progressBar: {
        color: 'accent',
        value: Math.ceil((ctCompleted / ctAll) * 100) > 100 ? 100 : Math.ceil((ctCompleted / ctAll) * 100)
      }
    });

    const sac = Math.ceil(((saCompleted + saRemained) / saRemained) * 100);
    this.blocks.push({
      title: 'Percentage of completed Self Assessments',
      hnt: {
        label: saRemained > 0 ? `${ sac }% of your Self Assessments are incomplete` : 'All SA completed!',
        icon: saRemained > 0 ? 'warn' : 'icon-success',
        color: saRemained > 0 ? 'warn' : 'accent'
      },
      bottomTitle: { label: `${ saCompleted }% completed` },
      progressBar: { color: 'accent', value: saCompleted || 0 }
    });
  }

  private getPercentageLabel(all, completed) {
    const p = Math.ceil((completed / all) * 100) || 0;
    return `${ completed }/${ all } completed - ${ p }%`;
  }

  private createButtons() {
    if (this.isDetailView) {
      this.buttons.push({
        label: 'HISTORICAL ASSESSMENT RESULTS',
        callback: this.goToHistorical.bind(this),
        isDisabled: false
      });
      // this.buttons.push({
      //   label: 'SAVED MATERIALS',
      //   callback: this.goToDetails.bind(this),
      //   isDisabled: false
      // });
      // this.buttons.push({
      //   label: 'ALL NOTES',
      //   callback: this.goToDetails.bind(this),
      //   isDisabled: false
      // });
      // this.buttons.push({
      //   label: 'TAKE NEXT TOPIC TEST',
      //   callback: this.goToDetails.bind(this),
      //   isDisabled: false
      // });
    } else {
      this.buttons.push({
        label: 'Begin',
        callback: this.goToDetails.bind(this),
        isDisabled: false
      });
    }
  }

  goToDetails(card: StudentCard, event): void {
    event.stopPropagation();
    if (card?.toBeCompletedPercentage) {
      this.router.navigate([`courses-details/subject-details/${ card.courseId }/${ card.curriculumId }`]);
    } else {
      this.toastr.info('Subject is delivered offline.');
    }
  }

  goToHistorical(): void {
    this.router.navigate([`/courses-details/subjects/${ this.card.courseId }/grade-results`]);
  }

  private async getHintText() {
    this.hint = await this.httpClient.get(`/infoIconText`).toPromise();
    this.createBlocks();
  }
}