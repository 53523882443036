<div class="wrapper">
  <div class="dropdown" (click)="toggleDropdown()" [ngClass]="{ open: isShowed, error: isError }" currClickOutside
    (clickOutside)="closeDropDown()">
    <div [ngClass]="{ disabled: isDisabled }" fxLayout="row" fxLayoutAlign="space-between center">
      <div class="content">
        <div class="custom-select__trigger mat-body-2 ">
          <div class="cursor-pointer text" *ngIf="!selectedValue || selectedValue.length === 0">{{ placeholder }}</div>
          <div class="cursor-pointer text" *ngIf="selectedValue?.length > 0">
            {{ selectedLabel }}
          </div>
        </div>
        <div class="arrow-wrapper"><i class="arrow up"></i></div>
      </div>
    </div>
    <div class="custom-options" [@listAnimation]="isShowed" *ngIf="options.length > 0 && isShowed">
      <div currCustomScroll class="custom-options-wrapper ps">
        <span *ngFor="let option of options" class="custom-option cursor-pointer mat-body-2 text"
          (click)="selectValue(option.value, $event)"
          [ngClass]="selectedValue?.includes(option.value) ? 'selected' : ''">
          <mat-icon *ngIf="icon" [svgIcon]="icon" class="option-icon" [class]="icon"></mat-icon>
          {{ option.label }}
        </span>
      </div>
    </div>
  </div>

  <span *ngIf="isError" class="error-message">Please select value</span>
</div>
