<!--
 Created by Yaroslav S. on 10/07/2024
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="credits-error">
  <mat-icon [svgIcon]="'error'"></mat-icon>
  <div class="credits-error-text">
    You have run out of credits or hit your maximum monthly spend. Buy more credits or learn how to increase your
    limits.
  </div>
</div>
