/**
 * Created by Maxim Bon. on 19/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-import-teacher-time-table-popup',
  templateUrl: './import-teacher-time-table-popup.component.html',
  styleUrls: ['./import-teacher-time-table-popup.component.scss']
})
export class ImportTeacherTimeTablePopupComponent implements OnInit {

  ddOptions=[
    {label:'Semester 1 - 2020/2021', value: 1},
    {label:'Semester 2 - 2020/2021', value: 2},
    {label:'Semester 1 - 2021/2022', value: 3}
  ];

  constructor(public dialogRef: MatDialogRef<ImportTeacherTimeTablePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
  }

}
