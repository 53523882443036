/**
 * Created by Alex Poh. on 18/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationAnimationService {
  componentStates: { animationState: string; ignoreFunction?: (url: string) => any }[] = [];

  private isFirstInit = true;

  constructor(private router: Router) {}

  init(): { animationState: string } {
    if (this.isFirstInit) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (this.componentStates.length > 0) {
            if (
              !this.componentStates[this.componentStates.length - 1].ignoreFunction ||
              this.componentStates[this.componentStates.length - 1].ignoreFunction(event.url)
            ) {
              this.componentStates[this.componentStates.length - 1].animationState = event.url;
            }
          } else {
            this.componentStates.push({ animationState: event.url });
          }
        }
      });
      this.isFirstInit = false;
    }
    const result: { animationState: string } = { animationState: '' };
    this.componentStates.push(result);
    return result;
  }

  destroy() {
    this.componentStates.pop();
  }

  ignore(ignoreFunction: (url: string) => boolean) {
    this.componentStates[this.componentStates.length - 1].ignoreFunction = ignoreFunction;
  }
}
