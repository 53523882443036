<!-- Created by Maxim B. on 14/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="tests-container" fxLayout="row" fxLayoutAlign="space-between stretch">
  <div class="left-container" fxLayout="column" fxLayoutAlign="center start">
    <p class="date">
      Topic test -
      <span *ngIf="testCard?.testDateStart"
        >{{ testCard?.testDateStart | date: 'dd.MM.yyyy hh:mm' }} - {{ testCard?.testDateEnd | date: 'hh:mm' }}</span
      >
      <span *ngIf="!testCard?.testDateStart" class="warning">date not setted</span>
    </p>
    <h2 class="name mat-h2">{{ testCard?.name }}</h2>
    <div class="details-container mat-body-2" fxLayout="row" fxLayoutAlign="start center">
      <span *ngIf="testCard?.testType != 'notPassed'"
        >{{ testCard?.studentsPassed }}/{{ testCard?.studentsAll }} students passed
      </span>
      <span *ngIf="testCard?.testType == 'notPassed'">Assign to {{ testCard?.studentsAll }} students </span>
      <span class="divider">|</span>
      <span>Average grade: {{ testCard?.averageGrade }}</span>
      <span class="divider">|</span>
      <span class="warning" [class.all-tests-checked]="testCard?.checkedTestQuantity == testCard?.testQuantity">
        {{ testCard?.checkedTestQuantity }}/{{ testCard?.testQuantity }} tests checked
      </span>
      <curr-red-label
        *ngIf="testCard?.checkedTestQuantity != testCard?.testQuantity"
        [text]="'Student responses require your attention'"
      ></curr-red-label>
    </div>
  </div>
  <div
    class="right-container"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    *ngIf="testCard?.testType == 'needToCheck'"
  >
    <curr-button size="big"  variant="contained" color="primary">
      REVIEW ANSWERS
    </curr-button>
  </div>
  <div
    class="right-container"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    *ngIf="testCard?.testType == 'checked'"
  >
    <curr-button size="big"  variant="contained" color="primary">
      VIEW ANSWERS
    </curr-button>
    <curr-button size="big"  variant="contained" color="primary">
      EXPORT RESULTS
    </curr-button>
  </div>
  <div
    class="right-container"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="8px"
    *ngIf="testCard?.testType == 'notPassed'"
  >
    <curr-button size="big"  variant="contained" color="primary">
      SET THE DUE DATE
    </curr-button>
    <curr-button size="big"  variant="contained" color="primary" icon="edit">
      EDIT
    </curr-button>
  </div>
</div>
