import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { MatDialog } from '@angular/material/dialog';
import { CreateSubjectPopupComponent } from '../../shared/components/create-subject-popup/create-subject-popup.component';
import { Router } from '@angular/router';

export enum GettingStartedTaskType {
  CURRICULUM_MANAGEMENT = 'CURRICULUM_MANAGEMENT',
  IMPORT_TIMETABLE = 'IMPORT_TIMETABLE',
  SETUP_PREDICTED_GRADE = 'SETUP_PREDICTED_GRADE'
}

@Injectable({
  providedIn: 'root'
})
export class GettingStartedTaskService {
  TASK_BUTTONS = {
    CURRICULUM_MANAGEMENT: {
      title: 'Getting started tasks',
      value: { label: 'Review scheme of work and lesson plans', checked: false },
      buttons: [
        {
          label: 'MY MATERIALS',
          variant: 'contained',
          size: 'small',
          color: 'primary',
          callback: () => {
            this.router.navigate([`/curriculum-management`]);
          }
        }
      ]
    },
    IMPORT_TIMETABLE: {
      value: { label: 'Import your teaching time table', checked: false },
      buttons: [
        { label: 'IMPORT', variant: 'contained', size: 'small', color: 'primary', callback: this.importTimetable }
      ]
    },
    SETUP_PREDICTED_GRADE: {
      value: { label: 'teacherDashboard.gettingStartedTasks.setUpBenchmark', checked: false },
      buttons: [
        {
          label: 'SETUP',
          variant: 'contained',
          size: 'small',
          color: 'primary',
          callback: this.scrollToTable
        }
      ]
    }
  };

  constructor(private httpClient: ApplicationHttpClient, public dialog: MatDialog, private router: Router) {}

  getTasks() {
    return this.httpClient.get(`/schoolStartedTasks`);
  }

  setTaskChecked(schoolStartedTaskId: number) {
    return this.httpClient.patch(`/schoolStartedTasks/${schoolStartedTaskId}/complete`);
  }

  getTaskData(type: GettingStartedTaskType) {
    return this.TASK_BUTTONS[type];
  }

  importTimetable(btn, dialog: MatDialog) {
    dialog.open(CreateSubjectPopupComponent, {
      width: '1069px',
      id: 'import-timetable',
      data: {
        importType: 'timetable'
      }
    });
  }

  scrollToTable() {
    const top = document.getElementById('statistic-table').offsetTop;
    const opt = { top, behavior: 'smooth' };
    // @ts-ignore
    window.scrollTo(opt);
  }
}
