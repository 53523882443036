/**
 * Created by Maxim B. on 02/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'curr-grade-guideline',
  templateUrl: './grade-guideline.component.html',
  styleUrls: ['./grade-guideline.component.scss']
})

/*
* Usage example
* <curr-grade-guideline [(gradleValues)]="gradleValues"></curr-grade-guideline>
* gradleValues = [
    {
      label: 'For years 1-3', years: [1, 2, 3],
      grades: [
        { label: 'A+', max: 100, min: 95 },
      ]
    }
  ]
* */

export class GradeGuidelineComponent implements OnInit, OnChanges {

  @Input() gradleValues: { label: string, years: number[], grades: any[] }[];
  @Output() valueChange = new EventEmitter<{}>();

  readonly gradeLabels = [
    'A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-',
    'D+', 'D', 'D-', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
    'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];

  currentGradeObj = { label: '', grades: [] };
  currentGrades: { label: any, max: number, min: number }[] = [];
  disabledYears: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  yearPickerOptions: any;

  constructor() {
  }

  ngOnChanges(): void {
    this.disabledYears = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.yearPickerOptions = this.gradleValues.map(i => {
      i.years.forEach(y => {
        this.disabledYears[y - 1] = y;
      });
      return { label: i.label, years: i.years };
    });
  }

  ngOnInit(): void {
  }

  updateValue() {
    this.valueChange.emit(this.gradleValues);
  }

  setCurrentGrade(item) {
    if (item) {
      this.currentGradeObj = this.gradleValues.find(i => i.label === item.label);
      this.currentGrades = this.currentGradeObj.grades || [];
    }
  }

  addNewGrade() {
    if (!this.currentGrades) {
      return;
    }
    this.currentGrades.push(this.getNewGrade());
  }

  private getNewGrade() {
    const grade = {
      label: 'A',
      max: 100,
      min: 99
    };
    if (!this.currentGrades.length) {
      return grade;
    }
    const lastLabel = this.currentGrades[this.currentGrades.length - 1].label;
    if (!isNaN(lastLabel)) {
      grade.label = (+lastLabel + 1).toString();
    } else {
      const indexOfLastLabel = this.gradeLabels.indexOf(lastLabel) || 0;
      grade.label = this.gradeLabels[indexOfLastLabel + 1];
    }

    grade.max = (this.currentGrades[this.currentGrades.length - 1].min - 1) || 100;
    grade.min = (grade.max - 1) > 0 ? grade.max - 1 : 0;
    if (grade.max <= 0) {
      grade.max = 0;
    }

    return grade;
  }

  recalculateGradePoints(item: any, index: number) {
    if (index >= 0) {
      this.checkGradeForEdgeCases(item);
      const nextItem = this.currentGrades[index + 1];

      if (!nextItem) {
        return;
      } else {
        if ((nextItem.max + 1) !== item.min) {
          nextItem.max = item.min - 1;
          this.checkGradeForEdgeCases(nextItem);
        }
        this.recalculateGradePoints(nextItem, index + 1);
      }
    }

  }


  private checkGradeForEdgeCases(item: any) {
    if (item.max > 100) {
      item.max = 100;
    }
    if (item.max <= 0) {
      item.max = 0;
    }
    if (item.min >= item.max) {
      item.min = item.max - 1;
    }
    if (item.min < 0) {
      item.min = 0;
    }
  }

  addNewOption(opt: { label: string; years: number[] }) {
    this.currentGradeObj = { label: '', grades: [] };
    this.currentGrades = [];
    opt.years.forEach(y => {
      this.disabledYears[y - 1] = y;
    });
    this.gradleValues.unshift({ label: opt.label, years: opt.years, grades: [] });
  }
}
