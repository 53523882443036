/**
 * Created by Iryna Iak. on 27/10/2021
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'curr-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalPopupComponent {
  constructor(public dialog: MatDialog) {
  }
}
