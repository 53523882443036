/**
 * Created by Iryna Iak. on 18/10/21.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { BehaviorSubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from '../../../auth/auth.service';
import { CalculatedPriceService } from './calculated-price.service';
import { UserService } from '../../user.service';
import { ISubject } from '../choose-subject/choose-subject.component';

@Component({
  selector: 'curr-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class
StepperComponent implements OnInit, OnDestroy {

  @Input() animationDone;
  @ViewChild('stepper') stepper: MatStepper;
  calculatedSubjects$: BehaviorSubject<any>;
  selectedIndex: number = 0;
  isLinear = false;
  selectedSubjects: ISubject[] = [];
  changedPlan: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  planOptions = [
    // { value: 'PLUS', label: 'Exam Boost PLUS' },
    { value: 'EXAM_PREPARATION', label: 'Exam Preparation', disabled: false },
    { value: 'STANDARD', label: 'Exam Boost', disabled: true }
  ];
  isLoading: boolean = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private calculatedPriceService: CalculatedPriceService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    if (this.router.url !== '/add-subjects') {
      this.authService.checkUserAuthProgress();
    }
    this.calculatedSubjects$ = this.calculatedPriceService.getCalculatedPrice;
  }

  setIndex(event): void {
    this.selectedIndex = event.selectedIndex;
  }

  triggerClick(): void {
    if(this.selectedIndex === 2){
      this.calculatedSubjects$ = this.calculatedPriceService.getCalculatedPrice;
    }
  }

  changePlan(value: string, id: number): void {
    const item = {
      subjectId: id,
      paymentPlanType: value
    };
    this.changedPlan = value;
    const includesItemIdx = this.selectedSubjects.findIndex((elem: any) => elem.subjectId === id);
    if (~includesItemIdx) {
      this.selectedSubjects[includesItemIdx].paymentPlanType = value;
    } else {
      this.selectedSubjects.push(item);
    }
    this.userService.setUpdatedPlan(item)
      .pipe(first())
      .subscribe(res => {
        const newRes = {
          ...res,
          subjects: res.subjects.sort((a, b) => a.id - b.id)
        };
        this.calculatedSubjects$.next(newRes);
      });
  }

  getTotalSubjectsPrice(subjects): number {
    return subjects?.reduce((sum, subject) => sum + subject.price, 0);
  }

  goNextStep(): void  {
    this.stepper.next();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
