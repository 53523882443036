/**
 * Created by Alex Poh. on 18/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadChildren, Router } from '@angular/router';
import { UserService } from '../user.service';
import { Role } from '../role';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ModuleLoaderService {
  private roleModuleMap: Map<Role, LoadChildren> = new Map();
  private module$: Observable<LoadChildren>;

  constructor(private userService: UserService, private router: Router, private location: Location) {
    this.roleModuleMap.set(Role.Unauthorized, () => import('../../auth/auth.module').then(m => m.AuthModule));
    this.roleModuleMap.set(Role.Student, () => import('../../student/student.module').then(m => m.StudentModule));
    this.roleModuleMap.set(Role.Teacher, () => import('../../teacher/teacher.module').then(m => m.TeacherModule));
    this.roleModuleMap.set(Role.SchoolAdmin, () =>
      import('../../school-admin/school-admin.module').then(m => m.SchoolAdminModule)
    );
    this.roleModuleMap.set(Role.HubAdmin, () => import('../../hub-admin/hub-admin.module').then(m => m.HubAdminModule));
  }

  getModule(): Observable<LoadChildren> {
    return this.userService.getCurrentUser().pipe(
      distinctUntilChanged(),
      map(user => {
        return this.roleModuleMap.get(user.role);
      })
    );
  }

  initModuleLoadingForCurrentRole() {
    this.module$ = this.getModule();
    this.module$.subscribe(module => {
      let config = this.router.config;
      config = config.filter(item => item.path !== '');
      config.push({
        path: '',
        loadChildren: module
      });
      this.router.resetConfig(config);
      this.router.navigate([this.location.path()]);
    });
  }
}
