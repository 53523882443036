/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question } from '../../../student/test.model';
import { ISubjectTestSelectTopic } from './test-question-view.interface';

@Component({
  selector: 'curr-test-question-view',
  templateUrl: './test-question-view.component.html',
  styleUrls: ['./test-question-view.component.scss']
})
export class TestQuestionViewComponent implements OnInit {
  @Input() set question(question: Question) {
    this.questionData = question;
    const filteredTopic = this.topics.find(item => item.id === this.questionData.curriculum_lobj_id);
    if (filteredTopic) this.filteredTopic = filteredTopic.title.replace(/<[^>]*>/g, '');
  }
  @Input() topics: ISubjectTestSelectTopic[] = [];

  @Output() onEditQuestion = new EventEmitter<Question>();
  @Output() onChangeMarkType = new EventEmitter<Question>();

  questionData: Question;
  filteredTopic: string;

  constructor() {}

  ngOnInit() {
    const filteredTopic = this.topics.find(item => item.id === this.questionData.curriculum_lobj_id);
    if (filteredTopic) this.filteredTopic = filteredTopic.title.replace(/<[^>]*>/g, '');
  }

  editQuestion() {
    this.onEditQuestion.emit(this.questionData);
  }

  setQuestionsMarkType(question: Question) {
    if (question.testAndQuestionTypes.length === 0) {
      question.testAndQuestionTypes = this.questionData.testAndQuestionTypes;
    }

    this.onChangeMarkType.emit(question);
  }
}
