import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'curr-curriculum-managment-card',
  templateUrl: './curriculum-managment-card.component.html',
  styleUrls: ['./curriculum-managment-card.component.scss']
})
export class CurriculumManagmentCardComponent implements OnInit {
  @Output()
  publish = new EventEmitter<boolean>();

  @Output()
  delete = new EventEmitter<boolean>();

  constructor(public router: Router) {}

  btnPublishStyle = { width: '110px', padding: 0, textAligned: 'center' };
  btnTrashStyle = { width: '101px', padding: 0, textAligned: 'center' };

  @Input() card: any;
  @Input() type: string;

  ngOnInit() {
  }
}
