<!--
 Created by Dmytro Sav. on 4/5/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
 
<div class="wrapper">
  <div
    class="dropdown"
    (click)="toggleDropdown()"
    [ngClass]="{ open: isShowed, error: isError }"
    currClickOutside
    (clickOutside)="closeDropDown()"
  >
    <div class="custom-select" [class.disabled]="isDisabled">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text">
          <div class="text" [class.disabled]="isDisabled">{{ about.key }}</div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="options">
            <mat-icon [matMenuTriggerFor]="beforeMenu" (click)="$event.stopPropagation()">more_vert</mat-icon>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editItem()">
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button mat-menu-item (click)="deleteItem()">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </div>
          <div class="arrow-wrapper m-r-24 m-l-8"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="divider"></div>
      <div class="content-container paper-title content-height" [innerHTML]="about.value">
      </div>
    </div>
  </div>
</div>
