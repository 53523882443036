import { Injectable } from '@angular/core';
import { GraphOptions } from '../graph-options';
import * as d3 from 'd3';
import { StudentGrade } from '../../components/student-dashboard-chart/student-dashboard-chart.component';
import { environment } from '../../../../environments/environment.prod';
import { ChartPeriod } from '../../components/dashboard-chart/dashboard-chart.service';

@Injectable()
export class StudentGradesStatisticService {
  private svg: any;
  private options: GraphOptions;
  private data: StudentGrade[];
  // TODO: remove?
  // private points = [];
  private xAxis;
  private yAxis;
  private maxValueY = 100;
  private isFirstTime = true;
  private isAnimationEnabled = true;
  gradePositionsIndex = { E: 1, D: 2, C: 3, B: 4, A: 5 };
  studentGradesStatisticColors = environment.studentGradesStatisticColors;
  tooltip;
  subjectColorMap = {};
  currentPeriod: ChartPeriod;
  parseDate;
  monthMilisec = 2_628_000_000;
  dayMilisec = 86_400_000;

  constructor() {
  }

  init(svg: HTMLElement, options: GraphOptions) {
    this.svg = d3.select(svg);
    this.options = options;
    this.parseDate = d3.timeParse('%m-%d-%Y');
    this.defineTooltip();
  }

  updateHistogram(data, currentPeriod: ChartPeriod) {
    this.currentPeriod = currentPeriod;
    if (this.data) {
      this.removeOldContent(data);
      this.data = this.prepareConvergedData(data);
    }
    if (this.isFirstTime) {
      this.isFirstTime = false;
      this.data = this.prepareConvergedData(data);
      // this.updateView();
      setTimeout(() => {
        this.updateView();
      }, 1000);
    } else {
      this.updateView();
    }
  }

  updateView() {
    this.updateSubjectColorMap(this.data);
    this.setupViewBox();
    this.addAxes();
    this.removeUnnecessaryLines();
    this.addGrid();
    this.addLines();
    this.addPoints();
  }

  prepareConvergedData(data: StudentGrade[]): StudentGrade[] {
    data.forEach(d => {
      d.period = this.parseDate(d.period);
    });
    this.data = data;
    return data;
  }

  removeOldContent(data) {
    this.svg.selectAll('circle').remove();
  }

  setupViewBox() {
    this.svg.attr('viewBox', [0, 0, this.options.width, this.options.height]);
  }

  addAxes() {
    if (!this.xAxis) {
      this.xAxis = this.svg.append('g');
    }
    if (!this.yAxis) {
      this.yAxis = this.svg.append('g');
    }

    this.xAxis
      .attr('transform', `translate(0,${ this.options.height - this.options.margin.bottom })`)
      .call(this.getXAxis());
    this.yAxis.append('g').call(this.getYAxis());
  }

  removeUnnecessaryLines() {
    this.svg.selectAll('.tick>line').remove();
  }

  addGrid() {
    const lines = this.svg
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', `translate(${ this.options.margin.left },0)`)
      .call(this.getYAxisGrid())
      .call(gNext => gNext.select('.domain').remove())
      .selectAll('line');
    lines.attr('stroke-dasharray', 5).attr('style', 'color: var(--theme-color-grey-1);');
    lines.filter((d, i) => i === 0).attr('stroke-dasharray', 0);
  }

  getXAxis() {
    const x = d3
      .scaleTime()
      .domain(this.getMinMaxDateArr())
      .range([this.options.margin.left, this.options.width - this.options.margin.right]);
    return g =>
      g
        .attr('transform', `translate(0,${ this.options.height - this.options.margin.bottom })`)
        .attr('class', 'mat-caption')
        .call(
          d3
            .axisBottom(x)
            .ticks(this.getTicks())
            .tickFormat(this.getXAxisFormat)
        )
        .call(gNext => gNext.select('.domain').remove());
  }

  getXAxisFormat = d => {
    if (this.currentPeriod === ChartPeriod.YEAR || this.currentPeriod === ChartPeriod.SEMESTER) {
      const formatTime = d3.timeFormat('%B');
      return formatTime(d)
        .substring(0, 3)
        .toUpperCase();
    }
    if (this.currentPeriod === ChartPeriod.MONTH) {
      const formatTime = d3.timeFormat('%d');
      return formatTime(d);
    }
  };

  getX() {
    return d3
      .scaleTime()
      .domain(this.getMinMaxDateArr())
      .nice()
      .range([this.options.margin.left, this.options.width - this.options.margin.right]);
  }

  getYAxis() {
    return g =>
      g
        .attr('transform', `translate(${ this.options.margin.left },0)`)
        .attr('class', 'mat-caption')
        .call(d3.axisLeft(this.getY()).tickFormat(this.gradeFormats))
        .call(gNext => gNext.select('.domain').remove())
        .call(gNext => gNext.select('.tick:last-of-type text').clone());
  }

  getY() {
    return d3
      .scaleLinear()
      .domain([0, this.maxValueY])
      .nice()
      .range([this.options.height - this.options.margin.bottom, this.options.margin.top]);
  }

  getYAxisGrid() {
    return d3
      .axisLeft(this.getY())
      .tickSize(-this.options.width)
      .tickFormat('')
      .ticks(5);
  }

  gradeFormats = d => {
    const alphabetGrades = ['E', 'D', 'C', 'B', 'A'];
    if (Math.floor(d / 20) !== d / 20 || d === 0) {
      return;
    } else {
      return alphabetGrades[Math.floor(d / 20) - 1];
    }
  };

  addPoints() {
    const points = this.svg
      .append('g')
      .selectAll('dot')
      .data(this.data);
    const circles = points.enter().append('circle');
    circles
      .attr('cx', d => {
        return this.getX()(d.period);
      })
      .attr('cy', d => {
        return this.getY()(parseInt(d.grade, 10));
      })
      .attr('r', d => {
        return this.getGradeRadius(d.grade);
      })
      .attr('stroke-width', 2)
      .attr('opacity', '0')
      .attr('fill', d => {
        return this.getGradeFillColor(d);
      })
      .attr('class', 'cssClass')
      .attr('shape-rendering', 'geometricPrecision')
      .on('mouseover', d => {
        this.tooltip
          .transition()
          .duration(200)
          .style('opacity', 0.9);
        // this.tooltip
        //   .html(`${ d.course }`)
        //   .style('left', d3.event.pageX + 'px')
        //   .style('top', d3.event.pageY - 28 + 'px');
      })
      .on('mouseout', d => {
        this.tooltip
          .transition()
          .duration(500)
          .style('opacity', 0);
      });
    circles
      .transition()
      .delay(this.isAnimationEnabled ? 1000 : 0)
      .duration(this.isAnimationEnabled ? 1000 : 0)
      .style('opacity', '1');
  }

  addLines() {
    const [starDate, endDate] = this.getMinMaxDateArr();
    this.data.forEach((series, i) => {
      this.addLine(
        [
          { ...series, period: starDate },
          { ...series, period: endDate }
        ],
        'red-statistic'
      );
    });
    // this.addLine(this.data, 'red-statistic');
  }

  addLine(data, cssClass: string) {
    const line = this.svg.append('path');
    line.lower();
    line
      .datum(data)
      .attr('fill', 'none')
      // .attr('class', cssClass)
      .attr('stroke', d => {
        return this.getGradeFillColor(d[0]);
      })
      .attr('stroke-width', 2)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .attr('d', this.getLine());
  }

  getLine() {
    return d3
      .line()
      .x(d => {
        return this.getX()(d.period);
      })
      .y(d => {
        return this.getY()(parseInt(d.predictedGrade, 10));
      });
  }

  private getGradePositionIndex(grade: string) {
    return Math.ceil(parseInt(grade, 10) / 20);
  }

  private getGradeRadius(grade: string) {
    return this.getGradePositionIndex(grade) * 2 + 2;
  }

  private getGradeFillColor(d: StudentGrade) {
    return this.subjectColorMap[d.courseId] || this.studentGradesStatisticColors[0];
  }

  private defineTooltip() {
    // Define the div for the tooltip
    this.tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'student-chart-tooltip')
      .style('opacity', 0);
  }

  private updateSubjectColorMap(data: StudentGrade[]) {
    if (!data.length) {
      return;
    }
    data.forEach(i => {
      if (!this.subjectColorMap[i.courseId]) {
        this.subjectColorMap[i.courseId] = this.assignColorForSubject();
      }
    });
  }

  private assignColorForSubject() {
    const colorsLen = this.studentGradesStatisticColors.length;
    const objLen = Object.keys(this.subjectColorMap).length;
    if (colorsLen > objLen) {
      return this.studentGradesStatisticColors[objLen];
    } else {
      return this.studentGradesStatisticColors[objLen % colorsLen];
    }
  }

  private getMinMaxDateArr() {
    if (this.currentPeriod === ChartPeriod.YEAR || this.currentPeriod === ChartPeriod.SEMESTER) {
      const dates = this.data.map(x => x.period);
      const latest = new Date(Math.max.apply(null, dates)).getTime() + this.monthMilisec;
      const earliest = new Date(Math.min.apply(null, dates)).getTime() - this.monthMilisec;
      return [earliest, latest];
    }

    if (this.currentPeriod === ChartPeriod.MONTH) {
      const dates = this.data.map(x => x.period);
      const latest = new Date(Math.max.apply(null, dates)).getTime() + this.dayMilisec;
      const earliest = new Date(Math.min.apply(null, dates)).getTime() - this.dayMilisec;
      return [earliest, latest];
    }
  }

  private getTicks() {
    if (this.currentPeriod === ChartPeriod.YEAR || this.currentPeriod === ChartPeriod.SEMESTER) {
      return d3.timeMonth.every(1);
    }
    if (this.currentPeriod === ChartPeriod.MONTH) {
      return d3.timeDay.every(2);
    }
  }
}
