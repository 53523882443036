<!-- Created by Alex Poh. on 03/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-subject-details">
  <div class="m-b-24">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="page-title-section">
    <div class="page-title">{{ subject.subject }}</div>
    <div class="subject-years" *ngIf="subject.year">Year {{subject.year}}</div>
  </div>

  <div class="page-title-subtitle">Scheme of Work</div>

  <div [@routeAnimations]="animationData.animationState">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- <div class="container">
  <div class="m-b-40">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>
  <div class="form-container" *ngIf="isNewSubject">
    <div class="name m-b-16" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="16px">
      <div class="name-container m-b-16" fxLayout="row" fxLayoutAlign="start center">
        <span class="subject-name">{{ subject?.title }}</span>
        <button mat-icon-button (click)="editName()">
          <mat-icon svgIcon="edit" color="primary"></mat-icon>
        </button>
      </div>
      <ng-template [ngTemplateOutlet]="buttonsMenu"></ng-template>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between" class="m-b-16" *ngIf="!isNewSubject">
    <div fxFlex>
      <div class="mat-display-2 m-b-8">{{ subject.title }}</div>
      <div class="year-and-level">
        <div class=" mat-body-1 m-r-16">Year: {{ subject.year }}</div>
        <mat-divider class="vertical-divider m-r-16" [vertical]="true"></mat-divider>
        <div class=" mat-body-1 m-r-16 mat-caption">Level: {{ subject.level }}</div>
      </div>
    </div>
    <div fxFlex fxLayout="row" fxFlexAlign="center" fxLayoutAlign="end" fxLayoutGap="8px" class="control-buttons">
      <ng-container>
        <curr-button
          class="curr-button"
          *ngFor="let button of buttons"
          (btnClick)="button.action()"
          variant="contained"
          size="small"
          class=""
          >{{ button.label }}</curr-button
        >
      </ng-container>
    </div>
  </div>
  <mat-divider class="m-b-16"></mat-divider>
  <div class="navigate-tabs-container" fxLayout="row" fxLayoutAlign="space-between center">
    <nav
      mat-tab-nav-bar
      class="m-b-24 custom-ink-size"
      [fxFlex]="isNewSubject ? '80%' : '100%'"
      *ngIf="router.url.indexOf('ganttChart') == -1 && router.url.indexOf('sunburst') == -1"
    >
      <a
        mat-tab-link
        *ngFor="let paper of subject.papers"
        [routerLink]="'paper/' + paper.id"
        color="primary"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
      >
        {{ paper.title }}
      </a>
    </nav>
    <curr-button fxFlex="nogrow" *ngIf="isNewSubject" (btnClick)="addPaper()" [icon]="'plus'"
      >ADD PAPER
    </curr-button>
  </div>
  <div [@routeAnimations]="animationData.animationState">
    <router-outlet></router-outlet>
  </div>
</div> -->

<!-- <ng-template #buttonsMenu>
  <div fxFlex fxLayout="row" fxFlexAlign="center" fxLayoutAlign="end" fxLayoutGap="8px" class="control-buttons">
    <ng-container>
      <curr-button
        *ngFor="let button of buttons"
        (btnClick)="button.action()"
        variant="contained"
        size="small"
        class="curr-button"
        >{{ button.label }}</curr-button
      >
    </ng-container>
  </div>
</ng-template> -->
