<!--
 Created by Dmytro Sav. on 4/29/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="wrapper">
  <div class="mat-h2 m-b-0 m-l-16">Questions most commonly answered incorrectly</div>
  <mat-divider class="divider m-t-16 m-b-16"></mat-divider>
  <div  fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center" *ngIf="!questions.length">
    <span class="mat-body-1"  class="no-questions" fxFlex>No questions to display. It seems, your students are really good</span>
  </div>
  <div class="container" fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center" *ngIf="questions.length">
    <div class="card" *ngFor="let question of questions" fxLayout="row" fxLayoutAlign="space-between">
      <div class="mat-h2 m-b-0">{{ question.label }}</div>
      <div class="mat-body-1">{{ question.count }} incorrect answers</div>
    </div>
  </div>
</div>
