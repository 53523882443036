/**
 * Created by Maxim B. on 16/06/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Question, QuestionsLabels, QuestionType} from '../../../student/test.model';
import { TestCreationService } from '../../../shared-pages/edit-test/test-creation.service';

@Component({
  selector: 'curr-question-database-popup',
  templateUrl: './question-database-popup.component.html',
  styleUrls: ['./question-database-popup.component.scss']
})
export class QuestionDatabasePopupComponent implements OnInit {
  ddQuestionTypeOptions;
  topic = '';
  type = '';
  cumulativeQuestions: Question[] = [];
  isDataLoading = false;
  isCTCreation = false;
  selectedQuestions = [];
  topicIds = [];
  selectedQuestionsIds = [];
  questionType: string;

  constructor(public dialogRef: MatDialogRef<QuestionDatabasePopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private tcService: TestCreationService) {
    this.ddQuestionTypeOptions = [
      { value: null, label: 'All Question Types' },
      { value: QuestionType.OPEN_ENDED, label: QuestionsLabels.OPEN_ENDED },
      { value: QuestionType.SELECT_ONE, label: QuestionsLabels.MULTI_CHOICE },
      { value: QuestionType.SINGLE_ANSWER, label: QuestionsLabels.SINGLE_ANSWER },
      { value: QuestionType.TRUE_OR_FALSE, label: QuestionsLabels.TRUE_FALSE },
      { value: QuestionType.FILE_INPUT, label: QuestionsLabels.FILE },
      { value: QuestionType.MULTIPART, label: QuestionsLabels.MULTI_LEVEL }
    ];
  }

  ngOnInit(): void {
    this.isCTCreation = this.data.isCTCreation;
    this.selectedQuestions = this.data.questions;
    this.questionType = this.data.questionType;
    this.topicIds = this.data.topicIds && this.data.topicIds[0] ? this.data.topicIds : [];
    if (this.isCTCreation) {
      this.getQuestionsFromDB();
    } else {
      this.getMasterTopicQuestions();
    }
  }

  getMasterTopicQuestions() {
    this.tcService.getMasterTopicQuestions(this.topicIds).subscribe(
      resp => {
        const r = resp as any;
        if (r.data) {
          this.cumulativeQuestions = r.data.items;
        }
      },
      err => {
        this.isDataLoading = false;
      },
      () => {
        this.isDataLoading = false;
      }
    );
  }

  getQuestionsFromDB() {
    this.selectedQuestionsIds =
      this.selectedQuestions.map(q => {
        return q.id;
      }) || [];
    this.isDataLoading = true;
    this.tcService.addQuestionsFromDataBase(this.topicIds, this.selectedQuestionsIds, this.questionType).subscribe(
      resp => {
        const r = resp as any;

        if (r) {
          this.cumulativeQuestions = r;
        }
      },
      err => {
        this.isDataLoading = false;
      },
      () => {
        this.isDataLoading = false;
      }
    );
  }

  isQuestionInCT(q: Question) {
    return this.selectedQuestions.find(i => i.id === q.id);
  }

  selectTopic(val: any | []) {
    this.topic = val;
  }

  selectType(val: any | []) {
    this.type = val;
  }

  // TODO: remove?
  filterEntries($event: KeyboardEvent) {
  }

  onAddRemoveToCT(val: boolean, question: Question) {
    if (val) {
      this.selectedQuestions.push(question);
    } else {
      const ind = this.selectedQuestions.findIndex(i => i.id === question.id);
      if (ind > -1) {
        this.selectedQuestions.splice(ind, 1);
      }
    }
  }
}
