/**
 * Created by Daniil Vat. on 8/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { IReportStats } from '../../interfaces/report-stats';
import { fadeAnimation } from '../../../shared/animations/fade.animation';

interface IReportStatsItem {
  label: string;
  generateValue: (value: number | string) => number | string;
  text: string;
  icon: string;
}
const REPORT_STATS_ITEMS: IReportStatsItem[] = [
  {
    label: 'percentageAttemptedAllQuestions',
    generateValue: (value: number) => `${value}%`,
    text: 'of pupils attempted all questions',
    icon: 'done'
  },
  {
    label: 'outOfTimeCount',
    generateValue: (value: number) => value,
    text: 'pupils were unable to complete the paper in time',
    icon: 'access-time'
  },
  {
    label: 'lowerHigherMarkDiff',
    generateValue: (value: number) => `${value} marks`,
    text: 'The paper differentiated between the lowest and highest marks',
    icon: 'thumbs-up-down'
  },
  {
    label: 'highestMark',
    generateValue: (value: string) => value,
    text: 'the highest mark obtained ',
    icon: 'thumb-up'
  },
  {
    label: 'lowestMark',
    generateValue: (value: string) => value,
    text: 'the lowest mark obtained ',
    icon: 'thumb-down'
  }
];

@Component({
  selector: 'curr-report-stats',
  templateUrl: './report-stats.component.html',
  styleUrls: ['./report-stats.component.scss'],
  animations: [fadeAnimation]
})
export class ReportStatsComponent implements OnInit {
  @Input() data: IReportStats;

  reportStatsItems = REPORT_STATS_ITEMS;

  constructor() {}

  ngOnInit() {}
}
