import { Component, OnInit, ViewChild } from '@angular/core';
import {
  CreatePaymentMethodCardData, StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions
} from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { COUNTRIES } from 'src/app/shared/const/countries';
import { AuthService } from '../../../auth/auth.service';
import { UserService } from '../../user.service';
import { FailedMessageService } from '../../../auth/sign-up-checkout/payment-failed-page/failed-message.service';


@Component({
  selector: 'curr-payment-part',
  templateUrl: './payment-part.component.html',
  styleUrls: ['./payment-part.component.scss']
})
export class PaymentPartComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  stripeValidationState = {
    number: false,
    expiry: false,
    cvc: false
  };
  countries = COUNTRIES;
  clientKey;
  securityCard: boolean;
  isLoading: boolean;
  selectedCountry: string = 'United Kingdom';
  stripeTest: FormGroup;
  invalidCard: string;
  paymentId: string;
  destroyed$: Subject<any> = new Subject();
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Roboto", Roboto, sans-serif',
        lineHeight: '20px',
        fontSize: '17px',
        '::placeholder': {
          color: '#a4a3a3'
        }
      }
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };
  paymentRequest: google.payments.api.PaymentDataRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['AMEX', 'VISA', 'MASTERCARD']
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'example',
            gatewayMerchantId: 'exampleGatewayMerchantId'
          }
        }
      }
    ],
    merchantInfo: {
      merchantId: '12345678901234567890',
      merchantName: 'Test'
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: '0.10',
      currencyCode: 'GBP',
      countryCode: 'GB'
    },
    callbackIntents: ['PAYMENT_AUTHORIZATION']
  };
  userId: number;

  constructor(
    private router: Router,
    private authService: AuthService,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private userService: UserService,
    public failedMessage: FailedMessageService
  ) {
  }

  ngOnInit(): void {
    this.userService.getCurrUserProfileInfo()
      .pipe(first())
      .subscribe(resp => {
        this.userId = resp.id;
      });
    this.stripeTest = this.fb.group({
      name: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: ['', [Validators.required]]
    });
  }

  changeStripeNumber(event: StripeCardElementChangeEvent): void {
    switch (event.elementType as string) {
      case 'cardExpiry':
        this.stripeValidationState.expiry = event.complete;
        break;
      case 'cardCvc':
        this.stripeValidationState.cvc = event.complete;
        break;
      case 'cardNumber':
        this.stripeValidationState.number = event.complete;
        break;
    }
    this.invalidCard = event?.error?.message || '';
  }

  createToken(): void {
    this.isLoading = true;
    const params: CreatePaymentMethodCardData = {
      type: 'card',
      card: this.card.element
    };
    this.stripeService.createPaymentMethod(params)
      .pipe(first(),
        switchMap((data: any) =>
          this.authService.addSecurePaymentMethod(data.paymentMethod.id)
        ))
      .subscribe((res) => {
        this.securityCard = res.actionRequired;
        this.clientKey = res.clientSecrets;
        const role = JSON.parse(localStorage.getItem('currentUser')).role;

        if (this.securityCard === true) {
          this.clientKey.forEach((item) => {
            this.stripeService.confirmCardPayment(item).subscribe(result => {
              if (result.error ) {
                this.failedMessage.changeMessage(result.error.message);
                this.router.navigateByUrl('/sign-up/checkout/failed');
              }
              if (result.paymentIntent) {
                localStorage.setItem('hasPaid', 'true');
                this.router.navigateByUrl('/sign-up/checkout/success');
              }
            });
          })
        } else if (role === 'STUDENT') {
          this.router.navigateByUrl(`/profile/${ this.userId }`);
        } else {
          localStorage.setItem('hasPaid', 'true');
          this.router.navigateByUrl('/sign-up/checkout/success');
        }
      }, ((response) => {
        this.failedMessage.changeMessage(response.error.message);
        this.router.navigateByUrl('/sign-up/checkout/failed');
      }));
  }

  onLoadPaymentData = (
    event: Event
  ): void => {
    const eventDetail = event as CustomEvent<google.payments.api.PaymentData>;
    console.log('load payment data', eventDetail.detail);
  };

  onPaymentDataAuthorized: google.payments.api.PaymentAuthorizedHandler = (
    paymentData
  ) => {
    console.log('payment authorized', paymentData);
    return {
      transactionState: 'SUCCESS'
    };
  };

  onError = (event: ErrorEvent): void => {
    console.error('Error.', event.error);
  };
}
