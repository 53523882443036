/**
 * Created by Daniil V. on 03/03/21.
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../api/application-http-client';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

import { distinctUntilChanged, pluck } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { map } from 'rxjs/operators';

interface CommonState {
  infoText: string;
}
const initialState: CommonState = {
  infoText: 'test'
};

/**
 * Service for sharing information within the app
 */
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private subject: BehaviorSubject<CommonState> = new BehaviorSubject(initialState);
  private readonly commonStore: Observable<CommonState> = this.subject.asObservable().pipe(distinctUntilChanged());

  public nextLearningObjective$: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);

  public infoText$: Observable<string> = this.httpClient.get<{ data: string }>(`/infoIconText`).pipe(
    map(res => res.data),
    tap(next => this.set('infoText', next))
  );
  constructor(private httpClient: ApplicationHttpClient) {}

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    // @ts-ignore
    return this.commonStore.pipe(pluck(name));
  }

  set(name: string, state: any) {
    this.subject.next({ ...this.value, [name]: state });
  }
}
