import { AfterViewInit, Component, Input, OnInit, HostListener } from '@angular/core';

import { UpcomingVideoService } from './upcoming-video.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { UpcommingVideosViewPopupComponent } from './upcomming-videos-view-popup/upcomming-videos-view-popup.component';
import { v4 as uuidv4 } from 'uuid';
export interface UpcomingVideo {
  learning_objective?: string;
  url?: string;
  subject?: string;
  title?: string;
  videoId?: string;
  containerId?: string;
  player: any;
  isVimeoVideo: boolean;
}

@Component({
  selector: 'curr-upcoming-lessons-videos',
  templateUrl: './upcoming-lessons-videos.component.html',
  styleUrls: ['./upcoming-lessons-videos.component.scss']
})
export class UpcomingLessonsVideosComponent implements OnInit, AfterViewInit {
  @Input() mediaArr: UpcomingVideo[] = [];

  showIndex = 0;

  constructor(private upcomingVideoService: UpcomingVideoService, public dialog: MatDialog) {}

  screenWidth: number;

  ngOnInit(): void {
    if (this.mediaArr.length) {
      this.mediaArr.forEach(i => {
        i.isVimeoVideo = this.upcomingVideoService.isVimeoVideo(i.url);
        if (!i.isVimeoVideo) {
          i.videoId = this.upcomingVideoService.extractYoutubeVideoId(i);
          i.containerId = `container-${uuidv4()}-${i.videoId}`;
        } else {
          i.videoId = this.upcomingVideoService.extractVimeoVideoId(i);
          i.containerId = `container-${uuidv4()}-${i.videoId}`;
        }
      });
    }

    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }

  ngAfterViewInit(): void {
    this.initPlayer();
  }

  initPlayer() {
    this.mediaArr.forEach((uv, i) => {
      if (i >= this.showIndex && i <= this.showIndex + 2) {
        uv.player = this.upcomingVideoService.createVideoPlayer(uv);
      }
    });
  }

  navigate(number: number) {
    this.stopVideo();
    this.showIndex += number;
    timer(500)
      .pipe(take(1))
      .subscribe(() => this.initPlayer());
  }

  openVideoPlayer(video: UpcomingVideo) {
    this.stopVideo();
    const dialogRef = this.dialog.open(UpcommingVideosViewPopupComponent, {
      width: '1069px',
      height: '748px',
      data: {
        video
      }
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  stopVideo() {
    this.mediaArr.forEach((uv, i) => {
      if (uv.player) {
        uv.isVimeoVideo ? uv.player.pause() : uv.player.stopVideo();
      }
    });
  }
}
