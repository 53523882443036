<!--
 Created by Dmytro Sav. on 5/20/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="column" fxLayoutAlign="center center" class="container">
  <div class="m-b-24 mat-h1">{{ title }}</div>

  <div fxLayout="row">
    <curr-input
      [(value)]="courseId"
      (valueChange)="getCourseById()"
      fxFlex="293px"
      placeholder="Enter Course ID"
      [isBordered]="true"
      inputType="string"
    ></curr-input>
  </div>

  <ng-container *ngIf="!isCreatedCourse">
    <div class="m-b-24  ">
      <div fxLayout="column" fxLayoutAlign="center center" *ngFor="let subject of filteredCourses">
        <div
          class="subject"
          [class.active]="subject.id === selectedCourse?.id"
          fxLayout="row"
          fxLayoutGap="12px"
          (click)="selectedCourse = subject"
        >
          <img src="../../../../assets/images/biology-logo.svg" alt="icon" />

          <div fxLayout="column" fxLayoutAlign="center start" class="text-wrapper">
            <div class="subject-id"># {{ subject.id }}</div>

            <div class="subject-name">
              {{ subject?.name }}
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  </ng-container>

  <div fxFlex="100%" class="m-b-16">
    <curr-dropdown-component-v2
      *ngIf="curriculums"
      [(selectedValue)]="curriculumId"
      fxFlex="293px"
      [options]="curriculums"
    ></curr-dropdown-component-v2>
  </div>
  <ng-template #createCourse>
    <div fxLayout="column" fxLayoutGap="11px">
      <curr-input placeholder="Subject id" [formGroup]="form" [controlName]="'subject'"></curr-input>
      <div fxLayout="row">
        <curr-dropdown
          [formGroup]="form"
          fxFlex="293px"
          [controlName]="'curriculum'"
          [options]="curriculums">
        </curr-dropdown>
      </div>
    </div>
  </ng-template>

  <div *ngIf="!isCreatedCourse" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
    <div>Did not find the class you need?</div>
    <div (click)="isCreatedCourse = true" class="text-underline cursor-pointer">Create new</div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center" class="m-t-16" fxLayoutGap="10px" *ngIf="!isEdited">
    <curr-button [mat-dialog-close] fxFlex="129px" variant="text" size="big">Cancel</curr-button>
    <curr-button
      *ngIf="isCreatedCourse"
      (btnClick)="addCourse()"
      [mat-dialog-close]="data"
      fxFlex="129px"
      variant="contained"
      size="big"
      [isDisabled]="!courseId"
    >
      Create
    </curr-button>
  </div>
</div>
