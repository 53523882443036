/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { YearSettings } from './year-settings';
import { Injectable } from "@angular/core";

@Injectable()
export class YearSettingsResolver implements Resolve<any> {
  constructor() {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new YearSettings();
  }

}
