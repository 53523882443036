<!--Created by Maxim Bon. on 19/05/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxLayout="column" fxLayoutAlign="center center">
  <div fxLayout="row" fxLayoutAlign="center center" class="m-b-16">
    <span class="mat-body-1 m-r-8">Semester: </span>
    <curr-dropdown [options]="ddOptions" selectedValue="1"></curr-dropdown>
  </div>
  <curr-upload-files class="add-paddings"
                     type="edit-resource"
                     [hint]="''"
                     title="Drop file here"
                     [formats]="['.doc', '.docx']"
  ></curr-upload-files>
</div>
