import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ChartPeriod } from '../dashboard-chart/dashboard-chart.service';
import { LoadGradesStatisticService } from '../../graphs/student-grades-statistic/load-grades-statistic.service';
import { ActivatedRoute } from '@angular/router';
import { fadeAnimation } from '../../../shared/animations/fade.animation';

export interface StudentGrade {
  grade?: string;
  course?: string;
  period?: string;
  courseId?: number;
  courceId?: number;
  ctYear?: number;
  ctMonth?: number;
  ctDay?: number;
}

@Component({
  selector: 'curr-student-dashboard-chart',
  templateUrl: './student-dashboard-chart.component.html',
  styleUrls: ['./student-dashboard-chart.component.scss'],
  animations: [fadeAnimation]
})
export class StudentDashboardChartComponent implements OnInit {
  // TODO: pls describe this magical numbers
  @Input() width = 1440 - 48 - 16 * 2 - 432 - 24;
  @Input() areFiltersPresented = true;

  @Input() set courseId(courseId: string) {
    this.selectedFiler.course_id = courseId;
  }

  selectedFiler: any = {};
  displayData$: Subject<StudentGrade[]> = new BehaviorSubject<StudentGrade[]>([]);
  ChartPeriod = ChartPeriod;
  currentPeriod: ChartPeriod;
  averageGrade = 'B';
  studentData = [];
  noDataImage = 'assets/images/no-graph-data.png';
  profileData;

  constructor(private route: ActivatedRoute,
              private lgs: LoadGradesStatisticService) {
  }

  ngOnInit(): void {
    this.currentPeriod = ChartPeriod.YEAR;
    this.profileData = this.route?.snapshot?.data?.profileData;
    this.fetchGraphData();
  }

  refreshGraphWithPeriod(period) {
    this.currentPeriod = period;
    this.fetchGraphData();
  }

  fetchGraphData() {
    setTimeout(() => {
      if (this.currentPeriod === ChartPeriod.MONTH) {
        this.lgs.getMonthStatistic(this.profileData?.id).subscribe(resp => {
          this.studentData = resp;
          this.displayData$.next(resp);
        });
      } else if (this.currentPeriod === ChartPeriod.SEMESTER) {
        this.lgs.getYearStatistic(this.profileData?.id).subscribe(resp => {
          this.studentData = resp;
          this.displayData$.next(resp);
        });
      } else {
        this.lgs.getYearStatistic(this.profileData?.id).subscribe(resp => {
          this.studentData = resp;
          this.displayData$.next(resp);
        });
      }
    }, 500);
  }

  filterChanged(courseId) {
    this.courseId = courseId;
    this.fetchGraphData();
  }
}
