/**
 * Created by Maxim B. on 31/03/20.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import {
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { LearningObjective } from '../../../shared-pages/subject-details/api-subject-details';
import { ToastrService } from 'ngx-toastr';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { Subject, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';

/*
 * Usage example:
 * <curr-tiny-editor
    [formGroup]="form"
    controlName="name" [options]="ptions" [(value)]="value" [isHigherBtnVisible]="true"></curr-tiny-editor>
 *  options: {
 *   height:number,
 *   disabled: boolean,
 *   menubar: string,
 *   statusbar: boolean,
 *  } - all options are optional
 *  value: string
 *  value will be auto assigned back to your "value" variable
 *  additional info by link https://www.tiny.cloud/docs/general-configuration-guide/basic-setup/
 * */
@Component({
  selector: 'curr-tiny-editor',
  templateUrl: './tiny-editor.component.html',
  styleUrls: ['./tiny-editor.component.scss']
})
export class TinyEditorComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('edit') editEl: ElementRef;
  @Input() options: any;
  @Input() value: string;
  @Input() isHigherBtnVisible = false;
  @Input() formGroup: FormGroup;
  @Input() controlName = '';
  @Input() topic: LearningObjective;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFocusOut: EventEmitter<EventObj<FocusEvent>> = new EventEmitter<EventObj<FocusEvent>>();
  @Output() onLoaded: EventEmitter<string> = new EventEmitter();

  key = environment.tinyEditorKey;
  editorOptions = {};
  isEditorReady = false;
  valueForTinyEditor = '';
  alreadyImagesUploaded = 0;
  imageKeyValueKeysCount = 0;
  sbj = new Subject<number>();
  sbjAsObs = this.sbj.asObservable();
  imageKeyValue = {};
  imageUrlValue = {};
  subscription: Subscription;
  editorId = `editor-${ Date.now() }-${Math.random() * 100000}`;
  isFirstTime = true;
  unShowedImage: string;

  constructor(
    private toastr: ToastrService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private fileUploadService: FileUploadService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isFirstTime) {
      this.isFirstTime = false;
      return;
    }
    if (this.formGroup?.controls[this.controlName]) {
      this.valueForTinyEditor = this.formGroup.controls[this.controlName]?.value || '';
      this.value = this.formGroup.controls[this.controlName]?.value || '';
      this.valueChange.emit(this.value);
    }
  }

  focusOut(): void {
    this.updateValue();
    this.onFocusOut.emit()
  }

  initImages() {
    if (this.value) {
      this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.value);
      this.imageKeyValueKeysCount = Object.keys(this.imageKeyValue).length;
    }
    this.subscription = this.sbjAsObs.subscribe(data => {
      if (this.isEditorReady && this.alreadyImagesUploaded === this.imageKeyValueKeysCount) {
        this.getImages();
      }
    });
    this.uploadImagesAndChangeValueForTinyEditor();
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.controlName = 'item' + Date.now();
      this.formGroup = this.fb.group({
        [this.controlName]: new FormControl(this.valueForTinyEditor || '')
      });
    } else {
      if (!this.value) {
        this.valueForTinyEditor = this.formGroup?.controls[this.controlName]?.value || '';
        this.value = this.formGroup?.controls[this.controlName]?.value || '';
      }
    }
    this.editorOptions = {
      id: Date.now(),
      placeholder: this.options.placeholder || 'Type here...',
      height: this.options.height || 700,
      disabled: this.options.disabled || false,
      menubar: this.options.menubar || 'file edit view format table help',
      outputFormat: 'html',
      browser_spellcheck: true,
      contextmenu: false,
      statusbar: this.options.statusbar || true,
      extended_valid_elements: 'higher',
      custom_elements: 'higher',
      file_picker_types: 'image',
      setup: editor => {
        if (this.isHigherBtnVisible) {
          editor.ui.registry.addButton('customHigherButton', {
            icon: 'warning',
            tooltip: 'Make text Higher',
            onAction: _ => {
              const node = editor.selection.getContent();
              editor.selection.setContent(`<higher><span style="background-color: #56C7D8;">${node}</span></higher>`);
            }
          });

          editor.ui.registry.addButton('customClearHigherButton', {
            icon: 'remove',
            tooltip: 'Remove Higher',
            onAction: _ => {
              const content = editor.selection.getContent();
              editor.insertContent(content);
            }
          });
        }
      },
      init_instance_callback: (editor: any) => {
        // editor.setContent('\sum_{n=0}^\infty\frac{(-1)^n}{(2n)!}x^{2n}')

        // setTimeout(() => {
        //   MathJax.Hub.Queue(['Typeset', MathJax.Hub, editor.getBody()]);
        // }, 1000)
       // MathJax.Hub.Queue(['Typeset', MathJax.Hub, editor.getBody()]);
      },
      // skin: 'oxide-dark',  CAN ADD DARK THEME FOR EDITOR
      // content_css: 'dark',
      external_plugins: {
        tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
        // mathjax: 'https://raw.githubusercontent.com/dimakorotkov/tinymce-mathjax/master/plugin.min.js'
      },
      // mathjax: {
      //   lib: 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js', //required path to mathjax
      //   //symbols: {start: '\\(', end: '\\)'}, //optional: mathjax symbols
      //   //className: "math-tex", //optional: mathjax element class
      // },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen tiny_mce_wiris',
        'insertdatetime media table paste code help wordcount',
        'media', 'paste', 'code'
      ],
      toolbar: `
        code | mathjax | undo redo | formatselect | bold italic backcolor | image |
        alignleft aligncenter alignright alignjustify | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry |
        bullist numlist outdent indent | removeformat | customHigherButton customClearHigherButton | help | media | paste
      `,
      images_upload_handler: this.imageUploadHandler.bind(this),
      paste_data_images: true,
      image_title: true,
      base_url: '/tinymce',
      suffix: '.min',
      button_tile_map: true
    };
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    // tslint:disable-next-line:forin
    for (const key in this.imageUrlValue) {
      URL.revokeObjectURL(this.imageUrlValue[key]);
    }
  }

  updateValue(): void {
    if (this.unShowedImage) {
      this.displayJustLoadedImage(this.unShowedImage);
    }
    this.putImageOriginalImageSrc(); // Emit here!
  }

  onEditorInitialized() {
    this.initImages();
    this.isEditorReady = true;
    this.sbj.next(1);
    this.onLoaded.emit(this.valueForTinyEditor);
  }

  imageUploadHandler(blobInfo, success, failure, progress) {
    if (!blobInfo.blob().name) {
      return;
    }
    this.fileUploadService.upload(blobInfo.blob(), 'IMAGE').subscribe(
      (resp: { uuidName: string; oldName: string; state: string }[]) => {
        const i = resp[0];
        if (i.uuidName) {
          this.fileUploadService.download(i.uuidName, 'IMAGE').subscribe((r: any) => {
            this.imageKeyValue[i.uuidName] = r.data.bytes;

            if (this.imageKeyValue[i.uuidName]) {
              const blob = this.b64toBlob(this.imageKeyValue[i.uuidName], 'image/png');
              const url = URL.createObjectURL(blob);
              this.imageUrlValue[i.uuidName] = url;
            }
            this.unShowedImage = i.uuidName;
            success(i.uuidName + '' || '');
          });
        }
      },
      error => {
        failure(error.msg || 'Error happened');
      }
    );
  }

  displayJustLoadedImage(src: string) {
    const kv = Object.assign({}, this.imageKeyValue);
    timer(0)
      .pipe(take(1))
      .subscribe(
        resp => {
          const cont = document.getElementById(this.editorId);
          if (!cont) {
            return;
          }
          const frames = cont.getElementsByTagName('iframe');
          if (!frames.length) {
            return;
          }
          const images = frames[0].contentWindow.document.getElementsByTagName('img');
          if (!images.length) {
            return;
          }
          // @ts-ignore
          for (const im of images) {
            const source = im.attributes?.src?.nodeValue;
            if (kv[source] && src === source) {
              im.src = this.imageUrlValue[source];
            }
          }
          this.unShowedImage = '';
        },
        () => {
          this.toastr.warning('Error happened while display image', 'Try to reload');
        },
        () => {
          this.unShowedImage = '';
        }
      );
  }

  private async uploadImagesAndChangeValueForTinyEditor() {
    this.valueForTinyEditor = this.value;
    // tslint:disable-next-line:forin
    for (const key in this.imageKeyValue) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      this.imageKeyValue[key] = bytes;
      this.alreadyImagesUploaded++;
      this.sbj.next(1);
    }
  }

  getImages() {
    const cont = document.getElementById(this.editorId);
    if (!cont) {
      return;
    }
    const frames = cont.getElementsByTagName('iframe');
    if (!frames.length) {
      return;
    }
    const images = frames[0].contentWindow.document.getElementsByTagName('img');
    if (!images.length) {
      return;
    }
    // @ts-ignore
    for (const im of images) {
      const source = im.attributes?.src?.nodeValue;
      if (this.imageKeyValue[source]) {
        const blob = this.b64toBlob(this.imageKeyValue[source], 'image/png');
        const url = URL.createObjectURL(blob);
        im.src = url;
        this.imageUrlValue[source] = url;
      }
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  private putImageOriginalImageSrc() {
    let val = this.valueForTinyEditor;
    // tslint:disable-next-line:forin
    for (const key in this.imageKeyValue) {
      val = val.replace('data:image/png;base64,' + this.imageKeyValue[key] + '', key);
    }
    this.value = val;
    this.valueChange.emit(this.value);
    if (this.formGroup && this.formGroup?.controls[this.controlName]) {
      this.formGroup.controls[this.controlName]?.patchValue(val);
    }
  }
}
