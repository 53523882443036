<!-- Created by Alex Poh. on 03/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <curr-header></curr-header>
  <div class="global-content-wrapper">
    <div class="content-wrapper" [@routeAnimations]="animationData?.animationState" [@.disabled]="url === '/add-subjects'">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<curr-footer class="footer"></curr-footer>
