/**
 * Created by Alex Poh. on 17/08/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateSubjectPopupComponent } from '../../../../shared/components/create-subject-popup/create-subject-popup.component';

@Component({
  selector: 'curr-gantt-without-data',
  templateUrl: './gantt-without-data.component.html',
  styleUrls: ['./gantt-without-data.component.scss']
})
export class GanttWithoutDataComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  public importTimeTable() {
    this.dialog.open(CreateSubjectPopupComponent, {
      width: '1069px',
      id: 'import-timetable',
      data: {
        importType: 'timetable'
      }
    });
  }
}
