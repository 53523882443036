/*
 * Created by Dmytro Sav. on 5/14/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component } from '@angular/core';
import { PreviewComponent } from './preview-component';
import { Role } from '../../shared/role';

@Component({
  selector: 'curr-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss']
})
export class TestDemoViewSelectorComponent {
  components: PreviewComponent[] = [
    new PreviewComponent('School Admin - Users', 'sa-users.png', '/users', Role.SchoolAdmin),
    new PreviewComponent('School Admin - YS', 'sa-users.png', '/year-settings', Role.SchoolAdmin),
    new PreviewComponent('School Admin - Glossary', 'sa-users.png', '/glossary', Role.SchoolAdmin),
    new PreviewComponent('Teacher - Create Subject', 'create-subject.png', '/subjects/new', Role.Teacher),
    new PreviewComponent('Teacher - Create CT', 'edit-test.png', '/edit-test/new', Role.Teacher),
    new PreviewComponent('Student - Profile', 'student-profile.png', '/profile', Role.Student),
    new PreviewComponent('Student - lear reso', 'student-profile.png', '/learning-resources/32', Role.Student),
    new PreviewComponent('Student - Subject Notes', 'student-subject-notes.png', '/subjects/1/notes', Role.Student),
    new PreviewComponent('School Admin - User Profile', 'admin-profile.png', '/users/1', Role.SchoolAdmin),
    new PreviewComponent('SA - Questions', 'Questions.png', '/sa-questions', Role.Teacher),
    new PreviewComponent('CT - Questions', 'Questions.png', '/ct-questions', Role.Teacher),
    new PreviewComponent('Review SA', 'sa-review.png', '/sa-review', Role.Student),
    new PreviewComponent('Review CT', 'ct-review.png', '/ct-review/1', Role.Student),
    new PreviewComponent('Student Course Details', 'Student-Subjects.png', '/courses-details', Role.Student),
    new PreviewComponent('School Admin - Profile', 'school-admin.png', '/profile', Role.SchoolAdmin),
    new PreviewComponent('Create Teacher', 'Create-teacher.png', '/create-teacher', Role.SchoolAdmin),
    new PreviewComponent('Edit Teacher', 'Edit-teacher.png', '/edit-teacher', Role.SchoolAdmin),
    new PreviewComponent('Form Page', 'form-page.png', '/form-page', Role.Teacher),
    new PreviewComponent('Teacher - Student Profile', 'student-profile-forteacher.png', '/courses/1/1', Role.Teacher),
    new PreviewComponent(
      'New pastoral care user',
      'new-pastoral-care-user.png',
      '/create-pastoral-care-user',
      Role.SchoolAdmin
    ),
    new PreviewComponent('HA - Customers', 'customer-view.png', '/customers', Role.HubAdmin),
    new PreviewComponent('HA - Customer Detail', 'customer-view.png', '/customers/43', Role.HubAdmin),
    new PreviewComponent('HA - Settings', 'settings-page.png', '/settings', Role.HubAdmin),
    new PreviewComponent('HA - Dashboard', 'School-Admin-Dashboard.png', '/dashboard', Role.HubAdmin),
    new PreviewComponent('HA - Countries', 'student-profile-forteacher.png', '/countries', Role.HubAdmin),
    new PreviewComponent('Student - HA Results', 'ha-results.png', '/subjects/1/grade-results', Role.Student),
    new PreviewComponent('Teacher - Profile', 'teacher-profile.png', '/profile', Role.Teacher)
  ];

  fakeComponents: [];

  constructor() {}
}
