/**
 * Created by Yaroslav S. on 04/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IZone } from '../ai-zones-marker/ai-zones-marker.interface';

@Component({
  selector: 'curr-ai-zones-marker-popup',
  templateUrl: './ai-zones-marker-popup.component.html',
  styleUrls: ['./ai-zones-marker-popup.component.scss']
})
export class AiZonesMarkerPopupComponent implements OnInit {

  title = '';
  imageLink = '';
  zones: IZone[] = [];
  oldZones: IZone[] = [];

  constructor(
    public dialogRef: MatDialogRef<AiZonesMarkerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.imageLink = this.data.imageLink;
    this.oldZones = this.data.zones;
    this.dialogRef.disableClose = true;
  }

  onZonesList(zones: IZone[]) {
    this.zones = zones;
  }

  submit() {
    this.dialogRef.close(this.zones);
  }

  close() {
    this.dialogRef.close(this.zones);
  }
}
