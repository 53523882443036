import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Question, QuestionsLabels, QuestionType } from '../../../../student/test.model';
import { RestApiService, restApiServiceCreator } from '../../../api/rest-api.service';
import { ApplicationHttpClient } from '../../../api/application-http-client';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { COUResponse } from '../../../../shared-pages/subject-details/api-subject-details';

@Component({
  selector: 'curr-multistage-question-form-block',
  templateUrl: './multistage-question-form-block.component.html',
  styleUrls: ['./multistage-question-form-block.component.scss']
})
export class MultistageQuestionFormBlockComponent implements OnInit, OnChanges {
  @Input() rootQuestion: Question;
  @Input() inSowPage = false;
  @Input() cou: COUResponse[] = [];
  @Input() startIndexForSubQuestion = 1;
  @Input() isCTQuestion = false;
  @Output() saveQuestionData = new EventEmitter<string>();

  MAX_SUB_QUESTIONS_QUANTITY = 100;
  questionType = QuestionType;
  questionsLabels = QuestionsLabels;
  isQuestionTinyEditor = false;
  isEditSubQuestionMode = true;
  isSavingQuestion = false;
  selectedSubQuestion: Question;
  selectedSubQuestionIndex = 0;
  saTestService: RestApiService<Question>;
  cumulativeTestService: RestApiService<Question>;

  constructor(private httpClient: ApplicationHttpClient,
              private toastr: ToastrService,
              public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(this.httpClient);
    this.saTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveSAQuestions')(this.httpClient);
    this.rootQuestion.is_master = true;
    if (this.rootQuestion?.children == null) {
      this.rootQuestion.children = [];
    }
    if (!this.rootQuestion?.children?.length) {
      this.addNewRootSubQuestion();
    } else {
      this.setInitialSubQuestion();
    }

  }

  ngOnChanges(): void {
    this.setInitialSubQuestion();
  }

  setInitialSubQuestion(): void {
    const subq = this.rootQuestion.children[0];
    this.setSelectedSubQuestion(subq, 0);
  }

  saveQuestion(mode: string) {
    this.saveQuestionData.emit(mode);
  }

  selectPrevQuestion() {
    if (this.selectedSubQuestionIndex >= 1) {
      this.selectedSubQuestionIndex--;
      this.setSelectedSubQuestion(this.rootQuestion.children[this.selectedSubQuestionIndex], this.selectedSubQuestionIndex);
    }
  }

  setSelectedSubQuestion(q: Question, i: number) {
    this.selectedSubQuestion = q;
    this.selectedSubQuestionIndex = i;
  }

  addNewRootSubQuestion() {
    if (!this.rootQuestion.id || this.isSavingQuestion) {
      return;
    }
    const q = new Question('New Single Answer Question', this.rootQuestion.curriculum_lobj_id, this.questionType.SINGLE_ANSWER);
    q.base_type = this.isCTQuestion ? 'ct' : 'sa';
    q.parent_id = this.rootQuestion.id;
    this.isSavingQuestion = true;
    this.createSaveQuestionRequest(q).subscribe((id: any) => {
      if (id) {
        q.id = (id as number);
      }
      this.rootQuestion.children.push(q);
      this.setSelectedSubQuestion(q, this.rootQuestion.children.length - 1);
      this.isSavingQuestion = false;

    }, this.onError, this.onComplete);
  }

  createSubQuestionToSubQuestion() {
    if (this.selectedSubQuestion && this.selectedSubQuestion.children.length === 0) {
      this.isSavingQuestion = true;
      const q = new Question('New Single Answer Sub Question', this.rootQuestion.curriculum_lobj_id, this.selectedSubQuestion.answer_type);
      q.mark = this.selectedSubQuestion.mark;
      q.question = this.selectedSubQuestion.question;
      q.parent_id = this.selectedSubQuestion.id;
      this.selectedSubQuestion.is_master = true;
      this.selectedSubQuestion.answer_type = this.questionType.MULTIPART;
      if (this.selectedSubQuestion.answer_type === this.questionType.MULTIPART) {
        this.selectedSubQuestion.mark = null;
      }
      this.createUpdateQuestionRequest(this.selectedSubQuestion).subscribe(resp => {
        this.createSaveQuestionRequest(q).subscribe((id: any) => {
          if (id) {
            q.id = (id as number);
          }
          this.isSavingQuestion = false;
          this.selectedSubQuestion.children.push(q);
        }, this.onError, this.onComplete);
      }, this.onError, this.onComplete);
      return;
    }
    if (this.selectedSubQuestion && this.selectedSubQuestion.children.length > 0) {
      this.isSavingQuestion = true;
      const q = new Question('New Single Answer Question', this.rootQuestion.curriculum_lobj_id, this.questionType.SINGLE_ANSWER);
      q.parent_id = this.selectedSubQuestion.id;
      this.selectedSubQuestion.answer_type = this.questionType.MULTIPART;

      this.isSavingQuestion = true;
      this.createSaveQuestionRequest(q).subscribe((id: any) => {
        if (id) {
          q.id = (id as number);
        }
        this.selectedSubQuestion.children.push(q);
        this.isSavingQuestion = false;
        // this.setSelectedSubQuestion(q, this.selectedSubQuestion.children.length - 1);
      }, this.onError, this.onComplete);
    }
  }

  createSaveQuestionRequest(q: Question) {
    let req: Observable<Question>;
    if (!this.isCTQuestion) {
      req = this.saTestService.add(q);// SA
    } else {
      req = this.cumulativeTestService.add(q);   // CT
    }
    return req;
  }

  createUpdateQuestionRequest(q: Question) {
    let req: Observable<Question>;
    if (!this.isCTQuestion) {
      req = this.saTestService.update(q, q.id + ''); // SA
    } else {
      req = this.cumulativeTestService.update(q, q.id + ''); // CT
    }
    return req;
  }

  updateQuestion(q: Question) {
    this.createUpdateQuestionRequest(q).subscribe(resp => {
      this.toastr.success('Question was updated!');
    }, () => {
      this.toastr.warning('An error happened, while saving');
    });
  }

  setSelectedSubQuestionFromSubQuestion(q: Question, subq: Question, j: number) {
    this.setSelectedSubQuestion(subq, j);
  }

  onError(err: any) {
  }

  onComplete() {
    this.isSavingQuestion = false;
  }

  deleteSubquestion(id: number) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      width: '400px',
      data: {
        questionTitle: 'Are you sure in deleting?',
        confirmLabel: 'YES',
        cancelLabel: 'NO'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let req: Observable<Question>;
        if (this.rootQuestion.base_type === 'sa') {
          req = this.saTestService.remove(id + '');
        } else if (this.rootQuestion.base_type === 'ct') {
          req = this.cumulativeTestService.remove(id + '');
        }
        req.subscribe(resp => {
          this.toastr.info('Question was deleted.');
          this.removeQuestionFromRoot(id, this.rootQuestion);
        }, (err) => {
          if (err.error.message) {
            this.toastr.warning(err.error.message);
          }
        });
      }
    });
  }

  private removeQuestionFromRoot(id: number, question: Question) {
    if (!question.children || !question.children.length) {
      return;
    }
    const index = question.children.findIndex(i => i.id === id);
    if (index > -1) {
      question.children.splice(index, 1);
      if (question.children.length === 0) {
        if (question.id === this.rootQuestion.id) {
          this.addNewRootSubQuestion();
        } else {
          this.createSubQuestionToSubQuestion();
        }
      } else {
        const subq = this.rootQuestion.children[0];
        this.setSelectedSubQuestion(subq, 0);
      }
    }
    if (index === -1) {
      question.children.forEach(i => {
        this.removeQuestionFromRoot(id, i);
      });
    }
  }
}
