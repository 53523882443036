/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagingApiKeysService } from './managing-api-keys.service';
import { IAiKeys } from './managing-api-keys.interface';

@Injectable({
  providedIn: 'root'
})
export class ManagingApiKeysResolver implements Resolve<any> {

  constructor( private managingApiKeysService: ManagingApiKeysService) {}

  resolve(): Observable<IAiKeys> {
   return this.managingApiKeysService.getAiKeys();
  }
}
