/**
 * Created by Alex Poh. on 29/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DrawingBoardService } from './drawing-board.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'curr-drawing-board',
  templateUrl: './drawing-board.component.html',
  styleUrls: ['./drawing-board.component.scss'],
  providers: [DrawingBoardService]
})
export class DrawingBoardComponent implements OnInit, OnDestroy {
  @Input()
  set color(value: string) {
    this.drawingBoardService.color = value;
  }

  @Input()
  set weight(value: number) {
    this.drawingBoardService.strokeWeight = value;
  }

  @Input()
  clear$: Observable<boolean>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private drawingBoardService: DrawingBoardService) {}

  ngOnInit(): void {
    this.drawingBoardService.createCanvas('canvas-container');
    if (this.clear$) {
      this.clear$.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.clear();
      });
    }
  }

  clear() {
    this.drawingBoardService.clear();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
}
