import { Component, Inject, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { CreateCoursePopupComponent } from './create-course-popup/create-course-popup.component';
import { DeleteCoursePopupComponent } from './delete-course-popup/delete-course-popup.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { View } from '../../shared/components/course-card/course-card.component';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { Series } from '../../shared/graphs/school-performance-histogram/series.model';
import { Course, CourseDetails } from './course';
import { getRestApiProvider, Paginated, RestApiService } from '../../shared/api/rest-api.service';
import { switchMap, take } from 'rxjs/operators';
import { fadeAnimation } from '../../shared/animations/fade.animation';
import { CoursesService } from './courses.service';
import {
  CoursesFilterFields,
  CoursesFilterOptions
} from '../../shared/components/select-courses-filters/select-courses-filters.component';
// import { FirebaseNotificationService } from '../../shared/services/firebase-notification.service';

interface CardData {
  subject: string;
  year: number;
  level: string;
  form: number;
}

@Component({
  selector: 'curr-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  providers: [getRestApiProvider<Course>('schoolYearCourses/getTeacherSubjects', 'getTeacherSubjects')],
  animations: [fadeAnimation]
})
export class CoursesComponent implements OnInit {
  paginated$ = new BehaviorSubject(new Paginated(10, 0, 10));

  path = [
    { label: 'Home', url: '/dashboard' },
    { label: 'Courses', url: '' }
  ];

  form: FormGroup = this.fb.group({
    subject: ['', [Validators.required]],
    curriculum: ['', [Validators.required]]
  });

  filterChanged$ = new BehaviorSubject({}); // TODO: it is possible to simplify current flow, by removing this variable.

  filterChangedSubscription: Subscription;

  // TODO: check if need?
  // tslint:disable-next-line:no-unused-variable
  private linkDashboard = { rout: '/dashboard', fragment: 'students' };

  filtersData: any;
  selectFilter: CoursesFilterOptions;
  coursesFilterFields: CoursesFilterFields = {
    subject: 'subject_name',
    course: 'st_course_id',
    grade: 'study_year_id',
    semester: 'semesters'
  };

  constructor(public dialog: MatDialog,
              private fb: FormBuilder,
              private coursesService: CoursesService,
              // private notificationService: FirebaseNotificationService,
              @Inject('getTeacherSubjects') private teacherCoursesService: RestApiService<CourseDetails>) {
  }

  createCourse(title?: string) {
    const dialogRef = this.dialog.open(CreateCoursePopupComponent, {
      width: '501px',
      maxHeight: '90vh',
      data: { courseId: '', form: this.form, title }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        timer(2000)
          .pipe(take(1))
          .subscribe(resp => {
            this.cards = [];
            this.filterChanged$.next(this.filterChanged$.value);
          });
      }
    });
  }

  deleteCourse(index) {
    const dialogRef = this.dialog.open(DeleteCoursePopupComponent, {
      width: '384px',
      height: '132px',
      data: { isDeleted: false }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined && result.isDeleted === true) {
        this.cards.splice(index, 1);
        this.init();
      }
    });
  }

  editCourse(index) {
    const data = this.cards[index].data as CardData;
    this.form.patchValue(data);
    this.createCourse('Create Course');
  }

  hint = {
    icon: 'warn',
    label: '',
    color: 'warn',
    isUnderLine: true,
    postIcon: 'mdi-arrow-right'
  };

  buttons = [];

  chartData: Series[] = [
    {
      data: [
        {
          date: '2012-1-1',
          value: 2
        },
        {
          date: '2012-2-2',
          value: 3
        },
        {
          date: '2012-3-3',
          value: 4
        },
        {
          date: '2012-4-4',
          value: 3
        },
        {
          date: '2012-5-5',
          value: 2
        },
        {
          date: '2012-6-6',
          value: 3
        },
        {
          date: '2012-7-7',
          value: 4
        },
        {
          date: '2012-8-8',
          value: 3
        }
      ],
      name: 'test',
      cssClasses: { line: 'green-statistic', dot: 'green-statistic-dot' }
    }
  ];

  cards = [];

  ngOnInit() {
    // this.notificationService.showNotification('You have a Tutor session in 5 minutes');
    this.init();
    this.getOptionsForFilters();
  }

  init() {
    this.paginated$ = new BehaviorSubject(new Paginated(10, 0, 10));
    this.cards = [];
    this.filterChangedSubscription = this.filterChanged$
      .pipe(
        switchMap(res => {
          return this.teacherCoursesService.getAllWithPostPaginated(this.paginated$, res);
        })
      )
      .subscribe(res => {
        this.cards.push(...this.coursesService.createCourseCards(res));

        this.cards.forEach((card, index) => {
          card.title.topRightIcons = [
            { icon: 'sandwich', link: '/#', viewToSwitch: View.StandardView },
            { icon: 'graph', link: '/#', viewToSwitch: View.GraphView }
          ];
          // tslint:disable-next-line
          card['chartData'] = of(this.chartData);
        });
      });
  }

  showMore() {
    this.paginated$.next(this.paginated$.value.next());
  }

  getOptionsForFilters() {
    this.coursesService.getFilters().subscribe(response => {
      this.filtersData = response;
    });
  }

  onCoursesFilterChange(selectFilter: CoursesFilterOptions) {
    this.filterChangedSubscription.unsubscribe();
    this.filterChanged$.next(selectFilter);
    this.init();
  }
}
