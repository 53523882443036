<!--
 Created by Daniil Vat. on 28/3/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<div class="list-item" fxLayout="row" fxLayoutAlign="start">
  <div class="list-item--marker" [ngStyle]="{'background-color': color}"></div>
  <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="list-item--text" [currMath]="text"></div>
    <div class="list-item--value" [currMath]="value" *ngIf="value"></div>
  </div>
</div>
