<div class="drop-downs">
  <curr-dropdown [defaultValue]="filterOptions[filterFields.subject] || subjectDefault"
    *ngIf="ddOptionsSubjects.length && filterFields.subject" class="curr-dropdown" [options]="ddOptionsSubjects"
    placeholder="All subjects" (valueChange)="changeSelection($event, filterFields.subject)">
  </curr-dropdown>

  <curr-dropdown [defaultValue]="filterOptions[filterFields.grade] ||  gradesDefault"
    *ngIf="ddOptionsGrades.length && filterFields.grade" class="curr-dropdown" [options]="ddOptionsGrades"
    placeholder="All years" (valueChange)="changeSelection($event, filterFields.grade)"></curr-dropdown>

  <curr-dropdown [defaultValue]="filterOptions[filterFields.course] || coursesDefault"
    *ngIf="ddOptionsCourses.length && filterFields.course" class="curr-dropdown" [options]="ddOptionsCourses"
    placeholder="All courses" (valueChange)="changeSelection($event, filterFields.course)">
  </curr-dropdown>

  <curr-dropdown [defaultValue]="filterOptions[filterFields.semester] || semestersDefault"
    *ngIf="ddOptionsSemesters.length && filterFields.semester" class="curr-dropdown" [options]="ddOptionsSemesters"
    placeholder="All semesters" (valueChange)="changeSelection($event, filterFields.semester)">
  </curr-dropdown>
</div>
