/**
 * Created by Alex Poh. on 24/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import * as d3 from 'd3';
import { GraphOptions } from '../graph-options';
import { DateValue, Histogram, Series } from './series.model';
import { classToClass } from 'class-transformer';
import { Grades } from '../../components/dashboard-chart/chart-data';

@Injectable()
export class SchoolPerformanceHistogramService {
  private svg: any;
  private options: GraphOptions;
  private data: Series[];
  private lines: Selection[] = [];
  // TODO: remove?
  // private points = [];
  private xAxis;
  private yAxis;
  private maxValueY = 100;
  private isFirstTime = true;
  private isAnimationEnabled = true;
  private grades: Grades = {};

  constructor() {}

  get gradesLabels(): string[] {
    const labels = Object.keys(this.grades).reverse();
    return labels;
  }

  init(svg: HTMLElement, options: GraphOptions) {
    this.svg = d3.select(svg);
    this.options = options;
  }

  updateHistogram({ series: data, grades }: Histogram) {
    if (this.data) {
      this.removeOldContent(data);
    }
    this.grades = grades;
    if (this.isFirstTime) {
      this.isFirstTime = false;
      this.data = this.prepareConvergedData(data);
      this.updateView();
      setTimeout(() => {
        this.data = this.prepareData(data);
        this.updateView();
      }, 1000);
    } else {
      if (this.shouldCollapse(data)) {
        setTimeout(() => {
          this.data = this.prepareConvergedData(this.data);
          this.updateView();
          setTimeout(() => {
            this.isAnimationEnabled = false;
            this.data = this.prepareConvergedData(data);
            this.updateView();
            this.isAnimationEnabled = true;
            setTimeout(() => {
              this.data = this.prepareData(data);
              this.updateView();
            }, 1000);
          }, 1000);
        }, 1000);
      } else {
        this.data = this.prepareData(data);
        this.updateView();
      }
    }
  }

  updateView() {
    this.setupViewBox();
    this.addAxes();
    this.removeUnnecessaryLines();
    this.addGrid();
    this.addLines();
    this.addVerticalGrid();
  }

  prepareData(data: Series[]): Series[] {
    const clonedData = classToClass(data);
    return clonedData.map((series: Series) => {
      series.data = series.data.map((val: DateValue) => {
        return { value: this.calculateYAxisPosition(val.value), date: new Date(val.date) };
      });
      return series;
    });
  }

  calculateYAxisPosition(value: number): number {
    const gradeStep = this.maxValueY / this.gradesLabels.length;
    let gradePosition: number = ++this.gradesLabels.length;

    const gradesValues = Object.values(this.grades).sort((a, b) => a.maxValue - b.maxValue);
    if (value > gradesValues[gradesValues.length - 1]?.maxValue) {
      value = gradesValues[gradesValues.length - 1].maxValue;
    }

    const { maxValue: gradeMaxRes, minValue: gradeMinRes } =
      gradesValues.find((grade, i) => {
        if (value <= grade.maxValue && value >= grade.minValue) {
          gradePosition = ++i;
          return true;
        }
      }) || {};

    const gradeMaxGraph = gradePosition * gradeStep + gradeStep / 2;
    const gradeMinGraph = gradePosition * gradeStep - gradeStep / 2;

    const differenceCoefficientBetweenGraphAndRes = (gradeMaxGraph - gradeMinGraph) / (gradeMaxRes - gradeMinRes);

    const finalPos = gradeMinGraph + (value - gradeMinRes) * differenceCoefficientBetweenGraphAndRes;

    return finalPos;
  }

  prepareConvergedData(data: Series[]): Series[] {
    const clonedData = classToClass(data);
    return clonedData?.map((series: Series) => {
      series.data = series.data.map((val: DateValue) => {
        return { value: this.maxValueY / 2, date: new Date(val.date) };
      });
      series.cssClasses.dot = 'no-dot';
      return series;
    });
  }

  shouldCollapse(newData): boolean {
    return !this.data?.every((d, index) => {
      if (!newData[index]) {
        return true;
      } else {
        return d.data.length === newData[index].data.length;
      }
    });
  }

  removeOldContent(data: Series[]) {
    this.svg.selectAll('circle').remove();
    const dataToRemove = this.data.filter((series, i) => {
      if (!data.some(oldSeries => oldSeries.cssClasses.line === series.cssClasses.line)) {
        this.lines[i] = undefined;
        return true;
      } else {
        return false;
      }
    });
    dataToRemove.forEach(series => this.svg.selectAll(`.${series.cssClasses.line}`).remove());
  }

  setupViewBox() {
    this.svg.attr('viewBox', [0, 0, this.options.width, this.options.height]);
  }

  addAxes() {
    if (!this.xAxis) {
      this.xAxis = this.svg.append('g');
    }
    if (!this.yAxis) {
      this.yAxis = this.svg.append('g');
    }

    this.xAxis
      .attr('transform', `translate(0,${this.options.height - this.options.margin.bottom})`)
      .call(this.getXAxis());
    this.yAxis.append('g').call(this.getYAxis());
  }

  removeUnnecessaryLines() {
    this.svg.selectAll('.tick>line').remove();
  }

  addGrid() {
    const lines = this.svg
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', `translate(${this.options.margin.left},0)`)
      .call(this.getYAxisGrid())
      .call(gNext => gNext.select('.domain').remove())
      .selectAll('line');
    lines.attr('stroke-width', 1).attr('style', 'color: var(--theme-color-grey-1); opacity:0.5');
    lines.filter((d, i) => i === 0).attr('stroke-dasharray', 0);
  }

  addVerticalGrid(){
    const lines = this.svg
      .append('g')
      .attr('class', 'y axis-grid')
      .attr('transform', `translate(${this.options.margin.left},0)`)
      .call(this.getXAxisGrid())
      .call(gNext => gNext.select('.domain').remove())
      .selectAll('line');
    lines.attr('stroke-width', 1).attr('style', 'color: var(--theme-color-grey-1); opacity:0.5');
    lines.filter((d, i) => i === 0).attr('stroke-dasharray', 0);
  }


  addLines() {
    this.data.forEach((series, i) => {
      this.addLine(series.data, series.cssClasses.line, i);
      this.addPoints(series.data, series.cssClasses.dot, i);
    });
  }

  addLine(data: DateValue[], cssClass: string, lineIndex: number) {
    let line;
    if (this.lines[lineIndex]) {
      line = this.lines[lineIndex];
    } else {
      line = this.svg.append('path');
      this.lines[lineIndex] = line;
    }
    line
      .datum(data)
      .filter(dataSet => {
        // check if dataSets have valid dates
        return dataSet?.every(dataItem => dataItem.date instanceof Date && !isNaN(dataItem.date));
      })
      .attr('fill', 'none')
      .attr('class', cssClass)
      .attr('stroke-width', 3)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-linecap', 'round')
      .transition()
      .duration(this.isAnimationEnabled ? 1000 : 0)
      .attr('d', this.getLine());
  }

  getLine() {
    return d3.line()
      .x(d => {
        return this.getX()(d.date);
      })
      .y(d => {
        return this.getYForPoints()(d.value);
      });
  }

  getXAxis() {
    return g =>
      g
        .attr('transform', `translate(0,${this.options.height - this.options.margin.bottom})`)
        .attr('class', 'mat-caption')
        .style('font-weight', '500')
        .style('font-family', 'Roboto')
        .style('color', '#B0B0B5')
        .call(
          d3.axisBottom(this.getX())
            .tickValues(this.data[0].data.map(d => d?.date))
            .tickFormat(d => {
              return d3
                .timeFormat('%B')(d)
                .substring(0, 3)
                .toUpperCase();
            })
        )
        .call(gNext => gNext.select('.domain').remove());
  }

  getX() {
    return d3.scaleTime()
      .domain(d3.extent(this.data[0]?.data, d => d?.date))
      .range([this.options.margin.left, this.options.width - this.options.margin.right]);
  }

  getYAxis() {
    this.svg.selectAll('.grades').remove();
    return g =>
      g
        .attr('transform', `translate(${this.options.margin.left},0)`)
        .attr('class', 'mat-caption grades')
        .style('font-weight', '500')
        .style('font-family', 'Roboto')
        .style('color', '#B0B0B5')
        .call(
          d3.axisLeft(this.getY())
            .ticks(this.gradesLabels.length)
            .tickFormat(this.gradeFormats)
        )
        .call(gNext => gNext.select('.domain').remove())
        .call(gNext => gNext.select('.tick:last-of-type text').clone());
  }

  getY() {
    return d3.scaleLinear()
      .domain([0, this.gradesLabels.length])
      .range([this.options.height - this.options.margin.bottom, this.options.margin.top]);
  }

  getYForPoints() {
    return d3.scaleLinear()
      .domain([0, this.maxValueY])
      .range([this.options.height - this.options.margin.bottom, this.options.margin.top]);
  }

  getYAxisGrid() {
    return d3.axisLeft(this.getY())
      .tickSize(-this.options.width)
      .ticks(this.gradesLabels.length)
      .tickFormat('');
  }

  getXAxisGrid() {
    return d3.axisTop(this.getX())
      .tickSize(-this.options.height)
      .ticks(this.gradesLabels.length)
      .tickFormat('');
  }

  gradeFormats = d => {
    const alphabetGrades = this.gradesLabels;
    if (d !== 0) {
      return alphabetGrades[d - 1];
    }
    return;
  };

  addPoints(data: DateValue[], cssClass: string, lineIndex: number) {
    const points = this.svg
      .append('g')
      .selectAll('dot')
      .data(data);
    points.enter().append('circle');
    const circles = points.enter().append('circle');
    circles
      .attr('cx', (d) => {
        return this.getX()(d.date);
      })
      .attr('cy', (d) => {
        return this.getYForPoints()(d.value);
      })
      .attr('r', 4)
      .attr('stroke-width', 2)
      .attr('opacity', '0')
      .attr('class', cssClass);
    circles
      .transition()
      .delay(this.isAnimationEnabled ? 1000 : 0)
      .duration(this.isAnimationEnabled ? 1000 : 0)
      .style('opacity', '1');
  }
}
