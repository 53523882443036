<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-16">
  <h2 class="mat-h2 m-b-0" fxFlex>{{data.title}}</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div>
  <div *ngIf="data.msg" fxLayout="column" fxLayoutAlign="center center" class="m-b-24">
    <span class="full-width">{{data.msg}}</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="8px" class="confirm-topics-btn">
    <curr-button variant="text" (btnClick)="dialogRef.close('db')" *ngIf="data.firstTime">SET TEST AS ONLINE</curr-button>
    <curr-button variant="text" (btnClick)="dialogRef.close('offline')" *ngIf="data.firstTime">SET TEST AS OFFLINE</curr-button>
    <curr-button variant="text" (btnClick)="dialogRef.close('new')" *ngIf="!data.firstTime">GENERATE NEW TEST</curr-button>
    <curr-button variant="text" (btnClick)="dialogRef.close('manually')" *ngIf=" !data.firstTime">EDIT QUESTIONS MANUALLY</curr-button>
  </div>
</div>
