/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

export class YearSettings {
  constructor() {
    this.yearTermType = YearTermType.DIFFERENT;
    this.yearTypeForEachYear = [];
    this.semestersDates = [];
    this.hasSubGroups = false;
    this.isSameSemestersForAll = true;
    this.semestersQuantity = 2;
    this.lessonMinutes = 0;
    this.setYearTypeForEachYear();
    this.setSemestersDates();
  }

  setSemestersDates() {
    this.semestersDates = [];
    for (let i = 1; i <= 13; i++) {
      const periods: SemesterPeriod[] = [];
      for (let b = 1; b <= this.semestersQuantity; b++) {
        periods.push(new SemesterPeriod(`${b} Semester`));
      }
      this.semestersDates.push(new YearSemesterDate(i, periods));
    }
    // this.semestersDatesForAll = Object.assign({}, this.semestersDates[0]);
    this.semestersDatesForAll = JSON.parse(JSON.stringify(this.semestersDates[0]))
  }

  setYearTypeForEachYear() {
    this.yearTypeForEachYear = [];
    for (let i = 1; i <= 13; i++) {
      this.yearTypeForEachYear.push(new SchoolYearGroupNaming(i, this.yearTermType));
    }
  }

  // step 1
  yearLabel: string;
  yearTermType: string;
  yearTypeForEachYear: SchoolYearGroupNaming[];
  enabledYears: number[];
  hasSubGroups: boolean;
  // step 2
  isSameSemestersForAll: boolean;
  semestersQuantity: number;
  semestersDates: YearSemesterDate[];
  semestersDatesForAll: YearSemesterDate;
  // step 3
  ceaseDates: YearCeasePeriod[];
  lessonMinutes: number;
}

class SchoolYearGroupNaming {
  constructor(grade: number = 1, type: string = YearTermType.YEAR) {
    this.isEnabled = false;
    this.grade = grade;
    type === YearTermType.DIFFERENT ? this.customType = 'Unit' : this.customType = '';
    this.termType = type;
  }

  isEnabled: boolean;
  termType: string;
  grade: number;
  customType: string;
}


class FromToDate {
  from: Date;
  to: Date;
}

class SemesterPeriod extends FromToDate {
  name: string;

  constructor(name: string = 'Semester') {
    super();
    this.name = name;
  }
}

class CeasePeriod extends FromToDate {
  name: string;
}

class YearCeasePeriod {
  year: string | number;
  periods: CeasePeriod[];
}

export class YearSemesterDate {
  year: string | number;
  periods: SemesterPeriod[];

  constructor(y: number, periods: SemesterPeriod[]) {
    this.year = y;
    this.periods = periods;
  }
}


export enum YearTermType {
  YEAR = 'Year',
  CLASS = 'Class',
  FORM = 'Form',
  GRADE = 'Grade',
  DIFFERENT = 'Different',
}
