import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { ProfileDataService } from 'src/app/student/profile/profile-data.service';
import { SchoolAdmin, UserRole} from 'src/app/shared/components/profile-card/profile-card.model';

@Injectable()
export class SchoolAdminUserProfileService {
  private userProfileData: SchoolAdmin = {
    role: UserRole.SCHOOL_ADMIN,
    name: 'Jessie Jefferson',
    email: {value: 'j.jefferson@gmail.com', verify: true},
    phone: {value: '+44 08787676', verify: false},
    isActive: true,
    personalID: '435GG56YRE',
    school: 'High London School',
    country: 'UK',
    avatar: '',
    id: '1'
  }

  constructor(
    private _profileDataModel: ProfileDataService
  ) { }

  getUserProfile() {
    return of(this.userProfileData)
    .pipe(
      delay(500),
      tap((user) => {
        this._profileDataModel.UserProfile.next(user);
      })
    )
  }

  updateUser(user): Observable<any> {
    return of('updated')
    .pipe(
      delay(500),
      tap({
        next: () => {
          this._profileDataModel.UserProfile.next(user)
        }
      })
    )
  }
}
