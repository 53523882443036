import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'curr-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
/*
 * Usage example:

 * <curr-button (btnClick)='yourFunction()' variant="contained" size="small" icon="building" [isDragged]="false" [isDisabled]="false">
      Enabled
    </curr-button>
 *
 *  variant: string = 'outlined' | 'contained' | 'text'
 *  size: string = 'small' | 'big'
 *  icon: string
 *  [isDragged]: boolean
 *  [isDisabled]: boolean
 *  [isHighlighted]: boolean
 *  (btnClick) : EventEmitter
 * */
export class ButtonComponent implements OnInit {
  @Input() variant: 'outlined' | 'contained' | 'text' = 'contained';
  @Input() size: string = 'small';
  @Input() icon?: string;
  @Input() style?: any;
  @Input() color?: string = 'primary';
  @Input() isDragged? = false;
  @Input() isDisabled? = false;
  @Input() isHighlighted? = false;
  @Input() isUpperCase? = true;
  @Input() isMatNext?: boolean = false;
  @Input() isMatPrev?: boolean = false;

  @Output() btnClick = new EventEmitter();

  public onClick(event: Event) {
    if (!this.isDisabled) {
      this.btnClick.emit();
    } else {
      event.stopPropagation();
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
