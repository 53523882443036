<!--
 Created by Dmytro Sav. on 5/18/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-describedby="results table">
  <ng-container matColumnDef="topicName">
    <th mat-header-cell *matHeaderCellDef scope="row">Assessments</th>
    <td mat-cell *matCellDef="let element">
      <div class="mat-h1 tabl-header" (click)="goToLearningResources(element)">
        {{ element.test_name || 'No name'}}
      </div>
    </td>
  </ng-container>
  <ng-container matColumnDef="selfAssessment">
    <th mat-header-cell *matHeaderCellDef scope="row" class="w-250 result">Result</th>
    <td mat-cell *matCellDef="let element">
      <div fxLayout="column" fxLayoutGap="8px" class="selfAssessment">
        <ng-container *ngIf="element.test_type === 'sa'">
          <curr-button (btnClick)="goToResults(element)">VIEW RESULT</curr-button>
          <curr-button (btnClick)="goToTest(element)">RE DO</curr-button>
        </ng-container>
        <ng-container *ngIf="element.test_type === 'topic_test'">
          <a class="arrow-link" (click)="goToCTReview(element)">
            {{ element.grade || 'No grade' }}
          </a>
        </ng-container>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>