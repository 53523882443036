<!-- Created by Alex Poh. on 22/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-form-field [formGroup]="formGroup" color="primary" fxFlex appearance="outline">
<!--  <mat-label>{{ placeholder }}</mat-label>-->
  <input   [formControlName]="controlName" matInput placeholder="{{ placeholder }}" />
  <mat-hint>
    <div class="mat-caption">{{ caption }}</div>
  </mat-hint>
  <mat-error *ngIf="formGroup?.controls[controlName].errors">{{ errorMessage }}</mat-error>
</mat-form-field>
