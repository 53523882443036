/**
 * Created by Alex Poh. on 09/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Question, QuestionType } from '../../../../student/test.model';
import { getRestApiProvider, RestApiService, restApiServiceCreator } from '../../../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../../../shared/api/application-http-client';
import { COUResponse, LearningObjective } from '../../api-subject-details';
import { QuestionDatabasePopupComponent } from '../../../../shared/components/question-database-popup/question-database-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'curr-test-questions',
  templateUrl: './test-questions.component.html',
  styleUrls: ['./test-questions.component.scss'],
  providers: [getRestApiProvider<COUResponse>('curriculumCoUQuestions')]
})
export class TestQuestionsComponent implements OnInit, OnChanges {
  @Input() learningObjective: LearningObjective;
  @Input() subjectDetailsId: string;
  @Input() learningObjectives: LearningObjective[];
  saTestService: RestApiService<Question>;
  cumulativeTestService: RestApiService<Question>;
  saQuestions: Question[] = [];
  cumulativeQuestions: Question[] = [];
  options: any[] = [];
  isAddNewQuestionFormVisible = false;
  selectedIndex = 0;
  newQuestion;
  instantlyEditMultipart = false;
  cou;
  questionType = QuestionType;

  constructor(private httpClient: ApplicationHttpClient,
              public dialog: MatDialog,
              private couService: RestApiService<COUResponse>) {
    this.cumulativeTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveCTQuestions')(this.httpClient);
    this.saTestService = restApiServiceCreator<Question>('curriculumLearningObjectiveSAQuestions')(this.httpClient);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.learningObjective) {
      if (changes?.learningObjective?.currentValue?.id !== changes?.learningObjective?.previousValue?.id) {
        this.getQuestions();
        this.createBaseQuestion();
      }
    }
  }

  ngOnInit(): void {
    this.couService.getAll({ curriculum_id: this.subjectDetailsId }).subscribe(resp => {
      this.cou = resp;
    });
    this.getQuestions();
    // setTimeout(() => {
    //   this.addQuestion('manually', this.questionType.MULTIPART);
    // }, 2000);
  }

  private createBaseQuestion(type: string = QuestionType.SELECT_ONE) {
    if (!this.learningObjective?.id) {
      return;
    }
    this.newQuestion = new Question('New Question', this.learningObjective.id, type);
    this.checkQuestionOptions();
    if (this.selectedIndex === 0) {
      // SA
      this.newQuestion.base_type = 'sa';
    } else if (this.selectedIndex === 1) {
      // CT
      this.newQuestion.base_type = 'ct';
    }
  }

  getQuestions() {
    if (this.learningObjective?.id) {
      this.saTestService.getAll({ curriculum_learning_objective_id: this.learningObjective?.id }).subscribe(res => {
        this.saQuestions = res;
      });
      this.cumulativeTestService
        .getAll({ curriculum_LearningObjective_id: this.learningObjective?.id })
        .subscribe(res => {
          this.cumulativeQuestions = res;
        });
    }
  }

  addQuestion(manuallyOrDB: 'manually' | 'db', type: string = QuestionType.SELECT_ONE) {
    if (manuallyOrDB === 'manually') {
      this.createBaseQuestion(type);
      if (type === QuestionType.MULTIPART) {
        this.newQuestion.is_master = true;
        this.saveQuestion();
      } else {
        if (this.newQuestion?.question) {
          this.isAddNewQuestionFormVisible = true;
        }
      }
    }
    if (manuallyOrDB === 'db') {
      this.openDataBaseQuestionPopup();
    }
  }

  openDataBaseQuestionPopup() {
    const dialogRef = this.dialog.open(QuestionDatabasePopupComponent, {
      width: '80%',
      data: {
        questions: [],
        topicIds: [this.learningObjective.id],
        isCTCreation: false,
        questionType: 'CUMULATIVE_TEST' // ToDo:: need to change during design changes
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.addManually) {
        this.addQuestion('manually', this.questionType.SELECT_ONE);
      }
      if (result.length) {
        result.forEach(i => {
          if (this.selectedIndex === 1) {
            if (!this.cumulativeQuestions.find(q => q.id === i.id)) {
              this.cumulativeQuestions.push(i);
            }
          } else {
            if (!this.saQuestions.find(q => q.id === i.id)) {
              this.saQuestions.push(i);
            }
          }
        });
      }
    });
  }

  tabSelectedIndexChange(event) {
    this.selectedIndex = event;
    this.isAddNewQuestionFormVisible = false;
    this.createBaseQuestion();
  }

  getRightOptionIndex(): number {
    return this.newQuestion.correct_answer_options[0];
  }

  saveQuestion() {
    this.checkQuestionOptions();
    if (this.newQuestion.is_past_exam_question === undefined) {
      this.newQuestion.is_past_exam_question = false;
    }
    if (Object.keys(this.newQuestion.answer_options_competence).length === 0) {
      delete this.newQuestion.answer_options_competence;
    }
    // if (this.newQuestion.answer_options_competence[this.getRightOptionIndex()]) {
    //   delete this.newQuestion.answer_options_competence[this.getRightOptionIndex()]
    // }
    if (this.newQuestion.answer_type === this.questionType.MULTIPART) {
      this.newQuestion.mark = null;
    }
    let req: Observable<Question>;
    if (this.selectedIndex === 0) {
      // SA
      req = this.saTestService.add(this.newQuestion);
    } else if (this.selectedIndex === 1) {
      // CT
      req = this.cumulativeTestService.add(this.newQuestion);
    }
    req
      .pipe(
        switchMap(res => {
          this.getQuestions();
          return of(res);
        })
      )
      .subscribe(resp => {
        if ((resp as any).data) {
          this.newQuestion.id = (resp as any).data.id;
        }
        if (this.selectedIndex === 1) {
          this.cumulativeQuestions.unshift(this.newQuestion);
        } else {
          this.saQuestions.unshift(this.newQuestion);
        }
        this.isAddNewQuestionFormVisible = false;
        if (this.newQuestion.answer_type === QuestionType.MULTIPART) {
          this.instantlyEditMultipart = true;
        } else {
          this.createBaseQuestion();
          this.instantlyEditMultipart = false;
        }
      });
  }

  setCreateTestText() {
    const type = this.selectedIndex === 1 ? 'Cumulative Test' : 'Self Assessment';
    let msg = '';
    if (this.selectedIndex === 1) {
      msg =
        this.cumulativeQuestions.length >= 5
          ? `You have enough questions for generating ${ type }`
          : `Minimum 5 questions are required for a ${ type } in this topic. `;
    } else {
      msg =
        this.saQuestions.length >= 1
          ? `You have enough questions for generating ${ type }`
          : `Minimum 1 question are required for a ${ type } in this topic. `;
    }

    return msg;
  }

  enableToggleBtn() {
    if (this.selectedIndex === 1) {
      return this.cumulativeQuestions.length >= 5;
    } else {
      return this.saQuestions.length >= 1;
    }
  }

  removeQuestion(id: number, type: string) {
    const questionsArr = type === 'sa' ? 'saQuestions' : 'cumulativeQuestions';
    const ind = this[questionsArr].findIndex(e => e.id === id);
    if (ind > -1) {
      this[questionsArr].splice(ind, 1);
    }
    this.instantlyEditMultipart = false;
  }

  private checkQuestionOptions() {
    // tslint:disable-next-line:triple-equals
    if (
      this.newQuestion.answer_type === this.questionType.SELECT_ONE ||
      this.newQuestion.answer_type === this.questionType.SELECT_MULTIPLE
    ) {
      this.newQuestion.answer_options = this.newQuestion.answer_options.map((a, i) => {
        if (!a.length) {
          return `Option ${ i + 1 }`;
        }
        return a;
      });
    }
  }
}
