/**
 * Created by Daniil Vat. on 20/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'curr-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {
  imgSrc: string;

  constructor(public dialogRef: MatDialogRef<ImagePopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.imgSrc = this.data.imgSrc;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
