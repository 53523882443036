/**
 * Created by Alex Poh. on 26/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
/**
 * Will replace provided html with a loader when [isLoading] is true.
 * Example:
 * <curr-loader [isLoading]='loadingStatus' [diameter]='48'>
 *    <button>Submit<button>
 * </curr-loader>
 *
 */
@Component({
  selector: 'curr-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input()  isLoading = true;

  @Input()  diameter = 48;

  constructor() {}

  ngOnInit(): void {}
}
