<!--Created by Mykola Dan. on 02/11/21.-->
<!--Copyright © 2021 SEVEN. All rights reserved-->
<div class="container">
  <div class="header" fxLayout="row">
    <span class="title">Historical assessments</span>
    <mat-icon class="arrow"
              (click)="openWidget()"
              [ngClass]="{ 'closed': !isOpen }">
              keyboard_arrow_up
    </mat-icon>
  </div>
  <div *ngIf="isOpen" class="body m-t-16" fxLayout="column">
    <curr-loader *ngIf="isLoading"></curr-loader>
    <curr-assessment
      class="assessment"
      *ngFor="let assessment of assessments$ | async"
      [assessment]="assessment"
    ></curr-assessment>
    <curr-button class="m-t-16 button" size="big" (btnClick)="goToHistoricalAssessments()" color="accent">View All</curr-button>
  </div>
</div>
