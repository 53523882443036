/**
 * Created by Yaroslav S. on 17/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'curr-loader-with-text',
  templateUrl: './loader-with-text.component.html',
  styleUrls: ['./loader-with-text.component.scss']
})
export class LoaderWithTextComponent {

  @Input() title: string;
  @Input() text: string;

  constructor() {}
}
