import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Options } from '../dropdown.component';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'curr-dropdown-component-v2',
  templateUrl: './dropdown-component-v2.component.html',
  styleUrls: ['./dropdown-component-v2.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        query(
          'true',
          [
            style({ height: '0', opacity: 0 }),
            stagger(5, [animate('0.3s ease-in-out', style({ height: '*', opacity: 1 }))])
          ],
          {
            optional: true
          }
        ),

        query('false', [stagger(-5, [animate('0.3s ease-in-out', style({ opacity: 0, height: '0' }))])], {
          optional: true
        })
      ])
    ])
  ]
})
export class DropdownComponentV2Component implements OnInit, OnChanges {
  @Input() placeholder: string;

  @Input() options: Array<Options>;

  @Input() isDisabled? = false;

  @Input() isMultiSelected? = false;

  @Input() defaultValue: Options;

  @Input() selectedValue: string[] | string;

  @Input() formGroup?: FormGroup; // TODO: support form group for this component
  @Input() controlName? = '';
  @Input() icon?: string;

  @Output() selectedValueChange = new EventEmitter<any | []>();
  isShowed = false;
  isError = false;
  selectedLabel;

  constructor(private fb: FormBuilder) {}

  ngOnChanges(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.selectedValue)
      });
      this.controlName = 'item';
    }
    if (!this.isMultiSelected && !Array.isArray(this.selectedValue) && this.selectedValue) {
      this.selectedValue = [this.selectedValue];
    }
    if (!this.selectedValue || !this.getOptionByValue(this.selectedValue[0])) {
      // this.selectedValue = this.options[0]?.value;
    }
    this.selectedLabel = this.getSelectedLabel();
  }

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  selectValue(value: string, event?: Event) {
    event?.stopPropagation();
    if (this.isMultiSelected) {
      if ((this.selectedValue as string[]).includes(value)) {
        (this.selectedValue as string[]).splice(this.selectedValue.indexOf(value), 1);
      } else {
        (this.selectedValue as string[]).push(value);
      }
    } else {
      this.selectedValue = [value];
    }
    this.emitSelectedValue();
    this.selectedLabel = this.getSelectedLabel();
    if (!this.isMultiSelected) {
      this.closeDropDown();
    }
  }

  getOptionByValue(value: string): Options {
    return this.options.find(opt => opt.value === value);
  }

  closeDropDown() {
    this.isShowed = false;
  }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.selectedValue)
      });
      this.controlName = 'item';
    }
  }

  getSelectedLabel() {
    return (this.selectedValue as string[])?.map(sv => this.getOptionByValue(sv)?.label).join(', ');
  }

  emitSelectedValue() {
    if (this.isMultiSelected) {
      this.selectedValueChange.emit(this.selectedValue);
    } else {
      this.selectedValueChange.emit(this.selectedValue[0]);
    }
  }
}
