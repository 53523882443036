/*
 * Created by Dmytro Sav. on 5/5/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { map } from 'rxjs/operators';
import {
  IUnderPerformingStudent,
  IMostIncorrectQuestions,
  IReportStats,
  IFrequentlyLeftUnansweredQuestion,
  ILowestComprehensionObject,
  IQuestionsAnsweredIncorrectly,
  IMostLostMarks,
  IQuestionWithMostLostMarks
} from '../interfaces';
import { LearningObjective } from '../../shared-pages/subject-details/api-subject-details';
import { IUnderlyingFactors } from '../interfaces/underlying-factors';

@Injectable({
  providedIn: 'root'
})
export class CourseDetailsService {
  blocks = [
    {
      title: 'Average grade for all students',
      hint: {},
      icon: 'info',
      bottomTitle: { label: 'B' },
      progressBar: { color: 'accent', value: 79 }
    },

    {
      title: 'Lessons',
      hint: {},
      bottomTitle: { label: '43/95 completed - 45%' },
      progressBar: { color: 'accent', value: 45 }
    },

    {
      title: 'Cummulative Tests',
      hint: {
        label: 'Next Cumulative Test: 12 February at 9:30',
        icon: 'calendar',
        color: 'primary'
      },
      bottomTitle: { label: `12/25 completed - 53%` },
      progressBar: { color: 'accent', value: 53 }
    },

    {
      title: 'Percentage of completed Self Assessments',
      hint: {
        icon: 'warn',
        label: '3% of your Self Assessments are incomplete',
        color: 'warn',
        link: { rout: '/dashboard', fragment: 'students' },
        // postIcon: 'mdi-arrow-right',
        isUnderLine: false
      },
      bottomTitle: { label: '97% completed', isUnderLine: false },
      progressBar: { color: 'accent', value: 97 }
    }
  ];

  course = {
    blocks: this.blocks,
    title: {}
  };

  private courseDetails = new BehaviorSubject<any>(this.course);
  data = this.courseDetails.asObservable();

  constructor(private httpClient: ApplicationHttpClient) {}

  updateData = (courseDetails: any) => {
    this.courseDetails.next(courseDetails);
  };

  getLearningObjectiveById(learningObjectiveId: string) {
    return this.httpClient
      .get<{ data: { learningObjective: LearningObjective } }>(`/curriculumLearningObjectives/${learningObjectiveId}`)
      .pipe(map(res => res?.data?.learningObjective));
  }

  // SA methods
  getSaMostIncorrectQuestions(courseId: string, learningObjectiveId: string): Observable<IMostIncorrectQuestions> {
    return this.httpClient
      .get<{ data: IMostIncorrectQuestions }>(`/curriculumSaMostIncorrect/${courseId}/${learningObjectiveId}`)
      .pipe(map(res => res?.data));
  }

  getSaUnderPerformingStudents(courseId: string, learningObjectiveId: string): Observable<IUnderPerformingStudent[]> {
    return this.httpClient
      .get<{ data: { items: IUnderPerformingStudent[] } }>(
        `/curriculumSaUnderPerforming/${courseId}/${learningObjectiveId}`
      )
      .pipe(map(res => res?.data?.items));
  }

  // TT methods
  getReportStats(courseId: string, learningObjectiveId: string): Observable<IReportStats> {
    return this.httpClient
      .get<{ data: IReportStats }>(`/curriculumCumReportStats/${learningObjectiveId}/${courseId}`)
      .pipe(map(res => res?.data));
  }

  getTtMostIncorrectQuestions(courseId: string, learningObjectiveId: string): Observable<IMostIncorrectQuestions> {
    return this.httpClient
      .get<{ data: IMostIncorrectQuestions }>(`/curriculumCumMostIncorrect/${courseId}/${learningObjectiveId}`)
      .pipe(map(res => res?.data));
  }

  getUnderlyingFactors(courseId: string, learningObjectiveId: string): Observable<IUnderlyingFactors> {
    return this.httpClient
      .get<IUnderlyingFactors>(`/curriculumCumTests/${learningObjectiveId}/review-factors?course_id=${courseId}`);
  }

  getTtUnderPerformingStudents(courseId: string, learningObjectiveId: string): Observable<IUnderPerformingStudent[]> {
    return this.httpClient
      .get<{ data: IUnderPerformingStudent[] }>(`/underperformingStudents/${learningObjectiveId}/${courseId}`)
      .pipe(map(res => res?.data));
  }

  getTtOutperformingStudents(courseId: string, learningObjectiveId: string): Observable<IUnderPerformingStudent[]> {
    return this.httpClient
      .get<{ data: IUnderPerformingStudent[] }>(`/outperformingStudents/${learningObjectiveId}/${courseId}`)
      .pipe(map(res => res?.data));
  }

  getFindListOfIncorrectAnswersByType(
    courseId: string,
    learningObjectiveId: string
  ): Observable<IQuestionsAnsweredIncorrectly> {
    return this.httpClient
      .get<{ data: IQuestionsAnsweredIncorrectly }>(
        `/findListOfIncorrectAnswersByType/${courseId}/${learningObjectiveId}`
      )
      .pipe(map(res => res?.data));
  }

  getTypesOfQuestionsWithMostLostMarks(courseId: string, learningObjectiveId: string): Observable<IMostLostMarks> {
    return this.httpClient
      .get<{ data: IMostLostMarks }>(`/typesOfQuestionsWithMostLostMarks/${learningObjectiveId}/${courseId}`)
      .pipe(map(res => res?.data));
  }

  getMostFrequentlyLeftUnansweredQuestions(
    courseId: string,
    learningObjectiveId: string
  ): Observable<IFrequentlyLeftUnansweredQuestion[]> {
    return this.httpClient
      .get<{ data: IFrequentlyLeftUnansweredQuestion[] }>(
        `/mostFrequentlyLeftUnanswered/${learningObjectiveId}/${courseId}`
      )
      .pipe(map(res => res?.data));
  }

  getMostFrequentIncorrectQuestions(
    courseId: string,
    learningObjectiveId: string
  ): Observable<IFrequentlyLeftUnansweredQuestion[]> {
    return this.httpClient
      .get<{data: IFrequentlyLeftUnansweredQuestion[]}>(
        `/frequentlyIncorrect/${learningObjectiveId}/${courseId}`
      ).pipe(map(res => res?.data));
  }

  getLowestComprehensionObjects(
    courseId: string,
    learningObjectiveId: string
  ): Observable<ILowestComprehensionObject[]> {
    return this.httpClient
      .get<{ data: ILowestComprehensionObject[] }>(`/lowestComprehension/${learningObjectiveId}/${courseId}`)
      .pipe(map(res => res?.data));
  }

  getQuestionsByMarkGroupWhereMostMarksAreLost(
    courseId: string,
    learningObjectiveId: string,
    fromMark: number,
    toMark: number
  ): Observable<IQuestionWithMostLostMarks[]> {
    return this.httpClient
      .get<{ data: IQuestionWithMostLostMarks[] }>(
        `/questionsByTypeOfQuestionsWithMostLostMarks/${learningObjectiveId}/${courseId}/${fromMark}/${toMark}`
      )
      .pipe(map(res => res?.data));
  }
}
