/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { AiUploadFilesService } from './ai-upload-files.service';
import { FileUploadModel, fileInit, AIImages } from './ai-upload-files.interface';
import { IgxToastComponent } from 'igniteui-angular';
import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { AiZonesMarkerPopupComponent } from '../ai-zones-marker-popup/ai-zones-marker-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { IZone } from '../ai-zones-marker/ai-zones-marker.interface';

@Component({
  selector: 'curr-ai-upload-files',
  templateUrl: './ai-upload-files.component.html',
  styleUrls: ['./ai-upload-files.component.scss'],
  providers: [AiUploadFilesService]
})
export class AiUploadFilesComponent implements OnInit {
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  @ViewChild('toast') private toast: IgxToastComponent;
  @ViewChild('aiFilesProcessItem') aiFilesProcessItem: ElementRef;
  @ViewChild('dropListContainer') dropListContainer?: ElementRef;
  @ViewChild('headerContainer') headerContainer?: ElementRef;

  @Input() title?: string = 'Drag and drop an image or click';
  @Input() formats? = ['image/jpeg', 'image/jpg','image/png'];
  @Input() automaticallyImport: boolean = true;
  @Input() aiFilesType: 'question' | 'markScheme' = 'question';
  @Input() enableProgressBar: boolean = true;
  @Input() isMultiple: boolean = false;
  @Input() maxFiles: number = 5;
  @Input() initImages: any;
  @Output() onLoadedFile: EventEmitter<any> = new EventEmitter();
  @Output() onCollectedFiles = new EventEmitter<{collectedFiles: AIImages[], aiFilesType: 'question' | 'markScheme', originalFiles: any}>();

  files: Array<FileUploadModel> = [];
  pureFilesList: File[] = [];

  supported = [];
  color = 'accent';
  supportedConverted;

  notSupportedMessage = '';
  // imageHeight = `192px`;
  isFieldFocused = false;

  dropListReceiverElement?: HTMLElement;
  dragDropInfo?: {
    dragIndex: number;
    dropIndex: number;
  };

  constructor(
    private uploadFilesService: AiUploadFilesService,
    private elementRef: ElementRef,
    public dialog: MatDialog) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.correctImageHeight();
  }

  @HostListener('document:paste', ['$event'])
  onPasteScreenshot(event: ClipboardEvent): void {
    if (this.files.length >= this.maxFiles) {
      event.preventDefault();
      return;
    }
    if (this.isFieldFocused) {
      this.isFieldFocused = false;
      this.elementRef.nativeElement.querySelector('[contenteditable]').blur();
      const items = event.clipboardData?.items;
      if (items) {
        // @ts-ignore
        for (const item of items) {
          if (item.type.indexOf('image') !== -1) {
            const screenshotBlob = item.getAsFile();

            if (this.files.length > 0) {
              this.files.push({ data: screenshotBlob, ...fileInit });
            } else {
              this.files = [{ data: screenshotBlob, ...fileInit }];
            }

            if (this.pureFilesList.length > 0) {
              this.pureFilesList.push(screenshotBlob);
            } else {
              this.pureFilesList = [screenshotBlob];
            }

            this.uploadFilesService.uploadFiles(this.files);

            for (let i = 0; i < this.pureFilesList.length; i++) {
              this.handleFileInput(this.pureFilesList[i], i);
              this.handleFileToBase64(this.pureFilesList[i], i);
            }

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < this.pureFilesList.length; i++) {
              this.resizeImage(this.pureFilesList[i], 790).then(resizedFile => {
                this.files[i].resizedFile = resizedFile;
                this.handleFileInputResized(resizedFile, i);
                this.handleFileToBase64Resized(resizedFile, i);
              })
            }

            this.correctImageHeight();
            this.onLoadedFile.emit(this.pureFilesList);

            event.preventDefault();
            return;
          }
        }
      }
    }
  }

  onFocusScreenshot(): void {
    this.isFieldFocused = true;
  }

  onBlurScreenshot(): void {
    this.isFieldFocused = false;
  }

  ngOnInit() {
    if (this.initImages && this.initImages.length > 0) {
      this.files = this.initImages;
    }

    this.supported = [...this.formats];
    this.supportedConverted = this.uploadFilesService.convertSupported(this.supported);
    this.notSupportedMessage =
      'This file format is not supported. Please select the correct file format: ' + this.supportedConverted;
  }

  onMouseEnter(i: number): void {
    this.files[i].hovered = true;
  }

  onMouseLeave(i: number): void {
    this.files[i].hovered = false;
  }

  public toastPosition: any;

  marked() {
    this.files.forEach(file => {
      file.marked = true;
    });
  }

  getFileType(fileName: string) {
    const dotLastPosition = fileName.lastIndexOf('.');
    return fileName.slice(dotLastPosition);
  }

  onClick(droppedEvent?) {
    if (this.files.length >= this.maxFiles) return;
    // this.files = [];
    this.marked();
    const fileUpload = this.fileUpload.nativeElement;

    if (!droppedEvent) {
      fileUpload.onchange = () => {
        let filesTree = fileUpload.files;
        if (this.files.length + fileUpload.files.length >= this.maxFiles) {
          filesTree = Array.from(fileUpload.files).slice(0, (this.maxFiles - this.files.length));
        }

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < filesTree.length; index++) {
          const file = filesTree[index];
          if (this.isMultiple) {
            this.files.push({ data: file, ...fileInit });
            this.pureFilesList.push(file);
          } else {
            this.files = [{ data: file, ...fileInit }];
            this.pureFilesList = [file];
          }
        }

        if (this.automaticallyImport) {
          this.uploadFilesService.uploadFiles(this.files);
        }

        for (let i = 0; i < this.pureFilesList.length; i++) {
          this.handleFileInput(this.pureFilesList[i], i);
          this.handleFileToBase64(this.pureFilesList[i], i);
        }

        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.pureFilesList.length; index++) {
          this.resizeImage(this.pureFilesList[index], 790).then(resizedFile => {
            this.files[index].resizedFile = resizedFile;
            this.handleFileInputResized(resizedFile, index);
            this.handleFileToBase64Resized(resizedFile, index);
          })
        }

        this.correctImageHeight();
        this.onLoadedFile.emit(this.pureFilesList);
      };
      fileUpload.click();
    } else {
      let droppedEventTree = droppedEvent;
      if (this.files.length + droppedEvent.length >= this.maxFiles) {
        droppedEventTree = Array.from(droppedEvent).slice(0, (this.maxFiles - this.files.length));
      }
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < droppedEventTree.length; index++) {
        if (index >= this.maxFiles) return;
        const file = droppedEventTree[index];
        const isSupported = this.supported.includes(file.type) || this.supported.includes(this.getFileType(file.name));
        if (!isSupported) {
          this.toast.open();
        } else {
          if (this.isMultiple) {
            this.files.push({ data: file, ...fileInit });
            this.pureFilesList.push(file);
          } else {
            this.files = [{ data: file, ...fileInit }];
            this.pureFilesList = [file];
          }
        }
      }

      if (this.automaticallyImport) {
        this.uploadFilesService.uploadFiles(this.files);
      }

      for (let i = 0; i < this.pureFilesList.length; i++) {
        this.handleFileInput(this.pureFilesList[i], i);
        this.handleFileToBase64(this.pureFilesList[i], i);
      }

      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < this.pureFilesList.length; index++) {
        this.resizeImage(this.pureFilesList[index], 790).then(resizedFile => {
          this.files[index].resizedFile = resizedFile;
          this.handleFileInputResized(resizedFile, index);
          this.handleFileToBase64Resized(resizedFile, index);
        })
      }

      this.correctImageHeight();
      this.onLoadedFile.emit(this.pureFilesList);
    }

    fileUpload.value = '';
  }

  editImage($event: MouseEvent, file: FileUploadModel, i: number) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(AiZonesMarkerPopupComponent, {
      width: '840px',
      maxHeight: '90vh',
      data: {
        title: 'Select Area',
        imageLink: file.resizedLocalLink,
        zones: file.zoneData
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.length > 0) {
        this.files[i].zoneData = result;
      }
    });
  }

  sendToAI() {
    const collectedFiles = this.files.map(item => {
      const includeData = item.zoneData && item.zoneData.length > 0 ? item.zoneData.map((item: IZone) => {
        return {
          description: '',
          is_ignored: item.isIgnoreActive,
          position: {
            start_x: item.position.start_x,
            start_y: item.position.start_y,
            width: item.position.width,
            height: item.position.height,
          }
        }
      }) : [];

      return {
        src: item.resizedBase64,
        type: 'image',
        include: includeData,
      }
    });

    this.onCollectedFiles.emit({collectedFiles, aiFilesType: this.aiFilesType, originalFiles: this.files})
  }

  private handleFileInput(file: File, i: number): void {
    this.files[i].localLink = URL.createObjectURL(file);
  }

  private handleFileInputResized(file: File, i: number): void {
    this.files[i].resizedLocalLink = URL.createObjectURL(file);
  }

  private handleFileToBase64(file: File, i: number): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.files[i].base64 = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  private handleFileToBase64Resized(file: File, i: number): void {
    const reader = new FileReader();
    reader.onload = () => {
      this.files[i].resizedBase64 = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  retryFile(file: FileUploadModel) {
    this.uploadFilesService.retryFile(file);
  }

  cancelFile(file: FileUploadModel, index: number) {
    this.uploadFilesService.cancelFile(file);
    this.pureFilesList.splice(index, 1);
  }

  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    this.dragDropInfo = { dragIndex, dropIndex };

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');

    if (phElement) {
      phContainer.removeChild(phElement);
      phContainer.parentElement?.insertBefore(phElement, phContainer);

      moveItemInArray(this.files, dragIndex, dropIndex);
    }
  }

  dragMoved(event: CdkDragMove<number>) {
    if (!this.dropListContainer || !this.dragDropInfo) return;

    const placeholderElement =
      this.dropListContainer.nativeElement.querySelector(
        '.cdk-drag-placeholder'
      );

    const receiverElement =
      this.dragDropInfo.dragIndex > this.dragDropInfo.dropIndex
        ? placeholderElement?.nextElementSibling
        : placeholderElement?.previousElementSibling;

    if (!receiverElement) {
      return;
    }

    receiverElement.style.display = 'none';
    this.dropListReceiverElement = receiverElement;
  }

  dragDropped(event: CdkDragDrop<number>) {
    if (!this.dropListReceiverElement) {
      return;
    }

    this.dropListReceiverElement.style.removeProperty('display');
    this.dropListReceiverElement = undefined;
    this.dragDropInfo = undefined;
  }

  private correctImageHeight() {
    // const aiFilesProcessItemWidth = this.aiFilesProcessItem?.nativeElement
    //   ? this.aiFilesProcessItem.nativeElement.offsetWidth + ''
    //   : (this.headerContainer?.nativeElement.offsetWidth / 3) - 10;
    // this.imageHeight = `${aiFilesProcessItemWidth}px`;
  }

  private resizeImage(file: File, maxWidth: number): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob: Blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/jpeg', // Set the desired file type (e.g., image/jpeg)
              lastModified: Date.now() // Use the current timestamp for last modified date
            });
            resolve(resizedFile);
          }, 'image/jpeg'); // Convert to JPEG format
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }
}
