/**
 * Created by Maxim B. on 22/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
export class CommonFields {

  age = 0;
  gender: 'male' | 'female' = 'female';
  subject = '';
  year = 0;
  performance = '';
  avgGradeYearBeforeLast = '';
  avgGradeLastYear = '';
  avgGradeThisYear = '';
  exAvgGradeYearBeforeLast = '';
  exAvgGradeLastYear = '';
  exAvgGradeThisYear = '';
  avgGradeByPeriod = '';
  exAvgGradeByPeriod = '';
  avgGradeThisWeek = '';
  exAvgGradeThisWeek = '';
  avgGradeThisSemester = '';
  exAvgGradeThisSemester = '';
  avgGradeThisSemesterBefore = '';
  exAvgGradeThisSemesterBefore = '';
  avgGradeThisMonth = '';
  exAvgGradeThisMonth = '';
  avgGradeThisMonthBefore = '';
  exAvgGradeThisMonthBefore = '';
  averageMeetingAttendanceRate = '';
  lou = '';
  lot = '';
  lor = '';
  selfAssessmentCompletionPercentage = '';

  form='';
  level='';

}
