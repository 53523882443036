import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { MockDataGenerator } from 'src/app/student/subject-notes/subject-notes.model';

export interface NotesAboutStudent {
  authorName: string,
  authorRole: string,
  date: Date,
  note: string,
  authorId: string
}

export interface ArchiveMeeting {
  date: Date,
  personName: string,
  personId: string
}

export interface SubjectResult {
  subjectName: string,
  progress: number,
  grade: string,
}

export interface ArchiveStudentTest {
  type: string,
  title: string,
  typeFullName: string,
  date: Date,
  subject: string,
  complete: boolean,
  result?: string,
  isAttention?: boolean
}

@Injectable()
export class TeacherService {

  public curriculumId$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  private notes: NotesAboutStudent = {
    authorName: 'Emma Larsen',
    date: new Date(),
    authorRole: 'teacher',
    authorId: 'teacher1',
    note: 'Very smart student with a talent for the exact sciences. He is best acquainted with the gradual study of material and video.'
  };
  private teacherNote: NotesAboutStudent = {
    authorName: 'Emma Larsen',
    date: new Date(),
    authorRole: 'teacher',
    authorId: 'teacherId2',
    note: 'Some note about this student from me.'
  }
  private meetingArchive: ArchiveMeeting = {
    date : new Date(),
    personName: 'Ashley Bales',
    personId: 'personId1'
  }
  private subjectsResults:SubjectResult[] = [
    {
      subjectName: 'Astronomy',
      grade: 'A+',
      progress: 0.53
    },
    {
      subjectName: 'Math',
      grade: 'A+',
      progress: 0.53
    },
    {
      subjectName: 'Astronomy',
      grade: 'A+',
      progress: 0.91
    },
    {
      subjectName: 'Sciense',
      grade: 'A+',
      progress: 0.53
    },
    {
      subjectName: 'Literature',
      grade: 'A+',
      progress: 0.71
    },
    {
      subjectName: 'Astronomy',
      grade: 'A+',
      progress: 0.44
    },
    {
      subjectName: 'Literature',
      grade: 'A+',
      progress: 0.68
    },
    {
      subjectName: 'Chemistry',
      grade: 'A+',
      progress: 0.83
    },
    {
      subjectName: 'Physics',
      grade: 'A+',
      progress: 0.59
    },
    {
      subjectName: 'History',
      grade: 'A+',
      progress: 0.88
    }
  ];
  private archiveTestList: ArchiveStudentTest[] = [
    {
      complete: true,
      date: new Date(),
      result: 'B',
      subject: 'Physics',
      type: 'ct',
      title: 'CT2',
      typeFullName: 'Commulative Test',
      isAttention: true
    },
    {
      complete: true,
      date: new Date(),
      subject: 'Astronomy',
      type: 'sa',
      title: 'Black Holes',
      typeFullName: 'Self Assessment',
    },
    {
      complete: true,
      date: new Date(),
      result: 'B',
      subject: 'Math',
      type: 'ct',
      title: 'CT4',
      typeFullName: 'Commulative Test'
    },
    {
      complete: true,
      date: new Date(),
      subject: 'Economics',
      type: 'sa',
      title: 'Macroeconomics',
      typeFullName: 'Self Assessment'
    },
    {
      complete: true,
      date: new Date(),
      result: 'B+',
      subject: 'Biology',
      type: 'ct',
      title: 'CT3',
      typeFullName: 'Commulative Test'
    },
    {
      complete: true,
      date: new Date(),
      result: 'A',
      subject: 'Biology',
      type: 'ct',
      title: 'CT2',
      typeFullName: 'Commulative Test'
    },
  ]

  constructor() { }

  getNotesAboutStudent(studentId: string): Observable<NotesAboutStudent[]> {
    return of([...MockDataGenerator.generateItems(15, this.notes), this.teacherNote]);
  }

  getMeetingArchiveList(studentId: string): Observable<ArchiveMeeting[]> {
    return of(MockDataGenerator.generateItems(10, this.meetingArchive))
  }

  getStudentSubjectsResults(studentId: string): Observable<SubjectResult[]> {
    return of(this.subjectsResults)
  }

  getStudentArchiveTestList(studentId: string): Observable<ArchiveStudentTest[]> {
    return of(this.archiveTestList)
  }


}
