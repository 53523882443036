<div class="container">
  <section fxLayout="row" fxLayoutAlign="end" class="btn-section">
    <curr-button class="curr-button" variant="outlined" (btnClick)="deactivateUser()">DEACTIVATE THIS USER</curr-button>
    <curr-button class="delete-user curr-button" variant="outlined" (btnClick)="deleteUser()">DELETE</curr-button>
  </section>
  <div class="user-profile" fxLayoutGap="73px" fxLayoutAlign="center stretch" fxLayout="row" fxLayout.xs="column">
    <section fxFlex.gt-xs="432px">
      <div class="title m-b-4" fxLayoutAlign="space-between">
        <span>Profile</span>
      </div>
      <curr-profile-card
        (updateUserCard)="this.updateUserProfile($event)"
        [profileData]="profileCardData">
      </curr-profile-card>
    </section>

    <section class="delete-permission-card" fxLayout="column">
      <div class="title" fxLayoutAlign="space-between">
        <span>Permission</span>
      </div>
      <mat-divider></mat-divider>
      <div class="card">
        <curr-checkbox
          class="permission-box m-b-4"
          [options]="checkboxList.options"
          [color]="checkboxList.color"
          [(value)]="checkboxList.value"
          [type]="checkboxList.type"
          [isMultipleSelect]="checkboxList.isMultipleSelect"
        ></curr-checkbox>
      </div>
    </section>
  </div>
</div>
