<!--
 Created by Dmytro Sav. on 4/30/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container" ngClass.lt-sm="mob-container">
  <div class="m-b-40" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="new-container">
      <div class="new-container_heading">
        <h1>Subjects</h1>
        <curr-button (btnClick)="goToAddSubjectsPage()" variant="contained" color="primary" size="big" fxFlex.gt-xs="148px">
          + ADD SUBJECT
        </curr-button>
      </div>
    </div>
  </div>
<div class="main-part-container">
  <div class="wrapper course-subjects-wrapper" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="24px grid"
       fxLayoutAlign="start center">
    <ng-container *ngIf="isStudent">
      <curr-student-course-card *ngFor="let card of studentCourses" [card]="card" [isDetailView]="false"
                                ngClass.lt-sm="full-width"></curr-student-course-card>
    </ng-container>
    <ng-container *ngIf="!isStudent">
      <curr-course-card *ngFor="let card of cards" [course]="card" ngClass.lt-sm="full-width"></curr-course-card>
    </ng-container>
  </div>
  <div class="calendar-wrapper" *ngIf="!isExamPreparation && isLoadedSubscriptionInfo">
    <curr-dashboard-calendar class="course-subjects-calendar"></curr-dashboard-calendar>
  </div>
</div>
<!--  <div fxLayout="column" fxLayoutAlign="center center" class="m-t-56" ngClass.lt-sm="m-b-24">-->
<!--    <curr-loader [isLoading]="isLoading" [diameter]="48" class="m-b-8"></curr-loader>-->
<!--    <curr-button variant="contained" color="primary" size="big" (click)="loadStudentCourses()">-->
<!--      SHOW MORE-->
<!--    </curr-button>-->
<!--  </div>-->
</div>
