import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-localstorage';
import { environment } from '../../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private baseApiUrl = environment.apiUrl;

  constructor(private localStorageService: LocalStorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers;
    if (req.url.indexOf('oauth/token') === -1) {
      const token = this.localStorageService.get('token');
      headers = { 'Content-Type': 'application/json' };

      if (req.url === `${ this.baseApiUrl }/service/import/topics` || req.body instanceof FormData || req.body?.file) {
        headers = {};
      }

      if (token) {
        // tslint:disable-next-line:no-string-literal
        headers['Authorization'] = `${ token }`;
      }

      const request = req.clone({ setHeaders: headers });

      return next.handle(request);
    } else {
      return next.handle(req);
    }
  }
}
