/**
 * Created by Alex Poh. on 13/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AbstractHasAccessDirective } from './abstract-has-access.directive';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from '../user';
import { UserService } from '../user.service';
import { Observable } from 'rxjs';

@Directive({
  selector: '[currHasPermissions]'
})
export class HasPermissionsDirective extends AbstractHasAccessDirective {
  @Input() currHasPermissions: string[];

  constructor(
    protected viewContainerRef: ViewContainerRef,
    protected templateRef: TemplateRef<any>,
    public userService: UserService
  ) {
    super(viewContainerRef, templateRef, userService);
  }

  hasAccess(user: User): Observable<boolean> {
    return this.userService.hasPermissions(this.currHasPermissions);
  }
}
