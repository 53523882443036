import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'curr-concern-widget-row',
  templateUrl: './concern-widget-row.component.html',
  styleUrls: ['./concern-widget-row.component.scss']
})
export class ConcernWidgetRowComponent implements OnInit {
  @Input() concern = 0;
  progress = '0%';

  constructor() {
  }

  ngOnInit(): void {
    if (this.concern > 1) {
      this.concern = this.concern / 100;
    }
    this.progress = (this.concern * 100) + '%';
    if (this.concern < 0) {
      this.progress = '1%';
    }
  }

}
