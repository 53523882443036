/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAiKeys } from '../managing-api-keys.interface';

@Component({
  selector: 'curr-open-ai-section',
  templateUrl: './open-ai-section.component.html',
  styleUrls: ['./open-ai-section.component.scss']
})
export class OpenAiSectionComponent implements OnInit {

  @Input() apiKeys: IAiKeys;
  @Input() showError = false;
  @Output() onSaveKey = new EventEmitter<{type: string, key: string, appId: string | null}>();

  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
  }

  public get openaiApiKey(): FormControl {
    return this.form.get('openaiApiKey') as FormControl;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      openaiApiKey: [this.apiKeys.openaiApiKey, [Validators.required]]
    });
  }

  saveApiKey(): void {
    this.onSaveKey.emit({
      type: 'OPEN_AI',
      key: this.openaiApiKey.value,
      appId: null
    });
  }
}
