/**
 * Created by Alex Poh. on 20/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchoolAdminComponent } from './school-admin.component';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../shared/shared.module';
import { SchoolAdminDashboardComponent } from './school-admin-dashboard/school-admin-dashboard.component';
import { MatDividerModule } from '@angular/material/divider';
import { EditTeacherPageComponent } from './edit-teacher-page/edit-teacher-page.component';
import { EditCoursesComponent } from './shared/edit-courses/edit-courses.component';
import { EditInfoComponent } from './shared/edit-info/edit-info.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CreateTeacherPageComponent } from './create-teacher-page/create-teacher-page.component';
import { CreateCoursesComponent } from './shared/create-courses/create-courses.component';
import { SchoolAdminManageUsersComponent } from './school-admin-manage-users/school-admin-manage-users.component';
import { ManageUsersResolver } from './school-admin-manage-users/manage-users.resolver';
import { MatTableModule } from '@angular/material/table';
import { YearSettingsComponent } from './year-settings/year-settings.component';
import { YearSettingsResolver } from './year-settings/year-settings.resolver';
import { ShoolAdminUserProfileComponent } from './school-admin-manage-users/shool-admin-user-profile/shool-admin-user-profile.component';
import { SchoolAdminUserProfileService } from './school-admin-manage-users/shool-admin-user-profile/school-admin-user-profile.service';
import { ProfileDataService } from '../student/profile/profile-data.service';
import { SchoolAdminProfileComponent } from './school-admin-profile/school-admin-profile.component';
import { sharedPages } from '../shared-pages/shared-pages.routes';
import { GlossaryTermsResolver } from './glosary-of-terms/glossary-terms.resolver';
import { GlossaryOfTermsComponent } from './glosary-of-terms/glossary-of-terms.component';
import { MatSortModule } from '@angular/material/sort';
import { SharedPagesModule } from '../shared-pages/shared-pages.module';
import { TeacherSharedModule } from '../teacher/teacher-shared.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CreatePastoralCareUserComponent } from './create-pastoral-care-user/create-pastoral-care-user.component';
import { ProfileComponent } from '../student/profile/profile.component';
import { ProfileDataResolver } from '../student/profile/profile-data.resolver';
import { StudentProfileService } from '../student/profile/student-profile.service';
import { TeacherProfileComponent } from '../teacher/teacher-profile/teacher-profile.component';
import { CumulativeTestsComponent } from '../teacher/cumulative-tests/cumulative-tests.component';
import { CumulativeTestsResolver } from '../teacher/cumulative-tests/cumulative-tests.resolver';
import { SubjectInfoResolver } from '../teacher/cumulative-tests/subject-info.resolver';
import { TeacherSelfAssessmentTestsComponent } from '../teacher/teacher-self-assessment-tests/teacher-self-assessment-tests.component';
import { TeacherSelfAssessmentResolver } from '../teacher/teacher-self-assessment-tests/teacher-self-assessment.resolver';
import { QuestionsPageComponent } from '../teacher/questions-page/questions-page.component';
import { SaQuestionsPageResolver } from '../teacher/questions-page/sa-questions-page.resolver';
import { IncorrectlyAnsweredQuestionsResolver } from '../teacher/questions-page/incorrectly-answered-questions.resolver';
import { ReportPageComponent } from '../teacher/report-page/report-page.component';
import { ManagingApiKeysComponent } from './managing-api-keys/managing-api-keys.component';
import { ManagingApiKeysResolver } from './managing-api-keys/managing-api-keys.resolver';
import { ManagingApiKeysSettingsResolver } from './managing-api-keys/managing-api-keys-settings.resolver';
import { ManagingApiKeysService } from './managing-api-keys/managing-api-keys.service';
import { OpenAiSectionComponent } from './managing-api-keys/open-ai-section/open-ai-section.component';
import { MathpixSectionComponent } from './managing-api-keys/mathpix-section/mathpix-section.component';
import { CreditsErrorComponent } from './managing-api-keys/credits-error/credits-error.component';
import { AiSubjectsTableComponent } from './managing-api-keys/ai-subjects-table/ai-subjects-table.component';

const routes: Routes = [
  {
    path: '',
    component: SchoolAdminComponent,
    data: {
      breadcrumb: [
        {
          label: 'Home',
          url: ''
        }
      ]
    },
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: SchoolAdminDashboardComponent },
      ...sharedPages,
      {
        path: 'edit-teacher',
        component: EditTeacherPageComponent,
        data: {
          breadcrumb: {
            label: 'Teacher Name',
            url: ''
          }
        }
      },
      {
        path: 'create-teacher',
        component: CreateTeacherPageComponent,
        data: {
          breadcrumb: {
            label: 'Create New Teacher',
            url: ''
          }
        }
      },
      {
        path: 'create-pastoral-care-user',
        component: CreatePastoralCareUserComponent,
        data: {
          breadcrumb: {
            label: 'Create New Pastoral Care User',
            url: ''
          }
        }
      },
      {
        path: 'users',
        component: SchoolAdminManageUsersComponent,
        data: {
          breadcrumb: {
            label: 'Users',
            url: ''
          }
        },
        resolve: {
          users: ManageUsersResolver
        }
      },
      { path: 'users/:userId', component: ShoolAdminUserProfileComponent },
      {
        path: 'year-settings',
        component: YearSettingsComponent,
        data: {
          breadcrumb: {
            label: 'Year Settings',
            url: ''
          }
        },
        resolve: {
          ys: YearSettingsResolver
        }
      },
      { path: 'profile', component: SchoolAdminProfileComponent },
      {
        path: 'glossary',
        component: GlossaryOfTermsComponent,
        resolve: {
          terms: GlossaryTermsResolver
        },
        data: {
          breadcrumb: {
            label: 'Glossary of Terms',
            url: ''
          }
        }
      },
      {
        path: 'studentProfile/:studentId',
        component: ProfileComponent,
        resolve: {
          profileData: ProfileDataResolver
        }
      },
      {
        path: 'teacherProfile/:studentId',
        component: TeacherProfileComponent,
        resolve: {
          profileData: ProfileDataResolver
        }
      },
      {
        path: 'profile/:studentId',
        component: TeacherProfileComponent,
        resolve: {
          profileData: ProfileDataResolver
        }
      },
      {
        path: 'cumulative-tests/:subjectId/:curriculumId',
        component: CumulativeTestsComponent,
        data: {},
        resolve: {
          tests: CumulativeTestsResolver,
          subject: SubjectInfoResolver
        }
      },
      {
        path: 'self-assessment/:subjectId/:curriculumId',
        component: TeacherSelfAssessmentTestsComponent,
        data: {},
        resolve: {
          tests: TeacherSelfAssessmentResolver,
          subject: SubjectInfoResolver
        }
      },
      {
        path: 'sa-questions/:courseId/:saId',
        component: QuestionsPageComponent,
        data: {
          type: 'sa'
        },
        resolve: {
          tests: SaQuestionsPageResolver,
          incorrectlyAnsweredQuestions: IncorrectlyAnsweredQuestionsResolver
        }
      },
      {
        path: 'ct-report/:courseId/:ctId',
        component: ReportPageComponent,
        data: {
          type: 'ct'
        }
      },
      {
        path: 'sa-report/:courseId/:ctId',
        component: ReportPageComponent,
        data: {
          type: 'sa'
        }
      },
      {
        path: 'managing-api-keys',
        component: ManagingApiKeysComponent,
        resolve: {
          apiKeys: ManagingApiKeysResolver,
          apiKeysSettings: ManagingApiKeysSettingsResolver,
        }
      }
    ]
  }
];

@NgModule({
  declarations: [
    SchoolAdminComponent,
    SchoolAdminDashboardComponent,
    EditTeacherPageComponent,
    CreateTeacherPageComponent,
    CreateCoursesComponent,
    EditInfoComponent,
    EditCoursesComponent,
    SidebarComponent,
    SchoolAdminManageUsersComponent,
    YearSettingsComponent,
    GlossaryOfTermsComponent,
    ShoolAdminUserProfileComponent,
    CreatePastoralCareUserComponent,
    SchoolAdminProfileComponent,
    ManagingApiKeysComponent,
    OpenAiSectionComponent,
    MathpixSectionComponent,
    CreditsErrorComponent,
    AiSubjectsTableComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedModule,
    SharedPagesModule,
    TeacherSharedModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCardModule,
    MatDividerModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatMenuModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule
  ],
  providers: [
    ManageUsersResolver,
    SchoolAdminUserProfileService,
    ManagingApiKeysService,
    ProfileDataResolver,
    StudentProfileService,
    CumulativeTestsResolver,
    SubjectInfoResolver,
    TeacherSelfAssessmentResolver,
    ProfileDataService,
    YearSettingsResolver,
    GlossaryTermsResolver,
    ManagingApiKeysResolver,
    ManagingApiKeysSettingsResolver
  ]
})
export class SchoolAdminModule {}
