<!--Created by Maxim B. on 30/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="confirm-container" fxLayout="column" fxLayoutAlign="start start">
  <h2 class="mat-h2 m-b-0" *ngIf="title">{{title}}</h2>
  <span class="description mat-caption m-t-8" *ngIf="description">{{description}}</span>
  <div class="btns" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <curr-button size="big" [variant]="'text'" (btnClick)="submitAnswer(false)">{{data.cancelLabel || 'CANCEL'}}</curr-button>
    <curr-button size="big" [variant]="'contained'"
                 (btnClick)="submitAnswer(true)">{{data.confirmLabel || 'CONFIRM'}}</curr-button>
  </div>
</div>
