/*
 * Created by Dmytro Sav. on 4/17/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, Input } from '@angular/core';
import { getRestApiProvider, RestApiService, Paginated } from '../../api/rest-api.service';
import { ITask } from './task-list.interface';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { fadeAnimation } from '../../animations/fade.animation';
import { MatDialog } from '@angular/material/dialog';
import { TaskPopupComponent } from './task-popup/task-popup.component';
import * as moment from 'moment';

interface Tasks {
  [key: string]: ITask[];
}

@Component({
  selector: 'curr-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss'],
  providers: [getRestApiProvider<ITask>('tasks')],
  animations: [fadeAnimation],
})
export class TaskListComponent implements OnInit {
  
  @Input()
  showTitleSection = true;
  
  cancelChange = false;
  disableButton: boolean;
  cancelButtonStyle = { color: '#006878', 'text-decoration': 'underline' };
  tasks: Tasks;
  btnStyle = { width: '91px', padding: 0 };
  options = [];
  isError: boolean;
  disabled = true;
  isFirst: boolean;
  page = 1;
  showLoadMore = true;
  min = new Date();

  constructor(
    private taskService: RestApiService<ITask>,
    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  ngOnInit() {
    this.disableButton = true;
    this.min = new Date(this.min.getFullYear(), this.min.getMonth(), this.min.getDate());
    this.getAll();
  }

  getAll() {
    this.taskService.getAll().subscribe((res: any) => {
      if (res) {
        this.init(res);
        this.disabled = false;
      }
    });
  }

  init(input: Tasks) {
    this.tasks = input;
  }
  toggleDisableButton(title, date) {
    const taskTitle = Object.values(title.formGroup.value)[0].toString();
    const taskDate = date.selected;
    if (taskTitle === '' || taskDate === undefined) {
      this.disableButton = true;
    } else {
      this.disableButton = false;
    }
  }

  onCancel() {
    this.isFirst = false;
    this.cancelChange = true;
  }

  prevent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  deleteTask(task, tasks, index) {
    tasks = tasks.filter((value) => value.id !== task.id);
    tasks.length === 0 ? delete this.tasks[index] : (this.tasks[index] = tasks);
  }

  doneTask(task, tasks, index) {
    task.is_completed = true;
    this.updateTask(task);
    this.deleteTask(task, tasks, index);
  }

  clearTask(isAddNew = false) {
    if (Object.values(this.tasks).length === 0) {
      this.isFirst = true;
    } else {
      this.isFirst = false;
      this.tasks = this.sortObjectByKey(this.tasks);
      const firstDayTask = Object.values(this.tasks)[0];
      const firstTask = firstDayTask[0];

      Object.values(this.tasks).forEach((task) => {
        task.forEach((item) => {
          item.isEdit = false;
          item.isAddNew = false;
        });
      });
      firstTask.isAddNew = isAddNew;
    }
  }

  addNew() {
    const dialogRef = this.dialog.open(TaskPopupComponent, {
      width: '424px',
      data: {
        title: 'Add Task',
        isEdit: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.createNew(result, result.deadline);
        // @ts-ignore
        this.tasks = this.sortObjectByKey(this.tasks);
        this.clearTask();
      }
    });
  }

  editTask(dayTask) {
    const dialogRef = this.dialog.open(TaskPopupComponent, {
      width: '424px',
      data: {
        title: 'Edit Task',
        isEdit: true,
        dayTask,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const index = moment(result.deadline.slice(0, -4)).format('YYYY-M-DD');
        this.deleteTask(result, this.tasks[index], index);
        this.moveTask(result.deadline, result);
        this.updateTask(result);
      }
    });
  }

  hideError() {
    setTimeout(() => {
      this.isError = false;
    }, 1500);
  }

  convertDate(date: string) {
    return moment(date).format('YYYY-M-DD');
  }

  sortObjectByKey(object) {
    return Object.keys(object)
      .sort()
      .reduce((acc, key) => {
        acc[key] = object[key];
        return acc;
      }, {});
  }

  moveTask(newDate, newTask) {
    const convertIntoKey = this.convertDate(newDate);
    this.tasks[convertIntoKey] ? this.tasks[convertIntoKey].unshift(newTask) : (this.tasks[convertIntoKey] = [newTask]);
  }

  // editTask(title, task, newDate) {
  //   const date = newDate.selected;
  //   const name = Object.values(title.formGroup.value)[0].toString(); // title.customInput.nativeElement.value;
  //   if (!name) {
  //     this.isError = true;
  //   } else {
  //     task.name = name;
  //     task.isEdit = false;
  //
  //     if (date) {
  //       const index = this.convertDate(new Date(task.deadline));
  //       this.deleteTask(task, this.tasks[index], index);
  //
  //       task.deadline = this.toIsoString(new Date(date));
  //       this.moveTask(date, task);
  //     }
  //
  //     this.updateTask(task);
  //   }
  // }

  createNew(task: ITask, newDate: any) {
    this.taskService.add(task).subscribe(
      (res: any) => {
        task.id = res?.id;
        this.moveTask(newDate, task);
        this.clearTask();
      },
      (error) => {
        if (error?.error?.messages[0]) {
          this.isError = true;
          this.hideError();
          this.toastr.warning(error.error.messages[0]);
        }
      },
    );
  }

  updateTask(task: ITask) {
    this.taskService.update(task, task.id + '').subscribe((res) => {});
  }

  onScroll() {
    this.taskService
      .getAllPaginated(of(new Paginated(10, (++this.page - 1) * 10)))
      .pipe(
        map((res) => {
          const resKeys = Object.keys(res);
          const respose = {};
          resKeys.forEach((key: string) => {
            if (this.tasks[key]) {
              this.tasks[key] = [...this.tasks[key], ...res[key]];
            } else {
              respose[key] = res[key];
            }
          });
          return respose;
        }),
      )
      .subscribe((res: any) => {
        if (res && Object.keys(res).length) {
          this.tasks = { ...this.tasks, ...res };
        } else {
          this.showLoadMore = false;
        }
      });
  }
}
