/**
 * Created by Iryna Iak. on 18/10/21.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component } from '@angular/core';
import { ChooseYearPopupComponent } from '../choose-year-popup/choose-year-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'curr-choose-plan',
  templateUrl: './choose-plan.component.html',
  styleUrls: ['./choose-plan.component.scss']
})
export class ChoosePlanComponent {
  infoPlan = 'Test'
  constructor(public dialog: MatDialog) { }

  openChooseYearPopUp(): void {
    this.dialog.open(ChooseYearPopupComponent);
  }
}
