/**
 * Created by Maxim B. on 20/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit } from '@angular/core';
import { YearSettings } from './year-settings';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'curr-year-settings',
  templateUrl: './year-settings.component.html',
  styleUrls: ['./year-settings.component.scss']
})
export class YearSettingsComponent implements OnInit {
  ys: YearSettings;
  isNew = false;
  selectedTabIndex = 1;
  customTabs = [{ name: 'School year groups' }, { name: 'Semesters' }, { name: 'Teaching Contact time' }];
  path = [
    {
      label: 'Home',
      url: ''
    },
    {
      label: 'Year Settings',
      url: ''
    }
  ];

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.ys = this.route.snapshot.data.ys;
  }

  tabSelectedIndexChange($event: number) {
    this.selectedTabIndex = $event;
  }

  save() {}

  goToNextStep(val: string) {
    if (val === 'next') {
      this.selectedTabIndex++;
    }
    if (val === 'prev') {
      this.selectedTabIndex--;
    }
  }
}
