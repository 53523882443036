/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { restApiServiceCreator } from '../../shared/api/rest-api.service';
import { SelfAssessmentTestForTeacher } from './self-assessment-test-for-teacher';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class TeacherSelfAssessmentResolver implements Resolve<any> {
  constructor(private httpClient: ApplicationHttpClient) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    const selfAssessmentTestService = restApiServiceCreator<SelfAssessmentTestForTeacher>(
      'curriculumLearningObjectiveSATests/review'
    )(this.httpClient);
    const schoolYearCourseId = route.paramMap.get('subjectId');
    return selfAssessmentTestService.getAll({ schoolYearCourseId }).pipe(
      map((res: SelfAssessmentTestForTeacher[]) => {
        return {
          // subject: 'Biology', /**TODO use real values insted of hardcoded */
          // year: 'Year 9', /**TODO use real values insted of hardcoded */
          // form: 'Form 3', /**TODO use real values insted of hardcoded */
          // level: 'A-Level', /**TODO use real values insted of hardcoded */
          passed: this.transformTestsToCard(res.filter(item => item.is_passed)),
          notPassed: this.transformTestsToCard(res.filter(item => !item.is_passed))
        };
      })
    );
  }

  private transformTestsToCard(tests: SelfAssessmentTestForTeacher[]) {
    return tests.map(res => {
      return this.transformTestToCard(res);
    });
  }

  private transformTestToCard(test: SelfAssessmentTestForTeacher) {
    return {
      name: test.title,
      ...test,
      studentsPassed: test.students_passed,
      testType: test.is_passed ? 'passed' : 'notPassed'
    };
  }
}
