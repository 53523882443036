/**
 * Created by Alex Poh. on 22/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { EMPTY, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable()
export class ApiService {
  get<T>(url): Observable<T> {
    return EMPTY;
  }
  post<T>(item: T, url): Observable<T> {
    return EMPTY;
  }
}
