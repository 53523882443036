<div fxLayoutAlign="row" fxLayoutGap="8px">
  <div fxFlex="150px">
    <curr-dropdown-component-v2
      *ngIf="treeFilter?.root.children"
      [options]="getOptionsFromChildrenArray(treeFilter.root.children)"
      [selectedValue]="getOptionsFromChildrenArray(treeFilter.root.children)[0].value"
      (selectedValueChange)="firstLevelChanged($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="150px">
    <curr-dropdown-component-v2
      *ngIf="treeFilter?.root.children"
      [options]="getOptionsFromChildrenArray(secondLevelValue?.children)"
      [selectedValue]="getOptionsFromChildrenArray(secondLevelValue?.children)[0].value"
      (selectedValueChange)="secondLevelChanged($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="150px">
    <curr-dropdown-component-v2
      *ngIf="treeFilter?.root.children"
      [options]="getOptionsFromChildrenArray(thirdLevelValue?.children)"
      [selectedValue]="getOptionsFromChildrenArray(thirdLevelValue?.children)[0].value"
      (selectedValueChange)="thirdLevelChanged($event)"
    ></curr-dropdown-component-v2>
  </div>
</div>
