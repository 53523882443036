<!-- Created by Max Bon. on 17/08/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-8">
  <h2 class="mat-h2 m-b-0" fxFlex>
    {{inEditNoteMode ? 'Edit note' : 'Add note'}}
  </h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div class="name-container" fxLayout="column" fxLayoutAlign="center center">
  <curr-input class="full-width" [(value)]="comment" placeholder="Enter your comment"></curr-input>
  <div class="m-t-8 full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="highlight-color m-r-16" *ngFor="let c of colors;let i = index"
           [class.selected]="c.checked" (click)="setCheckedColor(i)"
           [style]="{'background-color': c.color}"></div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <curr-button variant="text" (btnClick)="dialogRef.close()">CANCEL</curr-button>
      <curr-button variant="contained" class="m-l-16" (btnClick)="saveNote()"
      [isDisabled]="!comment.length || !selectedColor.length">
        {{inEditNoteMode ? 'SAVE' : 'ADD NOTE'}}
      </curr-button>
    </div>

  </div>
</div>
