/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { NewQuestionService } from '../../new-question.service';

@Component({
  selector: 'curr-movable-item-panel',
  templateUrl: './movable-item-panel.component.html',
  styleUrls: ['./movable-item-panel.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class MovableItemPanelComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  isShowed = true;

  @Input()
  stepNumber: number;

  @Input()
  isIntroduction: boolean = false;

  @Input()
  itemIndexWithParents: number[];

  @Input()
  firstLevelAmount: number = 0;

  @Output()
  onShowed = new EventEmitter<boolean>();

  constructor(private newQuestionService: NewQuestionService) {}

  ngOnInit() {}

  toggleDropdown() {
    this.isShowed = !this.isShowed;
    this.onShowed.emit(this.isShowed);
  }

  closeDropDown() {
    this.isShowed = false;
  }

  removeItem(e) {
    e.stopPropagation();
    this.newQuestionService.removeItem(this.itemIndexWithParents);
  }

  stopPropagationIntroduction(e) {
    e.stopPropagation();
  }

  // changeIntroduction(e) {
  //   this.newQuestionService.changeIntroStatus({introStatus: e.checked, itemIndexs: this.itemIndexWithParents});
  // }

  get showRemoveBtn() {
    if (this.firstLevelAmount === 1 && this.stepNumber === 2 && this.itemIndexWithParents.length === 1) return false;
    return true;
  }
}
