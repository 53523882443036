<!-- Created by Alex Poh. on 31/03/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div fxHide.xs class="mat-h2" fxLayout="row" [fxLayoutAlign]="position">
  <ng-container *ngTemplateOutlet="content"> </ng-container>
</div>

<div fxHide.gt-xs class="mat-body-2" fxLayout="row" [fxLayoutAlign]="position">
  <ng-container *ngTemplateOutlet="content"> </ng-container>
</div>

<ng-template #content>
  <ul class="custom-bread-crumb">
    <span *ngFor="let item of breadcrumb; let i = index">
      <li>
        <a class="align-center" *ngIf="item?.url" [routerLink]="item?.url" [currMath]="item.label"></a>
        <span *ngIf="!item?.url" class="align-center align-breadcrumb length" ngClass.lt-sm="middle-text" [currMath]="item.label"></span>
        <div class="arrow-wrapper"><i class="arrow right" *ngIf="breadcrumb.length !== i + 1"></i></div>
      </li>
    </span>
  </ul>
</ng-template>
