<!-- Created by Alex Poh. on 06/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="full-width nav-container-sow">
  <div id="nav-sow">
    <div class="overline last-modified ">Last modified: {{ learningObjective.updated_at | date: 'dd MMM yyyy' }}</div>
    <curr-button size="big"  *ngIf="disabledStatus" color="primary" variant="outlined" (btnClick)="editSoW(false)">EDIT
    </curr-button>
    <curr-button size="big"  *ngIf="!disabledStatus" [isDisabled]="form.invalid" color="primary" variant="outlined"
      (btnClick)="editSoW(true)">SAVE
    </curr-button>
  </div>
</div>
<!-- <div class="m-b-32 full-width" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxFlex="100" class="full-width" fxLayout="column" fxLayoutAlign="start start">
    <div class="title mat-body-2 m-b-0">
      Unit name:
    </div>
    <div class="m-b-0 full-width" fxLayout="row" fxLayoutAlign="space-between start">
      <curr-input-disabled [disabled]="disabledStatus" [isBordered]="false" inputType="string"
        (click)="enableEditMode()" (valueChange)="topicNameChanged($event)" [formGroup]="unitTopicFormGroup"
        fxFlex="auto" [controlName]="'unit'"></curr-input-disabled>
      <curr-button size="big"  (btnClick)="addNewUnit()" fxFlex="125px" [isDisabled]="disabledStatus" class="m-t-8" [icon]="'plus'"
        [variant]="'text'"> ADD UNIT
      </curr-button>
    </div>
  </div>
</div> -->
<!-- <div class="m-b-32 full-width" fxLayout="row" fxLayoutAlign="space-between start">
  <div fxFlex="100" class="full-width" fxLayout="column" fxLayoutAlign="start start">
    <div class="title mat-body-2 m-b-0">
      Topic name:
    </div>
    <div class="m-b-0 full-width" fxLayout="row" fxLayoutAlign="space-between center">
      <curr-input-disabled [disabled]="disabledStatus" [isBordered]="false" inputType="string"
        (click)="enableEditMode()" (valueChange)="topicNameChanged($event)" [formGroup]="unitTopicFormGroup"
        fxFlex="auto" [controlName]="'topic'"></curr-input-disabled>
      <curr-button size="big"  (btnClick)="addNewTopic()" fxFlex="125px" [isDisabled]="disabledStatus" class="m-t-8" [icon]="'plus'"
        [variant]="'text'">ADD TOPIC
      </curr-button>
    </div>
  </div>
</div> -->
<div class="m-b-32 full-width" fxLayout="row" fxLayoutAlign="space-between start">
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start">
    <div class="m-b-0 full-width">
      <div class="title mat-body-2 m-b-0">
        Topic name:
      </div>
<!--      <curr-input-disabled [disabled]="disabledStatus" [isBordered]="false" (click)="enableEditMode()"-->
<!--        inputType="string" (valueChange)="topicNameChanged($event)" [formGroup]="form" [controlName]="'title'">-->
<!--      </curr-input-disabled>-->
      <span *ngIf="disabledStatus" [currMath]="form.controls.title.value"></span>
      <curr-tiny-editor *ngIf="!disabledStatus" (valueChange)="topicNameChanged($event)" (onFocusOut)="topicNameChanged($event.event.target.innerHTML)" (click)="enableEditMode()" [options]="{height: 250, statusbar: false}" [value]="form.controls['title'].value" [formGroup]="form" [controlName]="'title'"></curr-tiny-editor>
    </div>
    <!-- <div class="m-b-0 m-t-24 flex">
      <div class="margin-r">
        <div class="m-b-0">
          <curr-dropdown [placeholder]="'Please match to parent topic'" [options]="parentTopicOptions" [selectedValue]="learningObjective.parent_learning_objective_id"
                         [formGroup]="form" controlName="parent_learning_objective_id" fxFlex="336px"></curr-dropdown>
        </div>
      </div>
      <div>
        <div class="m-b-0" *ngIf="masterTopicOptions?.length">
          <curr-dropdown [placeholder]="'Please match to master topic'" [options]="masterTopicOptions" [selectedValue]="learningObjective.master_learning_objective_id"
                         [formGroup]="form" controlName="master_learning_objective_id" fxFlex="336px"></curr-dropdown>
        </div>
      </div>
    </div> -->

    </div>
</div>
<!-- <div *ngIf="learningObjective.sequence_num || !disabledStatus" class="m-b-32">
  <div class="title mat-body-2 m-b-0">
    Syllabus ref:
  </div>
  <div class="m-b-0 mat-body-1" fxLayout="row" fxLayoutAlign="start center">
    <span class="m-r-4 sequence_num">{{secNumPrefix}}</span>
    <curr-input-disabled [disabled]="disabledStatus" [isBordered]="false" inputType="string" [formGroup]="form"
      [controlName]="'sequence_num'" fxFlex></curr-input-disabled>
  </div>
</div> -->
<div *ngIf="learningObjective.duration || !disabledStatus" class="m-b-32">
  <div class="title mat-body-2 m-b-0">
    Duration (min):
  </div>
  <div class="m-b-0 mat-body-1">
    <curr-input-disabled [disabled]="disabledStatus" [isBordered]="false" inputType="string" [formGroup]="form"
      [controlName]="'duration'"></curr-input-disabled>
  </div>
</div>
<ng-container *ngFor="let field of getCustomFieldsToDisplay(); let i = index">
  <div class="m-b-32">
    <!--TODO: return this functionality after api is implemented. -->
    <!--<ng-container *ngIf="field.type === 'number'">-->
    <!--<div class="number-field" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">-->
    <!--<div class="title mat-body-2 m-b-0">{{ field.name }}:</div>-->
    <!--<curr-input-disabled-->
    <!--[disabled]="disabledStatus"-->
    <!--inputType="number"-->
    <!--[formGroup]="form"-->
    <!--fxFlex="48px"-->
    <!--[controlName]="removeSpacesFromStr(field.name)"-->
    <!--&gt;</curr-input-disabled>-->
    <!--<div class="mat-caption">{{ field.suffix }}</div>-->
    <!--</div>-->
    <!--</ng-container>-->
    <!--<ng-container *ngIf="field?.type === 'text' || true"> -->
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <div class="title mat-body-2 m-b-0">{{ field.key }}:</div>
      <div fxLayout="row" fxLayoutAlign="space-between center" class="delete-column-container">
        <curr-tiny-editor-disabled fxFlex="100"
          [options]="{ height: 180, placeholder: '', menubar: 'file edit view  insert format table help' }"
          [disabled]="disabledStatus" [topic]="learningObjective" (valueChange)="saveValueToCustomField($event, i)"
          [formGroup]="form" [controlName]="field.key" (onFocusOut)="saveValueToCustomFieldByFocusOut($event, i)"></curr-tiny-editor-disabled>

        <button mat-icon-button *ngIf="!disabledStatus" (click)="deleteCustomField(i)">
          <mat-icon [svgIcon]="'trash'"></mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-container>
<div class="m-b-8" *ngIf="!disabledStatus" fxLayout="column" fxLayoutAlign="start stretch">
  <div class="m-b-8" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="isVisibleCreateField">
    <div fxFlex="70">
      <curr-input errorMessage="Title is required" [isRequired]="true" placeholder="Enter field title"
        [(value)]="newCustomField.key" class="no-hint-input">
      </curr-input>
    </div>

    <curr-button size="big"  (btnClick)="saveCustomField()" [isDisabled]="!newCustomField.key" [variant]="'contained'">SAVE
    </curr-button>
    <curr-button size="big"  (btnClick)="cancelCustomField()" variant="text">CANCEL</curr-button>
  </div>

</div>
<div class="m-b-8" fxLayout="row" fxLayoutAlign="space-between center">
  <curr-button size="big"  *ngIf="!isVisibleCreateField" [icon]="'plus'" (btnClick)="addCustomField()" [variant]="'contained'">ADD
    CUSTOM FIELD
  </curr-button>
</div>
