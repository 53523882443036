/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IMovebleItem, directionType } from './movable-sections.interface';
import { Question } from 'src/app/student/test.model';
import { FormGroup } from '@angular/forms';
import { SubjectTestSelectTopic } from 'src/app/teacher/questions-page/questions-page.interface';
import { NewQuestionService } from '../new-question.service';

@Component({
  selector: 'curr-movable-sections',
  templateUrl: './movable-sections.component.html',
  styleUrls: ['./movable-sections.component.scss'],
})
export class MovableSectionsComponent implements OnInit {
  
  @Input()
  questions: Question[];

  @Input()
  form: FormGroup;

  @Input()
  stepNumber: number;

  @Input()
  topics: SubjectTestSelectTopic[] = [];

  @Input()
  originalQuestionContent: string;

  @Input()
  originalMarkSchemeContent: string;

  @Input()
  dialogType: 'manual' | 'ai';

  @Input()
  dialogMode: 'create' | 'edit';

  @Output()
  onAddNewQuestion = new EventEmitter<number[]>();

  isRecognizedTextShow = false;

  get isShowAIBtn(): boolean {
    return this.dialogType === 'ai'
    && ((this.stepNumber == 0 && this.originalQuestionContent && this.originalQuestionContent !== '')
    || (this.stepNumber == 1 && this.originalMarkSchemeContent && this.originalMarkSchemeContent !== '')
    || (this.stepNumber == 2 && ((this.originalQuestionContent && this.originalQuestionContent !== '')
    || (this.originalMarkSchemeContent && this.originalMarkSchemeContent !== ''))))
  }

  get isCreateMode(): boolean {
    return this.dialogMode === 'create';
  }

  constructor(private newQuestionService: NewQuestionService) {}

  ngOnInit() {
  }

  toggleRecognizedTextDialog() {
    this.isRecognizedTextShow = !this.isRecognizedTextShow;
  }

  onAddItem(itemIndexs: number[]) {
    this.onAddNewQuestion.emit(itemIndexs);
  }

  onChangeItemPosition(movedItem: IMovebleItem) {
    if (movedItem.direction === 'right') this.moveRight(movedItem);
    if (movedItem.direction === 'left') this.moveLeft(movedItem);
    if (movedItem.direction === 'top' || movedItem.direction === 'bottom') this.moveTopBottom(movedItem);
  }

  private moveLeft(movedItem: IMovebleItem) {
    const movebleIndexs = movedItem.itemIndexs;
    
    if (movebleIndexs.length === 2 && this.isCreateMode) {
      const moveItem = this.questions[movebleIndexs[0]].children[movebleIndexs[1]];
      this.questions[movebleIndexs[0]].children.splice(movebleIndexs[1], 1);
      this.questions.push(moveItem);

      if (this.questions[movebleIndexs[0]].children.length === 0) {
        this.newQuestionService.changeIntroStatus({introStatus: false, itemIndexs: [movebleIndexs[0]]});
      }
    }

    if (movebleIndexs.length === 3) {
      const moveItem = this.questions[movebleIndexs[0]].children[movebleIndexs[1]].children[movebleIndexs[2]];
      this.questions[movebleIndexs[0]].children[movebleIndexs[1]].children.splice(movebleIndexs[2], 1);
      this.questions[movebleIndexs[0]].children.push(moveItem);

      if (this.questions[movebleIndexs[0]].children[movebleIndexs[1]].children.length === 0) {
        this.newQuestionService.changeIntroStatus({introStatus: false, itemIndexs: [movebleIndexs[0], movebleIndexs[1]]});
      }
    }
  }

  private moveRight(movedItem: IMovebleItem) {
    const movebleIndexs = movedItem.itemIndexs;

    if (movebleIndexs.length === 1) {
      const moveItem = this.questions[movebleIndexs[0]];
      this.questions.splice(movebleIndexs[0], 1);

      const pasteItem = this.questions[movebleIndexs[0] - 1].children;
      pasteItem.push(moveItem);
      this.newQuestionService.changeIntroStatus({introStatus: true, itemIndexs: [movebleIndexs[0] - 1]});

      for (let i = 0; i < pasteItem.length; i++) {
        for (let j = 0; j < pasteItem[i].children.length; j++) {
          for (let k = 0; k < pasteItem[i].children[j].children.length; k++) {
            pasteItem[i].children.push(pasteItem[i].children[j].children[k]);
          }

          pasteItem[i].children[j].children = [];
        }
      }
    }

    if (movebleIndexs.length === 2) {
      const moveItem = this.questions[movebleIndexs[0]].children[movebleIndexs[1]];
      this.questions[movebleIndexs[0]].children.splice(movebleIndexs[1], 1);

      const pasteItem = this.questions[movebleIndexs[0]].children[movebleIndexs[1] - 1].children;
      pasteItem.push(moveItem);
      this.newQuestionService.changeIntroStatus({introStatus: true, itemIndexs: [movebleIndexs[0], movebleIndexs[1] - 1]});

      for (let i = 0; i < pasteItem.length; i++) {
          for (let k = 0; k < pasteItem[i].children.length; k++) {
            pasteItem[i].children.push(pasteItem[i].children[k]);
          }

          pasteItem[i].children = [];
      }
    }
  }

  private moveTopBottom(movedItem: IMovebleItem) {
    const movebleIndexs = movedItem.itemIndexs;

    if (movebleIndexs.length === 1) {
      this.questions = this.arrayMoveTopBottom(
        this.questions,
        movebleIndexs[0],
        movedItem.direction === 'top' ? (movebleIndexs[0] - 1) : (movebleIndexs[0] + 1));
    }

    if (movebleIndexs.length === 2) {
      this.questions[movebleIndexs[0]].children = this.arrayMoveTopBottom(
        this.questions[movebleIndexs[0]].children,
        movebleIndexs[1],
        movedItem.direction === 'top' ? (movebleIndexs[1] - 1) : (movebleIndexs[1] + 1));
    }

    if (movebleIndexs.length === 3) {
      this.questions[movebleIndexs[0]].children[movebleIndexs[1]].children = this.arrayMoveTopBottom(
        this.questions[movebleIndexs[0]].children[movebleIndexs[1]].children,
        movebleIndexs[2],
        movedItem.direction === 'top' ? (movebleIndexs[2] - 1) : (movebleIndexs[2] + 1));
    }
  }

  private arrayMoveTopBottom(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };
}
