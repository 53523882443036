<button
  mat-button
  [ngClass]="[variant, size, color]"
  class="base dragged "
  cdkDrag
  [color]="color"
  [style.color]="color"
  (click)="onClick($event)"
  [cdkDragDisabled]="!isDragged"
  [disabled]="isDisabled"
  [ngStyle]="style"
  [class.isHighlighted]="isHighlighted"
  [class.isUpperCase]="isUpperCase"
  [attr.matStepperNext]="isMatNext"
  [attr.matStepperPrevious]="isMatPrev"
>
  <mat-icon *ngIf="icon" [svgIcon]="icon" class="button-icon" [class]="icon"></mat-icon>
  <ng-content></ng-content>
</button>
