/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'curr-expansion-panel-topic',
  templateUrl: './expansion-panel-topic.component.html',
  styleUrls: ['./expansion-panel-topic.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class ExpansionPanelTopicComponent implements OnInit {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() isDisabled? = false;
  @Input() isShowed = true;
  @Input() status: 'approved' | 'pending';
  isError = false;
  inEditMode = false;

  constructor() {}

  ngOnInit() {}

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }
}
