import { Expose, Type } from 'class-transformer';
import { ChartFilter } from '../../../shared-pages/course-filters/course-filters';

export class Subject {
  id: number;
  value: string;
}

export class Level {
  id: number;
  value: string;
}

export class SubjectToLevel {
  subjects: number;
  levels: number;
}

export class Cours {
  id: number;
  value: string;
}

export class LevelToCourse {
  levels: number;
  courses: number;
}

export class Filter {
  subjects: Subject[];
  levels: Level[];
  subjectToLevel: SubjectToLevel[];
  courses: Cours[];
  levelToCourse: LevelToCourse[];
  answers: string[];
}

export class SubjectData {
  id: number;
  value: string;
}

export class CurrentStudyYear {
  id: number;
  value: string;
}

export class YearData {
  ctYear: number;
  ctMonth: number;
  ctDay: number;
  avgGrade: string;
  avgPredictedGrade: string;

  @Expose()
  get date() {
    return new Date(this.ctYear, this.ctMonth, this.ctDay || 0, 0, 0, 0, 0).toString();
  }

  @Expose()
  get value() {
    return parseInt(this.avgGrade, 10);
  }
}

export class Grade {
  averageValue: number;
  maxValue: number;
  minValue: number;
}

export class Grades {
  [key: string]: Grade;
}

export class Period {
  index: number;
  periodName: string;
}

export class ChartData {
  filter: ChartFilter;
  currentSubject: SubjectData;
  currentStudyYear: SubjectData;
  averageGrade: number;
  averageMark: string;
  @Type(() => YearData)
  currentYear: YearData[];
  currentYearAverageGrade: number;
  @Type(() => YearData)
  lastYear: YearData[];
  lastYearAverageGrade?: number;
  @Type(() => YearData)
  yearBeforeLast: YearData[];
  yearBeforeLastAverageGrade?: number;
  grades: Grades;
  periods: Period[];
  predictedAverageMark: string;

  get currentData() {
    return {
      name: 'Current',
      averageGrade: this.currentYearAverageGrade,
      yearData: this.currentYear
    };
  }

  get lastData() {
    return {
      name: 'Last',
      averageGrade: this.lastYearAverageGrade,
      yearData: this.lastYear
    };
  }

  get beforeLastData() {
    return {
      name: 'Before last',
      averageGrade: this.yearBeforeLastAverageGrade,
      yearData: this.yearBeforeLast
    };
  }
}

// not presented in api
export interface DataGroup {
  name: string;
  averageGrade: number;
  yearData: YearData[];
}

export class TeacherChartData extends ChartData {
  @Type(() => Range)
  range: Range[];

  getData(rangeIndex: number) {
    const range = this.range[rangeIndex];
    if (range) {
      return {
        name: range.rangeName,
        averageGrade: range.avrPeriodGradeNumb,
        yearData: range.periods
      };
    }
  }
}

export class Range {
  rangeName: string;
  avrPeriodGradeLetter: string;
  avrPeriodGradeNumb: number;
  @Type(() => YearData)
  periods: YearData[];
  grades: Grades;
}
