<!-- Created by Alex Poh. on 26/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-dashboard-chart">
  <div class="header-container main-header m-b-0" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-16 m-l-16">Results</h2>
  </div>

  <mat-divider class="m-b-16 full-width"></mat-divider>

  <div class="chart-card">
    <div class="chart-card-filters">
      <curr-inline-course-filter
        class="inline-course-filter"
        (filterChanged)="filterChanged($event)">
      </curr-inline-course-filter>
    </div>

    <div class="loader-container" *ngIf="isLoading">
      <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
    </div>

    <div class="performance-histogram-wrapper">
      <curr-school-performance-histogram
        *ngIf="isChartVisible && !isLoading"
        class="school-performance-histogram"
        [width]="chartWidth"
        [chartData$]="displayData$">
      </curr-school-performance-histogram>
  
      <div class="no-data-image" *ngIf="!isChartVisible && !isLoading">
        <mat-icon [inline]="true" class="image" [svgIcon]="noDataChartIcone"></mat-icon>
      </div>
    </div>
  </div>
</div>

  <!-- <div fxLayout="row" fxLayout.xs="column" fxFlexAlign="space-between">
    <div fxFlex class="m-b-16 mat-h2"></div>
    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutGap="32px" class="m-t-8">
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.MONTH ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.MONTH)">
        MONTH
      </div>
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.SEMESTER ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.SEMESTER)">
        SEMESTER
      </div>
      <div class="button" [ngClass]="currentPeriod === ChartPeriod.YEAR ? 'button-selected' : ''"
        (click)="refreshGraphWithPeriod(ChartPeriod.YEAR)">
        YEAR
      </div>
    </div>
  </div> -->
  <!-- <div class="chart-card"  fxFlex fxLayout="column" [@fade]> -->
    <!-- <div fxLayout="row wrap" fxLayoutGap="10px" fxFlexAlign="center" fxFlexAlign.xs="start">
        <div fxFlex="0 1" fxLayoutGap="10px" fxLayout="row">
          <div class="mat-h3 m-b-0">
            Avg.Grade: {{ (generalGraphData?.range)[currentPeriodIndex].avrPeriodGradeLetter || '-' }} &nbsp;
          </div>
        </div>
        <div fxLayout="row" fxFlex="0 1">
          <div class="mat-h3 m-r-8 m-b-0">{{'common.benchmark' | translate}}: D&nbsp;</div>
          <mat-icon class="icon-smaller cursor-pointer" [matTooltip]="infoIconText$ | async" svgIcon="exclamation-mark"
            [inline]="true"></mat-icon>
        </div>
        <div fxLayout="row" fxFlex="0 1"></div>
      </div> -->

    <!-- <div fxFlex="0 0 auto" fxFlex.xs="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="8px"> -->
    <!-- <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="space-between stretch" fxFlex.xs="100%" fxLayout.xs="column" fxLayoutGap="15px" class="mat-body-2">
      <div class="title">RESULTS</div>
      <curr-inline-course-filter *ngIf="areFiltersPresented" (filterChanged)="filterChanged($event)">
      </curr-inline-course-filter>
    </div> -->
    <!-- </div> -->

    <!-- <div fxLayout="row space-between" fxLayout.xs="column start" class="m-t-32 m-b-32 m-l-8">
      <div *ngIf="dashboardData && dashboardData.length > 0" fxFlex fxFlexAlign="center" class="mat-h2">
        <mat-icon (click)="prevPeriod()" svgIcon="less-than-symbol" class="icon cursor-pointer date-navigation-arrow">
        </mat-icon>
        {{ currentGraphData?.name }}

        <mat-icon
          (click)="nextPeriod()"
          svgIcon="greater-than-symbol"
          class="icon cursor-pointer date-navigation-arrow"
        ></mat-icon>
      </div> -->
    <!-- <div fxFlex fxLayoutAlign="end">
        <curr-button fxFlex="195px" variant="outlined">VIEW ALL STATISTICS</curr-button>
      </div>
    </div> -->
    <!-- <div class="performance-histogram-wrapper">
      <curr-school-performance-histogram
        *ngIf="isChartVisible"
        [width]="856" [chartData$]="displayData$" fxFlex fxLayout="column">
      </curr-school-performance-histogram>

      <div class="curr-dashboard-chart" *ngIf="!isChartVisible && !isLoading" [@fade]>
        <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="16px"> -->
        <!-- <mat-icon [inline]="true" class="image" [svgIcon]="noDataChartIcone" [@fade]></mat-icon> -->
        <!-- <h5>No Data Yet</h5>
          <button mat-button class="benchmark-button" fxLayoutAlign="center center">
            Start benchmark assessment
          </button> -->
        <!-- </div> -->
      <!-- </div>
    </div>
  </div> -->

