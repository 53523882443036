<div class="container">
  <div class="profile" fxFlex.gt-sm="30">
    <curr-profile-card
        fxFlex.gt-xs="432px"
        (updateUserCard)="updateUserProfile($event)"
        [profileData]="profileCardData">
    </curr-profile-card>
  </div>
</div>

