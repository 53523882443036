/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'curr-teacher-tabs',
  templateUrl: './teacher-tabs.component.html',
  styleUrls: ['./teacher-tabs.component.scss']
})
export class TeacherTabsComponent implements OnInit {
  @Input() value: number;
  @Input() tabLabels: string[];
  @Output() valueChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}
}
