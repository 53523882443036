export enum MediaType {
  video = 'VIDEO',
  link = 'ARTICLE',
  image = 'IMAGE',
  document = 'DOC',
  notes = 'NOTES'
}

export interface MediaItem {
  id?: string;
  title: string;
  estimated_time: number;
  description: string;
  resource: string;
  resource_type: MediaType;
  previewUrl?: string;
  curriculum_lobj_id?: string;
  file?: any;
  resourceData?: string;
}
