<div class="curr-table-pagination">
  <div class="range-label">
    {{getRangeLabel(currentPageIndex, pageSize, totalResults)}}
  </div>
  <div class="paginator-buttons">
    <button mat-icon-button (click)="previousPage()" [disabled]="currentPageIndex === 0">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="page-numbers">
      <span *ngFor="let page of getPageNumbers(); let i = index" [ngClass]="{ 'current-page': currentPageIndex + 1 === page }">
        <button *ngIf="page !== '...'" mat-icon-button (click)="setPage(page)">
          {{ page }}
        </button>
        <span class="page-dots" *ngIf="page === '...'">...</span>
      </span>
    </div>
    <button mat-icon-button (click)="nextPage()" [disabled]="currentPageIndex + 1 === getTotalPages()">
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>