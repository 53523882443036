<!--
 Created by Daniil Vat. on 18/2/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<div class="wrapper">
  <div class="panel" [ngClass]="{ open: isShowed, error: isError }" (clickOutside)="closeDropDown()">
    <div class="custom-select" [class.disabled]="isDisabled" (click)="toggleDropdown()" [attr.data-titleName]="title">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text">
          <div class="text" [class.disabled]="isDisabled">
            <div header class="mat-h2 full-width paper-title" [currMath]="title"></div>
          </div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="arrow-wrapper m-r-24 m-l-24"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="divider"></div>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
