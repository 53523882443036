/**
 * Created by Alex Poh. on 18/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HasPermissionsDirective } from './has-access-directives/has-permissions.directive';
import { HasRolesDirective } from './has-access-directives/has-roles.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HeaderComponent } from './components/header/header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { FooterComponent } from './components/footer/footer.component';
import { SchoolPerformanceHistogramComponent } from './graphs/school-performance-histogram/school-performance-histogram.component';
import { MatButtonModule } from '@angular/material/button';
import { DonutChartComponent } from './graphs/donut-chart/donut-chart.component';
import { DrawingBoardComponent } from './components/drawing-board/drawing-board.component';
import { ButtonComponent } from './components/button/button.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { TinyEditorComponent } from './components/tiny-editor/tiny-editor.component';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { MatCardModule } from '@angular/material/card';
import { CumulativeTestCreateComponent } from './components/cumulative-test-create/cumulative-test-create.component';
import { CumulativeTestCardComponent } from './components/cumulative-test-card/cumulative-test-card.component';
import { InputComponent } from './components/input/input.component';
import { MatInputModule } from '@angular/material/input';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { GradeGuidelineComponent } from './components/grade-guideline/grade-guideline.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { PeriodGuidelineComponent } from './components/period-guideline/period-guideline.component';
import { YearsPickerComponent } from './components/years-picker/years-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PickYearDialogComponent } from './components/years-picker/pick-year-dialog/pick-year-dialog.component';
import { CreatePeriodDialogComponent } from './components/period-guideline/create-period-dialog/create-period-dialog.component';
import { DropdownContentComponent } from './components/dropdown-content/dropdown-content.component';
import { PaperInfoItemComponent } from './components/paper-info-item/paper-info-item.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { CustomerPlateComponent } from './components/customer-plate/customer-plate.component';
import { UserRootComponent } from './components/user-root/user-root.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DragDropDirective } from './directives/drop-upload.directive';
import { WebcamModule } from 'ngx-webcam';
import { MediaItemComponent } from './components/media-item/media-item.component';
import { ImageViewPopupDialogComponent } from './components/media-item/image-view-popup-dialog/image-view-popup-dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { CumulativeTestBlockComponent } from './components/cumulative-test-block/cumulative-test-block.component';
import { TextareaDisabledComponent } from './components/textarea-disabled/textarea-disabled.component';

import { InputDisabledComponent } from './components/input-disabled/input-disabled.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RedLabelComponent } from './components/red-label/red-label.component';
import { CameraComponent } from './components/camera/camera.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CourseCardComponent } from './components/course-card/course-card.component';
import { TaskListComponent } from './components/task-list/task-list.component';

import { TableComponent } from './components/table/table.component';
import { TablePaginationComponent } from './components/table-pagination/table-pagination.component';
import { MatTableModule } from '@angular/material/table';
import { UniversalCardComponent } from './components/universal-card/universal-card.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime-ex';
import { GettingStartedTasksComponent } from './components/getting-started-tasks/getting-started-tasks.component';
import { MatSortModule } from '@angular/material/sort';
import { CreateSubjectPopupComponent } from './components/create-subject-popup/create-subject-popup.component';
import { TableConstructorComponent } from './components/user-statistic-table/table-constructor/table-constructor.component';
import { NotificationCardComponent } from './components/notifications-bar/notification-card/notification-card.component';
import { NotificationsBarComponent } from './components/notifications-bar/notifications-bar.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPaginationModule, PaginatePipe } from 'ngx-pagination';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConcernWidgetComponent } from './components/user-statistic-table/concern-widget/concern-widget.component';
import { OrdinalPipe } from './components/user-statistic-table/ordinal.pipe';
import { UserStatisticTableComponent } from './components/user-statistic-table/user-statistic-table.component';
import { TeachersStatisticTableComponent } from './components/teachers-statistic-table/teachers-statistic-table.component';
import { StudentsStatisticTableComponent } from './components/students-statistic-table/students-statistic-table.component';
import { AnswerComponent } from './components/answer/answer.component';
import { LoaderWithTextComponent } from './components/loader-with-text/loader-with-text.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { QuestionComponent } from './components/question/question.component';
import { DialogRecordingComponent } from './components/recording-demo/dialog-recording/dialog-recording.component';
import { AnswersIncorrectComponent } from './components/answers-incorrect/answers-incorrect.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IgxToastModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { MatChipsModule } from '@angular/material/chips';
import { LoaderComponent } from './components/loader/loader.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ResultsTableComponent } from './components/results-table/results-table.component';
import { ProfileCardComponent } from './components/profile-card/profile-card.component';
import { AuthInputComponent } from 'src/app/auth/shared/auth-input/auth-input.component';
import { QuestionCardComponent } from './components/question-card/question-card.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { YsStepOneComponent } from './components/year-settings/ys-step-one/ys-step-one.component';
import { YsStepTwoComponent } from './components/year-settings/ys-step-two/ys-step-two.component';
import { YsStepThreeComponent } from './components/year-settings/ys-step-three/ys-step-three.component';
import { MatRadioModule } from '@angular/material/radio';
import { LinkPreviewService } from './components/media-item/link-preview.service';
import { SchedulerComponent } from './components/scheduler/scheduler.component';
import { MobileMenuComponent } from './components/mobile-menu/mobile-menu.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { InitialsAvatarComponent } from './components/initials-avatar/initials-avatar.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { StudentPerformanceChartComponent } from './components/student-performance-chart/student-performance-chart.component';
import { EditQuestionBlockComponent } from './components/edit-question-block/edit-question-block.component';
import { StudentCumulativeTestCardComponent } from './components/student-cumulative-test-card/student-cumulative-test-card.component';
import { TaskListPresentDatePipe } from './components/task-list/task-list-present-date.pipe';
import { TaskPopupComponent } from './components/task-list/task-popup/task-popup.component';
import { QuestionDatabasePopupComponent } from './components/question-database-popup/question-database-popup.component';
import { CreateCurriculumPopupComponent } from './components/create-curriculum-popup/create-curriculum-popup.component';
import { DropdownComponentV2Component } from './components/dropdown/dropdown-component-v2/dropdown-component-v2.component';
import { TinyEditorDisabledComponent } from './components/tiny-editor-disabled/tiny-editor-disabled.component';
import { StudentCourseCardComponent } from './components/student-course-card/student-course-card.component';
import { StudentLessonCardComponent } from './components/student-lesson-card/student-lesson-card.component';
import { ChartFilterComponent } from './components/dashboard-chart/chart-filter/chart-filter.component';
import { MathModule } from './math-ml/math.module';
import { StudentNotesResourcesComponent } from './components/student-notes-resources/student-notes-resources.component';
import { StudentAddCommentToNotePopupComponent } from './components/student-add-comment-to-note-popup/student-add-comment-to-note-popup.component';
import { ResourceStudentNotesService } from './components/student-notes-resources/resource-student-notes.service';
import { StudentCreateNoteCustomContextMenuComponent } from './components/student-create-note-custom-context-menu/student-create-note-custom-context-menu.component';
import { StudentNotesImagePreviewComponent } from './components/student-notes-image-preview/student-notes-image-preview.component';
import { StudentNoteSidebarViewComponent } from './components/student-note-sidebar-view/student-note-sidebar-view.component';
import { InlineCourseFilterComponent } from '../shared-pages/course-filters/inline-course-filter/inline-course-filter.component';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSelectModule } from '@angular/material/select';
import { QuestionAnswerVariantComponent } from './components/edit-question-block/question-answer-variant/question-answer-variant.component';
import { QuestionTypeButtonDropdownComponent } from './components/edit-question-block/question-type-button-dropdown/question-type-button-dropdown.component';
import { QuestionFormAnswerBlockComponent } from './components/edit-question-block/question-form-answer-block/question-form-answer-block.component';
import { MultistageQuestionFormBlockComponent } from './components/edit-question-block/multistage-question-form-block/multistage-question-form-block.component';
import { QuestionFormQuestionBlockComponent } from './components/edit-question-block/question-form-question-block/question-form-question-block.component';
import { MustistageSubquestionBlockComponent } from './components/edit-question-block/multistage-question-form-block/mustistage-subquestion-block/mustistage-subquestion-block.component';
import { QuestionPaginationComponent } from './components/question-pagination/question-pagination.component';
import { StudentDashboardChartComponent } from './components/student-dashboard-chart/student-dashboard-chart.component';
import { StudentGradesStatisticComponent } from './graphs/student-grades-statistic/student-grades-statistic.component';
import { UpcomingLessonsVideosComponent } from './components/upcoming-lessons-videos/upcoming-lessons-videos.component';
import { UpcommingVideosViewPopupComponent } from './components/upcoming-lessons-videos/upcomming-videos-view-popup/upcomming-videos-view-popup.component';
import { CourseSeparateFilterComponent } from '../shared-pages/course-filters/course-separate-filter/course-separate-filter.component';
import { ConcernWidgetRowComponent } from './components/user-statistic-table/concern-widget-row/concern-widget-row.component';
import { PagiForLoadingDataComponent } from './components/pagi-for-loading-data/pagi-for-loading-data.component';
import { SchoolAdminChartComponent } from './components/school-admin-chart/school-admin-chart.component';
import { OffsetTopDirective } from './directives/offset-top.directive';
import { ScrollableDirective } from './directives/scrollable.directive';
import { SelectCoursesFiltersComponent } from './components/select-courses-filters/select-courses-filters.component';
import { CustomScrollDirective } from './directives/custom-scroll.directive';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { CourseFiltersComponent } from '../shared-pages/course-filters/course-filters.component';
import { CouRightPipe } from './pipes/cou-right.pipe';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { ChipInputAutocompleteComponent } from './components/chip-input-autocomplete/chip-input-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SettingsBarComponent } from './components/settings-bar/settings-bar.component';
import { ChooseYearPopupComponent } from './components/choose-year-popup/choose-year-popup.component';
import { ChooseSubjectComponent } from './components/choose-subject/choose-subject.component';
import { ChoosePlanComponent } from './components/choose-plan/choose-plan.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { MatStepperModule } from '@angular/material/stepper';
import { GaugeModule } from 'angular-gauge';
import { LogoComponent } from './components/logo/logo.component';
import { ModalPopupComponent } from './components/modal-popup/modal-popup.component';

import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CircleProgressOptions, NgCircleProgressModule } from 'ng-circle-progress';
import { RefferalPopUpComponent } from './components/refferal-pop-up/refferal-pop-up.component';
import { DashboardCalendarComponent } from '../student/dashboard/dashboard-calendar/dashboard-calendar.component';
import { TopicTestWidgetComponent } from './components/topic-test-widget/topic-test-widget.component';
import { LearningObjectiveDetailsComponent } from '../student/prior-learning-page/learning-objective-details/learning-objective-details.component';
import { HistoricalAssesmentWidgetComponent } from '../student/historical-assesment-widget/historical-assesment-widget.component';
import { AssessmentComponent } from '../student/historical-assesment-widget/assessment/assessment.component';
import { HistoricalAssessmentComponent } from '../student/historical-assessment/historical-assessment.component';
import { NgxStripeModule } from 'ngx-stripe';
import { GooglePayButtonModule } from '@google-pay/button-angular';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaymentPartComponent } from './components/payment-part/payment-part.component';
import { GenderPipe } from './pipes/gender.pipe';
import { HintComponent } from './components/hint/hint.component';
import { EditorVideoPopupComponent } from './components/editor-video-popup/editor-video-popup.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';

import { NewQuestionAnswerVariantComponent } from './components/new-question-block/new-question-answer-variant/new-question-answer-variant.component';
import { NewKeywordsComponent } from './components/new-question-block/new-keywords/new-keywords.component';
import { AiUploadFilesComponent } from './components/new-question-block/create-edit-dialog/ai-upload-files/ai-upload-files.component';
import { AiZonesMarkerComponent } from './components/new-question-block/create-edit-dialog/ai-zones-marker/ai-zones-marker.component';
import { AiZonesMarkerPopupComponent } from './components/new-question-block/create-edit-dialog/ai-zones-marker-popup/ai-zones-marker-popup.component';
import { PaginationStepperComponent } from './components/pagination-stepper/pagination-stepper.component';

import { CreateEditDialogComponent } from './components/new-question-block/create-edit-dialog/create-edit-dialog.component';
import { QuestionSectionComponent } from './components/new-question-block/question-section/question-section.component';

import { MovableSectionsComponent } from './components/new-question-block/movable-sections/movable-sections.component';
import { MovableItemComponent } from './components/new-question-block/movable-sections/movable-item/movable-item.component';
import { MovableItemPanelComponent } from './components/new-question-block/movable-sections/movable-item-panel/movable-item-panel.component';
import { MarkSchemeSectionComponent } from './components/new-question-block/mark-scheme-section/mark-scheme-section.component';
import { MovableDialogComponent } from './components/new-question-block/movable-sections/movable-dialog/movable-dialog.component';
import { CloseConfirmationPopupComponent } from './components/new-question-block/close-confirmation-popup/close-confirmation-popup.component';

import { AssessmentResultsChartComponent } from './components/assessment-results-chart/assessment-results-chart.component';

import { ExpansionPanelTopicComponent } from './components/expansion-panel-topic/expansion-panel-topic.component';
import { ExpansionPanelQuestionsComponent } from './components/expansion-panel-questions/expansion-panel-questions.component';

import { ProgressBarGradientComponent } from './components/progress-bar-gradient/progress-bar-gradient.component';

import { AngularDraggableModule } from 'angular2-draggable';
import { NgChartsModule } from 'ng2-charts';

const DATE_FORMAT = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({
  providers: [
    DatePipe,
    LinkPreviewService,
    ResourceStudentNotesService,
    CircleProgressOptions,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT }
  ],
  declarations: [
    AnswerComponent,
    HasRolesDirective,
    HasPermissionsDirective,
    SchoolAdminChartComponent,
    UniversalCardComponent,
    TableComponent,
    TablePaginationComponent,
    UserRootComponent,
    HeaderComponent,
    TaskListPresentDatePipe,
    TaskPopupComponent,
    FooterComponent,
    UploadFilesComponent,
    DropdownComponent,
    DropdownComponentV2Component,
    DialogRecordingComponent,
    TaskListComponent,
    ResultsTableComponent,
    UserCardComponent,
    CalendarComponent,
    CumulativeTestBlockComponent,
    ClickOutsideDirective,
    DropdownContentComponent,
    PaperInfoItemComponent,
    ButtonComponent,
    GettingStartedTasksComponent,
    CameraComponent,
    DragDropDirective,
    CumulativeTestCreateComponent,
    CumulativeTestCardComponent,
    SchoolPerformanceHistogramComponent,
    DonutChartComponent,
    BreadcrumbComponent,
    DrawingBoardComponent,
    CheckboxComponent,
    OrdinalPipe,
    ToggleButtonComponent,
    TinyEditorComponent,
    TinyEditorDisabledComponent,
    InputComponent,
    CheckboxComponent,
    GradeGuidelineComponent,
    TextareaComponent,
    PeriodGuidelineComponent,
    TextareaDisabledComponent,
    InputDisabledComponent,
    UserStatisticTableComponent,
    TeachersStatisticTableComponent,
    StudentsStatisticTableComponent,
    YearsPickerComponent,
    QuestionComponent,
    PickYearDialogComponent,
    CreatePeriodDialogComponent,
    CustomerPlateComponent,
    CreateSubjectPopupComponent,
    ConfirmPopupComponent,
    AnswersIncorrectComponent,
    CourseCardComponent,
    NotificationsBarComponent,
    RedLabelComponent,
    ConcernWidgetComponent,
    NotificationCardComponent,
    MediaItemComponent,
    ImageViewPopupDialogComponent,
    TableConstructorComponent,
    ProgressBarComponent,
    ImageUploadComponent,
    LoaderComponent,
    ProfileCardComponent,
    AuthInputComponent,
    YsStepOneComponent,
    YsStepTwoComponent,
    YsStepThreeComponent,
    QuestionCardComponent,
    IconButtonComponent,
    SchedulerComponent,
    MobileMenuComponent,
    PaginationComponent,
    StudentCumulativeTestCardComponent,
    InitialsAvatarComponent,
    StudentLessonCardComponent,
    DashboardChartComponent,
    StudentPerformanceChartComponent,
    EditQuestionBlockComponent,
    CreateCurriculumPopupComponent,
    QuestionDatabasePopupComponent,
    StudentAddCommentToNotePopupComponent,
    StudentCourseCardComponent,
    ChartFilterComponent,
    StudentNotesResourcesComponent,
    StudentCreateNoteCustomContextMenuComponent,
    StudentNotesImagePreviewComponent,
    StudentNoteSidebarViewComponent,
    InlineCourseFilterComponent,
    CourseSeparateFilterComponent,
    QuestionAnswerVariantComponent,
    QuestionTypeButtonDropdownComponent,
    QuestionFormAnswerBlockComponent,
    MultistageQuestionFormBlockComponent,
    QuestionFormQuestionBlockComponent,
    MustistageSubquestionBlockComponent,
    QuestionPaginationComponent,
    StudentDashboardChartComponent,
    StudentGradesStatisticComponent,
    UpcomingLessonsVideosComponent,
    UpcommingVideosViewPopupComponent,
    ConcernWidgetRowComponent,
    PagiForLoadingDataComponent,
    OffsetTopDirective,
    ScrollableDirective,
    SelectCoursesFiltersComponent,
    CustomScrollDirective,
    ExpansionPanelComponent,
    CourseFiltersComponent,
    CouRightPipe,
    ImagePopupComponent,
    SettingsBarComponent,
    ChooseYearPopupComponent,
    ChooseSubjectComponent,
    ChoosePlanComponent,
    StepperComponent,
    LogoComponent,
    ModalPopupComponent,
    CircleProgressComponent,
    RefferalPopUpComponent,
    DashboardCalendarComponent,
    TopicTestWidgetComponent,
    LearningObjectiveDetailsComponent,
    HistoricalAssesmentWidgetComponent,
    AssessmentComponent,
    HistoricalAssessmentComponent,
    PaymentPartComponent,
    GenderPipe,
    ModalPopupComponent,
    ChipInputAutocompleteComponent,
    HintComponent,
    EditorVideoPopupComponent,
    TimepickerComponent,
    DatepickerComponent,
    NewQuestionAnswerVariantComponent,
    NewKeywordsComponent,
    AiUploadFilesComponent,
    AiZonesMarkerComponent,
    AiZonesMarkerPopupComponent,
    PaginationStepperComponent,
    LoaderWithTextComponent,
    MovableSectionsComponent,
    MovableItemComponent,
    MovableItemPanelComponent,
    CreateEditDialogComponent,
    QuestionSectionComponent,
    MarkSchemeSectionComponent,
    MovableDialogComponent,
    CloseConfirmationPopupComponent,
    AssessmentResultsChartComponent,
    ExpansionPanelTopicComponent,
    ExpansionPanelQuestionsComponent,
    ProgressBarGradientComponent
  ],
  imports: [
    NgxStripeModule,
    RouterModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePayButtonModule,
    MatFormFieldModule,
    CommonModule,
    FormsModule,
    DragDropModule,
    ReactiveFormsModule,
    MatCardModule,
    FlexLayoutModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    IgxToastModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatSortModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatPaginatorModule,
    WebcamModule,
    MatDialogModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    RouterModule,
    MatProgressBarModule,
    EditorModule,
    MatInputModule,
    TranslateModule,
    AngularSvgIconModule.forRoot(),
    MatTooltipModule,
    MatChipsModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    NgxPaginationModule,
    InfiniteScrollModule,
    MathModule.forRoot(),
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    MatAutocompleteModule,
    MatSelectInfiniteScrollModule,
    MatStepperModule,
    GaugeModule.forRoot(),
    NgCircleProgressModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    AngularDraggableModule,
    NgChartsModule
  ],
  exports: [
    PaymentPartComponent,
    NgxStripeModule,
    MatDividerModule,
    HasRolesDirective,
    UserRootComponent,
    SchoolAdminChartComponent,
    FormsModule,
    FlexLayoutModule,
    AnswersIncorrectComponent,
    ReactiveFormsModule,
    AnswerComponent,
    TranslateModule,
    HasPermissionsDirective,
    CumulativeTestBlockComponent,
    MatButtonModule,
    TableComponent,
    UploadFilesComponent,
    TaskListComponent,
    CalendarComponent,
    ConcernWidgetComponent,
    MatCardModule,
    QuestionComponent,
    DragDropDirective,
    GettingStartedTasksComponent,
    DropdownComponent,
    DropdownComponentV2Component,
    UniversalCardComponent,
    UserCardComponent,
    DropdownContentComponent,
    PaperInfoItemComponent,
    MatIconModule,
    DialogRecordingComponent,
    MatCheckboxModule,
    HeaderComponent,
    FooterComponent,
    ButtonComponent,
    CheckboxComponent,
    CumulativeTestCreateComponent,
    CumulativeTestCardComponent,
    ToggleButtonComponent,
    TinyEditorComponent,
    TinyEditorDisabledComponent,
    SchoolPerformanceHistogramComponent,
    DonutChartComponent,
    TextareaComponent,
    InputComponent,
    DrawingBoardComponent,
    BreadcrumbComponent,
    MediaItemComponent,
    GradeGuidelineComponent,
    PeriodGuidelineComponent,
    CustomerPlateComponent,
    CourseCardComponent,
    TextareaDisabledComponent,
    InputDisabledComponent,
    RedLabelComponent,
    UserStatisticTableComponent,
    TeachersStatisticTableComponent,
    StudentsStatisticTableComponent,
    CameraComponent,
    NotificationsBarComponent,
    ProgressBarComponent,
    MatChipsModule,
    ImageCropperModule,
    ImageUploadComponent,
    MatSnackBarModule,
    ResultsTableComponent,
    LoaderComponent,
    ProfileCardComponent,
    AuthInputComponent,
    YsStepOneComponent,
    YsStepTwoComponent,
    YsStepThreeComponent,
    QuestionCardComponent,
    IconButtonComponent,
    SchedulerComponent,
    NotificationCardComponent,
    StudentCumulativeTestCardComponent,
    MobileMenuComponent,
    StudentLessonCardComponent,
    PaginationComponent,
    EditQuestionBlockComponent,
    PaginatePipe,
    DashboardChartComponent,
    StudentPerformanceChartComponent,
    StudentNotesResourcesComponent,
    StudentAddCommentToNotePopupComponent,
    StudentCourseCardComponent,
    ChartFilterComponent,
    StudentNoteSidebarViewComponent,
    InlineCourseFilterComponent,
    CourseSeparateFilterComponent,
    MatSelectModule,
    MatSelectInfiniteScrollModule,
    QuestionTypeButtonDropdownComponent,
    QuestionPaginationComponent,
    StudentDashboardChartComponent,
    UpcomingLessonsVideosComponent,
    ConcernWidgetRowComponent,
    PagiForLoadingDataComponent,
    OffsetTopDirective,
    ScrollableDirective,
    SelectCoursesFiltersComponent,
    CustomScrollDirective,
    ExpansionPanelComponent,
    CourseFiltersComponent,
    CouRightPipe,
    ImagePopupComponent,
    ChipInputAutocompleteComponent,
    ImagePopupComponent,
    SettingsBarComponent,
    ChooseYearPopupComponent,
    ChooseSubjectComponent,
    ChoosePlanComponent,
    StepperComponent,
    LogoComponent,
    CircleProgressComponent,
    DashboardCalendarComponent,
    TopicTestWidgetComponent,
    LearningObjectiveDetailsComponent,
    HistoricalAssesmentWidgetComponent,
    LoaderComponent,
    GenderPipe,
    ModalPopupComponent,
    ClickOutsideDirective,
    HintComponent,
    EditorVideoPopupComponent,
    TimepickerComponent,
    DatepickerComponent,
    AiZonesMarkerPopupComponent,
    PaginationStepperComponent,
    LoaderWithTextComponent,
    MovableSectionsComponent,
    CreateEditDialogComponent,
    AssessmentResultsChartComponent,
    ExpansionPanelTopicComponent,
    ExpansionPanelQuestionsComponent,
    TablePaginationComponent,
    InitialsAvatarComponent,
    ProgressBarGradientComponent
  ]
})
export class SharedModule {
}
