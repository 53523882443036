/**
 * Created by Iryna Iak. on 13/10/21.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curr-settings-bar',
  templateUrl: './settings-bar.component.html',
  styleUrls: ['./settings-bar.component.scss']
})
export class SettingsBarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
