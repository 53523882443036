import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '../../../shared/components/breadcrumb/breadcrumb.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'curr-learning-objective-details',
  templateUrl: './learning-objective-details.component.html',
  styleUrls: ['./learning-objective-details.component.scss']
})
export class LearningObjectiveDetailsComponent implements OnInit, OnDestroy {
  path = [];
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService
  ) { }

  ngOnInit(): void {
    this.breadcrumbService
      .getRoutParameter('courseId')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: () => {
          this.path = [
            {
              label: 'Subjects',
              url: '/courses-details'
            },
            { label: this.route.snapshot.data.subjectDetails.subject, url: '' },
          ];
        }
      })
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
