<table
  mat-table
  [dataSource]="dataSource"
  matSort
  class="mat-elevation-z8"
  [class.in-edit-mode]="inEditMode"
  aria-describedby="table desc"
>
  <ng-container matColumnDef="key">
    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="table-title">Description</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!inEditMode">
        {{ element.key }}
      </ng-container>
      <div fxLayout="row" *ngIf="inEditMode">
        <curr-input
          fxFlex="150px"
          class="custom-val"
          [(value)]="element.key"
          [isBordered]="true"
          placeholder="Keyword"
        ></curr-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="mark">
    <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
      <span class="table-title">Mark</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="!inEditMode">{{ element.mark }}</ng-container>
      <div fxLayout="row" *ngIf="inEditMode">
        <curr-input
          fxFlex="60px"
          class="custom-val"
          [(value)]="element.mark"
          inputType="number"
          [isBordered]="false"
          [isCentered]="true"
        ></curr-input>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="delete">
    <th scope="col" mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element; let i = index">
      <curr-icon-button icon="close" (click)="deleteElement(element, i)"></curr-icon-button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<curr-button class="m-t-8" *ngIf="inEditMode" variant="text" [icon]="'plus'" (click)="addKeyword()"
  >ADD KEYWORD</curr-button
>
