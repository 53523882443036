/**
 * Created by Daniil Vat. on 18/2/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Question, QuestionType } from '../../../student/test.model';

@Component({
  selector: 'curr-expansion-panel-questions',
  templateUrl: './expansion-panel-questions.component.html',
  styleUrls: ['./expansion-panel-questions.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('200ms', style({ opacity: 0 }))])
    ])
  ]
})
export class ExpansionPanelQuestionsComponent implements OnInit {

  @Input() questionNumber: number;
  @Input() question: Question;
  @Input() isDisabled? = false;
  @Input() isShowed = true;
  @Output() onEditQuestionItem = new EventEmitter<Question>();
  @Output() onDeleteQuestionItem = new EventEmitter<Question>();

  isError = false;
  inEditMode = false;

  constructor() {}

  ngOnInit() {}

  toggleDropdown() {
    if (!this.isDisabled) {
      this.isShowed = !this.isShowed;
    }
  }

  closeDropDown() {
    this.isShowed = false;
  }

  editQuestionItem(question: Question) {
    this.onEditQuestionItem.emit(question);
  }

  deleteQuestionItem(question: Question) {
    this.onDeleteQuestionItem.emit(question);
  }

  changedQuestionTypeName(questionType) {
    return questionType.replace('_', ' ').toLowerCase();
  }

  formatNumber(number) {
    return number < 10 ? '0' + number : number.toString();
  }
}
