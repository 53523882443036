/**
 * Created by Dmytro Sav. on 4/15/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { SunburstComponent } from '../subject-details/sunburst/sunburst.component';
import { MatDialog } from '@angular/material/dialog';
import { CreateSubjectPopupComponent } from '../../shared/components/create-subject-popup/create-subject-popup.component';
import { Question, QuestionType } from '../../student/test.model';
import { BehaviorSubject, interval, of, Subject } from 'rxjs';
import { CurriculumManagementModel } from './curriculum-management.model';
import { getRestApiProvider, Paginated, RestApiService } from '../../shared/api/rest-api.service';
import { ToastrService } from 'ngx-toastr';
import { SunburstApiResponse } from '../subject-details/sunburst/sunburst-api-response';
import { catchError, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { SunburstService } from '../subject-details/sunburst/sunburst.service';
import { CreateCurriculumPopupComponent } from 'src/app/shared/components/create-curriculum-popup/create-curriculum-popup.component';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';

export class CurriculumData {
  curriculumManagementModel: CurriculumManagementModel;
  sunburst: SunburstApiResponse;
}

@Component({
  selector: 'curr-curriculum-management',
  templateUrl: './curriculum-management.component.html',
  animations: [
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1, height: '100%' })),
      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [style({ opacity: 0, height: '0px' }), animate(600)]),
      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave', animate(600, style({ opacity: 0 })))
    ]),
    trigger('openMinimized', [
      transition('minimized => opened', [
        animate('0s', style({ overflow: 'hidden' })),
        query('.curr-sunburst-wrapper', animate('0s', style({ transform: 'scale(0.3)', height: '75px' }))),
        query(
          '.curr-sunburst-wrapper',
          animate('0.1s', style({ transform: 'scale(0.3)', height: '750px', transformOrigin: 'bottom' }))
        ),
        query('.close-button', animate('0s', style({ width: '0' }))),
        query(
          '.curr-sunburst-wrapper',
          animate('0.5s ease-in', style({ transform: 'translate(0, 600px) scale(0.3)' }))
        ),
        group([
          query(
            '.curr-sunburst-wrapper',
            animate('1s ease-out', style({ transform: 'translate(0, 0) scale(1)', height: '750px' }))
          ),
          query('.close-button', [animate('0.5s', style({ width: '207px' }))])
        ])
      ]),
      transition('opened => minimized', [
        query(
          '.curr-sunburst-wrapper',
          animate('0s', style({ transform: 'translate(0, 0) scale(1)', height: '750px' }))
        ),
        query('.close-button', [style({ width: '207px' })]),
        group([
          query('.close-button', [animate('0.5s', style({ width: '0px' }))]),
          query('.curr-sunburst-wrapper', animate('0.5s', style({ transform: 'translate(0, 350px) scale(0.3)' })))
        ]),
        query('.curr-sunburst-wrapper', animate('0.5s', style({ height: '75px' })))
      ])
    ])
  ],
  styleUrls: ['./curriculum-management.component.scss'],
  providers: [
    getRestApiProvider<CurriculumManagementModel>('curriculums/management', 'curriculumsManagement'),
    getRestApiProvider<{ id: string; is_published: boolean }>('publishedStatus', 'publishedStatus'),
    getRestApiProvider<SunburstApiResponse[]>('sunBurstDiagram', 'sunBurstDiagram')
  ]
})
export class CurriculumManagementComponent implements OnInit {
  curriculumManagements: CurriculumManagementModel[] = [];

  publishedCurriculums: CurriculumManagementModel[] = [];
  unPublishedCurriculums: CurriculumManagementModel[] = [];

  isPublishedLoading = false;
  isUnpublishedLoading = false;

  diagramLoadingLimit = 9;

  currentFilters: any;

  filterChanged$ = new BehaviorSubject({});

  publishedCurriculumData: CurriculumData[] = [];

  publishedPaginated = new Paginated(1);
  unPublishedPaginated = new Paginated(10);
  publishedPaginated$: BehaviorSubject<Paginated> = new BehaviorSubject(this.publishedPaginated);
  unPublishedPaginated$: BehaviorSubject<Paginated> = new BehaviorSubject(this.unPublishedPaginated);

  btnViewStyle = { width: '87px', padding: 0, textAligned: 'center' };
  path = [{ label: 'Home', url: '/dashboard' }, { label: 'Curriculum Management' }];

  ddQuestionTypeOptions = [
    { value: QuestionType.SELECT_ONE, label: 'Multiple choice' },
    { value: QuestionType.SELECT_MULTIPLE, label: 'Multiple select' },
    { value: QuestionType.OPEN_ENDED, label: 'Open answer' },
    { value: QuestionType.TRUE_OR_FALSE, label: 'True/False' },
    { value: QuestionType.FILE_INPUT, label: 'File' },
    { value: QuestionType.SINGLE_ANSWER, label: 'Single answer' }
  ];

  questions: Question[] = [];

  currSelectedTabIndex = 0;

  // TODO: Check if need?
  // tslint:disable-next-line:no-unused-variable
  previouslyShadowed$: Subject<boolean> = new Subject<boolean>();

  isShadowedMap: Map<any, Subject<boolean>> = new Map();

  dropDownOptions = [
    { value: '1', label: 'Biology' },
    { value: '2', label: 'Geography' },
    { value: '3', label: 'Math' },
    { value: '4', label: 'Chemistry' }
  ];

  constructor(public dialog: MatDialog,
              public http: ApplicationHttpClient,
              @Inject('curriculumsManagement') private curriculumManagementService: RestApiService<CurriculumManagementModel>,
              @Inject('publishedStatus') private publishedStatusService: RestApiService<{ id: string; is_published: boolean }>,
              // @Inject('sunBurstDiagram') private sunburstService: RestApiService<SunburstApiResponse>,
              private sunburstService: SunburstService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.filterChanged$
      .pipe(
        switchMap(res => {
          // this.isPublishedLoading = true;
          return this.curriculumManagementService
            .getAllPaginated(this.publishedPaginated$, { ...this.currentFilters, is_published: true })
            .pipe(
              mergeMap(result => {
                const newPublishedCurriculums = [...result].sort((first, second) =>
                  first.curriculum_id > second.curriculum_id ? -1 : 1
                );
                newPublishedCurriculums.forEach(test => {
                  this.isShadowedMap.set(test, new Subject());
                });
                const curriculumPublishedIndexes = [];
                newPublishedCurriculums.forEach((val: CurriculumManagementModel) => {
                  curriculumPublishedIndexes.push(val.curriculum_id);
                });
                return this.sunburstService.getSunburstData({ curriculumIds: curriculumPublishedIndexes }).pipe(
                  catchError(err => {
                    console.error(err);
                    return of([]);
                  }),
                  finalize(() => {
                    if (this.publishedPaginated.offset % this.diagramLoadingLimit === 0) {
                      this.isPublishedLoading = false;
                    }
                  }),
                  map(r => {
                    return {
                      sunburst: r,
                      newPublishedCurriculums
                    };
                  })
                );
              })
            );
        })
      )
      .subscribe(result => {
        const { newPublishedCurriculums } = result;
        this.publishedCurriculums.push(...newPublishedCurriculums);
        const initialLength = this.publishedCurriculumData.length;
        result.sunburst.forEach((item, i) => {
          this.publishedCurriculumData.push({
            curriculumManagementModel: this.publishedCurriculums[initialLength + i],
            sunburst: item
          });
        });
      });

    this.filterChanged$
      .pipe(
        switchMap(res => {
          this.isUnpublishedLoading = true;
          return this.curriculumManagementService
            .getAllPaginated(this.unPublishedPaginated$, { ...this.currentFilters, is_published: false })
            .pipe(
              map(result => {
                this.unPublishedCurriculums.push(
                  ...result.concat().sort((first, second) => (first.curriculum_id > second.curriculum_id ? -1 : 1))
                );
                this.isUnpublishedLoading = false;
                return result;
              }),
              catchError(err => {
                this.isUnpublishedLoading = false;
                return of([]);
              })
            );
        })
      )
      .subscribe();
    this.initDiagramsLoading(this.diagramLoadingLimit);
  }

  initDiagramsLoading(quantity: number = 10) {
    const source = interval(300).pipe(take(quantity));

    source.subscribe(val => {
      this.loadMorePublished();
    });
  }

  dropDownClick(value: string, filter: string) {
  }

  openSunburst(sunburst: any, test: any, event) {
    if (sunburst.isOpened) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.isShadowedMap.get(test)?.next(false);
      sunburst?.scrollIntoView({ behavior: 'smooth' });
      sunburst.isOpened = true;
    }
  }

  closeSunburst(sunburst: SunburstComponent, test: any, sunburstContainer: any) {
    sunburst.reset();
    sunburstContainer.isOpened = false;
  }

  showCreateSubjectPopup() {
    this.dialog.open(CreateSubjectPopupComponent, {
      width: '978px',
      data: {}
    });
  }

  showCreateManuallyPopup() {
    this.dialog.open(CreateCurriculumPopupComponent, {
      width: '424px',
      data: {}
    });
  }

  showImportPopup() {
    this.dialog.open(CreateSubjectPopupComponent, {
      width: '1069px',
      data: {}
    });
  }

  createQuestion() {
  }

  importQuestions() {
  }

  tabSelectedIndexChange($event: number) {
    this.currSelectedTabIndex = $event;
  }

  onMouseOver(test, sunburstContainer) {
    if (!sunburstContainer.isOpened) {
      this.isShadowedMap.get(test)?.next(true);
    }
  }

  onMouseOut(test, sunburstContainer) {
    this.isShadowedMap.get(test)?.next(false);
  }

  filterChanged(event) {
    this.currentFilters = event;
    this.filterChanged$.next(event);
  }

  onPublish(curriculum: CurriculumManagementModel) {
    this.publishedStatusService.updateWithoutId({ id: curriculum.curriculum_id, is_published: true }).subscribe(res => {
      this.unPublishedCurriculums.splice(this.unPublishedCurriculums.indexOf(curriculum), 1);
      this.publishedCurriculums.push(curriculum);
      this.toastr.success('Curriculum was successfully published');
    });
  }

  onDelete(curriculum: CurriculumManagementModel) {
    this.http.delete(`/curriculums/${ curriculum.curriculum_id }`).subscribe(res => {
      this.unPublishedCurriculums.splice(this.unPublishedCurriculums.indexOf(curriculum), 1);
      this.toastr.success('Curriculum was successfully deleted');
    });
  }

  loadMorePublished() {
    this.publishedPaginated = this.publishedPaginated.next();
    this.publishedPaginated$.next(this.publishedPaginated);
    this.isPublishedLoading = true;
    // this.filterChanged$.next(this.currentFilters);
  }

  loadMoreUnpublished() {
    this.unPublishedPaginated = this.unPublishedPaginated.next();
    this.unPublishedPaginated$.next(this.unPublishedPaginated);
    this.isUnpublishedLoading = true;
  }
}
