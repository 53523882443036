<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
 
<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-20">
  <h2 class="mat-h2 m-b-0" fxFlex>Edit Paper Name</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div [formGroup]="form" class="paper-name-container m-b-0" fxLayout="column" fxLayoutAlign="center center">
  <curr-input
    placeholder="Paper Name"
    [formGroup]="form"
    controlName="name"
    class="full-width"
    maxlength="100"
    userHint="{{form.controls['name'].value ? form.controls['name'].value.length : 0}}/100"
    [errorMessage]="form.controls['name'].hasError('required') ? 'Paper Name is required' : 'Paper Name max length = 100 characters'"
  ></curr-input>
  <div class="m-t-24 full-width" fxLayout="row" fxLayoutAlign="end center">
    <curr-button variant="contained" [isDisabled]="form.invalid || !name.dirty" (btnClick)="saveName()" size="big">SUBMIT</curr-button>
  </div>
</div>
