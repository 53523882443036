import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[currOffsetTop]'
})
export class OffsetTopDirective {
  constructor(private el: ElementRef) {}
  get offsetTop(): number {
    return this.el.nativeElement.offsetTop;
  }
}
