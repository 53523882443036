import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../api/application-http-client';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

type ResourceType = 'VIDEO' | 'AUDIO' | 'IMAGE' | 'DOC' | 'ARTICLE' | 'LESSON_PLAN' | 'QUESTION';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  constructor(private httpClient: ApplicationHttpClient,
              private sanitizer: DomSanitizer) {
  }

  upload(dataFile: any, resourceType: ResourceType) {
    const formData = new FormData();
    formData.append('files', dataFile);
    formData.append('resourceType', resourceType);
    return this.httpClient.post(`/s3/upload`, formData).pipe(
      map(res => {
        return (res as any).data.items;
      })
    );
  }

  download(fileName: string, resourceType: string) {
    return this.httpClient.get(
      `/s3/download?fileName=${fileName}&resourceType=${resourceType}`);
  }

  base64ToFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getMetaData(fileName: string, resourceType: string) {
    return this.httpClient.get(
      `/s3/getMetaData?fileName=${fileName}&resourceType=${resourceType}`);
  }

  getImageFromBytes(bytes: string) {
    const objectURL = 'data:image/png;base64,' + bytes;
    return this.sanitizer.bypassSecurityTrustUrl(objectURL);
  }

  getAllImageKeysFromNote(text: string) {
    if (!text.length) {
      return {};
    }
    const result = {};
    const regexp = /<img [a-zA-Z0-9:\/\-.:;"_ =]*src="[a-zA-Z0-9:\/\-."_ =]*\/>/gm; // find all imgs tag
    // @ts-ignore
    const ar = [...text.toString().matchAll(regexp)];

    if (ar.length) {
      ar.forEach(i => {
        const imgtag = i[0];
        const d = document.createElement('div');
        d.innerHTML = imgtag;
        const img = d.childNodes[0];
        if (img.nodeName === 'IMG') {
          // @ts-ignore
          const src = img.attributes?.src?.nodeValue;
          if (src.indexOf('http') === -1) {
            result[src] = '';
          }
        }

      });
    }
    return result;
  }

}
