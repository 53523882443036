<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="movable-dialog" #movableDialog cdkDrag>
  <div class="movable-dialog-header">
    <div class="header-title">Content from AI</div>
    <div class="header-actions">
      <mat-icon class="drag-pan" [svgIcon]="'drag-pan'" cdkDragHandle></mat-icon>
      <mat-icon [svgIcon]="'close-dialog'" (click)="onCloseDialog.emit()"></mat-icon>
    </div>
  </div>
  <div class="movable-dialog-content">
    <ng-container *ngIf="originalQuestionContent && stepNumber !== 1">
      <div class="question-row-subtitle-bold">Question</div>
      <div class="inner-question" [currMath]="originalQuestionContent"></div>
    </ng-container>

    <ng-container *ngIf="originalMarkSchemeContent">
      <div class="question-row-subtitle-bold" [ngClass]="stepNumber === 2 ? 'm-t-24' : ''">Mark Scheme</div>
      <div class="inner-mark-scheme" [currMath]="originalMarkSchemeContent"></div>
    </ng-container>

    <ng-container *ngIf="!originalQuestionContent && !originalMarkSchemeContent">
      <div class="inner-question" [currMath]="'There is not recognized content'"></div>
    </ng-container>
  </div>

  <div class="movable-dialog-resizer" #resizer>
    <mat-icon [svgIcon]="'resizer'"></mat-icon>
  </div>
</div>
