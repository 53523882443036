/*
 * Created by Dmytro Sav. on 4/5/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { LearningObjective } from '../../api-subject-details';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PaperDetailsService } from '../paper-details.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'curr-sow-topics-list',
  templateUrl: './sow-topics-list.component.html',
  styleUrls: ['./sow-topics-list.component.scss']
})
export class SowTopicsListComponent implements OnInit, OnDestroy {

  @Input() topics: LearningObjective[];
  @Output() onSelectTopicItem = new EventEmitter<{topic: LearningObjective, topicIndex?: number}>();
  @Output() onChengeTopicsOrder = new EventEmitter<LearningObjective[]>();
  @Output() onEditTopicItem = new EventEmitter<LearningObjective>();
  @Output() onDeleteTopicItem = new EventEmitter<LearningObjective>();

  activeItem!: LearningObjective;
  sortedTopics!: LearningObjective[];

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private paperDetailsService: PaperDetailsService) {}

  ngOnInit(): void {
    if (this.topics.length > 0) {
      this.sortedTopics = this.topics.sort((a, b) => +a.sequence - +b.sequence);
      this.selectTopicItem(this.sortedTopics[0], 0);

      this.paperDetailsService.onSetActiveTopic().pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.selectTopicItem(this.sortedTopics[0], 0);

        setTimeout(() => {
          const el = document.querySelector('.sortable-list');
          if (el) {
            (el as HTMLElement)?.scrollTo({
              behavior: 'smooth',
              top: 0,
            });
          }
        }, 500);
      });

      this.paperDetailsService.onSetActiveTopicById().pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
        const topic = this.topics.find(item => +item.id === res);
        
        if (topic && +topic.id === +this.activeItem.id) {
          this.paperDetailsService.updateQuestionList();
          return;
        }

        if (topic) this.selectTopicItem(topic);
      });

      this.paperDetailsService.onSetActiveTopicByIdWithScroll().pipe(takeUntil(this.destroy$)).subscribe((res: number) => {
        const topic = this.topics.find(item => +item.id === res);
        if (topic) this.selectTopicItem(topic);

        setTimeout(() => {
          const el = document.querySelector('.sortable-list');
          if (el) {
            (el as HTMLElement)?.scrollTo({
              behavior: 'smooth',
              top: el.scrollHeight,
            });
          }
        }, 500);
      });
    }
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
  }

  drop(event: CdkDragDrop<unknown>) {
    moveItemInArray(this.topics, event.previousIndex, event.currentIndex);
    this.onChengeTopicsOrder.emit(this.topics);
  }

  selectTopicItem(topic: LearningObjective, topicIndex?: number) {
    this.activeItem = topic;
    this.onSelectTopicItem.emit({topic, topicIndex});
  }

  editTopicItem(topic: LearningObjective) {
    this.onEditTopicItem.emit(topic);
  }

  deleteTopicItem(topic: LearningObjective) {
    this.onDeleteTopicItem.emit(topic);
  }
}
