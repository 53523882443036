<!-- Created by Max Bon. on 18/08/2020 happy birthday Max! -->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container p-l-16 p-t-12 p-b-12" fxLayout="column">

  <div class="full-width add-note-container  m-b-12" fxLayout="row" fxLayoutAlign="start center"
       (click)="$event.stopPropagation(); setCheckedColor('note')">
    <curr-button variant="text">Add note</curr-button>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutAlign="start center" class="full-width  p-t-12">
    <div class="highlight-color m-r-16" *ngFor="let c of highlightColors;let i = index"
         (click)="$event.stopPropagation(); setCheckedColor(c)"
         [style]="{'background-color': c}"></div>
  </div>
</div>
