/**
 * Created by Alex Poh. on 26/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GraphOptions } from '../graph-options';
import * as d3 from 'd3';
import { DonutChartDataType } from './donut-chart-data-type';

@Component({
  selector: 'curr-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart-data-type.scss']
})
export class DonutChartComponent implements OnInit, AfterViewInit {

  @Input()
  graphOptions: GraphOptions;

  @Input()
  data: DonutChartDataType[];

  @Input()
  biggerText: string;

  @Input()
  smallerText: string;

  @ViewChild('chartContainer') chartContainer: ElementRef;

  private svg: any;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    this.svg = d3.select(this.chartContainer.nativeElement);
    const radius = Math.min(this.graphOptions.width, this.graphOptions.height) / 2 - this.graphOptions.margin.left;
    this.svg = this.svg
      .attr('width', this.graphOptions.width)
      .attr('height', this.graphOptions.height)
      .append('g')
      .attr('transform', 'translate(' + this.graphOptions.width / 2 + ',' + this.graphOptions.height / 2 + ')');
    const data = this.data;
    const pie = d3.pie().value(d => {
      return d.value.value;
    });
    const dataReady = pie(data);
    this.svg
      .selectAll('whatever')
      .data(dataReady)
      .enter()
      .append('path')
      .attr(
        'd',
        d3
          .arc()
          .innerRadius(110) // This is the size of the donut hole
          .outerRadius(radius)
      )
      .attr('class', d => {
        return d.data.value.class;
      });
    this.svg
      .append('text')
      .attr('x', -this.graphOptions.margin.left / 2 - 25)
      .attr('y', 0)
      .attr('class', 'mat-display-3')
      .text(this.biggerText);
    this.svg
      .append('text')
      .attr('x', -this.graphOptions.margin.left / 2 - 25)
      .attr('y', 40)
      .attr('class', 'mat-h1')
      .text(this.smallerText);
  }
}
