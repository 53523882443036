/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenPopupComponent } from './full-screen-popup.component';
import { FullScreenPopupInsertionDirective } from './full-screen-popup-insertion.directive';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [FullScreenPopupComponent, FullScreenPopupInsertionDirective],
  entryComponents: [FullScreenPopupComponent]
})
export class FullScreenPopupModule {
}
