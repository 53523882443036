import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export interface TestArchiveRecord {
  name?: string;
  date?: string | number;
  subject?: string;
  status?: string;
  result?: string;
  testType?: string;
  type?: 'ct' | 'sa';
}

@Component({
  selector: 'curr-student-profile-test-archive',
  templateUrl: './student-profile-test-archive.component.html',
  styleUrls: ['./student-profile-test-archive.component.scss']
})
export class StudentProfileTestArchiveComponent implements OnInit, OnChanges {
  @Input() testArchive: TestArchiveRecord[] = [];

  type = 'all';
  itemsPerPage = 5;
  currPagiIndex = 0;
  paginationArr;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.paginationArr = new Array(Math.ceil(this.testArchive.length / this.itemsPerPage));
  }

  ngOnInit(): void {}

  goToLearningResults(test: TestArchiveRecord) {}

  reviewAnswers(test: TestArchiveRecord) {}

  selectType(t: string) {
    this.type = t;
  }

  setPagination(i: any | number) {
    if (i < 0 || i > Math.ceil(this.testArchive.length / this.itemsPerPage) - 1) {
      return;
    }
    this.currPagiIndex = i;
  }

  inIndexBoundaries(i: number) {
    const startInd = this.currPagiIndex * this.itemsPerPage;
    return i >= startInd && i < startInd + this.itemsPerPage;
  }
}
