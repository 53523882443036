<div class="full-width container">

  <curr-question-form-question-block [question]="rootQuestion" [inSowPage]="inSowPage"
    [questionLabel]="'Question Introduction'" [questionPlaceholder]="'Question Introduction (optional)'"
    (saveQuestionData)="saveQuestion($event)"></curr-question-form-question-block>

  <mat-divider class="m-t-16 full-width disable-absolute"></mat-divider>
  <ng-container *ngTemplateOutlet="subQuestionsNavigation"></ng-container>


  <curr-question-form-question-block [question]="selectedSubQuestion" class="m-t-16"
    *ngIf="selectedSubQuestion?.children.length" [inSowPage]="inSowPage" [questionLabel]="'Subquestions '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1) +' Introduction'" [questionPlaceholder]="'Subquestions '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1) +' Introduction (optional)'"
    (saveQuestionData)="saveQuestion($event)"></curr-question-form-question-block>

  <ng-container *ngIf="!selectedSubQuestion?.children.length">
    <div class="block">
      <curr-loader *ngIf="isSavingQuestion"></curr-loader>

      <curr-mustistage-subquestion-block *ngIf="selectedSubQuestion && !isSavingQuestion"
        [question]="selectedSubQuestion" [cou]="cou" [inSowPage]="inSowPage"
        (onDeleteSubQuestion)="deleteSubquestion($event)" [isCTQuestion]="isCTQuestion" [questionLabel]="'Question '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1)" [questionPlaceholder]="'Question '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1)"
        (saveQuestionData)="updateQuestion(selectedSubQuestion)"></curr-mustistage-subquestion-block>

    </div>
  </ng-container>

  <ng-container *ngIf="selectedSubQuestion?.children.length">
    <div class="block-container">
      <div class="block" *ngFor="let q of selectedSubQuestion.children;let i = index">
        <curr-mustistage-subquestion-block [question]="q" [cou]="cou" [inSowPage]="inSowPage"
          [isCTQuestion]="isCTQuestion" (onDeleteSubQuestion)="deleteSubquestion($event)" [questionLabel]="'Question '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1) + '.' + (i + 1)" [questionPlaceholder]="'Questions '+ startIndexForSubQuestion
                                        + '.' + (selectedSubQuestionIndex + 1) + '.' + (i + 1)"
          (saveQuestionData)="updateQuestion(q)"></curr-mustistage-subquestion-block>


      </div>
    </div>

  </ng-container>


  <ng-container *ngTemplateOutlet="subQuestionsNavigation"></ng-container>
  <mat-divider class="m-b-16 full-width disable-absolute"></mat-divider>
</div>


<ng-template #subQuestionsNavigation>
  <div fxLayout="row" fxLayoutAlign="start center" class="full-width m-t-16 m-b-16 sub-question-navigation">
    <curr-button variant="outlined" icon="arrow-left" class="icon-arrow-btn m-r-32" (click)="selectPrevQuestion()">
    </curr-button>
    <div class="" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <ng-container *ngFor="let q of rootQuestion?.children;let i = index">
        <a (click)="setSelectedSubQuestion(q, i)" class="navigation-question-number"
          [ngClass]="{'selected': q.id === selectedSubQuestion.id}">
          {{startIndexForSubQuestion + '.' + (i + 1) }}
        </a>

      </ng-container>

    </div>
    <curr-button variant="outlined" icon="arrow-right"
      *ngIf="rootQuestion?.children?.length < MAX_SUB_QUESTIONS_QUANTITY" class="m-l-32 nav-multipart-btns"
      (btnClick)="addNewRootSubQuestion();" [isDisabled]="rootQuestion?.children?.length >= MAX_SUB_QUESTIONS_QUANTITY">
      Create question {{startIndexForSubQuestion + '.' + (rootQuestion?.children?.length + 1)}}</curr-button>
    <curr-button variant="outlined" icon="arrow-right"
      *ngIf="rootQuestion.children[selectedSubQuestionIndex]?.children?.length < MAX_SUB_QUESTIONS_QUANTITY"
      [isDisabled]="rootQuestion.children[selectedSubQuestionIndex]?.children?.length >= MAX_SUB_QUESTIONS_QUANTITY"
      (btnClick)="createSubQuestionToSubQuestion()" class="m-l-8 nav-multipart-btns">
      Create
      question {{startIndexForSubQuestion + '.' + (selectedSubQuestionIndex + 1) + '.' +
      (selectedSubQuestion?.children.length + 1)}}
    </curr-button>
  </div>
</ng-template>
