/*
 * Created by Dmytro Sav. on 4/28/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { ImageViewPopupDialogComponent } from '../media-item/image-view-popup-dialog/image-view-popup-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReviewQuestion, StudentReviewInfo } from '../../../teacher/questions-page/questions-page.interface';
import { StudentCheckTestService } from '../../../teacher/questions-page/student-check-test.service';
import { QuestionType } from '../../../student/test.model';
import { DialogRecordingComponent } from '../recording-demo/dialog-recording/dialog-recording.component';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { Subject, Subscription } from 'rxjs';
import { RestApiService } from '../../api/rest-api.service';
import { COUResponse } from 'src/app/shared-pages/subject-details/api-subject-details';
import { LocalStorageService } from 'ngx-localstorage';
import { StudentAnswer, Reply, ISelfMarkSchemas } from './answer.interface';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'curr-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
  providers: [RestApiService]
})
export class AnswerComponent implements OnInit, OnChanges, OnDestroy {

  private subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  cou;
  nextSessionId: any;
  questionType = QuestionType;
  @Input() selectedMainQuestionId: number;
  @Input() questionNumber: number;
  @Input() isCumulativeTests?: boolean;
  @Input() answersForApprove;
  @Input() isNotViewMode;
  @Input() answerData: {
    question: ReviewQuestion;
    answers: string[] | Array<{}>;
    student: StudentReviewInfo;
    result: string;
    reviewState: string;
    resultMark?: number;
    mark?: number;
    correctAnswer: number[];
    cou?: {
      label: string;
      studentAnswer: string;
      date: Date;
    };
    reply?: Reply;
    key: string;
  };

  @Output() onAnswersForApprove = new EventEmitter<any>();
  @Output() onApproveOne = new EventEmitter<any>();

  studentCumTestQuestionId;
  responseType;
  ddOptions = [
    { value: 'LOT', label: 'Lack of technique (LOT)' },
    { value: 'LOR', label: 'Lack of revision (LOR)' },
    { value: 'LOU', label: 'Lack of understanding (LOU)' }
  ];

  btnStyle = { padding: 0 };
  fileInputBtnStyle = { padding: '0 12px', textAlign: 'start' };

  blob: { blobUrl?: string; blobFile?: any } = {};
  tinyEditorValue: string;
  isTinyEditor = false;
  isNewRecord = false;
  startRecord = false;
  isAddReviewMode = false;
  isFileLoading = false;
  imagePreviewFile;
  previewFile;
  fileExtension;
  question: ReviewQuestion;
  studentAnswer: StudentAnswer;
  studentAnswerText: string;
  qt = QuestionType;
  audioFile;
  image;
  imageResultAnswer;
  isImage: boolean = false;
  isReplyImage: boolean = false;
  isDescriptionImage: boolean = false;
  isResultImage: boolean = false;
  isLoadEditor: boolean = true;
  highlights: string;
  insights: string;
  answerSelfMarkSchemas: ISelfMarkSchemas[] = [];

  constructor(public dialog: MatDialog,
              private fileUploadService: FileUploadService,
              private testService: StudentCheckTestService,
              private couService: RestApiService<COUResponse>,
              private localStorageService: LocalStorageService,
              private toastr: ToastrService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.answerData && this.answerData.question.answers) {
      this.studentCumTestQuestionId = this.answerData.question.answers[this.answerData.key].id;
    }
  }

  ngOnInit() {
    setTimeout(() => this.isLoadEditor = false, 500);

    // this.getNextSessionId();
    if (this.answerData && this.answerData.question.answers && this.answerData.question.children.length === 0) {
      this.studentCumTestQuestionId = this.answerData.question.answers[this.answerData.key].id;
      this.question = this.answerData.question;
      this.studentAnswer = this.question.answers[this.answerData.key];

      setTimeout(() => {
        this.studentAnswerText = this.studentAnswer.studentAnswerText;
      }, 600)
      
      this.highlights = this.studentAnswer.aiMark && this.studentAnswer.aiMark.highlights ? this.studentAnswer.aiMark.highlights : '';
      this.insights = this.studentAnswer.aiMark && this.studentAnswer.aiMark.insights ? this.studentAnswer.aiMark.insights : '';

      this.answersForApprove.teacherComment = this.studentAnswer.textAnswer;
      this.answersForApprove.mark = this.studentAnswer.mark;
      this.answersForApprove.aiMark.highlights = this.highlights;
      this.answersForApprove.aiMark.insights = this.insights;
      this.onAnswersForApprove.emit(this.answersForApprove);

      if (this.studentAnswer?.studentAnswerText?.includes('img') && this.question?.answerType === this.qt.OPEN_ENDED) {
        this.isResultImage = true;
        this.getResultAnswerImg(this.studentAnswer.studentAnswerText);
      } else if (this.studentAnswer?.studentAnswerText?.includes('img') && this.question?.answerType === this.qt.SINGLE_ANSWER) {
        this.isImage = true;
        this.getAnswerImg(this.studentAnswer.studentAnswerText);
      }
  
      if (this.studentAnswer?.answerSolution?.includes('img')) {
        this.isDescriptionImage = true;
        this.getAnswerImg(this.studentAnswer.answerSolution);
      }
  
      if (this.studentAnswer?.textAnswer?.includes('img')) {
        this.isReplyImage = true;
        this.getAnswerImg(this.studentAnswer.textAnswer);
      }
      // @ts-ignore
      if (this.answerData?.answers[this.studentAnswer?.studentCorrectAnswerOptions]?.includes('img')) {
        this.isImage = true;
        // @ts-ignore
        this.getAnswerImg(this.answerData.answers[this.studentAnswer.studentCorrectAnswerOptions]);
      }
      if (this.studentAnswer.audioAnswer) {
        this.fileUploadService.download(this.studentAnswer.audioAnswer, 'AUDIO').subscribe((audio: any) => {
          this.audioFile = 'data:audio/ogg;base64,' + audio.data.bytes;
        });
      }
      this.getFileIfExists();
      this.mapSelfMarkSchemas();
    }
  }

  private mapSelfMarkSchemas(): void {
    if (this.studentAnswer.selfMark && this.studentAnswer.selfMark.selfMarkSchemas.length > 0) {
      this.answerSelfMarkSchemas = this.studentAnswer.selfMark.selfMarkSchemas.filter(item => !item.correct);
    }
  }

  getNextSessionId() {
    const sessionId = this.localStorageService.get('schoolYearCourseId');
    if (sessionId) {
      this.subscription.add(
        this.couService.getNextSessionId(sessionId).subscribe(resp => {
          this.nextSessionId = resp;
        }));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAnswerImg(img) {
      if (img.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(img);
        this.uploadAnswerOption(imageKeyValue, img);
      }
    return this.image
  }

  setCheckboxAnswer(event: any, index: number, mark: number) {
    // if (event.length > 0) {
    //   this.studentAnswer.mark = this.studentAnswer.mark + mark;
    //   this.studentAnswer.selfMark.selfMarkSchemas[index].correct = true;
    // } else {
    //   this.studentAnswer.mark = this.studentAnswer.mark - mark;
    //   this.studentAnswer.selfMark.selfMarkSchemas[index].correct = false;
    // }
  }

  private async uploadAnswerOption(imageKey: any, img: any) {
    let previewAnswerOption = img;
    // tslint:disable-next-line:forin
    for (const key in imageKey) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      imageKey[key] = 'data:image/png;base64,' + bytes;
      previewAnswerOption = previewAnswerOption.replace(key, imageKey[key]);
    }
    return this.image = previewAnswerOption;
  }

  getResultAnswerImg(img) {
      if (img.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(img);
        this.uploadResultAnswerOption(imageKeyValue, img);
      }
    return this.imageResultAnswer
  }

  private async uploadResultAnswerOption(imageKey: any, img: any) {
    let previewAnswerOption = img;
    // tslint:disable-next-line:forin
    for (const key in imageKey) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      imageKey[key] = 'data:image/png;base64,' + bytes;
      previewAnswerOption = previewAnswerOption.replace(key, imageKey[key]);
    }
    return this.imageResultAnswer = previewAnswerOption;
  }

  toggleTinyEditor() {
    this.isTinyEditor = !this.isTinyEditor;
  }

  clickNewRecord() {
    this.isNewRecord = true;
    this.blob.blobUrl = null;
    this.startRecord = true;
    this.studentAnswer.audioAnswer = '';
    const dialogRef = this.dialog.open(DialogRecordingComponent, {
      width: '480px',
      height: '240px',
      data: this.blob
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroy$)).subscribe(result => {
      if (typeof result !== 'undefined') {
        this.blob = result;
      }
      this.closeRecord(result);
    });
  }

  closeRecord(blob: any) {
    if (blob) {
      this.blob = blob;
      this.sendTeacherAudio();
    }
    this.startRecord = false;
  }

  deleteReply() {
    this.testService.removeQuestionAudio(this.studentCumTestQuestionId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.studentAnswer.audioAnswer = null;
        this.audioFile = null;
        this.blob.blobUrl = null;
      });    
  }

  openImagePreviewDialog(url: string) {
    this.dialog.open(ImageViewPopupDialogComponent, {
      data: { url }
    });
  }

  ddOptionsChange($event: any | []) {
    this.responseType = $event;
    this.sendTeacherResponseType();
  }

  changeTeacherComment($event: any) {
  }

  sendHighlights(data) {
    this.answersForApprove.aiMark.highlights = this.highlights;
    this.onAnswersForApprove.emit(this.answersForApprove);
  }

  sendInsights(data) {
    this.answersForApprove.aiMark.insights = this.insights;
    this.onAnswersForApprove.emit(this.answersForApprove);
  }

  sendTeacherComment(com) {
      this.answersForApprove.teacherComment = this.studentAnswer.textAnswer;
      this.onAnswersForApprove.emit(this.answersForApprove);
  }

  approveQuestion(resultMark: number): void {
    if (resultMark+ '' === '') {
      this.toastr.error('The mark field is required');
      return;
    }

    const approvedData = [
      {
        aiMark: (this.highlights || this.insights) ? {
          highlights: this.highlights,
          insights: this.insights
        } : null,
        mark: resultMark,
        responseType: '',
        studentCumTestQuestionId: this.studentAnswer.id,
        teacherComment: this.studentAnswer.textAnswer,
        reviewState: 'APPROVED'
      }
    ]

    this.testService.approveAnswer(approvedData).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.onApproveOne.emit(approvedData[0]);
      this.toastr.success('The answer was approved successfully');
    });
  }

  sendTeacherMark(val: any) {
    this.answersForApprove.mark = val === '' ? 0 : val;
    this.answerData.mark = val === '' ? 0 : val;
    this.studentAnswer.resultMark = val === '' ? 0 : val;
    this.onAnswersForApprove.emit(this.answersForApprove);
  }

  isShowSelfMarkingTitle() {
    if ((this.answersForApprove.reviewState === 'APPROVED' || !this.isNotViewMode)
      && (!this.question.markType || this.question.markType === 'SELF_MARKED')
      // && (this.studentAnswer.textAnswer
      && ((this.answerSelfMarkSchemas.length > 0 && this.studentAnswer.selfMark && this.studentAnswer.selfMark.reasons.length > 0)
        || (this.answerSelfMarkSchemas.length > 0 && this.studentAnswer.selfMark && this.studentAnswer.selfMark.comment)
        || this.audioFile)) {
      return true;
    }

    if (this.answersForApprove.reviewState !== 'APPROVED' && this.isNotViewMode && (!this.question.markType || this.question.markType === 'SELF_MARKED')) {
      return true;
    }

    return false;
  }

  isShowAICommentsTitle() {
    if ((this.answersForApprove.reviewState === 'APPROVED' || !this.isNotViewMode)
      && this.question.markType === 'AI_MARKED'
      && this.question.answerType === this.qt.OPEN_ENDED
      // && (this.studentAnswer.textAnswer
      && (this.insights
        || this.highlights
      )) {
      return true;
    }

    if (this.answersForApprove.reviewState !== 'APPROVED'
      && this.isNotViewMode
      && this.question.markType === 'AI_MARKED'
      && this.question.answerType === this.qt.OPEN_ENDED) {
      return true;
    }

    return false;
  }

  isShowAITeacherCommentsTitle() {
    if ((this.answersForApprove.reviewState === 'APPROVED' || !this.isNotViewMode)
      && this.question.markType === 'AI_MARKED'
      && this.question.answerType === this.qt.OPEN_ENDED
      && this.studentAnswer.textAnswer) {
      return true;
    }

    if (this.answersForApprove.reviewState !== 'APPROVED'
      && this.isNotViewMode
      && this.question.markType === 'AI_MARKED'
      && this.question.answerType === this.qt.OPEN_ENDED) {
      return true;
    }

    if (this.answersForApprove.reviewState !== 'APPROVED'
      && this.isNotViewMode
      && this.question.markType === 'SELF_MARKED') {
      return true;
    }

    if ((this.answersForApprove.reviewState === 'APPROVED' || !this.isNotViewMode)
      && this.question.markType === 'SELF_MARKED'
      && this.studentAnswer.textAnswer) {
      return true;
    }

    return false;
  }

  isShowAITeacherComments() {
    if (this.answersForApprove.reviewState !== 'APPROVED'
      && this.isNotViewMode
      && this.question.markType === 'AI_MARKED'
      && this.question.answerType === this.qt.OPEN_ENDED) {
      return true;
    }

    if (this.answersForApprove.reviewState !== 'APPROVED'
      && this.isNotViewMode
      && this.question.markType === 'SELF_MARKED') {
      return true;
    }

    return false;
  }

  sendTeacherResponseType() {
    let query;
    if (this.isCumulativeTests) {
      query = this.testService.sendCTQuestionResponseType(this.responseType, this.studentCumTestQuestionId);
    } else {
      query = this.testService.sendSAQuestionResponseType(this.responseType, this.studentCumTestQuestionId);
    }
    query.pipe(takeUntil(this.destroy$)).subscribe(this.onSuccess, this.onErr);
  }

  sendTeacherAudio() {
    const f = new File([this.blob.blobFile], 'f.mp3');
    this.fileUploadService.upload(f, 'AUDIO').pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
      this.studentAnswer.audioAnswer = resp[0].uuidName;
      let query;
      if (this.isCumulativeTests) {
        query = this.testService.sendCTQuestionAudio(resp[0].uuidName, this.studentCumTestQuestionId);
      } else {
        query = this.testService.sendSAQuestionAudio(resp[0].uuidName, this.studentCumTestQuestionId);
      }
      query.pipe(takeUntil(this.destroy$)).subscribe(r => {
        this.studentAnswer.audioAnswer = resp[0].uuidName;
      });
    }, this.onErr);
  }

  onSuccess(resp) {
  }

  onErr(err) {
  }

  onComplete() {
    this.isFileLoading = false;
  }

  isStudentAnswerCorrect(i: number) {
    const correctAnswers = this.answerData.correctAnswer || [];
    return correctAnswers.includes(i);
  }

  isStudentAnswerIncorrect(i: number) {
    const correctAnswers = this.answerData.correctAnswer || [];
    const studentAnswers = this.answerData.question.answers[this.answerData.key].studentCorrectAnswerOptions || [];
    return studentAnswers.includes(i) && !correctAnswers.includes(i);
  }

  isFileTypeOfImage() {
    const name = this.studentAnswer?.studentAnswerFile?.toString()?.toLowerCase();
    return name?.includes('.jpg') || name?.includes('.png') || name?.includes('.jpeg');
  }

  private getFileIfExists() {
    if (this.studentAnswer.studentAnswerFile && this.isFileTypeOfImage()) {
      this.isFileLoading = true;
      this.fileUploadService.download(this.studentAnswer.studentAnswerFile, 'IMAGE').pipe(takeUntil(this.destroy$)).subscribe(
        (audio: any) => {
          this.isFileLoading = false;
          this.imagePreviewFile = 'data:image/png;base64,' + audio.data.bytes;
        },
        this.onErr,
        this.onComplete
      );
    }
    if (this.studentAnswer.studentAnswerFile && !this.isFileTypeOfImage()) {
      // TODO: remove?
      // const fileExtension = this.studentAnswer.studentAnswerFile.toString().split('.')[1];
      this.isFileLoading = true;
      this.fileUploadService.download(this.studentAnswer.studentAnswerFile, 'DOC').pipe(takeUntil(this.destroy$)).subscribe(
        (audio: any) => {
          this.isFileLoading = false;
          this.previewFile = `data:application/octet-stream;base64,` + audio.data.bytes;
        },
        this.onErr,
        this.onComplete
      );
    }
  }

  addTopicTestQuestion() {
    const testId = this.localStorageService.get('testId');
    if (this.nextSessionId && this.nextSessionId.tutorialSessionPlanId && this.selectedMainQuestionId && testId && !this.isCumulativeTests) {
      this.subscription.add(
        this.couService
          .addSelfAssessmentTestQuestion(this.nextSessionId.tutorialSessionPlanId, testId, this.selectedMainQuestionId)
          .subscribe(response => {
          }));
      this.couService.getTutorialSessionPlanInfo(this.nextSessionId.tutorialSessionId).pipe(takeUntil(this.destroy$)).subscribe(response => {
      });
    } else {
      if (this.nextSessionId && this.nextSessionId.tutorialSessionPlanId && this.selectedMainQuestionId && testId && this.isCumulativeTests) {
        this.subscription.add(
          this.couService.addTopicTestQuestion(this.nextSessionId.tutorialSessionPlanId, testId, this.selectedMainQuestionId).pipe(takeUntil(this.destroy$)).subscribe(response => {
          }));
      }
    }
  }
}
