<!-- Created by Maxim B. on 28/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="card-container" fxLayout="column" fxLayoutAlign="space-between stretch">
  <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="mat-h4">{{card?.title}}</h4>
    <div class="time-close">
      <mat-icon (click)="removeNotification.emit('')" [svgIcon]="'cancel'"></mat-icon>
      <span class="time">{{card?.date | date : 'HH:mm'}}</span>
    </div>
  </div>
  <div class="message">
    <p class="mat-body-2">{{card?.message}}</p>
  </div>
</div>
