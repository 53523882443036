import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-create-pastoral-care-user',
  templateUrl: './create-pastoral-care-user.component.html',
  styleUrls: ['./create-pastoral-care-user.component.scss']
})
export class CreatePastoralCareUserComponent implements OnInit {
  form: FormGroup;
  checkboxList = {
    options: [
      { label: 'Able to view student profile', value: 0 },
      { label: 'Able to view curriculums', value: 1 },
      { label: 'Able to view cources', value: 2 }
    ],
    value: [0, 1, 2],
    color: 'primary',
    type: 'checkbox',
    isMultipleSelect: true
  };

  constructor(private _fb: FormBuilder) {
    this.form = _fb.group({
      email: this._fb.control('', [Validators.required]),
      phone: this._fb.control('', [Validators.required]),
      personalID: this._fb.control('', [Validators.required]),
      country: this._fb.control('', [Validators.required]),
      school: this._fb.control('', [Validators.required]),
      firstName: this._fb.control('', [Validators.required]),
      lastName: this._fb.control('', [Validators.required]),
    })
  }

  ngOnInit(): void {
  }

  setProfilePhoto(photo: string) {}
  create() {}

  chooseFile() {}
  loadInfo() {}

}
