<!--
 Created by Dmytro Sav. on 5/21/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div fxLayout="column" fxLayoutAlign="center space-between" class="container">
  <div class="warn m-b-24">Delete this course?</div>

  <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="11px">
    <curr-button fxFlex="74px" variant="text" [mat-dialog-close]="data" (btnClick)="delete()">
      delete
    </curr-button>
    <curr-button fxFlex="93px" [mat-dialog-close]>cancel</curr-button>
  </div>
</div>
