/**
 * Created by Daniil V. on 01/06/21.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { debounceTime, first } from 'rxjs/operators';
import { ITag } from '../../../student/test.model';
import { ApplicationHttpClient } from '../../api/application-http-client';

@Component({
  selector: 'curr-chip-input-autocomplete',
  templateUrl: './chip-input-autocomplete.component.html',
  styleUrls: ['./chip-input-autocomplete.component.scss'],
})
export class ChipInputAutocompleteComponent implements OnInit {
  @Input() chips: ITag[];
  @Input() set suggestedChips(value: ITag[]) {
    this.autocompleteChips = value;
    this.chipsToRender = this.autocompleteChips?.filter(chip => {
      return !this.chips?.find(addedChip => addedChip.tag === chip.tag);
    })
  }
  @Output() onInputChange = new EventEmitter<string>();
  @Output() onChipsChange = new EventEmitter<ITag[]>();

  autocompleteChips: ITag[];
  chipsToRender: ITag[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  inputCtrl = new FormControl();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  get filteredAutocompleteChips(): ITag[] {
    return this.autocompleteChips?.filter(chip => {
      return !this.chips.find(addedChip => addedChip.tag === chip.tag);
    });
  }

  constructor(private httpClient: ApplicationHttpClient) {}

  ngOnInit() {
    this.inputCtrl.valueChanges.pipe(debounceTime(500)).subscribe(data => {
      this.onInputChange.emit(data);
    });
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    this.httpClient.post('/questionTags', { tag: value, id: null })
      .pipe(first())
      .subscribe((res: any) => {
        if (value) {
          if (!this.chips) {
            this.chips = [];
          }
          this.chips.push(res.data);
        }

        // Clear the input value
        event.input.value = '';

        this.inputCtrl.setValue(null);

        this.onChipsChange.emit(this.chips);
    })
  }

  remove(chip: ITag): void {
    const index = this.chips.indexOf(chip);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    this.onChipsChange.emit(this.chips);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.chips?.length) {
      this.chips = [];
    }
    this.chips.push(event.option.value);
    this.input.nativeElement.value = '';
    this.inputCtrl.setValue(null);

    this.onChipsChange.emit(this.chips);
  }
}
