/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */

import { Component, Input } from '@angular/core';
import {ChartConfiguration, ChartType} from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { COLORS } from '../consts';
import { ITestStatistics } from '../../questions-page/questions-page.interface';

@Component({
  selector: 'curr-question-statistic',
  templateUrl: './question-statistic.component.html',
  styleUrls: ['./question-statistic.component.scss']
})
export class QuestionStatisticComponent {
  @Input() set data(value: ITestStatistics) {
    if (value) {
      this.statisticData = value;

      this.doughnutChartData = {
        labels: ['Correct Answers', 'Partially Correct Answers', 'Incorrect Answers'],
        datasets: [
          {
            data: [Number(value.correctAnswers), Number(value.incorrectAnswers), Number(value.partiallyCorrectAnswers)],
            backgroundColor: ['#4DA3B1', '#83CCA5', '#A2D9A3'],
            hoverBackgroundColor: ['#4DA3B1', '#83CCA5', '#A2D9A3'],
            borderColor: ['#4DA3B1', '#83CCA5', '#A2D9A3'],
            hoverBorderColor: ['#4DA3B1', '#83CCA5', '#A2D9A3'],
            borderWidth: 1
          },
        ],
      };
    }
  }

  type: ChartType = 'doughnut';

  chartData: number[];
  labels: string[];
  doughnutChartData;
  statisticData: ITestStatistics;

  public doughnutChartType: ChartConfiguration<'doughnut'>['type'] = 'doughnut';
  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
    responsive: true,
    spacing: 2,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        align: 'center',
        color: '#000A12',
        font: {
          weight: 500,
          size: 10
        }
      },
    },
    cutout: '60%',
  };

  constructor() {}

  scrollToSection(section: string) {
    const element = document.querySelector(`[data-titleName="${section}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
