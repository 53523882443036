<!--
 Created by Yaroslav S. on 17/04/24.
 Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="loader-with-text">
  <div class="loader-with-text-inside">
    <div class="loader-title">{{ title }}</div>
    <div class="loader-text">{{ text }}</div>
    <mat-spinner [strokeWidth]="5" [diameter]="46"></mat-spinner>
  </div>
</div>
