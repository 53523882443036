/*
 * Created by Dmytro Sav. on 5/20/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Course } from '../course';
import { getRestApiProvider, Paginated, RestApiService } from '../../../shared/api/rest-api.service';
import { CoursesService } from '../courses.service';
import { CurriculumManagementModel } from '../../curriculum-managment/curriculum-management.model';
import { Options } from '../../../shared/components/dropdown/dropdown.component';
import { of } from 'rxjs';

interface DialogData {
  courseId: any;
  form: FormGroup;
  title: string;
}

@Component({
  selector: 'curr-create-course-popup',
  templateUrl: './create-course-popup.component.html',
  styleUrls: ['./create-course-popup.component.scss'],
  providers: [
    getRestApiProvider<Course>('schoolYearCourses/unassignedCourses', 'unassignedCourses'),
    getRestApiProvider<CurriculumManagementModel>('curriculums/management', 'curriculumsManagement')
  ]
})
export class CreateCoursePopupComponent implements OnInit {
  courseId = '';
  filteredCourses: Course[] = [];
  form: FormGroup;
  title: string;
  isEdited = false;
  activeCourseId: string;
  isCreatedCourse = true;

  curriculumId: string;
  curriculums: Options[];

  unassignedCourses: Course[];
  selectedCourse: Course;

  constructor(
    public dialogRef: MatDialogRef<CreateCoursePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject('unassignedCourses') private unassignedCoursesService: RestApiService<Course>,
    @Inject('curriculumsManagement') private curriculumManagementService: RestApiService<CurriculumManagementModel>,
    private coursesService: CoursesService
  ) {
  }

  ngOnInit() {
    this.form = this.data.form;
    if (this.data.title) {
      this.title = this.data.title;
      this.isCreatedCourse = true;
      this.isEdited = true;
    } else {
      this.title = 'Create course';
      this.unassignedCoursesService.getAll().subscribe(res => {
        this.unassignedCourses = res;
        this.filteredCourses = res;
      });
      this.curriculumManagementService.getAllPaginated(of(new Paginated(100, 0))).subscribe(res => {
        this.curriculums = res.map(curriculum => {
          return { value: curriculum.curriculum_id, label: curriculum.curriculum_name };
        });
        this.curriculumId = res[0].curriculum_id;
      });
    }
    if (this.data.courseId) {
    this.courseId = this.data.courseId;
    }
  }

  getCourseById() {
    if (!this.isCreatedCourse) {
      if (this.courseId && this.courseId !== '') {
        this.filteredCourses = this.unassignedCourses.filter(course => {
          return course.id.toString().includes(this.courseId);
        });
      } else {
        this.filteredCourses = this.unassignedCourses;
      }
    }
  }


  addCourse() {
    // this.data.form = this.form;
    if (!this.isCreatedCourse) {
      this.assignCourse();
    } else {
      this.createCourse();
    }
  }

  assignCourse() {
    this.coursesService.assignCourse(this.selectedCourse.id, this.curriculumId).subscribe(res => {
    });
  }

  createCourse() {
    this.coursesService.createCourse(this.courseId, this.curriculumId).subscribe(res => {
    });
  }


}
