import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fade', [
  transition('void => *', [style({ opacity: 0 }), animate('750ms ease-in', style({ opacity: 1 }))])
]);


export const fadeAnimationSequence = trigger('appearingItems', [
    transition('void => *', [
      query('.fade', [
        style({
          opacity: 0,
        }),
        stagger(200, [
          animate(
            '750ms ease-in',
            style({ opacity: 1 })
          ),
        ]),
      ]),
    ]),
]);
