import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileUploadService } from '../edit-question-block/file-upload.service';

@Component({
  selector: 'curr-hint',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss']
})
export class HintComponent implements OnInit, OnDestroy {
  hint;
  explanation;
  img;
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private fileUploadService: FileUploadService,
    private dialogRef: MatDialogRef<HintComponent>, @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit(): void {
    this.checkIOs();
    this.hint = this.data.hint;
    this.explanation = this.data.explanation;
    this.getQuestionDescription();
  }

  checkIOs(){
      document.documentElement.style.height = `initial`;
      setTimeout(() => {
        document.documentElement.style.height = `100%`;
        setTimeout(() => {
          window.scrollTo(0, 1);
        }, 500);
      }, 500);
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getQuestionDescription() {
    if (!this.explanation) {
      if (this.hint.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.hint);
        this.uploadHint(imageKeyValue, this.hint);
      }
      return this.hint;
    } else if (!this.hint) {
      if (this.explanation.includes('<img src')) {
        const imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.explanation);
        this.uploadExplanation(imageKeyValue, this.explanation);
      }
    }
  }

  private async uploadHint(imageKeyValue: any, hint) {

    let loadHint = hint;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          loadHint = loadHint.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.hint = loadHint;
      }
    });
  }

  private async uploadExplanation(imageKeyValue: any, explanation) {

    let loadExplanation = explanation;
    const observersObj: { [key: string]: Observable<any> } = {};
    for (const key of Object.keys(imageKeyValue)) {
      observersObj[key] = this.fileUploadService.download(key, 'IMAGE');
    }
    forkJoin(observersObj).pipe(takeUntil(this.unsubscribe)).subscribe({
      next: (res: any) => {
        for (const [key, value] of Object.entries(res)) {
          const bytes = (value as any)?.data?.bytes;
          imageKeyValue[key] = 'data:image/png;base64,' + bytes;
          loadExplanation = loadExplanation.replace(key, imageKeyValue[key]);
        }
      },
      complete: () => {
        this.explanation = loadExplanation;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
