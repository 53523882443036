/**
 * Created by Volodymyr Mak. on 4/30/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
export enum NoteType {
  VIDEO = 'video',
  IMAGE = 'image',
  DOCUMENT = 'document',
  ARTICLE = 'article'
}

export interface Note {
  type?: NoteType
  date: Date,
  text: string,
  editMode: boolean;
}

export interface SectionNote {
  title: string,
  notes: Note[]
}

export interface TopicNotes {
  topicName: string,
  video: SectionNote,
  image: SectionNote
  document: SectionNote,
  article: SectionNote
}

export class MockDataGenerator {
  static generateItems(itemNumber: number, item: any): any[] {
    const resultList = [];

    // TODO: Avoid while loops
    while (itemNumber > 0) {
      const copy = JSON.parse(JSON.stringify(item));
      resultList.push(copy);
      itemNumber--
    }
    return resultList;

    // TODO: My suggestion to use
    // return Array.from({length: itemNumber}, () => JSON.parse(JSON.stringify(item)));
  }
}

class MockSectionNoteModel implements SectionNote {
  notes: any[];

  constructor(public title: string, noteNumber) {
    this.notes = MockDataGenerator.generateItems(noteNumber,
      {
        date: new Date(),
        text: 'Some note here. Some note here. Some note here. Some note here. Some note here. Some note here. Some note here',
        editMode: false
      }
    )
  }
}

export class MockTopicNotesModel implements TopicNotes {
  video = new MockSectionNoteModel('Black Holes Explained – From Birth to Death ', 5);
  image = new MockSectionNoteModel('Black Holes Explained – From Birth to Death ', 3);
  document = new MockSectionNoteModel('Black Holes Explained – From Birth to Death ', 6);
  article = new MockSectionNoteModel('Black Holes Explained – From Birth to Death ', 8);

  constructor(public topicName: string) {
  }
}
