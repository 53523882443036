<!--
 Created by Daniil Vat. on 13/4/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->

<div class="assessment-results-chart">
  <div class="no-chart-data" *ngIf="isChartBlur">No data to show.</div>
  <div [ngClass]="{'blur-section': isChartBlur}">
    <div class="white-section"></div>
    <canvas
      baseChart
      class="assessment-chart"
      [datasets]="lineChartData"
      [labels]="lineChartLabels"
      [options]="lineChartOptions"
      [legend]="lineChartLegend"
      [type]="lineChartType"
    ></canvas>
  </div>
</div>
