import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gender',
  pure: false,
})
export class GenderPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return value;
    }
    return value.toLowerCase().replace(/_/g, ' ');
  }

}
