import { NgModule } from '@angular/core';
import { QuestionWidgetDirective } from 'src/app/teacher/shared/questions-widget/question-widget.directive';

@NgModule({
  exports: [
    QuestionWidgetDirective
  ],
  declarations: [QuestionWidgetDirective]
})
export class QuestionWidgetModule {}
