<!--
  ~ Created by Yaroslav S. on 02/05/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="test-question-view" *ngIf="questionData">
  <curr-test-question-item
    [question]="questionData"
    [topics]="topics"
    [showEdit]="true"
    [isFirstLevel]="true"
    [questionIndexes]="questionData.children && questionData.children.length > 0 ? [0] : []"
    (onEditQuestion)="editQuestion()"
    (onChangeMarkType)="setQuestionsMarkType($event)"
  ></curr-test-question-item>

  <ng-container *ngIf="questionData.children && questionData.children.length > 0">
    <div class="second-question-level" *ngFor="let secondLevelQuestion of questionData.children; let i = index">
      <curr-test-question-item
        [question]="secondLevelQuestion"
        [topics]="topics"
        [showEdit]="false"
        [isFirstLevel]="false"
        [questionIndexes]="[1, i]"
        (onChangeMarkType)="setQuestionsMarkType($event)"
      ></curr-test-question-item>

      <ng-container *ngIf="secondLevelQuestion.children && secondLevelQuestion.children.length > 0">
        <div class="third-question-level" *ngFor="let thirdLevelQuestion of secondLevelQuestion.children; let j = index">
          <curr-test-question-item
            [question]="thirdLevelQuestion"
            [topics]="topics"
            [showEdit]="false"
            [isFirstLevel]="false"
            [questionIndexes]="[1, i, j]"
            (onChangeMarkType)="setQuestionsMarkType($event)"
          ></curr-test-question-item>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
