<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="curr-student-profile-subject-table">
  <div class="header-container main-header m-b-0 m-t-24" fxLayout="row"
  fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-b-16 m-l-16">Subjects</h2>
  </div>

  <mat-divider class="m-b-16 full-width"></mat-divider>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Subject</th>
        <td mat-cell *matCellDef="let element">
          <div>{{ element.subject }}</div>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 1">
        <ng-container *ngIf="item == 'predicted_grade'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
            {{ 'dropdownItemLabels.' + item | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="userRole !== userRoles.Teacher">{{ element.predictedGrade }}</ng-container>
            <a
              *ngIf="userRole === userRoles.Teacher"
              [matMenuTriggerFor]="predictedGradeMenu"
              (menuClosed)="closePredictedGradeMenu()"
              class="user-name"
              (click)="clickPredictedGrade($event, element)"
            >
              <p>{{ element.predictedGrade }}</p>
              <mat-icon
                *ngIf="isPredictedGradeMenuOpen && clickedPendingUser == element.subject"
                svgIcon="up"
                class="dd-icon"
              ></mat-icon>
              <mat-icon
                *ngIf="!isPredictedGradeMenuOpen || clickedPendingUser != element.subject"
                svgIcon="down"
                class="dd-icon"
              ></mat-icon>
            </a>
            <mat-menu #predictedGradeMenu="matMenu" xPosition="after" class="meeting-menu">
              <ng-container *ngFor="let b of ddOptionsPredictedGrade">
                <button
                  mat-menu-item
                  class="icon-tab-menu-item mat-body-1"
                  fxLayoutAlign="start center"
                  [class.active]="b.value === element.predictedGrade"
                  (click)="setPredictedGrade(b.value, element)"
                >
                  <mat-icon *ngIf="b.value === element.predictedGrade" svgIcon="icon-done"
                            class="m-r-0 grade-icon"></mat-icon>
                  {{ b.label }}
                </button>
                <mat-divider></mat-divider>
              </ng-container>
            </mat-menu>
          </td>
        </ng-container>

        <ng-container *ngIf="item == 'avg_grade'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
            {{ 'dropdownItemLabels.' + item | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.avgGrade }}
          </td>
        </ng-container>

        <ng-container *ngIf="item == 'performance'">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">
            {{ 'dropdownItemLabels.' + item | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            <curr-progress-bar-gradient
              [benchmark]="element.predicted_grade"
              [averageGrade]="element.avg_grade"
              [progress]="element.performance">
            </curr-progress-bar-gradient>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
