/**
 * Created by Yaroslav S. on 02/05/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { CdkDragMove, CdkDragEnd } from '@angular/cdk/drag-drop';

@Component({
  selector: 'curr-movable-dialog',
  templateUrl: './movable-dialog.component.html',
  styleUrls: ['./movable-dialog.component.scss'],
})
export class MovableDialogComponent implements OnInit, AfterViewInit {
  @Input()
  originalQuestionContent: string;

  @Input()
  originalMarkSchemeContent: string;

  @Input()
  stepNumber: number;

  @Output()
  onCloseDialog = new EventEmitter<void>();

  @ViewChild('movableDialog') movableDialog!: ElementRef;
  @ViewChild('resizer') resizer!: ElementRef;

  minWidth: number = 465;
  minHeight: number = 492;

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.initResize();
  }

  onDragMoved(event: CdkDragMove): void {}

  onDragEnded(event: CdkDragEnd): void {}

  initResize() {
    const resizer = this.resizer.nativeElement;
    const movableDialog = this.movableDialog.nativeElement;

    let startX: number, startY: number, startWidth: number, startHeight: number;

    const doDrag = (e: MouseEvent) => {
      const newWidth = startWidth + e.clientX - startX;
      const newHeight = startHeight + e.clientY - startY;

      if (newWidth > this.minWidth) {
        movableDialog.style.width = newWidth + 'px';
      }

      if (newHeight > this.minHeight) {
        movableDialog.style.height = newHeight + 'px';
      }
    };

    const stopDrag = () => {
      document.documentElement.removeEventListener('mousemove', doDrag, false);
      document.documentElement.removeEventListener('mouseup', stopDrag, false);
    };

    const initDrag = (e: MouseEvent) => {
      startX = e.clientX;
      startY = e.clientY;
      startWidth = parseInt(document.defaultView.getComputedStyle(movableDialog).width, 10);
      startHeight = parseInt(document.defaultView.getComputedStyle(movableDialog).height, 10);
      document.documentElement.addEventListener('mousemove', doDrag, false);
      document.documentElement.addEventListener('mouseup', stopDrag, false);
    };

    resizer.addEventListener('mousedown', initDrag, false);
  }
}
