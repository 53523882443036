<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-8">
  <h2 class="mat-h2 m-b-0" fxFlex>{{video.title}}</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div class="video" [id]="video.containerId"></div>
<div fxLayout="row full-width" fxLayoutAlign="space-between start" class="m-b-16">
  <span class="mat-body-1"
        [class.collapsed-desc]="!isVideoDescVisible" fxFlex>{{video.learning_objective}}</span>
  <div class="arrow-container description" fxFlex="initial">
    <mat-icon class="video-desc" (click)="expandDesc()">
      {{ !isVideoDescVisible ? 'arrow_drop_down' : 'arrow_drop_up' }}
    </mat-icon>
  </div>
</div>
