<!-- Created by Alex Poh. on 15/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="card-container">
  <mat-card>
    <div fxLayout="row" fxLayoutAlign="space-between">
      <mat-list class="m-t-64" fxFlex="1 1 450px">
        <ng-container *ngFor="let group of paper?.topicGroups">
          <ng-container *ngFor="let topic of group.topics; let i = index">
            <mat-list-item
              class="topic-selector"
              [ngClass]="topic === selectedTopic ? 'glow-secondary' : ''"
              (click)="selectTopic(topic, i)"
            >
              <div class="mat-body-2">{{ topic.title }}</div>
            </mat-list-item>
            <mat-divider *ngIf="topic !== selectedTopic" [inset]="true"></mat-divider>
          </ng-container>
          <mat-list-item>
            <div class="mat-body-2 group-name">{{ group.name }}</div>
          </mat-list-item>
          <mat-divider [inset]="true"></mat-divider>
        </ng-container>
      </mat-list>
      <div class="chart-container" fxFlex="1300px">
        <svg #chart></svg>
      </div>
    </div>
  </mat-card>
</div>
