<!--
 Created by Daniil Vat. on 28/3/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<curr-loader [isLoading]="!receivedData && !displayedData" [diameter]="48"></curr-loader>
<div @fade fxLayout="row" class="hardest-questions" *ngIf="this.displayedData">
<!--  <div fxFlex="26%">-->
<!--    <curr-questions-pie-chart [data]="receivedData"></curr-questions-pie-chart>-->
<!--  </div>-->
  <div fxLayout="column" fxFlex="100%">
    <div class="list">
      <curr-colored-list-item *ngFor="let item of displayedData; let i = index" [text]="item.questionMessage"
        [index]="i">
      </curr-colored-list-item>
    </div>
    <div fxLayout="row" *ngIf="receivedData?.length >= limit && (displayedData?.length < receivedData?.length)">
      <curr-button size="big"  class="load-more-btn" variant="outlined" (btnClick)="showMore()">load more</curr-button>
    </div>
  </div>
</div>
