/*
 * Created by Dmytro Sav. on 5/12/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { CreateSubjectPopupComponent } from 'src/app/shared/components/create-subject-popup/create-subject-popup.component';

@Component({
  selector: 'curr-create-teacher-page',
  templateUrl: './create-teacher-page.component.html',
  styleUrls: ['./create-teacher-page.component.scss']
})
export class CreateTeacherPageComponent implements OnInit {
  form: FormGroup;
  courses = [];
  notes = [];
  path = [
    {
      label: 'Home',
      url: ''
    },
    {
      label: 'Create New Teacher',
      url: ''
    }
  ];
  checkboxList = {
    options: [
      { label: 'Allow the teacher to view their students grades in other subject', value: 0 },
      { label: 'hide from students how their compare their peers academically', value: 1 },
      { label: 'Allow the teacher to edit his/her class notes', value: 2 }
    ],
    value: [0, 1, 2],
    color: 'primary',
    type: 'checkbox',
    isMultipleSelect: true
  };

  constructor(public dialog: MatDialog, private _fb: FormBuilder) {
    this.form = _fb.group({
      email: this._fb.control('', [Validators.required]),
      phone: this._fb.control('', [Validators.required]),
      personalID: this._fb.control('', [Validators.required]),
      country: this._fb.control('', [Validators.required]),
      school: this._fb.control('', [Validators.required]),
      firstName: this._fb.control('', [Validators.required]),
      lastName: this._fb.control('', [Validators.required])
    });
  }

  ngOnInit() {}

  importTimetable() {
    this.dialog.open(CreateSubjectPopupComponent, {
      width: '1069px',
      data: {
        importType: 'timetable'
      }
    });
  }

  create() {}

  setProfilePhoto(photo: string) {}
}
