/**
 * Created by Alex Poh. on 03/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { navigationAnimation } from '../../animations/navigation.animation';
import { NavigationAnimationService } from '../../animations/navigation-animation.service';
import { NavigationStart, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'curr-user-root',
  templateUrl: './user-root.component.html',
  styleUrls: ['./user-root.component.scss'],
  animations: [navigationAnimation]
})
export class UserRootComponent implements OnInit, OnDestroy {
  url: string = '';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private navigationAnimationService: NavigationAnimationService, private router: Router) {}

  animationData: { animationState: string };

  ngOnInit(): void {
    this.url = this.router.url;
    this.router.events
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(res => {
      if (res instanceof NavigationStart) {
        this.url = res.url;
      }
    })
    this.animationData = this.navigationAnimationService.init();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.navigationAnimationService.destroy();
  }
}
