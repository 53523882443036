/*
 * Created by Volodymyr Mak. on 6/5/2020.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'curr-progress-bar-gradient',
  templateUrl: './progress-bar-gradient.component.html',
  styleUrls: ['./progress-bar-gradient.component.scss']
})
export class ProgressBarGradientComponent {
  @Input() benchmark: string;
  @Input() averageGrade: string;
  @Input() progress: number;
  
  private gradeOrder = ['A', 'B', 'C', 'D', 'E', 'F'];

  getProgress(): number {
    if (!this.progress) return 0;
    
    const benchmarkIndex = this.gradeOrder.indexOf(this.benchmark);
    const gradeIndex = this.gradeOrder.indexOf(this.averageGrade);

    if (gradeIndex === -1 || benchmarkIndex === -1) return 0;

    const step = 17;
    const difference = gradeIndex - benchmarkIndex;

    if (difference <= 0) return 100;

    return Math.max(100 - difference * step, 17);
  }

  getPerformanceClass(): string {
    const progress = this.getProgress();

    if (progress === 100) return 'green';
    if (progress >= 80) return 'yellow';
    return 'red';
  }
}
