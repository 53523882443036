import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'curr-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent {
  @Input()
  totalResults: number;

  @Input()
  pageSize: number;

  @Input()
  currentPageIndex: number;

  @Input()
  showingText: string;

  @Output()
  changePage = new EventEmitter<number>();

  maxIndex: number;

  getTotalPages(): number {
    return Math.ceil(this.totalResults / this.pageSize);
  }

  getRangeLabel(currentPageIndex: number, pageSize: number, totalResults: number): string {
    if (totalResults === 0 || pageSize === 0) {
      return `0 of ${totalResults}`;
    }
    const startIndex = currentPageIndex * pageSize;
    this.maxIndex = startIndex < totalResults ? Math.min(startIndex + pageSize, totalResults) : startIndex + pageSize;
    return `Showing ${startIndex + 1} to ${this.maxIndex} of ${totalResults} ${this.showingText}`;
  }

  getPageNumbers(): number[] {
    const totalPages = this.getTotalPages();
    const currentPage = this.currentPageIndex;
    const maxPagesToShow = 5;

    if (totalPages <= maxPagesToShow) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    let pages = [];
    let nextFirstPage = 2;
    if (totalPages > 3) {
      pages = [1, 2, 3];
      nextFirstPage = 4;
    } else {
      pages = [1];
    }

    if (currentPage > 3) {
      pages.push('...');
    }

    const startPage = Math.max(nextFirstPage, currentPage - 1);
    const endPage = Math.min(totalPages - 2, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (currentPage < totalPages - 3) {
      pages.push('...');
    }

    pages.push(totalPages - 1);
    pages.push(totalPages);
    return pages;
  }

  previousPage() {
    if (this.currentPageIndex === 0) return;
    this.changePage.emit(this.currentPageIndex - 1);
  }

  nextPage() {
    if (this.currentPageIndex === this.maxIndex) return;
    this.changePage.emit(this.currentPageIndex + 1);
  }

  setPage(page: number) {
    this.changePage.emit(page - 1);
  }
}
