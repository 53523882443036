/**
 * Created by Alex Poh. on 22/06/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { LearningObjective } from '../../../shared-pages/subject-details/api-subject-details';
import { FileUploadService } from '../edit-question-block/file-upload.service';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { EventObj } from '@tinymce/tinymce-angular/editor/Events';

@Component({
  selector: 'curr-tiny-editor-disabled',
  templateUrl: './tiny-editor-disabled.component.html',
  styleUrls: ['./tiny-editor-disabled.component.scss']
})
export class TinyEditorDisabledComponent implements OnChanges, OnInit, AfterContentInit {
  @Input() disabled: boolean;
  @Input() options: any;
  @Input() value: string;
  @Input() formGroup: FormGroup = this.fb.group({});
  @Input() controlName = '';
  @Input() topic: LearningObjective;
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFocusOut: EventEmitter<EventObj<FocusEvent>> = new EventEmitter<EventObj<FocusEvent>>();

  valueForPreview = '';
  imageKeyValue = {};
  isLoading = false;
  isFirstTime = true;

  constructor(private sanitizer: DomSanitizer,
              private fb: FormBuilder,
              private cdr: ChangeDetectorRef,
              private fileUploadService: FileUploadService) {
  }

  ngOnChanges(): void {
    if (!this.value && this.formGroup && this.controlName) {
      this.value = this.formGroup.controls[this.controlName].value;
    }
    this.imageKeyValue = this.fileUploadService.getAllImageKeysFromNote(this.value);
    if (!this.isFirstTime) {
      this.uploadImagesAndChangeValueForTinyEditor();
      this.showImages();
    } else {
      this.isFirstTime = false;
    }
  }

  ngOnInit(): void {
    this.uploadImagesAndChangeValueForTinyEditor();
  }

  ngAfterContentInit(): void {
    this.showImages();
  }

  showImages() {
    timer(2500)
      .pipe(take(1))
      .subscribe(resp => {
        this.getImages();
      });
  }

  private async uploadImagesAndChangeValueForTinyEditor() {
    const keysCount = Object.keys(this.imageKeyValue).length;
    this.isLoading = !!keysCount;
    this.valueForPreview = this.value;
    // tslint:disable-next-line:forin
    for (const key in this.imageKeyValue) {
      // @ts-ignore
      const bytes = (await this.fileUploadService.download(key, 'IMAGE').toPromise())?.data?.bytes;
      this.imageKeyValue[key] = 'data:image/png;base64,' + bytes;
      this.valueForPreview = this.valueForPreview.replace(key, this.imageKeyValue[key]);
      this.isLoading = false;
      this.cdr.detectChanges();
    }
  }

  getImages() {
    const images = document.getElementsByTagName('img');
    if (!images.length) {
      return;
    }
    // @ts-ignore
    for (const im of images) {
      const source = im.attributes?.src?.nodeValue;
      if (this.imageKeyValue[source]) {
        im.src = this.imageKeyValue[source];
      }
    }
  }

  sanitize(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
