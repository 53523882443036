<!--
 Created by Yaroslav S. on 10/07/2024
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="mathpix-section">
  <div class="mathpix-section-title">Mathpix OCR</div>

  <div class="form-fields">
    <div class="form-fields-label">App ID</div>
    <curr-input
      [formGroup]="form"
      controlName="mathPixAppId"
      class="full-width form-field"
      [errorMessage]="form.controls['mathPixAppId'].hasError('required') ? 'App ID is required' : ''"
    ></curr-input>
  </div>

  <div class="form-fields">
    <div class="form-fields-label">Secret Key</div>
    <curr-input
      [formGroup]="form"
      controlName="mathPixApiKey"
      class="full-width form-field"
      [errorMessage]="form.controls['mathPixApiKey'].hasError('required') ? 'Secret Key is required' : ''"
    ></curr-input>
  </div>

  <curr-credits-error *ngIf="showError"></curr-credits-error>

  <div class="mathpix-section-subtitle">Pricing for Organization Plan</div>

  <div class="mathpix-section-table">
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">Feature</div>
      <div class="row-item row-item-title">$99.90/year</div>
      <div class="row-item row-item-title">Extra Usage</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item">Snip Usage</div>
      <div class="row-item">60 000</div>
      <div class="row-item">$0,002</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item">PDF Usage</div>
      <div class="row-item">6 000</div>
      <div class="row-item">$0,01</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item">Multiple User Support</div>
      <div class="row-item">+</div>
      <div class="row-item">Customized plan</div>
    </div>
  </div>

  <div class="mathpix-section-table-mobile">
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">Feature</div>
      <div class="row-item">Snip Usage</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">$99.90/year</div>
      <div class="row-item">60 000</div>
    </div>
    <div class="mathpix-section-table-row last-row">
      <div class="row-item row-item-title">Extra Usage</div>
      <div class="row-item">$0,002</div>
    </div>

    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">Feature</div>
      <div class="row-item">PDF Usage</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">$99.90/year</div>
      <div class="row-item">6 000</div>
    </div>
    <div class="mathpix-section-table-row last-row">
      <div class="row-item row-item-title">Extra Usage</div>
      <div class="row-item">$0,01</div>
    </div>

    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">Feature</div>
      <div class="row-item">Multiple User Support</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">$99.90/year</div>
      <div class="row-item">+</div>
    </div>
    <div class="mathpix-section-table-row">
      <div class="row-item row-item-title">Extra Usage</div>
      <div class="row-item">Customized plan</div>
    </div>
  </div>

  <div class="mathpix-section-note">
    Note! Prices are for reference only and are not updated in real time. Please check the Pricing section on
    mathpix.com for the most accurate pricing.
  </div>

  <div class="mathpix-section-note m-b-0">Contact Mathpix to get a better price for high-volume OCR needs.</div>

  <div class="save-actions">
    <curr-button
      variant="contained"
      [isDisabled]="form.invalid || (!mathPixAppId.dirty && !mathPixApiKey.dirty)"
      (btnClick)="saveApiKey()"
      size="big"
      >SAVE</curr-button
    >
  </div>
</div>
