<!-- Created by Max Bon. on 01/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="header-container main-header m-b-0 m-t-16" fxLayout="row" fxLayoutAlign="space-between center">
  <h2 class="mat-h2 m-b-16 m-l-16">Test Archive</h2>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="m-b-8">
    <curr-button [variant]="'outlined'" color="primary" (btnClick)="selectType('all')" [isHighlighted]="type === 'all'">
      ALL
    </curr-button>
    <curr-button [variant]="'outlined'" color="primary" (btnClick)="selectType('CUMULATIVE TEST')"
      [isHighlighted]="type === 'CUMULATIVE TEST'">
      Topic Tests
    </curr-button>
    <curr-button [variant]="'outlined'" color="primary" (btnClick)="selectType('SELF ASSESSMENT')"
      [isHighlighted]="type === 'SELF ASSESSMENT'">
      SELF ASSESSMENT
    </curr-button>
  </div>
</div>

<mat-divider class="m-b-16 full-width"></mat-divider>
<mat-card>

  <ng-container *ngFor="let test of testArchive; let i = index">
    <ng-container *ngIf="inIndexBoundaries(i)">
      <ng-container *ngIf="type == 'all'">
        <ng-container *ngTemplateOutlet="tcard;context:{test:test, i:i}"></ng-container>
      </ng-container>
      <ng-container *ngIf="test.testType == type && type != 'all'">
        <ng-container *ngTemplateOutlet="tcard;context:{test:test, i:i}"></ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <div fxLayout="row" fxLayoutAlign="space-between center" class="m-t-16 m-b-16">
    <span class="mat-body-1 stat">Showing 1 to 5 of {{testArchive.length}} tests</span>
    <div class="pagi" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="24px">
      <mat-icon svgIcon="arrow-left" (click)="setPagination(currPagiIndex-1)"></mat-icon>
      <span *ngFor="let item of paginationArr;let i = index" (click)="setPagination(i)"
        [class.selected-index]="currPagiIndex == i">
        {{i + 1}}</span>
      <mat-icon svgIcon="arrow-right" (click)="setPagination(currPagiIndex+1)"></mat-icon>
    </div>
  </div>
</mat-card>


<ng-template #tcard let-test="test" let-i="i">
  <div class="container m-b-8">
    <div class="list main">
      <div class="wrapper" fxLayout="row" fxLayoutAlign="space-between center" (click)="goToLearningResults(test)">
        <div class="text-wrapper" fxLayout="column" fxLayoutAlign="center" fxFlex="60%">
          <div fxLayout="row" class="m-b-8">
            <span class="date-type m-r-4">{{test.testType}}</span>
            <span class="date-type"> - {{ test.testDate | date : 'dd/MM/yyyy'}}</span>
          </div>
          <div class="mat-h1 m-b-0 name desktop">{{ test?.title }}</div>
          <div class="mat-h1 m-b-0 name mobile">{{ test?.title }}</div>

          <div class="status mat-body-2 m-t-8" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start center"
            fxLayoutAlign.xs="start start">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span class="m-r-4">Subject:</span>
              <div class="value">{{ test.subject }}</div>
            </div>
          </div>
        </div>
        <div class="action-btn" fxLayout="column" fxLayoutAlign="center end" fxFlex.xs fxFlex="254px" fxLayoutGap="4px">
          <curr-button *ngIf="test.status === 'REVIEW'" (btnClick)="reviewAnswers(test)">REVIEW ANSWERS</curr-button>
          <span class="test-status-lbl" *ngIf="test.status === 'PENDING'">Result pending</span>
          <span class="test-status-lbl" *ngIf="test.status === 'NOT_PASSED'">Not passed</span>
          <span class="test-status-lbl" *ngIf="test.status === 'RESULT'">Result: {{test.testResult}}</span>
        </div>
      </div>
    </div>
  </div>

</ng-template>