<!-- Created by Maxim B. on 11/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="container">
  <div class="m-b-40 side-m-8">
    <curr-breadcrumb position="start" [path]="path"></curr-breadcrumb>
  </div>

  <div class="tabs-container side-m-8" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-tab-group
      class="custom-ink-size"
      [dynamicHeight]="false"
      fxFlex="80"
      mat-align-tabs="start"
      animationDuration="0ms"
      (selectedIndexChange)="tabSelectedIndexChange($event)"
      [selectedIndex]="selectedTabIndex"
    >
      <mat-tab *ngFor="let tab of customTabs; let i = index">
        <ng-template mat-tab-label>
          <span>{{ tab.name }}</span>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <!--    <button class="create-table-btn" mat-button (click)="createNewTab()">-->
    <!--      <mat-icon [svgIcon]="'plus'"></mat-icon>-->
    <!--      ADD TAB-->
    <!--    </button>-->
  </div>

  <div class="results-header-container m-t-16 side-m-8 m-b-24" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2 m-t-8">{{dataFromApi?.numberOfElements || 0}} of {{dataFromApi?.totalElements || 0}} Users</h2>
    <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="60">
      <!--      <curr-input [isBordered]="false" placeholder="Search" fxFlex="250px" (keyup)="filterEntries($event)"></curr-input>-->
      <!--      <mat-icon [svgIcon]="'search_black'" class="m-r-24 search"></mat-icon>-->
      <!--      <curr-button-->
      <!--        variant="outlined"-->
      <!--        (menuClosed)="isExportMenuOpen = false"-->
      <!--        class="m-r-8 curr-button"-->
      <!--        [matMenuTriggerFor]="exportMenu"-->
      <!--        (click)="isExportMenuOpen = true"-->
      <!--        fxFlex="113px"-->
      <!--        fxLayout="row"-->
      <!--        fxLayoutAlign="center center"-->
      <!--        [isHighlighted]="isExportMenuOpen"-->
      <!--      >-->
      <!--        EXPORT-->
      <!--        <mat-icon svgIcon="down" class="btn-icon-arrow " *ngIf="!isExportMenuOpen"></mat-icon>-->
      <!--        <mat-icon svgIcon="up" class="btn-icon-arrow " *ngIf="isExportMenuOpen"></mat-icon>-->
      <!--      </curr-button>-->
      <!--      <mat-menu #exportMenu="matMenu" class="custom-menu">-->
      <!--        <button mat-menu-item (click)="importUsers('all')">All users List</button>-->
      <!--        <button mat-menu-item (click)="importUsers('filtered')">Filtered User List</button>-->
      <!--      </mat-menu>-->

      <!--      <curr-button-->
      <!--        variant="outlined"-->
      <!--        (menuClosed)="isAddNewOpen = false"-->
      <!--        class="m-r-16 curr-button"-->
      <!--        [matMenuTriggerFor]="addNewMenu"-->
      <!--        (click)="isAddNewOpen = true"-->
      <!--        fxFlex="123px"-->
      <!--        fxLayout="row"-->
      <!--        fxLayoutAlign="center center"-->
      <!--        [isHighlighted]="isAddNewOpen"-->
      <!--      > ADD NEW-->
      <!--        <mat-icon svgIcon="down" class="btn-icon-arrow " *ngIf="!isAddNewOpen"></mat-icon>-->
      <!--        <mat-icon svgIcon="up" class="btn-icon-arrow " *ngIf="isAddNewOpen"></mat-icon>-->
      <!--      </curr-button>-->
      <!--      <mat-menu #addNewMenu="matMenu" class="custom-menu">-->
      <!--        <button mat-menu-item (click)="addUsers('manually')">Add Manually</button>-->
      <!--        <button mat-menu-item (click)="addUsers('file')">Import from file</button>-->
      <!--      </mat-menu>-->

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
        <button
          mat-icon-button
          class="selected-view"
          [matTooltip]="isInListView ? 'Cards view' : 'List view'"
          (click)="setUsersView()"
        >
          <mat-icon [svgIcon]="isInListView ? 'view-module' : 'list'"></mat-icon>
        </button>
        <!--        <button-->
        <!--          mat-icon-button-->
        <!--          class="selected-view"-->
        <!--          [class.sort-menu-open]="isSortMenuOpen"-->
        <!--          (menuClosed)="isSortMenuOpen = false"-->
        <!--          matTooltip="Sort options"-->
        <!--          (click)="isSortMenuOpen = true"-->
        <!--          [matMenuTriggerFor]="sortMenu"-->
        <!--        >-->
        <!--          <mat-icon [svgIcon]="'sort-by-alpha'"></mat-icon>-->
        <!--        </button>-->
        <!--        <mat-menu #sortMenu="matMenu" class="custom-menu sort-menu">-->
        <!--          <button (click)="sortUsers('name')" mat-menu-item [class.not-selected-sort-option]="sortOption !== 'name'">-->
        <!--            <mat-icon svgIcon="check" *ngIf="sortOption === 'name'"></mat-icon>-->
        <!--            Name-->
        <!--          </button>-->
        <!--          <button-->
        <!--            (click)="sortUsers('lastAdded')"-->
        <!--            mat-menu-item-->
        <!--            [class.not-selected-sort-option]="sortOption !== 'lastAdded'"-->
        <!--          >-->
        <!--            <mat-icon svgIcon="check" *ngIf="sortOption === 'lastAdded'"></mat-icon>-->
        <!--            Last added-->
        <!--          </button>-->
        <!--          <button-->
        <!--            (click)="sortUsers('lastUpdated')"-->
        <!--            mat-menu-item-->
        <!--            [class.not-selected-sort-option]="sortOption !== 'lastUpdated'"-->
        <!--          >-->
        <!--            <mat-icon svgIcon="check" *ngIf="sortOption === 'lastUpdated'"></mat-icon>-->
        <!--            Last updated-->
        <!--          </button>-->
        <!--        </mat-menu>-->
      </div>
    </div>
  </div>
  <curr-loader [isLoading]="isLoading" [diameter]="48"></curr-loader>

  <!--  <div class="filters-container side-m-8 m-b-32" fxLayout="row" fxLayoutAlign="space-between center">-->
  <!--    <div class="filters" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">-->
  <!--      <h3 class="mat-h3">Filter</h3>-->
  <!--      <curr-dropdown-->
  <!--        *ngIf="selectedTabIndex != 2 && selectedTabIndex != 3"-->
  <!--        [selectedValue]="this.selectedFilters.grade"-->
  <!--        placeholder="Year"-->
  <!--        [options]="ddOptionsGrade"-->
  <!--        fxFlex="115px"-->
  <!--        (valueChange)="changeSelection($event, 'grade')"-->
  <!--      ></curr-dropdown>-->
  <!--      <curr-dropdown-->
  <!--        *ngIf="selectedTabIndex != 2 && selectedTabIndex != 3"-->
  <!--        [selectedValue]="this.selectedFilters.form"-->
  <!--        placeholder="Form"-->
  <!--        [options]="ddOptionsForm"-->
  <!--        fxFlex="115px"-->
  <!--        (valueChange)="changeSelection($event, 'form')"-->
  <!--      ></curr-dropdown>-->
  <!--      <curr-dropdown-->
  <!--        *ngIf="selectedTabIndex != 2 && selectedTabIndex != 3"-->
  <!--        [selectedValue]="this.selectedFilters.subject"-->
  <!--        placeholder="Subject"-->
  <!--        [options]="ddOptionsSubject"-->
  <!--        fxFlex="135px"-->
  <!--        (valueChange)="changeSelection($event, 'subject')"-->
  <!--      ></curr-dropdown>-->
  <!--      <curr-dropdown-->
  <!--        [options]="ddOptionsActivated"-->
  <!--        *ngIf="selectedTabIndex != 2 && selectedTabIndex != 3"-->
  <!--        placeholder="Assigned status"-->
  <!--        fxFlex="145px"-->
  <!--        [selectedValue]="this.selectedFilters.assigned"-->
  <!--        (valueChange)="changeSelection($event, 'assigned')"-->
  <!--      ></curr-dropdown>-->
  <!--      <curr-dropdown-->
  <!--        [options]="ddOptionsActivated"-->
  <!--        [selectedValue]="this.selectedFilters.activated"-->
  <!--        placeholder="Activation status"-->
  <!--        fxFlex="145px"-->
  <!--        (valueChange)="changeSelection($event, 'activated')"-->
  <!--      ></curr-dropdown>-->
  <!--      <mat-checkbox [(ngModel)]="selectedFilters.isArchived" color="primary">Show archived</mat-checkbox>-->
  <!--    </div>-->
  <!--    <curr-button class="curr-button" variant="contained" icon="retry" (btnClick)="clearFilters()">CLEAR ALL FILTERS</curr-button>-->
  <!--  </div>-->

  <div class="m-t-16" *ngIf="isInListView">
    <table mat-table matSort [dataSource]="dataSource" aria-describedby="managers desc">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Name</th>
        <td mat-cell *matCellDef="let element" [class.deactivated-text]="element.deactivated">
          <a [routerLink]="'/profile/' + element.id || '1'" class="user-name"
          ><p>{{ element?.first_name + ' ' + element?.last_name }}</p></a>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef scope="col">ID</th>
        <td mat-cell *matCellDef="let element" [class.deactivated-text]="element.deactivated">{{ element.id }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Status</th>
        <td mat-cell *matCellDef="let element" [class.deactivated-text]="element.deactivated">
          <span>{{ element.deactivated ? 'Deactivated' : 'Active' }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="activate">
        <th mat-header-cell *matHeaderCellDef class="w100" scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <curr-button class="curr-button" variant="text" (click)="activateUser(element)" *ngIf="element.deactivated">
            ACTIVATE
          </curr-button>
        </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef style="width: 80px" scope="col"></th>
        <td mat-cell *matCellDef="let element">
          <div class="manage-icons-container" fxLayout="row" fxLayoutAlign="end center">
            <button mat-icon-button (click)="deleteUser(element)">
              <mat-icon svgIcon="trash"></mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon svgIcon="edit" [routerLink]="'/profile/' + element.id || '1'"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

  </div>

  <div class="m-t-16 cards-container" *ngIf="!isInListView" fxLayout="row" fxLayoutAlign="start center">
    <ng-container>
      <div class="user-container m-b-16" *ngFor="let user of users" fxFlex="316px">
        <curr-user-card
          [isDeactivated]="user.deactivated"
          [userData]="user"
          (onDeleteUser)="deleteUser(user)"
        ></curr-user-card>
      </div>
    </ng-container>
  </div>

  <div class="m-t-16 side-m-8" fxLayout="row" fxLayoutAlign="end center">
    <curr-pagi-for-loading-data *ngIf="dataFromApi"
                                [currPage]="dataFromApi.pageable.pageNumber"
                                [totalPages]="dataFromApi.totalPages"
                                (pageSelected)="pageSelected($event)"
    ></curr-pagi-for-loading-data>
  </div>
</div>
