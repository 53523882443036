import { Subscription } from 'rxjs';

export class FileUploadModel {
  data: File;
  state: string;
  marked: boolean;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  isError: boolean;
  color: string;
  timeLeft?: string;
  isCompleted: boolean;
  sub?: Subscription;
}

export const fileInit = {
  state: 'in',
  inProgress: false,
  color: 'accent',
  progress: 0,
  marked: false,
  canRetry: false,
  canCancel: true,
  isCompleted: false,
  isError: false
};
