import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from '../../../shared/api/application-http-client';

@Injectable({
  providedIn: 'root'
})
export class NotesAboutStudentService {

  constructor(private httpClient: ApplicationHttpClient
  ) {
  }

  getAllNotes(limit = 10, offset = 0) {
    return this.httpClient.get(`/teacherNotes?limit=${limit}&offset=${offset}`);
  }

  getNotesByStudId(studentId:number, limit = 10, offset = 0) {
    return this.httpClient
      .get(`/teacherNotes/search/findAllByStudentId?limit=${limit}&offset=${offset}&studentId=${studentId}`);
  }

  getNotesByStudIdAndByTeacherId(studentId:number, limit = 10, offset = 0) {
    return this.httpClient
      .get(`/teacherNotes/search/findAllByStudentIdAndTeacherId?limit=${limit}&offset=${offset}&studentId=${studentId}`);
  }

  getNoteById(id) {
    return this.httpClient.get(`/teacherNotes?id=${id}`);
  }

  deleteNoteById(id) {
    return this.httpClient.delete(`/teacherNotes/${id}`);
  }

  createNote(note: string, student_id: number) {
    return this.httpClient.post(`/teacherNotes`, { note, student_id });
  }

  updateNote(note: string, id: number) {
    return this.httpClient.put(`/teacherNotes/${id}`, note );
  }
}
