import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[currScrollable]'
})
export class ScrollableDirective {
  constructor(private el: ElementRef) {}
  set scrollTop(value: number) {
    this.el.nativeElement.scrollTo(0, value);
  }
}
