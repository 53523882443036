import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Question } from 'src/app/student/test.model';
import { NewQuestionService } from '../new-question.service';

@Component({
  selector: 'curr-new-keywords',
  templateUrl: './new-keywords.component.html',
  styleUrls: ['./new-keywords.component.scss']
})
export class NewKeywordsComponent implements OnInit, OnDestroy {

  @Input()
  question: Question;

  @Output()
  onKeywordsUpdate = new EventEmitter<{key: string, mark: number}[]>();

  answersData: { key: string, mark: number }[] = [];
  showDelete = false;
  isMarkSchemeTinyEditor = false;
  oeqKeywordsForm: FormGroup;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private newQuestionService: NewQuestionService) {}

  get oeqKeywords() {
    return this.oeqKeywordsForm.controls.oeqKeywords as FormArray;
  }

  ngOnInit() {
    this.oeqKeywordsForm = this.fb.group({
      oeqKeywords: this.fb.array([])
    });

    if (this.question.oeq_keywords?.length) {
      this.question.oeq_keywords.forEach((item, i) => {
        const optionsForm = this.fb.group({
          key: [item.key],
          mark: [item.mark]
        });

        this.oeqKeywords.push(optionsForm);
      });
    }

    this.answersData = this.question.oeq_keywords;

    if (this.answersData.length > 0) {
      this.changeColumns();
    } else {
      this.addKeyword();
    }

    this.oeqKeywords.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      this.answersData = changes;
      this.onKeywordsUpdate.emit(this.answersData);
      this.changeColumns();
    });

    this.newQuestionService.onUpdateOeqKeywords().pipe(takeUntil(this.destroy$)).subscribe(data => {
      if (data.questionNumber === this.question.itemNumber) {
        this.oeqKeywords.controls.forEach((item, index) => {
          if (item.value.key === 'Answer' || item.value.key === data.key) {
            this.oeqKeywords.removeAt(index);
          }
        });

        const optionsForm = this.fb.group({
          key: [data.key],
          mark: [data.mark]
        });

        this.oeqKeywords.push(optionsForm);
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  deleteElement(i: any) {
    if (this.answersData.length <= 1) return;
    this.oeqKeywords.removeAt(i);
  }

  addKeyword() {
    const newItem = this.fb.group({
      key: ['Answer'],
      mark: [1]
    });
    this.oeqKeywords.push(newItem);
  }

  updateEditorOption(event: any, index: number): void {
    this.answersData[index].key = event;
    this.oeqKeywords.controls[index].patchValue(this.answersData[index]);
  }

  updateOptionOutFocus() {
    this.onKeywordsUpdate.emit(this.answersData);
  }

  updateRowValue(event, index: number, type: 'key' | 'mark'): void {
    if (type === 'key') this.answersData[index].key = event;
    if (type === 'mark') this.answersData[index].mark = +event;
    this.onKeywordsUpdate.emit(this.answersData);
  }

  private changeColumns() {
    this.showDelete = this.answersData.length > 1;
  }
}
