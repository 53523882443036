/*
 * Created by Max Bon. on 01/07/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CtReviewService } from 'src/app/student/ct-review/ct-review.service';

interface StudentCTCard {
  attempts?: number;
  courseStudentId?: number;
  dateText?: string;
  startTime?: number;
  subject?: string;
  testId?: number;
  testState?: string;
  title?: string;
  testGrade?: string;
  courseId?: number;
  is_offline?: boolean;
}

@Component({
  selector: 'curr-student-cumulative-test-card',
  templateUrl: './student-cumulative-test-card.component.html',
  styleUrls: ['./student-cumulative-test-card.component.scss']
})
export class StudentCumulativeTestCardComponent implements OnInit {
  @Input() test: StudentCTCard = {};
  buttons = [];
  bottomTitles = [];
  testTypes;

  constructor(private router: Router, private ctReviewService: CtReviewService) {
    this.testTypes = {
      readyToPass: 'READY_TO_PASS',
      notPassed: 'NOT_PASSED',
      reviewed: 'CHECKED',
      passed: 'PASSED'
    };
  }

  ngOnInit(): void {
    this.createButtons();
    this.createBottomTitles();
  }

  private createButtons() {
    if (
      this.test.testState === this.testTypes.notPassed ||
      (this.test.testState === this.testTypes.readyToPass && !this.test.is_offline)
    ) {
      this.buttons.push({
        label: 'TAKE THE TEST',
        callback: this.goToTest.bind(this)
      });
      // this.buttons.push({
      //   label: 'DOWNLOAD THE TEST',
      //   callback: this.goToTest.bind(this)
      // });
    }
    if (this.test.testState === this.testTypes.reviewed) {
      let { testGrade = 'A+' } = this.test;
      if (testGrade === null) {
        testGrade = 'UNKNOWN';
      }
      this.buttons.push({
        variant: 'text',
        label: `Result: ${ testGrade }`,
        callback: this.goToCTReview.bind(this)
      });
    }
    if (this.test.testState === this.testTypes.passed) {
      this.buttons.push({
        callback: () => {
        },
        variant: 'text',
        label: 'Result pending'
      });
    }
  }

  goToTest() {
    this.router.navigate([
      `/courses-details/${ this.test.courseId }/cumulative-test/${ this.test.testId }/${ this.test.courseStudentId }`
    ]);
  }

  goToCTReview() {
    const { testId, courseStudentId, testGrade, title, startTime } = this.test;
    this.ctReviewService.ctInfo = { testGrade, title, startTime };
    this.router.navigateByUrl(`courses-details/ct-review/${ testId }/${ courseStudentId }/${ this.test.courseId }`);
  }

  testClass() {
    const { testState } = this.test;
    const { notPassed } = this.testTypes;
    return testState === notPassed ? 'future' : 'complete';
  }

  isToday() {
    const { startTime } = this.test;
    const d = new Date(startTime);
    const t = new Date();
    return d.getFullYear() === t.getFullYear() && d.getMonth() === t.getMonth() && d.getDate() === t.getDate();
  }

  private createBottomTitles() {
    const { subject, attempts } = this.test;
    this.bottomTitles.push({ icon: '', label: '', value: subject, isAttention: true, isBold: true });
    this.bottomTitles.push({ icon: '', label: '', value: '60 min. ', isAttention: true, isBold: false });
    this.bottomTitles.push({ icon: '', label: 'Possible attempts:', value: attempts, isAttention: true, isBold: true });
  }

  // goToLearningResources(test: StudentCTCard) {
  //   this.router.navigate(['course-details', this.test.testId]);
  // }
}
