/**
 * Created by Maxim B. on 10/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-textarea-disabled',
  templateUrl: './textarea-disabled.component.html',
  styleUrls: ['./textarea-disabled.component.scss']
})
export class TextareaDisabledComponent implements OnInit {
  @Input() disabled = true;
  @Input() value: string;
  @Input() label: string;
  @Input() rows = 5;
  @Input() formGroup?: FormGroup;
  @Input() controlName = '';
  @Input() errorMessage = '';
  @Output() valueChange = new EventEmitter<string>();
  @Output() onLostFocus = new EventEmitter<string>();
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    if (!this.formGroup) {
      this.formGroup = this.fb.group({
        item: new FormControl(this.value)
      });
      this.controlName = 'item';
    }

  }

  updateValue(val){
    this.valueChange.emit(val);
  }

  lostFocus(val:string) {
    this.onLostFocus.emit(val);
  }
}
