<!--
 Created by Volodymyr Mak. on 30/6/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="container">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <curr-breadcrumb position="start" class="m-t-40 m-b-24" [path]="path"></curr-breadcrumb>
  </div>

  <article class="m-b-40" fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="24px">
    <section fxFlex="30%">
      <curr-profile-card [profileData]="profileData"></curr-profile-card>
    </section>
    <section fxFlex>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Permissions</span>
<!--        <curr-button class="curr-button" variant="text" icon="edit">Edit</curr-button>-->
      </div>
      <mat-divider class="m-b-16 m-t-16"></mat-divider>
      <mat-card>
        <curr-checkbox [options]="options" [value]="value"></curr-checkbox>
      </mat-card>
    </section>
  </article>


  <article>
    <section>
      <div class="title" fxLayoutAlign="space-between center">
        <span>Courses</span>
        <div></div>
<!--        <curr-button class="curr-button" variant="text" icon="icon-plus">Add new</curr-button>-->
      </div>
      <mat-divider class="m-b-16 m-t-16"></mat-divider>
      <div fxLayout="row" fxLayoutGap="8px" class="courses-filter-wrapper m-b-16">
        <curr-course-filters (filterParamsChanged)="filterChanged($event)"></curr-course-filters>
      </div>
      <div fxLayoutGap="22px" fxLayout="row wrap" fxLayoutAlign="start center">
        <curr-course-card
          *ngFor="let item of cards"
          [course]="item" fxFlex="458px" class="m-b-24">
        </curr-course-card>
      </div>
      <div *ngIf="cards.length > 0" fxLayout="row" fxLayoutAlign="center center" class="m-t-56">
        <curr-button (btnClick)="showMore()" fxFlex="144px" variant="contained" color="primary" size="big">
          SHOW MORE
        </curr-button>
      </div>
    </section>
  </article>
</div>
