/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import { Subscription } from 'rxjs';
import { IZone } from '../ai-zones-marker/ai-zones-marker.interface';

export class FileUploadModel {
  data: File;
  resizedFile?: File;
  state: string;
  marked: boolean;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  isError: boolean;
  color: string;
  timeLeft?: string;
  isCompleted: boolean;
  localLink?: string;
  resizedLocalLink?: string;
  base64?: string;
  resizedBase64?: string;
  hovered?: boolean;
  sub?: Subscription;
  zoneData?: IZone[];
}

export const fileInit = {
  state: 'in',
  inProgress: false,
  color: 'accent',
  progress: 0,
  marked: false,
  canRetry: false,
  canCancel: true,
  isCompleted: false,
  isError: false
};

export class AIImages {
  src: string;
  type: string;
  include?: AIImagesInclude[];
}

class AIImagesInclude {
  description: string;
  type?: string;
  is_ignored: boolean;
  position?: {
    start_x: number;
    start_y: number;
    width: number;
    height: number;
  }
}
