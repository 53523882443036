/**
 * Created by Yaroslav S. on 10/07/2024
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAiKeys, IAiSubjectSettings } from './managing-api-keys.interface';
import { Subject, takeUntil } from 'rxjs';
import { ManagingApiKeysService } from './managing-api-keys.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'curr-managing-api-keys',
  templateUrl: './managing-api-keys.component.html',
  styleUrls: ['./managing-api-keys.component.scss'],
})
export class ManagingApiKeysComponent implements OnInit, OnDestroy {
  apiKeys: IAiKeys;
  apiKeysSettings: IAiSubjectSettings;

  path = [
    {
      label: 'Home',
      url: '/dashboard',
    },
    {
      label: 'Managing API Keys',
      url: '',
    },
  ];

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private managingApiKeysService: ManagingApiKeysService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.apiKeys = this.route.snapshot.data.apiKeys;
    this.apiKeysSettings = this.route.snapshot.data.apiKeysSettings;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  saveKeys(data: { type: string; key: string; appId: string | null }): void {
    if (data.appId ? (data.key.includes('*') || data.appId.includes('*')) : data.key.includes('*')) {
      this.toastr.error('Your API Key Must Not Contain An Asterisk (*)!');
      return;
    }

    this.managingApiKeysService
      .saveAiKeys(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.toastr.success('API Key Was Successfully Updated!');
      });
  }
}
