<!-- Created by Maxim B. on 01/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-form-field
  [formGroup]="formGroup"
  color="primary"
  fxFlexFill
  [ngClass]="[
    isGreyBackground ? 'add-grey-background' : '',
    formGroup.controls[controlName].value?.toString().length ? 'not-empty-inp' : 'empty-inp'
  ]"
  [appearance]="isBordered ? 'outline' : 'standard'">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <input
      matInput
      [matDatepicker]="datepicker"
      [readonly]="true"
      #customInput
      [class.centered]="isCentered"
      [matDatepickerFilter]="daysFilter"
      [formControlName]="controlName"
      (dateChange)="changeValue($event)"
      (focusin)="datepicker.open()"
      (keyup)="updateValue()"
      (blur)="onLostFocus()"
    />

  <mat-datepicker-toggle matSuffix [for]="datepicker">
    <mat-icon matDatepickerToggleIcon [svgIcon]="suffix"></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker #datepicker></mat-datepicker>

  <mat-hint *ngIf="userHint">{{ userHint }}</mat-hint>
  <mat-error *ngIf="formGroup.controls[controlName]?.errors">{{ errorMessage }}</mat-error>
</mat-form-field>
