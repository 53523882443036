<!-- Created by Max Bon. on 29/07/2020-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container" [ngClass]="[isSimpleNote() ? 'simple-note' : '']"
     fxLayout="column" fxLayoutAlign="start center" [id]="'snc-'+note.id">
  <span [style]="{'background-color': note.selected_text_color}"
        (click)="smoothScroll()"
        *ngIf="note.selected_text" class="selected-text">{{note.selected_text}}</span>

  <div  *ngIf="note.selected_image"
        (click)="smoothScroll()"
        class="image-container"  fxLayout="row" fxLayoutAlign="center center"
        [style]="{'border-color': note.selected_text_color, 'background-color': note.selected_text_color}">
    <img [src]="note.selected_image" alt="image">
  </div>

  <mat-divider class="full-width m-t-8 m-b-8"
               *ngIf="note.selected_text || note.selected_image"></mat-divider>

  <div class="comment-view full-width" fxLayout="row" fxLayoutAlign="space-between center">
    <span class="mat-body-1">
      {{note.text_note || note.image_note || 'No note'}}
    </span>
    <div class="btns-container">
      <curr-icon-button [icon]="'edit'" (click)="editNote()"></curr-icon-button>
      <curr-icon-button [icon]="'trash'" (click)="openConfirmDelete()"></curr-icon-button>
    </div>

  </div>

</div>












