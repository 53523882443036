<div>
  <div class="m-b-32 mat-h2">Create scheme of work</div>
  <curr-loader [diameter]="40" [isLoading]="areDropdownsLoading">
    <div class="m-b-8">
      <curr-dropdown
        [formGroup]="formGroup"
        controlName="subject"
        [selectedValue]="subjects[0].value"
        [options]="subjects"
      ></curr-dropdown>
    </div>
    <ng-container *ngIf="preCreateSchemeWork$ | async as preCreateSchemeWork">
      <div class="m-b-8">
        <curr-dropdown
          [formGroup]="formGroup"
          controlName="study_year_id"
          [selectedValue]="preCreateSchemeWork.schoolStudyYears[0].value"
          [options]="preCreateSchemeWork.schoolStudyYears"
        ></curr-dropdown>
      </div>
      <div class="m-b-8">
        <curr-dropdown
          [formGroup]="formGroup"
          controlName="examination_board_id"
          [selectedValue]="preCreateSchemeWork.examinationBoards[0].value"
          [options]="preCreateSchemeWork.examinationBoards"
        ></curr-dropdown>
      </div>
    </ng-container>
    <div class="m-b-8">
      <curr-input [formGroup]="formGroup" placeholder="Enter scheme of work name" controlName="scheme_work_title"></curr-input>
    </div>
    <div fxLayoutAlign="end" fxLayoutGap="23px">
      <curr-button fxFlex="60px" variant="text" (btnClick)="dialogRef.close()">CANCEL</curr-button>
      <curr-loader [diameter]="40" [isLoading]="isCreatingLoading">
        <curr-button fxFlex="87px" (btnClick)="create()">CREATE</curr-button>
      </curr-loader>
    </div>
  </curr-loader>
</div>
