<!--
  ~ Created by Yaroslav S. on 16/04/24.
  ~ Copyright © 2024 SEVEN. All rights reserved.
-->

<div class="questions-content">
  <div class="questions-content-title">{{ dialogSubTitle }}</div>
  <div *ngIf="dialogType === 'ai'" class="questions-content-subtitle">
    Ready to add new questions in a flash? Minimize the time you spend and enhance your interaction with the AI
    Assistant when uploading files. Read the User Prompt to unlock the secrets!
  </div>

  <curr-expansion-panel
    *ngIf="dialogType === 'ai'"
    [title]="'How to Efficiently Use the AI Assistant for Uploading Your Sources'"
    [isShowed]="false"
  >
    <div>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut lobortis quam, quis tincidunt mauris. Cras
      mauris mi, consectetur non imperdiet vel, volutpat et massa. Donec interdum vel nibh vel egestas. Vivamus pulvinar
      posuere odio semper interdum. Maecenas lectus augue, semper dictum lorem tincidunt, vulputate auctor risus. Mauris
      placerat vel diam sed volutpat. Mauris eu accumsan magna. Nunc lectus sapien, aliquet quis elit ut, pretium
      feugiat sapien. In ac tristique nulla. Aliquam ornare vitae turpis vel cursus. Etiam in elit eget ligula interdum
      faucibus. Aliquam quis nulla pretium leo dictum congue ut et magna. Cras mattis ante magna, vel congue eros cursus
      quis. Etiam facilisis eu metus suscipit iaculis. Phasellus nec rhoncus est, eu cursus nulla. Cras vehicula ipsum
      in faucibus tincidunt. Quisque pharetra, nibh a accumsan consectetur, est est consectetur justo, ut congue dui
      magna a ex. Vivamus commodo tortor non ante mollis, et lacinia tortor mattis. Nulla viverra interdum laoreet.
      Donec varius suscipit orci. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
      Donec porta eget tortor sed tincidunt. Duis ac accumsan mi, tempus convallis nisl. Maecenas mollis leo sed maximus
      pellentesque. Maecenas feugiat sem nec dui dictum, at iaculis mauris faucibus. Praesent sem justo, dapibus id
      elementum nec, suscipit in lorem.
    </div>
  </curr-expansion-panel>

  <div class="questions-content-full">
    <div *ngIf="dialogType === 'ai'">
      <curr-pagination-stepper
        [isSideBtnVisible]="true"
        [items]="[0, 1, 2]"
        [selectedIndex]="selectedIndex"
        [isDisabledPrev]="steperPrevDisabled"
        [isDisabledNext]="steperNextDisabled"
        [setStep]="setStepValue"
        (questionSelected)="onPaginationQuestionSelected($event)"
      >
      </curr-pagination-stepper>
    </div>

    <div class="questions-content-section" *ngIf="selectedIndex === 0">
      <div class="questions-content-section-item">
        <div class="question-row m-b-24">
          <div class="question-row-title">Upload Question</div>
          <curr-ai-upload-files
            [aiFilesType]="'question'"
            [automaticallyImport]="true"
            [enableProgressBar]="false"
            [isMultiple]="true"
            [initImages]="imagesStore.questionImages ?? null"
            (onLoadedFile)="onLoadedFile($event)"
            (onCollectedFiles)="onSendToAI($event)"
          ></curr-ai-upload-files>
        </div>
      </div>
      <div class="questions-content-section-item">
        <div class="question-row-title dark-title" *ngIf="!questions || questions.length === 0">Question Set</div>
        <div class="contenr-loader" *ngIf="isLoadingContent">
          <mat-spinner [strokeWidth]="5" [diameter]="38"></mat-spinner>
        </div>

        <div class="question-row-desc" *ngIf="!questions || questions.length === 0 || isLoadingContent">
          Please be prepared to wait a few moments after uploading your files. We will need time to process them before
          you can continue. You can continue browsing generated questions here after file processing is complete, or you
          can go to the ready-made set of questions in the corresponding section for further editing.
        </div>

        <div class="question-row-title" *ngIf="questions && questions.length > 0">Recognized by AI</div>
        <curr-movable-sections
          *ngIf="questions && questions.length > 0"
          [form]="form"
          [stepNumber]="selectedIndex"
          [topics]="topicsList"
          [questions]="questions"
          [dialogType]="dialogType"
          [dialogMode]="dialogMode"
          [originalQuestionContent]="originalQuestionContent"
          [originalMarkSchemeContent]="originalMarkSchemeContent"
          (onAddNewQuestion)="addNewQuestion($event)"
        >
        </curr-movable-sections>
      </div>
    </div>

    <div class="questions-content-section" *ngIf="selectedIndex === 1">
      <div class="questions-content-section-item" *ngIf="questions && questions.length > 0">
        <div class="question-row m-b-24">
          <div class="question-row-title">Upload Mark Scheme</div>
          <curr-ai-upload-files
            [aiFilesType]="'markScheme'"
            [automaticallyImport]="true"
            [enableProgressBar]="false"
            [isMultiple]="true"
            [initImages]="imagesStore.markSchemeImages ?? null"
            (onLoadedFile)="onLoadedFile($event)"
            (onCollectedFiles)="onSendToAI($event)"
          ></curr-ai-upload-files>
        </div>
      </div>

      <div class="questions-content-section-item">
        <div class="question-row-title dark-title" *ngIf="isLoadingContent">
          Mark Scheme
        </div>
        <div class="contenr-loader" *ngIf="isLoadingContent">
          <mat-spinner [strokeWidth]="5" [diameter]="38"></mat-spinner>
        </div>

        <div class="question-row-desc m-b-16" *ngIf="isLoadingContent">
          Please be prepared to wait a few moments after uploading your files. We will need time to process them before
          you can continue. You can continue browsing generated questions here after file processing is complete, or you
          can go to the ready-made set of questions in the corresponding section for further editing.
        </div>

        <div class="question-row-title" *ngIf="originalQuestionContent || originalMarkSchemeContent">
          Mark Scheme Details
        </div>
        <curr-movable-sections
          *ngIf="questions && questions.length > 0"
          [form]="form"
          [stepNumber]="selectedIndex"
          [topics]="topicsList"
          [questions]="questions"
          [dialogType]="dialogType"
          [dialogMode]="dialogMode"
          [originalQuestionContent]="originalQuestionContent"
          [originalMarkSchemeContent]="originalMarkSchemeContent"
          (onAddNewQuestion)="addNewQuestion($event)"
        >
        </curr-movable-sections>
      </div>
    </div>

    <div class="questions-content-section" *ngIf="selectedIndex === 2">
      <div class="questions-content-section-item">
        <div class="question-row-title" *ngIf="dialogType === 'ai'">Compare Question and Mark Scheme</div>
        <curr-movable-sections
          *ngIf="questions && questions.length > 0"
          [form]="form"
          [stepNumber]="selectedIndex"
          [topics]="topicsList"
          [questions]="questions"
          [dialogType]="dialogType"
          [dialogMode]="dialogMode"
          [originalQuestionContent]="originalQuestionContent"
          [originalMarkSchemeContent]="originalMarkSchemeContent"
          (onAddNewQuestion)="addNewQuestion($event)"
        >
        </curr-movable-sections>
      </div>
    </div>
  </div>

  <div class="questions-content-actions">
    <div class="actions-item">
      <curr-button class="without-lines" variant="outlined" size="big" (click)="cancelQuestion()">CANCEL</curr-button>
      <curr-button
        *ngIf="selectedIndex !== 0 && dialogType === 'ai'"
        (btnClick)="setStepNumber(selectedIndex - 1)"
        class="curr-button"
        size="big"
        [isDisabled]="steperPrevDisabled"
        variant="outlined"
      >
        <mat-icon svgIcon="arrow-left"></mat-icon>
        PREV
      </curr-button>
    </div>

    <div class="actions-item">
      <curr-button
        *ngIf="selectedIndex === 2"
        variant="contained"
        [isDisabled]="isSaveDisabled || selectedIndex < 2"
        size="big"
        (click)="saveNewQuestion()"
        >{{dialogSubTitle}}</curr-button
      >

      <curr-button
        *ngIf="selectedIndex !== 2"
        (btnClick)="setStepNumber(selectedIndex + 1)"
        class="curr-button"
        size="big"
        [isDisabled]="steperNextDisabled"
        variant="outlined"
      >
        NEXT
        <mat-icon svgIcon="arrow-right"></mat-icon>
      </curr-button>
    </div>
  </div>
</div>

<curr-loader-with-text
  *ngIf="isLoading"
  [title]="'Please be patient'"
  [text]="'AI Assistant is processing the data, which may take a few seconds. Thanks for waiting.'"
>
</curr-loader-with-text>
