<div class="pagination-wrapper button" fxLayout="row" fxLayoutAlign="center center">
  <div class="nav-item m-r-40" fxLayoutAlign="start center"  *ngIf="showSideBtns">
    <curr-button (btnClick)="setCurrentPage(currPage - 1)"
                 class="curr-button"
                 [isDisabled]="currPage == 0"
                 icon="arrow-left" variant="text">
      PREV.
    </curr-button>
  </div>

  <div class="pagination-items" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">

    <ng-container
      *ngFor="let page of arr; let index = index; let isFirst = first; let isLast = last">
      <div
        *ngIf="((index - currPage) <= 2 && (index - currPage) >= -2 )|| isFirst || isLast"
        class="pagination-list"
        fxLayoutAlign="center center"
        [class.current]="index === currPage">
        <a class="cursor-pointer" fxLayoutAlign="center center"
           (click)="setCurrentPage(index)">
          <span>{{ index + 1 }}</span>
        </a>
      </div>
      <div
        *ngIf="((index - currPage) == 3 && index != (arr.length-1)) || ((index - currPage) == -3 && index !=0)"
        class="pagination-list" fxLayoutAlign="center center">
        <a class="cursor-pointer"
           fxLayoutAlign="center center">
          <span>...</span>
        </a>
      </div>

    </ng-container>
  </div>

  <div class="nav-item m-l-40" fxLayoutAlign="end center"  *ngIf="showSideBtns">
    <curr-button class="curr-button" [isDisabled]="currPage >= (arr.length - 1) "
                 (btnClick)="setCurrentPage(currPage + 1)"
                 variant="text">
      NEXT
      <mat-icon svgIcon="arrow-right"></mat-icon>
    </curr-button>
  </div>
</div>
