/**
 * Created by Maxim Bon. on 18/05/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'curr-confirm-topics-popup',
  templateUrl: './confirm-topics-popup.component.html',
  styleUrls: ['./confirm-topics-popup.component.scss']
})
export class ConfirmTopicsPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmTopicsPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
