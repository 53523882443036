<!--
 Created by Daniil Vat. on 28/3/2021
 Copyright © 2021 Curriculum Ltd. All rights reserved.
-->
<curr-loader [isLoading]="!receivedData" [diameter]="48"></curr-loader>
<div fxLayout="column" class="list">
  <div class="list--item" *ngFor="let item of displayedData" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="70%" class="list--name p-r-8" class="list--name">{{item.firstName}} {{item.lastName}}</div>
    <div fxLayoutAlign="end" fxLayout="row" fxLayoutAlign="end start" fxFlex="30%" class="p-l-4"
      *ngIf="item.wrongAnswers">{{item.wrongAnswers}}
      incorrect
      answer{{item.wrongAnswers
      > 1 ? 's': ''}}</div>
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="center"
  *ngIf="receivedData?.length >= limit && (displayedData?.length < receivedData?.length)">
  <curr-button size="big"  fxFlex="29%" class="load-more-btn" variant="outlined" (btnClick)="showMore()">load more
  </curr-button>
</div>