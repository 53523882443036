<!--Created by Maxim B. on 16/04/20.-->
<!--Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-tab-group
  [dynamicHeight]="false"
  mat-align-tabs="end"
  animationDuration="0ms"
  (selectedIndexChange)="valueChange.emit($event)"
  [(selectedIndex)]="value"
>
  <mat-tab *ngFor="let lbl of tabLabels; let i = index" [label]="lbl"></mat-tab>
</mat-tab-group>
