<!-- Created by Maxim B. on 03/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div class="dialog-container" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <span>Select years for range</span>
    <mat-icon (click)="this.dialogRef.close()">close</mat-icon>
  </div>
  <div class="body">
    <mat-checkbox *ngFor="let item of fields; let i = index"
                  color="primary"
                  [(ngModel)]="item.checked"
                  [disabled]="item.disabled"
                  >{{item.label}}</mat-checkbox>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="start center">
    <curr-button variant="contained" color="primary"
                 [isDisabled]="isDisabledCreateBtn"
                 (click)="createRange()" size="small" icon="building">
      CREATE RANGE
    </curr-button>
  </div>
</div>
