/**
 * Created by Alex Poh. on 26/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import * as moment from 'moment';
import { of, switchMap, takeUntil, Subject } from 'rxjs';

import { IPerformanceChart, IPerformanceChartSubject } from './student-performance-chart.interface';
import { StudentPerformanceChartService } from './student-performance-chart.service';

@Component({
  selector: 'curr-student-performance-chart',
  templateUrl: './student-performance-chart.component.html',
  styleUrls: ['./student-performance-chart.component.scss'],
})
export class StudentPerformanceChartComponent implements OnInit, OnDestroy {
  
  @Input() studentId;

  chartAverageBenchmark: string;

  performanceChartSubjects = [];
  performanceResultsMark = '';
  performanceResultDate = '';
  performanceResultsChart = {
    grades: [],
    averageBenchmark: [],
  };
  performanceResultsChartMonth = [];
  performanceChartLoading = true;
  performanceChartNoData = false;

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private studentPerformanceChartService: StudentPerformanceChartService,
  ) {

  }

  ngOnInit(): void {
    this.getPerformanceChartData();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  performanceChartSubjectsChange(value: IPerformanceChartSubject) {
    this.getPerformanceChartWithoutSubjects(value.subjectName, value.studyYearId, value.courseId);
  }

  private getPerformanceChartData(): void {
    this.studentPerformanceChartService
      .getChartSubjects(this.studentId)
      .pipe(
        switchMap((subjectsOptions) => {
          if (subjectsOptions.subjectToCourse.length > 0) {
            const sortedList = subjectsOptions.subjectToCourse.sort((a, b) => a.subjectName.localeCompare(b.subjectName));
            
            sortedList.forEach((item) => {
              item.studyYearId = subjectsOptions.levelToCourse.find(levelItem => levelItem.courses == item.courseId)?.levels;
              const courseValue = subjectsOptions.courses.find(courseItem => courseItem.id == item.courseId)?.value;
              this.performanceChartSubjects.push({ value: item, label: item.subjectName + ' - Year - ' + courseValue });
            });
            
            return this.studentPerformanceChartService.getChartData(
              this.performanceChartSubjects[0].value.subjectName,
              this.performanceChartSubjects[0].value.studyYearId,
              this.performanceChartSubjects[0].value.courseId,
              this.studentId
            );
          }
          this.performanceChartNoData = true;
          return of();
        }),
        takeUntil(this.unsubscribe),
      )
      .subscribe(
        (chartData) => {
          if (chartData) this.processPerformanceChart(chartData);
        },
        (error) => {
          this.performanceChartLoading = false;
        },
      );
  }

  private getPerformanceChartWithoutSubjects(subject: string, studyYearId: number, courseId: number): void {
    this.studentPerformanceChartService
      .getChartData(subject, studyYearId, courseId, this.studentId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (chartData) => {
          if (chartData) this.processPerformanceChart(chartData);
        },
        (error) => {
          this.performanceChartLoading = false;
        },
      );
  }

  private processPerformanceChart(chartData: IPerformanceChart) {
    this.performanceResultsMark = chartData.averageGrade;

    this.performanceResultDate = `${moment(chartData.startDate).format('D MMMM YYYY')} - ${moment(chartData.endDate).format('D MMMM YYYY')}`;
    const chartResults = {
      grades: [],
      averageBenchmark: [],
    };

    this.chartAverageBenchmark = chartData.averageBenchmark;

    const month = [];
    const lineChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    chartData.grades.forEach((item) => {
      chartResults.averageBenchmark.push(chartData.averageBenchmark);
      if (item.gradeLabel === '-') {
        chartResults.grades.push(null)
      } else {
        chartResults.grades.push(item.gradeLabel)
      };
      month.push(lineChartLabels[item.month - 1]);
    });

    if (chartResults.grades.filter(item => !!item).length === 0) chartResults.averageBenchmark = [];

    this.performanceResultsChart = chartResults;
    this.performanceResultsChartMonth = month;
    this.performanceChartLoading = false;
  }

}
