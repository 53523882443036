<!--
 Created by Dmytro Sav. on 4/17/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="curr-task-list" [@fade]>
  <div class="task-title-section" *ngIf="showTitleSection">
    <div class="task-title">Tasks</div>
    <button class="add-new-btn" mat-button (click)="addNew()">
      <mat-icon svgIcon="plus-green" class="button-icon"></mat-icon>
    </button>
  </div>

  <div class="task-list-section">
    <div class="task-list-item" *ngFor="let task of tasks | keyvalue; let i = index" [@fade]>
      <div class="item-day">
        {{ task.key | taskListPresentDate }}
      </div>

      <div *ngFor="let dayTask of task.value; let last = last" [ngClass]="dayTask.is_completed ? 'task-item active ' : 'task-item'">
        <mat-checkbox
          [checked]="dayTask.is_completed"
          (change)="doneTask(dayTask, task.value, task.key)"
          [color]="'accent'"></mat-checkbox>

        <div class="center-section">
          <div class="center-section-name">{{ dayTask.name }}</div>
          <div class="center-section-date">{{ dayTask.deadline | taskListPresentDate }} {{ dayTask.deadline | date: 'hh:mm a' }}</div>
        </div>

        <mat-icon class="task-icon" svgIcon="edit" (click)="editTask(dayTask)"></mat-icon>
      </div>
    </div>

    <div class="no-tasks-data" *ngIf="(tasks | json) === '{}'">
      <img src="/assets/images/colored/amico.svg" />
      <div class="no-data">No Tasks</div>
    </div>
  </div>
</div>
