<!-- Created by Maxim Bon. on 13/05/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->
<div fxLayout="row" fxLayoutAlign="space-between start" class="header m-b-8">
  <h2 class="mat-h2 m-b-0" fxFlex>Rename</h2>
  <curr-icon-button icon="close" (click)="dialogRef.close()"></curr-icon-button>
</div>
<div [formGroup]="form" class="name-container m-b-16" fxLayout="column" fxLayoutAlign="center center">
  <curr-input
    [formGroup]="form"
    controlName="name"
    class="full-width"
    [errorMessage]="form.controls['name'].hasError('required') ? 'Name is required' : 'Name max length = 125 characters'"
  ></curr-input>
  <div class="m-t-8 full-width" fxLayout="row" fxLayoutAlign="end center">
    <curr-button variant="text" (btnClick)="dialogRef.close()" size="big">CANCEL</curr-button>
    <curr-button variant="contained" class="m-l-16" [isDisabled]="form.invalid" (btnClick)="saveName()" size="big">RENAME</curr-button>
  </div>
</div>
