/**
 * Created by Maxim B. on 16/04/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { TeacherService } from '../../services/teacher.service';

@Component({
  selector: 'curr-teacher-self-assessment-test-card',
  templateUrl: './teacher-self-assessment-test-card.component.html',
  styleUrls: ['./teacher-self-assessment-test-card.component.scss']
})
export class TeacherSelfAssessmentTestCardComponent implements OnInit {
  @Input() testCard: any;

  curriculumId: number;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private route: ActivatedRoute,
    private teacherService: TeacherService
  ) {
  }

  ngOnInit(): void {
    this.getSubjectId();
  }

  goToTestReview() {
    this.localStorageService.set('testId', this.testCard.curriculum_learning_objective_sa_test_id);
    this.router.navigateByUrl(
      `sa-report/${ this.testCard.curriculum_course_id }/${ this.testCard.curriculum_learning_objective_id }`
    );
  }

  getSubjectId(): void {
    this.curriculumId = this.route.snapshot.params.curriculumId;
    this.teacherService.curriculumId$.next(this.curriculumId);
  }
}
