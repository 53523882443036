import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'curr-cumulative-test-create',
  templateUrl: './cumulative-test-create.component.html',
  styleUrls: ['./cumulative-test-create.component.scss']
})
export class CumulativeTestCreateComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
