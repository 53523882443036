<!--
 Created by Volodymyr Mak. on 6/5/2020.
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div class="progress-bar" [ngStyle]="{'width': width, 'background-color': mainColor, 'height': height, 'border-radius': borderRadius}">
  <div
    [ngStyle]="{'width': value + '%', 'background-color': secondColor, 'height': height, 'border-radius': borderRadius}"
    class="progress">
  </div>
  <div
    *ngIf="secondValue"
    [ngStyle]="{'width': secondValue + '%', 'background-color': '#FFCC17', 'height': height, 'border-radius': borderRadius}"
    class="progress negative-margin z4">
  </div>
  <div
    *ngIf="thirdValue"
    [ngStyle]="{'width': thirdValue + '%', 'background-color': mainColor, 'height': height, 'border-radius': borderRadius}"
    class="progress negative-margin z3">
  </div>
</div>

