/**
 * Created by Daniil V. on 02/06/21.
 * Copyright © 2020 SEVEN. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { ApplicationHttpClient } from 'src/app/shared/api/application-http-client';
import { map } from 'rxjs/operators';
import { ITag, Question } from '../../../student/test.model';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewQuestionService {

  public question$: Subject<{question: Question, itemIndexs: number[]}> = new Subject();
  public answerFormat$: Subject<{type: string, itemIndexs: number[]}> = new Subject();
  public questionTopic$: Subject<{topicId: number, itemIndexs: number[]}> = new Subject();
  public removeItem$: Subject<number[]> = new Subject();
  public switchInroduction$: Subject<{introStatus: boolean, itemIndexs: number[]}> = new Subject();
  public updateOeqKeywords$: Subject<{ key: string, mark: number, questionNumber: string }> = new Subject();
  public updateAnswerOptions$: Subject<{ option: string, questionNumber: string }> = new Subject();
  
  constructor(private httpClient: ApplicationHttpClient) {}

  changeQuestion(data: {question: Question, itemIndexs: number[]}): void {
    this.question$.next(data);
  }

  onChangeQuestion(): Observable<{question: Question, itemIndexs: number[]}> {
    return this.question$.asObservable();
  }

  changeAnswerFormat(data: {type: string, itemIndexs: number[]}): void {
    this.answerFormat$.next(data);
  }

  onChangeAnswerFormat(): Observable<{type: string, itemIndexs: number[]}> {
    return this.answerFormat$.asObservable();
  }

  changeTopic(data: {topicId: number, itemIndexs: number[]}): void {
    this.questionTopic$.next(data);
  }

  onChangeTopic(): Observable<{topicId: number, itemIndexs: number[]}> {
    return this.questionTopic$.asObservable();
  }

  removeItem(indexes): void {
    this.removeItem$.next(indexes);
  }

  onRemoveItem(): Observable<number[]> {
    return this.removeItem$.asObservable();
  }

  changeIntroStatus(data: {introStatus: boolean, itemIndexs: number[]}): void {
    this.switchInroduction$.next(data);
  }

  onChangeIntroStatus(): Observable<{introStatus: boolean, itemIndexs: number[]}> {
    return this.switchInroduction$.asObservable();
  }

  updateOeqKeywords(data: { key: string, mark: number, questionNumber: string }): void {
    this.updateOeqKeywords$.next(data);
  }

  onUpdateOeqKeywords(): Observable<{ key: string, mark: number, questionNumber: string }> {
    return this.updateOeqKeywords$.asObservable();
  }

  updateAnswerOptions(data: { option: string, questionNumber: string }): void {
    this.updateAnswerOptions$.next(data);
  }

  onAnswerOptions(): Observable<{ option: string, questionNumber: string }> {
    return this.updateAnswerOptions$.asObservable();
  }
  
  getQuestionTags(searchStr = '') {
    return this.httpClient.get<{ data: ITag[] }>(`/questionTags/${searchStr}`).pipe(map(response => response?.data));
  }

  createTag(questionTagDTO: ITag) {
    return this.httpClient.post('/questionTags', questionTagDTO);
  }

  getExaminationBoardList(): Observable<any> {
    return this.httpClient.get('/examination-boards');
  }

  saveQuestions(questions: Question[]) {
    return this.httpClient.post('/curriculumQuestions', questions);
  }
}
