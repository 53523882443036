/**
 * Created by Yaroslav S. on 16/04/24.
 * Copyright © 2024 SEVEN. All rights reserved.
 */

import {
  Component,
  Type,
  OnDestroy,
  AfterViewInit,
  ComponentRef,
  ViewChild,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FullScreenPopupInsertionDirective } from './full-screen-popup-insertion.directive';
import { MatDialog } from '@angular/material/dialog';
import { CloseConfirmationPopupComponent } from '../new-question-block/close-confirmation-popup/close-confirmation-popup.component';

@Component({
  templateUrl: './full-screen-popup.component.html',
  styleUrls: ['./full-screen-popup.component.scss'],
})
export class FullScreenPopupComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;
  childComponentType: Type<any>;

  @ViewChild(FullScreenPopupInsertionDirective) insertionPoint: FullScreenPopupInsertionDirective;

  private readonly _onClose = new Subject<any>();
  public onClose = this._onClose.asObservable();

  public isFullScreen = false;
  public isSizeMini = false;
  public contentData: any;
  public showFullScreenBtn = true;
  public dialogTitle = '';
  public closeDialogTitle = '';
  public showContent = true;

  public dataFromChild: { data: any; shouldClose: boolean; confirmDialog: boolean };

  private destroy$: Subject<void> = new Subject<void>();

  @Output() afterClose = new EventEmitter();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private host: ElementRef<HTMLElement>,
    public dialog: MatDialog,
  ) {}

  get container(): HTMLElement {
    return this.host.nativeElement.querySelector('.full-screen-popup') as HTMLElement;
  }

  ngAfterViewInit(): void {
    document.body.style.overflow = 'hidden';
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  onDialogClicked(evt: MouseEvent): void {
    evt.stopPropagation();
  }

  animationDone(event: AnimationEvent) {
    if (event.animationName === 'fullScreenPopupOut') {
      this._onClose.next(this.dataFromChild);
    }
  }

  close() {
    if (!this.dataFromChild || this.dataFromChild.confirmDialog) {
      const dialogRef = this.dialog.open(CloseConfirmationPopupComponent, {
        width: '424px',
        data: {
          title: this.closeDialogTitle ?? 'Close?',
          showContent: this.showContent
        },
      });

      dialogRef
        .afterClosed()
        .pipe(takeUntil(this.destroy$))
        .subscribe((result) => {
          if (result) {
            document.body.style.overflow = 'auto';
            this.container.style.animation = 'fullScreenPopupOut 0.5s';
          }
        });
    } else {
      document.body.style.overflow = 'auto';
      this.container.style.animation = 'fullScreenPopupOut 0.5s';
    }
  }

  minimizePopup(): void {
    this.isSizeMini = !this.isSizeMini;
    document.body.style.overflow = this.isSizeMini ? 'auto' : 'hidden';
    this.showFullScreenBtn = this.isSizeMini ? false : this.contentData.options.showFullScreenBtn ?? true;
    this.container.className = `full-screen-popup ${this.isFullScreen ? 'full-screen' : ''} ${this.isSizeMini ? 'mini-size' : ''}`;
    this.container.style.animation = `${this.isSizeMini ? 'slideOut 0.5s' : 'slideIn 0.5s'}`;
  }

  openIn(): void {
    this.isFullScreen = !this.isFullScreen;
    document.body.style.overflow = 'hidden';

    if (this.isFullScreen) {
      this.container.className = 'full-screen-popup full-screen';
      this.container.style.animation = 'fullScreenPopupFull 0.5s';
    } else {
      this.container.className = 'full-screen-popup';
      this.container.style.animation = 'fullScreenPopupFullOut 0.5s';
    }
  }

  private loadChildComponent(componentType: Type<any>): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    if (this.contentData) {
      if (this.contentData.options) {
        this.showFullScreenBtn = this.contentData.options.showFullScreenBtn ?? true;

        if (this.contentData.options.isFullScreen) {
          this.isFullScreen = true;
          this.container.className = 'full-screen-popup full-screen';
        }

        this.dialogTitle = this.contentData.options.dialogTitle ?? '';
      }

      this.closeDialogTitle = this.contentData.data.closeDialogTitle ?? 'Close?';
      this.showContent = this.contentData.data.showContent ?? true;
      this.componentRef.instance.inputData = this.contentData;
    }

    this.componentRef.instance.onOutputData.subscribe((res) => {
      this.dataFromChild = res;
      if (res.shouldClose) this.close();
    });
  }
}
