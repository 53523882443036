import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateNetesSubjectService {
  private subject = new Subject<any>();
  private deleteNote = new Subject<number>();

  send() {
    this.subject.next({});
  }

  get(): Observable<any> {
    return this.subject.asObservable();
  }

  sendDeleteNote(nodeId) {
    this.deleteNote.next(nodeId);
  }

  onDeleteNote(): Observable<number> {
    return this.deleteNote.asObservable();
  }
}
