<!-- Created by Maxim B. on 01/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<mat-form-field
  [formGroup]="formGroup"
  color="primary"
  fxFlexFill
  [ngClass]="[
    isGreyBackground ? 'add-grey-background' : '',
    formGroup.controls[controlName].value?.toString().length ? 'not-empty-inp' : 'empty-inp'
  ]"
  [appearance]="isBordered ? 'outline' : 'standard'">
    <mat-label *ngIf="placeholder">{{ placeholder }}</mat-label>
    <input
      matInput
      aria-label="default time"
      [ngxTimepicker]="defaultValue"
      [readonly]="isReadOnly"
      #customInput
      [class.centered]="isCentered"
      [formControlName]="controlName"
      (keyup)="updateValue()"
      (blur)="onLostFocus()"
    />
    <ngx-material-timepicker (timeSet)="changeValue($event)" #defaultValue></ngx-material-timepicker>

  <ngx-material-timepicker-toggle *ngIf="suffix" [for]="defaultValue" matSuffix class="input-suffix">
    <mat-icon [svgIcon]="suffix"></mat-icon>
  </ngx-material-timepicker-toggle>

  <mat-hint *ngIf="userHint">{{ userHint }}</mat-hint>
  <mat-error *ngIf="formGroup.controls[controlName]?.errors">{{ errorMessage }}</mat-error>
</mat-form-field>
