/**
 * Created by Daniil Vat. on 13/4/2021
 * Copyright © 2021 Curriculum Ltd. All rights reserved.
 */
import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';
import { COLORS } from '../consts';
import { QuestionsLabels } from 'src/app/student/test.model';

@Component({
  selector: 'curr-type-of-questions-answered-incorrectly',
  templateUrl: './type-of-questions-answered-incorrectly.component.html',
  styleUrls: ['./type-of-questions-answered-incorrectly.component.scss']
})
export class TypeOfQuestionsAnsweredIncorrectlyComponent implements OnInit {
  @Input() set data(value: any) {
    if (value) {
      this.chartData = [Object.values(value.incorrectlyAnsweredByType).map(dataItem => Number(dataItem))];
      this.quuestionsList = Object.entries(value.incorrectlyAnsweredByType)
        .map(([questionType, val]) => {
          return {
            label: QuestionsLabels[questionType],
            value: `${ Number(val) }%`
          };
        });
    }
  }

  quuestionsList: { label: string; value: string }[] = [];

  type: ChartType = 'doughnut';
  chartData = [];

  options: ChartOptions = {
    responsive: true,
    // tooltips: {
    //   enabled: false
    // },
    // cutoutPercentage: 60,
    hover: { mode: null },
    // plugins: {
    //   labels: {
    //     fontColor: 'transparent'
    //   }
    // }
  };
  colors = [
    {
      backgroundColor: COLORS
    }
  ];

  constructor() {
  }

  // TODO: remove?
  ngOnInit() {
  }
}
