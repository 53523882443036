<!--
 Created by Alex Poh. on 02/07/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->

<div
  @fade
  *ngIf="courseFilters"
  class="dropdown-container m-b-56"
  fxLayout="row"
  fxLayoutAlign="start"
  fxLayoutGap="20px"
>
  <div fxFlex="196px">
    <curr-dropdown-component-v2
      [options]="courseFilters.subjects"
      placeholder="All subjects"
      (selectedValueChange)="selectSubject($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="196px">
    <curr-dropdown-component-v2
      [options]="[{ value: undefined, label: 'All years' }]"
      placeholder="All years"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="196px">
    <curr-dropdown-component-v2
      [options]="courseFilters.courses"
      placeholder="All courses"
      (selectedValueChange)="selectCourse($event)"
    ></curr-dropdown-component-v2>
  </div>

  <div fxFlex="240px">
    <curr-dropdown-component-v2
      [options]="courseFilters.getFilteredItems('semesters', 'courses', 'courseToSemester')"
      placeholder="All semesters"
      (selectedValueChange)="selectSemester($event)"
    ></curr-dropdown-component-v2>
  </div>
</div>
