/**
 * Created by Dmytro Sav. on 4/15/2020
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { RestApiService, restApiServiceCreator } from '../../shared/api/rest-api.service';
import { ApplicationHttpClient } from '../../shared/api/application-http-client';
import { Injectable } from '@angular/core';
import { CurriculumManagementModel } from './curriculum-management.model';

@Injectable()
export class CurriculumManagmentResolver implements Resolve<any> {

  constructor(private httpClient: ApplicationHttpClient) {
  }

  schoolYearCouseService: RestApiService<CurriculumManagementModel>;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.schoolYearCouseService = restApiServiceCreator<CurriculumManagementModel>('/curriculums/management')(
      this.httpClient
    );
    // TODO: remove hardcoded id
    return EMPTY;
    // return {
    //   subject: 'Biology',
    //   year: 'Year 9',
    //   form: 'Form 3 (Level Advance)',
    //
    //   published: [
    //     {
    //       name: 'Biology Year 9 – Form 1',
    //       id: 7856432,
    //       totalLessons: 78,
    //       Author: 'Me',
    //       isInComplete: false
    //     },
    //     {
    //       name: 'Physics Year 11 – Form 3',
    //       id: 7856432,
    //       totalLessons: 92,
    //       Author: 'Me',
    //       isInComplete: false
    //     },
    //     {
    //       name: 'Biology Year 10 – Form 2',
    //       id: 7856432,
    //       totalLessons: 74,
    //       Author: 'Me',
    //       isInComplete: false
    //     },
    //     {
    //       name: 'Physics Year 9 – Form 2',
    //       id: 7856432,
    //       totalLessons: 69,
    //       Author: 'Me',
    //       isInComplete: false
    //     },
    //     {
    //       name: 'Chemistry Year 9 – Form 3',
    //       id: 7856432,
    //       totalLessons: 47,
    //       Author: 'Me',
    //       isInComplete: false
    //     }
    //   ],
    //
    //   drafts: [
    //     {
    //       name: 'Biology Year 9 – Form 1',
    //       id: 7856432,
    //       totalLessons: 85,
    //       Author: 'Me',
    //       isInComplete: false
    //     }
    //   ]
    // };
  }
}
