<!-- Created by Maxim B. on 20/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="students-statistic-table">
  <div class="loader-container" *ngIf="isDataLoading">
    <mat-spinner [strokeWidth]="5" [diameter]="56"></mat-spinner>
  </div>

  <!-- <div class="drop-down-container" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="drop-downs" fxLayout="row" fxLayoutAlign="start center">

      <curr-select-courses-filters [preselectedCourseId]="preselectedCourseId" [filterOptionsData]="filterData"
        [filterFields]="coursesFilterFields" (onFilterChange)="onSelectFilterChange($event)">
      </curr-select-courses-filters>
    </div>

    <div class="search-container" fxLayout="row" fxLayoutAlign="end center" fxFlex="50">
      <curr-input class="curr-input" [isBordered]="false" placeholder="Search" fxFlex="50"
        (keyup)="filterEntries($event)"></curr-input>
      <mat-icon [svgIcon]="'search_black'"></mat-icon>
    </div>
  </div> -->

  <ng-container *ngIf="isDataPresented">
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort aria-describedby="users desc">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef scope="col">Name</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="'/studentProfile/' + (element.studentId || '1')" class="user-name">
              <p>{{ element.name }}</p>
            </a>
          </td>
        </ng-container>

        <ng-container [matColumnDef]="item" *ngFor="let item of displayedColumns | slice: 1">
          <ng-container *ngIf="item == 'prevWeekMeetings'">
            <th
              mat-header-cell
              *matHeaderCellDef
              [class.th-meeting]="item == 'prevWeekMeetings'"
              scope="col"
              scope="col"
            >
              <div>
                {{ 'dropdownItemLabels.' + item | translate }}
                <p
                  *ngIf="item == 'prevWeekMeetings'"
                  [matMenuTriggerFor]="meetingMenu"
                  (click)="$event.stopPropagation(); isMeetingDropdownOpen = true"
                  class="dont-brake-rows"
                >
                  {{ isMeetingThisWeek ? 'This week' : 'Prev. week' }}
                  <mat-icon *ngIf="isMeetingDropdownOpen">arrow_drop_up</mat-icon>
                  <mat-icon *ngIf="!isMeetingDropdownOpen">arrow_drop_down</mat-icon>
                </p>
                <mat-menu #meetingMenu="matMenu" xPosition="after" class="meeting-menu">
                  <button mat-menu-item (click)="selectMeetingWeek(true)">This week</button>
                  <button mat-menu-item (click)="selectMeetingWeek(false)">Prev. week</button>
                </mat-menu>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="item != 'prevWeekMeetings'">
            <th mat-header-cell *matHeaderCellDef scope="col">
              {{ 'dropdownItemLabels.' + item | translate }}
            </th>
          </ng-container>

          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="item === 'prevWeekMeetings'" [ngSwitch]="element[item]">
              <span *ngSwitchCase="'Yes'" class="m-b-0">{{ element[item] }}</span>
              <a *ngSwitchCase="'Pending'" [matMenuTriggerFor]="pendingMenu" class="user-name"
                (click)="clickPending($event, element, pendingMenu)">
                <p>{{element.prevWeekMeetings}}</p>
                <mat-icon *ngIf="isInTableDropdownOpen && clickedPendingUser == element.name">arrow_drop_up</mat-icon>
                <mat-icon *ngIf="!isInTableDropdownOpen || clickedPendingUser!=element.name">arrow_drop_down</mat-icon>
              </a>
              <mat-menu #pendingMenu="matMenu" xPosition="after" class="meeting-menu">
                <button mat-menu-item class="icon-tab-menu-item" (click)="setMeetingToDone(element, 'Pending')">
                  Pending
                </button>
                <button mat-menu-item class="icon-tab-menu-item" (click)="setMeetingToDone(element, 'Yes')">
                  Yes
                </button>
              </mat-menu>
              <span *ngSwitchDefault>{{ element[item] }}</span>
            </ng-container>
            <ng-container *ngIf="item != 'prevWeekMeetings' && item != 'performance'">
              {{ element[item] }}
            </ng-container>
            <ng-container *ngIf="item == 'performance'">
              <curr-progress-bar-gradient
                [benchmark]="averageBenchmark"
                [averageGrade]="element['avgGrade']"
                [progress]="element[item]">
              </curr-progress-bar-gradient>
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <curr-table-pagination
      [totalResults]="resultsLength"
      [pageSize]="20"
      [currentPageIndex]="currentPageIndex"
      [showingText]="'students'"
      (changePage)="onPageChange($event)">
    </curr-table-pagination>
  </ng-container>

  <ng-container *ngIf="!isDataPresented">
    <div class="no-data-container">
      <img src="/assets/images/colored/amico.svg" />
      <div class="no-data-title">Please invite your students to view this data</div>
      <div class="no-data-subtitle">Please share your course ID with students</div>
    </div>
  </ng-container>
</div>
