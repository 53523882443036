export const environment = {
  production: true,
  serverUrl: 'https://api.curriculum.com',
  apiUrl: 'https://api.curriculum.com/api/v1',
  apiLinkPreviewUrl: 'http://api.linkpreview.net/?key=0e87387e75e248985a32c874b49a14f2&q=',
  translation: {
    defaultLanguage: 'en'
  },
  auth: {
    username: 'admin1234'
  },
  tinyEditorKey: 'g630c5ja07iackz9ke9dmac45xy5z1v28hziwvnpz3bxki84',
  highlightColors:['#FFEA00', '#76FF03', '#00B0FF', '#FF4081', '#B388FF'],
  studentGradesStatisticColors:['#FFA000', '#512DA8','#C2185B','#19d2b8',
    '#B388FF','#1976D2','#cbfb07','#0097e6',
    '#4cd137','#88179e','#273c75','#c23616'],
};
