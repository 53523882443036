/**
 * Created by Alex Poh. on 25/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

export class TimePeriod {
  timeDisplayState: TimeDisplayState;

  constructor(public startDate: Date, public endDate: Date) {
    this.timeDisplayState = new YearState(
      new Date(endDate.getFullYear(), 0, 1),
      new Date(endDate.getFullYear(), 11, 31),
      this
    );
    this.timeDisplayState.timePeriod = this;
  }
}

export abstract class TimeDisplayState {
  public name: string;

  public constructor(public displayedStartDate: Date, public displayedEndDate: Date, public timePeriod: TimePeriod) {}

  abstract next();

  abstract prev();

  abstract setToYear();

  abstract setToSemester();

  abstract setToMonth();

  abstract project(date: Date): Date;
}

export class YearState extends TimeDisplayState {
  name = 'year';

  next() {
    this.timePeriod.timeDisplayState = new YearState(
      new Date(this.displayedStartDate.getFullYear() + 1, 0, 1),
      new Date(this.displayedStartDate.getFullYear() + 1, 11, 31),
      this.timePeriod
    );
  }

  prev() {
    this.timePeriod.timeDisplayState = new YearState(
      new Date(this.displayedStartDate.getFullYear() - 1, 0, 1),
      new Date(this.displayedStartDate.getFullYear() - 1, 11, 31),
      this.timePeriod
    );
  }

  setToYear() {}

  setToSemester() {
    this.timePeriod.timeDisplayState = new SemesterState(
      new Date(this.displayedStartDate.getFullYear(), 0, 1),
      new Date(this.displayedStartDate.getFullYear(), 5, 30),
      this.timePeriod
    );
  }

  setToMonth() {
    this.timePeriod.timeDisplayState = new MonthState(
      new Date(this.displayedStartDate.getFullYear(), 0, 1),
      new Date(this.displayedStartDate.getFullYear(), 0, 31),
      this.timePeriod
    );
  }

  project(date: Date): Date {
    const result = new Date(date.valueOf());
    result.setFullYear(this.displayedStartDate.getFullYear());
    return result;
  }
}

export class SemesterState extends TimeDisplayState {
  name = 'semester';
  next() {
    if (this.displayedStartDate.getMonth() === 0) {
      this.timePeriod.timeDisplayState = new SemesterState(
        new Date(this.displayedStartDate.getFullYear(), 6, 1),
        new Date(this.displayedStartDate.getFullYear(), 11, 31),
        this.timePeriod
      );
    } else {
      this.timePeriod.timeDisplayState = new SemesterState(
        new Date(this.displayedStartDate.getFullYear() + 1, 0, 1),
        new Date(this.displayedStartDate.getFullYear() + 1, 5, 30),
        this.timePeriod
      );
    }
  }

  prev() {
    if (this.displayedStartDate.getMonth() === 0) {
      this.timePeriod.timeDisplayState = new SemesterState(
        new Date(this.displayedStartDate.getFullYear() - 1, 6, 1),
        new Date(this.displayedStartDate.getFullYear() - 1, 11, 31),
        this.timePeriod
      );
    } else {
      this.timePeriod.timeDisplayState = new SemesterState(
        new Date(this.displayedStartDate.getFullYear(), 0, 1),
        new Date(this.displayedStartDate.getFullYear(), 5, 30),
        this.timePeriod
      );
    }
  }

  setToMonth() {
    this.timePeriod.timeDisplayState = new MonthState(
      new Date(this.displayedStartDate),
      new Date(this.displayedStartDate.getFullYear(), this.displayedStartDate.getMonth() + 1, 0),
      this.timePeriod
    );
  }

  setToSemester() {}

  setToYear() {
    this.timePeriod.timeDisplayState = new YearState(
      new Date(this.displayedStartDate.getFullYear(), 0, 1),
      new Date(this.displayedStartDate.getFullYear(), 11, 31),
      this.timePeriod
    );
  }

  project(date: Date): Date {
    const result = new Date(date.valueOf());
    result.setFullYear(this.displayedStartDate.getFullYear());

    return result;
  }
}

export class MonthState extends TimeDisplayState {
  name = 'month';
  next() {
    this.timePeriod.timeDisplayState = new MonthState(
      new Date(this.displayedStartDate.setMonth(this.displayedStartDate.getMonth() + 1)),
      new Date(this.displayedStartDate.getFullYear(), this.displayedStartDate.getMonth() + 1, 0),
      this.timePeriod
    );
  }

  prev() {
    this.timePeriod.timeDisplayState = new MonthState(
      new Date(this.displayedStartDate.getFullYear(), this.displayedStartDate.getMonth() - 1),
      new Date(this.displayedStartDate.setMonth(this.displayedStartDate.getMonth() - 1)),
      this.timePeriod
    );
  }

  setToMonth() {}

  setToSemester() {
    this.timePeriod.timeDisplayState = new SemesterState(
      new Date(this.displayedStartDate.getFullYear(), 0, 1),
      new Date(this.displayedStartDate.getFullYear(), 5, 30),
      this.timePeriod
    );
  }

  setToYear() {
    this.timePeriod.timeDisplayState = new YearState(
      new Date(this.displayedStartDate.getFullYear(), 0, 1),
      new Date(this.displayedStartDate.getFullYear(), 11, 31),
      this.timePeriod
    );
  }

  project(date: Date): Date {
    const result = new Date(date.valueOf());
    result.setFullYear(this.displayedStartDate.getFullYear());
    result.setMonth(this.displayedStartDate.getMonth());
    return result;
  }
}
