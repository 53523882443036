import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

export interface PaginationLabel {
  label: string;
  isAutomarked: boolean;
  id: number;
  questionInfoId?: number;
}

@Component({
  selector: 'curr-pagination-stepper',
  templateUrl: './pagination-stepper.component.html',
  styleUrls: ['./pagination-stepper.component.scss']
})
export class PaginationStepperComponent implements OnInit {
  @ViewChild('pagination') pagination: ElementRef;
  @Input() items: number[] = [];
  @Input() checkedStep: number[] = [];
  @Input() isSideBtnVisible = true;
  @Input() isDisabledPrev = false;
  @Input() isDisabledNext = false;
  @Input() selectedIndex = 1;
  @Input() set setStep(value: number) {
    if (typeof value === 'number') this.questionSelected.emit(value);
  }
  @Input() isTTTeacher: boolean = false;
  @Output() questionSelected = new EventEmitter<number>();
  @Output() paginationLabelsReady = new EventEmitter<PaginationLabel[]>();

  paginationLabels: PaginationLabel[] = [];
  selectedLabel: PaginationLabel;
  currentSelectedPaginationIndex: number;

  constructor() {
  }

  ngOnInit(): void {
  }

  prevQuestion() {
    if (this.selectedIndex > 0) {
      const i = this.selectedIndex - 1;
      this.selectQuestionOutput(i);
    }
  }

  nextQuestion() {
    if (this.selectedIndex < (this.items.length - 1)) {
      const i = this.selectedIndex + 1;
      this.selectQuestionOutput(i);
    }
  }

  selectQuestion(i: number) {
    if (this.selectedIndex < i && this.isDisabledNext) return;
    this.selectQuestionOutput(i);
  }

  private selectQuestionOutput(i: number) {
    this.questionSelected.emit(i);
  }
}
