/**
 * Created by Alex Poh. on 22/03/20.
 * Copyright © 2020 Curriculum Ltd. All rights reserved.
 */

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'curr-auth-input',
  templateUrl: './auth-input.component.html',
  styleUrls: ['./auth-input.component.scss']
})
export class AuthInputComponent implements OnInit {
  @Input()
  placeholder: string;

  @Input()
  caption: string;

  @Input()
  formGroup: FormGroup;

  @Input()
  controlName: string;

  @Input()
  errorMessage = '';

  constructor() {}

  ngOnInit(): void {}
}
