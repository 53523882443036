<!-- Created by Maxim B. on 17/04/20.-->
<!-- Copyright © 2020 Curriculum Ltd. All rights reserved.-->

<div class="container">
  <div class="full-width">
    <div id="nav-sow" [ngStyle]="{height: '36px'}">
      <div class="overline last-modified">Last modified: {{ learningObjective?.updated_at | date: 'dd MMM yyyy' }}</div>
      <curr-button *ngIf="disabledStatus && mediaItems.length > 0" size="big" color="primary" variant="outlined" (btnClick)="disabledStatus = false">
        EDIT
      </curr-button>
      <curr-button *ngIf="!disabledStatus && mediaItems.length > 0" size="big" color="primary" variant="outlined" (btnClick)="onSaveClick()">
        SAVE
      </curr-button>
    </div>
  </div>

  <div class="header-container main-header m-b-16" fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="mat-h2">Resources</h2>
    <curr-dropdown (valueChange)="setType($event)" [selectedValue]="currResourceType" [options]="ddTypeOptions"
      fxHide.gt-xs></curr-dropdown>
    <div class="tabs-container" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" fxHide.lt-sm>
      <curr-button [variant]="'outlined'" [icon]="'play'" color="primary" [isDisabled]="newMediaItem"
        (btnClick)="initByMediaType(MediaType.video)" [isHighlighted]="currResourceType === MediaType.video">
        VIDEOS
      </curr-button>
      <curr-button [variant]="'outlined'" [icon]="'image'" color="primary" [isDisabled]="newMediaItem"
        (btnClick)="initByMediaType(MediaType.image)" [isHighlighted]="currResourceType == MediaType.image">
        IMAGES
      </curr-button>
      <curr-button [variant]="'outlined'" [icon]="'file'" color="primary" [isDisabled]="newMediaItem"
        (btnClick)="initByMediaType(MediaType.document)" [isHighlighted]="currResourceType == MediaType.document">
        DOCUMENTS
      </curr-button>
      <curr-button [variant]="'outlined'" [icon]="'link'" color="primary" [isDisabled]="newMediaItem"
        (btnClick)="initByMediaType(MediaType.link)" [isHighlighted]="currResourceType == MediaType.link">
        ARTICLES
      </curr-button>
    </div>
  </div>

  <curr-loader [diameter]="40" [isLoading]="isLoading" *ngIf="isLoading"></curr-loader>

  <div *ngIf="!mediaItems.length" class="no-resources m-b-16">
    <img src="/assets/images/colored/empty-task-list.svg" />
    <div class="empty-text">No Student Handout Yet!</div>
  </div>

  <div class="drop-list-container" fxLayoutGap="10px" *ngIf="currResourceType == MediaType.video" fxLayout="column"
    fxLayoutAlign="start stretch">
    <div @fade class="wrapper-item" *ngFor="let item of mediaItems">
      <curr-media-item [mediaItem]="item" (onEditItem)="updateMediaItem($event)" (onDeleteItem)="deleteMediaItem(item)"
        [isEditable]="!disabledStatus"></curr-media-item>
    </div>
  </div>
  <div cdkDropList class="drop-list-container" fxLayoutGap="10px" *ngIf="currResourceType == MediaType.image"
    (cdkDropListDropped)="dropItem($event, 'image')" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="wrapper-item" cdkDrag *ngFor="let item of mediaItems; index as i">
      <!--      <div class="example-custom-placeholder" *cdkDragPlaceholder></div>-->
      <curr-media-item [mediaItem]="item" (onEditItem)="updateMediaItem($event, i)"
        (onDeleteItem)="deleteMediaItem(item)" [isEditable]="!disabledStatus"></curr-media-item>
    </div>
  </div>
  <div cdkDropList class="drop-list-container" fxLayoutGap="10px" *ngIf="currResourceType == MediaType.document"
    (cdkDropListDropped)="dropItem($event, 'doc')" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="wrapper-item" cdkDrag *ngFor="let item of mediaItems; index as i">
      <curr-media-item [mediaItem]="item" (onEditItem)="updateMediaItem($event, i)"
        (onDeleteItem)="deleteMediaItem(item)" [isEditable]="!disabledStatus"></curr-media-item>
    </div>
  </div>
  <div cdkDropList class="drop-list-container" fxLayoutGap="10px" *ngIf="currResourceType == MediaType.link"
    (cdkDropListDropped)="dropItem($event, 'link')" fxLayout="column" fxLayoutAlign="start stretch">
    <div class="wrapper-item" cdkDrag *ngFor="let item of mediaItems">
      <curr-media-item [mediaItem]="item" (onEditItem)="updateMediaItem($event)" (onDeleteItem)="deleteMediaItem(item)"
        [isEditable]="!disabledStatus"></curr-media-item>
    </div>
  </div>

  <div class="add-new-media-container" fxLayout="column" fxLayoutAlign="start stretch">
    <curr-media-item [mediaItem]="newMediaItem" *ngIf="newMediaItem" (onCancelNewlyCreatedItem)="cancelAddMedia()"
      (onNewlyCreatedItem)="createNewMediaItem($event)" [setupOnEditMode]="true"></curr-media-item>
    <div fxLayout="row" fxLayoutAlign="start start">
      <curr-button [icon]="'plus'" size="big" *ngIf="!newMediaItem" (btnClick)="addMedia()" fxFlex="160px" class="media-btn m-r-8"
        [variant]="'contained'">ADD MEDIA
      </curr-button>
      <curr-button variant="outlined" size="big" *ngIf="newMediaItem" (btnClick)="cancelAddMedia()">
        CANCEL
      </curr-button>
    </div>
  </div>
</div>