<!--
 Created by Dmytro Sav. on 4/5/2020
 Copyright © 2020 Curriculum Ltd. All rights reserved.
-->
<div class="wrapper">
  <div
    *ngIf="!inEditMode"
    class="dropdown"
    (click)="toggleDropdown()"
    [ngClass]="{ open: isShowed, error: isError }"
    currClickOutside
    (clickOutside)="closeDropDown()"
  >
    <div class="custom-select" [class.disabled]="isDisabled">
      <div class="content" fxLayoutAlign="space-between center" fxLayout="row">
        <div class="custom-select__trigger mat-h3 text">
          <div class="text" [class.disabled]="isDisabled">{{ about.key }}</div>
        </div>
        <div fxLayoutAlign="end center" fxLayout="row">
          <div class="options">
            <mat-icon [matMenuTriggerFor]="beforeMenu" (click)="$event.stopPropagation()">more_vert</mat-icon>
            <mat-menu #beforeMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="editItem()">
                <mat-icon>edit</mat-icon>
                Edit
              </button>
              <button mat-menu-item (click)="deleteItem()">
                <mat-icon>delete</mat-icon>
                Delete
              </button>
            </mat-menu>
          </div>
          <div class="arrow-wrapper m-r-24 m-l-8"><span class="arrow"></span></div>
        </div>
      </div>
    </div>

    <div *ngIf="isShowed" class="options" [@enterAnimation]>
      <div class="divider"></div>
      <div class="container paper-title" [innerHTML]="about.value">
      </div>
    </div>
  </div>

  <div
    *ngIf="inEditMode"
    class="dropdown">
    <div *ngIf="inEditMode" fxLayout="column" fxLayoutAlign="start start" class="edit-container">
      <curr-input [(value)]="editedAbout.key" placeholder="Title" (valueChange)="updateTitle($event)" class="full-width"></curr-input>
      <curr-tiny-editor
        [topic]="{}"
        [(value)]="about.value"
        (valueChange)="update($event)"
        [options]="{ height: 300, placeholder: '', menubar: 'file edit view  insert format table help' }"
        class="full-width"
      ></curr-tiny-editor>      <div fxLayout="row" fxLayoutAlign="start center" class="m-t-16">
        <curr-button variant="contained" (click)="save()" [isDisabled]="!editedAbout.key || !editedAbout.value">SAVE</curr-button>
        <curr-button variant="outlined" (click)="cancelEdit()" class="m-l-8">CANCEL</curr-button>
      </div>
    </div>
  </div>
</div>
